import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Typography from '../../components/Typography';
import imagen1 from '../../../assets/images/imagen1-headerAcademia.jpg';
import {Grid, Modal} from "@material-ui/core";
import {GridOff} from "@material-ui/icons";
import logoSancor from '../../../assets/images/logos/sancorlogo.png';
import logoBancoDelSol from '../../../assets/images/logos/bancodelsollogo.png';
import logoPrevencionSalud from '../../../assets/images/logos/prevencionsaludlogo.png';
import imgConstruccion from '../../../assets/images/cogwheel.png';
import proximamente from '../../../assets/images/productos/proximamente.png';





const styles = (theme) => ({
    background: {
        width: '98vw',
        maxWidth: '1400px',
    },
    titulo: {
        fontSize: 21,
        color: '#452d7b',
        fontFamily: 'Ubuntu',
        fontWeight: 'bold',
        letterSpacing: 0,
        margin: '20px 0 5px',
        textAlign: 'center',
        textShadowColor: "rgba(82, 82, 82, 0.15)",
        textShadowOffset: {
            width: 0,
            height: 1
        },
        textShadowRadius: 2
    },

    texto1: {
        fontSize: 14,
        color: '#8a8597',
        fontFamily: "Montserrat",
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: 'center'
    },

    image1: {
        width: '186px',

        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
        marginTop: '20px'
    },
    rectangulo:{
        borderRadius: 31,
        backgroundColor: "#ffffff",
        shadowColor: "rgba(70, 70, 70, 0.15)",
        shadowOffset: {
            width: 0,
            height: 10
        },
        shadowRadius: 40,
        shadowOpacity: 1,
        borderStyle: "solid",
        borderWidth: 4,
        borderColor: '#62c08a',
        width: '280px',
        height: '240px',
    },
    gridRectangulo:{
        display:'flex', alignItems:'center', justifyContent:'center'
    },
    proximamente:{
        width: 40,
        height: 166,
        transform:"rotate(-315deg)",
        backgroundColor: "#e42222",
        position: 'absolute',
        top: -70,
        left: 5
    },

});

function HeaderProductos(props) {
    const {classes} = props;

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            paddingLeft: '70px',
            paddingRight: '70px',
            width:'100%',
            backgroundColor: '#35303f',
            paddingBottom: '25px',
            paddingTop:'40px'

        }}>

            <Grid container className={classes.background}>
                <Grid container direction={"row"}>

                    <Grid item md={4} xs={4} className={classes.gridRectangulo} >
                       <Grid container className={classes.rectangulo} direction={"column"}>
                           <Grid item>
                               <Typography className={classes.titulo}>Sancor Seguros</Typography>
                               <Typography className={classes.texto1}>www.sancorseguros.com.ar</Typography>
                           </Grid>
                           <Grid item>
                               <img className={classes.image1}
                                    style={{display: 'block'}}
                                    src={logoSancor}/>
                           </Grid>

                       </Grid>
                    </Grid>

                    <Grid item md={4} xs={4} className={classes.gridRectangulo} >
                        <Grid container className={classes.rectangulo} style={{borderColor: '#fff'}} direction={"column"}>
                            <Grid item>
                                <Typography className={classes.titulo}>Banco del Sol</Typography>
                                <Typography className={classes.texto1}>www.bancodelsol.com</Typography>
                            </Grid>
                            <Grid item style={{position:"relative"}}>
                                <img className={classes.image1}
                                     style={{display: 'block'}}
                                     src={logoBancoDelSol}/>

                                <img src={proximamente} style={{
                                         width: '120px',
                                         height: '120px',
                                         position: 'absolute',
                                         top: -79,
                                         left: -4}}/>

                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item md={4} xs={4} className={classes.gridRectangulo}>
                        <Grid container className={classes.rectangulo} style={{borderColor: '#fff'}} direction={"column"}>
                            <Grid item>
                                <Typography className={classes.titulo}>Prevención Salud</Typography>
                                <Typography className={classes.texto1}>www.prevencionsalud.com.ar</Typography>
                            </Grid>
                            <Grid item style={{position:'relative'}}>
                                <img className={classes.image1}
                                     style={{display: 'block'}}
                                     src={logoPrevencionSalud}/>
                                <img src={proximamente} style={{
                                    width: '120px',
                                    height: '120px',
                                    position: 'absolute',
                                    top: -79,
                                    left: -4}}/>
                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </div>
    )
}


HeaderProductos.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HeaderProductos);