import MantineDropzone from "../../../../components/MantineDropzone/MantineDropzone";
import {MIME_TYPES} from "@mantine/dropzone";
import React, {useRef, useState} from "react";

function ImagesGrid(props) {
    const {setSrcImage, setOpenDialog, setSrcFile, croppedImageUrl, initialUrlImage} = props;
    const [errorText, setErrorText] = useState('');
    const fileInputRef = useRef(null);

    const handleReject = (errors) => {
        let newErrorText = '';
        errors.forEach((err) => {
            if (err.code === 'file-invalid-type') {
                newErrorText = 'El formato del archivo debe ser png, jpg o svg';
            }
            if (err.code === 'file-too-large') {
                if (newErrorText === '') {
                    newErrorText = 'El archivo debe tener un peso menor a 2MB';
                } else {
                    newErrorText = 'El formato del archivo debe ser png, jpg o svg y debe tener un peso menor a 2MB';
                }
            }
        })
        setErrorText(newErrorText);
    }

    const handleImageSelect = (file) => {
        setErrorText('');
        if (file) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setSrcImage(reader.result);
                setSrcFile(file);
                setOpenDialog(true);
            });
            reader.readAsDataURL(file);

            if (fileInputRef.current) {
                fileInputRef.current.value = null;
            }
        }
    };

    return (
        <div className="LForm__container">
            <div className="LForm__columnContainer">
                <label className="LForm__sectorTitle">Logo elegido para tu plataforma</label>
                <img src={croppedImageUrl ? croppedImageUrl : initialUrlImage} alt="Logo"
                     className="LForm__logoSelected"/>
            </div>
            <div className="LForm__columnContainer">
                <label className="LForm__sectorTitle">Seleccioná otro Logo</label>
                <MantineDropzone clarificationText="" onDrop={(event) => handleImageSelect(event[0])}
                                 accept={[MIME_TYPES.png, MIME_TYPES.jpeg, MIME_TYPES.svg]} maxSize={2000000}
                                 onReject={(e) => handleReject(e[0].errors)}
                                 classNames={{root: "LForm__dropzone", inner: "LForm__dropzoneInner"}}/>
                <p className="LForm__error">
                    {errorText}
                </p>
            </div>
        </div>
    );
}

export default ImagesGrid;