export default {
    formId: 'cotizarForm',
    formField: {
        logo: {
            name: 'logo',
            label: 'Elegí tu logo',
            requiredErrorMsg: 'Debe cargar un logo'
        },
        template: {
            name: 'template',
            label: '',
            requiredErrorMsg: 'Debe elegir una plantilla'
        },
        products:{
            name: 'products',
            label: 'Elegí tus productos',
            requiredErrorMsg: 'Debe elegir al menos un producto'
        },
        description: {
            name: 'description',
            label: 'Configurá tu descripción',
            requiredErrorMsg: 'Debe elegir al menos una de las primeras tres opciones'
        },
        links: {
            name: 'links',
            label: 'Incluí tus redes sociales',
        },
    }
};
