import React from 'react';
import linkedin from '../../../assets/images/icons/socialMedia/linkedin.png'
import instagram from '../../../assets/images/icons/socialMedia/instagram.png'
import facebook from '../../../assets/images/icons/socialMedia/facebook.png'
import youtube from '../../../assets/images/icons/socialMedia/youtube.png'
import tiktok from '../../../assets/images/icons/socialMedia/tiktok.png'
import other from '../../../assets/images/icons/socialMedia/other.png'
import {makeStyles} from "@material-ui/core/styles";
import {Tooltip} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    containerDiv: {
        display: "flex",
        justifyContent: "center"
    },
    icons: {
        width: "24px", height: "24px", margin: "0 4px"
    }
}));

const socialNetworks = {
    'linkedin': linkedin,
    'instagram': instagram,
    'facebook': facebook,
    'tiktok': tiktok,
    'youtube': youtube
}

function SocialMediaLinks(props) {
    const {links} = props;
    const classes = useStyles();

    /**
     * If the link contains any of the social network names, return the corresponding image, otherwise return the default image.
     * @param link - The link that you want to check if it contains any of the social networks.
     * @returns The image of the social network that is found in the link.
     */
    function containsAny(link) {
        const found = Object.keys(socialNetworks).find(v => link.includes(v));
        return <Tooltip title={link}><img src={found ? socialNetworks[found] : other} alt="social_media" className={classes.icons}/></Tooltip>
    }

    return (
        <div className={classes.containerDiv}>
            {links
                ? links.map(link => (
                    containsAny(link)
                ))
                : <p>Sin datos</p>
            }
        </div>
    );
}

export default SocialMediaLinks;