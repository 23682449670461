import * as Yup from "yup";
import {isValidPhoneNumber} from "react-phone-number-input";

const ClienteModel = () => {
    const hoy = new Date();
    const fechaMaxima = new Date(new Date().setFullYear(new Date().getFullYear() - 86));

    const formField = {
        tipoDocumento: {
            name: 'tipoDocumento',
            label: 'Tipo de documento',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        numeroDocumento: {
            name: 'numeroDocumento',
            label: 'Número de documento',
            requiredErrorMsg: 'Debe especificar este campo'
        },
        nombre: {
            name: 'nombre',
            label: 'Nombre',
            requiredErrorMsg: 'Debe especificar este campo'
        },
        apellido: {
            name: 'apellido',
            label: 'Apellido',
            requiredErrorMsg: 'Debe especificar este campo'
        },
        fechaNacimiento: {
            name: 'fechaNacimiento',
            label: 'Fecha de nacimiento',
            requiredErrorMsg: 'Debe especificar la fecha de nacimiento'
        },
        genero: {
            name: 'genero',
            label: 'Género',
            requiredErrorMsg: 'Debe seleccionar un género'
        },
        pais_pasajero: {
            name: 'pais_pasajero',
            label: 'País',
            requiredErrorMsg: 'Debe seleccionar un país'
        },
        provincia: {
            name: 'provincia',
            label: 'Provincia',
            requiredErrorMsg: 'Debe seleccionar una provincia'
        },
        codigoPostal: {
            name: 'codigoPostal',
            label: 'Código postal',
            requiredErrorMsg: 'Debe especificar un código postal'
        },
        ciudad: {
            name: 'ciudad',
            label: 'Localidad',
            requiredErrorMsg: 'Debe seleccionar una localidad'
        },
        calle: {
            name: 'calle',
            label: 'Dirección de domicilio',
            requiredErrorMsg: 'Debe especificar una dirección de domicilio'
        },
        numero: {
            name: 'numero',
            label: 'Número de domicilio',
            requiredErrorMsg: 'Debe especificar el número de su domicilio'
        },
        opcional: {
            name: 'opcional',
            label: 'Torre / Piso / Dpto / Lote',
            requiredErrorMsg: 'Debe especificar una dirección de domicilio'
        },
        telefono: {
            name: 'telefono',
            label: 'Celular',
            requiredErrorMsg: 'Debe especificar un número de celular'
        },
        email: {
            name: 'email',
            label: 'Email',
            requiredErrorMsg: 'Debe especificar un email'
        },
        nombre_contacto_emergencia: {
            name: 'nombre_contacto_emergencia',
            label: 'Nombre',
            requiredErrorMsg: 'Debe especificar este campo'
        },
        apellido_contacto_emergencia: {
            name: 'apellido_contacto_emergencia',
            label: 'Apellido',
            requiredErrorMsg: 'Debe especificar este campo'
        },
        telefono_contacto_emergencia: {
            name: 'telefono_contacto_emergencia',
            label: 'Celular',
            requiredErrorMsg: 'Debe especificar un número de celular'
        }
    }

    const initialValues = {
        pasajeros: [
            {
                [formField.tipoDocumento.name]: 'DNI',
                [formField.numeroDocumento.name]: '',
                [formField.nombre.name]: '',
                [formField.apellido.name]: '',
                [formField.fechaNacimiento.name]: '',
                [formField.genero.name]: '',
                [formField.pais_pasajero.name]: '11',
                [formField.provincia.name]: '',
                [formField.codigoPostal.name]: '',
                [formField.ciudad.name]: '',
                [formField.calle.name]: '',
                [formField.numero.name]: '',
                [formField.opcional.name]: '',
                [formField.telefono.name]: '',
                [formField.email.name]: '',
            },
        ],
        [formField.nombre_contacto_emergencia.name]: '',
        [formField.apellido_contacto_emergencia.name]: '',
        [formField.telefono_contacto_emergencia.name]: '',
    }

    const validationSchema = Yup.object().shape({
        pasajeros: Yup.array().of(
            Yup.object().shape({
                [formField.tipoDocumento.name]: Yup.string().required(`${formField.tipoDocumento.requiredErrorMsg}`),
                [formField.numeroDocumento.name]: Yup.string().required(`${formField.numeroDocumento.requiredErrorMsg}`)
                    .when(formField.tipoDocumento.name, {
                        is: 'DNI',
                        then: Yup.string()
                            .matches(/^\d+$/, "Introduzca únicamente números")
                            .min(7, "El documento ingresado no es válido")
                            .max(8, "El documento ingresado no es válido"),
                        otherwise: Yup.string()
                            .matches(/^[a-zA-Z0-9]{6,9}$/, "El número de pasaporte debe contener entre 6 y 9 caracteres alfanuméricos")
                    }),
                [formField.nombre.name]: Yup.string().required(`${formField.nombre.requiredErrorMsg}`)
                    .matches(/^[aA-zZ\s]+$/, "Ingrese un nombre válido")
                    .max(40, "El nombre ingresado es muy largo"),
                [formField.apellido.name]: Yup.string().required(`${formField.apellido.requiredErrorMsg}`)
                    .matches(/^[aA-zZ\s]+$/, "Ingrese un apellido válido")
                    .max(40, "El valor ingresado es muy largo"),
                [formField.fechaNacimiento.name]: Yup.date()
                    .required(`${formField.fechaNacimiento.requiredErrorMsg}`)
                    .max(hoy, "Ingrese una fecha valida")
                    .min(fechaMaxima, "Ingrese una fecha valida"),
                [formField.genero.name]: Yup.string().required(`${formField.genero.requiredErrorMsg}`),
                [formField.ciudad.name]: Yup.string().required(`${formField.ciudad.requiredErrorMsg}`),
                [formField.calle.name]: Yup.string().required(`${formField.calle.requiredErrorMsg}`)
                    .matches(/^(?=[a-zA-Z0-9()_+=,.:\s-]*$)/, "Esta utilizando carácteres no válidos")
                    .min(4, 'Ingrese al menos 4 caracteres'),
                [formField.numero.name]: Yup.number().required(`${formField.numero.requiredErrorMsg}`)
                    .integer()
                    .typeError("Introduzca únicamente números"),
                [formField.telefono.name]: Yup.string()
                    .test('telefono', "El celular ingresado no es válido",
                        (value) => {
                            if (typeof value === "string")
                                return (isValidPhoneNumber(value))
                            else return false
                        }
                    ),
                [formField.email.name]: Yup.string().required(`${formField.email.requiredErrorMsg}`)
                    .email("Ingrese un email válido"),
            })
        ),
        [formField.nombre_contacto_emergencia.name]: Yup.string().required(`${formField.nombre_contacto_emergencia.requiredErrorMsg}`)
            .matches(/^[aA-zZ\s]+$/, "Ingrese un nombre válido")
            .max(40, "El nombre ingresado es muy largo"),
        [formField.apellido_contacto_emergencia.name]: Yup.string().required(`${formField.apellido_contacto_emergencia.requiredErrorMsg}`)
            .matches(/^[aA-zZ\s]+$/, "Ingrese un apellido válido")
            .max(40, "El valor ingresado es muy largo"),
        [formField.telefono_contacto_emergencia.name]: Yup.string()
            .test('telefono', "El celular ingresado no es válido",
                (value) => {
                    if (typeof value === "string")
                        return (isValidPhoneNumber(value))
                    else return false
                }
            ),
    })

    return {formField, initialValues, validationSchema}
}

export {ClienteModel};
