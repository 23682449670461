const productKeysMatch = {
    "vehiculo": "AUTOMOTOR",
    "motovehiculo": "MOTOVEHICULO",
    "hogar": "HOGAR",
    "microseguros": "MICROSEGUROS",
    "asistenciaViajero": "ASISTENCIAVIAJERO"
}

/**
 * Generates a new object with all the products from _allProducts and _userProducts
 * if a product is not in _userProducts, it will be added.
 * It also checks if the product has a discount and a commission politic assigned.
 * If not, it will not be added.
 *
 * Future improvement: Check if the product is active on each the company
 * @param _userProducts
 * @param _allProducts
 * @param _userDiscounts
 * @param _userCommissions
 * @returns {*}
 */
const productsGeneratorWithCheck = (_userProducts, _allProducts, _userDiscounts, _userCommissions) => {
    for (const key in _allProducts) {
        // Check if the product has a discount politic assigned
        let hasDiscountPolitic = false;
        for (const aseguradora in _userDiscounts) {
            if (_userDiscounts[aseguradora].hasOwnProperty(productKeysMatch[key])) {
                hasDiscountPolitic = true;
                break;
            }
        }

        // Check if the product has a commission politic assigned
        let hasCommissionPolitic = false;
        for (const aseguradora in _userCommissions) {
            if (_userCommissions[aseguradora].hasOwnProperty(productKeysMatch[key])) {
                hasCommissionPolitic = true;
                break;
            }
        }

        // If the product is not in _userProducts, it will be added
        if (!_userProducts.hasOwnProperty(key) && hasDiscountPolitic && hasCommissionPolitic) {
            _userProducts[key] = _allProducts[key];
        }
    }

    return _userProducts;
}

export {productsGeneratorWithCheck};