import React, {useContext, useEffect, useRef, useState} from 'react';
import './FormRenderer.css';
import {CustomStepper} from "../../layouts/CustomStepper";
import {Form, Formik} from "formik";
import {Button} from "../Button";
import {useIsMount} from "../../modules/components/useIsMount";
import {useCurrentQuotationContext} from "../../context/CurrentQuotationContext/CurrentQuotationContext";
import {deepMerge} from "../../modules/utils/deepMergeObject";
import {CoverageComparison} from "../../features/Cotizacion/CoverageComparison";
import {Loading} from "../Loading";
import {automotorQuotationToEmissionValues} from "../../features/Forms/Automotor/AuxFunctions/automotorQuotationToEmissionValues";
import UserContext from "../../context/UserContext";
import {motovehiculoQuotationToEmissionValues} from "../../features/Forms/Motovehiculo/AuxFunctions/motovehiculoQuotationToEmissionValues";
import CotizarContext from "../../context/CotizarContext";

function FormRenderer(props) {
    const {handleSubmit, handleBack, isLoading, doNotDisplayNextButton = [], product, performReset, clientsFieldName, downloadPdfJsonTemplate} = props;
    const formRef = useRef();
    const isMount = useIsMount();
    const {
        finalValues,
        selectedCompanies,
        cotizacionResponse,
        activeStep,
        setActiveStep,
        setCurrentFormData,
        currentFormData,
        formData,
        updatingForm,
        setUpdatingForm,
        setCoverageComparisonModalOpen,
        coverageComparisonModalOpen,
        resumeKeyConstructor
    } = useCurrentQuotationContext();
    const {userCodigosOperacion, userDetalleComisiones, userDiscounts} = useContext(UserContext);
    const {companiesDict} = useContext(CotizarContext);
    const [realActiveStep, setRealActiveStep] = useState(activeStep);

    //Se mete la info de cada paso
    const [initialValues, setInitialValues] = useState({...currentFormData.map(step => step.initialValues).reduce((acc, cur) => ({...acc, ...cur}))});
    const [validationSchema, setValidationSchema] = useState(currentFormData.map(step => step.validationSchema));
    const [stepNames, setStepNames] = useState(currentFormData.map(step => step.name));
    const [formSteps, setFormSteps] = useState(currentFormData.map(step => step.form));

    //Se actualiza cada paso en caso de cambio
    useEffect(() => {
        if (!isMount) {
            const newInitialValues = {...currentFormData.map(step => step.initialValues).reduce((acc, cur) => ({...acc, ...cur}))};
            setInitialValues(newInitialValues);
            setValidationSchema(currentFormData.map(step => step.validationSchema));
            setStepNames(currentFormData.map(step => step.name));
            setFormSteps(currentFormData.map(step => step.form));

            // This condition allows to keep the values of the quotation when changing to emission
            if (performReset) {
                // This allows to reset the form to the initial values when changing the url (product)
                formRef.current.setFormikState(state => {
                    return {
                        ...state,
                        values: newInitialValues
                    }
                });
            } else if (formRef.current.values && !updatingForm) {
                // This allows to keep values and/or add newInitialValues when selectedCompanies changes
                formRef.current.setFormikState(state => {
                    return {
                        ...state,
                        values: {
                            ...newInitialValues,
                            ...state.values,
                        },
                    }
                });
            }
        }
    }, [currentFormData, performReset, selectedCompanies]);

    /**
     * This function helps manage form navigation from quotation to emission
     *      -if activeStep is equals to the amount of quotation steps it will push the values and set the quotationSteps
     *      into currentFormData
     *      -else it will decrease a step
     **/
    const backButtonHandler = (setFieldValue) => {
        if (activeStep === formData['cotizacion'].length) { //The step that immediately follows the quotation
            //Remove the emission values
            formRef.current.setFormikState(state => {
                return {
                    ...state,
                    values: {
                        ...finalValues
                    }
                }
            })
            //Reset quotation form steps
            setCurrentFormData(formData['cotizacion']);
            setActiveStep(formData['cotizacion'].length - 2)
            //-2 because the form has "reinitiated" and the last step is "Emisión", just to show stepper label
        } else {
            handleBack(setFieldValue)
        }
    }

    useEffect(() => {
        if (!isMount) {
            if (currentFormData[0]['name'] === "#Cotización") { //Is updating due to emision

                //TODO: Fix the array merge issue
                let mergedInitialValues = deepMerge(initialValues, {...formRef.current.values});
                let clientesArrayFix = []
                Object.keys(mergedInitialValues[clientsFieldName]).forEach(key => {
                    clientesArrayFix.push(mergedInitialValues[clientsFieldName][key])
                })
                mergedInitialValues[clientsFieldName] = clientesArrayFix

                const discountType = companiesDict[mergedInitialValues['company']].descuento;

                //Use a function based on product
                if (product === "AUTOMOTOR") {
                    let descuento = mergedInitialValues?.descuentos[mergedInitialValues['company']] ? mergedInitialValues.descuentos[mergedInitialValues['company']] : 0;
                    if ((mergedInitialValues.company === "RUS" || mergedInitialValues.companyId === 2) && descuento === 15) descuento = 14.7;
                    mergedInitialValues = automotorQuotationToEmissionValues(
                        mergedInitialValues,
                        cotizacionResponse,
                        finalValues,
                        userDetalleComisiones,
                        userCodigosOperacion,
                        userDiscounts,
                        discountType,
                        descuento)
                } else if (product === "MOTOVEHICULO") {
                    let descuento = mergedInitialValues?.descuentos[mergedInitialValues['company']] ? mergedInitialValues.descuentos[mergedInitialValues['company']] : 0;
                    if ((mergedInitialValues.company === "RUS" || mergedInitialValues.companyId === 2) && descuento === 15) descuento = 14.7;
                    mergedInitialValues = motovehiculoQuotationToEmissionValues(
                        mergedInitialValues,
                        cotizacionResponse,
                        finalValues,
                        userDetalleComisiones,
                        userCodigosOperacion,
                        userDiscounts,
                        discountType,
                        descuento)
                }

                formRef.current.setFormikState(state => {
                    return {
                        ...state,
                        values: {
                            ...state.values,
                            ...mergedInitialValues
                        }
                    }
                })

                setActiveStep(activeStep + 2)
                //2 because the first step is "Cotización", just to show stepper label

                setUpdatingForm(false)
            }
        }
    }, [initialValues]);

    useEffect(() => {
        if (currentFormData[0]['name'] === "#Cotización")
            setRealActiveStep(activeStep - (formData['cotizacion'].length - 1))
        else
            setRealActiveStep(activeStep)
    }, [activeStep]);

    return (
        <>
            <div className="formRenderer__form__stepper">
                <CustomStepper labels={stepNames} activeStep={realActiveStep}/>
            </div>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema[realActiveStep]}
                validateOnBlur={false}
                innerRef={formRef}
                onSubmit={handleSubmit}
            >
                {({isSubmitting, setFieldValue}) => (
                    <Form className="formRenderer__form__container">
                        {isLoading
                            ? <Loading/>
                            : initialValues && !updatingForm &&
                            formSteps[realActiveStep]
                        }
                        <div className="formRenderer__form__buttons">
                            {realActiveStep !== 0 &&
                                <Button
                                    type="button"
                                    variant="filled"
                                    color="primary"
                                    marginX="sm"
                                    enabled={!isSubmitting}
                                    onClick={() => backButtonHandler(setFieldValue)}
                                >
                                    Atras
                                </Button>
                            }
                            {!doNotDisplayNextButton.includes(activeStep) &&
                                <Button
                                    type="submit"
                                    variant="filled"
                                    color="primary"
                                    marginX="sm"
                                    enabled={!isSubmitting}
                                >
                                    {currentFormData[realActiveStep]["stepButtonName"]}
                                </Button>
                            }
                        </div>

                        {/*
                        TODO: Review location of this component.
                            It's automotor product specific
                            Needs formikContext to work
                        */}
                        {coverageComparisonModalOpen &&
                            <CoverageComparison
                                setCoverageComparisonModalOpen={setCoverageComparisonModalOpen}
                                downloadPdfJsonTemplate={downloadPdfJsonTemplate}
                                resumeKeyConstructor={resumeKeyConstructor}
                            />
                        }
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default FormRenderer;