import React from 'react';
import './ProductDetails.css'
import {currencyFormat} from "../../../modules/utils/currencyFormat";

function ProductDetails(props) {
    const {data, productDict} = props;
    const coverageName = Object.keys(data.mas_vendido)[0];
    const coverageBestSellerData = data.mas_vendido[coverageName];

    return (
        <div className="PDetails__container">
            <p className="PDetails__title">General {productDict[props.active.product]}</p>
            <div className="PDetails__productDetails">
                <p className="PDetails__lineText">Más vendida: <span>{coverageName}</span></p>
                <p className="PDetails__lineText">Compañía: <span>{coverageBestSellerData.company}</span></p>
                {data.suma_asegurada !== null && data.suma_asegurada > 0 &&
                    <p className="PDetails__lineText">
                        Suma promedio: <span>${currencyFormat(Math.round(data.suma_asegurada))}</span>
                    </p>
                }
                {data.pais && data.pais !== '' &&
                    <p className="PDetails__lineText">País mas elegido: <span>{data.pais}</span></p>
                }
                <p className="PDetails__lineText">Cuota
                    promedio: <span>${currencyFormat(Math.round(data.premio))} </span></p>
            </div>
        </div>
    );
}

export default ProductDetails;