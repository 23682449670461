import React from 'react';
import './RadioButton.css';
import { ReactComponent as CheckIcon } from '../../assets/icons/ic_check.svg';
import { ReactComponent as StarIcon } from '../../assets/icons/ic_star.svg';

function RadioButton(props) {
    const {id, callback, icon = "CIRCLE", selected} = props;

    const handleClick = () => {
        callback(id)
    }

    if (icon === "STAR") {
        return (
            <div onClick={handleClick}>
                <StarIcon className={selected ? "radioButton__star__icon--selected" : "radioButton__star__icon"}/>
            </div>
        );
    } else {
        return (
            <div className={`radioButton ${selected && "radioButton--selected"}`} onClick={handleClick}>
                {selected && <CheckIcon className="radioButton__icon"/>}
            </div>
        );
    }
}

export default RadioButton;