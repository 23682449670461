import React from 'react';
import './NumberedList.css';

function NumberedList(props) {
    const {items, color} = props;
    return (
        <ol className="NList__container">
            {items.map((item, index) => (
                <li key={index} className="NList__item">
                    <div className={`NList__itemIndexContainer NList__itemIndexContainer--${color}`}>
                        <span className="NList__itemIndexNumber">
                            {index + 1}
                        </span>
                    </div>
                    <span>{item}</span>
                </li>
            ))}
        </ol>
    );
}

export default NumberedList;