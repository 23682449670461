import moment from "moment";
import {VehiculoModel} from "../FormStages/Cotizar/Vehiculo/VehiculoModel";

const advancedConfigRUS = (recotizarValues, values, userCodigosOperacion, locationCodes, isPromotedCompany) => {
    const {initialValues: cotizarVehiculoInitialValues} = VehiculoModel();

    values['fecha'] = moment().format('YYYY-MM-DD');
    if (values['configuracionAvanzada']['RUS']) {
        if (recotizarValues) {
            values['configuracionAvanzada']['RUS']['vigenciaDesde'] = moment().format('YYYY-MM-DD');
            values['configuracionAvanzada']['RUS']['vigenciaHasta'] = moment().add(1, "Y").format("YYYY-MM-DD");
        }
        if (!isPromotedCompany){
            values['configuracionAvanzada']['RUS'].codigos = userCodigosOperacion['RUS'][0];
        }
        values['configuracionAvanzada']['RUS'].codRUSLocalidad = `${locationCodes.CodigoLocalidadRUS}`
    } else {
        values['configuracionAvanzada']['RUS'] = {
            ...cotizarVehiculoInitialValues['configuracionAvanzada']['RUS'],
            ...(!isPromotedCompany && {
                "codigos": userCodigosOperacion['RUS'][0],
            }),
            "vigenciaDesde": moment().format('YYYY-MM-DD'),
            "vigenciaHasta": moment().add(1, "Y").format("YYYY-MM-DD"),
            "codRUSLocalidad": `${locationCodes.CodigoLocalidadRUS}`,
        };
    }
    values['configuracionAvanzada']['RUS']['isPromotedCompany'] = isPromotedCompany;
}

export {advancedConfigRUS}