import React from 'react';
import './RedesColumn.css';
import TextLink from "../../../../components/TextLink/TextLink";

function RedesColumn(props) {
    const {row, setModalOpen} = props;
    const redes = row['redes_sociales'];

    const handleOpenModal = () => {
        setModalOpen({
            modalName: "Redes",
            values: {row, redes}
        })
    }

    return (
        <>
            {redes.length === 0
                ? <p className="redesModal__text">Sin redes</p>
                : <TextLink
                    size={"sm"}
                    action={() => handleOpenModal()}
                >
                    Ver Redes
                </TextLink>
            }
        </>
    );
}

export default RedesColumn;