import { useHistory } from "react-router-dom";
import { Button } from '../../../../components/Button';
import './ButtonContainer.css';

const ButtonContainer = () => {
    const history = useHistory();

    return (
        <div className="MisAseguradoras__buttonContainer--container">
            <div className="MisAseguradoras__buttonContainer--button-wrapper">
                <Button
                    variant="filled"
                    color="primary"
                    borderRadius="medium"
                    fullWidth fullHeight
                    enabled
                    onClick={() => {
                        history.push('/u/m/alta/compania')
                    }}
                >
                    <div className="MisAseguradoras__button--content">
                        Solicitar codigo
                    </div>
                </Button>
            </div>
            <div className="MisAseguradoras__buttonContainer--button-wrapper">
                <Button
                    variant="filled"
                    color="primary"
                    borderRadius="medium"
                    fullWidth fullHeight
                    contentIsText={false}
                    enabled
                    onClick={() => {
                        history.push('/u/m/misdocumentos')
                    }}
                >
                    <div className="MisAseguradoras__button--content">
                        Mis documentos
                    </div>
                </Button>
            </div>
        </div>
    );
}

export default ButtonContainer;