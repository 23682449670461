import React from 'react';
import './Collection.css'
import useWindowDimensions from "../../../../modules/utils/useWindowDimensions";

function Collection(props) {
    const {title, amount, percent, timeUnit, isMaster} = props;
    const {width} = useWindowDimensions();

    const percentMessage = () => {
        let message;
        if (percent < 0) {
            message = `${Math.round(percent) * (-1)}% menos que ${timeUnit}`
        } else {
            if (percent <= 1000) message = `${Math.round(percent)}% más que ${timeUnit}`
            else message = `+1000% más que ${timeUnit}`
        }
        return message
    }

    return (
        <div className={`Collection__container ${!isMaster && 'polkista'}`}>
            <p className={`Collection__title ${!isMaster && 'polkista'}`}>{title}</p>
            <p className="Collection__amount">${amount}</p>
            <p className="Collection__percent">{percentMessage()}</p>
        </div>
    );
}

export default Collection;