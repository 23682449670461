import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { ReactComponent as PlusCircledIcon } from "../../../assets/icons/ic_plus-circled.svg";
import { DownloadArrow } from "../../../components/icons/DownloadArrow";
import AuthContext from "../../../context/AuthContext";
import UserContext from "../../../context/UserContext";
import { downloadFile } from "../../../modules/utils/downloadFile";
import "./MisDocumentosRow.css";
import showNotification from "../../../modules/utils/showNotification";
import { format } from "date-fns";

const MisDocumentosRow = ({ rowItems, compact }) => {
    const { authToken } = useContext(AuthContext);
    const { idPolkista } = useContext(UserContext);
    const [fileData, setFileData] = useState({ ...rowItems });
    const [link, setLink] = useState(null);
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (rowItems.link) {
            const parts = rowItems.link.split('/');
            const filename = parts.pop();
            const path = parts.join('/') + "/";
            setLink({ filename, path });
        }
        setFileData({ ...rowItems });
    }, [rowItems]);

    const uploadDocument = async (file, fileContent) => {
        try {
            const res = await axios.post(process.env.REACT_APP_BACK_SERVER + '/upload_document',
                {
                    id_polkista: idPolkista,
                    id_documento: fileData.title,
                    nombre: file.name,
                    content: fileContent,
                    extension: file.name.split('.').pop(),
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`,
                    }
                });
            setFileData((prevData) => ({ ...prevData, name: file.name, modificationDate: format(new Date(), 'dd/MM/yyyy') }));
            showNotification({
                autoClose: 4000,
                color: 'green',
                status: 'success',
                title: 'Archivo subido correctamente',
                message: `El archivo ${file.name} se ha subido correctamente.`
            });
            return res.data
        } catch (error) {
            showNotification({
                autoClose: 4000,
                color: 'red',
                status: 'error',
                title: 'Error al subir el archivo',
                message: `Hubo un error al subir el archivo.`
            });
            return error
        }
    }

    const handleDocSelect = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            const file = event.target.files[0];
            reader.addEventListener('load', () => {
                uploadDocument(file, reader.result);
            }
            );
            reader.readAsDataURL(file);

            if (fileInputRef.current) {
                fileInputRef.current.value = null;
            }
        }
    };

    if (compact) {
        return (
            <div className="MisDocumentosRow__wrapper compact">
                <div className="MisDocumentosRow__container compact">
                    <div className="MisDocumentosRow__documentTitle">
                        {fileData.title}
                    </div>
                    <div className="MisDocumentosRow__icons">
                        {(fileData.name || fileData.modificationDate) && link &&
                            <a onClick={() => downloadFile(`${link.path}${link.filename}`, fileData.name)} >
                                <DownloadArrow />
                            </a>
                        }
                        <label>
                            <input
                                name="file"
                                type="file"
                                ref={fileInputRef}
                                hidden
                                onChange={(event) => handleDocSelect(event)}
                                accept="image/png, image/jpeg, .pdf"
                            />
                            <PlusCircledIcon />
                        </label>
                    </div>
                </div>
                {fileData.name &&
                    <div className="MisDocumentosRow__documentName--link">
                        {fileData.name}
                    </div>
                }
                {fileData.modificationDate &&
                    <div className="MisDocumentosRow__modificationDate compact">
                        Última modificación: {fileData.modificationDate}
                    </div>
                }
            </div>

        );
    } else {
        return (
            <div className="MisDocumentosRow__wrapper">
                <div className="MisDocumentosRow__container">
                    <div className="MisDocumentosRow__documentTitle">
                        {fileData.title}
                    </div>
                    <div className="MisDocumentosRow__documentName--link">
                        {fileData.name || ""}
                    </div>
                    <div className="MisDocumentosRow__modificationDate">
                        {fileData.modificationDate ? `Última modificación: ${fileData.modificationDate}` : ""}
                    </div>
                    <div className="MisDocumentosRow__icons">
                        {(fileData.name || fileData.modificationDate) && link &&
                            <a onClick={() => downloadFile(`${link.path}${link.filename}`, fileData.name)} >
                                <DownloadArrow />
                            </a>
                        }
                        <label>
                            <input
                                name="file"
                                type="file"
                                ref={fileInputRef}
                                hidden
                                onChange={(event) => handleDocSelect(event)}
                                accept="image/png, image/jpeg, .pdf"
                            />
                            <PlusCircledIcon />
                        </label>
                    </div>
                </div>
            </div>
        );
    }
}

export default MisDocumentosRow;