import React, {useContext, useState} from 'react';
import './RegistroUsuario.css'
import axios from "axios";
import AuthContext from "../../../context/AuthContext";
import {FormCard} from "../../../components/FormCard";
import {ErrorModal} from "../../../layouts/ErrorModal";
import {Form, Formik} from "formik";
import {Button} from "../../../components/Button";
import {InformacionModel} from "./FormStages/Informacion/InformacionModel";
import showNotification from "../../../modules/utils/showNotification";
import InformacionForm from "./FormStages/Informacion/InformacionForm";
import {CustomModal} from "../../../components/CustomModal";
import {decrypt} from "../../../modules/utils/encrypter";
import {TermsAndConditions} from "../../Landing/TermsAndConditions";
import {formatPhoneNumberIntl} from "react-phone-number-input";
import RegistroSuccess from "./FormStages/RegistroSuccess/RegistroSuccess";
import {Loading} from "../../../components/Loading";

const moment = require('moment');

function RegistroUsuario(props) {
    const {masterInvitationCode} = props;
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [errorModalContent, setErrorModalContent] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [tycModalOpen, setTycModalOpen] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const {authToken, mail} = useContext(AuthContext);

    const {initialValues: informacionInitialValues, validationSchema: informacionValidationSchema} = InformacionModel();

    async function _submitForm(values, actions) {
        let url = process.env.REACT_APP_BACK_SERVER + '/newRegistrarUsuario';

        let datosMaster = {}

        if (!masterInvitationCode) {
            datosMaster["id_codigo_aseguradora"] = [11, 3] //Sancor code, RUS code
            datosMaster["id_polkista_master"] = 21
            datosMaster["matricula"] = 81960
        } else {
            datosMaster = {...decrypt(masterInvitationCode)}
        }

        const jsonData = {
            "pais": "Argentina",
            "nombre": values['nombre'],
            "apellido": values['apellido'],
            "DNI": values['DNI'],
            "CUIT": values['CUIT'],
            "nacimiento": moment(values['nacimiento']).format('YYYY-MM-DD'),
            "l_nacimiento": values['l_nacimiento'],
            "nacionalidad": values['nacionalidad'],
            "codigoArea": formatPhoneNumberIntl(values['telefono']).split(" ")[1],
            "telefono": values['telefono'],
            "sexo": values['sexo'],
            "ocupacion": values['ocupacion'],
            "IVA": values['IVA'],
            "matricula": values['matricula'] === "" ? values['matricula'] : parseInt(values['matricula']),
            "domicilio": {
                "calle": values['calle'],
                "numero": values['numeroCalle'],
                "opcional": values['opcional']
            },
            "cbu": values['cbu'],
            "codigoPostal": values['codigoPostal'],
            "localidad": values['localidad'],
            "provincia": values['provincia'],
            "email": mail,
            "datos_master": datosMaster
        }

        axios.post(url, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            }
        }).then(() => {
            setActiveStep(activeStep + 1);
        }).catch(err => {
            setErrorModalContent({
                title: "Hubo un problema en el registro, por favor vuelva a intentarlo más tarde.",
                typeError: err.response?.data?.detail?.code || "Ha ocurrido un error en la comunicación con el servicio",
                suggestedActions: ["Revisá que la información ingresada sea correcta", "Reintentá el proceso una vez más", "Si el problema persiste ponete en contacto con el área de soporte"],
                detailedError: err.response?.data?.detail?.message || "Sin datos del error",
            })
            setErrorModalOpen(true);
        }).finally(() => {
            setIsLoading(false);
            actions.setSubmitting(false);
        });
    }


    function _handleSubmit(values, actions) {
        actions.setSubmitting(true);

        if (values.tieneNumeroProductor === 'false') {
            values.numeroProductor = "221209";
        }
        if (values.tycAccepted) {
            setIsLoading(true);
            _submitForm(values, actions);
        } else {
            showNotification({
                autoClose: 3000,
                color: 'red',
                status: 'error',
                title: 'Error de registro.',
                message: 'Debe leer y aceptar los Términos y Condiciones'
            });
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    }

    const getFormComponent = () => {
        if (isLoading) {
            return (
                <div className='registroUsuario__loading__container'>
                    <Loading/>
                </div>
                    );
        } else {
            if (activeStep === 0) {
                return <InformacionForm setTycModalOpen={setTycModalOpen}/>;
            } else {
                return <RegistroSuccess/>;
            }
        }
    }

    return (
        <div className="registroUsuario__form__container">
            {activeStep === 0 &&
            <p className="registroUsuario__success__title">Formulario de registro nuevo <span>Polkista</span></p>
            }
            <FormCard>
                <div className="registroUsuario__form__content">
                    <Formik
                        initialValues={informacionInitialValues}
                        validationSchema={informacionValidationSchema}
                        validateOnBlur={false}
                        onSubmit={_handleSubmit}
                    >
                        {({isSubmitting}) => (
                            <Form>
                                {getFormComponent()}

                                {activeStep < 1 &&
                                <div className="registroUsuario__form__buttons">
                                    <Button
                                        type="submit"
                                        variant="filled"
                                        color="primary"
                                        marginX="sm"
                                        enabled={!isSubmitting}
                                    >
                                        Registrar
                                    </Button>
                                </div>
                                }

                            </Form>
                        )}
                    </Formik>
                </div>
            </FormCard>

            {errorModalOpen &&
            <ErrorModal
                closeAction={() => {
                    setErrorModalOpen(false)
                    setActiveStep(0)
                }}
                title={errorModalContent.title}
                typeError={errorModalContent.typeError}
                suggestedActions={errorModalContent.suggestedActions}
                detailedError={errorModalContent.detailedError}
            />
            }

            {tycModalOpen && (
                <CustomModal fullWidth={true}>
                    <div className="registroUsuario__tyc__modal">
                        <FormCard>
                            <TermsAndConditions setTycModalOpen={setTycModalOpen}/>
                        </FormCard>
                    </div>
                </CustomModal>
            )}
        </div>
    );
}

export default RegistroUsuario;