import moment from "moment";
import {VehiculoModel} from "../FormStages/Cotizar/Vehiculo/VehiculoModel";

// TODO: Los 3 advancedConfig deberian estar parametrizado por compañia
const advancedConfigZurich = (recotizarValues, values, userCodigosOperacion, locationCodes, isPromotedCompany) => {
    const {initialValues: cotizarVehiculoInitialValues} = VehiculoModel();

    values['fecha'] = moment().format('YYYY-MM-DD');
    if (values['configuracionAvanzada']['Zurich']) {
        if (recotizarValues) {
            values['configuracionAvanzada']['Zurich']['vigenciaDesde'] = moment().format('YYYY-MM-DD');
            values['configuracionAvanzada']['Zurich']['vigenciaHasta'] = moment().add(1, "Y").format("YYYY-MM-DD");
        }
        if (!isPromotedCompany){
            values['configuracionAvanzada']['Zurich'].codigos = userCodigosOperacion['Zurich'][0]
        }
        values['configuracionAvanzada']['Zurich'].codZurichLocalidad = locationCodes.CodigoLocalidadZurich

    }
    else {
        values['configuracionAvanzada']['Zurich'] = {
            ...cotizarVehiculoInitialValues['configuracionAvanzada']['Zurich'],
            ...(!isPromotedCompany && {
                "codigos": userCodigosOperacion['Zurich'][0]
            }),
            "vigenciaDesde": moment().format('YYYY-MM-DD'),
            "vigenciaHasta": moment().add(1, "Y").format("YYYY-MM-DD"),
            "codZurichLocalidad": locationCodes.CodigoLocalidadZurich,
        };

    }
    values['configuracionAvanzada']['Zurich']['isPromotedCompany'] = isPromotedCompany;
}

export {advancedConfigZurich}