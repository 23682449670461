import {currencyFormat} from "./currencyFormat";

function replacePercentage(displayName, franquicia) {
    const partes = displayName.split(' ');

    for (let i = 0; i < partes.length; i++) {
        const parte = partes[i];
        if (parte.includes('%')) {
            partes[i] = `$${currencyFormat(franquicia)}`;
            break;
        }
    }

    return partes.join(' ');
}

const TR_COVERAGES = [20,18,13,9,7,"T3","T20","T13","T31","T34","T32","T14","T9","T25","T24", "T37"];
/**
 * This function replaces the amount of the coverage name with the value of franquicia on response
 * @param _company company name
 * @param _coberturasProductInfo object with the info of all the coverages
 * @param _coverage coverage object {id, franquicia, nombre, ...}
 * @returns {*} string with either same name or with replaced amount
 */
const todoRiesgoAmountReplacer = (_company, _coberturasProductInfo, _coverage) => {
    const {id: _covId, franquicia: _franquicia, nombre: _initialName} = _coverage;

    const _coverageInfo = _coberturasProductInfo?._company?.hasOwnProperty(_covId) || false;
    if (!_coverageInfo) return (_initialName);

    let displayName = _initialName;

    if (TR_COVERAGES.includes(_covId) && _franquicia && _franquicia > 0) {
        if (_company === "Sancor") {
            let partes = displayName.split("$");
            let partesDespuesDelMonto = partes[1].split(" ");
            partesDespuesDelMonto[0] = `$${currencyFormat(_franquicia)}`;
            partes[1] = partesDespuesDelMonto.join(" ");
            return partes.join("");
        } else { //RUS
            return replacePercentage(displayName, _franquicia)
        }
    } else {
        return displayName
    }
}

export {todoRiesgoAmountReplacer};