import React, {useContext, useEffect, useMemo, useState} from 'react';
import './CompanySelectorModal.css';
import {PopUpCardModal} from "../../../layouts/PopUpCardModal";
import {Button} from "../../../components/Button";
import UserContext from "../../../context/UserContext";
import CotizarContext from "../../../context/CotizarContext";
import {useCurrentQuotationContext} from "../../../context/CurrentQuotationContext/CurrentQuotationContext";
import {MasterSelector} from "./MasterSelector";
import AuthContext from "../../../context/AuthContext";
import PolkistaSelector from "./PolkistaSelector/PolkistaSelector";

// TODO: Considero que está raro. Debe existir una forma mas limpia de hacerlo.
const SANCOR_DATA = {id: 1, selected: true}
const RUS_DATA = {id: 2, selected: true}
const ZURICH_DATA = {id: 3, selected: true}
const TERRAWIND_DATA = {id: 4, selected: true}
const FEDERACION_PATRONAL_DATA = {id: 5, selected: true}

const companiesData = {
    "Sancor": SANCOR_DATA,
    "RUS": RUS_DATA,
    "Zurich": ZURICH_DATA,
    "TerraWind": TERRAWIND_DATA,
    "Federacion_Patronal": FEDERACION_PATRONAL_DATA,
}

const productsNameDict = {
    "automotor": "Automotor",
    "hogar": "Hogar",
    "motovehiculo": "Motovehículo",
    "microseguro": "Microseguros",
    "asistencia_viajero": "Asistencia al viajero",
}

function CompanySelectorModal(props) {
    const {closeAction, buttonAction, productBeingModified} = props;
    const productBeingModifiedFixCar = productBeingModified === "automotor" ? "vehiculo" : productBeingModified;
    const [selectedCompaniesCompleteInfo, setSelectedCompaniesCompleteInfo] = useState(null);
    const [openMoreInfo, setOpenMoreInfo] = useState(false);
    const {isMaster} = useContext(AuthContext);
    const {userCompanies, userCompaniesToPromote} = useContext(UserContext);
    const {companiesDict} = useContext(CotizarContext);
    const hasPromotedCompanies = useMemo(() => userCompaniesToPromote.some(company => companiesDict[company].products.find(product => product.value === productBeingModifiedFixCar)), [userCompaniesToPromote, productBeingModified]);
    const {selectedCompaniesToPromote, setSelectedCompaniesToPromote} = useCurrentQuotationContext();


    const handlePromoSelection = (companyKey, selected) => {
        setSelectedCompaniesToPromote(prevSelectedKeys => {
            if (prevSelectedKeys.includes(companyKey)) {
                return prevSelectedKeys.filter(key => key !== companyKey);
            } else {
                return [...prevSelectedKeys, companyKey];
            }
        });
    };

    const handleSelection = (idx, selected) => {
        //Modify the component state
        const newStateArray = [...selectedCompaniesCompleteInfo];
        newStateArray[idx].selected = selected;

        // Update the state
        setSelectedCompaniesCompleteInfo(newStateArray);
    }

    const checkSelection = () => {
        let isAtLeastOneSelected = false;
        let ownSelectedCompaniesQuantity = 0
        let hasMaxSelected = false;
        if (selectedCompaniesCompleteInfo) {
            selectedCompaniesCompleteInfo.forEach(company => {
                if (company.selected) {
                    isAtLeastOneSelected = true
                    ++ownSelectedCompaniesQuantity
                }
            })
            // hasMaxSelected = 4 <= ownSelectedCompaniesQuantity + selectedCompaniesToPromote.length

            return isAtLeastOneSelected && !hasMaxSelected;
        } else return false
    }

    useEffect(() => {
        const selectedCompaniesSaved = JSON.parse(localStorage.getItem('companiesByProduct'));
        let initialCompanies = userCompanies.filter(company => {
            const companyProducts = companiesDict[company].products;
            return companyProducts.some(product => product.value === productBeingModifiedFixCar);
        }).map(company => companiesData[company]);

        if (selectedCompaniesSaved) {
            if (selectedCompaniesSaved[productBeingModified]) {
                initialCompanies.map(company => {
                    company.selected = selectedCompaniesSaved[productBeingModified][company.id]
                })
            }

            //TODO: Revisar si puedo llevar el set afuera del if y borrar el else.
            setSelectedCompaniesCompleteInfo(initialCompanies)
        } else {
            setSelectedCompaniesCompleteInfo(initialCompanies)
        }
    }, [productBeingModified]);


    return (
        <PopUpCardModal
            title={<h1 className="csm__title">Elegí tus compañías para el
                producto {productsNameDict[productBeingModified] ?? productBeingModified}</h1>}
            body={hasPromotedCompanies && isMaster ?
                <MasterSelector selectedCompaniesCompleteInfo={selectedCompaniesCompleteInfo}
                                selectedCompanyKeys={selectedCompaniesToPromote}
                                handleSelection={handleSelection}
                                handlePromoSelection={handlePromoSelection}
                                openMoreInfo={openMoreInfo}
                                setOpenMoreInfo={setOpenMoreInfo}
                                productBeingModified={productBeingModified}/>
                :
                <PolkistaSelector selectedCompaniesCompleteInfo={selectedCompaniesCompleteInfo}
                                  handleSelection={handleSelection}/>
            }
            closeAction={closeAction}
            footer={
                <div className="csm__button__container">
                    <Button variant="filled"
                            color="primary"
                            type="button"
                            enabled={checkSelection()}
                            onClick={() => buttonAction(selectedCompaniesCompleteInfo, selectedCompaniesToPromote)}>
                        Aceptar
                    </Button></div>}
        />
    );
}

export default CompanySelectorModal;