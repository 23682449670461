/**
 * Takes an object with companies (usually companiesDict) and returns
 * an object with products and companies that allows them
 * @param companiesDict - Object with companies and their information, products key required
 * @param companies - Optional. If provided, only the companies in this array will be returned
 * @param companiesCodes - Optional. If provided, the companies will be returned as objects with codes (idCodigosAseguradoras, codigoProductor) and group
 * @returns {{}}
 * @example outputObj
 * {
 *     "vehiculo": ["Sancor", "RUS"],
 *     "microseguros": ["Sancor"],
 *     "hogar": ["Sancor"]
 * }
 * @example outputObj with companiesCodes
 * {
    "vehiculo": [
        { "value": "2", "label": 221209, "group": "Sancor"},
        { "value": "1", "label": 223322, "group": "Sancor" },
        { "value": "3", "label": 3592, "group": "RUS" }
    ],
    "microseguros": [
        { "value": "2", "label": 221209, "group": "Sancor"  },
        { "value": "1", "label": 223322, "group": "Sancor" }
    ],
    "hogar": [
        { "value": "2", "label": 221209, group": "Sancor" },
        { "value": "1", "label": 223322, "group": "Sancor" }
    ]
}
 */
function getCompaniesByProduct(companiesDict, companies = [], companiesCodes = null) {
    const outputObj = {};

    for (const company in companiesDict) {
        if (companies.length > 0 && !companies.includes(company)) {
            continue;
        }

        const products = companiesDict[company].products;

        for (const product of products) {
            const {value} = product;

            if (companiesCodes) {
                const companyCodeObjs = companiesCodes.filter((com) => com.group === company);
                if (outputObj[value]) {
                    outputObj[value] = outputObj[value].concat(companyCodeObjs);
                } else {
                    outputObj[value] = companyCodeObjs;
                }
            } else {
                if (outputObj[value]) {
                    outputObj[value].push(company);
                } else {
                    outputObj[value] = [company];
                }
            }
        }
    }

    return outputObj;
}

export {getCompaniesByProduct};