export function getBenefitsList(companyName) {
    switch (companyName) {
        case 'Sancor':
            return [
                {
                    title: "Asistencia en viaje",
                    description: "servicio de asistencia en viaje al vehículo y a las personas."
                },
                {
                    title: "Asistencia legal",
                    description: "en caso de siniestro, un equipo de profesionales analiza la situación para brindarte asesoramiento legal de acuerdo al caso."
                },
                {
                    title: "Extensión de las coberturas a países limítrofes.",
                    description: ""
                },
                {
                    title: "Accidentes personales",
                    description: "reintegro por muerte o invalidez total del conductor y/o acompañantes, en caso de accidente."
                },
                {
                    title: "Accidentes Personales Familiares",
                    description: "incluye una póliza específica que cubre a los familiares transportados hasta el 3er grado de consanguinidad/afinidad (actual exclusión de la cobertura de Responsabilidad Civil Automotor tradicional). Reintegra los gastos médicos y farmacéuticos en caso de accidente con el vehículo asegurado, y también indemniza en caso de muerte o invalidez total o parcial permanente."
                },
                {
                    title: "Segunda Opinión Médica",
                    description: "servicio disponible en caso de enfermedades graves y/o intervenciones quirúrgicas de alta complejidad."
                },
                {
                    title: "Llamando al Doctor",
                    description: "servicio de videollamadas donde profesionales médicos atenderán consultas sobre salud de manera gratuita, evitando esperas y traslados. Se pueden consultar diferentes especialidades: Medicina general, Pediatría, Ginecología y Obstetricia."
                }
            ];
        case 'Río Uruguay':
            return [];
        case 'Zurich':
            return [
                {
                    title: "Zurich con Zurich",
                    description: "En caso de siniestro entre dos vehículos asegurados por Zurich, la compañía indemnizará también al asegurado culpable del siniestro. Este beneficio solo aplica a la cobertura de daños."
                },
                {
                    title: "Zurich Weather",
                    description: "Protección integral ante eventos climáticos que incluye daños parciales y totales por granizo, inundación, desbordamiento, terremoto y caída de árboles (planes Premium)."
                },
                {
                    title: "Fast Track",
                    description: "Si te roban las ruedas o si se te rompen cristales o cerraduras, podés utilizar nuestra amplia red de proveedores, que repone o repara cualquier eventualidad en el mismo día."
                },
                {
                    title: "ZuriHelp",
                    description: "6 eventos por año hasta 2000 km."
                },
                {
                    title: "Volkswagen y Audi",
                    description: "Se trata de una cobertura diferencial que incluye reparaciones en talleres de concesionarios oficiales, servicio de asistencia en ruta con kilometraje ilimitado y el sistema digital de siniestros Z-Track, entre otros."
                },
                {
                    title: "Inspección Digital",
                    description: "Proceso de inspección digital autogestionable para el cliente. Esto agiliza el proceso de emisión y te brinda mayor comodidad."
                }
            ];
        case 'Federación Patronal':
            return [
                {
                    title: "Cobertura entre asegurados",
                    description: "Disfruta de una cobertura Todo Riesgo sin franquicia en caso de colisión con otro asegurado de Federación Patronal."
                },
                {
                    title: "Cobertura de amplia suscripción",
                    description: "Benefíciate de una póliza accesible y completa."
                },
                {
                    title: "Descuentos para nuevos clientes",
                    description: "Atractivos descuentos para quienes se unen a nuestra aseguradora."
                },
                {
                    title: "Servicio de gestoría",
                    description: "Asistencia administrativa integral para tus trámites."
                },
                {
                    title: "Asistencia penal",
                    description: "Protección legal en situaciones de responsabilidad penal."
                },
                {
                    title: "Asistencia por urgencias mecánicas",
                    description: "Ayuda inmediata en caso de averías en tu vehículo."
                },
            ];
        default:
            return [];
    }
}