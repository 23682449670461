import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useFormikContext} from "formik";
import Tabs from "../../../../../../components/Tabs/Tabs";
import {useCurrentQuotationContext} from "../../../../../../context/CurrentQuotationContext/CurrentQuotationContext";
import CotizarContext from "../../../../../../context/CotizarContext";
import {ReactComponent as PassengerIcon} from "../../../../../../assets/icons/ic_user.svg";
import {GenericQuotationResponseTab} from "../../../../../Cotizacion/GenericQuotationResponseTab";
import {format} from "date-fns";
import ModalIndicator from "../../../../../../components/ModalIndicator/ModalIndicator";
import {Message, Title} from "../EntradaCotizar/infoRangeTexts";
import UserContext from "../../../../../../context/UserContext";
import AsistenciaViajeroContext from "../../../../../../context/Products/AsistenciaViajeroContext";

function CotizarSuccessView(props) {
    const {submitForm} = props;
    const {addCoverageToCart, cotizacionResponse} = useCurrentQuotationContext();
    const {companiesDict} = useContext(CotizarContext)
    const {isMobile} = useContext(UserContext);
    const {minDatesWarning, setMinDatesWarning} = useContext(AsistenciaViajeroContext)
    const infoRangeRef = useRef()
    const {values} = useFormikContext();
    const [displayConfiguracionAvanzada, setDisplayConfiguracionAvanzada] = useState(false);
    const formattedVigenciaDesde = useMemo(() => {
        let formatted = "";
        if (typeof values.vigenciaDesde !== "string") {
            formatted = format(values.vigenciaDesde, 'dd/MM/yyyy')
        } else {
            formatted = values.vigenciaDesde
        }
        return formatted
    }, [values.vigenciaDesde])
    const formattedVigenciaHasta = useMemo(() => {
        let formatted = "";
        if (typeof values.vigenciaHasta !== "string") {
            formatted = format(values.vigenciaHasta, 'dd/MM/yyyy')
        } else {
            formatted = values.vigenciaHasta
        }
        return formatted
    }, [values.vigenciaHasta])
    const passengersNumber = values.pasajeros.length

    function getRowData(product) {
        return {
            nombre: product.name,
            ...product
        }
    }

    useEffect(() => {
        setMinDatesWarning(prev => ({
            ...prev,
            modal: false
        }))
    }, []);

    return (<div className="automotor__formStep__container">
            <div className="asistenciaViajero__cotSuccess__titleContainer">
                <p>{values.nombrePais || ""}</p>
                <p ref={infoRangeRef}
                   className={`asistenciaViajero__cotSuccess__titleContainer--highlighted ${minDatesWarning.icon && '--warning'}`}
                   onMouseEnter={() => {
                       if (minDatesWarning.icon) setMinDatesWarning({
                           ...minDatesWarning,
                           modal: true,
                           fadeOut: false
                       })
                   }}
                   onMouseLeave={() => {
                       if (minDatesWarning.icon) setMinDatesWarning({
                           ...minDatesWarning,
                           modal: false,
                           fadeOut: false
                       })
                   }}
                   onClick={() => {
                       if (minDatesWarning.icon) setMinDatesWarning(prev => ({
                           ...prev,
                           modal: !prev.modal,
                           fadeOut: isMobile
                       }))
                   }}>{formattedVigenciaDesde} - {formattedVigenciaHasta}</p>
                <div className="asistenciaViajero__cotSuccess__passengerContainer">
                    <PassengerIcon className="asistenciaViajero__cotSuccess__passengerIcon"/>
                    <p>
                        {passengersNumber}
                    </p>
                </div>
            </div>
            {minDatesWarning.modal &&
                <ModalIndicator arrowDirection="top" title={Title} message={Message}
                                setVisible={(isVisible) => setMinDatesWarning({...minDatesWarning, modal: isVisible})}
                                setDisplay={() => {
                                }} referenceElement={infoRangeRef} fadeOut={minDatesWarning.fadeOut}/>
            }
            <div className="automotor__cotSuccess__container">
                <Tabs
                    tabs={[
                        <div className="automotor__cotSuccess__tabLogoContainer">
                            <img
                                className={`automotor__cotSuccess__tabLogo`}
                                src={companiesDict['TerraWind']['logo']}
                                alt='TerraWind'
                            />
                        </div>
                    ]}
                    tabContent={[
                        <div className="automotor__cotSuccess__tabContent">
                            <GenericQuotationResponseTab
                                displayConfiguracionAvanzada={displayConfiguracionAvanzada}
                                setDisplayConfiguracionAvanzada={setDisplayConfiguracionAvanzada}
                                submitForm={submitForm}
                                addCoverageToCart={addCoverageToCart}
                                company={4}
                                coveragesArray={cotizacionResponse ? cotizacionResponse.productos : null}
                                hasNotSumaAsegurada
                                getRowData={getRowData}
                                hasCompanyFields={false}/>
                            <p className="automotor__cotSuccess__legendText">Los valores presentados se encuentran
                                sujetos a cotización de dólar oficial</p>
                        </div>
                    ]}
                    tabType="square"
                    blurInactiveTabs
                />
            </div>
        </div>
    );
}

export default CotizarSuccessView;