import Axios from "axios";
import FileDownload from "js-file-download";
import moment from "moment";

export function certificado(categoria, title, module, userName, userLastName, userPicture, setDownloadingState, setError) {
    setDownloadingState(true)
    setError(null)

    const jsonData = {
        "fecha": moment().format('DD/MM/YYYY'),
        "categoria": categoria,
        "modulo": module,
        "curso": title,
        "nombre": userName,
        "apellido": userLastName,
        "picture": userPicture
    }

    Axios.post(process.env.REACT_APP_PRINT_MICROSERVICE + `/printCertificate`, jsonData, {responseType: 'blob'})
        .then((response) => {
            FileDownload(response.data, 'certificado.pdf');
            setDownloadingState(false)
        })
        .catch((error) => {
            console.log(error);
            setDownloadingState(false)
            setError({title: "Ocurrió un error al descargar su certificado.",
                typeError: "Ha ocurrido un error en la comunicación con el servicio",
                suggestedActions: ["Reintentá el proceso una vez más", "Si el problema persiste ponete en contacto con el área de soporte"],
                detailedError: error.response?.data?.detail?.message || "Sin datos del error",})
        });
}