import React, {useEffect, useState} from 'react';
import {NumberInput} from '@mantine/core';
import {useField} from "formik";
import {ReactComponent as ExclamationIcon} from "../../../assets/icons/ic_exclamation.svg";
import './MantineNumberField.css';
import showNotification from "../../../modules/utils/showNotification";
import {currencyFormat} from "../../../modules/utils/currencyFormat";

function MantineNumberField(props) {
    const {label, name, min = 0, max, notFormat, rightSection, onChangeAction} = props;
    const [field, meta, helpers] = useField(name);
    const parsedValue = parseInt(field.value);
    const initialValue = isNaN(parsedValue) ? '' : parsedValue;
    const [value, setValue] = useState(initialValue);
    const {setValue: setFieldValue, setTouched} = helpers;

    useEffect(() => {
        const updatedParsedValue = parseInt(field.value);
        const updatedValue = isNaN(updatedParsedValue) ? '' : updatedParsedValue;
        setValue(updatedValue);
    }, [field.value]);

    return (
        <NumberInput
            {...props}
            hideControls
            label={label}
            value={value}
            onBlur={(e) => {
                const inputValue = parseInt(e.target.value.replace(/\$\s?|(\.*)/g, ''));
                if (inputValue < min) {
                    showNotification({
                        autoClose: 4000,
                        color: 'yellow',
                        status: 'warning',
                        title: 'El valor ingresado no es válido.',
                        message: `El valor ingresado no puede ser menor a ${!notFormat ? '$' : ''}${currencyFormat(min)}`
                    });
                } else if (max && inputValue > max) {
                    showNotification({
                        autoClose: 4000,
                        color: 'yellow',
                        status: 'warning',
                        title: 'La suma ingresada no es válida.',
                        message: `El valor ingresado no puede ser mayor a ${!notFormat ? '$' : ''}${currencyFormat(max)}`
                    });
                }
            }}
            onChange={(e) => {
                setTouched(name, true)
                if (e === "") {
                    setFieldValue(min)
                    setValue(min)
                } else {
                    setFieldValue(e)
                    setValue(e)
                }
                if (onChangeAction) {
                    onChangeAction(e)
                }
            }
            }
            parser={(v) => v.replace(/\$\s?|(\.*)/g, '')}
            formatter={(v) => {
                if (notFormat) {
                    return v
                }
                return !Number.isNaN(parseFloat(v))
                    ? `$ ${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                    : '$ '
            }
            }
            error={meta.error && meta.touched && <div>{meta.error}</div>}
            classNames={{
                wrapper: 'mantine__number__wrapper',
                label: meta.error && meta.touched ? 'mantine__number__label--error' : 'mantine__number__label',
                required: meta.error && meta.touched ? 'mantine__number__required--error' : 'mantine__number__required',
                error: 'mantine__number__error',
                input: meta.error && meta.touched ? 'mantine__number__field--error' : 'mantine__number__field',
            }}
            rightSection={
                rightSection ||
                meta.error && meta.touched && <ExclamationIcon className="mantine__number__icon"/>
            }
            rightSectionWidth={rightSection ? "auto" : 36}
        />
    );
}

export default MantineNumberField;