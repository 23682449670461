import React from 'react';
import './Hero.css'
import {Button} from "../../../components/Button";
import Clouds from '../../../assets/_images/clouds.png'
import {useAuth0} from "@auth0/auth0-react";

function Hero() {
    const { loginWithRedirect } = useAuth0();
    return (
        <div className="landing__hero__container">
            <div className="landing__hero__container--texts">
                <h1 className="landing__hero__title">
                    Aprendés, cotizás,
                    vendés y cobrás
                    desde la nube.
                </h1>
                <p className="landing__hero__subtitle">
                    Polko es una herramienta 100%
                    digital que permite a Brokers,
                    intermediarios de seguros y
                    demás agentes, potenciar sus
                    ventas de manera inteligente.
                </p>
            </div>
            <img className="landing__hero__background" src={Clouds} alt="clouds"/>
            <Button
                variant="filled"
                color="primary"
                enabled
                onClick={loginWithRedirect}
            >
                Quiero formar parte
            </Button>
        </div>
    );
}

export default Hero;