import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "../components/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Link as RouterLink} from "react-router-dom";
import LaunchIcon from "@material-ui/icons/Launch";

const useStyles = makeStyles((theme) => ({
    bottomSpacer: {
        marginTop: theme.spacing(3),
    },
    searchBar: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    titleBar: {
        display: 'flex',
        flexDirection: 'row'
    },
    textfieldCustom: {
        border: '3px solid #973379',
        overflow: 'hidden',
        borderRadius: 8,
    },
    titulo: {
        fontFamily: "Ubuntu",
        fontSize: 24,
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: -0.5,
        color: '#35303f'
    },
    gridSearchBar:{
        marginLeft: '90px',
        [theme.breakpoints.down('xs')]: {
            width:'100%',
            marginLeft:0
        },
    },
    maxHeightTable:{
        overflow:'auto',
        [theme.breakpoints.down('md')]: {
            height: '600px'
        },
    }
}));

function CotizacionesCombinado(props) {
    const classes = useStyles();
    // console.log(props)
    let rows = props.infoCotizaciones;

    const viviendasDict = {
        1021: 'Casa de ocupación permanente',
        1022: 'Departamento de ocupación permanente',
        1023: 'Country de ocupación permanente',
        1024: 'Vivienda/Departamento de ocupación transitoria',
    };


    return (
        <React.Fragment>
            <div className={classes.titleBar}>
                <div style={{flexGrow: 1}}/>

                <Grid container style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    marginBottom: '32px'
                }}>
                    <Grid item>
                        <Typography className={classes.titulo}>Últimas Cotizaciones</Typography>
                    </Grid>


                    {/*<Grid item className={classes.gridSearchBar}>*/}
                    {/*    <CssTextField*/}
                    {/*        placeholder="Buscar por modelo"*/}
                    {/*        variant="outlined"*/}
                    {/*        id="custom-css-outlined-input"*/}
                    {/*        //onChange={(e) => handleSearchValueChange(e)}*/}
                    {/*        margin="dense"*/}
                    {/*        InputProps={{*/}
                    {/*            startAdornment: (*/}
                    {/*                <InputAdornment position="start">*/}
                    {/*                    <SearchIcon/>*/}
                    {/*                </InputAdornment>*/}
                    {/*            ),*/}
                    {/*        }}*/}
                    {/*    />*/}

                    {/*</Grid>*/}
                </Grid>

            </div>
            <div className={classes.maxHeightTable}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Tipo vivienda</TableCell>
                            <TableCell>Tamaño</TableCell>
                            <TableCell>Localidad</TableCell>
                            <TableCell>Fecha Cotizacion</TableCell>
                            <TableCell>Recotizar</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rows !== null) &&
                        (rows.map((row, idx) => (
                            <TableRow key={row.id}>
                                <TableCell>
                                    {viviendasDict[row.tipoVivienda]}
                                    </TableCell>
                                <TableCell>{row.tamanioVivienda}</TableCell>
                                <TableCell>{row.codigosLocalidad}</TableCell>
                                <TableCell>{row.created_at}</TableCell>
                                <TableCell>
                                    <RouterLink to={{
                                        pathname: '/u/cotizar/combinado',
                                        state: {
                                            rowData: rows[idx]
                                        }
                                    }
                                    }>
                                        <LaunchIcon/>
                                    </RouterLink>
                                </TableCell>
                            </TableRow>
                        )))
                        }
                    </TableBody>
                </Table>
            </div>
            <div className={classes.bottomSpacer}/>

        </React.Fragment>
    );
}

export default CotizacionesCombinado;