import React from "react";
import "./NotificationsPopUpButtons.css"
import {ReactComponent as EmailIcon} from '../../../assets/icons/ic_email.svg';
import {ReactComponent as OpenEmailIcon} from '../../../assets/icons/ic_open_email.svg';

function NotificationsPopUpButtons(props) {
    const {visible, eventOnClickMails, arrToApply} = props;
    return (
        <div className="NPUB__container">
            {visible ?
                <div onClick={() => eventOnClickMails(arrToApply, false)} className="NPUB__mailContainer"><EmailIcon className="NPUB__mailsButtons"/>
                </div>
                :
                <div onClick={() => eventOnClickMails(arrToApply, true)} className="NPUB__mailContainer"><OpenEmailIcon className="NPUB__mailsButtons"/>
                </div>
            }
        </div>
    )
}

export default NotificationsPopUpButtons;