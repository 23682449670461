import React, {
    useEffect,
    useState,
    useMemo, useContext,
} from 'react';

import PropTypes from 'prop-types';
import axios from "axios";
import AuthContext from "../AuthContext";
import CotizarContext from "./CotizarContext";
import SancorBlack from "../../assets/logos/sancor_logo.svg";
import SancorBlackIsologo from "../../assets/logos/sancor_black_isologo.png";
import RUSBlack from "../../assets/logos/rus_logo.svg";
import RUSBlackIsologo from "../../assets/logos/rus_black_isologo.png";
import ZurichBlack from "../../assets/logos/zurich_black.png";
import ZurichBlackIsologo from "../../assets/logos/zurich_black_isologo.png";
import TerraWindBlack from "../../assets/logos/terrawind_black.svg";
import FederacionPatronalBlack from "../../assets/logos/federacion_patronal_logo.svg";
import FederacionPatronalBlackIsologo from "../../assets/logos/federacion_patronal_isologo.svg";
import {MIME_TYPES} from "@mantine/dropzone";

const coberturasAutomotorNames = {
    "names": {
        "RC": "Responsabilidad civil",
        "IT": "Incendio total",
        "IP": "Incendio parcial",
        "RHT": "Robo y/o hurto total",
        "RHP": "Robo y/o hurto parcial",
        "AT": "Accidente total",
        "APD": "Accidente parcial según deducible",
        "LTP": "Lock out o tumulto popular",
        "GTI": "Granizo / Terremoto / Inundación total y parcial",
        "RCR": "Rotura de cristales",
        "RCE": "Rotura de cerraduras",
        "RRH": "Reposición de ruedas en caso de robo o hurto",
        "DPR": "Daños parciales al amparo del Robo Total",
        "CPL": "Extensión de Cobertura a países limítrofes",
        "AV": "Asistencia al vehículo",
        "SCS": "CLEAS (Sistema Compensación de Siniestros)",
    },
}
const coberturasMotovehiculoNames = {
    "names": {
        "RC": "Responsabilidad civil",
        "IT": "Incendio total",
        "IP": "Incendio parcial",
        "RHT": "Robo y/o hurto total",
        "RHP": "Robo y/o hurto parcial",
        "AT": "Accidente total",
        "GIT": "Granizo / Inundación / Terremoto",
        "DPC": "Daños por robo o intento de robo (Cerraduras)",
        "DT": "Cláusula destrucción total del 80%",
        "AV": "Asistencia al vehículo",
        "EC": "Extensión de Cobertura a países limítrofes"
    },
}


const CotizarContextProvider = ({
                                    children,
                                }) => {

    const {authToken} = useContext(AuthContext)

    const [coberturasInfo, setCoberturasInfo] = useState(null)
    const [coberturasProductsInfo, setCoberturasProductsInfo] = useState(null);
    const [isReady, setIsReady] = useState(false);
    const coberturasAdicionalesOrder = ["48", "36", "37", "26"]
    //TODO: Simplificarlo a keys de compañias en lugar de compañias E indices identificadores.
    const companiesDict = {
        1: {
            name: "Sancor",
            logo: SancorBlack,
            isologo: SancorBlackIsologo,
            razonSocial: "Sancor",
            products: [
                {label: "AUTOMOTOR", value: "vehiculo", key: "automotor", hasDeclaracionJurada: true},
                {label: "MICROSEGUROS", value: "microseguros", key: "microseguros"},
                {label: "HOGAR", value: "hogar", key: "hogar"},
                {label: "MOTOVEHÍCULO", value: "motovehiculo", key: "motovehiculo", hasDeclaracionJurada: true}
            ],
            descuento: "premio",
            idsTarjeta: [
                {value: '1', label: 'Cabal'},
                {value: '5', label: 'Visa'},
                {value: '7', label: 'Favacard'},
                {value: '9', label: 'American Express'},
                {value: '12', label: 'Naranja'},
                {value: '16', label: 'Diners Club'},
                {value: '18', label: 'Naranja Visa'},
                {value: '20', label: 'Cordobesa Mastercard'},
                {value: '23', label: 'TDF - Tierra del Fuego'},
                {value: '45', label: 'Mastercard Nueva'},
                {value: '57', label: 'ELEBAR'},
                {value: '70', label: 'Cencosud Mastercard'},
            ],
            opcionesRolesExtra: [
                {value: '1', label: 'Tomador'},
                {value: '7', label: 'Asegurado Adicional'},
                {value: '15', label: 'Acreedor Hipotecario'},
                {value: '16', label: 'Acreedor Prendario'},
                {value: '17', label: 'Beneficiario'},
                {value: '18', label: 'Beneficiario de Asistencia'},
                {value: '46', label: 'Beneficiario de Subrogación'},
                {value: '111', label: 'Asegurado Titular Póliza de AP'},
            ],
            rolPrincipal: {value: '2', label: 'Asegurado'},
        },
        2: {
            name: "RUS",
            logo: RUSBlack,
            razonSocial: "Río Uruguay",
            isologo: RUSBlackIsologo,
            products: [
                {label: "AUTOMOTOR", value: "vehiculo", key: "automotor"},
                {label: "MOTOVEHÍCULO", value: "motovehiculo", key: "motovehiculo"}
            ],
            descuento: "premio",
            idsTarjeta: [
                {value: '1', label: 'Visa'},
                {value: '3', label: 'Mastercard'},
                {value: '6', label: 'Naranja'},
                {value: '7', label: 'Cabal'},
                {value: '11', label: 'American Express'},
                {value: '14', label: 'Kadicard'},
                {value: '16', label: 'Nativa Mastercard'},
                {value: '18', label: 'CMR Falabella'},
                {value: '19', label: 'Tarjeta Plata'},
                {value: '22', label: 'Favacard'},
            ],
            opcionesRolesExtra: [
                {value: '1', label: 'Tomador'},
                {value: '7', label: 'Asegurado Adicional'},
                {value: '15', label: 'Acreedor Hipotecario'},
                {value: '16', label: 'Acreedor Prendario'},
                {value: '17', label: 'Beneficiario'},
                {value: '18', label: 'Beneficiario de Asistencia'},
                {value: '46', label: 'Beneficiario de Subrogación'},
                {value: '111', label: 'Asegurado Titular Póliza de AP'},
            ],
            rolPrincipal: {value: '2', label: 'Asegurado'}
        },
        3: {
            name: "Zurich",
            logo: ZurichBlack,
            isologo: ZurichBlackIsologo,
            razonSocial: "Zurich",
            products: [{label: "AUTOMOTOR", value: "vehiculo", key: "automotor", hasDeclaracionJurada: true}],
            descuento: "prima",
            idsTarjeta: [
                {value: '1', label: 'Cabal'},
                {value: '5', label: 'Visa'},
                {value: '7', label: 'Favacard'},
                {value: '9', label: 'American Express'},
                {value: '12', label: 'Naranja'},
                {value: '16', label: 'Diners Club'},
                {value: '18', label: 'Naranja Visa'},
                {value: '20', label: 'Cordobesa Mastercard'},
                {value: '23', label: 'TDF - Tierra del Fuego'},
                {value: '45', label: 'Mastercard Nueva'},
                {value: '57', label: 'ELEBAR'},
                {value: '70', label: 'Cencosud Mastercard'},
            ],
            opcionesRolesExtra: [
                {value: '2', label: 'Asegurado'},
                {value: '1', label: 'Tomador'},
                {value: '3', label: 'Acreedor'},
            ],
            rolPrincipal: {value: '2', label: 'Asegurado'}
        },
        4: {
            name: 'TerraWind',
            logo: TerraWindBlack,
            products: [{label: "ASISTENCIA AL VIAJERO", value: "asistencia_viajero", key: "asistencia_viajero"}],
            razonSocial: "TerraWind",
            isologo: TerraWindBlack,
        },
        5: {
            name: 'Federacion_Patronal',
            logo: FederacionPatronalBlack,
            products: [{label: "AUTOMOTOR", value: "vehiculo", key: "automotor"}],
            razonSocial: "Federación Patronal",
            isologo: FederacionPatronalBlackIsologo,
        },
        "Sancor": {
            id: 1,
            logo: SancorBlack,
            razonSocial: "Sancor",
            products: [
                {label: "AUTOMOTOR", value: "vehiculo", key: "automotor", hasDeclaracionJurada: true},
                {label: "MICROSEGUROS", value: "microseguros", key: "microseguros"},
                {label: "HOGAR", value: "hogar", key: "hogar"},
                {label: "MOTOVEHÍCULO", value: "motovehiculo", key: "motovehiculo", hasDeclaracionJurada: true}
            ],
            isologo: SancorBlackIsologo,
            descuento: "premio",
            codigoFormasDePago: {TC: '2', CBU: '4', EF: '0'},
            idsTarjeta: [
                {value: '1', label: 'Cabal'},
                {value: '5', label: 'Visa'},
                {value: '7', label: 'Favacard'},
                {value: '9', label: 'American Express'},
                {value: '12', label: 'Naranja'},
                {value: '16', label: 'Diners Club'},
                {value: '18', label: 'Naranja Visa'},
                {value: '20', label: 'Cordobesa Mastercard'},
                {value: '23', label: 'TDF - Tierra del Fuego'},
                {value: '45', label: 'Mastercard Nueva'},
                {value: '57', label: 'ELEBAR'},
                {value: '70', label: 'Cencosud Mastercard'},
            ],
            bienesAdicionales: [{value: '10', label: 'Otros'}],
            equipoGncId: '1',
            emissionStatus: {Success: "approved", Pending: "pending", Cancel: "rejected"},
            opcionesRolesExtra: [
                {value: '1', label: 'Tomador'},
                {value: '7', label: 'Asegurado Adicional'},
                {value: '15', label: 'Acreedor Hipotecario'},
                {value: '16', label: 'Acreedor Prendario'},
                {value: '17', label: 'Beneficiario'},
                {value: '18', label: 'Beneficiario de Asistencia'},
                {value: '46', label: 'Beneficiario de Subrogación'},
                {value: '111', label: 'Asegurado Titular Póliza de AP'},
            ],
            rolPrincipal: {value: '2', label: 'Asegurado'}
        },
        "RUS": {
            id: 2,
            logo: RUSBlack,
            razonSocial: "Río Uruguay",
            isologo: RUSBlackIsologo,
            products: [
                {label: "AUTOMOTOR", value: "vehiculo", key: "automotor"},
                {label: "MOTOVEHÍCULO", value: "motovehiculo", key: "motovehiculo"}
            ],
            descuento: "premio",
            codigoFormasDePago: {TC: '2', CBU: '4', EF: '0'},
            idsTarjeta: [
                {value: '1', label: 'Visa'},
                {value: '3', label: 'Mastercard'},
                {value: '6', label: 'Naranja'},
                {value: '7', label: 'Cabal'},
                {value: '11', label: 'American Express'},
                {value: '14', label: 'Kadicard'},
                {value: '16', label: 'Nativa Mastercard'},
                {value: '18', label: 'CMR Falabella'},
                {value: '19', label: 'Tarjeta Plata'},
                {value: '22', label: 'Favacard'},
            ],
            bienesAdicionales: [{value: '10', label: 'Otros'}],
            equipoGncId: '1',
            emissionStatus: {Emitida: "approved", Propuesta: "pending", Cancel: "rejected"},
            opcionesRolesExtra: [
                {value: '1', label: 'Tomador'},
                {value: '7', label: 'Asegurado Adicional'},
                {value: '15', label: 'Acreedor Hipotecario'},
                {value: '16', label: 'Acreedor Prendario'},
                {value: '17', label: 'Beneficiario'},
                {value: '18', label: 'Beneficiario de Asistencia'},
                {value: '46', label: 'Beneficiario de Subrogación'},
                {value: '111', label: 'Asegurado Titular Póliza de AP'},
            ],
            rolPrincipal: {value: '2', label: 'Asegurado'}
        },
        "Zurich": {
            id: 3,
            logo: ZurichBlack,
            products: [{label: "AUTOMOTOR", value: "vehiculo", key: "automotor", hasDeclaracionJurada: true}],
            razonSocial: "Zurich",
            isologo: ZurichBlackIsologo,
            descuento: "prima",
            codigoFormasDePago: {TC: '4', CBU: '7', EF: '5'},
            idsTarjeta: [
                {value: '30654364229-2', label: 'Cabal'},
                {value: '30684195707-2', label: 'Nevada'},
                {value: '30598910045-2', label: 'Visa'},
                {value: '30574816870-2', label: 'Amex'},
                {value: '30536209383-2', label: 'KADICARD Pesos'},
                {value: '30579836268-2', label: 'Diners'},
                {value: '30619540146-2', label: 'CREDIMAS Pesos'},
                {value: '30522211563-2', label: 'Argen/Master'},
                {value: '30681424721-2', label: 'MIRA Pesos'},
                {value: '30685376349-2', label: 'Naranja'},
                {value: '30619540146-2', label: 'CREDIMAS Pesos'},
            ],
            bienesAdicionales: [
                {value: '91', label: 'Llantas'},
                {value: '92', label: 'Cubiertas'},
                {value: '14', label: 'Defensa'},
                {value: '15', label: 'Malacate'},
                {value: '96', label: 'Otros'},
            ],
            equipoGncId: '3',
            emissionStatus: {Success: "approved", Pending: "pending", Cancel: "rejected"},
            opcionesRolesExtra: [
                {value: '2', label: 'Asegurado'},
                {value: '1', label: 'Tomador'},
                {value: '3', label: 'Acreedor'},
            ],
            rolPrincipal: {value: '2', label: 'Asegurado'}
        },
        "TerraWind": {
            id: 4,
            logo: TerraWindBlack,
            products: [{label: "ASISTENCIA AL VIAJERO", value: "asistencia_viajero", key: "asistencia_viajero"}],
            razonSocial: "Terrawind",
            isologo: TerraWindBlack,
            emissionStatus: {Success: "approved", Pending: "pending", Cancel: "rejected"},
            codigoFormasDePago: {TC: 'MERCADO_PAGO', TD: 'MERCADO_PAGO', TRANSFERENCIA: 'TRANSFERENCIA'},
        },
        "Federacion_Patronal": {
            id: 5,
            logo: FederacionPatronalBlack,
            products: [{label: "AUTOMOTOR", value: "vehiculo", key: "automotor"}],
            razonSocial: "Federación Patronal",
            isologo: FederacionPatronalBlackIsologo,
            codigoFormasDePago: {TC:'2', CBU: 'DE', EF: '1'},
            idsTarjeta: [
                {value: 'VI', label: 'Visa'},
                {value: 'MC', label: 'Mastercard'},
                {value: 'CA', label: 'Cabal'},
                {value: 'AE', label: 'American Express'},
            ],
            emissionStatus: {Success: "approved", Pending: "pending", Cancel: "rejected"},
            descuento: "prima",
            rolPrincipal: {value: '2', label: 'Asegurado'},
            inspeccionTipoArchivos: [MIME_TYPES.jpeg]
        },
    }

    const genericOptionsFormasDePago = [{label: 'Tarjeta de crédito', value: 'TC'}, {label: 'Débito por CBU', value: 'CBU'}, {label: 'Tarjeta de débito', value: 'TD'}, {label: 'Efectivo', value: 'EF'}, {label: 'Transferencia', value: 'TRANSFERENCIA'}];


    useEffect(() => {
        let shouldUpdate = true;

        const init = async () => {
            let urlRequestOne = process.env.REACT_APP_BACK_SERVER + '/u/getDatosCotizar'
            let urlRequestTwo = process.env.REACT_APP_QUOTATION_MICROSERVICE + '/detallesCoberturas/autos'
            let urlRequestThree = process.env.REACT_APP_QUOTATION_MICROSERVICE + '/detallesCoberturas/motovehiculo'

            const requestOne = axios.post(urlRequestOne, {origen: "polko"}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            });
            const requestTwo = axios.get(urlRequestTwo);
            const requestThree = axios.get(urlRequestThree);
            let coberturasInfoDict = {};

            axios.all([requestOne, requestTwo, requestThree])
                .then(axios.spread((responseOne, responseTwo, responseThree) => {
                    if (shouldUpdate) {
                        let dict = {};
                        responseOne['data']['CombinadoFamiliar_CoberturasSancor'].map((item) =>
                            dict[item.numeroCobertura] = {
                                descripcion: item.descripcion,
                                descripcionCompleta: item.descripcionCompleta,
                                obligatorio: item.obligatorio,
                                sumaMaxima: item.sumaMaxima,
                                sumaMinima: item.sumaMinima,
                                sumaChica: item.sumaChica,
                                sumaGrande: item.sumaGrande,
                                sumaMediana: item.sumaMediana
                            });
                        setCoberturasInfo(dict)

                        //HANDLE RESPONSE TWO
                        coberturasInfoDict["automotor"] = {
                            ...responseTwo["data"],
                            ...coberturasAutomotorNames
                        }

                        //HANDLE RESPONSE THREE
                        coberturasInfoDict["motovehiculo"] = {
                            ...responseThree["data"],
                            ...coberturasMotovehiculoNames
                        }

                        setCoberturasProductsInfo(coberturasInfoDict)
                        setIsReady(true);
                    }
                }));

        }

        init();
        return () => {
            /* This cleanup function is used to prevent updating the state
            when the component is unmounted */
            shouldUpdate = false;
        };

    }, [])

    const context = useMemo(() => ({
        coberturasInfo,
        coberturasProductsInfo,
        companiesDict,
        genericOptionsFormasDePago,
        coberturasAdicionalesOrder,
        isReady
    }), [
        coberturasInfo,
        coberturasProductsInfo,
        companiesDict,
        genericOptionsFormasDePago,
        coberturasAdicionalesOrder,
        isReady
    ]);
    return (
        <CotizarContext.Provider value={context}>
            {children}
        </CotizarContext.Provider>
    );
}
CotizarContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default CotizarContextProvider;