import React, {useContext} from 'react';
import "./LinkInput.css";
import {MantineInputField} from "../../../../../components/FormFields/MantineInputField";
import {MantineInput} from "../../../../../components/MantineInput";
import MasterContext from "../../../../../context/MasterContext";

function LinkInput(props) {
    const {isForm, value, index, inputValues, setInputValues} = props;

    const {socialMediaDict} = useContext(MasterContext);
    const socialMedia = isForm
        ? null
        : Object.keys(socialMediaDict);

    //Updates array of socialMedia state
    const handleChange = (e) => {
        const newInputValues = [...inputValues];
        newInputValues[index] = e.target.value;
        setInputValues(newInputValues);
    }

    return (
        <div className="LInput__rowContainer">
            <div className="LInput__logoContainer">
                {isForm ? value.logo : socialMediaDict[socialMedia[index]].logo}
            </div>
            <div className="LInput__inputContainer">
                {isForm
                    ? <MantineInputField
                        required={false}
                        name={`links[${value.id}]`}
                    />
                    : <MantineInput
                        initialValue={value}
                        value={inputValues[index]}
                        onChange={(e) => handleChange(e)}
                    />
                }
            </div>
        </div>
    );
}

export default LinkInput;