import React from 'react';

const AutomotorContext = React.createContext({
    marcas: null,
    anios: null,
    modelos: null,
    versiones: null,
    updateModels: null,
    updateVersions: null,
    isLoadingModels: null,
    isLoadingVersions: null,
    modelsError: null,
    versionsError: null,
    vehicleDetails: null,
    handleRecotizarInitialValues: null
});

export default AutomotorContext;