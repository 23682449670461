import React from 'react';
import "./DescriptionOptions.css";
import {OptionCard} from "../../../../components/OptionCard";

function DescriptionOptions(props) {
    const {options, setOptions} = props;

    const handleSelection = (id) => {
        const newOptions = options.map(opt => {
            if (opt.id === id) {
                return {
                    ...opt,
                    selected: !opt.selected
                }
            }
            return opt;
        })
        setOptions(newOptions)
    }

    const handleTextChange = (id, text) => {
        const newOptions = options.map(opt => {
            if (opt.id === id) {
                return {
                    ...opt,
                    selected: true,
                    text: text
                }
            }
            return opt;
        })
        setOptions(newOptions)
    }

    return (
        <div className="DOptions__body">
            {options.map(opt => {
                return (
                    <div className="DOptions__descriptionContainer">
                        <OptionCard
                            id={opt.id}
                            text={opt.text}
                            variant={opt.variant}
                            selected={opt.selected}
                            handleSelection={handleSelection}
                            handleTextChange={handleTextChange}
                        />
                    </div>
                )
            })}
        </div>

    );
}

export default DescriptionOptions;