import React from 'react';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {withStyles} from "@material-ui/core/styles";


const styles = (theme) => ({
    iconStyle: {
        fontSize: 30,
        color: "#6eb97e",
        [theme.breakpoints.down('xs')]: {
            fontSize: 18,
        },
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        maxWidth: "calc(40% - 32px)"
    },
    paperMobile: {
        maxWidth: "calc(80%)"
    },
    centerContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    popoverContent: {
        overflow: 'auto',
        maxHeight: '400px',
        padding: '12px',
        //width: '100%',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.29',
        letterSpacing: 'normal',
        textAlign: 'justify',
        flexGrow: 1,
        whiteSpace: 'pre-wrap',
    }
});


function PopOverCustom(props) {
    const {classes} = props;
    const isMobile = props.isMobile;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const descripcion = props.descripcion;

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div className={classes.centerContent}>
            <Typography
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                className={classes.iconStyle}
            >
                <HelpOutlineIcon fontSize='inherit'/>
            </Typography>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
                classes={{paper: isMobile ? classes.paperMobile : classes.paper}}
            >
                <div className={classes.popoverContent}>{descripcion}</div>
            </Popover>
        </div>
    );
}

export default withStyles(styles)(PopOverCustom);
