import {Grid} from "@mantine/core";
import {MantineNumberField} from "../../../components/FormFields/MantineNumberField";
import {MantineSelectField} from "../../../components/FormFields/MantineSelectField";
import SearchZipCodeModal from "../SearchZipCodeModal/SearchZipCodeModal";
import React, {useEffect, useState} from "react";
import {getLocalidadOptionsByZipCode} from "../utils/getLocalidadOptionsByZipCode";
import {useFormikContext} from "formik";
import {updateLocalidadField, updateProvinciaField} from "./utils/utils";
import getValueByPath from "../../utils/getValueByPath";

function ZipCodeFieldPackage(props) {
    const {
        localidadField,
        codigoPostalField,
        provinciaField,
        localidadSize,
        codigoPostalSize,
        provinciaSize,
        localidadOptions,
        setLocalidadOptions,
        provinciasOptions,
        setProvinciaOptions,
        saveLabelAsValue
    } = props;
    const [modalToSearch, setModalToSearch] = useState(false);
    const {setFieldValue, values} = useFormikContext();

    useEffect(() => {
        const initialCodigoPostal = getValueByPath(values, codigoPostalField.name);

        if (initialCodigoPostal) {
            onSearchZipCode(initialCodigoPostal);
        }
    }, []);

    useEffect(() => {
        setLocalidadOptions(localidadOptions.filter(item => item.idProvincia === getValueByPath(values, provinciaField.name)));
    }, [provinciasOptions])

    const onSearchZipCode = async (code) => {
        setFieldValue(provinciaField.name, '');
        setFieldValue(localidadField.name, '');
        if (1000 <= code && code <= 9999) {
            const data = await getLocalidadOptionsByZipCode(code);
            setLocalidadOptions(data);
            updateProvinciaField(data, getValueByPath(values, provinciaField.name), setFieldValue, provinciaField.name, setProvinciaOptions);
            if (saveLabelAsValue) {
                data.forEach((localidad) => {
                    localidad.value = localidad.label.split(' ')[1]
                })
            }
            updateLocalidadField(data, getValueByPath(values, localidadField.name), setFieldValue, localidadField.name);
        }
    };

    const handleSearchZipCodeModal = () => {
        setFieldValue(codigoPostalField.name, '');
        setFieldValue(provinciaField.name, '');
        setFieldValue(localidadField.name, '');
        setLocalidadOptions([]);
        setModalToSearch(true);
    }

    return (
        <>
            <Grid.Col xs={12} sm={codigoPostalSize}>
                <MantineNumberField
                    name={codigoPostalField.name}
                    label={codigoPostalField.label}
                    withAsterisk
                    notFormat
                    onChangeAction={(code) => onSearchZipCode(code)}
                    rightSection={<a onClick={handleSearchZipCodeModal} className="automotor__zipCodeAction">
                        No sé mi código</a>}
                />
            </Grid.Col>
            <Grid.Col xs={12} sm={provinciaSize}>
                <MantineSelectField
                    name={provinciaField.name}
                    label={provinciaField.label}
                    data={provinciasOptions}
                    onChangeAction={() => setFieldValue(localidadField.name, '')}
                    disabled={provinciasOptions && provinciasOptions.length === 1}
                />
            </Grid.Col>
            <Grid.Col xs={12} sm={localidadSize}>
                <MantineSelectField
                    name={localidadField.name}
                    label={localidadField.label}
                    data={localidadOptions}
                    searchable
                    isLocalidad
                    withAsterisk
                    filter={(value, item) => {
                        if (item.idProvincia && item.label.toLowerCase().includes(value.toLowerCase().trim())) return item
                    }}
                    disabled={localidadOptions && localidadOptions.length === 0}
                />
            </Grid.Col>
            {
                modalToSearch &&
                <SearchZipCodeModal codigosLocalidadField={localidadField} codigoPostalField={codigoPostalField}
                                    provinciaField={provinciaField} setModalToSearch={setModalToSearch}
                                    localidadOptions={localidadOptions}
                                    setLocalidadOptions={setLocalidadOptions} provinciasOptions={provinciasOptions}
                                    setProvinciasOptions={setProvinciaOptions}/>
            }
        </>
    )
}

export default ZipCodeFieldPackage;