import React, {useContext, useState} from 'react';
import './ProductsModal.css';
import {PopUpCardModal} from "../../../../layouts/PopUpCardModal";
import {Button} from "../../../../components/Button";
import {ProductOptions} from "../../Utils/ProductOptions";
import MasterContext from "../../../../context/MasterContext";
import {saveConfiguration} from "../../Utils/saveConfiguration";
import AuthContext from "../../../../context/AuthContext";
import {ErrorModal} from "../../../../layouts/ErrorModal";

function ProductsModal(props) {
    const {setOpen, values} = props;
    const {idPolkistaMaster, handleModified} = useContext(MasterContext);
    const {authToken} = useContext(AuthContext);
    //List of companies by product and their active status
    const [activatedProducts, setActivatedProducts] = useState(values['products']);
    const [errorContent, setErrorContent] = useState(null);
    const [loading, setLoading] = useState(false);

    const checkSelection = () => {
        let isOneSelected = false;
        Object.keys(activatedProducts).forEach((product) => {
            if (activatedProducts[product].active) {
                isOneSelected = true;
            }
        })
        return isOneSelected;
    }

    const handleUpload = async () => {
        setLoading(true);
        const data = values['row'];
        let jsonData = {
            "logo": data.logo,
            "template": data.template,
            "productos": activatedProducts,
            "descripcion": data.descripcion,
            "idMaster": idPolkistaMaster,
            "links": data.redes_sociales,
            "idCanalDigital": data.id,
            "idPolkista": data.id_polkista,
        }

        await saveConfiguration(jsonData, authToken, true)
            .then(() => {
                handleModified();
                setOpen({modalName: "", values: null})
            })
            .catch(err => {
                setErrorContent({
                    title: "Hubo un problema al subir los valores.",
                    typeError: err.data.detail.code || "Ha ocurrido un error en la comunicación con el servidor",
                    suggestedActions: ["Reintentá el proceso una vez más", "Si el problema persiste ponete en contacto con el área de soporte"],
                    detailedError: err.data.detail.message || "Sin datos del error.",
                })
            });
    }

    return (
        <>
            <PopUpCardModal
                title={"Selecciona los productos que deseas habilitar"}
                body={
                    <div className="productsModal__body">
                        <ProductOptions idPolkista={values['row']['id_polkista']} products={activatedProducts} setProducts={setActivatedProducts} edit/>
                    </div>
                }
                closeAction={() => setOpen({modalName: "", values: null})}
                footer={
                    <div className="productsModal__button__container">
                        <Button variant="filled"
                                color="primary"
                                marginX="sm"
                                type="button"
                                enabled={checkSelection()}
                                onClick={() => handleUpload()}
                                loading={loading}
                        >
                            Aceptar
                        </Button>
                    </div>
                }
            />
            {errorContent &&
            <ErrorModal
                closeAction={() => {
                    setErrorContent(null)
                    setLoading(false)
                }}
                title={errorContent.title}
                typeError={errorContent.typeError}
                suggestedActions={errorContent.suggestedActions}
                detailedError={errorContent.detailedError}/>
            }
        </>
    );
}

export default ProductsModal;