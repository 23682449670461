import React, {useState} from "react";
import {OvalTab} from "../../../components/OvalTab";
import {CarouselWrapper} from "../../../components/CarouselWrapper";
import "./MarketingMobileTabs.css";

function MarketingMobileTabs(props) {
    const {tabs, tabContent} = props;
    const [activeTab, setActiveTab] = useState(0);

    function handleTabClick(index) {
        setActiveTab(index);
    }

    return (
        <div className="MMTabs__container">
            <div className="MMTabs__headerContainer">
                <CarouselWrapper slideSize="32%" withDots={false} align="start" initialSlide={0} dragFree={false}>
                {tabs.map((tab, index) => (
                    <OvalTab key={index} active={activeTab === index} color="primary"
                             onClick={() => handleTabClick(index)}>
                        <p>{tab.toUpperCase()}</p>
                    </OvalTab>
                ))}
                </CarouselWrapper>
            </div>
            <>
                {tabContent.map((content, index) => (
                    <>
                        {activeTab === index ? content : null}
                    </>
                ))}
            </>
        </div>
    );
}

export default MarketingMobileTabs;
