import React, {useEffect, useState} from 'react';
import './DropzoneFile.css';
import {Menu} from '@mantine/core';
import {ReactComponent as CloseIcon} from "../../assets/icons/ic_close.svg";
import {ReactComponent as CheckIcon} from "../../assets/icons/ic_check.svg";
import {ReactComponent as PickerIcon} from "../../assets/icons/ic_picker.svg";
import PdfIcon from "../../assets/images/PDF_icon.svg";
import {useFormikContext} from "formik";
import getValueByPath from '../../features/utils/getValueByPath';

const defaultOptions = [
    { label: "Frente", value: "FRENTE", used: false },
    { label: "Atrás", value: "ATRAS", used: false },
    { label: "Lateral derecho", value: "LATERALDERECHO", used: false },
    { label: "Lateral izquierdo", value: "LATERALIZQUIERDO", used: false },
    { label: "Auxilio", value: "AUXILIO", used: false },
    { label: "Otra", value: "OTRA", used: false },
];

function DropzoneFile(props) {
    const {file, index, onDelete, fileFieldName = "inspeccionPrevia.archivos", fileOptions = defaultOptions} = props;
    // for compatibility reasons when the Dropzone is used without the DropzoneWrapper
    const [labelOptions, setLabelOptions] = useState(fileOptions); // manages label state internally
    const { setFileOptions = setLabelOptions } = props;
    const [label, setLabel] = useState({l: null, idx: null});
    const [preview, setPreview] = useState(null);
    const {setFieldValue, errors, touched, setFieldTouched} = useFormikContext();

    // TODO: both getValueByPath and getIn (formik method) return the first element of the errors array for the specified
    // field name when there is only one element in the array. But we need the array, not an element.
    // This aproach fixes that but will break if fileFieldName is nested.
    const errorFileField = errors.fileFieldName;
    const touchedFileField = touched.fileFieldName;

    const handleSelectLabel = (l, i) => {
        setFieldValue(`${fileFieldName}.${index}.type`, l)
        setFieldTouched(`${fileFieldName}.${index}.type`, true, false);

        const updatedOptions = [...fileOptions];
        if (label && label.l) {
            updatedOptions.find((option) => option.value === label.l).used = false;
        }
        if (updatedOptions[i].value !== "OTRA") {
            updatedOptions[i].used = true;
        }
        setFileOptions(updatedOptions);

        setLabel({ l, idx: i });
    }

    const generateMenuItems = () => {
        return (
            <Menu.Dropdown>
                {fileOptions.map((option, idx) => (
                    !option.used &&
                        <Menu.Item key={idx}>
                            <p
                                className="dropzoneFile__menu__item--custom"
                                onClick={() => handleSelectLabel(option.value, idx)}
                            >
                                {option.value}
                            </p>
                        </Menu.Item>
                ))}
            </Menu.Dropdown>
        );
    }

    const handleDeleteImage = () => {
        onDelete();
        setFileOptions((prevOptions) => {
            const updatedOptions = [...prevOptions];
            const labelToDelete = updatedOptions.find((option) => option.value === label.l);
            if (labelToDelete) { labelToDelete.used = false }
            return updatedOptions;
        });
        setLabel(null);
    }

    function renderFileLabel() {
        if (errorFileField &&
            errorFileField[index] &&
            touchedFileField &&
            touchedFileField[index] &&
            touchedFileField[index].type
        ) {
            return (
                <div className="dropzoneFile__label__container">
                    <p className="dropzoneFile__label--error">Es necesario agregar una etiqueta</p>
                    <PickerIcon className="dropzoneFile__icon__picker"/>
                </div>
            );
        } else if (label && label.l) {
            return (
                <div className="dropzoneFile__label__container">
                    <p className="dropzoneFile__label--right">{label.l}</p>
                    <CheckIcon className="dropzoneFile__icon__check"/>
                </div>
            );
        } else {
            return (
                <div className="dropzoneFile__label__container">
                    <p className="dropzoneFile__label">Etiqueta</p>
                    <PickerIcon className="dropzoneFile__icon__picker"/>
                </div>
            );
        }
    }

    useEffect(() => {
        let objectUrl;

        if (file.link) {
            setLabel((prevLabel) => ({l: file.type, idx: prevLabel?.idx || 0}))
            if (file.link.split('.').pop().toUpperCase() === 'PDF') {
                setPreview(PdfIcon)
            } else {
                setPreview(file.link);
            }
        } else {
            if (file.file.type === 'application/pdf') {
                setPreview(PdfIcon)
            } else {
                objectUrl = URL.createObjectURL(file.file)
                setPreview(objectUrl)
            }

            setLabel((prevLabel) => ({l: file.type, idx: prevLabel?.idx || 0}))
        }

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [file]);


    return (
        <div className="dropzoneFile__card">
            <div className="dropzoneFile__image__container">
                {preview && <img className="dropzoneFile__image" src={preview} alt="preview_file"/>}
                <div onClick={() => handleDeleteImage()}><CloseIcon className="dropzoneFile__icon__close"/></div>
            </div>
            <div className="dropzoneFile__menu__container">
                <Menu shadow="md" width={144} classNames={{
                    item: "dropzoneFile__menu__item"
                }}>
                    <Menu.Target>
                        <div className="dropzoneFile__target__container">
                            {renderFileLabel()}
                        </div>
                    </Menu.Target>

                    {generateMenuItems()}
                </Menu>
            </div>
        </div>
    );
}

export default DropzoneFile;