import React from 'react';
import './TermsAndConditions.css';
import {Button} from '../../../components/Button';

function TermsAndConditions(props) {
    const {setTycModalOpen} = props;
    return (
        <div className="TyC__container">
            <p className="TyC__title">
                Términos y condiciones de servicio - POLKO SAS
            </p>
            <br/>
            <div>
                <p className="TyC__subtitle">
                    1- PRELIMINARES
                </p>
                <p className="TyC__text">
                    1.1. POLKO S.A. (en adelante, “POLKO”), sociedad en formación, con domicilio y sede social en calle
                    Av. Rafael Núñez N° 3578 B° Cerro de las Rosas, ciudad de Córdoba, Provincia de Córdoba, es la
                    Titular y Administradora de la plataforma virtual para dispositivos móviles (en adelante la
                    “Aplicación”) y servicios web que se detallarán infra.
                    <br/>
                    1.2. El presente contrato, describe los términos y condiciones generales (en adelante los "Términos
                    y Condiciones Generales") aplicables al uso de los servicios ofrecidos por POLKO, (en adelante los
                    “Servicios") dentro de la Aplicación denominada Polko y el sitio web www.polko.com.ar (en adelante
                    "Polko" o el "Sitio").
                    <br/>
                    1.3. Cualquier persona en ejercicio de su labor profesional (en adelante el "Usuario" o en plural
                    "Usuarios") que desee acceder y/o usar la Aplicación, el Sitio o los Servicios, podrá hacerlo
                    sujetándose a los Términos y Condiciones Generales respectivos, junto con todas las demás políticas
                    y principios que rigen POLKO y que son incorporados al presente por referencia.
                    <br/>
                    CUALQUIER PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES GENERALES, LOS CUALES TIENEN UN
                    CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE UTILIZAR LA APLICACIÓN, EL SITIO Y/O LOS
                    SERVICIOS.
                    <br/>
                    1.4. Como condición previa y esencial a la utilización de la Aplicación, el Sitio o los Servicios
                    ofrecidos por POLKO, el Usuario debe leer íntegramente y en forma completa, entender y aceptar todas
                    las condiciones establecidas en los Términos y Condiciones Generales y en las Políticas de
                    Privacidad, así como en los demás documentos incorporados a los mismos por referencia, previo a su
                    registración como Usuario de POLKO, optando de forma expresa por recibir los mismos y toda otra
                    información relevante por los medios digitales establecidos.
                    <br/>
                    1.5. A través de la plataforma virtual para dispositivos móviles y/o del sitio web, POLKO facilita
                    la vinculación entre Usuarios independientes denominados Polkistas y las personas humanas o
                    destinatarios finales (en adelante los “Consumidores”) que requieran de los servicios de seguros y
                    financieros, que son ofrecidos a través de POLKO. El servicio a ser prestado por los Polkistas a los
                    Consumidores, a efectos de los presentes Términos y Condiciones Generales se denominará como
                    “Servicio Polkista”, ejecutado a través de un contrato de mandato, donde el Polkista actúa como
                    gestor y mandatario, y el Consumidor se constituye como mandante en dicha relación. La Aplicación
                    únicamente constituye un mecanismo intermediación entre los Polkistas y los Consumidores.
                    <br/>
                    1.6. Bajo ninguna circunstancia los Usuarios o Polkistas serán considerados empleados de POLKO ni de
                    ninguno de sus afiliados. Los Usuarios prestan el Servicio Polkista por cuenta y riesgo propios y
                    liberan a POLKO de cualquier responsabilidad que pudiera surgir durante la prestación del mismo.
                    <br/>
                    1.7. A través de la Aplicación, POLKO junto con sus Polkistas, facilitan la vinculación entre los
                    Consumidores y una serie de entidades que ofrecen servicios de seguros y financieros a través de la
                    misma (en adelante los “Entidades Asociadas”), para que los Consumidores puedan celebrar contratos
                    de seguros y/o financieros o la prestación de servicios con dichos Entidades Asociadas.
                    <br/>
                    1.8. El Usuario conoce y acepta que POLKO no es parte en las operaciones concertadas o de la
                    prestación de servicios que puedan acordarse en el marco de la Aplicación entre el Consumidor y los
                    Entidades Asociadas o cualquier otra entidad. Cualquier reclamo o requerimiento al respecto deberá
                    realizarse a la Entidad Asociada o con la que se haya celebrado la contratación correspondiente.
                </p>
                <br/>


                <p className="TyC__subtitle">
                    2- REGISTRO
                </p>
                <p className="TyC__text">
                    2.1. Podrán ser Usuarios las personas humanas mayores de edad y con capacidad plena para contratar,
                    en la medida en que realicen el proceso de Registro (conforme el mismo se define más adelante), y el
                    mismo sea aceptado por POLKO. A tales efectos, Usted afirma que o es mayor de 18 años, o un menor
                    emancipado o que posee permiso legal de sus padres y/o tutor y que está completamente habilitado y
                    competente para entrar en estos términos, condiciones, obligaciones, afirmaciones, representaciones,
                    y garantías establecidas en estos Términos y Condiciones Generales, y para respetar y cumplir con
                    estos Términos y Condiciones Generales.
                    <br/>
                    2.2. El acceso a la Aplicación es gratuito, salvo en lo relativo al costo de la conexión a través de
                    la red de telecomunicaciones suministrada por el proveedor de acceso contratado (ISP) por el
                    Usuario, que estará a su exclusivo cargo. El Usuario únicamente podrá acceder a la Aplicación a
                    través de los medios autorizados. Para acceder y utilizar la Aplicación, el Usuario deberá contar
                    con un Smartphone con sistema operativo iOS o Android, o en su caso, con una computadora con acceso
                    a internet y deberá completar todos los campos del formulario de inscripción correspondiente a los
                    Usuarios con datos válidos, como así también aceptar las Políticas de Privacidad y los presentes
                    Términos y Condiciones Generales.
                    <br/>
                    2.3. Quien aspire a convertirse en Usuario o Polkista, tendrá la obligación de completar el
                    formulario de registración en todos sus campos con datos válidos para poder utilizar los servicios
                    que brinda POLKO. El Usuario deberá verificar que la información que pone a disposición de POLKO sea
                    exacta, precisa y verdadera (en adelante los “Datos Personales”). El Usuario presta expresa
                    conformidad con que POLKO utilice diversos medios para identificar sus Datos Personales, asumiendo
                    la obligación de revisarlos y mantenerlos actualizados periódicamente cada vez que los mismos sufran
                    modificaciones. POLKO podrá utilizar diversos medios para identificar a los Usuarios, pero POLKO no
                    garantiza a los Usuarios por la certeza de los Datos Personales que el resto de los Usuarios pongan
                    a su disposición. Los Usuarios garantizan y responden, en cualquier caso, por la veracidad,
                    exactitud, vigencia y autenticidad de los Datos Personales puestos a disposición de POLKO. Esto no
                    obsta a la obligación de POLKO de cumplir con las disposiciones establecidas en la Ley N° 25.326 y
                    en sus Políticas de Privacidad.
                    <br/>
                    2.4. Los Datos Personales que el Usuario proporcione se integrarán en una base de datos personales
                    del que es responsable POLKO.
                    <br/>
                    2.5. POLKO se reserva el derecho de solicitar algún comprobante y/o dato adicional a efectos de
                    corroborar los Datos Personales, así como de suspender temporal o definitivamente a aquellos
                    Usuarios cuyos datos no hayan podido ser confirmados. En estos casos de inhabilitación, se dará de
                    baja a la cuenta personal.
                    <br/>
                    2.6. Una vez efectuado el Registro, POLKO otorgará al Usuario una cuenta personal para acceder con
                    la contraseña que elija (en adelante la “Cuenta”). El Usuario se obliga a mantener la
                    confidencialidad de su contraseña (en adelante “Clave de Seguridad). El Usuario será responsable por
                    todas las operaciones efectuadas a través de su Cuenta, pues el acceso a la misma está restringido
                    al ingreso y uso de su Clave de Seguridad, de conocimiento exclusivo del Usuario. El Usuario se
                    compromete a notificar a POLKO en forma inmediata cualquier sospecha, certeza o temor de un acceso
                    no autorizado a su Cuenta, así como del conocimiento por terceros de su Clave de Seguridad. A tal
                    efecto, el Usuario deberá ingresar a su perfil dentro de la Aplicación, o enviar un e-mail a
                    consultas@polko.com.ar desde la casilla de mail que figura en su Cuenta.
                    <br/>
                    2.7. En el caso de que un Usuario haya olvidado su contraseña o quiera cambiarla, podrá reemplazarla
                    ingresando a su perfil dentro de la Aplicación, o enviando un e-mail a consultas@polko.com.ar desde
                    la casilla de mail que figure en su Cuenta.
                    <br/>
                    2.8. La Cuenta es personal, única e intransferible, y está prohibido que un mismo Usuario registre o
                    posea más de una Cuenta. En caso que POLKO detecte distintas Cuentas que contengan datos
                    coincidentes o relacionados, podrá cancelar, suspender o inhabilitarlas a su entera discreción.
                    <br/>
                    2.9. POLKO se reserva el derecho de rechazar cualquier solicitud de registración o de cancelar una
                    registración previamente aceptada, sin que esté obligado a comunicar o exponer las razones de su
                    decisión y sin que ello genere algún derecho a indemnización o resarcimiento.
                </p>
                <br/>

                <p className="TyC__subtitle">
                    3- PRIVACIDAD DE LA INFORMACIÓN
                </p>
                <p className="TyC__text">
                    3.1. Para utilizar los Servicios ofrecidos por POLKO, los Usuarios deberán facilitar determinados
                    datos de carácter personal. Su información personal se procesa y almacena en servidores o medios
                    magnéticos que mantienen altos estándares de seguridad y protección tanto física como tecnológica.
                    Para mayor información sobre la privacidad de los Datos Personales y casos en los que será revelada
                    la información personal, se pueden consultar nuestras Políticas de Privacidad.
                </p>
                <br/>

                <p className="TyC__subtitle">
                    4- MODIFICACIONES DEL ACUERDO
                </p>
                <p className="TyC__text">
                    4.1. POLKO podrá modificar los Términos y Condiciones Generales en cualquier momento haciendo
                    públicos en la Aplicación y/o el Sitio, los términos modificados. Todos los términos modificados
                    entrarán en vigencia a partir del décimo día de su publicación. Dichas modificaciones serán
                    comunicadas por POLKO a los Usuarios que en la Configuración de su Cuenta, hayan indicado que desean
                    recibir notificaciones de los cambios en estos Términos y Condiciones Generales. Todo usuario que no
                    esté de acuerdo con las modificaciones efectuadas por POLKO podrá solicitar la baja de la cuenta.
                    <br/>
                    4.2. El uso de la Aplicación y/o el Sitio y/o sus Servicios implica la aceptación de estos Términos
                    y Condiciones generales de uso de POLKO.
                </p>
                <br/>

                <p className="TyC__subtitle">
                    5- OPERATORIA DE LA APLICACIÓN
                </p>
                <p className="TyC__text">
                    5.1. La utilización de la Aplicación y del Sitio, se encuentran limitadas y dirigidas exclusivamente
                    a los Usuarios.
                    <br/>
                    5.2. El Usuario podrá encontrar en la Aplicación y el Sitio, productos y servicios de las Entidades
                    Asociadas, destinados a ser ofrecidos a los potenciales Consumidores. El Consumidor pagará el precio
                    correspondiente a la Entidad Asociada mediante la gestión que realizará el Usuario o Polkista a
                    través de la Aplicación.
                    <br/>
                    5.3. Un mismo Usuario podrá ofrecer un número razonable de Servicios Polkistas disponibles en la
                    Aplicación por día. Los Usuarios aceptan en forma irrevocable que POLKO se reserva el derecho de
                    limitar o bloquear, a su solo arbitrio, a cualquier Usuario ante cualquier sospecha de fraude,
                    estafa, uso de datos ajenos, etc., o por cualquier otra sospecha de riesgo, cualquiera fuese su
                    naturaleza.
                    <br/>
                    5.4. POLKO podrá, a su exclusivo criterio, rechazar y/o suspender y/o cancelar cualquier contrato
                    celebrado realizado por un Usuario a través de la Aplicación o Sitio siempre y cuando dicho
                    contratación incumpla: (i) cualquier disposición contenida las presentes en los Términos y
                    Condiciones Generales o de cualquier ley o regulación aplicable a los mismos; (ii) al derecho
                    aplicable en general y a los derechos de terceros incluyendo, a título meramente enunciativo, otros
                    Usuarios; y (iii) al uso permitido de la Aplicación. En caso de cancelación realizada por POLKO, el
                    Consumidor tendrá derecho al reembolso por hasta el valor de la totalidad del contrato celebrado.
                    <br/>
                    5.5. Una vez solicitado y/o efectuado la concertación de algún Servicio Polkista, la Aplicación
                    enviará un correo electrónico al Usuario con las condiciones del Servicio Polkista (en adelante
                    “Resumen Operación”). El Resumen Operación incluirá el detalle del contrato celebrado entre el
                    Usuario y el Consumidor final, para ser entregado una copia a este último como constancia de la
                    operación concertada.
                    <br/>
                    5.6. Cualquier cambio en el Servicio Polkista será informado al Usuario al teléfono de contacto y/o
                    al correo electrónico informado por el Usuario en su Cuenta.
                    <br/>
                    5.7. El Usuario o Polkista cumplirá el Servicio Polkista entregando una copia del contrato celebrado
                    que se emitirá y estará disponible en el Resumen Operación, para que sea entregado al Consumidor,
                    siendo el Consumidor el único responsable por la veracidad de los datos informados al Usuario o
                    Polkista.
                    <br/>
                    5.8. Se encuentra totalmente prohibido utilizar el Servicio Polkista para celebrar contratos con
                    fines ilícitos, ilegales, contrarios a lo establecido en los presentes Términos y Condiciones
                    Generales, a la buena fe y al orden público, lesivos de los derechos e intereses de terceros.
                </p>
                <br/>

                <p className="TyC__subtitle">
                    6- VIOLACIÓN AL SISTEMA O BASE DE DATOS
                </p>
                <p className="TyC__text">
                    6.1. No está permitida ninguna acción o uso de dispositivo, software, u otro medio tendiente a
                    interferir tanto en las actividades y operatoria de POLKO. Cualquier intromisión, tentativa o
                    actividad violatoria o contraria a las leyes sobre derecho de propiedad intelectual y/o a las
                    prohibiciones estipuladas en este contrato harán pasible a su responsable de las acciones legales
                    pertinentes, y a las sanciones previstas por este acuerdo, así como lo hará responsable de
                    indemnizar los daños ocasionados.
                </p>
                <br/>

                <p className="TyC__subtitle">
                    7- SANCIONES
                </p>
                <p className="TyC__text">
                    7.1. Sin perjuicio de otras medidas, POLKO podrá advertir, suspender en forma temporal o
                    definitivamente la Cuenta de un Usuario, aplicar una sanción que impacte negativamente en la
                    reputación de un Usuario, iniciar las acciones que estime pertinentes y/o suspender la prestación de
                    sus Servicios si (a) se quebrantara alguna ley, o cualquiera de las estipulaciones de los Términos y
                    Condiciones Generales y demás políticas de POLKO; (b) si incumpliera sus compromisos como Usuario;
                    (c) si se incurriera a criterio de POLKO en conductas o actos dolosos o fraudulentos; (d) no pudiera
                    verificarse la identidad del Usuario o cualquier información proporcionada por el mismo fuere
                    errónea. En el caso de la suspensión de un Usuario, sea temporal o definitiva, su Cuenta quedará
                    inhabilitada para todo uso, incluso para disponer de los fondos existentes en la misma.
                </p>
                <br/>
                <p className="TyC__subtitle">
                    8- RESPONSABILIDAD
                </p>
                <p className="TyC__text">
                    8.1. POLKO sólo pone a disposición del Usuario un espacio virtual que les permite ofrecer a los
                    potenciales consumidores, los Servicios Polkistas mediante la Aplicación o el Sitio. POLKO no
                    interviene en el perfeccionamiento de las operaciones realizadas entre el Usuario y el Consumidor,
                    por ello no será responsable respecto de la calidad, cantidad, estado, integridad o legitimidad de
                    los productos o servicios ofrecidos por el Usuario, así como de la capacidad para contratar del
                    Usuario, ni otorga garantía sobre la veracidad de sus datos personales por ellos ingresados. Esto no
                    obsta a la obligación de POLKO de rectificar, actualizar o suprimir los datos incorrectos y demás
                    obligaciones dispuestas en la Ley N° 25.326 y en sus Políticas de Privacidad.

                    <br/>
                    8.2. Se aclara también que POLKO tampoco tiene injerencia ni será responsable por los productos y/o
                    servicios de las Entidades Asociadas o demás entidades a los que se acceda a través de la Aplicación
                    y/o el Sitio, incluyendo, pero sin limitarse a características de los mismos, condiciones de
                    adquisición, de comercialización, de contratación, tarifas, promociones, descuentos, entre otros,
                    todo lo cual es definido por la Entidad Asociada o demás entidades. Cualquier requerimiento o
                    reclamo al respecto deberá realizarse a la Entidad Asociada o entidad correspondiente.
                    <br/>
                    8.3. POLKO NO GARANTIZA, NI SE ADHIERE, NI ASUME RESPONSABILIDAD POR NINGUN PRODUCTO, O SERVICIO
                    PUBLICITADO U OFRECIDO POR UN TERCERO A TRAVES DE LA APLICACIÓN O DE OTROS SITIOS ACCEDIDOS A TRAVÉS
                    DE LOS ENLACES EN LA APLICACIÓN, O QUE APAREZCAN PUBLICITADOS EN CUALQUIER CARTEL O IMAGEN DENTRO DE
                    LA APLICACIÓN. NI CON LA CONTRATACION DE UN PRODUCTO O SERVICIO A TRAVES DE CUALQUIER MEDIO O EN
                    CUALQUIER CIRCUNSTANCIA, USTED DEBERIA USAR SU MEJOR CRITERIO Y EJERCITAR PRECAUCION EN CASO DE SER
                    NECESARIO.

                    <br/>
                    8.4. El Usuario conoce y acepta que, al realizar operaciones con la Aplicación y/o Sitio, con la
                    Entidad Asociada y demás entidades lo hace bajo su propio riesgo. En ningún caso POLKO será
                    responsable por lucro cesante, o por cualquier otro daño y/o perjuicio que haya podido sufrir, el
                    Usuario, el Consumidor, la Entidad Asociada, las demás entidades, o cualquier otra persona debido a
                    la utilización de la Aplicación y/o el Sitio.
                    <br/>
                    8.5. El Usuario deberá utilizar la Aplicación responsablemente, verificando en todos los casos la
                    correspondencia de las operaciones y los montos indicados en la pantalla. Las órdenes efectuadas
                    mediante la Cuenta podrán ser revertidas, revocadas o anuladas por el Usuario, previa verificación y
                    autorización por POLKO.
                    <br/>
                    8.6. POLKO recomienda actuar con prudencia y sentido común al momento de utilizar la Aplicación y/o
                    el Sitio. En caso que uno o más Consumidores o algún tercero inicien cualquier tipo de reclamo o
                    acciones legales contra un Usuario, una Entidad Asociada o cualquier otra entidad, todos y cada uno
                    de los involucrados en dichos reclamos o acciones eximen de toda responsabilidad a POLKO, sus
                    sociedades vinculadas, directores, socios, empleados, representantes, apoderados y agentes.
                    <br/>
                    8.7. Sitios Enlazados. La Aplicación puede contener enlaces y/o referencias a otras aplicaciones o
                    sitios web de terceros (en adelante, “Sitios Enlazados”), lo cual no indica que ellos sean propiedad
                    de POLKO u operados por POLKO. La instalación de estos enlaces o referencias en la Aplicación y/o el
                    Sitio se limita a facilitar al Usuario la búsqueda y acceso a la información allí disponible. En
                    virtud de que POLKO no tiene control sobre tales aplicaciones y sitios, POLKO NO será responsable
                    por los contenidos, materiales, acciones y/o servicios prestados por los mismos, ni por daños o
                    pérdidas ocasionadas a los Usuarios, a los Consumidores, a las Entidades Asociadas o a otras
                    entidades por la utilización de los mismos. La presencia de enlaces a otras plataformas o sitios web
                    no implica una sociedad, relación, aprobación y/o respaldo de POLKO a dichas plataformas o sitios y
                    sus contenidos. Si Ud. decide dejar la Aplicación y acceder a Sitios Enlazados, Ud. entiende que lo
                    hace bajo su propio riesgo. La conexión que el Usuario realice con otros Sitios Enlazados queda
                    totalmente bajo la responsabilidad de dicho Usuario. POLKO no ha controlado de ninguna manera los
                    Sitios Enlazados y no se hace responsable por el contenido, exactitud o autenticidad expresados en
                    estos sitios web. La inclusión de estos enlaces en la Aplicación y/o el Sitio no implica aprobación
                    o respaldo por POLKO de los Sitios Enlazados, sus entidades o productos y servicios.
                    <br/>
                    8.8. EN NINGÚN CASO POLKO SERÁ RESPONSABLE POR DAÑOS Y PERJUICIOS DIRECTOS, INDIRECTOS, IMPREVISTOS,
                    MEDIATOS O PUNITORIOS (INCLUSO SI POLKO FUE ADVERTIDO SOBRE DICHOS DAÑOS Y PERJUICIOS), QUE RESULTEN
                    DE ALGÚN ASPECTO DEL USO QUE EL USUARIO HAGA DE LA APLICACIÓN Y/O EL SITIO, YA SEA QUE LOS DAÑOS Y
                    PERJUICIOS SURJAN A PARTIR DEL USO O EL MAL USO DE LA APLICACIÓN O EL SERVICIO DE POLKO, ANTE LA
                    INCAPACIDAD DE UTILIZAR LA APLICACIÓN Y/O EL SITIO, O LA INTERRUPCIÓN, SUSPENSIÓN, MODIFICACIÓN,
                    ALTERACIÓN O CANCELACIÓN DE LA APLICACIÓN. DICHA LIMITACIÓN TAMBIÉN SERÁ APLICABLE CON RELACIÓN A
                    LOS DAÑOS Y PERJUICIOS EN QUE SE INCURRAN A RAÍZ DE OTROS SERVICIOS O PRODUCTOS RECIBIDOS A TRAVÉS O
                    CON RELACIÓN A LA APLICACIÓN O LOS SITIOS ENLAZADOS EN LA APLICACIÓN, ASÍ COMO TAMBIÉN A CAUSA DE LA
                    INFORMACIÓN O EL CONSEJO RECIBIDO A TRAVÉS DE LA APLICACIÓN Y/O EL SITIO, O PUBLICITADO CON RELACIÓN
                    A ESTOS O UNO DE LOS SITIOS ENLAZADOS EN LA APLICACIÓN. LAS LIMITACIONES QUE ANTECEDEN SE APLICARÁN
                    DENTRO DEL MÁXIMO ALCANCE PERMITIDO POR LA LEY.
                    <br/>
                    8.9. Son los Usuarios Polkistas quienes utilizan POLKO para ofrecer los Servicios Polkistas.
                </p>
                <br/>

                <p className="TyC__subtitle">
                    9- ALCANCE DE LOS SERVICIOS DE POLKO
                </p>
                <p className="TyC__text">
                    9.1. Este acuerdo no crea ningún contrato de sociedad, de mandato, de franquicia, o relación laboral
                    entre POLKO y el Usuario. El usuario reconoce y acepta que POLKO no es parte en ninguna operación,
                    ni tiene control alguno la capacidad de los Usuarios para concertar las operaciones con los
                    Consumidores. POLKO no garantiza la veracidad de la publicidad de terceros que aparezca en la
                    Aplicación y/o el sitio.
                </p>
                <br/>

                <p className="TyC__subtitle">
                    10- FALLAS EN EL SISTEMA
                </p>
                <p className="TyC__text">
                    10.1. POLKO no se responsabiliza por cualquier daño, perjuicio o pérdida al usuario causados por
                    fallas en el sistema, en el servidor o en Internet. POLKO tampoco será responsable por cualquier
                    virus que pudiera infectar el equipo del Usuario como consecuencia del acceso, uso o examen de su
                    Aplicación o sitio web o a raíz de cualquier transferencia de datos, archivos, imágenes, textos, o
                    audio contenidos en el mismo. Los usuarios NO podrán imputarle responsabilidad alguna ni exigir pago
                    por lucro cesante, en virtud de perjuicios resultantes de dificultades técnicas o fallas en los
                    sistemas o en Internet. POLKO no garantiza el acceso y uso continuado o ininterrumpido de su
                    Aplicación o sitio. El sistema puede eventualmente no estar disponible debido a dificultades
                    técnicas o fallas de Internet, o por cualquier otra circunstancia ajena a POLKO; en tales casos se
                    procurará restablecerlo con la mayor celeridad posible sin que por ello pueda imputársele algún tipo
                    de responsabilidad. POLKO no será responsable por ningún error u omisión contenidos en su Aplicación
                    o sitio web.
                </p>
                <br/>

                <p className="TyC__subtitle">
                    11- CONDICIONES DE PAGO - TARIFAS - FACTURACIÓN
                </p>
                <p className="TyC__text">
                    11.1. El Usuario solo deberá pagar a POLKO un costo (en adelante “Comisión”) por la operación
                    concretada.
                    <br/>
                    11.2. POLKO se reserva el derecho de tomar las medidas judiciales y extrajudiciales que estime
                    pertinentes para obtener el pago del monto debido.
                    <br/>
                    11.3. POLKO se reserva el derecho de modificar, cambiar, agregar, o eliminar las tarifas vigentes,
                    en cualquier momento, lo cual será notificado a los Usuarios, en la forma establecida en la Cláusula
                    4. Sin embargo, POLKO podrá modificar temporalmente la Política de Tarifas y las tarifas por sus
                    servicios, siendo efectivas estas modificaciones cuando se hagan públicas a través de los canales de
                    comunicación de POLKO.
                    <br/>
                    11.4. En caso de haberse facturado cargos que no hubiesen correspondido, el Usuario deberá
                    comunicarse con nuestro equipo de Atención al Cliente para resolver dicha cuestión.
                </p>
                <br/>

                <p className="TyC__subtitle">
                    12- PROHIBICIÓN DE CEDER
                </p>
                <p className="TyC__text">
                    12.1. El Usuario no podrá ceder sus derechos y obligaciones dimanantes de los presentes Términos y
                    Condiciones Generales sin el previo consentimiento escrito de POLKO. POLKO podrá ceder, transferir,
                    delegar o disponer de los derechos u obligaciones derivados de la presente vinculación (incluyendo
                    su posición contractual), total o parcialmente, para lo cual, en virtud de su aceptación de los
                    presentes Términos y Condiciones Generales, los Usuarios otorgan su consentimiento expreso y de
                    manera previa para la realización de dichas acciones.
                </p>
                <br/>

                <p className="TyC__subtitle">
                    13- PROPIEDAD INTELECTUAL
                </p>
                <p className="TyC__text">
                    13.1. POLKO y/o sus sociedades controlantes, controladas, filiales o subsidiarias se reservan todos
                    los derechos, incluyendo los derechos de propiedad intelectual e industrial, asociados con los
                    servicios de POLKO, sus sitios web, los contenidos de sus pantallas, programas, bases de datos,
                    redes, códigos, desarrollo, software, arquitectura, hardware, contenidos, información, tecnología,
                    fases de integración, funcionalidades, dominios, archivos que permiten al Usuario acceder y crear su
                    Cuenta, herramientas de gestión, marcas, patentes, derechos de autor, diseños y modelos
                    industriales, nombres comerciales, entre otros, y declara que están protegidos por leyes nacionales
                    e internacionales vigentes.
                    <br/>
                    13.2. En ningún caso se entenderá que el Usuario tendrá algún tipo de derecho sobre los mismos
                    excepto para utilizar el servicio de POLKO conforme a lo previsto en estos Términos y Condiciones
                    Generales. El uso indebido o contrario a la normativa vigente de los derechos de propiedad
                    intelectual e industrial de POLKO, así como su reproducción total o parcial, queda prohibido, salvo
                    autorización expresa y por escrito de POLKO.
                    <br/>
                    13.3. Los Usuarios tampoco podrán comunicar que los productos o servicios que ofrecen son
                    patrocinados, promovidos, producidos, ofrecidos y/o vendidos por POLKO y deberán abstenerse de
                    realizar cualquier acto que pudiera causar un daño, pérdida de reputación, o disminución del valor
                    de los derechos de propiedad intelectual e industrial de POLKO.
                    <br/>
                    13.4. La Aplicación y/o el Sitio puede contener enlaces a sitios web de terceros. En virtud que
                    POLKO no tiene control sobre tales sitios, no será responsable por los contenidos, materiales,
                    acciones y/o servicios prestados por los mismos, ni por daños o pérdidas ocasionadas por la
                    utilización de éstos, causados directa o indirectamente. La presencia de enlaces a otros sitios web
                    no implica una sociedad, relación, aprobación, respaldo de POLKO con dichos sitios y sus contenidos.
                </p>
                <br/>

                <p className="TyC__subtitle">
                    14- INDEMNIDAD
                </p>
                <p className="TyC__text">
                    14.1. El Usuario mantendrá indemne a POLKO, así como a sus filiales, empresas controladas y/o
                    controlantes, funcionarios, directivos, sucesores, administradores, representantes y empleados, por
                    cualquier reclamo iniciado por otros Usuarios, terceros o por cualquier Organismo, relacionado con
                    sus actividades en la Aplicación y/o el Sitio, el cumplimiento y/o el incumplimiento de los Términos
                    y Condiciones Generales o demás Políticas, así como respecto de cualquier violación de leyes o
                    derechos de terceros.
                    <br/>
                    14.2. A tal fin, el Usuario faculta a POLKO a: i) intervenir y representarlo en dichos reclamos,
                    pudiendo celebrar los acuerdos transaccionales que considere oportunos y que tiendan a evitar
                    mayores costos y/o evitar eventuales contingencias sin limitación, en su nombre y representación;
                    ii) retener y debitar de su Cuenta y/o de la(s) cuenta(s) bancaria(s) registrada(s) en la Cuenta,
                    los fondos existentes y/o futuros para recuperar cualquier gasto incurrido en relación a los
                    supuestos del párrafo anterior, los cuales podrán incluir, entre otros, los honorarios de los
                    letrados intervinientes y las costas judiciales en una cantidad razonable, y/o iii) generar débitos
                    específicos en su facturación.
                </p>
                <br/>

                <p className="TyC__subtitle">
                    15- SOPORTE ELECTRÓNICO
                </p>
                <p className="TyC__text">
                    15.1. El Usuario acepta expresamente que la documentación e información relacionada con su
                    vinculación con POLKO y su accionar en la Aplicación tenga soporte electrónico. En todo caso, el
                    Usuario podrá hacer copias, incluso en soporte físico, de la documentación e información provista
                    por POLKO y la Aplicación. Las constancias emitidas en este marco por la Aplicación y/o el Sitio y
                    POLKO, serán consideradas prueba suficiente del cumplimiento o incumplimiento de las obligaciones de
                    las partes, sustituyen la necesidad de cualquier recibo en papel, y son plenamente oponibles al
                    Usuario. A tales efectos, el Usuario, los Consumidores, las Entidades Asociadas, cualquier otra
                    entidad y POLKO acuerdan otorgarle pleno valor probatorio a las constancias que se emitan en el
                    marco de la Aplicación y/o el Sitio, con el alcance establecido en la legislación vigente.
                </p>
                <br/>

                <p className="TyC__subtitle">
                    16- PROTECCIÓN DE DATOS
                </p>
                <p className="TyC__text">

                    16.1. Los Datos Personales que Ud. proporciona en el Registro se integrarán en una base de datos
                    personales del que es responsable POLKO, cuya dirección figura en el encabezamiento del presente
                    documento.
                    <br/>
                    16.2. El Usuario confirma y acepta que presta su consentimiento previo, expreso e informado para que
                    POLKO pueda tratar sus datos personales en un todo de acuerdo a lo dispuesto en las Políticas de
                    Privacidad que complementa el presente.

                </p>
                <br/>

                <p className="TyC__subtitle">
                    17- NOTIFICACIONES
                </p>
                <p className="TyC__text">
                    17.1. POLKO podrá realizar las notificaciones al Usuario a través de una notificación general en la
                    Aplicación y/o el Sitio, a través de mensajes de texto, y a la dirección de correo electrónico
                    facilitada por el Usuario en su Cuenta. El Usuario podrá comunicarse con POLKO mediante el envío de
                    un correo electrónico a la dirección consultas@polko.com.ar
                </p>
                <br/>

                <p className="TyC__subtitle">
                    18- ANEXOS
                </p>
                <p className="TyC__text">
                    18.1. Forman parte integral e inseparable de los Términos y Condiciones Generales, los siguientes
                    documentos y/o secciones de POLKO incorporados por referencia, donde se detallan políticas y/o
                    Términos y Condiciones de diferentes servicios ofrecidos en la Aplicación y/o el Sitio. Los mismos
                    se podrán consultar mediante el enlace abajo provisto o accediendo directamente a las páginas
                    correspondientes.
                </p>
                <br/>
                <p className="TyC__subtitle">
                    19- JURISDICCIÓN Y LEY APLICABLE
                </p>
                <p className="TyC__text">
                    19.1. Este acuerdo estará regido en todos sus puntos por las leyes vigentes en la República
                    Argentina.
                    <br/>
                    19.2. Cualquier controversia derivada del presente acuerdo, su existencia, validez, interpretación,
                    alcance o cumplimiento, será sometida ante la Justicia Provincial Ordinaria con sede en la ciudad de
                    Córdoba.
                </p>
                <br/>

                <p className="TyC__subtitle">
                    20- DOMICILIO
                </p>
                <p className="TyC__text">
                    20.1. Se fija como domicilio de POLKO S.A. en Avenida Rafael Núñez N° 3578 B° Cerro de las Rosas,
                    ciudad de Córdoba, Provincia de Córdoba, código postal 5000, República Argentina.
                </p>
                <br/>
            </div>
            <div className="TyC__button__container">
                <Button
                    type="button"
                    variant="filled"
                    color="primary"
                    enabled
                    marginX="sm"
                    size="lg"
                    onClick={() => setTycModalOpen(false)}
                >
                    Cerrar
                </Button>
            </div>
        </div>
    );
}

export default TermsAndConditions;