import formModel from './formModel';

const moment = require('moment');

const {
    formField: {
        vigenciaDesde,
        vigenciaHasta,
        frecuenciaPago,
        cuotas,
        ajusteAutomatico,
        formaDePago,
        infoDePago: {
            codigoAgencia,
            numeroCbu,
            marcaTarjeta,
            numeroTarjeta,
            vencimientoTarjeta,
        },
        emailDespachoElectronico,
        despachoElectronico,
        comentariosSolicitud,
        moduloCobertura,
        descuentoExtra,
        scoring: {
            garage,
            edadConductores,
            kmsAnuales,
        },
        bienesAsegurados: {
            id,
            descripcion,
            suma,
        },
        cliente: {
            rol,
            personaJuridica,
            dni,
            cuit,
            nombre,
            apellido,
            fechaNacimiento,
            estadoCivil,
            sexo,
            domicilio,
            codigosLocalidad,
            codigoArea,
            telefono,
            email,
            situacionIva,
            categoriaMonotributo,
            actividadPrincipal,
            lugarNacimiento
        },
        vehiculo: {
            patente,
            motor,
            chasis,
            marca,
            modelo,
            anio,
            tipoUso,
            valor,
            tieneGnc,
            ceroKm,
            equipoDeRastreo,
            gnc: {
                descripcionGnc,
                marcaRegulador,
                numeroRegulador,
                cilindros: {
                    marcaCilindro,
                    numeroCilindro,
                },
                valorTotal,
            },
            inspeccionPrevia: {
                comentariosInspeccion,
                fechaInspeccion,
                tipoCombustible,
                aspectoGeneral,
                estadoCristales,
                resultadoInspeccion,
                kilometraje,
                aspectoPintura,
                tipoPintura,
                aspectoChapa,
                aspectoNeumaticos,
                aspectoTapizado,
                colorTapizado,
                tipoTapizado,
                colorVehiculo,
                tipoLlantas,
            },
        },
    }
} = formModel;

export default {
    premium: '',
    [vigenciaDesde.name]: moment().format('YYYY-MM-DD'),
    [vigenciaHasta.name]: moment().add(1,"Y").format("YYYY-MM-DD"),
    [frecuenciaPago.name]: '5',
    [cuotas.name]: '',
    [ajusteAutomatico.name]: '',
    [formaDePago.name]: '0',
    infoDePago: {
        [numeroCbu.name]: '',
        [marcaTarjeta.name]: '',
        [numeroTarjeta.name]: '',
        [vencimientoTarjeta.name]: '',
    },
    [emailDespachoElectronico.name]: '',
    [despachoElectronico.name]: 'false',
    [comentariosSolicitud.name]: '',
    [moduloCobertura.name]: '',
    [descuentoExtra.name]: 0,
    scoring: {
        [garage.name]: true,
        [edadConductores.name]: false,
        [kmsAnuales.name]: false,
    },
    bienesAsegurados: [],
    clientes: [
        {
            [rol.name]: '2',
            [personaJuridica.name]: '0',
            [dni.name]: '',
            [cuit.name]: '',
            [nombre.name]: '',
            [apellido.name]: '',
            [fechaNacimiento.name]: '',
            [estadoCivil.name]: '2',
            [sexo.name]: '',
            [domicilio.name]: '',
            [codigosLocalidad.name]: '',
            [codigoArea.name]: '',
            [telefono.name]: '',
            [email.name]: '',
            [situacionIva.name]: '4',
            [categoriaMonotributo.name]: '1',
            [actividadPrincipal.name]: '10001',
            [lugarNacimiento.name]: 'Cordoba'
        },
    ],
    vehiculo: {
        [patente.name]: '',
        [motor.name]: '',
        [chasis.name]: '',
        [marca.name]: '',
        [modelo.name]: '',
        [anio.name]: '',
        [tipoUso.name]: '',
        [valor.name]: '',
        [tieneGnc.name]: '',
        [ceroKm.name]: 'false',
        [equipoDeRastreo.name]: '',
        gnc: {
            [descripcionGnc.name]: '',
            [marcaRegulador.name]: '',
            [numeroRegulador.name]: '',
            cilindros: [
                {
                    [marcaCilindro.name]: '',
                    [numeroCilindro.name]: '',
                },
            ],
            [valorTotal.name]: '',
        },
        inspeccionPrevia: {
            [comentariosInspeccion.name]: '',
            [fechaInspeccion.name]: moment().format('YYYY-MM-DD'),
            [tipoCombustible.name]: '1',
            [aspectoGeneral.name]: '2',
            [estadoCristales.name]: 'SinProblemas',
            [resultadoInspeccion.name]: '1',
            [kilometraje.name]: '15000',
            [aspectoPintura.name]: '2',
            [tipoPintura.name]: '2',
            [aspectoChapa.name]: '2',
            [aspectoNeumaticos.name]: '2',
            [aspectoTapizado.name]: '2',
            [colorTapizado.name]: '13',
            [tipoTapizado.name]: '2',
            [colorVehiculo.name]: 'A declarar',
            [tipoLlantas.name]: '2',
            archivos: []
        },
    }
};
