import React, {useContext, useEffect, useMemo, useState} from 'react';
import './CotizarMotovehiculo.css'
import {VehiculoModel} from "./FormStages/Cotizar/Vehiculo/VehiculoModel";
import VehiculoForm from "./FormStages/Cotizar/Vehiculo/VehiculoForm";
import axios from "axios";
import AuthContext from "../../../context/AuthContext";
import TitularForm from "./FormStages/Cotizar/Titular/TitularForm";
import {TitularModel} from "./FormStages/Cotizar/Titular/TitularModel";
import CotizarSuccessView from "./FormStages/Cotizar/CotizarSuccess/CotizarSuccessView";
import {FormCard} from "../../../components/FormCard";
import {useCurrentQuotationContext} from "../../../context/CurrentQuotationContext/CurrentQuotationContext";
import {FormRenderer} from "../../../components/FormRenderer";
import {Loading} from '../../../components/Loading'
import {ErrorModal} from "../../../layouts/ErrorModal";
import {PolizaModel} from "./FormStages/Emitir/Poliza/PolizaModel";
import {ClienteModel} from "./FormStages/Emitir/Cliente/ClienteModel";
import {InspeccionModel} from "./FormStages/Emitir/Inspeccion/InspeccionModel";
import PolizaForm from "./FormStages/Emitir/Poliza/PolizaForm";
import ClienteForm from "./FormStages/Emitir/Cliente/ClienteForm";
import DetallesVehiculoForm from "./FormStages/Emitir/DetallesVehiculo/DetallesVehiculoForm";
import SolicitudForm from "./FormStages/Emitir/Solicitud/SolicitudForm";
import InspeccionForm from "./FormStages/Emitir/Inspeccion/InspeccionForm";
import {DetallesVehiculoModel} from "./FormStages/Emitir/DetallesVehiculo/DetallesVehiculoModel";
import {SuccessModal} from "../../../layouts/SuccessModal";
import {Form, Formik} from "formik";
import {MantineSelectField} from "../../../components/FormFields/MantineSelectField";
import {Button} from "../../../components/Button";
import {useHistory, useLocation} from "react-router-dom";
import {isResponseSuccessful} from "../../../modules/utils/isResponseSuccessful";
import UserContext from "../../../context/UserContext";
import {advancedConfigSancor} from "../Automotor/AuxFunctions/advancedConfigSancor";
import {advancedConfigRUS} from "../Automotor/AuxFunctions/advancedConfigRUS";
import CotizarContext from "../../../context/CotizarContext";
import {getActiveCompaniesIdsOfProduct} from "../../../modules/utils/getActiveCompaniesIdsOfProduct";
import {advancedQuotation} from "../Automotor/AuxFunctions/advancedQuotation";
import {handleDownloadDocs} from "../../../modules/utils/handleDownloadDocs";
import showNotification from "../../../modules/utils/showNotification";
import {emissionDataGenerator} from "./AuxFunctions/emissionDataGenerator";
import MotovehiculoContextProvider from "../../../context/Products/MotovehiculoContext/MotovehiculoContextProvider";
import ContextLoading from "../../../modules/components/ContextLoading";
import loadMotovehiculoData from "../../../context/Products/MotovehiculoContext/loadMotovehiculoData";
import fetchContextData from "../../../context/utils/fetchContextData";
import MotovehiculoContext from "../../../context/Products/MotovehiculoContext/MotovehiculoContext";
import {buildLocationCode} from "../../Cotizacion/utils/buildLocationCode";
import _ from "lodash";
import {getLocalidadOptionsByZipCode} from "../../Cotizacion/utils/getLocalidadOptionsByZipCode";

const moment = require('moment');

function CotizarMotovehiculo(props) {
    const {recotizarValues, action, performReset} = props;
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalContent, setErrorModalContent] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const {
        selectedCompanies,
        cotizacionResponse,
        setCotizacionResponse,
        finalValues,
        setFinalValues,
        currentFormData,
        setCurrentFormData,
        formData,
        setFormData,
        activeStep,
        setActiveStep,
        emisionResponse,
        setEmisionResponse,
        setCartCoverages,
        setTotalInsurableByCompany,
        selectedCompaniesToPromote,
        localidadOptions
    } = useCurrentQuotationContext();
    const {companiesDict} = useContext(CotizarContext);
    const {authToken} = useContext(AuthContext);
    const {
        activeFormProduct,
        setActiveFormProduct,
        userCodigosOperacion,
        userCompaniesToPromote
    } = useContext(UserContext);
    const userCompaniesToPromoteByProduct = useMemo(() => {
        return userCompaniesToPromote.filter(company => companiesDict[company].products.some(product => product.key === activeFormProduct))
    }, [userCompaniesToPromote, activeFormProduct])
    const history = useHistory();
    const location = useLocation();
    const downloadPdfJsonTemplate = ["motovehiculo.detalle_marca", "motovehiculo.detalle_version", "motovehiculo.gnc", "motovehiculo.anio", "codigoPostal", "motovehiculo.sumaAseguradaAdicional", "nombreLocalidad"];
    const {vehicleDetails} = useContext(MotovehiculoContext);

    //Cotizar
    const {
        initialValues: cotizarVehiculoInitialValues,
        validationSchema: cotizarVehiculoValidationSchema
    } = VehiculoModel();
    const {initialValues: titularInitialValues, validationSchema: titularValidationSchema} = TitularModel();
    //Emitir
    const {initialValues: polizaInitialValues, validationSchema: polizaValidationSchema} = PolizaModel();
    const {initialValues: clienteInitialValues, validationSchema: clienteValidationSchema} = ClienteModel();
    const {
        initialValues: detallesVehiculoInitialValues,
        validationSchema: detallesVehiculoValidationSchema
    } = DetallesVehiculoModel();
    const {initialValues: inspeccionInitialValues, validationSchema: inspeccionValidationSchema} = InspeccionModel();

    const valuesAdjusterFromRecotizar = () => {
        if (action === "shortcut") {
            return recotizarValues;
        } else if (recotizarValues) {
            let adjustedConfiguracionAvanzada = {}

            for (let company in recotizarValues.configuracionAvanzada) {
                let companyConfig = recotizarValues.configuracionAvanzada[company]

                let newDates = {}
                if (company === "RUS") {
                    newDates = {
                        "vigenciaDesde": moment().format("YYYY-MM-DD"),
                        "vigenciaHasta": moment().add(1, "Y").format("YYYY-MM-DD"),
                    }
                } else { // "Sancor"
                    newDates = {
                        "fechaCotizacion": moment().format("YYYY-MM-DD"),
                    }
                }

                if (companyConfig) {
                    adjustedConfiguracionAvanzada[company] = {
                        ...companyConfig,
                        ...newDates
                    }
                }
            }

            const quotationData = {
                ...recotizarValues,
                _id: recotizarValues._id,
                "configuracionAvanzada": adjustedConfiguracionAvanzada
            }

            vehicleDetails.current = {
                detalle_marca: recotizarValues.motovehiculo.detalle_marca || "",
                detalle_version: recotizarValues.motovehiculo.detalle_version || "",
            }

            // Create return object omitting "idCanalDigital" property
            const {idCanalDigital, ...filteredQuotationData} = quotationData;
            return filteredQuotationData
        } else return null
    }

    //Se define cada paso
    const initialQuotationForm = [
        {
            name: "Vehículo",
            stepButtonName: "Siguiente",
            form: <VehiculoForm cotizacionRetomada={recotizarValues !== undefined}/>,
            initialValues: cotizarVehiculoInitialValues,
            validationSchema: cotizarVehiculoValidationSchema
        },
        {
            name: "Titular",
            stepButtonName: "Cotizar",
            form: <TitularForm/>,
            initialValues: titularInitialValues,
            validationSchema: titularValidationSchema
        },
        {
            name: "Cotización",
            stepButtonName: "Siguiente",
            form: <CotizarSuccessView submitForm={_submitCotizarForm}/>,
            initialValues: valuesAdjusterFromRecotizar(), //Include it in last step so it overrides previous initial values
            validationSchema: null
        },
        {
            name: "#Emisión",
            form: null,
            initialValues: null,
            validationSchema: null
        },
    ];

    const emisionFormDataByCompany = {
        "Sancor": [
            {
                name: "Póliza",
                stepButtonName: "Siguiente",
                form: <PolizaForm/>,
                initialValues: polizaInitialValues,
                validationSchema: polizaValidationSchema
            },
            {
                name: "Cliente",
                stepButtonName: "Siguiente",
                form: <ClienteForm/>,
                initialValues: clienteInitialValues,
                validationSchema: clienteValidationSchema
            },
            {
                name: "Vehículo",
                stepButtonName: "Siguiente",
                form: <DetallesVehiculoForm/>,
                initialValues: detallesVehiculoInitialValues,
                validationSchema: detallesVehiculoValidationSchema
            },
            {
                name: "Inspección",
                stepButtonName: "Siguiente",
                form: <InspeccionForm/>,
                initialValues: inspeccionInitialValues,
                validationSchema: inspeccionValidationSchema
            },
            {
                name: "Solicitud",
                stepButtonName: "Emitir",
                form: <SolicitudForm/>,
                initialValues: null,
                validationSchema: null
            },
        ],
        "RUS": [
            {
                name: "Póliza",
                stepButtonName: "Siguiente",
                form: <PolizaForm/>,
                initialValues: polizaInitialValues,
                validationSchema: polizaValidationSchema
            },
            {
                name: "Cliente",
                stepButtonName: "Siguiente",
                form: <ClienteForm/>,
                initialValues: clienteInitialValues,
                validationSchema: clienteValidationSchema
            },
            {
                name: "Vehículo",
                stepButtonName: "Siguiente",
                form: <DetallesVehiculoForm/>,
                initialValues: detallesVehiculoInitialValues,
                validationSchema: detallesVehiculoValidationSchema
            },
            {
                name: "Inspección",
                stepButtonName: "Siguiente",
                form: <InspeccionForm/>,
                initialValues: inspeccionInitialValues,
                validationSchema: inspeccionValidationSchema
            },
            {
                name: "Solicitud",
                stepButtonName: "Emitir",
                form: <SolicitudForm/>,
                initialValues: null,
                validationSchema: null
            },
        ],
    }

    //PRODUCT FUNCTIONS
    async function _submitCotizarForm(values, actions) {
        let url = process.env.REACT_APP_BACK_SERVER + '/newcotizar/motovehiculo';
        if (action !== "shortcut") values.companies = getActiveCompaniesIdsOfProduct(selectedCompanies, 'motovehiculo').concat(selectedCompaniesToPromote);
        if (values.companies.length < 4) {
            if (selectedCompaniesToPromote.length < userCompaniesToPromoteByProduct.length) {
                const newCompanies = userCompaniesToPromoteByProduct.filter(company =>
                    !selectedCompaniesToPromote.includes(company) && !values.companies.includes(companiesDict[company].id)
                );
                newCompanies.forEach(company => {
                    if (values.companies.length < 4) {
                        values.companies.push(companiesDict[company].id);
                    }
                });
            }
        }
        const companiesName = values.companies.map(company => companiesDict[company].name)
        const locationCodes = await buildLocationCode(companiesName, values.codigosLocalidad, companiesDict);
        values.codigoPostal = parseInt(values.codigoPostal);
        let selectedLocation = null;

        if (cotizacionResponse !== null) {
            values.is_saved = true;
            values.idCotizar = cotizacionResponse['idCotizar']
        }

        if (action && action !== "shortcut") {
            values.idCotizar = values['_id']
            if (action === "emitir") {
                const locations = await getLocalidadOptionsByZipCode(values.codigoPostal)
                const intCodigoLocalidad = parseInt(values.codigosLocalidad);
                selectedLocation = locations.find(localidad => localidad.value === intCodigoLocalidad)
            } else if (action === "recotizar" && localidadOptions.length > 0) {
                selectedLocation = localidadOptions[0].find(localidad => localidad.value === values.codigosLocalidad)
            }
        } else if (action === "shortcut" || !action) { // Only apply this if it's a new quotation
            if (localidadOptions.length > 0) selectedLocation = localidadOptions[0].find(localidad => localidad.value === values.codigosLocalidad)
            values.companies.forEach(company => {
                const companyName = companiesDict[company].name;
                const isPromotedCompany = userCompaniesToPromoteByProduct.includes(companyName)
                if (companyName === 'Sancor') {
                    advancedConfigSancor(recotizarValues, values, userCodigosOperacion, locationCodes, isPromotedCompany)
                }
                if (companyName === 'RUS') {
                    advancedConfigRUS(recotizarValues, values, userCodigosOperacion, locationCodes, isPromotedCompany)
                }
            })
        }

        values.motovehiculo = {
            ...values.motovehiculo,
            ...vehicleDetails.current
        }

        if (!values.nombreLocalidad) values.nombreLocalidad = selectedLocation ? selectedLocation.label.split(') ')[1] : "";

        let jsonData = {
            ...values,
            "vigencia": "1", //TODO: remove this when backend is fixed
            "valorAdicional": values.motovehiculo.sumaAseguradaAdicional, //TODO: remove this when backend is fixed
            "personaJuridica": values.tipoPersona, //TODO: remove this when backend is fixed
            "usoVehiculo": "2", //TODO: remove this when backend is fixed
            "origen": "POLKO",
            "saveToDatabase": true,
            "idCotizar": values.idCotizar
        }

        //Advanced quotation of only one company
        if (values.applyAdvancedConfiguration) {
            jsonData = advancedQuotation(
                jsonData,
                values.companyToQuote,
                companiesDict[values.companyToQuote]['id'],
                values.configuracionAvanzada
            )
        }

        axios.post(url, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            }
        }).then(res => {
            if (values.applyAdvancedConfiguration) {
                setCotizacionResponse(prevState => {
                    let dict = {...prevState}
                    dict[values.companyToQuote] = res.data[values.companyToQuote]
                    return dict
                })
            } else {
                setCotizacionResponse(res.data);
                setTotalInsurableByCompany(res.data)
            }

            jsonData['companies'].map(company => {
                if (res.data[companiesDict[company]['name']].sumaAseguradaVehiculo) {
                    values.configuracionAvanzada[companiesDict[company]['name']].sumaAseguradaVehiculo = parseInt(res.data[companiesDict[company]['name']].sumaAseguradaVehiculo)
                } else {
                    res.data[companiesDict[company]['name']].sumaAseguradaVehiculo = 0
                    values.configuracionAvanzada[companiesDict[company]['name']].sumaAseguradaVehiculo = 0
                }
            })
        }).catch(err => {
            setErrorModalContent({
                title: "Hubo un problema al cotizar, por favor vuelva a intentarlo más tarde.",
                typeError: "Ha ocurrido un error en la comunicación con el servicio",
                suggestedActions: ["Revisá que la información ingresada sea correcta", "Reintentá el proceso una vez más", "Si el problema persiste ponete en contacto con el área de soporte"],
                detailedError: err.response?.data?.detail?.message || "Sin datos del error",
            })
            setErrorModalOpen(true)
        }).finally(() => {
            if (actions)
                actions.setSubmitting(false);

            if (values.applyAdvancedConfiguration) {
                setFinalValues(prevState => {
                    let dict = {...prevState}
                    dict.configuracionAvanzada[values.companyToQuote] = jsonData.configuracionAvanzada[values.companyToQuote]
                    return dict
                })
            } else {
                setFinalValues(values);
            }
        });
    }

    async function _submitEmitirForm(values, actions) {
        let url = process.env.REACT_APP_BACK_SERVER + '/newemitir/motovehiculo';

        setFinalValues(values)
        const jsonData = emissionDataGenerator(_.cloneDeep(values))

        axios.post(url, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            }
        }).then(res => {
            setEmisionResponse(res.data)
            actions.setSubmitting(false);
            if (isResponseSuccessful(res.data["status_code"])) {
                setSuccessModalOpen(true)
            } else {
                setErrorModalContent({
                    title: "Hubo un problema al emitir, por favor vuelva a intentarlo más tarde.",
                    typeError: res.data['detail']['code'],
                    suggestedActions: ["Revisá que la información ingresada sea correcta", "Evitá incluir caracteres especiales", "Reintente el proceso"],
                    detailedError: res.data['detail']['message'],
                })
                setErrorModalOpen(true);
            }
            setIsLoading(false)
        }).catch(err => {
            setErrorModalContent({
                title: "Hubo un problema al emitir, por favor vuelva a intentarlo más tarde.",
                typeError: "Ha ocurrido un error en la comunicación con el servicio",
                suggestedActions: ["Revisá que la información ingresada sea correcta", "Reintentá el proceso una vez más", "Si el problema persiste ponete en contacto con el área de soporte"],
                detailedError: err.response?.data?.detail?.message || err.toString() || "Sin datos del error",
            })
            setErrorModalOpen(true);
            actions.setSubmitting(false);
            setIsLoading(false)
        });
    }

    const downloadDocumentation = async (formValues, setSubmitting) => {
        setSubmitting(true)

        let url = process.env.REACT_APP_QUOTATION_MICROSERVICE + '/documentacion/motovehiculo'
        try {
            await handleDownloadDocs(
                url,
                finalValues.company,
                formValues.documento,
                emisionResponse['identificadores'],
                authToken
            );
        } catch (error) {
            showNotification({
                autoClose: 3000,
                color: 'red',
                status: 'error',
                title: 'Error al descargar la documentación.',
                message: error,
            });
        }

        setSubmitting(false)
    };

    function _handleSubmit(values, actions) {
        let emisionStep = 99;
        if (values['company'])
            emisionStep = formData['cotizacion'].length + formData['emision'][values['company']].length - 1;

        actions.setSubmitting(true);
        if (activeStep === emisionStep) {
            setIsLoading(true)
            _submitEmitirForm(values, actions);
        } else {
            setActiveStep(activeStep + 1);
            if (activeStep === 1) {
                _submitCotizarForm(values, actions);
            } else {
                actions.setTouched({});
                actions.setSubmitting(false);
            }
        }
    }

    function _handleBack() {
        if (activeStep === 2) {
            setCotizacionResponse(null);
            setCartCoverages([])
        }
        if (activeStep > 0)
            setActiveStep(activeStep - 1);
    }

    useEffect(() => {
        setCurrentFormData(initialQuotationForm)
        setFormData({
            "cotizacion": initialQuotationForm,
            "emision": emisionFormDataByCompany
        })

        setActiveStep(0) //Reset active step
        setCartCoverages([]) //Reset cart coverages
        setActiveFormProduct("motovehiculo") //Name has to be the route path

        if (action === "emitir") {
            setActiveStep(initialQuotationForm.length - 2)
            _submitCotizarForm(valuesAdjusterFromRecotizar(), null);
        }
    }, [performReset, selectedCompanies, activeFormProduct]);

    if (!currentFormData || location.pathname.split("/")[3] !== activeFormProduct) return <FormCard active={activeStep}><Loading/></FormCard>
    return (
        <div className="motovehiculo__form__container">
            <FormCard active={activeStep}>
                <FormRenderer
                    handleSubmit={_handleSubmit}
                    handleBack={_handleBack}
                    isLoading={isLoading}
                    doNotDisplayNextButton={[2]}
                    product={"MOTOVEHICULO"}
                    performReset={performReset}
                    downloadPdfJsonTemplate={downloadPdfJsonTemplate}
                    clientsFieldName="clientes"
                />
            </FormCard>

            {errorModalOpen &&
                <ErrorModal
                    closeAction={() => {
                        if (currentFormData[0]['name'] === "#Cotización") { //Indicates an error during emision
                            setActiveStep(formData['cotizacion'].length)
                            setEmisionResponse(null)
                        } else {
                            setActiveStep(0)
                            setCotizacionResponse(null)
                        }
                        setErrorModalOpen(false)
                    }}
                    title={errorModalContent.title}
                    typeError={errorModalContent.typeError}
                    suggestedActions={errorModalContent.suggestedActions}
                    detailedError={errorModalContent.detailedError}
                />
            }

            {successModalOpen &&
                <SuccessModal
                    closeAction={() => {
                        setSuccessModalOpen(false)
                        setCotizacionResponse(null)
                        setEmisionResponse(null)
                        history.push({pathname: '/u/dashboard'});
                    }}
                    title={"¡Felicitaciones, la operación se ha realizado con éxito!"}
                    bodyItems={
                        emisionResponse['identificadores'].map(id => {
                            return {name: id.nombre, value: id.valor}
                        })
                    }
                    footer={
                        <div className="motem__success__footer__container">
                            <p className="motem__success__footer__subtitle">Documentación</p>
                            <Formik
                                initialValues={{documento: emisionResponse['documentacion'][0]}}
                                onSubmit={() => {
                                }}
                            >
                                {({isSubmitting, values, setSubmitting}) => (
                                    <Form method="post" style={{width: "70%"}}>
                                        <MantineSelectField
                                            name={`documento`}
                                            label={""}
                                            data={
                                                emisionResponse['documentacion'].map(doc => {
                                                    return {value: doc, label: doc}
                                                })
                                            }
                                        />
                                        <div className="motem__success__footer__button__container">
                                            <Button
                                                type="button"
                                                disabled={isSubmitting}
                                                variant="filled"
                                                color="primary"
                                                marginY="sm"
                                                enabled
                                                onClick={() => downloadDocumentation(values, setSubmitting)}
                                            >
                                                Descargar
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                            <p className="motem__success__footer__text">
                                Descargá los documentos y anotá los
                                números de identificación para próximas consultas.
                            </p>

                            <img className="motem__success__footer__logo"
                                 src={companiesDict[finalValues.company]['logo']}
                                 alt={finalValues.company}/>
                        </div>
                    }
                />
            }
        </div>
    );
}

function withMotovehiculoData(WrappedComponent) {
    return function WrappedWithMotovehiculoData(props) {
        const [loadingPage, setLoadingPage] = useState(true);
        const [errorPage, setErrorPage] = useState(null);
        const [motovehiculoData, setMotovehiculoData] = useState(null);
        const {authToken} = useContext(AuthContext);

        useEffect(() => {
            fetchContextData([() => loadMotovehiculoData(authToken)], [setMotovehiculoData], setLoadingPage, ['motovehiculoMarcas'])
                .then(errors => {
                        if (errors.length > 0) {
                            setErrorPage({
                                title: "Hubo un problema al cargar la información del usuario, por favor vuelva a intentarlo más tarde.",
                                typeError: "Ha ocurrido un error en la comunicación con el servicio",
                                suggestedActions: ["Reintentá el proceso una vez más", "Si el problema persiste ponete en contacto con el área de soporte"],
                                detailedError: errors[0].error.response?.data?.detail || "Sin datos del error",
                            })
                        }
                    }
                )
        }, [authToken]);

        if (loadingPage) return <ContextLoading/>

        if (errorPage) return <ErrorModal closeAction={() => setErrorPage(null)} title={errorPage.title}
                                          typeError={errorPage.typeError}
                                          suggestedActions={errorPage.suggestedActions}
                                          detailedError={errorPage.detailedError}/>

        return (
            <MotovehiculoContextProvider initialData={motovehiculoData}>
                <WrappedComponent {...props} />
            </MotovehiculoContextProvider>
        );
    };
}

export default withMotovehiculoData(CotizarMotovehiculo);