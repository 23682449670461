import React, {useState} from 'react';
import {Input} from '@mantine/core';
import './MantineInput.css';
import {ReactComponent as ExclamationIcon} from "../../assets/icons/ic_exclamation.svg"

function MantineInput(props) {
    const {label, name, errorText, haveError, initialValue} = props;
    const [value, setValue] = useState(initialValue);

    return (
        <Input.Wrapper
            {...props}
            id={`wrapper-${name}`}
            label={label}
            error={haveError && <div>{errorText}</div>}
            classNames={{
                wrapper: 'mantine__inputWrapper',
                invalid: 'mantine__inputWrapper',
                label: haveError ? 'mantine__inputLabel--error' : 'mantine__inputLabel',
                required: haveError ? 'mantine__inputRequired--error' : 'mantine__inputRequired',
                error: 'mantine__inputError',
            }}
        >
            <Input id={name}
                   {...props}
                   value={value}
                   onChange={(e) => setValue(e.target.value)}
                   classNames={{
                       input: haveError ? 'mantine__inputField--error' : 'mantine__inputField',
                   }}
                   rightSection={
                       haveError && <ExclamationIcon className="mantine__inputIcon"/>
                   }
            />
        </Input.Wrapper>
    );
}

export default MantineInput;