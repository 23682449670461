import React, {
    useEffect,
    useState,
    useMemo, useContext, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import DashboardContext from "./DashboardContext";
import axios from "axios";
import AuthContext from "../AuthContext";
import UserContext from "../UserContext";
// import getInfoUrl from "../../modules/utils/getInfoUrl";

const DashboardContextProvider = ({
                                      children,
                                  }) => {

    const [cotizaciones, setCotizaciones] = useState(null)
    const [emisiones, setEmisiones] = useState(null)
    const [infoCotizaciones, setInfoCotizaciones] = useState(null)
    const [infoCotizacionesCD, setInfoCotizacionesCD] = useState(null)
    const [billeteraData, setBilleteraData] = useState(null)
    const [fecha, setFecha] = useState(null);
    const [datosCoberturas, setDatosCoberturas] = useState(null);
    const [rankingEmisores, setRankingEmisores] = useState(null);
    const [dashboardContextError, setDashboardContextError] = useState(null);
    const [newsletterData, setNewsletterData] = useState(null);
    const [isReady, setIsReady] = useState(false);
    const [currentAcuerdo, setCurrentAcuerdo] = useState(null);
    const [beneficiosDisponibles, setBeneficiosDisponibles] = useState(null);

    const {authToken} = useContext(AuthContext)
    const {setPersonalRanking} = useContext(UserContext)

    const [modified, setModified] = useState(false);
    const handleModified = useCallback(() => {
        setModified(!modified)
    }, [modified]);

    // TODO: Cuando queramos poner newsletter, fijarse que onda esto, pero hay que darle una vuelta de rosca para mi
    // const handleNewsletterData = useCallback(async (urlsArray) => {
    //     const tempNewsletterData = await Promise.all(urlsArray?.map( async (newsletter) => {
    //         const data = await getInfoUrl(newsletter.url);
    //         return data;
    //     }));
    //     setNewsletterData(tempNewsletterData);
    // },[newsletterData])

    useEffect(() => {

        let shouldUpdate = true;

        const init = async () => {
            let url = process.env.REACT_APP_BACK_SERVER + '/newgetDatosDashboard'
            const requestOne = axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                }
            });
            url = process.env.REACT_APP_BACK_SERVER + '/polkistas/getEstadisticasGenerales'
            const bestSellerCoverageRequest = axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                }
            })
            // TODO: Las 2 lineas de abajo hay que eliminar cuando en el back pongan esta data en /polkistas/getEstadisticasGenerales
            url = process.env.REACT_APP_BACK_SERVER + '/getEstadisticasBilletera'
            const billeteraRequest = axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                }
            });
            url = process.env.REACT_APP_BACK_SERVER + '/getRanking'
            const rankingRequest = axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                }
            });
            url = process.env.REACT_APP_BACK_SERVER + '/getCurrentAcuerdo'
            const currentAcuerdoRequest = axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                }
            });
            url = process.env.REACT_APP_BACK_SERVER + '/getBeneficiosDisponibles'
            const beneficiosDisponiblesRequest = axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                }
            });
            axios.all([requestOne, bestSellerCoverageRequest, billeteraRequest, rankingRequest, currentAcuerdoRequest, beneficiosDisponiblesRequest]).then(axios.spread((...responses) => {
                if (shouldUpdate) {
                    const responseOne = responses[0]
                    const bestSellerCoverageResponse = responses[1]
                    const billeteraResponse = responses[2]
                    const rankingResponse = responses[3]
                    const currentAcuerdoResponse = responses[4]
                    const beneficiosDisponiblesResponse = responses[5]

                    //PROCESANDO LA PRIMERA CONSULTA
                    setCotizaciones(responseOne['data']['infoCotizaciones'])
                    setEmisiones(responseOne['data']['emisiones'])
                    const cotizacionesPolko = []
                    const cotizacionesClienteDirecto = []

                    /* Filtering the data from the response and creating two arrays, one for POLKO and one for Cliente
                    Directo. */
                    responseOne['data']['infoCotizaciones'].map((cotizacion) => {
                        if (cotizacion['origen'] === 'POLKO')
                            cotizacionesPolko.push(cotizacion)
                        else
                            cotizacionesClienteDirecto.push(cotizacion)

                    })
                    setInfoCotizaciones(cotizacionesPolko.sort((a, b) => {
                        return new Date(b.updated_at) - new Date(a.updated_at); // descending
                    }))
                    setInfoCotizacionesCD(cotizacionesClienteDirecto.sort((a, b) => {
                        return new Date(b.updated_at) - new Date(a.updated_at); // descending
                    }))
                    setFecha(responseOne['data']['fecha'])
                    setBilleteraData(billeteraResponse['data'])

                    //PROCESANDO LA SEGUNDA CONSULTA
                    setDatosCoberturas(bestSellerCoverageResponse.data)
                    setRankingEmisores(rankingResponse.data)
                    setPersonalRanking(rankingResponse['data']['Personal'])

                    setCurrentAcuerdo(Object.keys(currentAcuerdoResponse.data).length > 0 ? currentAcuerdoResponse.data : null)
                    setBeneficiosDisponibles(beneficiosDisponiblesResponse.data)

                    setIsReady(true);
                }
            }))
                .catch(error => {
                    setDashboardContextError({
                        title: "Hubo un problema al cargar la información del dashboard, por favor vuelva a intentarlo más tarde.",
                        typeError: "Ha ocurrido un error en la comunicación con el servicio",
                        suggestedActions: ["Reintentá el proceso una vez más", "Si el problema persiste ponete en contacto con el área de soporte"],
                        detailedError: error.response?.data?.detail?.message || error.toString() || "Sin datos del error",
                    })
                })
        }

        init();
        return () => {
            /* This cleanup function is used to prevent updating the state
            when the component is unmounted */
            shouldUpdate = false;
        };
    }, [authToken, modified])

    const context = useMemo(() => ({
        cotizaciones,
        emisiones,
        infoCotizaciones,
        infoCotizacionesCD,
        billeteraData,
        fecha,
        datosCoberturas,
        rankingEmisores,
        dashboardContextError,
        setDashboardContextError,
        handleModified,
        isReady,
        newsletterData,
        currentAcuerdo,
        setCurrentAcuerdo,
        beneficiosDisponibles,
        // handleNewsletterData,
    }), [
        cotizaciones,
        emisiones,
        infoCotizaciones,
        infoCotizacionesCD,
        billeteraData,
        fecha,
        datosCoberturas,
        rankingEmisores,
        dashboardContextError,
        setDashboardContextError,
        handleModified,
        isReady,
        newsletterData,
        currentAcuerdo,
        setCurrentAcuerdo,
        beneficiosDisponibles,
        // handleNewsletterData
    ]);
    return (
        <DashboardContext.Provider value={context}>
            {children}
        </DashboardContext.Provider>
    );
};

DashboardContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default DashboardContextProvider;