import React, {useState, useEffect, useContext} from "react";
import "./Notifications.css"
import {ReactComponent as BellIcon} from '../../assets/icons/ic_bell.svg';
import {ReactComponent as NotificationIcon} from '../../assets/icons/ic_notification.svg';
import {ReactComponent as ArrowRightIcon} from '../../assets/icons/ic_arrow_right.svg';
import PopUpCardModal from "../../layouts/PopUpCardModal/PopUpCardModal";
import NotificationsDetail from "./Details/NotificationsDetail";
import NotificationBar from "./Bar/NotificationBar"
import Newsletter from "./Newsletter/Newsletter"
import {gql, useMutation, useSubscription} from "@apollo/client";
import {BoxDialog} from "../../components/BoxDialog"
import Tabs from "../../components/Tabs/Tabs"
import store from 'store'
import NotificationsPopUpButtons from "./PopUpButtons/NotificationsPopUpButtons";
import useWindowDimensions from "../../modules/utils/useWindowDimensions";
import Loading from "../../modules/components/Loading";
import DashboardContext from "../../context/DashboardContext";
import update from "store/plugins/update";
import moment from "moment";


const NEW_NOTIFICATION = gql`
  subscription MySubscription {
    General_Notificaciones(order_by: { created_at: desc }) {
      id
      id_Auth
      mensaje
      titulo
      created_at
      leido
    }
  }
`;

const MARK_AS_READ_MUTATION = gql`
    mutation MyMutation($id: Int!, $read: Boolean!) {
        update_General_Notificaciones(where: {id: {_eq: $id}}, _set: {leido: $read}) {
            affected_rows
        }
    }
`;

const MOCKnewsletters = [{
    id: 40,
    url: "https://www.cadena3.com/noticia/siempre-juntos-rosario/julio-jerez-padre-de-maximo-seguimos-en-el-barrio-y-amenazados_351930",
    leido: true
}, {
    id: 41,
    url: "https://www.lavoz.com.ar/politica/anibal-fernandez-en-rosario-es-imperioso-que-nos-metamos-en-cada-lugar-y-lleguemos-hasta-el-hueso/",
    leido: true
}, {
    id: 42,
    url: "https://www.clarin.com/politica/sorpresa-encuesta-midio-balotaje-presidencial-larreta-bullrich-massa-kicillof_0_bdu1cXBMsu.html",
    leido: false
}, {
    id: 43,
    url: "https://tn.com.ar/economia/2023/03/09/mientras-crece-la-pelea-por-la-coparticipacion-nacion-y-ciudad-negocian-el-traspaso-de-32-lineas-de-colectivo/",
    leido: false
}];

function Notifications() {
    const [isOpenPreviewBox, setIsOpenPreviewBox] = useState(false);
    const [openCenter, setOpenCenter] = useState(false);
    const [localStorageChanged, setLocalStorageChanged] = useState(false);
    const [detailsOpen, setDetailsOpen] = useState(null);
    const {data, loading} = useSubscription(NEW_NOTIFICATION);
    const [markAsRead] = useMutation(MARK_AS_READ_MUTATION);
    const [orderedNotifications, setOrderedNotifications] = useState(null);
    const [markAsReadOption, setMarkAsReadOption] = useState(false);
    const {newsletterData} = useContext(DashboardContext)
    const [notificationsMarked, setNotificationsMarked] = useState({});
    const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);

    const {width} = useWindowDimensions();


    const readGlobalNotifications = store.get("readGlobalNotifications");
    if (readGlobalNotifications === undefined)
        store.set("readGlobalNotifications", []);

    function filterNotificationsPrevious30Days(_notifications) {
        const todaysDate = moment();
        return _notifications.filter((_notification) => {
            const notificationDate = moment(_notification.created_at);
            const differenceDays = todaysDate.diff(notificationDate, 'days');
            return differenceDays <= 30;
        });
    }

    const orderData = () => {
        let unread = [];
        let read = [];

        const filteredNotifications = filterNotificationsPrevious30Days(data?.General_Notificaciones);

        if (filteredNotifications.length !== 0) {
            for (let notification of filteredNotifications) {
                if (notification.id_Auth === null) {
                    if (readGlobalNotifications.includes(notification.id))
                        read.push(notification);
                    else unread.push(notification);
                } else {
                    if (notification.leido)
                        read.push(notification);
                    else unread.push(notification);
                }
            }
            setUnreadNotificationsCount(unread.length);
            unread.push.apply(unread, read);
            setOrderedNotifications(unread);
        }
    };

    useEffect(() => {
            if (data) {
                orderData();
            }
            if (localStorageChanged) {
                setLocalStorageChanged(false)
            }
        },
        [data, newsletterData, localStorageChanged]);





    const handlePreviewBox = () => {
        if (width > 480) {
            setIsOpenPreviewBox(!isOpenPreviewBox)
            setOpenCenter(false)
        } else {
            setOpenCenter(true)
        }
    };

    const handleOpenCenter = (toOpen) => {
        setNotificationsMarked({})
        setOpenCenter(toOpen);
        setIsOpenPreviewBox(false);
    };

    const handleCloseDetails = () => {
        setDetailsOpen(null)
        setNotificationsMarked({})
    }

    const handleOpenDetails = (notificationIndex) => {
        setDetailsOpen(notificationIndex);
        if (isOpenPreviewBox) {
            setOpenCenter(true);
            setIsOpenPreviewBox(false)
        }

        if ((!notificationIndex.leido && notificationIndex.id_Auth) || !readGlobalNotifications.includes(notificationIndex.id)) {
            updateValue({[notificationIndex.id]: notificationIndex}, true)
        }
        setNotificationsMarked({[notificationIndex.id]: notificationIndex});
    }

    // Añade al arreglo de notificaciones marcadas el indice que ocupa la notificacion recientemente marcada en el arreglo de notificaciones
    function handleNotificationsMarkedArray(notificationTarget, isChecked, readed) {
        if (isChecked) {
            setNotificationsMarked(prevDict => ({...prevDict, [notificationTarget.id]: notificationTarget}));
            if (Object.keys(notificationsMarked).length === 0) {
                setMarkAsReadOption(readed)
            }
        } else {
            setNotificationsMarked(prevDict => {
                const newDict = {...prevDict};
                delete newDict[notificationTarget.id];
                return newDict;
            });
            // if (readed === markAsReadOption) {
            //     setMarkAsReadOption(!markAsReadOption)
            // }
        }
        // if (readed !== markAsReadOption) {
        //     setMarkAsReadOption(false)
        // }
        setMarkAsReadOption(readed)
    }

    store.addPlugin(update);

    const handleReadGlobalNotification = (notification, readStatus) => {
        // TODO: Cambiar el uso de estas librerias, por el uso de localstorage.getItem y setItem.
        if (readStatus) {
            store.update('readGlobalNotifications', [], (readGlobalNotifications) => {
                if (!store.get('readGlobalNotifications')?.includes(notification.id)) {
                    readGlobalNotifications.push(notification.id);
                    setLocalStorageChanged(true);
                }
            });
        } else {
            store.update('readGlobalNotifications', (readGlobalNotifications) => {
                let index = readGlobalNotifications.indexOf(notification.id);
                if (index > -1) {
                    readGlobalNotifications.splice(index, 1);
                    setLocalStorageChanged(true);
                }
            });
        }
    }


    function updateValue(notificationTarget, newValue) {
        const copyArray = [...orderedNotifications];
        copyArray.forEach(notification => {
            if (notificationsMarked.hasOwnProperty(notification.id) || notificationTarget.hasOwnProperty(notification.id)) {
                if (notification.id_Auth) {
                    markAsRead({variables: {id: notification.id, read: newValue}}).then(() => {
                        notification.leido = newValue;
                    });
                } else {
                    handleReadGlobalNotification(notification, newValue);
                }
                setNotificationsMarked({})
            }
        });
        setOrderedNotifications(copyArray);
        setMarkAsReadOption(newValue);
        if (!detailsOpen) {
            setNotificationsMarked({});
        }
    }

    // const selectAll = () => {
    //     orderedNotifications.forEach(notification => {
    //         setNotificationsMarked(prevDict => ({...prevDict, [notification.id]: notification}));
    //     })
    // }

    const resetNotificationsMarked = () => {
        setNotificationsMarked({})
    }

    return (
        <div>
            <div className="notification__iconContainer" onClick={() => handlePreviewBox()}>
                <BellIcon className="notification__bellIcon"/>
                <div className="notification__unreadInBellContainer">
                    {unreadNotificationsCount > 0 && <NotificationIcon className="notification__newAlert"/>}
                </div>
            </div>
            {isOpenPreviewBox &&
                <div className="notification__boxContainer">
                    <BoxDialog setDisplay={setIsOpenPreviewBox}>
                        <div>
                            <div className="notification__header">
                                <p> Notificaciones </p>
                                <button onClick={() => handleOpenCenter(true)}>Ver más <span
                                    className="notification__arrowRightIcon"><ArrowRightIcon/></span>
                                </button>
                            </div>
                            <div className="notification__boxTabContainer">
                                <Tabs ovalTab tiny
                                      tabs={["TODAS (" + (orderedNotifications !== null ? orderedNotifications?.length : 0) + ")", "NO LEÍDAS (" + (unreadNotificationsCount ? unreadNotificationsCount : 0) + ")"]}
                                      tabContent={
                                          [loading ? <div className="notification__loadingContainer"><Loading/></div> :
                                              <div className="notification__barsContainer">
                                                  {orderedNotifications?.length > 0 ? orderedNotifications.map((row, index) => {
                                                      return (
                                                          index < 10 && (
                                                              <NotificationBar
                                                                  tableRow={row}
                                                                  details={handleOpenDetails}
                                                                  tiny/>
                                                          ))
                                                  })
                                                  :
                                                  <div className="notification__emptyNotificationsTextAlert">No hay notificaciones disponibles</div>
                                                  }
                                              </div>
                                              ,
                                              loading ? <div className="loadingContainer"><Loading/></div> :
                                                  <div className="notification__barsContainer">
                                                      {unreadNotificationsCount > 0 ? orderedNotifications?.map((row, index) => {
                                                          if ((!row.leido && row.id_Auth !== null) || (!store.get("readGlobalNotifications")?.includes(row.id) && row.id_Auth === null)) {
                                                              return (
                                                                  index < 10 && (
                                                                      <NotificationBar
                                                                          tableRow={row}
                                                                          details={handleOpenDetails}
                                                                          tiny/>
                                                                  ))
                                                          }
                                                      })
                                                          :
                                                      <div className="notification__emptyNotificationsTextAlert">No hay notificaciones sin leer</div>
                                                      }</div>,
                                              // newsletterData ?
                                              //     (<div className="notification__barsContainer">
                                              //         {newsletterData?.map((row, index) =>
                                              //             index < 10 && (<Newsletter
                                              //                 tableRow={row}
                                              //                 tiny newsletter
                                              //                 url={row.url}/>))}
                                              //     </div>) : <div className="notification__loadingContainer"><Loading/></div>
                                          ]}/>
                            </div>
                        </div>
                    </BoxDialog>
                </div>
            }
            {openCenter && <PopUpCardModal fullSpace
                                           body={
                                               detailsOpen ?
                                                   <div className="notificationCenterContainer">
                                                       <NotificationsDetail content={detailsOpen}/>
                                                   </div> :
                                                   <div style={{marginTop: "16px", width: "100%", height: "100%"}}>
                                                       <Tabs ovalTab
                                                             tabs={[<span onClick={resetNotificationsMarked}>TODAS ({orderedNotifications !== null ? orderedNotifications?.length : 0})</span>, <span onClick={resetNotificationsMarked}>NO LEÍDAS ({unreadNotificationsCount})</span>]}
                                                             tabContent={
                                                                 [loading ?
                                                                     <div className="notification__loadingContainer">
                                                                         <Loading/>
                                                                     </div> :
                                                                     <div className="notificationCenterContainer">
                                                                         {orderedNotifications?.length > 0 ? orderedNotifications?.map((row, index) => {
                                                                             return (
                                                                                 <NotificationBar
                                                                                     tableRow={row}
                                                                                     arrayIndex={index}
                                                                                     details={handleOpenDetails}
                                                                                     notificationsMarkedArr={notificationsMarked}
                                                                                     checkBoxEnabled
                                                                                     onCheckboxChange={handleNotificationsMarkedArray}/>
                                                                             )
                                                                         })
                                                                             :
                                                                             <div className="notification__emptyNotificationsTextAlert">
                                                                                 No hay notificaciones disponibles
                                                                             </div>
                                                                         }
                                                                     </div>
                                                                     ,
                                                                     loading ?
                                                                         <div
                                                                             className="notification__loadingContainer">
                                                                             <Loading/>
                                                                         </div>
                                                                         :
                                                                         <div className="notificationCenterContainer">
                                                                             {unreadNotificationsCount > 0 ? orderedNotifications?.map((row, index) => {
                                                                                 if ((!row.leido && row.id_Auth !== null) || (!store.get("readGlobalNotifications")?.includes(row.id) && row.id_Auth === null)) {
                                                                                     return (
                                                                                         <NotificationBar
                                                                                             tableRow={row}
                                                                                             arrayIndex={index}
                                                                                             details={handleOpenDetails}
                                                                                             notificationsMarkedArr={notificationsMarked}
                                                                                             checkBoxEnabled
                                                                                             onCheckboxChange={handleNotificationsMarkedArray}/>
                                                                                     )
                                                                                 }
                                                                             })
                                                                                 :
                                                                                 <div className="notification__emptyNotificationsTextAlert">
                                                                                     No hay notificaciones sin leer
                                                                                 </div>
                                                                             }
                                                                         </div>,
                                                                     // newsletterData ?
                                                                     //     (<div className="notificationCenterContainer">
                                                                     //         {newsletterData?.map((row) =>
                                                                     //             <Newsletter
                                                                     //                 tableRow={row}
                                                                     //                 newsletter
                                                                     //                 url={row.url}/>)}
                                                                     //     </div>) :
                                                                     //     <div
                                                                     //         className="notification__loadingContainer">
                                                                     //         <Loading/>
                                                                     //     </div>
                                                                 ]}/>
                                                   </div>
                                           }
                                           title={"Notificaciones"}
                                           closeAction={() => {
                                               handleCloseDetails()
                                               handleOpenCenter(false)
                                           }}
                                           back={detailsOpen ? handleCloseDetails : null}
                                           footer={
                                               <div className="popUpButtonsContainer">
                                                   {/*<div onClick={selectAll}>Seleccionar todas</div>*/}
                                                   {(Object.keys(notificationsMarked).length !== 0 || detailsOpen) &&
                                                       <NotificationsPopUpButtons
                                                           visible={markAsReadOption}
                                                           eventOnClickMails={updateValue}
                                                           arrToApply={notificationsMarked}
                                                       />}
                                               </div>
                                           }/>}
        </div>
    )
}

export default Notifications;