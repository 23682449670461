import React, {useEffect, useState} from "react";
import './Tabs.css';
import {Tab} from "../Tab";
import {OvalTab} from "../OvalTab";

function Tabs(props) {
    const {tabs, tabContent, ovalTab, tiny, color, activeTab: activeTabProp, setActiveTab: setActiveTabProp, tabType, blurInactiveTabs, initialTab} = props;
    const [activeTabState, setActiveTabState] = useState(initialTab || 0);

    const activeTab = activeTabProp !== undefined ? activeTabProp : activeTabState;
    const setActiveTab = setActiveTabProp || setActiveTabState;

    function handleTabClick(index) {
        setActiveTab(index);
    }

    useEffect(() => {
        setActiveTab(initialTab || 0);
    }, [initialTab]);

    return (
        <div className="tabs__container">
            <div className="tabs__header__container">
                {ovalTab ? tabs.map((tab, index) => (
                    <OvalTab key={index} active={activeTab === index} tiny={tiny} color={color} onClick={() => handleTabClick(index)}>
                        {tab}
                    </OvalTab>
                )) : tabs.map((tab, index) => (
                    <Tab key={index} active={activeTab === index} onClick={() => handleTabClick(index)} type={tabType} blurInactiveTabs={blurInactiveTabs}>
                        {tab}
                    </Tab>
                ))}
            </div>
            <>
                {tabContent.map((content, index) => (
                    <React.Fragment key={index}>
                        {activeTab === index ? content : null}
                    </React.Fragment>
                ))}
            </>
        </div>
    );
}

export default Tabs;
