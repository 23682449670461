import React, {useContext} from 'react';
import {Grid} from "@mantine/core";
import {Field, useFormikContext} from "formik";
import {DetallesVehiculoModel} from "./DetallesVehiculoModel";
import {MantineInputField} from "../../../../../../components/FormFields/MantineInputField";
import {MantineNumberField} from "../../../../../../components/FormFields/MantineNumberField";
import DetallesAdicionales from "./DetallesAdicionales";
import CotizarContext from "../../../../../../context/CotizarContext";

export default function DetallesVehiculoForm() {
    const {formField} = DetallesVehiculoModel();
    const {
        patente,
        motor,
        chasis,
        marca,
        modelo,
        version,
        anio,
        sumaAseguradaVehiculo,
    } = formField;
    const {values} = useFormikContext();
    const {companiesDict} = useContext(CotizarContext)
    const coverageAbleToHaveBienesAdicionales = values.company === "Zurich" ? values.rowData.parametrosAdicionales?.admiteBienesAdicionales : true;
    const showGnc = companiesDict[values.company].equipoGncId && companiesDict[values.company].bienesAdicionales

    return (
        <div className="automotor__formStep__container">
            <div className="autem__form__details">
                <Grid gutterXs="xs" gutter="md" justify="start" align="center">
                    <Grid.Col xs={12} sm={12}>
                        <p>Datos del vehículo</p>
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6}>
                        <MantineInputField
                            name={`vehiculo.${patente.name}`}
                            label={patente.label}
                            withAsterisk={values.esCeroKm !== "true"}
                        />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6}>
                        <MantineInputField
                            name={`vehiculo.${motor.name}`}
                            label={motor.label}
                            withAsterisk
                        />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6}>
                        <MantineInputField
                            name={`vehiculo.${chasis.name}`}
                            label={chasis.label}
                            withAsterisk
                        />
                    </Grid.Col>

                    <Grid.Col xs={12} sm={6}>
                        <MantineInputField
                            name={`vehiculo.detalle_marca`}
                            label={marca.label}
                            disabled
                        />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6}>
                        <MantineInputField
                            name={`vehiculo.${anio.name}`}
                            label={anio.label}
                            disabled
                        />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6}>
                        <MantineInputField
                            name={`vehiculo.detalle_modelo`}
                            label={modelo.label}
                            disabled
                        />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6}>
                        <MantineInputField
                            name={`vehiculo.detalle_version`}
                            label={version.label}
                            disabled
                        />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6}>
                        <MantineNumberField
                            name={`vehiculo.${sumaAseguradaVehiculo.name}`}
                            label={sumaAseguradaVehiculo.label}
                            disabled
                        />
                    </Grid.Col>

                    <Field type="hidden" name="ableToHaveBienesAdicionales" value={coverageAbleToHaveBienesAdicionales} />
                    {showGnc && coverageAbleToHaveBienesAdicionales && (
                        <DetallesAdicionales/>
                    )}

                </Grid>
            </div>
        </div>
    );
}