import {formatPhoneNumberIntl} from "react-phone-number-input";
import normalizeString from "../../../utils/normalizeString";

const moment = require("moment");

export const emissionDataGenerator = (values) => {
    for (const cliente of values.clientes) {
        cliente['codigoArea'] = formatPhoneNumberIntl(cliente.telefono).split(" ")[1]
        if (cliente['tipoPersona'] === '1') { //Si es personaJurídica
            cliente['situacionImpositiva'] = '1'
            cliente['dni'] = cliente['cuit']
        } else {
            cliente['fechaNacimiento'] = moment(cliente['fechaNacimiento'], 'YYYY-MM-DD').format('YYYY-MM-DD')
        }
    }

    let bienesAdicionalesSend = []
    values.motovehiculo.bienesAdicionales.forEach(bien => {
        bienesAdicionalesSend.push(bien)
    })

    return {
        "vigenciaDesde":  moment(values.vigenciaDesde, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        "vigenciaHasta": moment(values.vigenciaHasta, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        "formaDePago": values.formaDePago,
        "infoDePago": {
            "numeroCbu": values.infoDePago.numeroCbu,
            "marcaTarjeta": values.infoDePago.marcaTarjeta,
            "numeroTarjeta": values.infoDePago.numeroTarjeta
        },
        "clientes": [
            ...values.clientes
        ],
        "comentariosSolicitud": normalizeString(values.comentariosSolicitud, ['\\', '#', '$', '%', '\'', '\"', '*', '{', '}'], false, ""),
        "vehiculo": {
            ...values.motovehiculo,
            "sumaAseguradaVehiculo": parseInt(values.motovehiculo.sumaAseguradaVehiculo),
            "tipoUso": "2",
            "bienesAdicionales": bienesAdicionalesSend,
            "inspeccionPrevia": {
                ...values.inspeccionPrevia,
                "comentariosInspeccion": values.inspeccionPrevia.daniosPreexistentes ? values.inspeccionPrevia.comentariosInspeccion : ""
            },
        },
        "idCobertura":  values.idCobertura,
        "nombreCobertura":  values.nombreCobertura,
        "idCotizacion":  values.idCotizacion,
        "company":  values.company,
        "premio": Math.ceil(values.premio),
        "parametrosAdicionales": {
            ...values.parametrosAdicionales
        }
    }
}