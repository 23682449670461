import {CompanySelectorItem} from "../../CompanySelectorItem";

function PolkistaSelector(props) {
    const {selectedCompaniesCompleteInfo, handleSelection} = props;
    return (
        <div className="csm__tabsContentContainer">
            {selectedCompaniesCompleteInfo?.map((company, idx) => (
                <CompanySelectorItem
                    index={idx}
                    companyKey={company.id}
                    selected={company.selected}
                    callback={handleSelection}
                />
            ))}
        </div>
    );
}

export default PolkistaSelector;