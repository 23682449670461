import { ReactComponent as DownloadArrowIcon } from '../../../assets/icons/ic_download-arrow.svg';
import '../Icon.css';
import "./DownloadArrow.css";

const DownloadArrow = (props) => {
  const { size = "sm" } = props;

  return (
    <div className='DownloadArrow--container'>
      <DownloadArrowIcon className={`DownloadArrow Icon ${size}`} />
    </div>
  );
}

export default DownloadArrow;