import React from 'react';
import './SettledCommissionsInfoModal.css';
import {InformationModal} from "../../../layouts/InformationModal";

function SettledCommisssionsInfoModal({onClose}) {
    return (
        <InformationModal
            closeAction={onClose}
            title={"Importante"}
            body={
                <div className="setComInfoModal__container">
                    <p className="setComInfoModal__title">Comisiones Disponibles</p>
                    <div className="setComInfoModal__block">
                        <p className="setComInfoModal__text">
                            Se incluirán las Pólizas Emitidas y sus comisiones correspondientes disponibles para su
                            "Retiro".
                        </p>
                        <p className="setComInfoModal__text">Una vez solicitada la extracción, demora hasta 7 días
                            hábiles en imputar en el CBU/CVU
                            cargado. Su estado se visualizará en Comisiones Acumuladas.
                        </p>
                    </div>
                </div>
            }
        />
    );
}

export default SettledCommisssionsInfoModal;