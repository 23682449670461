import React, {useContext, useState} from 'react';
import './ManagementTab.css';
import MasterContext from "../../../context/MasterContext";
import MainTable from "../../../components/MainTable/MainTable";
import {ProductsColumn} from "../ManagementTableComponents/ProductsColumn";
import {LogoColumn} from "../ManagementTableComponents/LogoColumn";
import {DescriptionColumn} from "../ManagementTableComponents/DescriptionColumn";
import {EnlacesColumn} from "../ManagementTableComponents/EnlacesColumn";
import {RedesColumn} from "../ManagementTableComponents/RedesColumn";
import {DescargablesColumn} from "../ManagementTableComponents/DescargablesColumn";
import {ResumenColumn} from "../ManagementTableComponents/ResumenColumn";
import {ActionsColumn} from "../ManagementTableComponents/ActionsColumn";
import UserContext from "../../../context/UserContext";
import {ManagementCard} from "../ManagementCard";
import {CarouselWrapper} from "../../../components/CarouselWrapper";
import {ProductsModal} from "../ManagementTableModals/ProductsModal";
import {DescriptionModal} from "../ManagementTableModals/DescriptionModal";
import {RedesModal} from "../ManagementTableModals/RedesModal";
import {EnlacesModal} from "../ManagementTableModals/EnlacesModal";
import {DescargablesModal} from "../ManagementTableModals/DescargablesModal";
import {ResumenModal} from "../ManagementTableModals/ResumenModal";
import {NewChannelButton} from "./NewChannelButton";
import {LogoModal} from "../ManagementTableModals/LogoModal";
import {Loading} from "../../../components/Loading";

function ManagementTab(props) {
    const {setEditRow, setActiveTab, setIdPolkistaToCreate} = props;
    const {isMobile} = useContext(UserContext);
    const {infoPagesPolkistas, masterPolkistas, isModifiedReady} = useContext(MasterContext);
    const [modalOpen, setModalOpen] = useState({modalName: "", values: null});

    const managementCDColumns = [
        {
            title: "Nombre",
            key: "id_polkista",
            render: (value) => <span>{masterPolkistas[value]['datos_polkista']['NombreCompleto']}</span>
        },
        {title: "Logo", key: "row", render: (value) => <LogoColumn row={value} setModalOpen={setModalOpen}/>},
        {title: "Productos", key: "row", render: (value) => <ProductsColumn row={value} setModalOpen={setModalOpen}/>},
        {
            title: "Descripción",
            key: "row",
            render: (value) => <DescriptionColumn row={value} setModalOpen={setModalOpen}/>
        },
        {title: "Redes", key: "row", render: (value) => <RedesColumn row={value} setModalOpen={setModalOpen}/>},
        {title: "Enlace", key: "row", render: (value) => <EnlacesColumn row={value} setModalOpen={setModalOpen}/>},
        {
            title: "Descargables",
            key: "row",
            render: (value) => <DescargablesColumn row={value} setModalOpen={setModalOpen}/>
        },
        {title: "Resumen", key: "row", render: (value) => <ResumenColumn row={value} setModalOpen={setModalOpen}/>},
        {
            title: "Acciones",
            key: "row",
            render: (value) => <ActionsColumn row={value} setEditRow={setEditRow} setActiveTab={setActiveTab}/>
        },
    ];

    if (!isModifiedReady) return <div className="managementTab__loading"><Loading/></div>;
    return (
        <div className="managementTab__container">
            {!isMobile
                ? <div className="managementTab__wrapper">
                    <div className="managementTab__tableContainer">
                        <MainTable columns={managementCDColumns} rows={infoPagesPolkistas} pagination itemsPerPage={6}/>
                    </div>
                    <div className="managementTab__button">
                        <NewChannelButton setIdPolkistaToCreate={setIdPolkistaToCreate} setEditRow={setEditRow}
                                          setActiveTab={setActiveTab}/>
                    </div>
                </div>
                : <div className="ManagementTab__carouselWrapper">
                    <CarouselWrapper slideSize={"200px"}>
                        {infoPagesPolkistas.map((infoCard, index) => (
                            <div className="ManagementTab__cardContainer">
                                <ManagementCard
                                    infoCard={infoCard}
                                    key={index}
                                    setEditRow={setEditRow}
                                    setActiveTab={setActiveTab}
                                    setModalOpen={setModalOpen}/>
                            </div>
                        ))}
                    </CarouselWrapper>
                    <NewChannelButton isMobile
                                      setIdPolkistaToCreate={setIdPolkistaToCreate}
                                      setEditRow={setEditRow}
                                      setActiveTab={setActiveTab}/>
                </div>
            }

            {/*    MODALS */}
            {modalOpen.modalName === "Logo" &&
            <LogoModal
                setOpen={setModalOpen}
                values={modalOpen.values}
            />
            }
            {modalOpen.modalName === "Products" &&
            <ProductsModal
                setOpen={setModalOpen}
                values={modalOpen.values}
            />
            }
            {modalOpen.modalName === "Description" &&
            <DescriptionModal
                setOpen={setModalOpen}
                values={modalOpen.values}
            />}
            {modalOpen.modalName === "Redes" &&
            <RedesModal
                setOpen={setModalOpen}
                values={modalOpen.values}
            />}
            {modalOpen.modalName === "Enlaces" &&
            <EnlacesModal
                setOpen={setModalOpen}
                values={modalOpen.values}
            />
            }
            {modalOpen.modalName === "Descargables" &&
            <DescargablesModal
                setOpen={setModalOpen}
                values={modalOpen.values}
            />
            }
            {modalOpen.modalName === "Resumen" &&
            <ResumenModal
                setOpen={setModalOpen}
                values={modalOpen.values}
            />
            }
        </div>

    );
}

export default ManagementTab;