import * as Yup from "yup";

const ScoringModel = () => {
    const formField = {
        scoring: { //Object
            name: 'scoring',
        },
        sexo: {
            name: 'sexo',
            label: 'Sexo',
            requiredErrorMsg: 'Debe seleccionar un sexo'
        },
        fechaNacimiento: {
            name: 'fechaNacimiento',
            label: 'Edad',
            requiredErrorMsg: 'Debe especificar su edad'
        },
    }

    const initialValues = {
        [formField.scoring.name]: {
            [formField.fechaNacimiento.name]: '',
            [formField.sexo.name]: '',
        },
    }

    const validationSchema = Yup.object().shape({
        [formField.scoring.name]: Yup.object().shape({
            [formField.fechaNacimiento.name]: Yup.number()
                .min(18, "Debe tener al menos 18 años")
                .max(160, "Debe ser menor de 160 años")
                .required(`${formField.fechaNacimiento.requiredErrorMsg}`),
            [formField.sexo.name]: Yup.string().required(`${formField.sexo.requiredErrorMsg}`),
        }),
    })

    return {formField, initialValues, validationSchema}
}

export {ScoringModel};
