import axios from "axios";
const S3URL = "https://polko-images.s3.us-east-2.amazonaws.com/"

const getDocuments = async (idPolkista, authToken) => {
    try {
        const res = await axios.get(process.env.REACT_APP_BACK_SERVER + `/get_documentos/${idPolkista}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                },
            });
            const documents = res.data.map((doc) => {
                return {
                    ...doc,
                    link: S3URL + doc.link,
                    type: doc.label,
                };
            });
        return documents;
    } catch (error) {
        console.error("Error fetching documents:", error);
        return [];
    }
};

export default getDocuments;

