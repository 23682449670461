import withRoot from '../withRoot';
import React, {Fragment, useContext, useEffect, useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import TablaCotizaciones from '../Dashboard/Cotizaciones';
import {Grid} from "@material-ui/core";
import Typography from "../components/Typography";
import sancorimg from '../../assets/images/logos/sancorlogo.png';
import imageCotizaciones from "../../assets/images/imageCotizaciones.png";
import NavBar from "./NavBar/NavBar";
import CotizacionesCombinado from "../Dashboard/CotizacionesCombinado";
import DashboardContext from "../../context/DashboardContext";
import CotizacionesMicroseguros from "../Dashboard/CotizacionesMicroseguro";

const useStyle = makeStyles((theme) => ({
    body: {
        marginTop: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '8px'
    },
    appBarSpacer: theme.mixins.toolbar,
    title: {
        fontSize: '24px',
        color: '#452d7b',
        fontFamily: 'Ubuntu',
        fontWeight: 'bold'
    },
    table: {

        marginTop: theme.spacing(3),
        padding: '40px',
        borderRadius: 16,
        boxShadow: '0 5px 40px 0 rgba(70, 70, 70, 0.1)',
        backgroundColor: "#ffffff",
        display: 'block',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            padding: '40px 20px'
        },

    },

    tituloGrid: {
        fontFamily: "Ubuntu",
        fontSize: 32,
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: -1.33,
        color: '#35303f',
        paddingLeft: '16px'
    },

    subtituloGrid: {
        fontFamily: "Ubuntu",
        fontSize: 24,
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: -1,
        color: '#35303f',
        paddingLeft: '16px'
    },
    divResponsive: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
            overflow: 'auto'
        },
    },

    imagenResponsive: {
        width: '300px',
        position: 'absolute',
        top: -190,
        left: '33vw',
        [theme.breakpoints.down('lg')]: {
            left: '60vw',
        },
        [theme.breakpoints.between('1100', '1281')]: {
            left: '69vw',
        },
        [theme.breakpoints.down('md')]: {
            left: '66vw',
        },
        [theme.breakpoints.down('sm')]: {
            left: '65vw',
            width: '250px',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
    },

    tableResponsive: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',

        },
    }
}));

function Cotizaciones(props) {
    const classes = useStyle();
    const {infoCotizaciones} = useContext(DashboardContext);
    const [cotizacionesData, setCotizacionesData] = useState(null);

    const dictProductos = {
        vehiculo: "AUTOMOTOR",
        hogar: "HOGAR",
        microseguro: "MICROSEGURO",
    }

    const getTabla = ((producto) => {
        switch (producto) {
            case 'vehiculo':
                return (
                    <div className={classes.divResponsive}>
                        <TablaCotizaciones infoCotizaciones={cotizacionesData}/>
                    </div>)
            case 'hogar':
                return (
                    <div className={classes.divResponsive}>
                        <CotizacionesCombinado infoCotizaciones={cotizacionesData}/>
                    </div>)
            case 'microseguro':
                return (
                    <div className={classes.divResponsive}>
                        <CotizacionesMicroseguros infoCotizaciones={cotizacionesData}/>
                    </div>)
        }
    })

    useEffect(() => {
        let cotizaciones = [];
        infoCotizaciones.map(cotizacion => {
            if (cotizacion['producto'] === dictProductos[props.location.state.producto]) {
                cotizaciones.push(cotizacion)
            }
        })
        setCotizacionesData(cotizaciones)
    }, []);

    return (
        <Fragment>
            <NavBar/>
            <div className={classes.body}>
                <Grid container direction={"column"} style={{maxWidth: '1200px', marginTop: '30px'}}>
                    <Grid item>
                        <Typography className={classes.tituloGrid}>
                            Emitir Póliza
                        </Typography>
                    </Grid>
                    <Grid container direction={"row"}
                          style={{display: 'flex', alignItems: 'center', marginTop: '25px'}}>
                        <Grid item>
                            <Typography className={classes.subtituloGrid}>
                                Compañía:
                            </Typography>
                        </Grid>
                        <Grid item>
                            <img src={sancorimg} alt={''} style={{
                                width: '160px',
                                marginLeft: '25px'
                            }}/>
                        </Grid>
                    </Grid>
                    <Grid item style={{position: 'relative'}} className={classes.tableResponsive}>

                        <Grid item className={classes.table}>
                            {cotizacionesData && (
                                getTabla(props.location.state.producto)
                            )}
                        </Grid>
                        <img src={imageCotizaciones} alt={''}
                             className={classes.imagenResponsive}/>
                    </Grid>
                </Grid>
            </div>
        </Fragment>
    )
}

export default withRoot(Cotizaciones);
