import * as Yup from "yup";

const InformacionModel = () => {
    const formField = {
        tamanioVivienda: {
            name: 'tamanioVivienda',
            label: 'Tamaño de vivienda',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        tipoVivienda: {
            name: 'tipoVivienda',
            label: 'Tipo de vivienda',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        codigosLocalidad: {
            name: 'codigosLocalidad',
            label: 'Localidad',
            requiredErrorMsg: 'Debe especificar una localidad'
        },
        codigoPostal: {
            name: 'codigoPostal',
            label: "Código postal",
        },
        idProvincia: {
            name: 'idProvincia',
            label: 'Provincia',
        },
    }

    const initialValues = {
        [formField.tamanioVivienda.name]: 'Mediana',
        [formField.tipoVivienda.name]: '1021',
        [formField.codigosLocalidad.name]: '',
        [formField.idProvincia.name]: '',
    }

    const validationSchema = Yup.object().shape({
        [formField.codigosLocalidad.name]: Yup.string().required(`${formField.codigosLocalidad.requiredErrorMsg}`)
            .test('codigoLocalidad', "Seleccione una localidad válida",
                (value) => {
                    if (value) return value.includes(",")
                    else return false
                }),
        [formField.codigosLocalidad.name]: Yup.string().required(`${formField.codigosLocalidad.requiredErrorMsg}`)
    })

    return {formField, initialValues, validationSchema}
}

export {InformacionModel};
