import React, {useContext, useEffect, useState} from 'react';
import Drawer from '@material-ui/core/Drawer';
import {Grid, Typography, Button, Link} from '@material-ui/core';
import logo from '../../../assets/images/logoblancopolko.png'
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import {makeStyles} from "@material-ui/core/styles";
import EditIcon from '@material-ui/icons/Edit';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import PersonalesList from "./personalesList";
import Dialog from "@material-ui/core/Dialog";
import CancelIcon from '@material-ui/icons/Cancel';
import {useAuth0} from "@auth0/auth0-react";
import ProductosList from "./productosList";
import ComplementariosList from "./complementariosList";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import QueueIcon from '@material-ui/icons/Queue';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {useHistory} from "react-router-dom";
import AuthContext from "../../../context/AuthContext";
import UserContext from "../../../context/UserContext";

const useStyles = makeStyles(theme => ({
    linkText: {
        //align: "center",
        color: "#fff",
        fontFamily: "Ubuntu",
        fontWeight: 500,
        fontSize: '14px',
        letterSpacing: -0.5,
    },
    tituloMenu: {
        fontFamily: "Ubuntu",
        fontSize: 12,
        fontWeight: 500,

        color: '#c0c0c0',
        letterSpacing: -0.8,
    },
    infoMenu: {
        fontFamily: "Ubuntu",
        fontSize: 14,
        fontWeight: 500,
        color: '#fff',
        letterSpacing: -0.5,
        marginBottom: '8px'
    },
    divRectangle: {
        display: 'flex',
        alignItems: 'center',
        padding: '2px 10px 0px 7px',
        height: 'fit-content',
        background: 'rgba(255, 255, 255, 0.1)',
        //mixBlendMode: 'hard-light',
        borderRadius: '3px',
        width: 'fit-content'
    },
    textOverlay: {
        align: "center",
        color: '#f3f4f8',
        fontFamily: "Ubuntu",
        fontSize: 10,
        fontWeight: 500,
        textAlign: 'center',
        letterSpacing: -0.8,
        paddingLeft: '4px',
        paddingBottom: '2px'
    },
    rectangleMarketing: {
        display: 'flex',
        alignItems: 'center',
        padding: '3px 10px',
        height: 'fit-content',
        background: 'rgba(255, 255, 255, 0.1)',
        borderRadius: '3px',
        width: 'fit-content',
        flexDirection: 'row'
    },
    textoMaketing: {
        align: "center",
        color: '#f3f4f8',
        fontFamily: "Ubuntu",
        fontSize: 13,
        fontWeight: 500,
        textAlign: 'center',
        letterSpacing: -0.5,
        paddingLeft: '4px'
    },

    iconoMarketing: {
        width: '15px',
        color: '#f3f4f8',
    },

    cerrarSesionText: {
        align: "center",
        color: '#c0c0c0',
        fontFamily: "Ubuntu",
        fontSize: 14,
        fontWeight: 500,
        textAlign: 'center',
        letterSpacing: -0.5,
        paddingLeft: '4px'
    },
    titulomodal: {
        textAlign: 'center',
        fontSize: '24px',
        color: '#452d7b',
        fontFamily: 'Ubuntu',
        fontWeight: 'bold',
        letterSpacing: '-0.5',
        marginBottom: '15px'
    },
    icons: {
        '& .MuiSvgIcon-root': {
            fontSize: '13px'
        }
    },
    drawerRounded: {
        '& .MuiDrawer-paper': {
            borderBottomRightRadius: '10px',
            borderTopRightRadius: '10px'
        }
    },
    accordionStyle: {
        background: 'rgb(26,26,27)',
        padding: 0,
        boxShadow: 'none',
        '& .MuiAccordionSummary-root': {
            minHeight: 0
        },
        '& .MuiAccordionSummary-content': {
            margin: 0,
            padding: 0
        }
    },
    linkTextAccordion: {
        //align: "center",
        color: "#fff",
        fontFamily: "Ubuntu",
        fontWeight: 500,
        fontSize: '14px',
        letterSpacing: -0.5,
        paddingLeft: '8px'
    },

}));

export default function TemporaryDrawer() {
    const history = useHistory();
    const classes = useStyles();
    const {isMaster} = useContext(AuthContext);
    const {userNiveles,userMail,userName, userLastName} = useContext(UserContext);
    const {logout} = useAuth0();
    const [state, setState] = useState({left: false});

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({...state, [anchor]: open});
    };

    const [modal, setModal] = useState(false);
    const [modal1, setModal1] = useState(false);
    const [modal2, setModal2] = useState(false);
    const abrirCerrarModal = () => {
        setModal(!modal);
    }
    const abrirCerrarModal1 = () => {
        setModal1(!modal1);
    }
    const abrirCerrarModal2 = () => {
        setModal2(!modal2);
    }

    const list = (anchor) => (
        <div
            style={{background: '#1A1A1A', height: '300vh', width: '300px'}}
            role="presentation"
            //onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Grid container direction={"column"} style={{padding: 30, marginTop: 15}}>
                <Grid container xs={12}>
                    <Grid container xs={6} xl={6} sm={6} justify='flex-start' alignItems='center'>

                        <img src={logo} alt='logo' style={{
                            width: '70px',
                            filter: 'drop-shadow(3px 4px 6px #35303f)'
                        }}/>
                    </Grid>
                    <Grid container xs={6} xl={6} sm={6} justify='flex-end' alignItems='center'>
                        <Close style={{color: '#cb3435'}}
                               onClick={toggleDrawer(anchor, false)

                               }/>
                    </Grid>
                </Grid>
                <Grid container xs={12} xl={12} sm={12}>

                    <Typography style={{
                        marginTop: 30,
                        flexGrow: 1,
                        align: "center",
                        color: "#973379",
                        fontFamily: "Ubuntu",
                        fontWeight: 600,
                        marginBottom: 10,
                        letterSpacing: -1,
                    }}>
                        BIENVENIDO
                    </Typography>
                </Grid>

                <Grid item>
                        <Grid container xs={12} xl={12} sm={12} style={{marginTop: 15, paddingLeft: '8px'}}
                              alignItems='center'>
                            <Grid container xs={1} xl={1} sm={1}>
                                <DonutLargeIcon
                                    style={{
                                        color: '#fff', width: '13px'
                                    }}/>
                            </Grid>
                            <Grid container xs={11} xl={11} sm={11} justify='flex-start'>
                                <Typography
                                    onClick={() => history.push({pathname: '/u/dashboard'})}
                                    className={classes.linkText}
                                >Inicio</Typography>
                            </Grid>
                        </Grid>
                </Grid>
                <Grid item style={{marginTop: 15}}>
                    <Accordion className={classes.accordionStyle}>
                        <AccordionSummary style={{margin: 0, padding: 0}}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                        >
                            <Grid container xs={12} xl={12} sm={12} style={{paddingLeft: '8px'}}
                                  alignItems='center'>
                                <Grid container xs={1} xl={1} sm={1}>
                                    <MonetizationOnIcon
                                        className={classes.icons}
                                        style={{
                                            color: '#fff', width: '13px'
                                        }}
                                    />
                                </Grid>
                                <Grid container xs={11} xl={11} sm={11} justify='flex-start'>
                                    <Typography
                                        className={classes.linkText}
                                    >Cotizar</Typography>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                <Link onClick={() => {
                                    history.push('/u/cotizar/vehiculo')
                                }}>
                                    <Grid container xs={12} xl={12} sm={12} style={{paddingLeft: '8px'}}
                                          alignItems='center'>
                                        <Grid container xs={1} xl={1} sm={1}>
                                            <SubdirectoryArrowRightIcon
                                                style={{
                                                    color: '#fff', width: '13px'
                                                }}/>
                                        </Grid>
                                        <Grid container xs={11} xl={11} sm={11} justify='flex-start'>
                                            <Typography className={classes.linkTextAccordion}>Automotor</Typography>
                                        </Grid>
                                    </Grid>
                                </Link>
                                <Link onClick={() => {
                                    history.push('/u/cotizar/combinado')
                                }}>
                                    <Grid container xs={12} xl={12} sm={12} style={{paddingLeft: '8px'}}
                                          alignItems='center'>
                                        <Grid container xs={1} xl={1} sm={1}>
                                            <SubdirectoryArrowRightIcon
                                                style={{
                                                    color: '#fff', width: '13px'
                                                }}/>
                                        </Grid>
                                        <Grid container xs={11} xl={11} sm={11} justify='flex-start'>
                                            <Typography className={classes.linkTextAccordion}>Hogar</Typography>
                                        </Grid>
                                    </Grid>
                                </Link>
                                <Link onClick={() => {
                                    history.push('/u/cotizar/microseguro')
                                }}>
                                    <Grid container xs={12} xl={12} sm={12} style={{paddingLeft: '8px'}}
                                          alignItems='center'>
                                        <Grid container xs={1} xl={1} sm={1}>
                                            <SubdirectoryArrowRightIcon
                                                style={{
                                                    color: '#fff', width: '13px'
                                                }}/>
                                        </Grid>
                                        <Grid container xs={11} xl={11} sm={11} justify='flex-start'>
                                            <Typography className={classes.linkTextAccordion}>Microseguro</Typography>
                                        </Grid>
                                    </Grid>
                                </Link>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </Grid>


                <Grid item style={{marginTop: 15}}>
                    <Accordion className={classes.accordionStyle}>
                        <AccordionSummary style={{margin: 0, padding: 0}}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                        >
                            <Grid container xs={12} xl={12} sm={12} style={{paddingLeft: '8px'}}
                                  alignItems='center'>
                                <Grid container xs={1} xl={1} sm={1}>
                                    <ReceiptIcon
                                        style={{
                                            color: '#fff', width: '13px'
                                        }}/>
                                </Grid>
                                <Grid container xs={11} xl={11} sm={11} justify='flex-start'>
                                    <Typography
                                        className={classes.linkText}
                                    >Emitir</Typography>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                <Link onClick={() => {
                                    history.push({
                                        pathname: '/u/cotizaciones/' + 'vehiculo',
                                        state: {
                                            producto: 'vehiculo'
                                        }
                                    })
                                }}>
                                    <Grid container xs={12} xl={12} sm={12} style={{paddingLeft: '8px'}}
                                          alignItems='center'>
                                        <Grid container xs={1} xl={1} sm={1}>
                                            <SubdirectoryArrowRightIcon
                                                style={{
                                                    color: '#fff', width: '13px'
                                                }}/>
                                        </Grid>
                                        <Grid container xs={11} xl={11} sm={11} justify='flex-start'>
                                            <Typography className={classes.linkTextAccordion}>Automotor</Typography>
                                        </Grid>
                                    </Grid>
                                </Link>
                                <Link onClick={() => {
                                    history.push({
                                        pathname: '/u/cotizaciones/' + 'hogar',
                                        state: {
                                            producto: 'hogar'
                                        }
                                    })
                                }}>
                                    <Grid container xs={12} xl={12} sm={12} style={{paddingLeft: '8px'}}
                                          alignItems='center'>
                                        <Grid container xs={1} xl={1} sm={1}>
                                            <SubdirectoryArrowRightIcon
                                                style={{
                                                    color: '#fff', width: '13px'
                                                }}/>
                                        </Grid>
                                        <Grid container xs={11} xl={11} sm={11} justify='flex-start'>
                                            <Typography className={classes.linkTextAccordion}>Hogar</Typography>
                                        </Grid>
                                    </Grid>
                                </Link>
                                <Link onClick={() => {
                                    history.push({
                                        pathname: '/u/cotizaciones/' + 'microseguro',
                                        state: {
                                            producto: 'microseguro'
                                        }
                                    })
                                }}>
                                    <Grid container xs={12} xl={12} sm={12} style={{paddingLeft: '8px'}}
                                          alignItems='center'>
                                        <Grid container xs={1} xl={1} sm={1}>
                                            <SubdirectoryArrowRightIcon
                                                style={{
                                                    color: '#fff', width: '13px'
                                                }}/>
                                        </Grid>
                                        <Grid container xs={11} xl={11} sm={11} justify='flex-start'>
                                            <Typography className={classes.linkTextAccordion}>Microseguros</Typography>
                                        </Grid>
                                    </Grid>
                                </Link>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </Grid>


                <Grid item style={{marginTop: 15}}>
                    <Accordion className={classes.accordionStyle}>
                        <AccordionSummary style={{margin: 0, padding: 0}}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                        >
                            <Grid container xs={12} xl={12} sm={12} style={{paddingLeft: '8px'}}
                                  alignItems='center'>
                                <Grid container xs={1} xl={1} sm={1}>
                                    <SearchIcon
                                        style={{
                                            color: '#fff', width: '13px'
                                        }}/>
                                </Grid>
                                <Grid container xs={11} xl={11} sm={11} justify='flex-start'>
                                    <Typography
                                        className={classes.linkText}
                                    >Documentacion</Typography>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                <Link onClick={() => {
                                    history.push({
                                        pathname: '/u/documentacion/' + 'vehiculo',
                                        state: {
                                            producto: 'vehiculo'
                                        }
                                    })
                                }}>
                                    <Grid container xs={12} xl={12} sm={12} style={{paddingLeft: '8px'}}
                                          alignItems='center'>
                                        <Grid container xs={1} xl={1} sm={1}>
                                            <SubdirectoryArrowRightIcon
                                                style={{
                                                    color: '#fff', width: '13px'
                                                }}/>
                                        </Grid>
                                        <Grid container xs={11} xl={11} sm={11} justify='flex-start'>
                                            <Typography className={classes.linkTextAccordion}>Automotor</Typography>
                                        </Grid>
                                    </Grid>
                                </Link>
                                <Link onClick={() => {
                                    history.push({
                                        pathname: '/u/documentacion/' + 'hogar',
                                        state: {
                                            producto: 'hogar'
                                        }
                                    })
                                }}>
                                    <Grid container xs={12} xl={12} sm={12} style={{paddingLeft: '8px'}}
                                          alignItems='center'>
                                        <Grid container xs={1} xl={1} sm={1}>
                                            <SubdirectoryArrowRightIcon
                                                style={{
                                                    color: '#fff', width: '13px'
                                                }}/>
                                        </Grid>
                                        <Grid container xs={11} xl={11} sm={11} justify='flex-start'>
                                            <Typography className={classes.linkTextAccordion}>Hogar</Typography>
                                        </Grid>
                                    </Grid>
                                </Link>
                                <Link onClick={() => {
                                    history.push({
                                        pathname: '/u/documentacion/' + 'microseguro',
                                        state: {
                                            producto: 'microseguro'
                                        }
                                    })
                                }}>
                                    <Grid container xs={12} xl={12} sm={12} style={{paddingLeft: '8px'}}
                                          alignItems='center'>
                                        <Grid container xs={1} xl={1} sm={1}>
                                            <SubdirectoryArrowRightIcon
                                                style={{
                                                    color: '#fff', width: '13px'
                                                }}/>
                                        </Grid>
                                        <Grid container xs={11} xl={11} sm={11} justify='flex-start'>
                                            <Typography className={classes.linkTextAccordion}>Microseguros</Typography>
                                        </Grid>
                                    </Grid>
                                </Link>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </Grid>


                <Grid item>
                        <Grid container xs={12} xl={12} sm={12} style={{marginTop: 15, paddingLeft: '8px'}}
                              alignItems='center'>
                            <Grid container xs={1} xl={1} sm={1}>
                                <AccountBalanceIcon
                                    style={{
                                        color: '#fff', width: '13px'
                                    }}/>
                            </Grid>
                            <Grid container xs={11} xl={11} sm={11} justify='flex-start'>
                                <Typography
                                    onClick={() => history.push({pathname: '/u/billetera'})}
                                    className={classes.linkText}
                                >Billetera</Typography>
                            </Grid>
                        </Grid>
                </Grid>
                {isMaster &&
                <Grid item>
                    <Grid container xs={12} xl={12} sm={12} style={{marginTop: 15, paddingLeft: '8px'}}
                          alignItems='center'>
                        <Grid container xs={1} xl={1} sm={1}>
                            <PeopleAltIcon
                                style={{
                                    color: '#fff', width: '13px'
                                }}/>
                        </Grid>
                        <Grid container xs={11} xl={11} sm={11} justify='flex-start'>
                            <Typography
                                onClick={() => history.push({pathname: '/u/m/dashboard'})}
                                className={classes.linkText}
                            >Master</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                }


                <hr width={'100%'} size={1} color={'#2C2C2C'} style={{marginTop: 20, marginBottom: 5}} align='left'/>

                <Grid container direction={"column"}>

                    <Grid container direction={"row"} xs={12} xl={12} sm={12} style={{
                        display: 'flex', alignItems: 'center',
                        marginTop: 15, marginBottom: 10,
                    }}>
                        <Grid item xs={8} justify={"flex-start"}>
                            <Typography style={{

                                flexGrow: 1,
                                align: "center",
                                color: "#973379",
                                fontFamily: "Ubuntu",
                                fontWeight: 600,

                                letterSpacing: -1,
                            }}>
                                PERFIL
                            </Typography>
                        </Grid>

                        <Grid item xs={4} style={{display: 'flex', justifyContent: 'flex-end'}}>

                            <Link
                                onClick={() => history.push({pathname: '/u/perfil'})}
                                className={classes.divRectangle}>
                                <Grid container direction={'row'} style={{display: 'flex', alignItems: 'center'}}>
                                    <Grid item>
                                        <EditIcon style={{
                                            color: '#f3f4f8',
                                            width: '12px',

                                        }}/>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.textOverlay}>
                                            EDITAR
                                        </Typography>

                                    </Grid>
                                </Grid>

                            </Link>
                        </Grid>
                    </Grid>


                </Grid>


                <div style={{margin: '10px 8px'}}>
                    <Typography className={classes.tituloMenu}>NOMBRE:</Typography>
                    <Typography className={classes.infoMenu}>{userName + " " + userLastName}</Typography>

                    <Typography className={classes.tituloMenu}>EMAIL:</Typography>
                    <Typography className={classes.infoMenu}>{userMail}</Typography>

                    <Typography className={classes.tituloMenu}>NIVEL:</Typography>
                    <Typography className={classes.infoMenu}
                                style={{
                                    color: 'fff',
                                    fontWeight: 'bold',
                                    marginBottom: 0
                                }}>POLKISTA {userNiveles['Nivel']}</Typography>
                </div>

                <hr width={'100%'} size={1} color={'#2C2C2C'} style={{marginTop: 10, marginBottom: 5}} align='left'/>

                <Grid container direction={"column"} style={{marginTop: '20px'}}>

                    <Grid item justify={"flex-start"}>
                        <Typography style={{
                            flexGrow: 1,
                            align: "center",
                            color: "#973379",
                            fontFamily: "Ubuntu",
                            fontWeight: 600,
                            letterSpacing: -1,
                        }}>
                            MARKETING
                        </Typography>
                    </Grid>

                    <Grid item style={{margin: '20px 8px 10px 8px'}}>
                        <Button className={classes.rectangleMarketing} onClick={() => abrirCerrarModal()}>
                            <div style={{display: 'flex'}}>
                                <AccountBoxIcon className={classes.iconoMarketing}/>
                            </div>
                            <div>
                                <Typography className={classes.textoMaketing}>
                                    PERSONALES
                                </Typography>
                            </div>
                            <Dialog
                                open={modal}
                                onClose={abrirCerrarModal}
                            >
                                <div style={{padding: '8px'}}>
                                    <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>

                                        <CancelIcon onClick={() => abrirCerrarModal()}/>

                                    </div>
                                    <Typography className={classes.titulomodal}>Kit de Marketing Personal</Typography>


                                    <PersonalesList/>
                                </div>

                            </Dialog>

                        </Button>
                    </Grid>

                    <Grid item style={{margin: '10px 8px'}}>
                        <Button className={classes.rectangleMarketing} onClick={() => abrirCerrarModal1()}>
                            <div style={{display: 'flex'}}>
                                <QueueIcon className={classes.iconoMarketing}/>
                            </div>
                            <div>
                                <Typography className={classes.textoMaketing}>
                                    PRODUCTOS
                                </Typography>
                            </div>
                            <Dialog
                                open={modal1}
                                onClose={abrirCerrarModal1}
                            >
                                <div style={{padding: '8px'}}>
                                    <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>

                                        <CancelIcon onClick={() => abrirCerrarModal1()}/>

                                    </div>
                                    <Typography className={classes.titulomodal}>Kit de Marketing Productos</Typography>


                                    <ProductosList/>
                                </div>

                            </Dialog>

                        </Button>
                    </Grid>

                    <Grid item style={{margin: '10px 8px'}}>
                        <Button className={classes.rectangleMarketing} onClick={() => abrirCerrarModal2()}>
                            <div style={{display: 'flex'}}>
                                <QueueIcon className={classes.iconoMarketing}/>
                            </div>
                            <div>
                                <Typography className={classes.textoMaketing}>
                                    COMPLEMENTARIOS
                                </Typography>
                            </div>
                            <Dialog
                                open={modal2}
                                onClose={abrirCerrarModal2}
                            >
                                <div style={{padding: '8px'}}>
                                    <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>

                                        <CancelIcon onClick={() => abrirCerrarModal2()}/>

                                    </div>
                                    <Typography className={classes.titulomodal}>Kit de Marketing
                                        Complementarios</Typography>

                                    <ComplementariosList/>
                                </div>

                            </Dialog>

                        </Button>
                    </Grid>


                </Grid>


                <hr width={'100%'} size={1} color={'#2C2C2C'} style={{marginTop: 20, marginBottom: 20}} align='left'/>

                <Grid container
                      onClick={() => window.open("https://wa.me/+5493518687927", "_blank")}
                      direction={"row"}
                      xs={12} xl={11} sm={11} justify='flex-start' style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingBottom: '4px'
                }}>
                    <WhatsAppIcon style={{
                        color: '#c0c0c0',
                        paddingBottom: '3px',
                        paddingLeft: '3px',
                        fontSize: 14,
                    }}/>
                    <Typography className={classes.cerrarSesionText}>Contacto</Typography>
                </Grid>

                <Grid container xs={11} xl={11} sm={11} justify='flex-start'>
                    <Link onClick={logout}><Typography className={classes.cerrarSesionText}>Cerrar
                        Sesión</Typography></Link>
                </Grid>

            </Grid>
        </div>
    );

    return (
        <div>
            <React.Fragment key={'left'}>
                <Grid container>
                    <Button onClick={toggleDrawer('left', true)}>
                        <MenuIcon style={{color: '#FFF'}}/>
                    </Button>
                </Grid>
                <Drawer anchor={'left'} open={state['left']} onClose={toggleDrawer('left', false)}
                        className={classes.drawerRounded}>
                    {list('left')}
                </Drawer>
            </React.Fragment>
        </div>
    );
}
