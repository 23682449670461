import {Button} from "../../../../../components/Button";
import React from "react";

const ConfirmAgreement = (props) => {
    const {buttonIsLoading, sendAgreement, selectedAgreement, handleBack} = props;


    return (
        <div className="AgreementsPopUp --confirm">
                <h1 className="ConfirmAgreementsPopUp__title">Confirmar acuerdo</h1>
            <div className="ConfirmAgreementsPopUp__bodyWrapper">
                <p className="ConfirmAgreementsPopUp__body">Acepta crear el acuerdo por {selectedAgreement.emisiones} pólizas en {selectedAgreement.dias} días.</p>
                <p className="ConfirmAgreementsPopUp__body --legend">No podrá crear un nuevo acuerdo hasta que éste llegue a su fecha de finalización.</p>
            </div>

            <div className="ConfirmAgreementsPopUp__buttonsWrapper">
                <Button type="submit"
                        enabled
                        variant="filled"
                        color="primary"
                        onClick={() => {
                            handleBack(selectedAgreement);
                        }}>
                    Atras
                </Button>

                <Button type="submit"
                        enabled
                        variant="filled"
                        color="primary"
                        loading={buttonIsLoading}
                        onClick={() => {
                            sendAgreement(selectedAgreement);
                        }}>
                    Acepto
                </Button>
            </div>
        </div>
    );
}

export default ConfirmAgreement;