import {currencyFormat} from "../../../../modules/utils/currencyFormat";

const requestException = (type, productData, rowData, userData) => {
    let subj = "";
    let msg = "";
    switch (type) {
        case "applyDiscount":
            subj = "Solicitud de Autorización descuento y/o cobertura";
            msg = "Estimado/a Polkista:%0A" +
                "Para poder atender su solicitud de excepción de manera efectiva, le agradeceríamos proporcionar detalles específicos sobre la cobertura y los costos que necesita autorizar.%0A" +
                "Además, le solicitamos que adjunte los siguientes documentos:%0A" +
                "1. Cotización de Polko.%0A" +
                "2. Fotografías del vehículo.%0A" +
                "3. Póliza actual (opcional).%0A" +
                "%0A" +
                "Agradecemos de antemano su cooperación y quedamos a su disposición para cualquier consulta o aclaración que pueda necesitar.%0A" +
                "%0A" +
                "Saludos.%0A" +
                "El equipo de Polko."
            break;
        case "emitir":
            const {detalle_marca, detalle_modelo, detalle_version, anio, tieneGnc, esCeroKm, sumaAseguradaGnc, sumaAseguradaAdicional} = productData || {};
            const {id, sumaAseguradaVehiculo, compania, coverageName, coveragePremium, discount} = rowData;
            const {userName, userLastName} = userData;

            subj = `Solicitud de excepción por emisión - ${compania} - ${userName} ${userLastName}`;
            msg = "Estimado/a Polkista:%0A" +
                "Para poder atender su solicitud de manera efectiva, le pido que no borre el texto incrustado por el equipo de Polko, corrobore la información, adjunte las fotos del vehículo, captura de pantalla de la cotización en Polko y complete los siguientes datos del cliente. %0A%0A" +
                "Datos del cliente:%0A" +
                `- Nombre completo: %0A` +
                `- DNI: %0A` +
                `- Fecha de nacimiento: %0A%0A` +

                "Datos del vehículo:%0A" +
                `- Marca: ${detalle_marca}%0A` +
                `- Modelo: ${detalle_modelo}%0A` +
                `- Versión: ${detalle_version}%0A` +
                `- Año: ${anio}%0A` +
                `- Tiene GNC: ${tieneGnc === "0" ? "No" : "Si"}%0A` +
                `${tieneGnc === "1" ? `- Suma asegurada GNC: $${currencyFormat(sumaAseguradaGnc)}%0A` : ""}` +
                `${sumaAseguradaAdicional > 0 ? `- Suma asegurada adicional: $${currencyFormat(sumaAseguradaAdicional)}%0A` : ""}` +
                `- Es 0km: ${esCeroKm === "false" ? "No" : "Si"}%0A%0A` +

                `Datos de la cobertura:%0A` +
                `- ID cobertura: ${id}%0A` +
                `- Nombre de cobertura: ${coverageName}%0A` +
                `- Suma Asegurada: $${sumaAseguradaVehiculo ? currencyFormat(sumaAseguradaVehiculo) : 0} %0A` +
                `- Premio: $${coveragePremium ? currencyFormat(coveragePremium) : 0}%0A` +
                `- Descuento aplicado: ${discount}% %0A%0A` +

                "Reiteramos la importancia de que adjunte las fotos del vehículo y complete los datos del cliente para poder procesar su solicitud correctamente.%0A%0A" +

                "Agradecemos su colaboración.%0A%0A" +

                "Saludos cordiales,%0A" +

                "Equipo de Polko.%0A"
            break;
        default:
            break;
    }

    window.open(`mailto:consultas@polko.com.ar?subject=${subj}&body=${msg}`);
}

export default requestException