import React, {forwardRef, useContext, useState} from 'react';
import './ShareCodeBar.css';
import {Button} from '../../../components/Button';
import {MultiSelect} from '@mantine/core';
import {ReactComponent as CloseIcon} from "../../../assets/icons/ic_close.svg";
import {ReactComponent as ArrowDown} from "../../../assets/icons/ic_arrow-down.svg";
import MasterContext from "../../../context/MasterContext";
import {encrypt} from "../../../modules/utils/encrypter";
import showNotification from "../../../modules/utils/showNotification";
import UserContext from "../../../context/UserContext";
import {ReactComponent as Sancor_SVG} from '../../../assets/logos/sancor_svg.svg';
import {ReactComponent as RUS_SVG} from '../../../assets/logos/rus_svg.svg';
import {ReactComponent as ZURICH_SVG} from '../../../assets/logos/zurich_svg.svg';
import {ReactComponent as TERRAWIND_SVG} from '../../../assets/logos/terrawind_black.svg';
import {ReactComponent as FEDERACIONPATRONAL_SVG} from '../../../assets/logos/federacion_patronal_logo.svg';
import {ReactComponent as InformationFilledWIcon} from "../../../assets/icons/ic_info-filled-w.svg";
import {ShareCodeBarModal} from "../ShareCodeBarModal";

function SelectedItem({value, label, onRemove, logos, group, ...others}) {
    return (
        <div className="codesSelector__item__container" {...others}>
            {logos[group]}
            <p className="codesSelector__item__label--selected">{label}</p>
            <div onClick={onRemove}>
                <CloseIcon className="codesSelector__item__close"/>
            </div>
        </div>
    );
}

const DropdownItem = forwardRef(({label, value, logos, group, ...others}, ref) => {
    return (
        <div className="codesSelector__item__container" ref={ref} {...others}>
            {logos[`${group}--dark`]}
            <p className="codesSelector__item__label">{label}</p>
        </div>
    );
});

function ShareCodeBar() {
    const {codesToShareOptions, idPolkistaMaster} = useContext(MasterContext);
    const {userMatricula} = useContext(UserContext);
    const [codes, setCodes] = useState([]);
    const [infoModalOpen, setInfoModalOpen] = useState(false);
    const hardcodedLogos = {
        "Sancor": <Sancor_SVG className="codesSelector__item__icon"/>,
        "RUS": <RUS_SVG className="codesSelector__item__icon"/>,
        "Zurich": <ZURICH_SVG className="codesSelector__item__icon"/>,
        "TerraWind": <TERRAWIND_SVG className="codesSelector__item__icon"/>,
        "Federacion_Patronal": <FEDERACIONPATRONAL_SVG className="codesSelector__item__icon --colorStroke"/>,
        "Sancor--dark": <Sancor_SVG className="codesSelector__item__icon--dark"/>,
        "RUS--dark": <RUS_SVG className="codesSelector__item__icon--dark"/>,
        "Zurich--dark": <ZURICH_SVG className="codesSelector__item__icon--dark"/>,
        "TerraWind--dark": <TERRAWIND_SVG className="codesSelector__item__icon--dark"/>,
        "Federacion_Patronal--dark": <FEDERACIONPATRONAL_SVG className="codesSelector__item__icon--dark"/>
    }

    const handleSelection = (selectedCodes) => {
        if (selectedCodes.length > 1) {
            const lastCode = selectedCodes[selectedCodes.length - 1];
            const lastCodeGroup = codesToShareOptions.find(option => option.value === lastCode)['group'];

            let hasDuplicate = false;
            selectedCodes.slice(0, -1).forEach(code => {
                const optionData = codesToShareOptions.find(option => option.value === code);
                if (lastCodeGroup === optionData['group']) {
                    hasDuplicate = true;
                }
            })

            if (!hasDuplicate) {
                setCodes(selectedCodes);
            } else {
                showNotification({
                    autoClose: 3000,
                    color: 'red',
                    status: 'error',
                    title: 'No es posible agregar el código.',
                    message: 'Sólo se permite un código por compañía aseguradora.'
                });
            }
        } else {
            setCodes(selectedCodes);
        }
    }

    const handleCopy = () => {
        if (codes.length > 0) {
            const dataToEncrypt = {
                "id_codigo_aseguradora": codes.map((code) => parseInt(code)),
                "id_polkista_master": idPolkistaMaster,
                "matricula": userMatricula && userMatricula !== "" ? parseInt(userMatricula) : 81960
            }

            const contentToCopy = `${process.env.REACT_APP_URL}form?code=${encrypt(dataToEncrypt)}`
            let textarea = document.createElement("textarea");
            textarea.textContent = contentToCopy;
            textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
            document.body.appendChild(textarea);
            textarea.select();
            let copySuccessful = false;
            try {
                copySuccessful = true;
                return document.execCommand("copy");
            } catch (ex) {
                copySuccessful = ex;
                console.warn("Copy to clipboard failed.", ex);
            } finally {
                document.body.removeChild(textarea);
                if (copySuccessful) {
                    showNotification({
                        autoClose: 3000,
                        color: 'green',
                        status: 'success',
                        title: '¡Copiado!',
                        message: 'Ya podés compartir la invitación a tu Polkista.',
                    });
                } else {
                    showNotification({
                        autoClose: 2000,
                        color: 'red',
                        status: 'error',
                        title: 'Error al copiar.',
                        message: `${copySuccessful}`
                    });
                }
            }
        } else {
            showNotification({
                autoClose: 3000,
                color: 'red',
                status: 'error',
                title: 'No hay códigos seleccionados.',
                message: 'Seleccione al menos un código para compartir.'
            });
        }
    }

    return (
        <>
            <div className="shareCodeBar__container">
                <MultiSelect
                    value={codes} onChange={(valueCodes) => handleSelection(valueCodes)}
                    classNames={{
                        wrapper: 'codesSelector__wrapper',
                        input: 'codesSelector__input',
                        searchInput: 'codesSelector__searchInput',
                        value: 'codesSelector__value',
                        item: 'codesSelector__item',
                    }}
                    styles={{rightSection: {pointerEvents: 'none'}}}
                    label=""
                    limit={10}
                    placeholder="Selecciona para vincular un nuevo Polkista"
                    logos={hardcodedLogos}
                    valueComponent={(properties) => <SelectedItem logos={hardcodedLogos} {...properties} />}
                    itemComponent={(properties) => <DropdownItem logos={hardcodedLogos} {...properties} />}
                    rightSection={<ArrowDown className="codesSelector__icon"/>}
                    data={codesToShareOptions}
                />
                <div className="shareCodeBar__actions">
                    <Button
                        type="button"
                        variant="filled"
                        color="tertiary--pink"
                        enabled
                        onClick={() => handleCopy()}
                    >
                        Copiar
                    </Button>
                    <div className="shareCodeBar__icon__container" onClick={() => setInfoModalOpen(true)}>
                        <InformationFilledWIcon className="shareCodeBar__icon"/>
                    </div>
                </div>
            </div>
            {infoModalOpen &&
            <ShareCodeBarModal onClose={() => setInfoModalOpen(false)}/>
            }
        </>
    );
}

export default ShareCodeBar;