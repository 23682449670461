import './Marketing.css'
import {Cards} from "../../features/Marketing/Cards";
import React, {useContext, useEffect, useState} from "react";
import {gql, useLazyQuery} from "@apollo/client";
import Tabs from "../../components/Tabs/Tabs"
import UserContext from "../../context/UserContext";
import {MarketingMobileTabs} from "../../features/Marketing/MarketingMobileTabs";
import {ErrorModal} from "../../layouts/ErrorModal";
import {Loading} from "../../components/Loading";
import AuthContext from "../../context/AuthContext";

const MASTER_CATEGORY_NAME = 'Master';
const GET_PIEZAS = gql`
    query MyQuery {
      General_Imagenes_Marketing(order_by: {created_at: asc}) {
        id
        nombre
        categoria
        url
        url_portada
      }
    }
`;

function Marketing() {
    const {isMaster} = useContext(AuthContext);
    const {isMobile} = useContext(UserContext);
    const [getPiezasGenerales, {data: piezas_generales}] = useLazyQuery(GET_PIEZAS);
    const [piezasData, setPiezasData] = useState(null);
    const [errorModalContent, setErrorModalContent] = useState(null);
    const categories = ['Todos', 'Personal', 'Productos', 'Complementarios','Master'];

    const renderMarketingTabs = () => {
        // const sortedKeys = Object.keys(piezasData).sort(key => key === 'Todos' ? -1 : 1);

        const tabContent = categories.map(categoria => {
            // Validación sobre las piezas de master
            if (categoria === MASTER_CATEGORY_NAME && !isMaster) {
                return null;
            } else {
                return (
                    piezasData[categoria] && piezasData[categoria].length > 0
                        ?
                        <div className="gridContainer">
                            {piezasData[categoria].map((piezas) => (
                                <Cards
                                    description={piezas.nombre}
                                    imgSource={piezas.url_portada ? piezas.url_portada : piezas.url}
                                    zipUrl={piezas.url_portada && piezas.url}
                                    setErrorModalContent={setErrorModalContent}
                                />
                            ))}
                        </div>
                        :
                        <div>
                            <p className="noPiezas">No hay placas disponibles</p>
                        </div>
                )
            }
        }).filter(tab => tab !== null);

        if (isMobile) {
            return (
                <MarketingMobileTabs
                    piezasData={piezasData}
                    tabContent={tabContent}
                    // tabs={sortedKeys}
                    tabs={categories}
                />
            )
        } else {
            return (
                <Tabs
                    piezasData={piezasData}
                    tabContent={tabContent}
                    tabs={categories}
                    ovalTab
                    centered
                    color="primary"
                />
            )
        }
    }

    const fetchData = async () => {
        try {
            await getPiezasGenerales();
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        if (piezas_generales) {
            const piezas = {};

            piezas_generales['General_Imagenes_Marketing'].forEach((pieza) => {
                const categoria = pieza.categoria;
                if (!piezas[categoria]) {
                    piezas[categoria] = [];
                }

                piezas[categoria].push(pieza);
            });

            // Agregar la categoría "Todos"
            piezas.Todos = Object.values(piezas).reduce((result, categoria) => {
                return result.concat(categoria);
            }, []);

            setPiezasData(piezas);
        }
    }, [piezas_generales]);

    return (
        <>
            <div className="containerMarketing">
                <p className="headerMarketing">¡Explorá nuestro <span
                    className="headerMarketing bold">Kit de marketing</span>!
                </p>
                <div className="bodyMarketing">
                    {!piezasData
                        ?
                        <div className="Marketing__contentLoading">
                            <Loading/>
                        </div>
                        : renderMarketingTabs()
                    }
                </div>
                {errorModalContent &&
                <ErrorModal
                    title={errorModalContent.title}
                    typeError={errorModalContent.typeError}
                    suggestedActions={errorModalContent.suggestedActions}
                    detailedError={errorModalContent.detailedError}
                    closeAction={() => setErrorModalContent(null)}
                />
                }
            </div>
        </>
    );
}

export default Marketing;