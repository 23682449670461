const tiposViviendas = {
    1021: 'CASA',
    1022: 'DEPARTAMENTO',
    1023: 'VIVIENDA EN BARRIO CERRADO'
};

const tamaniosVivienda = {
    Chica: 'PEQUEÑA (HASTA 80 MTS²)',
    Mediana: 'MEDIANA (HASTA 150 MTS²)',
    Grande: 'GRANDE (150 MTS² O MAS)'
};

const productDescriptionGenerator = (product, rowData, coberturasInfo) => {
    switch (product) {
        case "AUTOMOTOR":
            return `${rowData.vehiculo.detalle_marca || rowData.vehiculo.marca} ${rowData.vehiculo.detalle_version || rowData.vehiculo.nombreModelo} ${rowData.vehiculo.anio}`
        case "HOGAR":
            return `${tiposViviendas[rowData.hogar.tipoVivienda]} ${tamaniosVivienda[rowData.hogar.tamanioVivienda]}`
        case "MICROSEGUROS":
            return rowData.coberturas.adicionales.map(cobertura => (
                coberturasInfo[cobertura.numeroCobertura].descripcion)).join(" - ").toUpperCase()
        case "MOTOVEHICULO":
            return `${rowData.motovehiculo.detalle_marca} ${rowData.motovehiculo.detalle_version} ${rowData.motovehiculo.anio}`
        case "ASISTENCIA AL VIAJERO":
            const pasajerosLength = rowData.asistencia_viajero.pasajeros.length
            return `${rowData.asistencia_viajero.nombrePais}: ${rowData.asistencia_viajero.vigenciaDesde} - ${rowData.asistencia_viajero.vigenciaHasta}, ${pasajerosLength} pasajero${pasajerosLength > 1 ? 's' : ''}`
    }
}

export {productDescriptionGenerator}