import React from 'react';
import './DownloadableCard.css';

function DownloadableCard(props) {
    const {preview, handleDownload, name} = props;

    return (
        <div className="downloadableCard__container">
            <div className="downloadableCard__column">
                <div className="downloadableCard__template">
                    <img className="downloadableCard__image" src={preview} alt="product_banner"/>
                </div>
            </div>
            <div className="downloadableCard__row">
                <button
                    type="button"
                    className="downloadableCard__button"
                    onClick={() => handleDownload()}
                    onTouchStart={() => handleDownload()}
                >
                    Descargar
                </button>
            </div>
            <div className="downloadableCard__row" style={{marginTop: '8px'}}>
                <p className="downloadableCard__text">{name}</p>
            </div>
        </div>
    );
}

export default DownloadableCard;