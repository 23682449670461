import {PopUpCardModal} from "../../../../layouts/PopUpCardModal";
import "./DeleteAlert.css";
import moment from "moment";

function DeleteAlert(props) {
  const { description, date, fullName, idQuotation, setIsOpenDeleteAlert, deleteQuotation, loading} = props;
  return (
      <PopUpCardModal title={<div className="QDAlert__titleContainer"><p className="QDAlert__title">¿Estás seguro de quitar la siguiente cotización?</p></div>} body={
          <div className="QDAlert__bodyContainer">
              <div>
                  <p className="QDAlert__description">{description}</p>
                  <p className="QDAlert__description">Cotizado el dia: {moment(date).format('DD/MM/YYYY')} {fullName ? `por ${fullName}` : ""}</p>
              </div>
              <p className="QDAlert__clarification">Esta acción no se puede deshacer</p>
          </div>
      } closeAction={() => setIsOpenDeleteAlert(false)} footer={
          <div className="QDAlert__container">
              <p onClick={() => {
                  if (!loading) deleteQuotation(idQuotation)
              }} className={loading ? "QDAlert__options QDAlert__options--disabled" : "QDAlert__options"}>Quitar</p>
              <p onClick={() => {
                  if (!loading) setIsOpenDeleteAlert(false)
              }} className={loading ? "QDAlert__options QDAlert__options--disabled" : "QDAlert__options"}>Cancelar</p>
          </div>
      }/>
  );
}

export default DeleteAlert;