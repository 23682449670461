import React from 'react';
import {Carousel} from '@mantine/carousel';
import './Testimonials.css'
import {TestimonialCard} from "../../../components/TestimonialCard";
import useWindowDimensions from "../../../modules/utils/useWindowDimensions";

function Testimonials() {
    const {width} = useWindowDimensions();
    const isMobile = width <= 480;
    return (
        <>
            <h2 className="landing__testimonials__title">Confían en nosotros</h2>
            <Carousel
                slideSize="33.333333%"
                slideGap="xl"
                loop
                draggable={!!isMobile}
                initialSlide={1}
                withControls={false}
                classNames={{container: 'landing__testimonials__carousel'}}
            >
                <Carousel.Slide>
                    <div className="landing__testimonials__container">
                        <TestimonialCard>
                            <img className="landing__testimonials__image" src="https://i.imgur.com/ERJLkD9.jpeg"
                                                         alt="Rambaudi_Marcelo"/>
                            <p className="landing__testimonials__text">“Polko es un sistema que integra y permite el
                                trabajo en comunidad. Abre un universo de negocios y oportunidades en la era digital
                                moderna”.</p>

                            <div className="landing__testimonials__container--person">
                                <p className="landing__testimonials__name">Rambaudi Marcelo</p>
                                <p className="landing__testimonials__position">JEFE COMERCIAL EN SANCOR COOP. DE SEGUROS
                                    LTDA. U.N. CÓRDOBA</p>
                            </div>
                        </TestimonialCard>
                    </div>
                </Carousel.Slide>
                <Carousel.Slide>
                    <div className="landing__testimonials__container">
                        <TestimonialCard>
                            <img className="landing__testimonials__image" src="https://i.imgur.com/abE1JWD.jpeg"
                                                         alt="Diego_Corvalan"/>
                            <p className="landing__testimonials__text">“Es más que una herramienta, es un sistema de
                                trabajo que me permitió desarrollar un modelo de negocios 100% distinto e innovador en
                                la actividad”.</p>

                            <div className="landing__testimonials__container--person">
                                <p className="landing__testimonials__name">Diego Corvalán</p>
                                <p className="landing__testimonials__position">PRODUCTOR Y ASESOR DE SEGUROS - MAT.
                                    82921</p>
                            </div>
                        </TestimonialCard>
                    </div>
                </Carousel.Slide>
                <Carousel.Slide>
                    <div className="landing__testimonials__container">
                        <TestimonialCard>
                            <img className="landing__testimonials__image" src="https://i.imgur.com/6pgc1mQ.jpeg"
                                                         alt="Andres_Avila"/>
                            <p className="landing__testimonials__text">“Es una excelente herramienta. La posibilidad de
                                poder cotizar online, en cualquier momento y con diversas aseguradoras, hace más ágil y
                                confiable la gestión con el cliente”.</p>

                            <div className="landing__testimonials__container--person">
                                <p className="landing__testimonials__name">Andres Avila</p>
                                <p className="landing__testimonials__position">PRODUCTOR Y ASESOR DE SEGUROS - MAT. 75303</p>
                            </div>
                        </TestimonialCard>
                    </div>
                </Carousel.Slide>
            </Carousel>
        </>
    );
}

export default Testimonials;