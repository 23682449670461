import React, {useContext} from 'react';
import {useFormikContext} from "formik";
import {currencyFormat} from "../../../../../../modules/utils/currencyFormat";
import {MantineTextArea} from "../../../../../../components/FormFields/MantineTextArea";
import {DetallesVehiculoModel} from "../DetallesVehiculo/DetallesVehiculoModel";
import CotizarContext from "../../../../../../context/CotizarContext";

export default function SolicitudForm() {
    const {values} = useFormikContext();
    const {formField} = DetallesVehiculoModel();
    const {comentariosSolicitud} = formField;
    const {companiesDict} = useContext(CotizarContext);
    const coverageAbleToHaveBienesAdicionales = values.company === "Zurich" ? values.rowData.parametrosAdicionales?.admiteBienesAdicionales : true;

    const calculateDisplayPremium = () => {
        if (values.company === 'RUS') {
            return Math.ceil(values.premio / parseInt(values.parametrosAdicionales.cuotas))
        } else {
            return values.premio
        }
    }

    return (
        <React.Fragment>
            <div className="automotor__formStep__container">
                <div className="autem__solicitud__header">
                    <p className="autem__solicitud__title">Detalles de póliza</p>
                    <img className="autem__solicitud__logo" src={companiesDict[values.company]['logo']}
                         alt={values.company}/>
                </div>
                <hr className="autem__solicitud__hr"/>
                <div className="autem__solicitud__body">
                    <div className="autem__solicitud__body__content">
                        <p className="autem__solicitud__content__title">Datos del vehículo</p>
                        <p className="autem__solicitud__content__text">Cobertura: <span>{values.nombreCobertura}</span>
                        </p>
                        <p className="autem__solicitud__content__text">{values.vehiculo.detalle_marca + " " + values.vehiculo.detalle_version + " " + values.vehiculo.anio}</p>
                        <p className="autem__solicitud__content__text">Patente: <span>{values.vehiculo.patente}</span>
                        </p>
                        <p className="autem__solicitud__content__text">Valor
                            vehículo: <span>$ {currencyFormat(values.vehiculo.sumaAseguradaVehiculo)}</span></p>
                        {coverageAbleToHaveBienesAdicionales &&
                            <p className="autem__solicitud__content__text">
                                GNC: <span>{values.vehiculo.tieneGnc}</span>
                            </p>
                        }
                    </div>
                    <div className="autem__solicitud__body__content">
                        <p className="autem__solicitud__content__title">Datos del asegurado</p>
                        <p className="autem__solicitud__content__text">Nombre: <span>{values.clientes[0].nombre + " " + values.clientes[0].apellido}</span>
                        </p>
                        <p className="autem__solicitud__content__text">
                            {values.clientes[0]['tipoPersona'] === '1'
                                ? <>CUIT: <span>{values.clientes[0].cuit}</span></>
                                : <>DNI: <span>{values.clientes[0].dni}</span></>
                            }
                        </p>
                        <p className="autem__solicitud__content__text">Celular: <span>{values.clientes[0].telefono}</span>
                        </p>
                    </div>
                </div>
                <div className="autem__solicitud__body--field">
                    <MantineTextArea
                        name={comentariosSolicitud.name}
                        label={comentariosSolicitud.label}
                        placeholder={"Comentarios adicionales para la solicitud"}
                    />
                </div>
                <div className="autem__solicitud__footer">
                    <p className="autem__solicitud__premium">
                        Cuota mensual: ${currencyFormat(calculateDisplayPremium())}
                    </p>
                </div>
            </div>
        </React.Fragment>
    );
}