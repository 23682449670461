export default {
    formId: 'leadCatcherForm',
    formField: {
        nombre: {
            name: 'nombre',
            label: 'Nombre',
            type: 'text',
            requiredErrorMsg: 'Debe especificar su nombre'
        },
        apellido: {
            name: 'apellido',
            label: 'Apellido',
            type: 'text',
            requiredErrorMsg: 'Debe especificar su apellido'
        },
        mail: {
            name: 'mail',
            label: 'Mail',
            type: 'text',
            requiredErrorMsg: 'Debe especificar un E-mail'
        },
        celular: {
            name: 'celular',
            label: 'Celular',
            type: 'number',
            requiredErrorMsg: 'Debe especificar un número de celular'
        },
        cuit: {
            name: 'cuit',
            label: 'CUIT',
            type: 'number',
            requiredErrorMsg: 'Debe especificar un CUIT'
        },
        numero_productor: {
            name: 'numero_productor',
            label: 'Número de productor',
            type: 'number',
            requiredErrorMsg: 'Debe especificar un número de productor'
        },
        numero_organizador: {
            name: 'numero_organizador',
            label: 'Número de organizador',
            type: 'number',
            requiredErrorMsg: 'Debe especificar un número de organizador'
        },
        compania: {
            name: 'compania',
            label: 'Compañía',
            type: 'select',
            requiredErrorMsg: 'Debe especificar una compañía'
        },
        tiene_codigo: {
            name: 'tiene_codigo',
            label: '¿Tenés código?',
            type: 'radio',
            options: [{value: true, label: 'Si'}, {value: false, label: 'No'}],
            requiredErrorMsg: 'Debe especificar si tiene código'
        },
        fecha_nacimiento: {
            name: 'fecha_nacimiento',
            label: 'Fecha de nacimiento',
            type: 'text',
            requiredErrorMsg: 'Debe especificar su fecha de nacimiento'
        },
        estado_civil: {
            name: 'estado_civil',
            label: 'Estado civil',
            type: 'select',
            options: [{value: 'Soltero/a', label: 'Soltero/a'}, {value: 'Casado/a', label: 'Casado/a'}],
            requiredErrorMsg: 'Debe especificar su estado civil'
        },
        domicilio: {
            name: 'domicilio',
            label: 'Domicilio',
            type: 'text',
            requiredErrorMsg: 'Debe especificar su domicilio'
        },
        codigo_postal: {
            name: 'codigo_postal',
            label: 'Código postal',
            type: 'number',
            requiredErrorMsg: 'Debe especificar su código postal'
        },
        localidad: {
            name: 'localidad',
            label: 'Localidad',
            type: 'localidad',
            requiredErrorMsg: 'Debe especificar su localidad'
        },
        provincia: {
            name: 'provincia',
            label: 'Provincia',
            type: 'text',
            requiredErrorMsg: 'Debe especificar su provincia'
        },
        oficina: {
            name: 'tiene_oficina',
            label: '¿Tenés oficina?',
            type: 'radio',
            options: [{value: "si", label: 'Si'}, {value: "no", label: 'No'}],
            requiredErrorMsg: 'Debe especificar si tiene oficina'
        },
        experiencia: {
            name: 'experiencia',
            label: 'Años de experiencia',
            type: 'number',
            requiredErrorMsg: 'Debe especificar su experiencia'
        },
        select_companies: {
            name: 'aseguradoras',
            label: 'Compañías con las que trabaja',
            type: 'multiSelect',
            options: [{value: 'Sancor', label: 'Sancor'}, {value: 'Rio Uruguay', label: 'Río Uruguay'}, {value: "San Cristobal", label: "San Cristóbal"}, {value: 'Zurich', label: 'Zurich'}],
            requiredErrorMsg: 'Debe especificar al menos una compañía'
        },
        files: {
            name: 'files',
            label: 'Archivos',
            requiredErrorMsg: 'Debe subir los archivos requeridos'
        },
    }
};
