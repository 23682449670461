import React, {Fragment, useState} from 'react';
import {
    Stepper,
    Step,
    StepLabel,
    Button,
    Typography,
    Snackbar
} from '@material-ui/core';
import {Formik, Form} from 'formik';

import VehiculoForm from './Forms/VehiculoForm';
import PolizaForm from './Forms/PolizaForm';
import ClienteForm from './Forms/ClienteForm';
import SolicitudForm from './Forms/SolicitudForm';

import validationSchema from './FormModel/validationSchema';
import formModel from './FormModel/formModel';
import formInitialValues from './FormModel/formInitialValues';

import useStyles from './styles';
import axios from 'axios';

import {useAuth0} from "@auth0/auth0-react";
import EmisionSuccess from "./EmisionSuccess";
import Loading from "../../../components/Loading";
import Alert from '@material-ui/lab/Alert';

const steps = ['Póliza', 'Cliente', 'Vehículo', 'Solicitud'];
const {formId, formField} = formModel;


function _renderStepContent(step, applyDiscounts) {
    switch (step) {
        case 0:
            return <PolizaForm formField={formField}/>;
        case 1:
            return <ClienteForm formField={formField}/>;
        case 2:
            return <VehiculoForm formField={formField}/>;
        case 3:
            return <SolicitudForm formField={formField} applyDiscounts={applyDiscounts}/>;
        default:
            return <div>Not Found</div>;
    }
}

export default function CheckoutPage(props) {
    const classes = useStyles();
    const {continueCotizacion, emisionValues, applyDiscounts} = props;
    const [activeStep, setActiveStep] = useState(0);
    const currentValidationSchema = validationSchema[activeStep];
    const isLastStep = activeStep === steps.length - 1;
    const [emisionResponse, setEmisionResponse] = useState(null);
    const [finalValues, setFinalValues] = useState(null);
    const {getIdTokenClaims} = useAuth0();

    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);

    const handleOpenAlert = (message) => {
        setOpenAlert(true);
        setAlertMessage(message);
    };

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
        setAlertMessage(null);
    };

    async function _submitForm(values, actions) {
        const claims = await getIdTokenClaims();
        const id_token = claims.__raw;
        values.applyDiscounts = true;
        for (const element of values.clientes) {
            if (element['personaJuridica'] === '1') {
                element['situacionIva'] = '1'
                element['dni'] = element['cuit']
            }
        }

        let url = process.env.REACT_APP_BACK_SERVER + '/emitir';
        if (values.vehiculo.tieneGnc === 'true') {
            values.vehiculo.inspeccionPrevia.tipoCombustible = '3'
            values.bienesAsegurados.push({
                id: "1",
                descripcion: values.vehiculo.gnc.descripcionGnc,
                suma: values.vehiculo.gnc.valorTotal
            })
        }

        setFinalValues(values);

        axios.post(url, {...values, ...{applyDiscounts: applyDiscounts}}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${id_token}`,
            }
        }).then(res => {
            setEmisionResponse(res.data)
        }).catch(err => {
            console.log(err);
            alert('No se pudo completar la operación, vuelva a cargar la página')
        });

        actions.setSubmitting(false);
        setActiveStep(activeStep + 1);
    }

    function _handleSubmit(values, actions) {
        if (isLastStep) {
            _submitForm(values, actions);
        } else {
            setActiveStep(activeStep + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    }

    function _handleBack() {
        setActiveStep(activeStep - 1);
    }

    return (
        <React.Fragment>
            <Typography style={{
                fontFamily: "Ubuntu",
                fontSize: 36,
                fontWeight: 400,
                fontStyle: "normal",
                letterSpacing: -0.5,
                color: '#35303f',
                textAlign: 'center'
            }}>
                Emisión
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
                {steps.map(label => (
                    <Step key={label}
                          classes={{
                              root: classes.step,
                              completed: classes.completed,
                              active: classes.active
                          }}
                    >
                        <StepLabel
                            StepIconProps={{
                                classes: {
                                    root: classes.step,
                                    completed: classes.completed,
                                    active: classes.active,
                                }
                            }}
                        >
                            {label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Fragment>
                {activeStep === steps.length ? (
                    <Fragment>
                        {(emisionResponse !== null && finalValues !== null)
                            ? <EmisionSuccess finalValues={finalValues}
                                              emisionResponse={emisionResponse}
                                              applyDiscounts={applyDiscounts}
                                              setActiveStep={setActiveStep}
                                              setEmisionResponse={setEmisionResponse}/>
                            : (
                                <Fragment>
                                    <Typography variant="h5" gutterBottom>
                                        Procesando el pedido. Por favor espere...
                                    </Typography>
                                    <Loading/>
                                    <Typography variant="body1" gutterBottom align="center">
                                        Esto puede demorar hasta 60 segundos.
                                    </Typography>
                                </Fragment>
                            )
                        }
                    </Fragment>
                ) : (
                    <Formik
                        initialValues={continueCotizacion ? emisionValues : formInitialValues}
                        validationSchema={currentValidationSchema}
                        validateOnBlur={false}
                        onSubmit={_handleSubmit}
                    >
                        {({isSubmitting, errors}) => (
                            <Form id={formId}>
                                {_renderStepContent(activeStep, applyDiscounts)}
                                <div className={classes.buttons}>
                                    {activeStep !== 0 && (
                                        <Button onClick={_handleBack} className={classes.button}>
                                            Atras
                                        </Button>
                                    )}
                                    <div className={classes.wrapper}>
                                        <Button
                                            disabled={isSubmitting}
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={classes.buttonContained}
                                        >
                                            {isLastStep ? 'Emitir' : 'Siguiente'}
                                        </Button>
                                    </div>
                                </div>
                                {isSubmitting && errors?.vehiculo?.inspeccionPrevia?.archivos !== undefined && (
                                    <div>
                                        {handleOpenAlert(errors?.vehiculo?.inspeccionPrevia?.archivos)}
                                    </div>
                                )}
                                {isSubmitting && errors?.clientes !== undefined && (
                                    <div>
                                        {handleOpenAlert("Algunos de los campos ingresados no son válidos, revise nuevamente los clientes ingresados.")}
                                    </div>
                                )}
                            </Form>
                        )}
                    </Formik>
                )}
            </Fragment>
            <Snackbar open={openAlert && alertMessage} autoHideDuration={5000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity="warning" elevation={6} variant="filled"
                       classes={{filledWarning: classes.alert}}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}
