import React, {useContext, useMemo, useState} from 'react';
import {useFormikContext} from "formik";
import Tabs from "../../../../../../components/Tabs/Tabs";
import {QuotationResponseTab} from "../../../../../Cotizacion/QuotationResponseTab";
import {useCurrentQuotationContext} from "../../../../../../context/CurrentQuotationContext/CurrentQuotationContext";
import CotizarContext from "../../../../../../context/CotizarContext";
import UserContext from "../../../../../../context/UserContext";
import {ReactComponent as InformationFilledIcon} from "../../../../../../assets/icons/ic_info-filled.svg";
import {PopUpCardModal} from "../../../../../../layouts/PopUpCardModal";
import MotovehiculoContext from "../../../../../../context/Products/MotovehiculoContext/MotovehiculoContext";
import {isResponseSuccessful} from "../../../../../../modules/utils/isResponseSuccessful";
import {getBenefitsList} from "../../../../Automotor/FormStages/Cotizar/CotizarSuccess/utils/getBenefitsList";

function CotizarSuccessView(props) {
    const {submitForm} = props;
    const {addCoverageToCart, cotizacionResponse} = useCurrentQuotationContext();
    const {values} = useFormikContext();
    const {companiesDict} = useContext(CotizarContext)
    const {userCompanies} = useContext(UserContext);
    const [displayConfiguracionAvanzada, setDisplayConfiguracionAvanzada] = useState(false);
    const [companyForModal, setcompanyForModal] = useState("");
    const [activeTab, setActiveTab] = useState(0);
    const {vehicleDetails} = useContext(MotovehiculoContext)
    const initialTab = useMemo(() => {
        if (cotizacionResponse) {
            const index = values.companies.findIndex(company => isResponseSuccessful(cotizacionResponse[companiesDict[company]['name']]?.status_code))
            setActiveTab(index)
            return index;
        }
        else return null;
    }, [cotizacionResponse, values.companies, companiesDict])
    const benefits = useMemo(() => {
        return getBenefitsList(companyForModal);
    }, [companyForModal])

    return (
        <div className="motovehiculo__formStep__container">
            <p className="motovehiculo__cotSuccess__title">{vehicleDetails.current.detalle_marca} {vehicleDetails.current.detalle_version} - {values['motovehiculo']['anio']}</p>
            <div className="motovehiculo__cotSuccess__container">
                <Tabs
                    initialTab={initialTab}
                    tabs={
                        values.companies.map((company, index) => {
                            const isPromotional = !userCompanies.includes(companiesDict[company].name)
                            return (
                                <div className="automotor__cotSuccess__tabLogoContainer"
                                     onClick={() => setActiveTab(index)}>
                                    <img
                                        className={`automotor__cotSuccess__tabLogo ${index !== activeTab && "automotor__cotSuccess__tabLogoContainer--blur"}`}
                                        src={companiesDict[company]['logo']}
                                        alt={companiesDict[company]['name']}/>
                                    {isPromotional && <InformationFilledIcon
                                        className="automotor__cotSuccess__promotionalMoreInfo"
                                        onClick={() => setcompanyForModal(companiesDict[company].razonSocial)}/>}
                                </div>
                            )
                        })
                    }
                    tabContent={
                        values.companies.map(company => (
                            <QuotationResponseTab
                                displayConfiguracionAvanzada={displayConfiguracionAvanzada}
                                setDisplayConfiguracionAvanzada={setDisplayConfiguracionAvanzada}
                                submitForm={submitForm}
                                addCoverageToCart={addCoverageToCart}
                                company={company}/>
                        ))
                    }
                />
            </div>
            {companyForModal && <PopUpCardModal title={<h1 className="csm__title">{companyForModal}</h1>}
                                                body={<div className="csm__body">
                                                    <p className="csm__text">
                                                        <p className="csm__initialText">Disfrute la experiencia de
                                                            cotizar su producto con {companyForModal}.<br/>
                                                            {companyForModal !== 'Río Uruguay' && "La compañía cuenta con los siguientes beneficios:"}
                                                        </p><br/>
                                                        <ul className="csm__listContainer">
                                                            {benefits.map((benefit, index) => (
                                                                <li key={index}>
                                                                    <strong>{benefit.title}:</strong> {benefit.description}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </p>
                                                </div>}
                                                closeAction={() => setcompanyForModal("")}/>}
        </div>
    );
}

export default CotizarSuccessView;