import React from 'react';
import './BilleteraCardSecondary.css';
import {currencyFormat} from "../../modules/utils/currencyFormat";
import {Button} from "../Button";
import {Skeleton} from "@mantine/core";
import useWindowDimensions from "../../modules/utils/useWindowDimensions";

function BilleteraCardSecondary(props) {
    const {amount, pendingPayment, text, buttonAction, buttonText, buttonColor, loading} = props;
    const {width} = useWindowDimensions();

    if (loading) return (
        <Skeleton height={"100%"}>
            <div className="billeteraCardSecondary__container"/>
        </Skeleton>
    )
    return (
        <div className="billeteraCardSecondary__container">
            <div className="billeteraCardSecondary__body">
                <p className="billeteraCardSecondary__text">{text}</p>
                <p className="billeteraCardSecondary__amount">$ {currencyFormat(amount)}</p>
            </div>

            <div className="billeteraCardSecondary__buttonSection">
            <Button
                type="button"
                variant="filled"
                color={buttonColor}
                enabled={amount > 0 || pendingPayment > 0}
                marginY="sm"
                size={width < 1025 ? "md" : "lg"}
                onClick={buttonAction}
            >
                {buttonText}
            </Button>
            {pendingPayment > 0 &&
                <div>
                    <p className="billeteraCardSecondary__note">
                        Pendiente de pago: $ {currencyFormat(pendingPayment)}
                    </p>
                </div>
            }
            </div>
        </div>
    );
}

export default BilleteraCardSecondary;