const productIdentifier = (object) => {
    if (object.hasOwnProperty('vehiculo')) {
        return "AUTOMOTOR"
    } else if (object.hasOwnProperty('hogar')) {
        return "HOGAR"
    } else if (object.hasOwnProperty('microseguros')) {
        return "MICROSEGUROS"
    }else if (object.hasOwnProperty('motovehiculo')) {
        return "MOTOVEHICULO"
    }
    else if (object.hasOwnProperty('asistencia_viajero')) {
        return "ASISTENCIA AL VIAJERO"
    }
}

export {productIdentifier}