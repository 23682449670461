import {sendWhatsapp} from "../../../../modules/utils/sendWhatsapp";
import {ReactComponent as MenuIcon} from "../../../../assets/icons/ic_options.svg";
import moment from "moment";
import React, {useState} from "react";
import "./QTableButtons.css"
import "./QTableMobileButtons.css"
import {Menu} from "@mantine/core";
import {useHistory} from "react-router-dom";

function QTableMobileButtons(props) {
    const {valuesRow, setIsOpenDeleteAlert, setDetailedRow, client, product, detail} = props;
    const [openMenu, setOpenMenu] = useState(false);
    const history = useHistory();


    const handleTrashButton = (idQuotation, description, clientName, date) => {
        setIsOpenDeleteAlert(true)
        setDetailedRow({idQuotation, description, clientName, date})
    }


    return (
        <Menu shadow="md" width={95} opened={openMenu} onChange={setOpenMenu}>
            <Menu.Target>
                <div onClick={() => setOpenMenu(!openMenu)}><MenuIcon className="QT__menuOpenIcon"/></div>
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Item onClick={() => {
                    history.push({
                        pathname: `/u/cotizar/${product.toLowerCase()}`,
                        state: {
                            rowData: valuesRow,
                            action: "emitir"
                        }
                    });
                }}>Emitir</Menu.Item>
                <Menu.Item onClick={() => {
                    history.push({
                        pathname: `/u/cotizar/${product.toLowerCase()}`,
                        state: {
                            rowData: valuesRow,
                            action: "recotizar"
                        }
                    });
                }}
                >Recotizar</Menu.Item>
                <Menu.Divider/>
                {client && <Menu.Item onClick={() =>
                    sendWhatsapp('https://wa.me/' + valuesRow['clientes'][0]['telefono'] + '?text=¡Buenos%20días,%20' + valuesRow['clientes'][0]['nombre'] + '!%0aMe%20pongo%20en%20contacto%20con%20usted%20para%20consultarle%20sobre%20la%20cotización%20de%20su%20' + product.toLowerCase() + '%20' + detail + '%20que%20realizó%20el%20día%20%20' + '%20' + moment(valuesRow['fecha']).format('DD/MM/YYYY') + '.')
                }>Contactar</Menu.Item>}
                <Menu.Item onClick={() => {
                    if (client) {
                        handleTrashButton(valuesRow['_id'], valuesRow['detalle'], valuesRow['clientes'][0]['nombre'] + " " + valuesRow['clientes'][0]['apellido'], valuesRow['fecha'])
                    } else {
                        handleTrashButton(valuesRow['_id'], detail, "", valuesRow['fecha'])
                    }
                }}><p className="QT__deleteOption">Eliminar</p></Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
}

export default QTableMobileButtons;