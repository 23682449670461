import React from 'react';
import './DescriptionColumn.css';
import TextLink from "../../../../components/TextLink/TextLink";

function DescriptionColumn(props) {
    const {row, setModalOpen} = props;

    const handleOpenModal = () => {
        setModalOpen({
            modalName: "Description",
            values: {row: row, descriptions: row['descripcion']}
        })
    }

    return (
        <>
            <TextLink
                size={"sm"}
                action={() => handleOpenModal()}
            >
                Ver Descripciones
            </TextLink>

        </>
    );
}

export default DescriptionColumn;