import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Axios from "axios";
import FileDownload from "js-file-download";

const useStyles = makeStyles(theme => ({
    hideControler: {
        margin: "8px",
        minHeight: "260px",
        '&:hover $divColumn':{
            filter: "opacity(0.6) grayscale(1) blur(1px)",
            cursor: "default"
        },
        "&:hover $selectButton": {
            display: "block"
        }
    },
    templateContainer: {
        width: "120px",
        aspectRatio: "9/16",
        borderRadius: "4px",
        overflow: "hidden"
    },
    divColumn: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        margin: "0 16px",
        cursor: "pointer",
    },
    imgTemplate: {
        objectFit: "cover",
        objectPosition: "0% 0%",
        width: "100%",
        height: "100%",
    },
    selectButton: {
        position: "relative",
        top: "-104px",
        left: "0px",
        display: "none",
        width: "88px",
        height: "32px",
        textAlign: "center",
        borderRadius: "24px",
        fontFamily: "Ubuntu",
        color: "#452d7b",
        fontSize: "14px",
        background: "rgba(0,0,0,0)",
        padding: "4px 8px",
        border: "solid #452d7b 2px",
    },
    divRow: {
        display: "flex",
        width: "100%",
        height: "0",
        justifyContent: "center"
    },
    pieceName: {
        fontFamily: "Ubuntu",
        color: "#452d7b",
        fontSize: "16px",
        textAlign: "center"
    }
}));


function PiezaGrafica(props) {
    const classes = useStyles();
    const {preview, alternative, logo, qr, activeProducts, setRowDataSelected, setLoading, name, setOpen} = props;

    const handleDownload = () => {
        const jsonData = {
            qrUrl: qr,
            logoUrl: logo,
            alternative: alternative,
            activeProducts: activeProducts
        }
        setLoading(true)
        setRowDataSelected(null)
        setOpen(false)
        Axios.post(process.env.REACT_APP_BACK_SERVER + `/folletoCanalDigital`, jsonData, {responseType: 'blob'})
            .then((response) => {
                FileDownload(response.data, 'productos.png');
                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
                setLoading(false)
            });
    }
    return (
        <div className={classes.hideControler}>
            <div className={classes.divColumn}>
                <div className={classes.templateContainer}>
                    <img className={classes.imgTemplate} src={preview}/>
                </div>
            </div>
            <div className={classes.divRow}>
                <button
                    type="button"
                    className={classes.selectButton}
                    onClick={() => handleDownload()}
                >
                    Descargar
                </button>
            </div>
            <div className={classes.divRow} style={{marginTop: "8px"}}>
                <p className={classes.pieceName}>{name}</p>
            </div>
        </div>

    );
}

export default PiezaGrafica;