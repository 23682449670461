import React, {useState} from 'react';
import './MantineTextArea.css';
import {useField} from "formik";
import {Textarea} from '@mantine/core';
import {ReactComponent as ExclamationIcon} from "../../../assets/icons/ic_exclamation.svg";

function MantineTextArea(props) {
    const {label, name} = props;
    const [field, meta, helpers] = useField(name);
    const [value, setValue] = useState(field.value);
    const {setValue: setFieldValue, setTouched} = helpers;

    return (
        <Textarea
            {...props}
            label={label}
            value={value}
            onBlur={() => {
                setFieldValue(value)
                setTouched(name, false)
            }}
            onChange={(e) => setValue(e.target.value)}
            error={meta.error && meta.touched && <div>{meta.error}</div>}
            classNames={{
                wrapper: 'mantine__textarea__wrapper',
                invalid: 'mantine__textarea__wrapper',
                label: meta.error && meta.touched ? 'mantine__textarea__label--error' : 'mantine__textarea__label',
                required: meta.error && meta.touched ? 'mantine__textarea__required--error' : 'mantine__textarea__required',
                error: 'mantine__textarea__error',
                input: meta.error && meta.touched ? 'mantine__textarea__field--error' : 'mantine__textarea__field',
            }}
            rightSection={
                meta.error && meta.touched && <ExclamationIcon className="mantine__textarea__icon"/>
            }
        />
    );
}

export default MantineTextArea;