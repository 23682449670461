import React, {useContext, useMemo, useState} from 'react';
import './CoverageComparison.css';
import {CoveragesOverviewTable} from "../CoveragesOverviewTable";
import {useCurrentQuotationContext} from "../../../context/CurrentQuotationContext/CurrentQuotationContext";
import {Button} from '../../../components/Button';
import {useFormikContext} from "formik";
import {CustomModal} from "../../../components/CustomModal";
import {FormCard} from "../../../components/FormCard";
import Axios from "axios";
import FileDownload from "js-file-download";
import UserContext from "../../../context/UserContext";
import {currencyFormat} from "../../../modules/utils/currencyFormat";
import moment from "moment";
import {Loading} from "../../../components/Loading";
import CotizarContext from "../../../context/CotizarContext";
import DefaultValuesContext from "../../../context/DefaultValuesContext";
import showNotification from "../../../modules/utils/showNotification";
import {format} from "date-fns";
import isPercentage from "../../../modules/utils/isPercentage";
import {todoRiesgoAmountReplacer} from "../../../modules/utils/todoRiesgoAmountReplacer";

function CoverageComparison(props) {
    const {setCoverageComparisonModalOpen, downloadPdfJsonTemplate, resumeKeyConstructor} = props;
    const {
        cartCoverages,
        setUpdatingForm,
        setCurrentFormData,
        cotizacionResponse,
        finalValues,
        formData
    } = useCurrentQuotationContext();
    const {setFieldValue} = useFormikContext();
    const {
        userIdAuth,
        userName,
        userLastName,
        userMail,
        userLocalidad,
        userPicture,
        userPhone,
        userMatricula,
        masterMatricula,
        userWebHabilitado,
        userLink,
        activeFormProduct,
        userCompanies
    } = useContext(UserContext);
    const {coberturasProductsInfo, companiesDict} = useContext(CotizarContext);
    const {companiesDefaultValues} = useContext(DefaultValuesContext);
    const [selectedId, setSelectedId] = useState(null);
    const [highlightedId, setHighlightedId] = useState(null);
    const [isDownloading, setIsDownloading] = useState(false);
    const userHasSelectedCompany = useMemo(() => {
        let boolResult = false;
        if (selectedId !== null) boolResult = userCompanies.includes(cartCoverages[selectedId].companyName)
        return boolResult
    }, [userCompanies, cartCoverages, selectedId]) ;
    let resumeName = "";
    if (resumeKeyConstructor) {
        resumeKeyConstructor.forEach((key) => {
            const splitKey = key.split(".");
            let valueToPush = "";
            if (splitKey.length === 1) {
                valueToPush = finalValues[splitKey[0]] || splitKey[0];
                if (valueToPush instanceof Date) {
                    valueToPush = format(valueToPush, 'dd/MM/yyyy');
                }
            } else if (splitKey.length === 2) {
                valueToPush = finalValues[splitKey[0]] ? finalValues[splitKey[0]][splitKey[1]] : key;
            }
            resumeName = resumeName + " " + valueToPush;
        })
    } else {
        resumeName = `${cartCoverages[0].detalle_marca} ${cartCoverages[0].anio} - ${cartCoverages[0].detalle_version}`
    }

    const detallesCobertura = {
        companies: cartCoverages.map(item => item.company),
        nombreCobertura: cartCoverages.map(item => item.nombre),
        premium: cartCoverages.map(item => {
            return item.premium;
        }),
        actualPremium: cartCoverages.map(item => {
            return item.actualPremium;
        }),
        plans: cartCoverages.map(plan => plan['id']),
        franquicia: cartCoverages.map(plan => plan['franquicia']),
        beneficios: cartCoverages.map(item => item.benefits ? item.benefits : null),
    }

    //Replicate changes applied to this function into QuotationResponseTab.js
    const handleAddStep = (companyId) => {
        const cotizarDisabledStep = {
            name: "#Cotización",
            form: null,
            initialValues: null,
            validationSchema: null
        }

        const rowData = cartCoverages[selectedId];

        setFieldValue("company", companiesDict[companyId]['name'])
        setFieldValue("companyId", companyId)
        setFieldValue("rowData", rowData)
        setFieldValue("nombreCobertura", todoRiesgoAmountReplacer(
            companiesDict[companyId]['name'],
            coberturasProductsInfo[activeFormProduct],
            rowData
        ))

        setUpdatingForm(true)
        setCurrentFormData([cotizarDisabledStep, ...formData['emision'][companiesDict[companyId]['name']]]);
    }

    const changeSelectedId = (_id) => {
        if (selectedId === _id) setSelectedId(null)
        else setSelectedId(_id)
    }
    const changeHighlightedId = (_id) => {
        if (highlightedId === _id) setHighlightedId(null)
        else setHighlightedId(_id)
    }

    const handleDownloadPDF = () => {
        setIsDownloading(true);
        let jsonData = {}
        let cartCoveragesModified = []

        downloadPdfJsonTemplate.forEach(key => {
            const splitKey = key.split(".");
            let valueToPush = "";
            if (splitKey.length === 1) {
                valueToPush = finalValues[splitKey[0]] || splitKey[0];
                if (valueToPush instanceof Date) {
                    valueToPush = format(valueToPush, 'dd/MM/yyyy');
                }
                jsonData[splitKey[0]] = valueToPush
            } else if (splitKey.length === 2) {
                valueToPush = finalValues[splitKey[0]] ? finalValues[splitKey[0]][splitKey[1]] : key;
            jsonData[splitKey[1]] = valueToPush
            }
        });

        switch (activeFormProduct) {
            case 'asistencia_viajero':
                let allBenefitsCodesList = [];
                let allBenefitsList = {};
                detallesCobertura.beneficios.forEach(benefitsList => Object.keys(benefitsList).forEach(benefit => {
                        if (!allBenefitsCodesList.includes(benefit)) {
                            allBenefitsCodesList.push(benefit)
                            allBenefitsList[benefit] = benefitsList[benefit].coverage_name
                        }
                    }
                ));
                jsonData['allBenefitsCodesList'] = allBenefitsCodesList
                jsonData['allBenefitsList'] = allBenefitsList
                cartCoveragesModified = cartCoverages.map(obj => {
                    return {
                        ...obj,
                        premiumHigh: currencyFormat(Math.ceil((obj.actualPremium || obj.premium) * 1.2)),
                        premium: currencyFormat(Math.ceil(obj.actualPremium || obj.premium)),
                    };
                })
                break;
            case 'motovehiculo':
                cartCoveragesModified = cartCoverages.map(obj => {
                    return {
                        ...obj,
                        detalles: coberturasProductsInfo[activeFormProduct][companiesDict[obj.company]['name']][obj['id'].toString()]['detalle'] ?? {},
                        premiumHigh: currencyFormat(Math.ceil((obj.actualPremium || obj.premium) * 1.2)),
                        premium: currencyFormat(Math.ceil(obj.actualPremium || obj.premium)),
                        sumaAseguradaVehiculo: currencyFormat(obj?.sumaAseguradaVehiculo),
                        valorRC: currencyFormat(companiesDefaultValues['RC_VALUES'][obj.companyName]),
                        franquicia: obj.franquicia ?? 0,
                    };
                });
                break;
            case 'automotor':
                jsonData['hasSancorExtraDiscount'] = cartCoverages.some(item => item.company === 1 && parseInt(item.anio) >= 2015);
                jsonData['tieneGnc'] = finalValues.vehiculo['tieneGnc'];
                jsonData['sumaAseguradaGnc'] = currencyFormat(finalValues.vehiculo['sumaAseguradaGnc']);
                jsonData['sumaAseguradaAdicional'] = currencyFormat(finalValues.vehiculo['sumaAseguradaAdicional']) || 0;
                cartCoveragesModified = cartCoverages.map((obj, idx) => {
                    const franchiseDictValue = coberturasProductsInfo[activeFormProduct][companiesDict[obj.company]?.['name']][detallesCobertura['plans'][idx].toString()]['detalle']['APD'] ?? 0;
                    const franchise = () => {
                        if (franchiseDictValue) {
                            if (isPercentage(franchiseDictValue)) return franchiseDictValue
                            else return obj.franquicia
                        } else return 0
                    }
                    return {
                        ...obj,
                        detalles: coberturasProductsInfo[activeFormProduct][companiesDict[obj.company]['name']][obj['id'].toString()]['detalle'] ?? {},
                        premiumHigh: currencyFormat(Math.ceil((obj.actualPremium || obj.premium) * 1.2)),
                        premium: currencyFormat(Math.ceil(obj.actualPremium || obj.premium)),
                        sumaAseguradaVehiculo: currencyFormat(obj?.sumaAseguradaVehiculo),
                        valorRC: currencyFormat(companiesDefaultValues['RC_VALUES'][obj.companyName]),
                        franquicia: franchise(),
                        hasGnc: !!companiesDict[companiesDict[obj.company]['name']].equipoGncId,
                        hasBienesAdicionales: !!companiesDict[companiesDict[obj.company]['name']].bienesAdicionales
                    };
                });
                break;

        }

        jsonData = {
            ...jsonData,
            'num_cotizacion': cotizacionResponse['idCotizar'],
            'polkista_img': userPicture,
            'polkista_nombre': userName + " " + userLastName,
            'polkista_celular': userPhone,
            'polkista_email': userMail,
            'polkista_localidad': userLocalidad,
            'polkista_web': userWebHabilitado ? userLink.split("://")[1] : "",
            'selected_coverages': cartCoveragesModified,
            'starred_coverage': highlightedId !== null ? cartCoveragesModified[highlightedId]['id'] : '',
            'id_Auth': userIdAuth,
            'matricula': userMatricula === "" ? masterMatricula : userMatricula,
            'fecha': moment().format('DD/MM/YYYY'),
        };

        let url = process.env.REACT_APP_PRINT_MICROSERVICE + '/newTemplate'
        switch (activeFormProduct) {
            case 'motovehiculo':
                url += '/motovehiculo'
                break;
            case 'asistencia_viajero':
                url += '/asistencia-viajero'
                break;
            default:
                break;
        }

        Axios.post(url, jsonData, {responseType: 'blob'})
            .then((response) => {
                FileDownload(response.data, 'cotizacion.pdf');
                setIsDownloading(false);
            })
            .catch((error) => {
                showNotification({
                    autoClose: 3000,
                    color: 'red',
                    status: 'error',
                    title: 'Error al descargar el PDF.',
                    message: error.response?.data?.detail || "Hubo un problema en la comunicación con el servicio.",
                })
                setIsDownloading(false);
            });
    }

    return (
        <CustomModal>
            <FormCard>
                {isDownloading
                    ? <Loading/>
                    : <div className="ccomparison__container">
                        <CoveragesOverviewTable
                            nombreModelo={resumeName}
                            detallesCobertura={detallesCobertura}
                            title={"Detalle de coberturas"}
                            changeSelectedId={changeSelectedId}
                            changeHighlightedId={changeHighlightedId}
                            selectedId={selectedId}
                            highlightedId={highlightedId}
                            displayFooter
                        />

                        <div className="ccomparison__buttons__container">
                            <Button variant="filled"
                                    color="primary"
                                    marginX="sm"
                                    enabled
                                    type="button"
                                    onClick={() => setCoverageComparisonModalOpen(false)}>
                                Atras
                            </Button>

                            <Button variant="filled"
                                    color="primary"
                                    marginX="sm"
                                    enabled
                                    type="button"
                                    onClick={handleDownloadPDF}
                            >
                                Descargar PDF
                            </Button>

                            <Button variant="filled"
                                    color="primary"
                                    marginX="sm"
                                    type="button"
                                    enabled={selectedId !== null && userHasSelectedCompany}
                                    onClick={() => {
                                        setCoverageComparisonModalOpen(false);
                                        handleAddStep(cartCoverages[selectedId]['company'])
                                    }}
                            >
                                Emitir
                            </Button>
                        </div>
                    </div>
                }
            </FormCard>
        </CustomModal>
    );
}

export default CoverageComparison;