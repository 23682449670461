import { ReactComponent as ChevronDownIcon } from '../../../assets/icons/ic_chevron_down.svg';
import './ChevronDown.css';
import '../Icon.css';

/* available sizes: sm, md */
/* fitContainer expands the component to fit container dimensions */
const ChevronDown = (props) => {
    const { size = "md", fitContainer, rotate } = props;

    return (
        <ChevronDownIcon className={`ChevronDown ${rotate && `rotate${rotate}`} Icon ${fitContainer ? 'fitContainer' : size}`} />
    );

}

export default ChevronDown;