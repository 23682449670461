import React, {useContext, useEffect} from 'react';
import {Grid} from '@mantine/core';
import {useFormikContext} from 'formik'
import {VehiculoModel} from "./VehiculoModel";
import {MantineSelectField} from "../../../../../../components/FormFields/MantineSelectField";
import {MantineNumberField} from "../../../../../../components/FormFields/MantineNumberField";
import {useIsMount} from "../../../../../../modules/components/useIsMount";
import MotovehiculoContext from "../../../../../../context/Products/MotovehiculoContext/MotovehiculoContext";
import {findSelectedLabel} from "../../../../../../modules/utils/findSelectedLabel";

export default function VehiculoForm(props) {
    const {formField} = VehiculoModel();
    const {
        motovehiculo: {
            marca,
            anio,
            version,
            esCeroKm,
            sumaAseguradaAdicional
        }
    } = formField;
    const {values, setFieldValue} = useFormikContext();
    const {cotizacionRetomada} = props;
    const {marcas, anios, versiones, updateVersions, vehicleDetails, isLoadingVersions, handleRecotizarInitialValues} = useContext(MotovehiculoContext);
    const isMount = useIsMount();

    const cerokm_options = [
        {value: 'true', label: 'Si'},
        {value: 'false', label: 'No'}
    ];

    useEffect(() => {
        if (cotizacionRetomada) {
            handleRecotizarInitialValues(values.motovehiculo.version, values.motovehiculo.detalle_version, values.motovehiculo.detalle_marca);
        }
    }, []);


    useEffect(() => {
        if (isMount) {      // isMount is true if it's the first render of the component
            if (cotizacionRetomada) {
                updateVersions(values.motovehiculo.marca, values.motovehiculo.anio, false);
            }
        } else {
            if (values.motovehiculo.anio !== '') {
                setFieldValue(version.name, '', false);
                updateVersions(values.motovehiculo.marca, values.motovehiculo.anio, true);
            }
        }
    }, [values.motovehiculo.anio, values.motovehiculo.marca]);


    return (
        <div className="motovehiculo__formStep__container">
                <Grid gutterXs="xs" gutter="md" justify="center" align="center">
                    <Grid.Col xs={12} sm={4}>
                        <MantineSelectField
                            name={marca.name}
                            label={marca.label}
                            data={marcas}
                            onChangeAction={value => {
                                vehicleDetails.current = {
                                    ...vehicleDetails.current,
                                    detalle_marca: findSelectedLabel(marcas, value)
                                }
                            }}
                            searchable
                            clearable
                            withAsterisk
                        />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={2}>
                        <MantineSelectField
                            name={anio.name}
                            label={anio.label}
                            data={anios}
                            searchable
                            withAsterisk
                        />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6}>
                        <MantineSelectField
                            name={version.name}
                            label={version.label}
                            data={versiones}
                            onChangeAction={value => {
                                vehicleDetails.current = {
                                    ...vehicleDetails.current,
                                    detalle_version: findSelectedLabel(versiones, value)
                                }
                            }}
                            loadingData={isLoadingVersions}
                            searchable
                            clearable
                            withAsterisk
                        />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6}>
                        <MantineSelectField
                            name={esCeroKm.name}
                            label={esCeroKm.label}
                            data={cerokm_options}
                            withAsterisk
                        />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6}>
                        <MantineNumberField
                            name={sumaAseguradaAdicional.name}
                            label={sumaAseguradaAdicional.label}
                            withAsterisk
                        />
                    </Grid.Col>
                </Grid>
        </div>
    );
}
