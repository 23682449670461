import React from "react";
import {Route, useRouteMatch} from "react-router-dom";
import {compose} from "recompose";
import EmisionVehiculo from "../modules/Emision/VehiculoForm/EmisionPage";
import EmisionCombinado from "../modules/Emision/CombinadoForm/EmisionPage";
import EmisionMicroseguro from "../modules/Emision/MicrosegurosForm/EmisionPage";
import Productos from "../modules/Productos/Productos";
import Cotizaciones from "../modules/views/Cotizaciones";
import {withDashboardContextProvider, withDashboardContextReady} from "../context/DashboardContext";
import {withCotizarContextProvider, withCotizarContextReady} from "../context/CotizarContext";
import {withDefaultValuesContextProvider, withDefaultValuesContextReady} from "../context/DefaultValuesContext";

import {Dashboard} from '../pages/Dashboard'
import {Cotizacion} from '../pages/Cotizacion'
import {Academy} from '../pages/Academy'
import AcademyCourse from "../features/Academy/AcademyCourse/AcademyCourse";
import MChoice from "../features/Academy/Multiplechoice/MChoice/MChoice";
import {Documentacion} from '../pages/Documentacion'
import {Billetera} from '../pages/Billetera'
import {Marketing} from '../pages/Marketing'
import {Profile} from "../pages/Profile";

const PolkistasRoutes = () => {
    const {path} = useRouteMatch();

    return (
        <>
            <Route path={`${path}/cotizar/:producto`} component={Cotizacion}/>
            <Route path={`${path}/dashboard`} component={Dashboard}/>
            <Route path={`${path}/documentacion`} component={Documentacion}/>
            <Route path={`${path}/billetera`} component={Billetera}/>
            <Route path={`${path}/marketing`} component={Marketing}/>

            <Route path={`${path}/emitir/vehiculo`} component={EmisionVehiculo}/>
            <Route path={`${path}/emitir/combinado`} component={EmisionCombinado}/>
            <Route path={`${path}/emitir/microseguro`} component={EmisionMicroseguro}/>

            <Route exact path={`${path}/academia`} component={Academy}/>
            <Route path={`${path}/academia/cursos/:id`} component={AcademyCourse}/>
            <Route path={`${path}/academia/preguntas/:id`} component={MChoice}/>
            <Route path={`${path}/productos`} component={Productos}/>
            <Route path={`${path}/perfil`} component={Profile}/>

            <Route path={`${path}/cotizaciones/:producto`} component={Cotizaciones}/>
            <Route path={`${path}/documentacion/:producto`} component={Documentacion}/>
        </>
    );
};

export default compose(
    withCotizarContextProvider,
    withCotizarContextReady,
    withDashboardContextProvider,
    withDashboardContextReady,
    withDefaultValuesContextProvider,
    withDefaultValuesContextReady
)(PolkistasRoutes);