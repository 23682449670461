import withRoot from '../withRoot';
// --- Post bootstrap -----
import React, {useState} from 'react';
import Contacto2 from "../views/Contacto2.0";
import {isMobile} from 'react-device-detect';
import makeStyles from "@material-ui/core/styles/makeStyles";
import ProfileMenu from "../components/menus/ProfileMenu";
import HeaderProductos from "../views/Productos/HeaderProductos";
import LandingProductos from "../views/Productos/LandingProductos";
import NavBar from "../views/NavBar/NavBar";


const useStyle = makeStyles((theme) => ({
    modal: {
        position: 'absolute',
        width: '80vw',
        height: '80vh',
        overflowY: 'auto',
        backgroundColor: '#f3f4f8',
        border: '2px solid #000',
        boxShadow: '0 5px 40px 0 rgba(70, 70, 70, 0.15)',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',

    },
    titulo: {
        fontSize: '24px',
        color: '#452d7b',
        fontFamily: 'Ubuntu',
        fontWeight: 'bold',
        textAlign: 'center',
    },

    subtitulo: {
        fontSize: '24px',
        color: '#973379',
        fontFamily: 'Ubuntu',
        fontWeight: 'bold',
    },
    texto: {
        fontSize: '14px',
        color: '#8a8597',
        fontFamily: 'Nunito',
        textAlign: ' justify',
        letterSpacing: 0,
    },


}))

function Productos() {
    const styles = useStyle();
    const [modal, setModal] = useState(false);
    const abrirCerrarModal = () => {
        setModal(!modal);
    }

    const body = (
        <div className={styles.modal}>
            <ProfileMenu/>
        </div>
    )

    if (!isMobile) {
        return (


            <React.Fragment>
                <NavBar/>
                <div style={{backgroundColor: '#f5f6f8'}}>
                    <div style={{
                        margin: 0,
                        width: '100%',
                        display: 'flex',
                        flexDirection: "row",
                        justifyContent: 'center',
                        backgroundColor: '#f5f6f8'
                    }}>
                        <HeaderProductos/>
                    </div>
                    <div><LandingProductos/></div>

                    <div><Contacto2/></div>
                </div>

            </React.Fragment>
        )
    }

}

export default withRoot(Productos);
