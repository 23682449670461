import React, {useRef} from 'react';
import './BoxDialog.css';
import useClickOutside from "../../modules/utils/useClickOutside";

const BoxDialog = (props) => {
    const {withArrow=true,display , children, setDisplay, arrowPosition="right", color="white"} = props;
    const ref = useRef();
    useClickOutside(ref, () => setDisplay(false));
    return (
        <div className={`boxDialog__Container boxDialog--${color}`} ref={ref}>
            {withArrow && <div className={`boxDialog__triangle boxDialog__triangle--${arrowPosition} boxDialog__triangle--${color}`}></div>}
            {children}
        </div>
    );
};

export default BoxDialog;