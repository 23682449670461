import React from 'react';
import {CustomAgreementModel} from "./CustomAgreementModel";
import {MantineInputField} from "../../../../../../components/FormFields/MantineInputField";
import {Form, Formik} from "formik";
import {MantineSelectField} from "../../../../../../components/FormFields/MantineSelectField";
import {Button} from "../../../../../../components/Button";
import MantineNumberField from "../../../../../../components/FormFields/MantineNumberField/MantineNumberField";
import MantineTextArea from "../../../../../../components/FormFields/MantineTextArea/MantineTextArea";
import '../CustomAgreement.css';

const CustomAgreementForm = () => {
    const {formField} = CustomAgreementModel();
    const {
        cantidadEmisiones,
        cantidadMeses,
        motivo,
    } = formField;

    return (
        <div>
            <MantineNumberField
                name={cantidadEmisiones.name}
                label={cantidadEmisiones.label}
                placeholder={"100"}
                rightSection={null}
                notFormat
                withAsterisk
                styles={{wrapper: {width: "30%"}}}
            />
            <MantineNumberField
                name={cantidadMeses.name}
                label={cantidadMeses.label}
                placeholder={"3"}
                rightSection={null}
                notFormat
                withAsterisk
                styles={{wrapper: {width: "30%"}}}
            />
            <MantineTextArea
                name={motivo.name}
                label={motivo.label}
                placeholder={"Añadir motivo para el acuerdo..."}
                withAsterisk
                minRows={3}
                maxRows={5}
            />
        </div>
    );
}

export default CustomAgreementForm;
