import React, {useContext} from 'react';
import MasterContextProvider from './MasterContextProvider';
import MasterContext from "./MasterContext";
import ContextLoading from "../../modules/components/ContextLoading";

/**
 * Inserts UserContextProvider in the react tree
 * before adding the given component.
 *
 * @param {Object} Component The component that needs the context provider.
 * @returns {Object} The component to render wrapped with the provider.
 */
const withMasterContextProvider = (Component) => (props) => (
    <MasterContextProvider setLoading={props.setLoading}>
        <Component {...props} />
    </MasterContextProvider>
);

/**
 * Renders the given component when the user context is ready.
 *
 * @param {Object} Component The component to render.
 * @param {Object} Component The rendered component.
 * @returns {Object} The component to render or the LoadingPage component.
 */
const withMasterContextReady = (Component) => (props) => {

    const {isReady} = useContext(MasterContext);
    return isReady ? <Component {...props} /> :
        <ContextLoading/>
};


export {
    withMasterContextProvider,
    withMasterContextReady
};