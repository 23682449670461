import React, {useContext, useEffect} from 'react';
import './SidebarItem.css';
import {ReactComponent as ArrowDownIcon} from '../../assets/icons/ic_arrow-down.svg';
import {SidebarChildItem} from "../SidebarChildItem";
import UserContext from "../../context/UserContext";
import {Tooltip} from '@mantine/core';

function SidebarItem(props) {
    const {id, icon, name, action, children, menuOpened, setMenuOpened} = props;
    const {sidebarOpen, setSidebarOpen} = useContext(UserContext);

    const handleClick = () => {
        if (children) {
            const newMenuOpened = menuOpened === id ? null : id;

            if (!sidebarOpen) {
                setSidebarOpen(true);
                setTimeout(() => {
                    setMenuOpened(newMenuOpened);
                }, 1000); //.layout__sidebar__container transition time
            } else {
                if (menuOpened === id) {
                    setMenuOpened(null);
                } else {
                    setMenuOpened(newMenuOpened);
                }
            }
        } else {
            action()
        }
    }

    useEffect(() => {
        if (!sidebarOpen)
            setMenuOpened(null)
    }, [sidebarOpen]);

    return (
        <>
            <div className={`sidebar__item__container sidebar__item__container--${sidebarOpen}`} onClick={handleClick}>
                <Tooltip label={name} position="top-start" withArrow
                         styles={{tooltip: {display: sidebarOpen ? "none" : "block"}}}>
                    <div className="sidebar__item__container--left">
                        {icon}
                        <span className={`sidebar__item__text sidebar__item__text--${sidebarOpen}`}>{name}</span>
                    </div>
                </Tooltip>
                {children && <ArrowDownIcon className="sidebar__item__button"/>}
            </div>
            {menuOpened === id && sidebarOpen &&
                <div className="sidebar__subitems__container">
                    {children.map((child) => <SidebarChildItem name={child.name}
                                                               action={child.action}
                                                               newIndicator={child.newIndicator}/>)}
                </div>
            }
        </>
    );
}

export default SidebarItem;