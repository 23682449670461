const add_years = (dt) => {
    let date = new Date(dt.setYear(dt.getFullYear() + 1));
    const offset = date.getTimezoneOffset()
    date = new Date(date.getTime() - (offset * 60 * 1000))
    date = date.toISOString().split('T')[0]
    return date
}

const add_6months = (dt) => {
    let date = new Date(dt.setMonth(dt.getMonth() + 6));
    const offset = date.getTimezoneOffset()
    date = new Date(date.getTime() - (offset * 60 * 1000))
    date = date.toISOString().split('T')[0]
    return date
}

export {add_years, add_6months};