import React, {useContext} from 'react';
import CotizarContextProvider from './CotizarContextProvider';
import CotizarContext from "./CotizarContext";
import ContextLoading from "../../modules/components/ContextLoading";

/**
 * Inserts UserContextProvider in the react tree
 * before adding the given component.
 *
 * @param {Object} Component The component that needs the context provider.
 * @returns {Object} The component to render wrapped with the provider.
 */
const withCotizarContextProvider = (Component) => (props) => (
    <CotizarContextProvider setLoading={props.setLoading}>
        <Component {...props} />
    </CotizarContextProvider>
);

/**
 * Renders the given component when the user context is ready.
 *
 * @param {Object} Component The component to render.
 * @param {Object} Component The rendered component.
 * @returns {Object} The component to render or the LoadingPage component.
 */
const withCotizarContextReady = (Component) => (props) => {

    const {isReady} = useContext(CotizarContext);
    return isReady ? <Component {...props} /> :
        <ContextLoading/>
};


export {
    withCotizarContextProvider,
    withCotizarContextReady
};