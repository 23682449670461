import axios from "axios";
import { Form, Formik } from "formik";
import { useContext, useEffect, useMemo, useState } from "react";
import { Button } from "../../../components/Button";
import AuthContext from "../../../context/AuthContext";
import { CustomStepper } from "../../../layouts/CustomStepper";
import DatosPersonalesForm from "./DatosPersonalesForm";
import DocumentacionForm from "./DocumentacionForm";
import "./LeadCatcherForm.css";
import InitialValues from "./model/InitialValues";
import { baseSchema, noTieneCodigoSchema, documentosSchema, tieneCodigoSchema } from "./model/ValidationSchema";
import { renderActiveStep } from "../../../modules/utils/renderActiveStep";
import UserContext from "../../../context/UserContext";
import Model from "./model/Model";
import getDocuments from "../../MisAseguradoras/MisAseguradorasCompanies/utils/getDocuments";
import CotizarContext from "../../../context/CotizarContext";
import { getLocalidadOptionsByZipCode } from "../../Cotizacion/utils/getLocalidadOptionsByZipCode";
import { provinciasList } from "../../../constants/auxPersonalData";
import MasterContext from "../../../context/MasterContext/MasterContext";

function LeadCatcherForm(props) {
    const { setOpenCheckOut, setFormLinks } = props;
    const { authToken } = useContext(AuthContext);
    const { idPolkista } = useContext(UserContext);
    const { setAltaCompaniaTickets, altaCompaniaTickets } = useContext(MasterContext);
    const { companiesDict } = useContext(CotizarContext);
    const { formField: { files } } = Model;

    const [activeStep, setActiveStep] = useState(0);
    const [hasCodes, setHasCodes] = useState(true);
    const actualValidationSchema = useMemo(() => {
        return hasCodes ? baseSchema.clone().concat(tieneCodigoSchema) : baseSchema.clone().concat(noTieneCodigoSchema);
    }, [hasCodes]);
    const initialFormSteps = useMemo(() => [
        {
            name: "Personal",
            form: <DatosPersonalesForm hasCodes={hasCodes} setHasCodes={setHasCodes} />,
            validationSchema: actualValidationSchema,
        },
    ], [hasCodes]);

    const [formSteps, setFormSteps] = useState(initialFormSteps);
    const [initialValues, setInitialValues] = useState({ ...InitialValues(), });

    useEffect(() => {
        const getInitialDocuments = async () => {
            const res = await getDocuments(idPolkista, authToken);
            setInitialValues({ ...initialValues, [files.name]: res });
        }

        getInitialDocuments();
    }, []);

    useEffect(() => {
        const updateSteps = () => {
            if (!hasCodes && formSteps.length < 2) {
                setFormSteps([
                    ...initialFormSteps,
                    {
                        name: "Documentos",
                        form: <DocumentacionForm />,
                        validationSchema: documentosSchema,
                    },
                ]);
            } else if (hasCodes && formSteps.length > 1) {
                setFormSteps(initialFormSteps);
            }
        };

        updateSteps();
    }, [hasCodes, initialFormSteps, formSteps.length]);

    const getFormLinks = async (company) => {
        try {
            const res = await axios.get(process.env.REACT_APP_BACK_SERVER + `/get_post_form_links_aseguradoras?lista_companias=[${company}]`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                }
            });
            return res.data[0].links
        } catch (error) {
            return error
        }
    }

    const handleSubmit = async (values, { setSubmitting }) => {
        if (activeStep < formSteps.length - 1) {
            setActiveStep(activeStep + 1);
        } else {
            setSubmitting(true);

            const locations = await getLocalidadOptionsByZipCode(values.codigo_postal)
            const selectedLocation = locations.find(location => location.value === parseInt(values.localidad));
            const body = {
                ...values,
                id_polkista: idPolkista,
                id_aseguradora: companiesDict[values.compania].id,
                documentos: values.files.filter(item => !item.hasOwnProperty('link')),
                documentos_links: values.files.filter(item => item.hasOwnProperty('link')),
                localidad: selectedLocation.label.replace(/\(.*\)\s/, ''),
                provincia: provinciasList.find(provincia => provincia.value === parseInt(values.provincia)).label,
            };
            delete body.files;
            try {
                const response = await axios.post(process.env.REACT_APP_BACK_SERVER + '/newvinculacion_de_codigos', body,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${authToken}`,
                        }
                    });
                const formLinks = await getFormLinks(companiesDict[values.compania].id);
                setFormLinks(formLinks);
                setOpenCheckOut(200);
                setAltaCompaniaTickets([ ...altaCompaniaTickets, {[values.compania]: "En curso"} ]);
            } catch (error) {
                console.error('Error al enviar el formulario:', error);
                setOpenCheckOut(500);
            } finally {
                setSubmitting(false);
            }
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={formSteps[activeStep].validationSchema}
            enableReinitialize={true}
        >
            {({ isSubmitting }) => (
                <Form>
                    <div className="LCForm__stepper--container">
                        <CustomStepper color="tertiary" labels={formSteps.map(step => step.name)} activeStep={activeStep} />
                    </div>
                    {renderActiveStep(formSteps, activeStep)}

                    <div className="formRenderer__form__buttons">
                        {activeStep !== 0 &&
                            <Button
                                type="button"
                                variant="filled"
                                color="tertiary--pinkBack"
                                marginX="sm"
                                enabled={!isSubmitting}
                                onClick={() => setActiveStep(activeStep - 1)}
                            >
                                Atras
                            </Button>
                        }
                        <Button
                            type="submit"
                            variant="filled"
                            color="tertiary--pinkBack"
                            marginX="sm"
                            enabled={!isSubmitting}
                            loading={isSubmitting}
                        >
                            {activeStep === formSteps.length - 1 ? "Solicitar" : "Siguiente"}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default LeadCatcherForm;