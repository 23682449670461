import {
    Card
} from "@mantine/core";
import './Cards.css';
import {useContext, useState} from "react";
import UserContext from "../../../context/UserContext";
import Axios from "axios";
import FileDownload from "js-file-download";
import {ReactComponent as DownloadIcon} from "../../../assets/icons/ic_download.svg";


function Cards(props) {
    const {imgSource, description, zipUrl} = props;
    const {
        userMail,
        userName,
        userLastName,
        userPicture,
        userMatricula,
        userCargo,
        userProvince,
        userPhone
    } = useContext(UserContext);
    const [loading, setLoading] = useState(false);

    function tarjetapersonal() {

        let mail = userMail;
        let telefono = userPhone;
        let ubicacion = `${userProvince}, Argentina`;
        let nombre = userName + " " + userLastName;
        let cargo = userCargo;
        let matriculaMaster = userMatricula;

        Axios.post(process.env.REACT_APP_BACK_SERVER + `/tarjetapersonal?nombre=${nombre}&mail=${mail}&telefono=${telefono}&ubicacion=${ubicacion}&cargo=${cargo}&matriculaMaster=${matriculaMaster}`, {}, {responseType: 'blob'})
            .then((response) => {
                FileDownload(response.data, 'tarjeta.png');
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function pieMail() {
        let mail = userMail;
        let telefono = userPhone;
        let ubicacion = `${userProvince}, Argentina`;
        let nombre = userName + " " + userLastName;
        let cargo = userCargo;
        let matriculaMaster = userMatricula;
        let foto = userPicture;

        Axios.post(process.env.REACT_APP_BACK_SERVER + `/piedemail?nombre=${nombre}&mail=${mail}&telefono=${telefono}&ubicacion=${ubicacion}&image=${foto}&cargo=${cargo}&matriculaMaster=${matriculaMaster}`, {}, {responseType: 'blob'})
            .then((response) => {
                FileDownload(response.data, 'piemail.png');
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <>
            <div className="cardContainer">
                <Card shadow="2px 2px 5px rgba(0, 0, 0, 0.25)" radius="16px" sx={{
                    width: "280px",

                    '@media (min-width: 1201px) and (max-width: 1560px)': {
                        width: "196px",
                    },

                    '@media (min-width: 1025px) and (max-width: 1200px)': {
                        width: "156px",
                    },

                    '@media (min-width: 769px) and (max-width: 1024px)': {
                        width: "148px",
                    },

                    '@media (max-width: 480px)': {
                        width: "152px",
                    },
                }}>
                    <Card.Section style={{backgroundColor: "#C6C6C6"}}>
                        <div>
                            <a href={zipUrl ? zipUrl : imgSource} download={description} target="_blank" className="cardLink">
                            <img
                                className="cardImage"
                                src={imgSource}
                                alt={description}
                            />
                            <div className="cards__title"><p className="cards__titleText">{description}</p></div>
                                <div className="cards__downloadBackground"><DownloadIcon className="cards__downloadIcon"/></div>
                            </a>
                            </div>
                    </Card.Section>
                </Card>
            </div>
        </>
    );
}

export default Cards;