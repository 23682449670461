import {useContext, useEffect, useState} from "react";
import AuthContext from "../../../../context/AuthContext";
import axios from "axios";

const useOperaciones = (dateFilter, setLoading) => {
    const [operaciones, setOperaciones] = useState(null);
    const {authToken} = useContext(AuthContext);

    const getInfoOperaciones = () => {
        let url = process.env.REACT_APP_BACK_SERVER + `/newgetInfoAllOperaciones?anio=${dateFilter.anio}&mes=${dateFilter.mes}`;
        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            }
        }).then((resp) => {
            return resp.data;
        }).catch((err) => {
            console.log(err)
        });
    }

    useEffect(() => {
        setLoading(true);
        getInfoOperaciones()
            .then(data => {
                setOperaciones(data);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            });
    }, [dateFilter]);

    return operaciones;
};

export default useOperaciones;