import "./InteractiveBar.css"
import React from "react";

function InteractiveBar(props) {
    const {
        title,
        body,
        footer,
        extra,
        handlerClick,
        tiny
    } = props;

    return (
        <div className={tiny ? "IBar__tinyBarCenterContainer" : "IBar__barCenterContainer"}>
            <button className="IBar__contentContainer" color="black" onClick={() => handlerClick()}>
                <p className={tiny ? "IBar__tinyBarTitle" : "IBar__barTitle"}>{title}</p>
                <p className={tiny ? "IBar__tinyBarBody" : "IBar__barBody"}>{body}</p>
                <p className={tiny ? "IBar__tinyBarFooter" : "IBar__barFooter"}>{footer}</p>
            </button>
            <div className="IBar__attributesContainer">
                {extra}
            </div>
        </div>
    )
}

export default InteractiveBar;
