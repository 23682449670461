import React, {useContext, useEffect, useState} from 'react';
import './Academy.css';
import {BasicCard} from "../../components/AcademyCard";
import axios from "axios";
import Tabs from "../../components/Tabs/Tabs";
import {Loading} from "../../components/Loading";
import {useHistory} from "react-router-dom";
import {gql, useMutation} from "@apollo/client";
import userContext from "../../context/UserContext";
import AuthContext from "../../context/AuthContext";
import {ErrorModal} from "../../layouts/ErrorModal";
import {useIsMount} from "../../modules/components/useIsMount";

const INSCRIPCION = gql`
    mutation MyMutation($id_curso: Int, $id_polkista: String) {
        insert_Cursos_Polkistas_x_Cursos(objects: {Estado: "En Curso", id_curso: $id_curso, id_polkista: $id_polkista}, on_conflict: {constraint: Polkistas_x_Cursos_pkey, update_columns:[]}) {
            affected_rows
        }
    }`;

/**
 * Sort the courses by the order specified in ordenEspecifico
 * @param _infoCursos
 * @returns {(string|string)[]}
 */
const sortCourses = (_infoCursos) => {
    const ordenEspecifico = [
        "AUTOMOTOR",
        "HOGAR",
        "MICROSEGUROS",
        "PLATAFORMA",
        "MATERIAL COMPLEMENTARIO",
    ];
    const keysInfoCursos = Object.keys(_infoCursos);

    // Filter categories that exist in both ordenEspecifico and infoCursos
    const categoriasExistentesEnAmbos = ordenEspecifico.filter((categoria) =>
        keysInfoCursos.includes(categoria)
    );
    const categoriasExtrasEnInfoCursos = keysInfoCursos.filter(
        (categoria) => !ordenEspecifico.includes(categoria)
    );

    // Sort the categories, first the ones that exist in both ordenEspecifico and infoCursos, then the ones that only exist in infoCursos
    return ["TODOS", ...categoriasExistentesEnAmbos, ...categoriasExtrasEnInfoCursos];
}

function Academy() {
    const [infoCursos, setInfoCursos] = useState(null);
    const [sortedCategories, setSortedCategories] = useState(null);
    const history = useHistory();
    const {authToken} = useContext(AuthContext)
    const {isMobile, userIdAuth, userName, userLastName} = useContext(userContext);
    const [inscribirPolkista] = useMutation(INSCRIPCION);
    const userFullName = userName + " " + userLastName;
    const [error, setError] = useState(null)
    const isMount = useIsMount();

    async function getCursos() {
        let url = process.env.REACT_APP_BACK_SERVER + '/getDatosCursos';
        axios.post(url, [], {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        }).then((resp) => {
            //Courses name fix
            const newObjeto = {};
            for (const key in resp.data) {
                if (Object.prototype.hasOwnProperty.call(resp.data, key)) {
                    let nuevaKey = key.trim().replace(/"/g, '');
                    newObjeto[nuevaKey] = resp.data[key];
                }
            }

            setSortedCategories(sortCourses(newObjeto))
            setInfoCursos(newObjeto);
        });
    }


    function buttonClick(curso) {
        inscribirPolkista({
            variables: {
                id_curso: curso.id_Curso,
                id_polkista: userIdAuth
            }
        }).then(() => {
            history.push({
                pathname: 'academia/cursos/' + String(curso.id_Curso).padStart(2, '0'),
                state: {
                    curso
                }
            })
        })
    }

    useEffect(() => {
        if (isMount) {
            getCursos();
        }
    }, []);

    if (isMobile) {
        return (
            <div className="Academy__mobileText">
                <p>¡Explorá nuestros <span className="Academy__headerText--bold">{" "} cursos!</span>
                    <p className="Academy__mobileText--clarification">
                        Disponible solo en versión de escritorio.
                    </p>
                </p>
            </div>
        )
    }

    return (
        <div className="Academy__wrapper">
            <div className="Academy__container">
                <div className="Academy__header">
                    <p className="Academy__headerText">
                        ¡Explorá nuestros
                        <span className="Academy__headerText Academy__headerText--bold">{" "} cursos!</span>
                    </p>
                </div>
                {!infoCursos &&
                <div className="Academy__contentLoading">
                    <Loading/>
                </div>
                }
                {infoCursos && sortedCategories &&
                <Tabs
                    tabs={sortedCategories}
                    ovalTab
                    centered
                    color="primary"
                    tabContent={
                        sortedCategories.map((tab) => (
                            <div className="Academy__cardsContainer">
                                {tab === "TODOS"
                                    ? Object.values(infoCursos).flat().map((curso) => (
                                        <BasicCard setError={setError} image={curso.Logo}
                                                   imageAlt={curso.Titulo}
                                                   title={curso.Titulo}
                                                   description={curso.Descripcion}
                                                   infoItems={{
                                                       duration: curso.Duracion,
                                                       level: curso.Dificultad,
                                                       status: curso.Estado,
                                                       platform: curso.Plataforma,
                                                       userName: userFullName,
                                                       title: curso.Titulo,
                                                       module: curso.Modulo,
                                                       action: curso.Accion,

                                                   }}
                                                   buttonText="Comenzar curso" buttonSize="lg"
                                                   action={() => buttonClick(curso)}
                                        />))
                                    :
                                    infoCursos[tab].map((curso) => {
                                            return (
                                                <BasicCard setError={setError} image={curso.Logo}
                                                           imageAlt={curso.Titulo}
                                                           title={curso.Titulo}
                                                           description={curso.Descripcion}
                                                           infoItems={{
                                                               duration: curso.Duracion,
                                                               level: curso.Dificultad,
                                                               status: curso.Estado,
                                                               platform: curso.Plataforma,
                                                               userName: userFullName,
                                                               title: curso.Titulo,
                                                               module: curso.Modulo,
                                                               action: curso.Accion,
                                                           }}
                                                           buttonText="Comenzar curso"
                                                           buttonSize="lg" action={() => buttonClick(curso)}
                                                />
                                            )
                                        }
                                    )}
                            </div>
                        ))
                    }/>
                }
            </div>

            {error && <ErrorModal closeAction={() => setError(null)} title={error.title}
                                  typeError={error.typeError}
                                  suggestedActions={error.suggestedActions}
                                  detailedError={error.detailedError}/>}
        </div>
    )
        ;
}

export default Academy;