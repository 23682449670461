import React, {createRef} from 'react';
import {Grid} from "@mantine/core";
import {useFormikContext} from "formik";
import {ClienteModel} from "./ClienteModel";
import PhoneInput from "react-phone-number-input";
import {MantineSelectField} from "../../../../../../components/FormFields/MantineSelectField";
import {MantineInputField} from "../../../../../../components/FormFields/MantineInputField";
import {MantineDateField} from "../../../../../../components/FormFields/MantineDateField";
import {MantineInputRefField} from "../../../../../../components/FormFields/MantineInputRefField";
import {CuitGenerator} from "../../../../../../modules/utils/cuitGenerator";
import MantineDependantField from "../../../../../../components/FormFields/MantineDependantField/MantineDependantField";
import {ZipCodeFieldPackage} from "../../../../../Cotizacion/ZipCodeFieldPackage";
import {useCurrentQuotationContext} from "../../../../../../context/CurrentQuotationContext/CurrentQuotationContext";

export default function ClienteForm() {
    const {formField} = ClienteModel();
    const {
        dni,
        cuit,
        nombre,
        apellido,
        fechaNacimiento,
        sexo,
        calle, numero, opcional,
        codigosLocalidad,
        codigoPostal,
        idProvincia,
        telefono,
        email,
    } = formField;
    const selectedIndex = 0;
    const {values, setFieldValue} = useFormikContext();
    const ref = createRef();
    const {localidadOptions, setLocalidadOptions, setProvinciasOptions, provinciasOptions} = useCurrentQuotationContext();

    const opcionesSexo = [
        {value: '1', label: 'Femenino'},
        {value: '2', label: 'Masculino'}
    ];

    const updateLocalidadOptions = (newLocalidades) => {
        setLocalidadOptions((prevState) => {
            const newState =  [...prevState ];
            newState[selectedIndex] = newLocalidades;
            return newState;
        });
    };

    const calculateCuit = () => {
        if (values.clientes[selectedIndex].dni) {
            if ((values.clientes[selectedIndex].dni).length === 8 && values.clientes[selectedIndex].sexo !== '') {
                return CuitGenerator(values.clientes[selectedIndex].dni, values.clientes[selectedIndex].sexo)
            }
        }
    }

    return (
        <React.Fragment>
            <div className="microseguros__clientes__layout">
                {/*FORMULARIO DE CLIENTE*/}
                <div className="microseguros__clientes__form__container">
                    {values.clientes.map((client, index) => {
                        if (index === selectedIndex) {
                            return (
                                <Grid justify="center" align="center" gutterXs="xs">
                                    <Grid.Col xs={12} sm={4}>
                                        <MantineInputField
                                            name={`clientes[${selectedIndex}].${nombre.name}`}
                                            label={nombre.label}
                                            withAsterisk
                                        />
                                    </Grid.Col>
                                    <Grid.Col xs={12} sm={4}>
                                        <MantineInputField
                                            name={`clientes[${selectedIndex}].${apellido.name}`}
                                            label={apellido.label}
                                            withAsterisk
                                        />
                                    </Grid.Col>
                                    <Grid.Col xs={12} sm={4}>
                                        <MantineSelectField
                                            name={`clientes[${selectedIndex}].${sexo.name}`}
                                            label={sexo.label}
                                            data={opcionesSexo}
                                            withAsterisk
                                        />
                                    </Grid.Col>
                                    <Grid.Col xs={12} sm={6}>
                                        <MantineInputField
                                            name={`clientes[${selectedIndex}].${dni.name}`}
                                            label={dni.label}
                                            withAsterisk
                                        />
                                    </Grid.Col>
                                    <Grid.Col xs={12} sm={6}>
                                        <MantineDependantField
                                            test={cuit}
                                            dependantFieldName={`clientes[${selectedIndex}].${cuit.name}`}
                                            dependantFieldLabel={cuit.label}
                                            dependencies={[values.clientes[selectedIndex].dni, values.clientes[selectedIndex].sexo]}
                                            calculateValueFunction={calculateCuit}
                                            formatDisplay={(v) => v}
                                            withAsterisk
                                        />
                                    </Grid.Col>
                                    <Grid.Col xs={12} sm={12}>
                                        <MantineDateField
                                            name={`clientes[${selectedIndex}].${fechaNacimiento.name}`}
                                            label={fechaNacimiento.label}
                                            size="xs"
                                            withAsterisk
                                        />
                                    </Grid.Col>
                                    <Grid.Col xs={12} sm={4}>
                                        <MantineInputField
                                            name={`clientes[${selectedIndex}].${calle.name}`}
                                            label={calle.label}
                                            withAsterisk
                                        />
                                    </Grid.Col>
                                    <Grid.Col xs={12} sm={4}>
                                        <MantineInputField
                                            name={`clientes[${selectedIndex}].${numero.name}`}
                                            label={numero.label}
                                            withAsterisk
                                        />
                                    </Grid.Col>
                                    <Grid.Col xs={12} sm={4}>
                                        <MantineInputField
                                            name={`clientes[${selectedIndex}].${opcional.name}`}
                                            label={opcional.label}
                                        />
                                    </Grid.Col>

                                    <>
                                        <ZipCodeFieldPackage
                                            localidadField={{
                                                name: `clientes[${selectedIndex}].${codigosLocalidad.name}`,
                                                label: codigosLocalidad.label
                                            }}
                                            localidadSize={4}
                                            codigoPostalField={{
                                                name: `clientes[${selectedIndex}].${codigoPostal.name}`,
                                                label: codigoPostal.label
                                            }}
                                            codigoPostalSize={4}
                                            provinciaField={{
                                                name: `clientes[${selectedIndex}].${idProvincia.name}`,
                                                label: idProvincia.label
                                            }}
                                            provinciaSize={4}
                                            localidadOptions={localidadOptions[selectedIndex] || []}
                                            setLocalidadOptions={updateLocalidadOptions}
                                            provinciasOptions={provinciasOptions}
                                            setProvinciaOptions={setProvinciasOptions}
                                        />
                                    </>

                                    <Grid.Col xs={12} sm={6}>
                                        <MantineInputField
                                            name={`clientes[${selectedIndex}].${email.name}`}
                                            label={email.label}
                                            withAsterisk
                                        />
                                    </Grid.Col>

                                    <Grid.Col xs={12} sm={6}>
                                        <PhoneInput
                                            defaultCountry="AR"
                                            ref={ref}
                                            inputComponent={MantineInputRefField}
                                            name={`clientes[${selectedIndex}].${telefono.name}`}
                                            label={telefono.label}
                                            value={values['clientes'][selectedIndex][telefono.name]}
                                            onChange={(e) => {
                                                /** @namespace e.length **/
                                                if (e && e.length > 12) {
                                                    setFieldValue(`clientes[${selectedIndex}].${telefono.name}`, e)
                                                }
                                            }}
                                            withAsterisk
                                        />
                                    </Grid.Col>
                                </Grid>
                            )
                        }
                    })}
                </div>
            </div>
        </React.Fragment>
    );
}