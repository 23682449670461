import React from 'react';
import './TextLink.css';

function TextLink(props) {
    const {children, action, size = "lg", color="primary", refElement} = props;
    return (
        <p className={`textLink__container textLink__container--${size} textLink__color--${color}`} onClick={action} ref={refElement}>
            {children}
        </p>
    );
}

export default TextLink;