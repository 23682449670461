import React, {useContext} from 'react';
import './DocumentacionCardMobile.css';
import {ReactComponent as DownloadDocIcon} from "../../assets/icons/ic_download_doc.svg";
import CotizarContext from "../../context/CotizarContext";
import {Menu} from "@mantine/core";
import {Loading} from "../Loading";
import AuthContext from "../../context/AuthContext";
import {Button} from "../Button";
import copyToClipboard from "../../modules/utils/copyToClipboard";

function DocumentacionCardMobile(props) {
    const {companiesDict} = useContext(CotizarContext);
    const {isMaster} = useContext(AuthContext);
    const {data, downloadDocument, isDownloading, index} = props;
    const {
        aseguradora,
        producto,
        numeroReferencia,
        clientes,
        fecha,
        detalle,
        documentacion,
        identificadores,
        estado,
        estado_pago,
        link_pago
    } = data;
    const cliente = clientes[0];
    const status = companiesDict[aseguradora].emissionStatus[estado];
    const STATUS_MAPPINGS = {
        "rejected": {style: "documentacionCard__text--cancel", text: "Cancelada"},
        "pending": {style: "documentacionCard__text--pending", text: "Pendiente"},
        "approved": {style: "documentacionCard__text--success", text: "Emitida"}
    };
    const PAY_STATUS_MAPPINGS = {
        "SIN_ENVIAR": {style: "documentacionCard__text--pending", text: "En proceso"},
        "ENVIADO": {style: "documentacionCard__text--pending", text: "En proceso"},
        "PAGADO": {style: "documentacionCard__text--success", text: "Pagado"}
    };
    const {style: statusStyle, text: statusText} = STATUS_MAPPINGS[status] || {};
    let realDocumentation = documentacion;
    let realProducto = producto;

    if (aseguradora === "TerraWind") {
        if (!realDocumentation) realDocumentation = [];
        data?.pasajeros.forEach((pasajero) => {
            if (pasajero.documentacion) {
                realDocumentation = realDocumentation.concat(pasajero.documentacion);
            }
        });
        realProducto = "ASISTENCIA AL VIAJERO";
    }

    const showRetencionButton = isMaster && statusText === "Emitida" && producto === "AUTOMOTOR";

    return (
        <div className="documentacionCardMobile__container">
            {isDownloading === index
                ? <Loading/>
                : <>
                    <div className="documentacionCardMobile__block">
                        <img className="documentacionCardMobile__img" src={companiesDict[aseguradora]['logo']}
                             alt="aseguradora"/>
                        <p className="documentacionCardMobile__title--highlighted">{realProducto}</p>
                    </div>
                    <div className="documentacionCardMobile__block">
                        <div className="documentacionCardMobile__row">
                            {numeroReferencia &&
                                <p className="documentacionCardMobile__text">
                                    <span>Referencia:</span> {numeroReferencia}
                                </p>
                            }
                            <p className="documentacionCardMobile__text"><span>Fecha: </span>{fecha}</p>
                            <Menu shadow="md" position="bottom-end" withArrow>
                                <Menu.Target>
                                    <DownloadDocIcon
                                        className={`documentacionCardMobile__icon ${estado === "Cancel" ? "documentacionCard__icon--disabled" : ""}`}/>
                                </Menu.Target>

                                {estado !== "Cancel" && <Menu.Dropdown>
                                    <Menu.Label>Descargar documento</Menu.Label>
                                    <Menu.Divider/>
                                    {realDocumentation.map((doc, idx) => (
                                        <Menu.Item key={idx}
                                                   onClick={() => {
                                                       let realIdentificadores = identificadores;
                                                       if (aseguradora === "TerraWind") {
                                                           data?.pasajeros.forEach((pasajero) => {
                                                               pasajero?.documentacion?.forEach(documento => {
                                                                   if (documento === doc) {
                                                                       realIdentificadores = pasajero['identificadores'];
                                                                   }
                                                               })
                                                           })
                                                       }
                                                       downloadDocument(index, producto, aseguradora, doc, realIdentificadores)
                                                   }}>
                                            {doc}
                                        </Menu.Item>
                                    ))}
                                </Menu.Dropdown>
                                }
                            </Menu>
                            {showRetencionButton &&
                                <Button enabled variant="filled" color="primary" size="sm" onClick={() => {
                                    window.open('https://helpdeskgrupopas.atlassian.net/servicedesk/customer/portal/3/group/40/create/67', '_blank')
                                }}>Retencion</Button>}
                            {estado_pago && estado_pago !== "ENVIADO" &&
                                <Button enabled variant="filled" color="primary" size="sm"
                                        onClick={() => copyToClipboard(link_pago)}>pago
                                </Button>
                            }
                        </div>

                        <div className="documentacionCardMobile__row">
                            <p className="documentacionCardMobile__text">
                                <span>Cliente:</span>{cliente.nombre} {cliente.apellido}</p>

                        </div>
                        {detalle.map(detail => (
                            <p className="documentacionCardMobile__text"><span>{detail.title}:</span> {detail.value}
                            </p>
                        ))}
                    </div>
                </>
            }

        </div>
    );
}

export default DocumentacionCardMobile;