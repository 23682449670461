import React, {
    useEffect,
    useState,
    useMemo, useContext, useCallback,
} from 'react';

import PropTypes from 'prop-types';
import axios from "axios";
import AuthContext from "../AuthContext";
import MasterContext from "./MasterContext";
import {gql} from "@apollo/client/core";
import {useLazyQuery} from "@apollo/client";
import SancorBlack from "../../assets/logos/sancor_black.png";
import RUSBlack from "../../assets/logos/rus_black.png";
import {nameFormatter} from "../../modules/utils/nameFormatter";
import SancorBlackIsologo from "../../assets/logos/sancor_black_isologo.png";
import RUSBlackIsologo from "../../assets/logos/rus_black_isologo.png";
import {ReactComponent as Linkedin} from '../../assets/icons/ic_linkedin.svg'
import {ReactComponent as LinkedinIcon} from '../../assets/icons/ic_linkedin--circle.svg'
import {ReactComponent as Instagram} from '../../assets/icons/ic_instagram.svg'
import {ReactComponent as InstagramIcon} from '../../assets/icons/ic_instagram--circle.svg'
import {ReactComponent as Facebook} from '../../assets/icons/ic_facebook.svg'
import {ReactComponent as FacebookIcon} from '../../assets/icons/ic_facebook--circle.svg'
import {ReactComponent as Otro} from '../../assets/icons/ic_web.svg'
import {ReactComponent as OtroIcon} from '../../assets/icons/ic_web--circle.svg'
import ZurichBlack from "../../assets/logos/zurich_black.png";
import ZurichBlackIsologo from "../../assets/logos/zurich_black_isologo.png";
import TerraWindBlack from "../../assets/logos/terrawind_black.svg";
import UserContext from '../UserContext';
import FederacionPatronalBlack from "../../assets/logos/federacion_patronal_logo.svg";
import FederacionPatronalBlackIsologo from "../../assets/logos/federacion_patronal_isologo.svg";

const GET_DESCRIPCIONES_BY_ID = gql`
    query MyQuery($_in: [Int!]) {
      CanalDigital_Descripcion(where: {id: {_in: $_in}}) {
        id
        texto
      }
    }`

const GET_MASTER_CD_CREDITS_BY_ID = gql`
    query GetCreditosDisponibles($_idMaster: Int) {
      CanalDigital_CreditosDisponibles(where: {id_master: {_eq: $_idMaster}}) {
        creditos_gastados
        creditos_totales
      }
    }
`


/**
 * Generates an array of options for a select component, based on the codigos_aseguradoras array of objects and a dictionary of companies.
 *
 * @param {Object[]} array - The array of objects containing data for each option.
 * @param {Object} companiesDict - A dictionary (key-value pairs) containing information about companies, where the key is the company ID, and the value is an object with at least a 'name' property.
 * @returns {Object[]} - An array of objects with the format { value: <company ID>, label: <productor code>, group: <company name> }.
 *
 * @example Output:
 * [
 *   { value: "3", label: 3592, group: "RUS" },
 *   { value: "2", label: 221209, group: "Sancor" }
 * ]
 */
function masterCodesOptionsGenerator(array, companiesDict) {
    return array.map(item => ({
        value: item.id_codigo_aseguradora.toString(),
        label: item.codigos ? Object.values(item?.codigos)[0] : null,
        group: companiesDict[item.id_aseguradora.toString()]['name']
    }));
}

const MasterContextProvider = ({
                                   children,
                               }) => {

    const {authToken, idAuth} = useContext(AuthContext)

    const defaultDescripcionesId = [1, 2, 3]
    const [masterPolkistas, setMasterPolkistas] = useState(null);
    const [infoPagesPolkistas, setInfoPagesPolkistas] = useState([])
    const [descripciones, setDescripciones] = useState(null)
    const [open, setOpen] = useState(false)
    const [codesToShareOptions, setCodesToShareOptions] = useState(null);
    const [idPolkistaMaster, setIdPolkistaMaster] = useState(null);
    const [isReady, setIsReady] = useState(false);
    const [isModifiedReady, setIsModifiedReady] = useState(false);
    const [emissionStatistics, setEmissionStatistics] = useState(null);
    const [polkistasStatistics, setPolkistasStatistics] = useState(null);
    const [generalStatistics, setGeneralStatistics] = useState(null);
    const [availableCredits, setAvailableCredits] = useState(0);
    const [altaCompaniaTickets, setAltaCompaniaTickets] = useState(null);

    const companiesDict = {
        1: {
            name: "Sancor",
            logo: SancorBlack,
            products: [
                {label: "AUTOMOTOR", value: "vehiculo"},
                {label: "MICROSEGUROS", value: "microseguros"},
                {label: "HOGAR", value: "hogar"},
                {label: "MOTOVEHÍCULO", value: "motovehiculo"}
            ],
            isologo: SancorBlackIsologo,
        },
        2: {
            name: "RUS",
            logo: RUSBlack,
            isologo: RUSBlackIsologo,
            products: [
                {label: "AUTOMOTOR", value: "vehiculo"},
                {label: "MOTOVEHÍCULO", value: "motovehiculo"}
            ]
        },
        3: {
            name: "Zurich",
            logo: ZurichBlack,
            products: [{label: "AUTOMOTOR", value: "vehiculo"}],
            isologo: ZurichBlackIsologo,
        },
        4: {
            name: 'TerraWind',
            logo: TerraWindBlack,
            products: [{label: "ASISTENCIA AL VIAJERO", value: "asistencia_viajero", key: "asistencia_viajero"}],
            razonSocial: "TerraWind",
            isologo: TerraWindBlack,
        },
        5: {
            name: 'Federacion_Patronal',
            logo: FederacionPatronalBlack,
            products: [{label: "AUTOMOTOR", value: "vehiculo"}],
            razonSocial: "Federación Patronal",
            isologo: FederacionPatronalBlackIsologo,
        },
        "Sancor": {
            id: 1,
            logo: SancorBlack,
            products: [
                {label: "AUTOMOTOR", value: "vehiculo"},
                {label: "MICROSEGUROS", value: "microseguros"},
                {label: "HOGAR", value: "hogar"},
                {label: "MOTOVEHÍCULO", value: "motovehiculo"}
            ],
            isologo: SancorBlackIsologo,
        },
        "RUS": {
            id: 2,
            logo: RUSBlack,
            isologo: RUSBlackIsologo,
            products: [
                {label: "AUTOMOTOR", value: "vehiculo"},
                {label: "MOTOVEHÍCULO", value: "motovehiculo"}
            ]
        },
        "Zurich": {
            id: 3,
            logo: ZurichBlack,
            isologo: ZurichBlackIsologo,
            products: [{label: "AUTOMOTOR", value: "vehiculo", key: "automotor"}],
        },
        "TerraWind": {
            id: 4,
            logo: TerraWindBlack,
            products: [{label: "ASISTENCIA AL VIAJERO", value: "asistencia_viajero", key: "asistencia_viajero"}],
            razonSocial: "Terrawind",
            isologo: TerraWindBlack,
        },
        "Federacion_Patronal": {
            id: 5,
            logo: FederacionPatronalBlack,
            products: [{label: "AUTOMOTOR", value: "vehiculo", key: "automotor"}],
            razonSocial: "Federación Patronal",
            isologo: FederacionPatronalBlackIsologo,
        },
    }

    const socialMediaDict = {
        "facebook": {
            id: 0,
            logo: <Facebook className="RForm__Logo"/>,
            icon: <FacebookIcon/>,
        },
        "instagram": {
            id: 1,
            logo: <Instagram className="RForm__Logo"/>,
            icon: <InstagramIcon/>,
        },
        "linkedIn": {
            id: 2,
            logo: <Linkedin className="RForm__Logo"/>,
            icon: <LinkedinIcon/>,
        },
        "otro": {
            id: 3,
            logo: <Otro className="RForm__Logo"/>,
            icon: <OtroIcon/>,
        }
    }

    const [getDescripciones] = useLazyQuery(GET_DESCRIPCIONES_BY_ID, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            let descripcionesDict = {}
            data['CanalDigital_Descripcion'].map((desc) => {
                descripcionesDict[desc['id']] = desc['texto']
            })
            setDescripciones(descripcionesDict)
            setIsReady(true);
            setIsModifiedReady(true);
        }
    })
    const [getCDMasterCredits] = useLazyQuery(GET_MASTER_CD_CREDITS_BY_ID, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            const respData = data['CanalDigital_CreditosDisponibles'][0];
            setAvailableCredits(respData['creditos_totales'] - respData['creditos_gastados']);
        }
    })

    const [modified, setModified] = useState(false);
    const handleModified = useCallback(() => {
        setIsModifiedReady(false);
        setModified(!modified)
    }, [modified]);

    const togleSideNav = useCallback(() => {
        setOpen(!open)
    }, [open]);

    useEffect(() => {
        let shouldUpdate = true;

        const init = async () => {
            let requestConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            }
            let urlRequestOne = process.env.REACT_APP_BACK_SERVER + '/getDatosMaster'
            let urlRequestTwo = process.env.REACT_APP_BACK_SERVER + '/getDatosCanalDigitalMaster'
            let urlRequestThree = process.env.REACT_APP_BACK_SERVER + '/master/getEstadisticas'
            let altaCompaniaTicketsURL = process.env.REACT_APP_BACK_SERVER + '/get_tickets'

            const requestOne = axios.get(urlRequestOne, requestConfig);
            const requestTwo = axios.get(urlRequestTwo, requestConfig);
            const requestThree = axios.get(urlRequestThree, requestConfig);
            const altaCompaniaTicketsRequest = axios.get(altaCompaniaTicketsURL, requestConfig);

            axios.all([requestOne, requestTwo, requestThree, altaCompaniaTicketsRequest])
                .then(axios.spread((...responses) => {
                    if (shouldUpdate) {
                        setOpen(open)

                        const responseOne = responses[0]["data"]
                        const responseTwo = responses[1]["data"]
                        const responseThree = responses[2]["data"]
                        const altaCompaniaTicketsResponse = responses[3]["data"]

                        let descripcionesArray = [...defaultDescripcionesId];

                        //Handle response one: master data
                        setCodesToShareOptions(
                            masterCodesOptionsGenerator(responseOne['datos_usuario']['codigos_aseguradoras'], companiesDict)
                        )
                        setIdPolkistaMaster(responseOne['datos_usuario']['idPolkista'])

                        //Add master polkista data
                        responseOne['polkistas'][responseOne['datos_usuario']['idPolkista']] = {
                            datos_polkista: {
                                Nombre: responseOne['datos_usuario']['nombre'],
                                Apellido: responseOne['datos_usuario']['apellido'],
                                id_auth: idAuth,
                            },
                            codigos_aseguradoras: responseOne['datos_usuario']['codigos_aseguradoras']
                        }
                        //Transform polkistas data
                        Object.keys(responseOne['polkistas']).forEach((polkistaId) => {
                            const polkista = responseOne['polkistas'][polkistaId]['datos_polkista']
                            const name = `${polkista.Nombre} ${polkista.Apellido}`
                            responseOne['polkistas'][polkistaId]['datos_polkista']['NombreCompleto'] = nameFormatter(name)
                        })
                        setMasterPolkistas(responseOne['polkistas'])

                        //Handle response two: canal digital data
                        getCDMasterCredits({
                            context: {
                                headers: {
                                    "X-Hasura-Role": "master"
                                }
                            }, variables: {"_idMaster": responseOne['datos_usuario']['idPolkista']}
                        })

                        if (responseTwo.length !== 0) {
                            setInfoPagesPolkistas(responseTwo)

                            responseTwo.forEach((pageInfo) => {
                                const customDescr = pageInfo['descripcion'].filter(item => !defaultDescripcionesId.includes(item));
                                descripcionesArray.push(...customDescr);
                            });
                        }

                        //Handle response three
                        setEmissionStatistics(responseThree['emisiones'])
                        setPolkistasStatistics(
                            Object.keys(responseThree['polkistas']).map((key) => {
                                const nuevoObjeto = responseThree['polkistas'][key];
                                nuevoObjeto.idAuth = key;
                                //TODO: Add the name of the polkista after integration
                                return nuevoObjeto;
                            })
                        )
                        setGeneralStatistics(responseThree['general'])

                        getDescripciones({
                            context: {
                                headers: {
                                    "X-Hasura-Role": "master"
                                }
                            }, variables: {"_in": descripcionesArray}
                        })
                        setAltaCompaniaTickets(altaCompaniaTicketsResponse)
                    }
                }))
                .catch(errors => {
                    console.log("errors", errors)
                })
        }

        init();
        return () => {
            /* This cleanup function is used to prevent updating the state
            when the component is unmounted */
            shouldUpdate = false;
        };

    }, [modified])

    const context = useMemo(() => ({
        defaultDescripcionesId,
        masterPolkistas,
        infoPagesPolkistas,
        open,
        togleSideNav,
        descripciones,
        handleModified,
        socialMediaDict,
        companiesDict,
        codesToShareOptions,
        idPolkistaMaster,
        isModifiedReady,
        emissionStatistics,
        polkistasStatistics,
        generalStatistics,
        availableCredits,
        altaCompaniaTickets,
        setAltaCompaniaTickets,
        isReady
    }), [
        defaultDescripcionesId,
        masterPolkistas,
        open,
        infoPagesPolkistas,
        togleSideNav,
        descripciones,
        handleModified,
        socialMediaDict,
        companiesDict,
        codesToShareOptions,
        idPolkistaMaster,
        isModifiedReady,
        emissionStatistics,
        polkistasStatistics,
        generalStatistics,
        availableCredits,
        altaCompaniaTickets,
        setAltaCompaniaTickets,
        isReady
    ]);
    return (
        <MasterContext.Provider value={context}>
            {children}
        </MasterContext.Provider>
    );
}
MasterContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default MasterContextProvider;