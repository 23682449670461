import * as Yup from "yup";
import {isValidPhoneNumber} from "react-phone-number-input";

const ClienteModel = () => {
    const formField = {
        clientes: { //Objeto
            name: 'clientes',
        },
        rol: {
            name: 'rol',
            label: 'Rol',
            requiredErrorMsg: 'Debe seleccionar un rol'
        },
        tipoPersona: {
            name: 'tipoPersona',
            label: 'Tipo Persona',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        dni: {
            name: 'dni',
            label: 'DNI',
            requiredErrorMsg: 'Debe especificar este campo'
        },
        cuit: {
            name: 'cuit',
            label: 'CUIT/CUIL',
            requiredErrorMsg: 'Debe especificar el CUIT/CUIL'
        },
        nombre: {
            name: 'nombre',
            label: 'Nombre',
            requiredErrorMsg: 'Debe especificar este campo'
        },
        apellido: {
            name: 'apellido',
            label: 'Apellido',
            requiredErrorMsg: 'Debe especificar este campo'
        },
        fechaNacimiento: {
            name: 'fechaNacimiento',
            label: 'Fecha de nacimiento',
            requiredErrorMsg: 'Debe especificar la fecha de nacimiento'
        },
        estadoCivil: {
            name: 'estadoCivil',
            label: 'Estado civil',
            requiredErrorMsg: 'Debe seleccionar un estado civil'
        },
        sexo: {
            name: 'sexo',
            label: 'Sexo',
            requiredErrorMsg: 'Debe seleccionar un sexo'
        },
        calle: {
            name: 'calle',
            label: 'Dirección de domicilio',
            requiredErrorMsg: 'Debe especificar una dirección de domicilio'
        },
        numero: {
            name: 'numero',
            label: 'Número de domicilio',
            requiredErrorMsg: 'Debe especificar el número de su domicilio'
        },
        opcional: {
            name: 'opcional',
            label: 'Torre / Piso / Dpto / Lote',
            requiredErrorMsg: 'Debe especificar una dirección de domicilio'
        },
        codigosLocalidad: {
            name: 'codigosLocalidad',
            label: 'Localidad',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        codigoPostal: {
            name: 'codigoPostal',
            label: "Código postal",
        },
        idProvincia: {
            name: 'idProvincia',
            label: 'Provincia',
        },
        telefono: {
            name: 'telefono',
            label: 'Celular',
            requiredErrorMsg: 'Debe especificar un celular'
        },
        email: {
            name: 'email',
            label: 'Email',
            requiredErrorMsg: 'Debe especificar un email'
        },
        situacionImpositiva: {
            name: 'situacionImpositiva',
            label: 'Situación ante el IVA',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        categoriaMonotributo: {
            name: 'categoriaMonotributo',
            label: 'Categoría de monotributo',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        actividadPrincipal: {
            name: 'actividadPrincipal',
            label: 'Actividad principal (CIIU de AFIP)',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        lugarNacimiento: {
            name: 'lugarNacimiento',
            label: 'Lugar de nacimiento',
            requiredErrorMsg: 'Debe especificar un lugar de nacimiento'
        }
    }

    const initialValues = {
        [formField.clientes.name]: [
            {
                [formField.rol.name]: '2',
                [formField.tipoPersona.name]: '0',
                [formField.dni.name]: '',
                [formField.cuit.name]: '',
                [formField.nombre.name]: '',
                [formField.apellido.name]: '',
                [formField.fechaNacimiento.name]: '',
                [formField.estadoCivil.name]: '2',
                [formField.sexo.name]: '',
                [formField.calle.name]: '',
                [formField.numero.name]: '',
                [formField.opcional.name]: '',
                [formField.codigosLocalidad.name]: '',
                [formField.telefono.name]: '',
                [formField.email.name]: '',
                [formField.situacionImpositiva.name]: '4',
                [formField.categoriaMonotributo.name]: '1',
                [formField.actividadPrincipal.name]: '10001',
                [formField.lugarNacimiento.name]: 'Cordoba'
            },
        ]
    }

    const validationSchema = Yup.object().shape({
        [formField.clientes.name]: Yup.array().of(
            Yup.object().shape({
                [formField.rol.name]: Yup.string().required(`${formField.rol.requiredErrorMsg}`),
                [formField.dni.name]: Yup.number()
                    .required(`${formField.dni.requiredErrorMsg}`)
                    .min(1000000, "El documento ingresado no es válido")
                    .max(99999999, "El documento ingresado no es válido")
                    .integer()
                    .typeError("Introduzca únicamente números"),
                [formField.cuit.name]: Yup.string()
                    .required(`${formField.cuit.requiredErrorMsg}`)
                    .matches(/^(20|23|27|30|33)([0-9]{9}|-[0-9]{8}-[0-9]{1})$/, "El CUIT ingresado no es válido"),
                [formField.nombre.name]: Yup.string()
                    .required(`${formField.nombre.requiredErrorMsg}`)
                    .matches(/^[aA-zZ\s]+$/, "Ingrese un nombre válido")
                    .max(40, "El nombre ingresado es muy largo"),
                [formField.apellido.name]: Yup.string()
                    .required(`${formField.apellido.requiredErrorMsg}`)
                    .matches(/^[aA-zZ\s]+$/, "Ingrese un apellido válido")
                    .max(40, "El valor ingresado es muy largo"),
                [formField.fechaNacimiento.name]: Yup.date()
                    .max(new Date(Date.now() - 567648000000), "El cliente debe tener al menos 18 años para contratar")
                    .required(`${formField.fechaNacimiento.requiredErrorMsg}`),
                [formField.estadoCivil.name]: Yup.string().required(`${formField.estadoCivil.requiredErrorMsg}`),
                [formField.sexo.name]: Yup.string().required(`${formField.sexo.requiredErrorMsg}`),
                [formField.calle.name]: Yup.string()
                    .required(`${formField.calle.requiredErrorMsg}`)
                    .matches(/^(?=[a-zA-Z0-9()_+=,.:\s-]*$)/, "Esta utilizando carácteres no válidos")
                    .min(4, 'Ingrese al menos 4 caracteres'),
                [formField.numero.name]: Yup.number()
                    .required(`${formField.numero.requiredErrorMsg}`)
                    .integer()
                    .typeError("Introduzca únicamente números"),
                [formField.codigosLocalidad.name]: Yup.string().required(`${formField.codigosLocalidad.requiredErrorMsg}`),
                [formField.telefono.name]: Yup.string()
                    .test('telefono', "El celular ingresado no es válido",
                        (value) => {
                            if (typeof value === "string")
                                return (isValidPhoneNumber(value))
                            else return false
                        }
                    ),
                [formField.email.name]: Yup.string()
                    .required(`${formField.email.requiredErrorMsg}`)
                    .email("Ingrese un email válido"),
                [formField.situacionImpositiva.name]: Yup.string().required(`${formField.situacionImpositiva.requiredErrorMsg}`),
                [formField.categoriaMonotributo.name]: Yup.string().required(`${formField.categoriaMonotributo.requiredErrorMsg}`),
                [formField.actividadPrincipal.name]: Yup.string().required(`${formField.actividadPrincipal.requiredErrorMsg}`),
                [formField.lugarNacimiento.name]: Yup.string()
                    .required(`${formField.lugarNacimiento.requiredErrorMsg}`)
                    .matches(/^([A-Za-z0-9 -]+)$/, "Esta utilizando carácteres no válidos"),
            })
        )
    });

    return {formField, initialValues, validationSchema}
}

export {ClienteModel};
