import React, {useContext, useState} from 'react';
import './MainDrawer.css';
import {Drawer} from "@mantine/core";
import UserContext from "../../context/UserContext";
import PolkoSecondary from "../../assets/logos/polko_secondary.png";
import {MobileSidebarItem} from "../MobileSidebarItem";

function MainDrawer() {
    const {sidebarItems, sidebarOpen, setSidebarOpen, isMobile} = useContext(UserContext);
    const [menuOpened, setMenuOpened] = useState(null);

    return (
        <Drawer
            opened={isMobile ? sidebarOpen : false}
            onClose={() => setSidebarOpen(false)}
            position="left"
            padding="xs"
            size="60%"
            classNames={{
                content: 'main__drawer__container',
                overlay: 'main__drawer__overlay',
                close: 'main__drawer__closeButton',
            }}
        >
            <div className="main__drawer__content">
                <div className="main__drawer__header">
                        <img className="main__drawer__logo" src={PolkoSecondary} alt="polko"/>
                </div>
                <div className="main__drawer__body">
                    {sidebarItems.bodyItems.map((item) => {
                        if (item.renderCondition) {
                            return (
                                <MobileSidebarItem id={item.id}
                                                   icon={item.icon}
                                                   name={item.name}
                                                   action={item.action}
                                                   menuOpened={menuOpened}
                                                   setMenuOpened={setMenuOpened}
                                                   children={item.children}/>
                            )
                        }
                    })}
                </div>
                <div className="main__drawer__footer">
                    <hr className="main__drawer__hr"/>
                    {sidebarItems.footerItems.map((item) => <MobileSidebarItem id={item.id}
                                                                         icon={item.icon}
                                                                         name={item.name}
                                                                         action={item.action}
                                                                         menuOpened={menuOpened}
                                                                         setMenuOpened={setMenuOpened}
                                                                         children={item.children}/>)}
                </div>
            </div>
        </Drawer>
    );
}

export default MainDrawer;