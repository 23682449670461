import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Grid, List, Typography, SvgIcon, ListItem} from '@material-ui/core';
import {FieldArray, useFormikContext} from 'formik'
import Divider from '@material-ui/core/Divider';
import Loading from "../../../../components/Loading"
import PopOver from "../../../../components/PopOver"
import {currencyFormat} from "../../../../utils/currencyFormat";
import useStyles from "../styles";
import Flight from '@material-ui/icons/Flight';
import LocalHospital from '@material-ui/icons/LocalHospital';
import CallIcon from '@material-ui/icons/Call';
import CotizarContext from "../../../../../context/CotizarContext";

export default function CoberturasForm(props) {
    const classes = useStyles();
    const {cuotaMensual} = props;
    const {values} = useFormikContext();
    const {coberturasInfo} = useContext(CotizarContext);
    const [dictCoberturas, setDictCoberturas] = useState(null);
    const [coberturasSinCostoState, setCoberturasSinCostoState] = useState([
        {numeroCobertura: "44", icon: Flight},
        {numeroCobertura: "45", icon: LocalHospital},
        {numeroCobertura: "50", icon: CallIcon}
    ]);
    const coberturasAdicionalesDict = ['48', '36', '37', '26']


    useEffect(() => {
        let dict = [];

        coberturasAdicionalesDict.map((item) => {
            const index = values.coberturas.map(c => c.numeroCobertura).indexOf(item);
            if (index !== -1) {
                dict.push({
                    descripcion: coberturasInfo[item].descripcion,
                    descripcionCompleta: coberturasInfo[item].descripcionCompleta,
                    sumaAsegurada: values.coberturas[index].sumaAsegurada,
                    numeroCobertura: coberturasInfo[item].numeroCobertura
                });
            }
        });

        let items = [...coberturasSinCostoState];
        for (let i = 0; i < coberturasSinCostoState.length; i++) {
            let item = {...items[i]};
            item.descripcion = coberturasInfo[item.numeroCobertura].descripcion;
            items[i] = item;
        }

        setCoberturasSinCostoState(items);
        dict.sort((a, b) => parseInt(a.numeroCobertura) - parseInt(b.numeroCobertura));
        setDictCoberturas(dict)
    }, []);


    if (!dictCoberturas) return <Loading/>;
    return (
        <React.Fragment>
            <Grid container direction='column'>
                <Grid item container spacing={3} direction='column' className={classes.coberturasTable}>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        margin: "16px 16px 0px 16px",
                    }}>
                        <Typography className={classes.tableHeader}>Cobertura</Typography>
                        <Typography className={classes.tableHeader}>Suma Asegurada</Typography>
                    </div>
                    <FieldArray name='coberturas'>{() => (
                        dictCoberturas.map((item) => (
                            <Fragment>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    margin: "4px 0px 4px 24px",
                                }}>
                                    <div style={{width: "65%"}}>
                                        <Typography className={classes.tableRowLabel}>
                                            {item.descripcion}
                                        </Typography>
                                    </div>
                                    <div style={{
                                        width: "20%", display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "right",
                                    }}>
                                        <Typography
                                            className={classes.tableRowLabel}>$ {currencyFormat(item.sumaAsegurada)}</Typography>
                                    </div>
                                    <div style={{width: "15%"}}>
                                        <PopOver
                                            descripcion={item.descripcionCompleta}/>
                                    </div>
                                </div>
                                {item.numeroCobertura === '48'
                                &&
                                (<div style={{marginTop:"-16px"}}>{
                                    values.detalleBicicletas.map(itemDetail => (
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            margin: "0px 0px 8px 32px",
                                        }}>
                                            <div style={{width: "65%"}}>
                                                <Typography className={classes.tableRowDetailLabel}>
                                                    {itemDetail.descripcionBicicletas}
                                                </Typography>
                                            </div>
                                            <div style={{
                                                width: "20%", display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "right",
                                            }}>
                                                <Typography
                                                    className={classes.tableRowDetailLabel}>{currencyFormat(parseInt(itemDetail.sumaAseguradaBicicletas))}</Typography>
                                            </div>
                                            <div style={{width: "15%"}}/>
                                        </div>
                                    ))
                                }</div>)}

                                {item.numeroCobertura === '26'
                                &&
                                values.detalleEquipoElectronico.map(itemDetail => (
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        margin: "-16px 0px 0px 40px",
                                    }}>
                                        <div style={{width: "65%"}}>
                                            <Typography className={classes.tableRowDetailLabel}>
                                                {itemDetail.descripcionElectronico}
                                            </Typography>
                                        </div>
                                        <div style={{
                                            width: "20%", display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "right",
                                        }}>
                                            <Typography
                                                className={classes.tableRowDetailLabel}>{currencyFormat(parseInt(itemDetail.sumaAseguradaElectronico))}</Typography>
                                        </div>
                                        <div style={{width: "15%"}}/>
                                    </div>
                                ))}
                                <Divider light/>
                            </Fragment>
                        )))}</FieldArray>

                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        margin: "16px 16px -8px 16px",
                    }}>
                        <Typography className={classes.tableHeader}>Beneficios sin costo:</Typography>
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                        <List dense>
                            {coberturasSinCostoState.map((item, index) => (
                                <ListItem key={index}>
                                    <SvgIcon component={item.icon} className={classes.sinCostoIcons}/>
                                    <Typography className={classes.tableRowLabel}>
                                        {item.descripcion}
                                    </Typography>
                                </ListItem>
                            ))}
                        </List>
                    </div>

                    <div style={{marginTop: "24px"}}>
                        <Typography className={classes.note}>
                            Nota: si deseas modificar las coberturas seleccionadas debes realizar una nueva cotización
                            con las coberturas deseadas.
                        </Typography>
                    </div>
                    <Grid item/>
                </Grid>
                <div className={classes.boxPrice}>
                    <Typography className={classes.priceTitle}>Cuota mensual:</Typography>
                    <Typography className={classes.priceText}>$ {cuotaMensual}</Typography>
                </div>
            </Grid>
        </React.Fragment>
    );
}
