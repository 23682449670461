import './SummaryBox.css';
import {ReactComponent as ArrowDown} from '../../assets/icons/ic_arrow-down.svg';

const SummaryBox = (props) => {
    const {name, value, variant, withTab, tabContent, tabCollapsed, tabOnClick, clickable = false, onClick} = props;

    return (
        <div className="SummaryBox" onClick={clickable ? onClick : () => {}}>
            <div className={`SummaryBox__container ${variant} ${clickable && 'clickable'}`}>
                <div className="SummaryBox__header">{name}</div>
                <div className={`SummaryBox__content ${clickable && 'clickable'}`}>{value}</div>
            </div>
            {withTab &&
                <div className={`SummaryBox__tab ${tabCollapsed ? '' : 'expanded'}`}>
                    <div className={tabCollapsed ? 'SummaryBox__tab--collapse' : ''}>{tabContent}</div>
                    <ArrowDown onClick={() => {
                        tabOnClick();
                    }}
                        className={`SummaryBox__ArrowDown ${tabCollapsed ? '' : 'rotated'}`} />
                </div>
            }
        </div>
    );
}

export default SummaryBox;