import React, {useEffect, useState} from 'react';
import './Cotizacion.css'
import {useLocation, useRouteMatch} from "react-router-dom";
import CotizarHogar from "../../features/Forms/Hogar/CotizarHogar";
import {CurrentQuotationProvider} from "../../context/CurrentQuotationContext/CurrentQuotationContext";
import CotizarMicroseguros from "../../features/Forms/Microseguros/CotizarMicroseguros";
import {FormContainer} from "../../features/Cotizacion/FormContainer";

const getForm = ((producto, rowData, action, performReset) => {
    switch (producto) {
        case 'automotor':
            return <FormContainer rowData={rowData} producto={producto} action={action} performReset={performReset}/>
        case 'hogar':
            return <CotizarHogar recotizarValues={rowData} action={action} performReset={performReset}/>
        case 'motovehiculo':
            return <FormContainer rowData={rowData} producto={producto} action={action} performReset={performReset}/>
        case 'microseguros':
            return <CotizarMicroseguros recotizarValues={rowData} action={action} performReset={performReset}/>
        case 'asistencia_viajero':
            return <FormContainer rowData={rowData} producto={producto} action={action} performReset={performReset}/>
        default:
            return null;
    }
})

function Cotizacion(props) {
    const {params: {producto}} = useRouteMatch();
    const {rowData, action} = props.location.state ? props.location.state : {};
    // This is used to reset the form when the user navigates clicks again the same product/link
    const location = useLocation();
    const [previousKey, setPreviousKey] = useState(null);

    useEffect(() => {
        setPreviousKey(location.key);
    }, [location.key]);

    return (
        <CurrentQuotationProvider>
            <>
                <div className="cotizacion__container">

                    {getForm(producto, rowData, action, previousKey !== location.key)}

                </div>
            </>
        </CurrentQuotationProvider>
    );
}

export default Cotizacion;