import axios from "axios";
import fileDownload from "js-file-download";

const downloadFile = (link, filename) => {
    axios.get(link, {responseType: 'blob'})
        .then(response => {
            fileDownload(response.data, filename);
        });
}

export {downloadFile};
