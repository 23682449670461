import React from 'react';

const AuthContext = React.createContext({

    idAuth: null,
    authToken: null,
    isAuth: false,
    isMaster: false,
    mail: false,
    isReady: false

});

export default AuthContext;