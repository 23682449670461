import { useFormikContext } from "formik";
import DropzoneWrapper from "../../../components/DropzoneWrapper/DropzoneWrapper";
import documentList from "../../MisDocumentos/documentList";
import { useMemo } from "react";

const DocumentacionForm = () => {
    const { values } = useFormikContext();
    const initialLabelOptions = useMemo(() => {
        return documentList.map((doc) => {
            const document = values.files.find((file) => file.type === doc.value);
            return {...doc, used: !!document,};
        });
    }, []);

    return (
        <DropzoneWrapper maxFiles={9} labelOptions={initialLabelOptions} fileFieldName="files" fileList />
    );
}

export default DocumentacionForm;
