import React from 'react';
import './MasterOperaciones.css';
import {OperacionesPolkistasTable} from "../../features/MasterOperaciones/OperacionesPolkistasTable";

function MasterOperaciones() {
    return (
        <div className="masterOperaciones__container">
            <div className="masterOperaciones__welcomeTextContainer">
                <p className="masterOperaciones__title">Operaciones Polkistas</p>
                <p className="masterOperaciones__subtitle">Selecciona los filtros para comenzar tu búsqueda.</p>
            </div>

            <OperacionesPolkistasTable/>
        </div>
    );
}

export default MasterOperaciones;