import {currencyFormat} from "../../../modules/utils/currencyFormat";
import React from "react";

const stateColorsDict = {
    "Success": ["Success", "Emitida"],
    "Pending": ["Pending", "Pendiente", "Propuesta"],
}

const commissionColumns = [
    {title: "ID Emisión", key: "idEmision", render: (value) => <span>{value}</span>},
    {title: "Producto", key: "producto", render: (value) => <span>{value === 'COMBINADO' ? 'HOGAR' : value}</span>},
    {title: "Aseguradora", key: "company", render: (value) => <span>{value}</span>},
    {title: "N° de trámite", key: "numeroTramite", render: (value) => <span>{value}</span>},
    {title: "Detalle", key: "descripcion", render: (value) => <span>{value}</span>},
    {title: "Comisión", key: "comision", render: (value) => <span>$ {currencyFormat(value)}</span>},
    {title: "Cliente", key: "cliente", render: (value) => <span>{value}</span>},
    {title: "Inicio vigencia", key: "fecha", render: (value) => <span>{value}</span>},
    {
        title: "Estado",
        key: "estado",
        render: (value) => {
            if (stateColorsDict["Success"].includes(value))
                return <span className="comDetModal__column__state--success">{value}</span>
            else if (stateColorsDict["Pending"].includes(value))
                return <span className="comDetModal__column__state--warning">{value}</span>
            else
                return <span className="comDetModal__column__state--failed">{value}</span>
        }
    }
];

const commissionOtrosColumns = [
    {title: "Fecha", key: "fecha", render: (value) => <span>{value}</span>},
    {title: "Descripción", key: "descripcion", render: (value) => <span>{value}</span>},
    {title: "Comisión", key: "comision", render: (value) => <span>$ {currencyFormat(value)}</span>},
];


export {commissionColumns, commissionOtrosColumns};