import React from 'react';
import {Input} from '@mantine/core';
import './MantineInputFilter.css';
import {ReactComponent as SearchIcon} from "../../../assets/icons/ic_search.svg"

function MantineInputFilter(props) {
    const {value, setValue} = props;

    return (
        <Input.Wrapper
            {...props}
            classNames={{
                wrapper: 'mantine__inputFilter__wrapper',
                invalid: 'mantine__inputFilter__wrapper',
                label: 'mantine__inputFilter__label',
            }}
        >
            <Input {...props}
                   value={value}
                   onChange={(e) => setValue(e.target.value)}
                   classNames={{
                       input: 'mantine__inputFilter__field',
                   }}
                   rightSection={<SearchIcon className="mantine__inputFilter__icon"/>}
            />
        </Input.Wrapper>
    );
}

export default MantineInputFilter;