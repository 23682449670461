import React, {useEffect, useState} from 'react';
import {useFormikContext} from "formik";
import "./ProductsForm.css";
import {ProductOptions} from "../../../Utils/ProductOptions";

export default function ProductsForm(props) {
    const {formField: {products}, isEdit} = props;
    const {values, setFieldValue, errors} = useFormikContext();
    const [selectedProducts, setSelectedProducts] = useState(values.products)

    useEffect(() => {
        setFieldValue("products", selectedProducts)
    }, [selectedProducts])

    return (
        <React.Fragment>
            <div className="PForm__container">
                <div className="PForm__templateSelectedContainer">
                    <label className="PForm__sectorTitle">
                        {products.label}
                    </label>
                </div>
                <ProductOptions idPolkista={values['idPolkistaToCreate']} products={selectedProducts} setProducts={setSelectedProducts} edit={isEdit}/>
                <p className="PForm__error">{errors.products}</p>
            </div>
        </React.Fragment>
    );
}
