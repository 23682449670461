import React from 'react';
import '../../RegistroUsuario.css';
import {Button} from "../../../../../components/Button";
import {useHistory} from "react-router-dom";

function RegistroSuccess() {
    const history = useHistory();

    return (
        <div className="registroUsuario__success__container">
            <p className="registroUsuario__success__title">Registro de <span>Polko</span> completado.</p>
            <p className="registroUsuario__success__subtitle">Bienvenido, ya sos un polkista.</p>

            <Button
                type="button"
                variant="filled"
                color="secondary"
                marginY="lg"
                enabled
                onClick={() => history.push('/')}
            >
                Finalizar
            </Button>
        </div>
    );
}

export default RegistroSuccess;