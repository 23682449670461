import React, {createContext, useContext, useEffect, useState} from 'react';
import showNotification from "../../modules/utils/showNotification";
import UserContext from "../UserContext";
import {currencyFormat} from "../../modules/utils/currencyFormat";

const CurrentQuotationContext = createContext({});

export const useCurrentQuotationContext = () => useContext(CurrentQuotationContext);

export const CurrentQuotationProvider = ({children}) => {
    const storedCompanies = localStorage.getItem('companiesByProduct');
    const [selectedCompanies, setSelectedCompanies] = useState(storedCompanies ? JSON.parse(storedCompanies) : {});
    const [selectedCompaniesToPromote, setSelectedCompaniesToPromote] = useState([]);
    const [cotizacionResponse, setCotizacionResponse] = useState(null);
    const [notFormattedcotizacionResponse, setNotFormattedcotizacionResponse] = useState(null);
    const [emisionResponse, setEmisionResponse] = useState(null);
    const [finalValues, setFinalValues] = useState(null);
    const [formData, setFormData] = useState(null);
    const [currentFormData, setCurrentFormData] = useState(null);
    const [updatingForm, setUpdatingForm] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [modelos, setModelos] = useState([]);
    const [totalInsurableByCompany, setTotalInsurableByCompany] = useState(null);
    const anios = Array.from({length: 24}, (_, i) => ({value: (2024 - i).toString(), label: (2024 - i).toString()}))
    const [cartCoverages, setCartCoverages] = useState([]);
    const [coverageComparisonModalOpen, setCoverageComparisonModalOpen] = useState(false);
    const [discounts, setDiscounts] = useState({
        Sancor: 0,
        RUS: 0,
        Zurich: 0,
        Federacion_Patronal: 0
    })
    const [localidadOptions, setLocalidadOptions] = useState([]);
    const [provinciasOptions, setProvinciasOptions] = useState([]);
    const [coberturasInfo, setCoberturasInfo] = useState([]);
    // TODO: Lo de abajo está mal, considero que activeFormProduct deberia ser un estado perteneciente a este context en lugr de a UserContext.
    const {activeFormProduct} = useContext(UserContext);
    const [resumeKeyConstructor, setResumeKeyConstructor] = useState([]);
    const [coverageRenderRowConstructor, setCoverageRenderRowConstructor] = useState(null);
    const [advancedConfigReqData, setAdvancedConfigReqData] = useState(null);

    useEffect(() => {
        setCotizacionResponse(null);
        setTotalInsurableByCompany(null);
        setFinalValues(null);
        switch (activeFormProduct) {
            case 'automotor':
                setResumeKeyConstructor(["vehiculo.detalle_marca", "-", "vehiculo.detalle_version", "-", "vehiculo.anio"]);
                setCoverageRenderRowConstructor({
                    id: {
                        name: "id",
                        colSpan: 1,
                    },
                    coverageName: {
                        name: "nombre",
                        colSpan: 4,
                    },
                    premium: {
                        name: "premium",
                        colSpan: 1,
                        render(value) {
                            return `$${currencyFormat(value)}`
                        }
                    },
                    parametrosAdicionales: {
                        name: "parametrosAdicionales",
                        colSpan: 1,
                    }
                });
                break;
            case 'motovehiculo':
                setResumeKeyConstructor(["motovehiculo.detalle_marca", "-", "motovehiculo.detalle_version", "-", "motovehiculo.anio"]);
                setCoverageRenderRowConstructor({
                    id: {
                        name: "id",
                        colSpan: 1,
                    },
                    coverageName: {
                        name: "nombre",
                        colSpan: 4,
                    },
                    premium: {
                        name: "premium",
                        colSpan: 1,
                        render(value) {
                            return `$${currencyFormat(value)}`
                        }
                    },
                    parametrosAdicionales: {
                        name: "parametrosAdicionales",
                        colSpan: 1,
                    }
                });
                break;
            case 'asistencia_viajero':
                setResumeKeyConstructor(["nombrePais", "-", "vigenciaDesde", "-", "vigenciaHasta"]);
                setCoverageRenderRowConstructor({
                    id: {
                        name: "id",
                        colSpan: 1,
                    },
                    coverageName: {
                        name: "nombre",
                        colSpan: 3,
                    },
                    dolarPremium: {
                        name: "precio",
                        colSpan: 2,
                        render(value) {
                            return `${currencyFormat(parseInt(value))} USD`
                        }
                    },
                    verDetalle: {
                        name: "masInfo",
                        colSpan: 1,
                    },
                    premium: {
                        name: "premium",
                        colSpan: 2,
                        render(value) {
                            return `$${currencyFormat(parseInt(value))}`
                        }
                    }
                });
                break;
            default:
                break;
        }
    }, [activeFormProduct]);

    const [prevDiscounts, setPrevDiscounts] = useState({
        Sancor: 0,
        RUS: 0,
        Zurich: 0
    });

    const addCoverageToCart = (rowData) => {
        setCartCoverages(prevCartCoverages => {
            if (prevCartCoverages.find(cov => cov.id === rowData.id)) {
                return [...prevCartCoverages.filter(cov => cov.id !== rowData.id)]
            } else if (prevCartCoverages.length === 4) {
                showNotification({
                    autoClose: 3000,
                    color: 'red',
                    status: 'error',
                    title: 'Ha alcanzado el máximo de coberturas permitido.',
                    message: 'Elimine una cobertura para poder agregar otra.',
                });
                return [...prevCartCoverages]
            } else {
                return [...prevCartCoverages, rowData].sort((a, b) => parseInt(a.actualPremium) - parseInt(b.actualPremium))
            }
        });
    }
    const removeCoverageFromCart = (_id) => {
        let hardCopy = [...cartCoverages];
        hardCopy = hardCopy.filter((cartItem) => cartItem.id !== _id);
        setCartCoverages(hardCopy);
    }

    const updateCartCoverages = (idToUpdate, newPremiumValue) => {
        setCartCoverages(prevCartCoverages => {
            return prevCartCoverages.map(coverage => {
                if (coverage.id === idToUpdate) {
                    return {...coverage, actualPremium: newPremiumValue};
                }
                return coverage;
            });
        });
    };

    return (
        <CurrentQuotationContext.Provider value={{
            selectedCompanies,
            setSelectedCompanies,
            selectedCompaniesToPromote,
            setSelectedCompaniesToPromote,
            cotizacionResponse,
            setCotizacionResponse,
            notFormattedcotizacionResponse,
            setNotFormattedcotizacionResponse,
            finalValues,
            setFinalValues,
            discounts,
            setDiscounts,
            prevDiscounts,
            setPrevDiscounts,
            formData,
            setFormData,
            currentFormData,
            setCurrentFormData,
            modelos,
            setModelos,
            anios,
            advancedConfigReqData,
            setAdvancedConfigReqData,
            activeStep,
            setActiveStep,
            emisionResponse,
            setEmisionResponse,
            updatingForm,
            setUpdatingForm,
            cartCoverages,
            setCartCoverages,
            localidadOptions,
            setLocalidadOptions,
            provinciasOptions,
            setProvinciasOptions,
            coberturasInfo,
            setCoberturasInfo,
            totalInsurableByCompany,
            setTotalInsurableByCompany,
            coverageComparisonModalOpen,
            setCoverageComparisonModalOpen,
            addCoverageToCart,
            removeCoverageFromCart,
            updateCartCoverages,
            resumeKeyConstructor,
            coverageRenderRowConstructor
        }}>
            {children}
        </CurrentQuotationContext.Provider>
    );
};
