import React, {useContext} from 'react';
import {Grid} from "@mantine/core";
import {FieldArray, useFormikContext} from "formik";
import {DetallesVehiculoModel} from "./DetallesVehiculoModel";
import {Button} from '../../../../../../components/Button';
import {ReactComponent as CloseIcon} from "../../../../../../assets/icons/ic_close.svg";
import {MantineInputField} from "../../../../../../components/FormFields/MantineInputField";
import {MantineTextArea} from "../../../../../../components/FormFields/MantineTextArea";
import {MantineNumberField} from "../../../../../../components/FormFields/MantineNumberField";
import UserContext from "../../../../../../context/UserContext";

export default function DetallesVehiculoForm() {
    const {formField} = DetallesVehiculoModel();
    const {
        patente,
        motor,
        chasis,
        marca,
        version,
        anio,
        sumaAseguradaVehiculo,
        identificadorSancor,
        descripcion,
        monto,
        comentariosSolicitud
    } = formField;
    const {values} = useFormikContext();
    const {isMobile} = useContext(UserContext);

    const bienVacio = {
        [identificadorSancor.name]: '10',
        [descripcion.name]: '',
        [monto.name]: '',
    };

    return (
        <div className="motovehiculo__formStep__container">
            <div className="motem__form__details">
                <Grid gutterXs="xs" gutter="md" justify="center" align="center">
                    <Grid.Col xs={12} sm={12}>
                        <p>Datos del vehículo</p>
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6}>
                        <MantineInputField
                            name={`motovehiculo.${patente.name}`}
                            label={patente.label}
                            withAsterisk={values.esCeroKm !== "true"}
                        />
                    </Grid.Col>

                    <Grid.Col xs={12} sm={6}/>

                    <Grid.Col xs={12} sm={6}>
                        <MantineInputField
                            name={`motovehiculo.${motor.name}`}
                            label={motor.label}
                            withAsterisk
                        />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6}>
                        <MantineInputField
                            name={`motovehiculo.${chasis.name}`}
                            label={chasis.label}
                            withAsterisk
                        />
                    </Grid.Col>

                    <Grid.Col xs={12} sm={6}>
                        <MantineInputField
                            name={`motovehiculo.detalle_marca`}
                            label={marca.label}
                            disabled
                        />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6}>
                        <MantineInputField
                            name={`motovehiculo.${anio.name}`}
                            label={anio.label}
                            disabled
                        />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6}>
                        <MantineInputField
                            name={`motovehiculo.detalle_version`}
                            label={version.label}
                            disabled
                        />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6}>
                        <MantineNumberField
                            name={`motovehiculo.${sumaAseguradaVehiculo.name}`}
                            label={sumaAseguradaVehiculo.label}
                            disabled
                        />
                    </Grid.Col>

                    {values.sumaAseguradaAdicional > 0 &&
                        <>
                            <Grid.Col xs={12} sm={12}>
                                <p>Bienes asegurados (Adicionales)</p>
                            </Grid.Col>

                            <FieldArray name='motovehiculo.bienesAdicionales'>{({remove}) => (
                                values.motovehiculo?.bienesAdicionales?.map((e, index) => {

                                    if (isMobile) {
                                        return (
                                            <div className="motem__form__details__bienesAdicionales">
                                                <Grid.Col xs={12}>
                                                    <MantineInputField
                                                        name={`motovehiculo.bienesAdicionales[${index}].${descripcion.name}`}
                                                        label={descripcion.label}
                                                        withAsterisk
                                                    />
                                                </Grid.Col>
                                                <Grid.Col xs={12}>
                                                    <MantineInputField
                                                        name={`motovehiculo.bienesAdicionales[${index}].${monto.name}`}
                                                        label={monto.label}
                                                        withAsterisk
                                                    />
                                                </Grid.Col>
                                                <Grid.Col xs={12}>
                                                    <div className="motem__form__details__icon__container"
                                                         onClick={() => remove(index)}>
                                                        <CloseIcon className="motem__form__details__icon"/>
                                                    </div>
                                                </Grid.Col>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <>
                                                <Grid.Col sm={1}/>
                                                <Grid.Col sm={5}>
                                                    <MantineInputField
                                                        name={`motovehiculo.bienesAdicionales[${index}].${descripcion.name}`}
                                                        label={descripcion.label}
                                                        withAsterisk
                                                    />
                                                </Grid.Col>
                                                <Grid.Col sm={4}>
                                                    <MantineInputField
                                                        name={`motovehiculo.bienesAdicionales[${index}].${monto.name}`}
                                                        label={monto.label}
                                                        withAsterisk
                                                    />
                                                </Grid.Col>
                                                <Grid.Col sm={1}>
                                                    <div className="motem__form__details__icon__container"
                                                         onClick={() => remove(index)}>
                                                        <CloseIcon className="motem__form__details__icon"/>
                                                    </div>
                                                </Grid.Col>
                                                <Grid.Col sm={1}/>
                                            </>
                                        )
                                    }
                                })
                            )}</FieldArray>

                            <Grid.Col xs={12}>
                                <FieldArray name='motovehiculo.bienesAdicionales'>{({push}) => (
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        marginY="md"
                                        enabled
                                        onClick={() => push(bienVacio)}
                                    >
                                        Agregar bien adicional
                                    </Button>
                                )}</FieldArray>
                            </Grid.Col>
                        </>
                    }

                    <Grid.Col xs={12}>
                        <MantineTextArea
                            name={comentariosSolicitud.name}
                            label={comentariosSolicitud.label}
                            placeholder={"Comentarios adicionales para la solicitud"}
                        />
                    </Grid.Col>
                </Grid>
            </div>
        </div>
    );
}