import React from 'react';
import {Grid} from '@mantine/core';
import {TitularModel} from "./TitularModel";
import {MantineSelectField} from "../../../../../../components/FormFields/MantineSelectField";
import {ZipCodeFieldPackage} from "../../../../../Cotizacion/ZipCodeFieldPackage";
import {useCurrentQuotationContext} from "../../../../../../context/CurrentQuotationContext/CurrentQuotationContext";

export default function TitularForm() {
    const {formField} = TitularModel();
    const {
        tipoPersona,
        situacionImpositiva,
        codigosLocalidad,
        codigoPostal,
        idProvincia
    } = formField;
    const {
        localidadOptions,
        setLocalidadOptions,
        provinciasOptions,
        setProvinciasOptions
    } = useCurrentQuotationContext();

    const updateLocalidadOptions = (newLocalidades) => {
        setLocalidadOptions((prevState) => {
            const newState =  [...prevState ];
            newState[0] = newLocalidades;
            return newState;
        });
    };
    const situaciones_impositivas = [
        {value: '1', label: 'Responsable inscripto'},
        {value: '4', label: 'Consumidor final'},
    ];

    const opciones_binarias = [
        {value: '0', label: 'Física'},
        {value: '1', label: 'Jurídica'}
    ];

    return (
        <div className="motovehiculo__formStep__container">
            <Grid gutterXs="xs" gutter="md" justify="center" align="center">
                <Grid.Col xs={12} sm={6}>
                    <MantineSelectField
                        name={tipoPersona.name}
                        label={tipoPersona.label}
                        data={opciones_binarias}
                        withAsterisk
                    />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <MantineSelectField
                        name={situacionImpositiva.name}
                        label={situacionImpositiva.label}
                        data={situaciones_impositivas}
                        withAsterisk
                    />
                </Grid.Col>
                <>
                    <ZipCodeFieldPackage localidadField={codigosLocalidad} localidadSize={6}
                                         codigoPostalField={codigoPostal} codigoPostalSize={6}
                                         provinciaField={idProvincia} provinciaSize={6}
                                         localidadOptions={localidadOptions[0] || []} setLocalidadOptions={updateLocalidadOptions}
                                         provinciasOptions={provinciasOptions} setProvinciaOptions={setProvinciasOptions}
                    />
                </>

                <Grid.Col xs={12} sm={6}/>
            </Grid>
        </div>
    );
}
