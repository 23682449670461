import React, {useContext, useEffect} from 'react';
import './CustomModal.css';
import UserContext from "../../context/UserContext";

function CustomModal(props) {
    const {children, fullWidth = false} = props;
    const {sidebarOpen} = useContext(UserContext);

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        }
    }, []);

    return (
        <div className={fullWidth ? `customModal__container--fullwidth` :`customModal__container customModal__container--${sidebarOpen}`}>
            <div className="customModal__content">
                {children}
            </div>
        </div>
    );
}

export default CustomModal;