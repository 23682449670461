const moment = require("moment");

function calculateVigenciaHasta(company, date, frequency) {
    let vigenciaHasta;
    switch (company) {
        case "Sancor":
            switch (frequency) {
                case "5":
                    vigenciaHasta = moment(date, "YYYY-MM-DD").add(1, "Y").toDate();
                    break;
                default:
                    vigenciaHasta = moment(date, "YYYY-MM-DD").add(6, "M").toDate();
                    break;
            }
            break;
        case "RUS":
            switch (frequency) {
                case "ANUAL":
                    vigenciaHasta = moment(date, "YYYY-MM-DD").add(1, "Y").toDate();
                    break;
                default:
                    vigenciaHasta = moment(date, "YYYY-MM-DD").add(3, "M").toDate();
                    break;
            }
            break;
    }
    return vigenciaHasta;
}

/**
 * This function is used when the user request to quote only one company.
 * It will apply and send only the selected/requested ONE
 * @param jsonData
 * @param companyToQuote
 * @param idCompanyToQuote
 * @param configuracionAvanzadaReceived
 * @returns modified jsonData
 */
//TODO: Review this function. It might be better to use one form by product
const advancedQuotation = (jsonData, companyToQuote, idCompanyToQuote, configuracionAvanzadaReceived) => {
    jsonData.companies = [idCompanyToQuote]

    let advancedConfigObj = {}
    const filteredKeys = Object.keys(jsonData.configuracionAvanzada).filter((key) => key === companyToQuote)
    filteredKeys.forEach((key) => {
        advancedConfigObj[key] = jsonData.configuracionAvanzada[key]
    })
    jsonData.configuracionAvanzada = advancedConfigObj

    switch (companyToQuote) {
        case "Sancor":
            jsonData['configuracionAvanzada'][companyToQuote]['vigenciaHasta'] = calculateVigenciaHasta(companyToQuote, configuracionAvanzadaReceived['Sancor']['fechaCotizacion'], configuracionAvanzadaReceived.Sancor.frecuenciaPago)
            break;
        case "RUS":
            jsonData['configuracionAvanzada'][companyToQuote]['vigenciaHasta'] = calculateVigenciaHasta(companyToQuote, configuracionAvanzadaReceived['RUS']['vigenciaDesde'], configuracionAvanzadaReceived.RUS.tipoVigencia)
            break;
        case "Zurich":
            jsonData['configuracionAvanzada'][companyToQuote]['vigenciaHasta'] = moment(configuracionAvanzadaReceived['Zurich']['vigenciaDesde'], "YYYY-MM-DD").add(1, "Y").toDate();
            break;
    }

    return jsonData
}

export {advancedQuotation}