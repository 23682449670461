import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {Link as RouterLink} from 'react-router-dom';
import LaunchIcon from '@material-ui/icons/Launch';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "../components/Typography";


const CssTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "green"
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "green"
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#973379",
                borderWidth: 2,
                borderRadius: 8,
                borderStyle: "solid",
            },
        }
    }
})(TextField);

const useStyles = makeStyles((theme) => ({
    bottomSpacer: {
        marginTop: theme.spacing(3),
    },
    searchBar: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    titleBar: {
        display: 'flex',
        flexDirection: 'row'
    },
    textfieldCustom: {
        border: '3px solid #973379',
        overflow: 'hidden',
        borderRadius: 8,
    },
    titulo: {
        fontFamily: "Ubuntu",
        fontSize: 24,
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: -0.5,
        color: '#35303f'
    },
    gridSearchBar:{
        marginLeft: '90px',
        [theme.breakpoints.down('xs')]: {
           width:'100%',
    marginLeft:0
        },
    },
    maxHeightTable:{
        overflow:'auto',
        [theme.breakpoints.down('md')]: {
            height: '600px'
        },
    }
}));

export default function Cotizaciones(props) {
    const {infoCotizaciones} = props;
    const classes = useStyles();
    const [cotizaciones, setCotizaciones] = useState(null);
    const [filteredCotizaciones, setFilteredCotizaciones] = useState(null);
    const [rows, setRows] = useState(null);

    const handleSearchValueChange = (event) => {
        if (event.target.value.length >= 3) {
            let filtered = cotizaciones.filter((item) =>
                item.modelo.toUpperCase().includes(event.target.value.toUpperCase())
            );
            setFilteredCotizaciones(filtered);
        } else setFilteredCotizaciones(null)
    };

    function getCotizaciones() {
        let options = infoCotizaciones.map((item, i) => ({
            "id": i,
            "marca": item.Marca,
            "modelo": item.Vehiculo.Modelo,
            "year": item.Year,
            "fecha": item.Fecha_cotizacion,
            "idCotizacion": item.id_Cotizacion_req
        }));
        setCotizaciones(options);
        setRows(options);
    }

    useEffect(() => {
        if (cotizaciones === null) {
            if (infoCotizaciones.length > 0) {
                getCotizaciones()
            }
        }
        if (filteredCotizaciones !== null)
            setRows(filteredCotizaciones);
        else if (cotizaciones !== null) {
            setRows(cotizaciones);
        }
    }, [infoCotizaciones, filteredCotizaciones]);

    return (

        <React.Fragment>
            <div className={classes.titleBar}>
                <div style={{flexGrow: 1}}/>

                <Grid container style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    marginBottom: '32px'
                }}>
                    <Grid item>
                        <Typography className={classes.titulo}>Últimas Cotizaciones</Typography>
                    </Grid>


                    <Grid item className={classes.gridSearchBar}>
                        <CssTextField
                            placeholder="Buscar por modelo"
                            variant="outlined"
                            id="custom-css-outlined-input"
                            onChange={(e) => handleSearchValueChange(e)}
                            margin="dense"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon/>
                                    </InputAdornment>
                                ),
                            }}
                        />

                    </Grid>
                </Grid>

            </div>

            <div className={classes.maxHeightTable}>
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Marca</TableCell>
                        <TableCell>Modelo</TableCell>
                        <TableCell>Año</TableCell>
                        <TableCell>Fecha Cotizacion</TableCell>
                        <TableCell>Recotizar</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rows !== null) &&
                    (rows?.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.marca}</TableCell>
                            <TableCell>{row.modelo}</TableCell>
                            <TableCell>{row.year}</TableCell>
                            <TableCell>{row.fecha}</TableCell>
                            <TableCell>
                                <RouterLink to={{
                                    pathname: '/u/cotizar/vehiculo',
                                    state: {
                                        rowData: infoCotizaciones[row.id]
                                    }
                                }
                                }>
                                    <LaunchIcon/>
                                </RouterLink>
                            </TableCell>
                        </TableRow>
                    )))
                    }
                </TableBody>
            </Table>
            </div>
            <div className={classes.bottomSpacer}/>
        </React.Fragment>
    );
}
