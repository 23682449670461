import React, {useContext, useState} from 'react';
import ProfileForm from "../../features/Forms/Profile/ProfileForm";
import ProfileImageUpload from "../../features/Forms/Profile/Forms/ProfileImageUpload";
import {FormCard} from "../../components/FormCard";
import './Profile.css';
import UserContext from "../../context/UserContext";

function Profile() {
    const {userPicture} = useContext(UserContext);
    const [userImage, setUserImage] = useState(userPicture);
    const [newImageData, setNewImageData] = useState(null);
    const [newImageUrl, setNewImageUrl] = useState(null);

    return (
        <div className='Profile__page'>
            <div className="Profile__container">
                <FormCard>
                    <div className="Profile__title"><p>EDITAR PERFIL</p></div>
                    <div className="Profile__mobilePadding">
                        <div className="Profile__formContainer">
                            <ProfileImageUpload userImage={userImage} setUserImage={setUserImage}
                                                setNewImageData={setNewImageData} setNewImageUrl={setNewImageUrl}/>
                            <ProfileForm userImage={userImage} setUserImage={setUserImage} newImageData={newImageData} newImageUrl={newImageUrl}/>
                        </div>
                    </div>
                </FormCard>
            </div>
        </div>
    )
}

export default Profile
