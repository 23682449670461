import React from 'react';
import './Drawer.css'
import {Drawer} from '@mantine/core';
import {Button} from "../../../components/Button";
import {useAuth0} from "@auth0/auth0-react";

function LandingDrawer(props) {
    const {drawerOpened, setDrawerOpened} = props;
    const {loginWithRedirect} = useAuth0();
    return (
        <Drawer
            opened={drawerOpened}
            onClose={() => setDrawerOpened(false)}
            position="right"
            padding="xl"
            size="60%"
            classNames={{
                content: 'landing__drawer__container',
                overlay: 'landing__drawer__overlay',
                closeButton: 'landing__drawer__closeButton',
            }}
        >
            <div className="landing__drawer__content">
                <a className="landing__drawer__link">INICIO</a>
                <a className="landing__drawer__link">BENEFICIOS</a>
                <a className="landing__drawer__link">CARACTERÍSTICAS</a>
                <a className="landing__drawer__link">CONTACTO</a>

                <div className="landing__drawer__buttons">
                    <Button
                        variant="filled"
                        color="secondary"
                        marginX="sm"
                        marginY="sm"
                        enabled
                        onClick={loginWithRedirect}
                    >
                        Ingresar
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        marginX="sm"
                        marginY="sm"
                        enabled
                        onClick={loginWithRedirect}
                    >
                        Registrarme
                    </Button>
                </div>
            </div>
        </Drawer>
    );
}

export default LandingDrawer;