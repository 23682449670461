import * as Yup from "yup";

const FormaPagoModel = () => {
    const formField = {
        infoDePago: {
            name: 'infoDePago',
            label: 'Forma De Pago',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
    }

    const initialValues = {
        [formField.infoDePago.name]: '',
    }

    const validationSchema = Yup.object().shape({
        [formField.infoDePago.name]: Yup.string().required(`${formField.infoDePago.requiredErrorMsg}`),
    })

    return {formField, initialValues, validationSchema}
}

export {FormaPagoModel};
