import React, {useContext, useEffect, useState} from 'react';
import {Grid} from '@material-ui/core';
import {InputField, SelectField} from '../../../../components/FormFields';
import {useFormikContext} from "formik";
import Divider from "@material-ui/core/Divider";
import SlantedField from "../../../../components/FormFields/SlantedField";
import {add_6months, add_years} from "../../../../utils/timeAdders";
import {gql, useQuery} from "@apollo/client";
import Loading from "../../../../components/Loading";
import CotizarContext from "../../../../../context/CotizarContext";

const GET_MODULOS = gql`
    query MyQuery {
  Autos_tabla_modulos {
    Nombre_Modulo
    idModulo
  }
}
`;

export default function PolizaForm(props) {
    const {
        formField: {
            vigenciaDesde,
            vigenciaHasta,
            frecuenciaPago,
            cuotas,
            ajusteAutomatico,
            formaDePago,
            infoDePago: {
                // codigoAgencia,
                numeroCbu,
                marcaTarjeta,
                numeroTarjeta,
                // vencimientoTarjeta,
            },
            // emailDespachoElectronico,
            // despachoElectronico,
            moduloCobertura,
        }
    } = props;
    const {values, setFieldValue} = useFormikContext();
    const {TR_ID_MODULOS} = useContext(CotizarContext);
    const [modulosCoberturas, setModulosCoberturas] = useState(null);

    const formasDePago = [
        {value: '0', label: 'Efectivo'},
        {value: '4', label: 'Débito por CBU'},
        {value: '2', label: 'Tarjeta de crédito'}
    ];

    const parseModulosData = (data) => {
        let dict = {};
        data['Autos_tabla_modulos'].map((item) =>
            dict[item.idModulo] = {
                nombreModulo: item.Nombre_Modulo,
            });
        setModulosCoberturas(dict)
    };

    const {data: modulosData} = useQuery(GET_MODULOS, {
        fetchPolicy: "cache-first",
        onCompleted: parseModulosData
    });

    const idsTarjeta = [
        {value: '1', label: 'Cabal'},
        {value: '5', label: 'Visa'},
        {value: '7', label: 'Favacard'},
        {value: '9', label: 'American Express'},
        {value: '12', label: 'Naranja'},
        {value: '16', label: 'Diners Club'},
        {value: '18', label: 'Naranja Visa'},
        {value: '20', label: 'Cordobesa - Matercard'},
        {value: '23', label: 'TDF - Tierra del Fuego'},
        {value: '45', label: 'Mastercard Nueva'},
        {value: '57', label: 'ELEBAR'},
        {value: '70', label: 'Censosud  Mastercard'},
    ];

    const frecuencias = {
        '5': 'Anual',
        '7': 'Semestral'
    };

    const ajustes = {
        '0': 'No aplicar',
        'Option_10': 'Aplicar 10%',
        'Option_20': 'Aplicar 20%',
        'Option_30': 'Aplicar 30%',
    };

    useEffect(() => {
        let date;
        if (new Date(values.vigenciaDesde.replace(/-/g, '/')) > new Date().getDate() - 1) {
            date = new Date(values.vigenciaDesde.replace(/-/g, '/'));
            if (values.frecuenciaPago === "5") {
                setFieldValue("vigenciaHasta", add_years(date).toString())
            } else {
                setFieldValue("vigenciaHasta", add_6months(date).toString())
            }
        }
    }, [values.vigenciaDesde, values.frecuenciaPago])

    if (!modulosCoberturas) return <Loading/>
    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <SlantedField value={frecuencias[values.frecuenciaPago]} label={frecuenciaPago.label}/>
                </Grid>
                {values.frecuenciaPago === '7' && (
                    <Grid item container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <SlantedField value={values.cuotas} label={cuotas.label}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SlantedField value={ajustes[values.ajusteAutomatico]} label={ajusteAutomatico.label}/>
                        </Grid>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Divider variant="middle"/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputField
                        name={vigenciaDesde.name}
                        label={vigenciaDesde.label}
                        type="date"
                        fullWidth
                        InputLabelProps={{shrink: true,}}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SlantedField value={values.vigenciaHasta.split('-').reverse().join('/')}
                                  label={vigenciaHasta.label}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <SlantedField
                        value={
                            TR_ID_MODULOS.includes(values.moduloCobertura.toString())
                                ? values.nombreModulo.split('Auto ')
                                : modulosCoberturas[values.moduloCobertura].nombreModulo
                        }
                        label={moduloCobertura.label}/>
                </Grid>

                <Grid item xs={12}>
                    <Divider variant="middle"/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <SelectField
                        name={formaDePago.name}
                        label={formaDePago.label}
                        data={formasDePago}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={0} md={6}/>
                {values.formaDePago === '2' && (
                    <Grid item container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <SelectField
                                name={`infoDePago.${marcaTarjeta.name}`}
                                label={marcaTarjeta.label}
                                data={idsTarjeta}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputField
                                name={`infoDePago.${numeroTarjeta.name}`}
                                label={numeroTarjeta.label}
                                fullWidth
                            />
                        </Grid>
                        {/*<Grid item xs={12} md={6}>
                            <InputField
                                name={`infoDePago.${vencimientoTarjeta.name}`}
                                label={vencimientoTarjeta.label}
                                fullWidth
                            />
                        </Grid>*/}
                    </Grid>
                )}
                {values.formaDePago === '4' && (
                    <Grid item container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <InputField
                                name={`infoDePago.${numeroCbu.name}`}
                                label={numeroCbu.label}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </React.Fragment>
    );
}
