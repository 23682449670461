import { ReactComponent as Eye } from '../../../assets/icons/ic_eye.svg';
import './EyeIcon.css';
import  '../Icon.css';

const EyeIcon = (props) => {
    const {size="sm"} = props;

    return (
        <Eye className={`EyeIcon Icon ${size}`} />
    );
}

export default EyeIcon;