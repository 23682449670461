import React, {useContext, useState} from 'react';
import './FormContainer.css';
import CotizarAutomotor from "../../Forms/Automotor/CotizarAutomotor";
import CotizarMotovehiculo from "../../Forms/Motovehiculo/CotizarMotovehiculo";
import {TextLink} from "../../../components/TextLink";
import {useCurrentQuotationContext} from "../../../context/CurrentQuotationContext/CurrentQuotationContext";
import {CompanySelectorModal} from "../CompanySelectorModal";
import {useHistory, useLocation} from "react-router-dom";
import {Popover} from "@mantine/core";
import {ReactComponent as CartIcon} from "../../../assets/icons/ic_cart.svg";
import {CoveragesPopover} from "../CoveragesPopover";
import {NewIndicator} from "../../../components/NewIndicator";
import UserContext from "../../../context/UserContext";
import AsistenciaViajero from "../../Forms/AsistenciaViajero/AsistenciaViajero";

const COMPANIES_DEFAULT_CONFIGURATION = {
    "automotor": {"1": true, "2": false, "3": false},
    "motovehiculo": {"1": true, "2": false, "3": false},
    "hogar": {"1": true, "2": false, "3": false},
    "microseguros": {"1": true, "2": false, "3": false}
}

const getForm = ((producto, rowData, _props) => {
    switch (producto) {
        case 'automotor':
            return <CotizarAutomotor recotizarValues={rowData} {..._props}/>
        case 'motovehiculo':
            return <CotizarMotovehiculo recotizarValues={rowData} {..._props}/>
        case 'asistencia_viajero':
            return <AsistenciaViajero recotizarValues={rowData} {..._props}/>
        default:
            return null;
    }
})

function FormContainer(props) {
    const {producto, rowData} = props;
    const {
        cotizacionResponse,
        setSelectedCompanies,
        cartCoverages,
        activeStep,
        setCoverageComparisonModalOpen,
        formData,
        setSelectedCompaniesToPromote
    } = useCurrentQuotationContext();
    const [companySelectorModalOpen, setCompanySelectorModalOpen] = useState(JSON.parse(localStorage.getItem('companiesByProduct')) === null);
    const [cartOpened, setCartOpened] = useState(false);
    const {userCompanies, userCompaniesToPromote} = useContext(UserContext)
    const location = useLocation();
    const history = useHistory();
    const productBeingModified = location.pathname.split("/")[3];
    const isLastQuotationStep = (formData?.cotizacion?.length - 2) === activeStep || false;

    /**
     * This function is called when the user finishes selecting the companies for the product being modified
     * @param userConfigurationForProduct - Object with the companies selected by the user for the product being modified
     * @param companiesToPromote
     */
    const handleFinishSelection = (userConfigurationForProduct, companiesToPromote) => {
        // Check if there is a previous configuration saved
        const selectedCompaniesSaved = JSON.parse(localStorage.getItem('companiesByProduct'));

        // If there is, update it with the new values
        // If there isn't, create a new one with the default values
        let userConfiguration = selectedCompaniesSaved ? selectedCompaniesSaved : COMPANIES_DEFAULT_CONFIGURATION;
        // Update the configuration for the product being modified
        userConfiguration[productBeingModified] = userConfigurationForProduct.reduce((result, item) => {
            result[item.id.toString()] = item.selected;
            return result;
        }, {});

        // Save the new configuration
        localStorage.setItem('companiesByProduct', JSON.stringify(userConfiguration));
        setSelectedCompanies(userConfiguration)
        setSelectedCompaniesToPromote(companiesToPromote)

        // Close the modal
        setCompanySelectorModalOpen(false)
    }

    return (
        <div className="form__container">
            {cotizacionResponse === null &&
                <div className="cotizacion__link__container">
                    {(userCompanies.length > 1 || userCompaniesToPromote.length > 0) ?
                        <div className="cotizacion__linkText__container"><TextLink
                            action={() => setCompanySelectorModalOpen(true)}>Elegí tus compañías </TextLink> {
                            userCompaniesToPromote.includes("Federacion_Patronal") && <NewIndicator size="tiny" color="yellow"/>} </div> :
                        <div></div>}
                    <div>
                        <TextLink
                            color='secondary'
                            action={() => {
                                history.push({
                                    pathname: '/u/documentacion',
                                    state: {
                                        filter: producto
                                    }
                                })
                            }}>Ver pólizas</TextLink>
                    </div>
                </div>
            }

            {cotizacionResponse && isLastQuotationStep &&
                <Popover withArrow arrowPosition="side" position="bottom-end"
                         shadow={"0 2px 2px rgba(0, 0, 0, 0.25)"}
                         opened={cartOpened} onChange={setCartOpened}
                         closeOnClickOutside={true}
                         classNames={{
                             dropdown: "automotor__cotSuccess__popover"
                         }}
                >
                    <Popover.Target>
                        <div className="automotor__cotSuccess__FAB__container">
                            <div className="automotor__cotSuccess__FAB" onClick={() => setCartOpened(!cartOpened)}>
                                {cartCoverages.length !== 0 &&
                                    <span className="automotor__cotSuccess__badge">
                                    <p className="automotor__cotSuccess__badge__text">
                                        {cartCoverages.length}
                                    </p>
                                </span>
                                }
                                <CartIcon className="automotor__cotSuccess__icon"/>
                            </div>
                        </div>
                    </Popover.Target>
                    <Popover.Dropdown>
                        <CoveragesPopover
                            setCoverageComparisonModalOpen={setCoverageComparisonModalOpen}
                            setCartOpened={setCartOpened}
                        />
                    </Popover.Dropdown>
                </Popover>
            }

            {getForm(producto, rowData, props)}

            {companySelectorModalOpen &&
                <CompanySelectorModal
                    closeAction={() => setCompanySelectorModalOpen(false)}
                    productBeingModified={productBeingModified}
                    buttonAction={handleFinishSelection}
                />
            }
        </div>
    );
}

export default FormContainer;