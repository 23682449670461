import React, {useState} from 'react';
import './Pagination.css';
import {ReactComponent as IconMinArrowLeft} from "../../assets/icons/ic_min_arrow-left.svg";

const Pagination = ({ totalItems, itemsPerPage, currentPage, setCurrentPage }) => {
    const lastPage = Math.ceil(totalItems / itemsPerPage);
    const [currentPageInputValue, setCurrentPageInputValue] = useState(currentPage);

    const handlePreviousPage = () => {
        if (currentPage > 1){
            setCurrentPageInputValue(currentPage - 1);
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < lastPage){
            setCurrentPageInputValue(currentPage + 1);
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPageInputValue(page)
        if (page !== "") {
            if (page > lastPage){
                page = lastPage;
            }
            else if (page < 1){
                page = 1;
            }
            setCurrentPage(page);
        }
    };

    return (
        <div className="pagination__container">
            <div onClick={handlePreviousPage} className="pagination__icon__container">
                <IconMinArrowLeft className={currentPage === 1 ? "pagination__icon pagination__icon--disabled" : "pagination__icon"}/>
            </div>
            <div onClick={handleNextPage} className="pagination__icon__container">
                <IconMinArrowLeft className={currentPage === lastPage ? "pagination__icon--right pagination__icon--disabled" : "pagination__icon--right"}/>
            </div>
            <p className="pagination__text">
                {`Página ${currentPage} de ${lastPage} | Ir a`}
            </p>
            <input
                className="pagination__input"
                type="number"
                value={currentPageInputValue}
                onChange={(e) => handlePageChange(e.target.value)}
            />
        </div>
    );
};

export default Pagination;
