import "./FileList.css";

const FileList = (props) => {
    const {filesToUpload} = props;

    return (
        <p className="FileList__text">
            <span>Archivos a cargar: </span>
            {filesToUpload.map((option, index) => (
                <span key={option.value}>
                    {option.label}
                    {index !== filesToUpload.length - 1 && `, `}
                </span>
            ))}
        </p>
    );
}

export default FileList;
