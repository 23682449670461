import React from 'react';
import './TestimonialCard.css'

function TestimonialCard ({children}) {
    return (
        <div className="card__testimonial">
            {children}
        </div>
    );
}

export default TestimonialCard;