import React from 'react';
import './PopUpCard.css'
import useWindowDimensions from "../../modules/utils/useWindowDimensions";

function PopUpCard(props) {
    const {fullSpace, children, autoWidth} = props
    const {width} = useWindowDimensions();
    return (
        <div className={fullSpace && width < 480 ? `popupCard__container__fullSpace` : `popupCard__container ${autoWidth ? "--autoWidth" : ""}`}>
            {children}
        </div>
    );
}

export default PopUpCard;