import React, {
    useEffect,
    useState,
    useMemo, useCallback
} from 'react';
import PropTypes from 'prop-types';
import DefaultValuesContext from "./DefaultValuesContext";
import {gql, useLazyQuery} from "@apollo/client";

const GET_VALORES = gql`
    query MyQuery {
        General_Default_Values(where: {id: {_in: [1, 2, 3, 4, 5, 6, 7, 8, 9]}}) {
            Description
            Name
            Value
            id
        }
    }
`

const DefaultValuesContextProvider = ({
                                          children,
                                      }) => {


        const [isReady, setIsReady] = useState(false);
        const [modified, setModified] = useState(false);
        const [companiesDefaultValues, setCompaniesDefaultValues] = useState({});
        const handleModified = useCallback(() => {
            setModified(!modified)
        }, [modified]);

        const [getValores, {loading, data}] = useLazyQuery(GET_VALORES, {
            fetchPolicy: 'network-only', // Used for first execution
            nextFetchPolicy: 'cache-first'
        });

        const valuesMemo = useMemo(() => formating(data), [loading, data])

        function formating(_data) {
            let array = {}
            if (!loading && _data !== undefined) {
                _data['General_Default_Values'].map((value) => {
                    array[value['Name']] = parseInt(value['Value'])
                })

            }
            return array
        }

        useEffect(() => {
            if (valuesMemo && Object.keys(valuesMemo).length > 0) {
                setCompaniesDefaultValues({
                   "RC_VALUES": {
                       "RUS": valuesMemo['suma_RC_RUS'],
                       "Sancor": valuesMemo['suma_RC_Sancor'],
                       "Zurich": valuesMemo['suma_RC_Zurich'],
                       "Federacion_Patronal": valuesMemo['suma_RC_Federacion_Patronal'],
                   }
                })
                setIsReady(true)
            }
        }, [valuesMemo]);


        useEffect(() => {
                let shouldUpdate = true;

                const init = async () => {
                    if (shouldUpdate) {
                        getValores()
                    }
                }

                init();

                return () => {
                    /* This cleanup function is used to prevent updating the state
                    when the component is unmounted */
                    shouldUpdate = false;
                };
            }, [modified]
        )

        const context = useMemo(() => ({
            valuesMemo,
            companiesDefaultValues,
            handleModified,
            isReady
        }), [
            valuesMemo,
            companiesDefaultValues,
            handleModified,
            isReady
        ]);
        return (
            <DefaultValuesContext.Provider value={context}>
                {children}
            </DefaultValuesContext.Provider>
        );
    }
;

DefaultValuesContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default DefaultValuesContextProvider;