import React, {useContext, useEffect, useState} from 'react';
import './Shortcuts.css';
import {ReactComponent as CarIcon} from '../../../assets/icons/ic_car.svg';
import {ReactComponent as HogarIcon} from '../../../assets/icons/ic_hogar.svg';
import {ReactComponent as MicroIcon} from '../../../assets/icons/ic_microBike.svg';
import {RoundedRectangle} from "../../../components/RoundedRectangle";
import Axios from "axios";
import CotizarContext from "../../../context/CotizarContext";
import AuthContext from "../../../context/AuthContext";
import {MantineMultiSelect} from "../../../components/MantineMultiSelect";
import {useIsMount} from "../../../modules/components/useIsMount";
import {useHistory} from "react-router-dom";
import {CoberturasModel as HogarCoberturasModel} from "../../Forms/Hogar/FormStages/Cotizar/Coberturas/CoberturasModel";
import {CoberturasModel as MicrosegurosCoberturasModel} from "../../Forms/Microseguros/FormStages/Cotizar/Coberturas/CoberturasModel";


function Shortcuts() {
    const shortcutArray = [CarIcon, HogarIcon, MicroIcon]
    const [shortcutTarget, setShortcutTarget] = useState(0);
    const history = useHistory();
    const isMount = useIsMount();
    const [anios, setAnios] = useState([]);
    const {autosMarcas} = useContext(CotizarContext);
    const {authToken} = useContext(AuthContext);
    const [dynamicData, setDynamicData] = useState([]);
    const [loading, setLoading] = useState(false);
    const {coberturasInfo, coberturasAdicionalesOrder} = useContext(CotizarContext);
    const [selectedValues, setSelectedValues] = useState([]);
    const {initialValues: hogarInitialValues} = HogarCoberturasModel();
    const {initialValues: microsegurosInitialValues} = MicrosegurosCoberturasModel();

    const productsMicro = coberturasAdicionalesOrder.map((cobertura) => ({
        label: coberturasInfo[cobertura].descripcion,
        value: cobertura,
        sumaChica: coberturasInfo[cobertura].sumaChica
    }));

    const tiposViviendas = [
        {value: '1021', label: 'Casa'},
        {value: '1022', label: 'Departamento'},
        {value: '1023', label: 'Vivienda en barrio cerrado'}
    ];

    const tamaniosVivienda = [
        {value: 'Chica', label: 'Pequeña (Hasta 80 mts²)'},
        {value: 'Mediana', label: 'Mediana (Hasta 150 mts²)'},
        {value: 'Grande', label: 'Grande (150 mts² o mas)'},
    ];

    useEffect(() => {
        setSelectedValues([])
    }, [shortcutTarget])

    const updateData = (option, target) => {
        if (target === 0) {
            if (option === 0) {
                setDynamicData(autosMarcas)
            } else if (option === 1) {
                setDynamicData(anios)
            }
        } else if (target === 1) {
            if (option === 0) {
                setDynamicData(tiposViviendas)
            } else if (option === 1) {
                setDynamicData(tamaniosVivienda)
            }
        } else if (target === 2) {
            if (option === 0) {
                setDynamicData(productsMicro)
            }
        }
    }

    const getAnios = async () => {
        let options = [];

        for (let i = 2024; i >= 2004; i--) {
            options.push({value: i.toString(), label: i.toString()})
        }

        setAnios(options)
    };


    const getModelos = async () => {
        setLoading(true);
        Axios.post(process.env.REACT_APP_AUTH0_AUDIENCE, {  // I cant find a way to make this query with apollo
                query: `query MyQuery {
                    General_infoAutosUltimate
                    (where: {Marca: {_eq: "${selectedValues[0].value}"}, 
                            _and: {a${selectedValues[1].value}: {_neq: 0}}}, order_by: {Modelo: asc}) {
                        Modelo
                        Codigo
                        a${selectedValues[1].value}
                      }
                    }`
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                }
            }).then((resp) => {
            let data = resp.data;
            let options = data.data["General_infoAutosUltimate"].map(d => ({
                "value": d.Codigo,
                "label": d.Modelo
            }));
            setDynamicData(options);
            setLoading(false);
        });
    };

    useEffect(() => {
        if (isMount) {      // isMount is true if it's the first render of the component
            setDynamicData(autosMarcas)
            if (anios.length === 0) {
                getAnios();
            }
        } else {
            if (selectedValues.length === 2 && shortcutTarget === 0) {
                getModelos()
            }
        }
    }, [selectedValues]);

    const sendToCotizar = (value, target) => {
        let infoFields = {}
        let segment = ""
        if (target === 0) {
            infoFields = {
                marca: value[0].value,
                anio: value[1].value,
                modelo: value[2].value,
                nombreModelo: value[2].label
            }
            segment = "automotor"
        } else if (target === 1) {
            infoFields = {
                ...hogarInitialValues,
                tipoVivienda: value[0].value,
                tamanioVivienda: value[1].value
            }
            segment = "hogar"
        } else if (target === 2) {
            microsegurosInitialValues['coberturasAdicionales'][value[0].value] = {
                sumaAsegurada: value[0].sumaChica,
                selectedCobertura: true
            }

            infoFields = microsegurosInitialValues
            segment = "microseguros"
        }
        history.push({
            pathname: `/u/cotizar/${segment}`,
            state: {
                rowData: infoFields,
                action: "shortcut"
            }
        });
    }

    return (
        <div className="Shortcuts__container">
            <div className="Shortcuts__buttonContainer">
                {shortcutArray.map((Icon, index) => (
                    <div onClick={() => {
                        setLoading(false);
                        setShortcutTarget(index)
                        setSelectedValues([])
                        updateData(0, index);
                    }} className="Shortcuts__individualContainer">
                        <RoundedRectangle
                            key={index}
                            variant="xs"
                            colorVar={index === shortcutTarget ? "active" : "desactive"}
                        >
                            <Icon className="Shortcuts__icon"/>
                        </RoundedRectangle>
                    </div>
                ))}
            </div>
            <div className="Shortcuts__selectContainer">
                {shortcutTarget === 0 &&
                <MantineMultiSelect disabled={loading} data={dynamicData} label="" placeholder="Marca - Año - Modelo"
                                    maxSelectedValues={3} onChange={(value) => {
                    setSelectedValues(value)
                    updateData(value.length, 0)
                }} action={(value) => sendToCotizar(value, 0)} value={selectedValues}/>}
                {shortcutTarget === 1 &&
                <MantineMultiSelect data={dynamicData} label="" placeholder="Tipo - Tamaño" maxSelectedValues={2}
                                    setSelectedValues={setSelectedValues} onChange={(value) => {
                    setSelectedValues(value)
                    updateData(value.length, 1)
                }} action={(value) => sendToCotizar(value, 1)} value={selectedValues}/>}
                {shortcutTarget === 2 &&
                <MantineMultiSelect data={dynamicData} label="" placeholder="Seleciona un producto"
                                    maxSelectedValues={1}
                                    setSelectedValues={setSelectedValues} onChange={(value) => {
                    setSelectedValues(value)
                    updateData(value.length, 2)
                }} action={(value) => sendToCotizar(value, 2)} value={selectedValues}/>}
            </div>
        </div>
    )

}

export default Shortcuts;