import React from 'react'
import {LandingHeader} from "../../features/Landing/Header"
import {LandingFooter} from "../../features/Landing/Footer";
import WappFab from "../../components/WappFAB/WappFAB";
import {LandingDrawer} from "../../features/Landing/Drawer";
import './PublicPage.css'

function PublicPage({children}) {
    const [drawerOpened, setDrawerOpened] = React.useState(false);
    return (
        <div className="PublicPage__container">
            <header>
                <LandingHeader
                    setDrawerOpened={setDrawerOpened}
                />
                <LandingDrawer
                    drawerOpened={drawerOpened}
                    setDrawerOpened={setDrawerOpened}
                />
            </header>
            <main>
                {children}
            </main>
            <footer>
                <LandingFooter/>
            </footer>
            <WappFab url={'https://wa.me/' + "3518687927" + '?text=¡Hola%20buenos%20días!%20Tengo%20una%20consulta'}/>
        </div>
    );
}

export default PublicPage;