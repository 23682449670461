import "./Summary.css";
import {SummaryBox} from "../../../../components/SummaryBox";
import {ReactComponent as DashIcon} from '../../../../assets/icons/ic_dash.svg';
import {PopUpCardModal} from "../../../../layouts/PopUpCardModal";
import {CardFrame} from "../../../../components/CardFrame";
import {useState} from "react";
import {format, parseISO} from "date-fns";

const Summary = (props) => {
    const {summaryItems, beneficiosDisponibles} = props;
    const [beneficiosPopUpOpen, setBeneficiosPopUpOpen] = useState(false);

    return (
        <>
            <div>
                <div className={`Summary__container ${summaryItems.length < 3 ? 'compact' : ''}`}>
                    {summaryItems.map((item, index) => {
                        return (
                            <SummaryBox
                                key={index}
                                name={item.name}
                                value={!item.value ? <DashIcon className="Summary__DashIcon" /> : item.value}
                                clickable={item.clickable}
                                onClick={() => setBeneficiosPopUpOpen(true)}
                            />
                        );
                    })}
                </div>
            </div>
            {beneficiosPopUpOpen &&
                <PopUpCardModal
                    title="Campañas disponibles"
                    body={
                        <div className='Summary__beneficios--container'>
                            {beneficiosDisponibles.map((item, index) => {
                                return (
                                    <CardFrame variant={'filled'}>
                                        <div className='Summary__beneficios--item'>
                                            <div className='Summary__beneficios--item-title'>
                                                {item.titulo}
                                            </div>
                                            <div className="Summary__beneficios--item-body" dangerouslySetInnerHTML={{__html: item.descripcion}}/>
                                            <div className="Summary__beneficios--item-footer">
                                                Vigencia hasta: {format(parseISO(item.fecha_finalizacion), 'dd/MM/yyyy')}
                                            </div>
                                        </div>
                                    </CardFrame>
                                );
                            })}
                        </div>
                    }
                    closeAction={() => setBeneficiosPopUpOpen(false)} />
            }
        </>
    );
};

export default Summary;
