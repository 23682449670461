import React, {useContext} from 'react';
import {useFormikContext} from "formik";
import {FormaPagoModel} from "./FormaPagoModel";
import {MantineSelectField} from "../../../../../../components/FormFields/MantineSelectField";
import CotizarContext from "../../../../../../context/CotizarContext";
import "./FormaPagoForm.css";
import {RoundedPanel} from "../../../../../../components/RoundedPanel";
import nextStepsText from "./nextStepsText";

export default function FormaPagoForm() {
    const {formField} = FormaPagoModel();
    const {infoDePago} = formField;

    const {companiesDict, genericOptionsFormasDePago} = useContext(CotizarContext);
    const {values} = useFormikContext();
    const formasDePago = values.company ? companiesDict[values.company].codigoFormasDePago : {};
    const filteredOptions = genericOptionsFormasDePago.filter(option =>
        formasDePago.hasOwnProperty(option.value)
    );
    const methodText = nextStepsText(values.infoDePago, "mid");

    return (
        <div className="asistenciaViajero__formaPagoForm__Container">
            <MantineSelectField
                name={infoDePago.name}
                label={infoDePago.label}
                data={filteredOptions}
                withAsterisk
            />

            {values.infoDePago &&
                <RoundedPanel color="base-light">
                    {methodText}
                </RoundedPanel>
            }

        </div>
    );
}