import React, {useContext, useMemo, useState} from 'react';
import {useFormikContext} from "formik";
import Tabs from "../../../../../../components/Tabs/Tabs";
import {QuotationResponseTab} from "../../../../../Cotizacion/QuotationResponseTab";
import {useCurrentQuotationContext} from "../../../../../../context/CurrentQuotationContext/CurrentQuotationContext";
import CotizarContext from "../../../../../../context/CotizarContext";
import UserContext from "../../../../../../context/UserContext";
import {ReactComponent as InformationFilledIcon} from "../../../../../../assets/icons/ic_info-filled.svg";
import {PopUpCardModal} from "../../../../../../layouts/PopUpCardModal";
import CarouselTabs from "../../../../../../components/CarouselTabs/CarouselTabs";
import {getTabsInfo} from "./utils/getTabsInfo";
import RequestClientDniModal from "./utils/RequestClientDniModal";
import DiscountNewClientPopup from "./utils/DiscountNewClientPopup";
import AutomotorContext from "../../../../../../context/Products/AutomotorContext/AutomotorContext";
import {isResponseSuccessful} from "../../../../../../modules/utils/isResponseSuccessful";
import { getBenefitsList } from "./utils/getBenefitsList";
import EmissionException from "./utils/EmissionException";
import requestException from "../../../../../Cotizacion/QuotationResponseTab/utils/requestException";

function CotizarSuccessView(props) {
    const {submitForm} = props;
    const {
        addCoverageToCart,
        finalValues,
        setFinalValues,
        cotizacionResponse
    } = useCurrentQuotationContext();
    const {companiesDict} = useContext(CotizarContext)
    const {userCompanies, isMobile, userName, userLastName} = useContext(UserContext);
    const {vehicleDetails} = useContext(AutomotorContext)
    const {values, setFieldValue} = useFormikContext();
    const [displayConfiguracionAvanzada, setDisplayConfiguracionAvanzada] = useState(false);
    const [companyForModal, setcompanyForModal] = useState("");
    const [showDiscountNewClientPopup, setShowDiscountNewClientPopup] = useState(false);
    const [requestClientDni, setRequestClientDni] = useState(false);
    const [emissionException, setEmissionException] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const benefits = useMemo(() => {
        return getBenefitsList(companyForModal);
    }, [companyForModal])


    function onEmitirClick(company, coverage) {
        switch (company) {
            case 1:
            case 2:
            case 4:
                return false;
            case 3:
                if (coverage?.parametrosAdicionales?.outStandard) {
                    setEmissionException({...coverage, coverageName: coverage.nombre, coveragePremium: parseInt(coverage.premium), discount: values.descuentos.Zurich});
                    return true;
                }
                else {
                    return false
                }
            case 5:
                if (finalValues.configuracionAvanzada.Federacion_Patronal.applyDiscount && !finalValues.configuracionAvanzada.Federacion_Patronal.dniCliente) {
                    setRequestClientDni(true);
                    return true;
                }
                else {
                    if (!finalValues.configuracionAvanzada.Federacion_Patronal.applyDiscount && finalValues.configuracionAvanzada.Federacion_Patronal.dniCliente) {
                        setFieldValue('configuracionAvanzada.Federacion_Patronal.dniCliente', "");
                        setFinalValues(prev => ({...prev, configuracionAvanzada: {...prev.configuracionAvanzada, Federacion_Patronal: {...prev.configuracionAvanzada.Federacion_Patronal, dniCliente: ""}}}));
                    }
                    return false;
                }
        }
    }

    const tabHandlers = {
        displayConfiguracionAvanzada,
        setDisplayConfiguracionAvanzada,
        submitForm,
        addCoverageToCart,
        showPopup: setShowDiscountNewClientPopup,
        onEmitirClick
    }
    const {
        tabs,
        tabContent
    } = getTabsInfo(values.companies, userCompanies, companiesDict, activeTab, setActiveTab, setcompanyForModal, tabHandlers)
    const initialTab = useMemo(() => {
        if (cotizacionResponse) {
            const index = values.companies.findIndex(company => isResponseSuccessful(cotizacionResponse[companiesDict[company]['name']]?.status_code))
            setActiveTab(index)
            return index;
        }
        else return null;
    }, [cotizacionResponse, values.companies, companiesDict])

    const handleZurichException = () => {
        requestException("emitir", values?.vehiculo, emissionException, {userName, userLastName})
    }

    return (<div className="automotor__formStep__container">
            <p className="automotor__cotSuccess__title">{vehicleDetails.current.detalle_marca} {vehicleDetails.current.detalle_version} - {values['vehiculo']['anio']}</p>
            <div className="automotor__cotSuccess__container">
                {!isMobile ?
                    <Tabs
                        initialTab={initialTab}
                        tabs={
                            values.companies.map((company, index) => {
                                const isPromotional = !userCompanies.includes(companiesDict[company].name)
                                return (
                                    <div className="automotor__cotSuccess__tabLogoContainer"
                                         onClick={() => setActiveTab(index)}>
                                        <img
                                            className={`automotor__cotSuccess__tabLogo ${index !== activeTab && "automotor__cotSuccess__tabLogoContainer--blur"}`}
                                            src={companiesDict[company]['logo']}
                                            alt={companiesDict[company]['name']}/>
                                        {isPromotional && <InformationFilledIcon
                                            className="automotor__cotSuccess__promotionalMoreInfo"
                                            onClick={() => setcompanyForModal(companiesDict[company].razonSocial)}/>}
                                    </div>
                                )
                            })
                        }
                        tabContent={
                            values.companies.map(company => (
                                <QuotationResponseTab
                                    displayConfiguracionAvanzada={displayConfiguracionAvanzada}
                                    setDisplayConfiguracionAvanzada={setDisplayConfiguracionAvanzada}
                                    submitForm={submitForm}
                                    addCoverageToCart={addCoverageToCart}
                                    company={company}
                                    showPopup={setShowDiscountNewClientPopup}
                                    onEmitirClick={(coverage) => onEmitirClick(company, coverage)}
                                />
                            ))
                        }
                        tabType="square"
                        blurInactiveTabs
                    />
                    :
                    <CarouselTabs tabType={"square"} tabs={tabs} tabContent={tabContent} slideSize={"8rem"}/>
                }
            </div>
            {companyForModal && <PopUpCardModal title={<h1 className="csm__title">{companyForModal}</h1>}
                                                body={<div className="csm__body">
                                                    <p className="csm__text">
                                                        <p className="csm__initialText">Disfrute la experiencia de
                                                            cotizar su producto con {companyForModal}.<br/>
                                                            {companyForModal !== 'Río Uruguay' && "La compañía cuenta con los siguientes beneficios:"}
                                                        </p><br/>
                                                        <ul className="csm__listContainer">
                                                            {benefits.map((benefit, index) => (
                                                                <li key={index}>
                                                                    <strong>{benefit.title}:</strong> {benefit.description}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </p>
                                                </div>}
                                                closeAction={() => setcompanyForModal("")}/>}

            {showDiscountNewClientPopup &&
                <DiscountNewClientPopup onClose={() => setShowDiscountNewClientPopup(false)}/>}

            {requestClientDni &&
                <RequestClientDniModal submitForm={submitForm} setRequestClientDni={setRequestClientDni}/>}

            {emissionException &&
                <EmissionException submitForm={submitForm} onClose={() => setEmissionException(false)} onZurichException={handleZurichException}/>}
        </div>
    );
}

export default CotizarSuccessView;