import {format, isValid, parse} from "date-fns";

function parseDate(value) {
    let date;

    if (typeof value === 'string') {
        date = parse(value, 'dd/MM/yyyy', new Date());
    } else if (value instanceof Date) {
        date = value;
    }

    if (!isValid(date)) {
        return '';
    }

    return format(date, 'dd/MM/yyyy');
}

export default parseDate;