import React, {useContext, useEffect, useState} from 'react';
import './AcademyCard.css';
import Button from "../Button/Button";
import {ReactComponent as DurationIcon} from "../../assets/icons/ic_time_clock.svg";
import {ReactComponent as LevelIcon} from "../../assets/icons/ic_level.svg";
import {ReactComponent as StatusIcon} from "../../assets/icons/ic_book.svg";
import userContext from "../../context/UserContext";
import {SmallLoading} from "../SmallLoading";
import {certificado} from "../../features/Academy/Multiplechoice/MChoice/Utils/certificado";
import {Skeleton} from "@mantine/core";


function AcademyCard(props) {
    const {image, imageAlt = "Imágen", title, description, infoItems, action, setError} = props;
    const status = infoItems.status;
    const {userPicture, userName, userLastName} = useContext(userContext);
    const [downloading, setDownloading] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const buttonProps = () => {
        switch (status) {
            case "Aprobado":
                return {color: "secondary", text: "Iniciar de nuevo"};
            case "En Curso":
                return {color: "yellow", text: "Continuar curso"};
            case "No Aprobado":
                return {color: "red", text: "Recursar"};
            default:
                return {color: "primary", text: "Comenzar curso"};
        }
    }
    const buttonPropsResult = buttonProps();

    function statusText(action, status) {
        if (action === "Informativo") {
            return <span className={`BCard__itemInfo BCard__itemInfo--${buttonPropsResult.color}`}>- Informativo</span>
        } else {
            if (status === "Aprobado") {
                return (
                    <span className={`BCard__itemInfo BCard__itemInfo--${buttonPropsResult.color}`}>
                        -
                        <span className={`BCard__itemInfo--clickable`}
                              onClick={() => {
                                  certificado(
                                      infoItems.platform,
                                      infoItems.title,
                                      infoItems.module,
                                      userName,
                                      userLastName,
                                      userPicture,
                                      setDownloading,
                                      setError)
                              }}
                        >
                            Certificado
                        </span>
                    </span>
                )
            }
        }
    }

    useEffect(() => {
        const img = new Image();
        img.onload = () => setIsLoading(false);
        img.src = image;
    }, [image]);

    return (
        <div className="BCard__container">
            <>
                {isLoading ? (
                    <Skeleton width="100%" height="30%"/>
                ) : (
                    <img className="BCard__image" src={image} alt={imageAlt} loading="lazy" />
                )}
            </>
            <div className="BCard__contentContainer">
                <p className="BCard__title">{title}</p>
                <p className="BCard__description" title={description}>{description}</p>
                <div className="BCard__itemsContainer">
                    {downloading ?
                        <SmallLoading/>
                        :
                        <div>
                            <p className="BCard__itemName">
                                <DurationIcon className="BCard__itemIcon"/>
                                Duración Estimada:
                                <span className="BCard__itemInfo">{infoItems.duration} minutos</span>
                            </p>
                            <p className="BCard__itemName">
                                <LevelIcon className="BCard__itemIcon"/>
                                Nivel de Dificultad:
                                <span className="BCard__itemInfo">{infoItems.level}</span>
                            </p>
                            <p className="BCard__itemName">
                                <StatusIcon className="BCard__itemIcon"/>
                                Estado:
                                <span className={`BCard__itemInfo BCard__itemInfo--${buttonPropsResult.color}`}>{infoItems.status}</span>
                                {statusText(infoItems.action, infoItems.status)}
                            </p>
                        </div>
                    }
                </div>
            </div>
            <div className="BCard__buttonContainer">
                <Button size="md" variant="filled" color={buttonPropsResult.color} enabled onClick={() => action()}>
                    {buttonPropsResult.text}
                </Button>
            </div>
        </div>
    )
        ;
}

export default AcademyCard;