import React, {useState} from 'react';
import './CarouselWrapper.css';
import {Carousel} from "@mantine/carousel";

/***
 * @returns {JSX.Element}
 * This component wraps mantine carousel to control the amount and logic of dots displayed
 * @param props
 */

function CarouselWrapper(props) {
    const {children, initialSlide=1, align="center", withDots= true, dragFree= true, slideSize, withControls= false} = props;
    const [selected, setSelected] = useState(0);

    const handleSelection = (idx) => {
        if (idx === children.length - 1) {
            setSelected(2)
        } else if (idx > 0) {
            setSelected(1)
        } else {
            setSelected(0)
        }
    }

    //TODO: handle case when there is only one slide
    const renderDots = () => {
        const dots = [];

        for (let i = 0; i < 3; i++) {
            dots.push(
                <button
                    type="button"
                    key={i}
                    className={`carouselWrapper__dot ${i === selected ? "carouselWrapper__dot--active" : ""}`}
                    onClick={() => handleSelection(i)}
                />
            );
        }

        return dots;
    };

    return (
        <>
            <Carousel
                slideGap="xl"
                slidesToScroll={1}
                controlsOffset="md"
                withControls={withControls}
                withIndicators={true}
                dragFree={dragFree}
                slideSize={slideSize}
                onSlideChange={(i) => handleSelection(i)}
                initialSlide={initialSlide}
                align={align}
                styles={{
                    indicators: {display: "none"},
                    container: {padding: "4px 0"},
                    root: {width: "100%"},
                }}
            >
                {children.map(child => <Carousel.Slide>{child}</Carousel.Slide>)}
            </Carousel>
            {withDots && <div className="carouselWrapper__dots__container">{renderDots()}</div>}
        </>
    );
}

export default CarouselWrapper;

