import React from 'react';
import "./TemplateSelect.css"
import {ImageTemplate} from "../imageTemplate";


function TemplateSelect(props) {
    const {templateId, name, highlight} = props;

    return (
        <div className="TSelect__templateContainer">
            <div className="TSelect__imageContainer TSelect__imageContainer--clickable">
                <ImageTemplate templateId={templateId} name={name} highlight={highlight}/>
            </div>
            <p className={highlight ? "TSelect__templateName--highlighted TSelect__templateName" : "TSelect__templateName"}>
                Plantilla&nbsp;
                <span className="TSelect__templateName--bold">
                        {name}
                </span>
            </p>
        </div>
    );
}

export default TemplateSelect;