import React, {useContext, useState} from 'react';
import './RedesModal.css';
import {Button} from "../../../../components/Button";
import {PopUpCardModal} from "../../../../layouts/PopUpCardModal";
import {LinkInput} from "../../DesignTab/SocialMediaStep/LinkInput";
import MasterContext from "../../../../context/MasterContext";
import AuthContext from "../../../../context/AuthContext";
import {saveConfiguration} from "../../Utils/saveConfiguration";
import {ErrorModal} from "../../../../layouts/ErrorModal";
import {linkSorter} from "../../Utils/linkSorter";

function RedesModal(props) {
    const {setOpen, values} = props;
    const [redesValues, setRedesValues] = useState(linkSorter(values['redes']));
    const {idPolkistaMaster, handleModified} = useContext(MasterContext);
    const {authToken} = useContext(AuthContext);
    const [errorContent, setErrorContent] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleUpload = async () => {
        setLoading(true);
        const data = values['row'];
        let jsonData = {
            "logo": data.logo,
            "template": data.template,
            "productos": data.productos,
            "descripcion": data.descripcion,
            "idMaster": idPolkistaMaster,
            "links": redesValues.filter(link => link !== ""),
            "idCanalDigital": data.id,
            "idPolkista": data.id_polkista,
        }


        await saveConfiguration(jsonData, authToken, true)
            .then(() => {
                handleModified();
                setOpen({modalName: "", values: null})
            })
            .catch(err => {
                setErrorContent({
                    title: "Hubo un problema al subir los valores.",
                    typeError: err.data.detail.code || "Ha ocurrido un error en la comunicación con el servidor",
                    suggestedActions: ["Reintentá el proceso una vez más", "Si el problema persiste ponete en contacto con el área de soporte"],
                    detailedError: err.data.detail.message || "Sin datos del error.",
                })
            }).finally(() => {
                setLoading(false);
            });
    }

    return (
        <>
            <PopUpCardModal
                title={"Editar las redes del polkista"}
                body={
                    <div className="redesModal__rowContainer">
                        {redesValues.map((red, index) => {
                            return (
                                <LinkInput
                                    value={red}
                                    index={index}
                                    inputValues={redesValues}
                                    setInputValues={setRedesValues}
                                />
                            )
                        })}
                    </div>
                }
                closeAction={() => setOpen({modalName: "", values: null})}
                footer={
                    <div className="redesModal__button__container">
                        <Button variant="filled"
                                color="primary"
                                marginX="sm"
                                type="button"
                                enabled
                                onClick={() => handleUpload()}
                                loading={loading}
                        >
                            Aceptar
                        </Button>
                    </div>
                }
            />
            {errorContent &&
            <ErrorModal
                closeAction={() => {
                    setErrorContent(null)
                    setLoading(false)
                }}
                title={errorContent.title}
                typeError={errorContent.typeError}
                suggestedActions={errorContent.suggestedActions}
                detailedError={errorContent.detailedError}/>
            }
        </>
    );
}

export default RedesModal;