import React, {useContext, useState} from 'react';
import './Sidebar.css';
import PolkoSecondary from "../../assets/logos/polko_secondary.png";
import {ReactComponent as ArrowDownIcon} from '../../assets/icons/ic_arrow-down.svg';
import {SidebarItem} from "../../components/SidebarItem";
import UserContext from "../../context/UserContext";

function Sidebar() {
    const {sidebarItems, sidebarOpen, setSidebarOpen} = useContext(UserContext);
    const [menuOpened, setMenuOpened] = useState(null);

    return (
        <div className={`layout__sidebar__container layout__sidebar__container--${sidebarOpen}`}>
            <div className="layout__sidebar__header">
                <div className={`layout__sidebar__logo__container layout__sidebar__logo__container--${sidebarOpen}`}>
                    <img className="layout__sidebar__logo" src={PolkoSecondary} alt="polko"/>
                </div>
                <span className="layout__sidebar__arrow__container" onClick={() => setSidebarOpen(!sidebarOpen)}>
                    <ArrowDownIcon className={`layout__sidebar__arrow layout__sidebar__arrow--${sidebarOpen}`}/>
                </span>
            </div>
            <div className={`layout__sidebar__body layout__sidebar__transition--${sidebarOpen}`}>
                {sidebarItems.bodyItems.map((item) => {
                    if (item.renderCondition) {
                        return (
                            <SidebarItem id={item.id}
                                         icon={item.icon}
                                         name={item.name}
                                         action={item.action}
                                         menuOpened={menuOpened}
                                         setMenuOpened={setMenuOpened}
                                         children={item.children}/>
                        )
                    }
                })}
            </div>
            <div className={`layout__sidebar__footer layout__sidebar__transition--${sidebarOpen}`}>
                <hr className="layout__sidebar__hr"/>
                {sidebarItems.footerItems.map((item) => <SidebarItem id={item.id}
                                                                     icon={item.icon}
                                                                     name={item.name}
                                                                     action={item.action}
                                                                     menuOpened={menuOpened}
                                                                     setMenuOpened={setMenuOpened}
                                                                     children={item.children}/>)}
            </div>
        </div>
    );
}

export default Sidebar;