import React, {useContext} from 'react';
import DashboardContextProvider from './DashboardContextProvider';
import DashboardContext from "./DashboardContext";
import ContextLoading from "../../modules/components/ContextLoading";
import {ErrorModal} from "../../layouts/ErrorModal";

/**
 * Inserts DashboardContextProvider in the react tree
 * before adding the given component.
 *
 * @param {Object} Component The component that needs the context provider.
 * @returns {Object} The component to render wrapped with the provider.
 */
const withDashboardContextProvider = (Component) => (props) => (
    <DashboardContextProvider>
        <Component {...props} />
    </DashboardContextProvider>
);

/**
 * Renders the given component when the user context is ready.
 *
 * @param {Object} Component The component to render.
 * @param {Object} Component The rendered component.
 * @returns {Object} The component to render or the LoadingPage component.
 */
const withDashboardContextReady = (Component) => (props) => {

    const {isReady, dashboardContextError, setDashboardContextError} = useContext(DashboardContext);
    if (dashboardContextError) {
        return <ErrorModal closeAction={() => setDashboardContextError(null)}
                           title={dashboardContextError.title}
                           typeError={dashboardContextError.typeError}
                           suggestedActions={dashboardContextError.suggestedActions}
                           detailedError={dashboardContextError.detailedError}/>;
    }

    return isReady ? <Component {...props} /> :
        <ContextLoading/>
};

export {
    withDashboardContextReady,
    withDashboardContextProvider,
};