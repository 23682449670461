function ErrorsDict (code, compania) {
    switch (code) {
        case 'INVALID_VEHICLE_CODE':
            return {
                message: `Vehículo no encontrado`,
                bodyText: `El vehículo no se encuentra en ${compania}.`,
                type: 'warning',
                hasDetails: false,
            }
        default:
            return {
                message: 'Hubo un problema al comunicarse con el servicio de cotización de la aseguradora.',
                type: 'error',
                hasDetails: true,
                isDefault: true,
                showErrorCode: true,
                suggestedActions: ["Revisá que la información ingresada sea correcta", "Evitá incluir caracteres especiales", "Reintente el proceso"]
            }
    }
}

export default ErrorsDict;