import Axios from "axios";

export const enviarPuntaje = async (idCurso, authToken) => {
    Axios.post(process.env.REACT_APP_AUTH0_AUDIENCE, {
            query: `mutation MyMutation {
                update_Cursos_Polkistas_x_Cursos(_set: {Estado: "Aprobado"}, where: {id_curso: {_eq: ${idCurso} }}) {
                returning {
                Estado
            }
        }
            }`
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            }
        })
};