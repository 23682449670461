import React, {useContext, useState} from 'react';
import './CoveragesOverviewTable.css';
import {CoverageTableHeader} from "../../../components/CoverageTableHeader";
import {ReactComponent as IconCheck} from "../../../assets/icons/ic_check.svg";
import {ReactComponent as IconCross} from "../../../assets/icons/ic_close.svg";
import {currencyFormat} from "../../../modules/utils/currencyFormat";
import {RadioButton} from "../../../components/RadioButton";
import UserContext from "../../../context/UserContext";
import useWindowDimensions from "../../../modules/utils/useWindowDimensions";
import CotizarContext from "../../../context/CotizarContext";
import {Popover} from "@mantine/core";
import isPercentage from "../../../modules/utils/isPercentage";

const dropdownAPD = (_displayText) => {
    return <div className="ctrow__popover__dropdown">
        <p className="ctrow__text">
            {_displayText}.
        </p>
    </div>
}

const createPopover = (_popoverDropdown, _open, _setOpen, _target) => (
    <Popover width={112} withArrow arrowPosition="side" position="bottom-end" shadow="0 2px 2px rgba(0, 0, 0, 0.25)"
             opened={_open} onChange={_setOpen} onClick={() => _setOpen(!_open)}
             classNames={{dropdown: "automotor__cotSuccess__popover"}}>
        <Popover.Target>
            {_target}
        </Popover.Target>
        <Popover.Dropdown>
            {_popoverDropdown}
        </Popover.Dropdown>
    </Popover>
);

function CoveragesOverviewTable(props) {
    const {
        title,
        displayFooter,
        nombreModelo,
        detallesCobertura,
        changeSelectedId,
        selectedId,
        changeHighlightedId,
        highlightedId
    } = props;
    const {companiesDict, coberturasProductsInfo} = useContext(CotizarContext);
    const {width} = useWindowDimensions();
    const {isMobile, activeFormProduct} = useContext(UserContext);

    const [popoverAPDOpen, setPopoverAPDOpen] = useState(false);

    const displayAPDTooltip = (_fullValue) => {
        const regex = /(\d+)%/;
        const match = _fullValue.match(regex);
        if (match && match.length > 1) {
            const porcentaje = match[1];
            return createPopover(
                dropdownAPD(_fullValue),
                popoverAPDOpen,
                setPopoverAPDOpen,
                <p className="cotable__APD__cell">{porcentaje + '%'}</p>
            )
        } else return _fullValue;
    }

    function renderBenefits(hasOwnBenefits) {
        if (hasOwnBenefits && !hasOwnBenefits.every(benefitList => benefitList === null)) {
            let allBenefitsCodesList = [];
            let allBenefitsList = {};
            detallesCobertura.beneficios.forEach(benefitsList => Object.keys(benefitsList).forEach(benefit => {
                    if (!allBenefitsCodesList.includes(benefit)) {
                        allBenefitsCodesList.push(benefit)
                        allBenefitsList[benefit] = benefitsList[benefit].coverage_name
                    }
                }
            ));
            return (
                allBenefitsCodesList.map((benefitCode, benefitIndex) => (
                    <>
                        <tr className="cotable__row" key={benefitIndex}>
                            <td className="cotable__column--first sticky-col">{allBenefitsList[benefitCode]}</td>
                            {detallesCobertura['companies'].map((company, idx) => {
                                return <td
                                    className="cotable__column">{detallesCobertura.beneficios[idx][benefitCode] ? detallesCobertura.beneficios[idx][benefitCode]['coverage_val'] : 'No incluido'}</td>
                            })}
                        </tr>
                    </>
                )))
        } else {
            {
                return (
                    Object.keys(coberturasProductsInfo[activeFormProduct]['names']).map(keyName => {
                        return (<tr className="cotable__row">
                            <td className="cotable__column--first sticky-col">{coberturasProductsInfo[activeFormProduct]['names'][keyName]}</td>

                            {detallesCobertura['companies'].map((company, idx) => {
                                try {
                                    const companyDetails = companiesDict[company];
                                    const keyValue = coberturasProductsInfo[activeFormProduct][companyDetails?.['name']][detallesCobertura['plans'][idx].toString()]['detalle'][keyName] ?? {};

                                    if (keyName === "APD") {
                                        if (!keyValue) {
                                            return (
                                                <td className="cotable__column">
                                                    <IconCross className="cotable__icon cotable__icon--cross"/>
                                                </td>
                                            )
                                        } else if (isPercentage(keyValue)) {
                                            return <td
                                                className="cotable__column">{keyValue}</td>
                                        } else if (typeof keyValue === "string") {
                                            return <td
                                                className="cotable__column">{displayAPDTooltip(detallesCobertura.franquicia[idx])}</td>
                                        } else if (detallesCobertura['franquicia'][idx] && detallesCobertura['franquicia'][idx] > 0) {
                                            return (
                                                <td className="cotable__column">
                                                    {`$ ${currencyFormat(detallesCobertura['franquicia'][idx])}`}
                                                </td>
                                            )
                                        }
                                    }

                                    if (typeof keyValue === "boolean") {
                                        return (
                                            <td className="cotable__column">
                                                {keyValue ?
                                                    <IconCheck className="cotable__icon cotable__icon--check"/> :
                                                    <IconCross className="cotable__icon cotable__icon--cross"/>}
                                            </td>
                                        )
                                    } else if (typeof keyValue === "string") {
                                        return <td className="cotable__column">{keyValue}</td>
                                    } else {
                                        return <td
                                            className="cotable__column">{keyValue ? `$ ${currencyFormat(keyValue)}` : "-"}</td>
                                    }
                                } catch (error) {
                                    console.error("Error while rendering table", error);
                                    return <td className="cotable__column">Error</td>;
                                }
                            })}
                        </tr>)
                    })
                )
            }
        }
    }

    return (
        <div className="cotable__view" style={isMobile ? {width: `${width * 0.85 - 32 - 32}px`} : {}}>
            <div className="cotable_wrapper">
                <table>
                    <thead className="cotable__structure">

                    <tr>
                        <th className="cotable__column--first sticky-col">
                            <div className="cotable__title__container">
                                <p className="cotable__title">{title}</p>
                                <p className="cotable__model">{nombreModelo}</p>
                            </div>
                        </th>

                        {detallesCobertura['companies'].map((company, idx) => (
                            <th className="cotable__column">
                                <CoverageTableHeader
                                    id={detallesCobertura['plans'][idx]}
                                    companyName={companiesDict[company]['name']}
                                    companyLogo={companiesDict[company]['logo']}
                                    coverage={detallesCobertura['nombreCobertura'][idx]}
                                    premium={detallesCobertura['actualPremium'][idx]}
                                />
                            </th>
                        ))}

                    </tr>
                    </thead>

                    <tbody className={isMobile ? "cotable__structure" : "cotable__structure cotable__body"}>


                    {renderBenefits(detallesCobertura['beneficios'])}

                    </tbody>

                    {displayFooter &&
                        <tfoot className="cotable__structure">

                        <tr className="cotable__row">
                            <td className="cotable__column--first sticky-col">
                                <p className="cotable__footer__text">Destacá una cobertura</p>
                            </td>
                            {detallesCobertura['companies'].map((company, idx) => (
                                <td className="cotable__column">
                                    <div className="cotable__radioButton__container">
                                        <RadioButton id={idx} callback={changeHighlightedId}
                                                     selected={highlightedId === idx}
                                                     icon="STAR"/>
                                    </div>
                                </td>
                            ))}
                        </tr>
                        <tr className="cotable__row">
                            <td className="cotable__column--first sticky-col">
                                <p className="cotable__footer__text">Seleccioná tu cobertura</p>
                            </td>
                            {detallesCobertura['companies'].map((company, idx) => (
                                <td className="cotable__column">
                                    <div className="cotable__radioButton__container">
                                        <RadioButton id={idx} callback={changeSelectedId}
                                                     selected={selectedId === idx}/>
                                    </div>
                                </td>
                            ))}
                        </tr>

                        </tfoot>
                    }

                </table>
            </div>
        </div>
    );
}

export default CoveragesOverviewTable;