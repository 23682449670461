import React, {useContext, useEffect} from 'react';
import './CoverageTableRow.css'
import {ReactComponent as PlusFilledIcon} from "../../assets/icons/ic_plus-filled.svg";
import {ReactComponent as InformationFilledIcon} from "../../assets/icons/ic_info-filled.svg";
import {useFormikContext} from "formik";
import {Button} from "../Button";
import {IconMinus} from '@tabler/icons';
import {useCurrentQuotationContext} from "../../context/CurrentQuotationContext/CurrentQuotationContext";
import CotizarContext from "../../context/CotizarContext";
import {OutStandardIcons} from "../../features/Cotizacion/OutStandardIcons";
import {currencyFormat} from "../../modules/utils/currencyFormat";
import UserContext from "../../context/UserContext";
import requestException from "../../features/Cotizacion/QuotationResponseTab/utils/requestException";

function CoverageTableRow(props) {
    const {
        rowData,
        tab,
        tabId,
        addCoverageToCart,
        handleAddStep,
        handleOpenCoverageOverviewModal,
        coverageName
    } = props;
    const {id, premium, parametrosAdicionales} = rowData;
    let identifier = id;
    if (rowData.compania === "Zurich") {
        identifier = parametrosAdicionales.codZurich || id
    }
    const {values} = useFormikContext();
    const {cartCoverages, updateCartCoverages} = useCurrentQuotationContext();
    const {companiesDict} = useContext(CotizarContext);
    const {userCompanies, userName, userLastName} = useContext(UserContext);
    const selectedDiscountForCompany = values['descuentos'] ? values['descuentos'][companiesDict[tabId]['name']] : 0
    const cartCoveragesIds = cartCoverages.map(cov => cov.id);
    let descuento = values['descuentos'] ? values['descuentos'][tab] : 0;
    if ((rowData.compania === "RUS" || tab === "RUS") && descuento === 15) descuento = 14.7;
    const companyDiscountType = companiesDict[tab].descuento;
    const coveragePremium = companyDiscountType === "prima" || parametrosAdicionales?.outStandardSancor === "true" ? parseInt(premium) :
        Math.ceil(premium * (1 - descuento / 100));
    const isPromotional = !userCompanies.includes(tab)

    //Changes here must be replicated into same object of CoverageCard
    const dataObjectRequired = {
        company: tabId,
        companyName: companiesDict[tabId]['name'],
        ...rowData,
        discount: selectedDiscountForCompany,
        premium: premium,
        actualPremium: coveragePremium,
    };

    useEffect(() => {
        updateCartCoverages(rowData.id, coveragePremium);
    }, [values.descuentos]);

    const handleOutStandard = (type) => {
        requestException(type, values?.vehiculo, {...rowData, coverageName, coveragePremium, discount: values.descuentos.Zurich}, {userName, userLastName})
    };

    return (
        <div className="ctrow__container">
            <div className="ctrow__icon__container"
                 onClick={() => addCoverageToCart(dataObjectRequired)}
            >
                {cartCoveragesIds.includes(id)
                    ? <IconMinus className="ctrow__icon__minus"/>
                    : <PlusFilledIcon className="ctrow__icon__plus"/>
                }
            </div>

            <p className="ctrow__text">{identifier}</p>
            <p className="ctrow__text">{coverageName}</p>

            <div className="ctrow__fueraPauta__container">
                {parametrosAdicionales &&
                    <OutStandardIcons parametrosAdicionales={parametrosAdicionales} companyName={rowData.compania} onOutStandard={handleOutStandard}/>
                }
            </div>

            <p className="ctrow__text">
                ${currencyFormat(coveragePremium)}
            </p>

            <div className="ctrow__actions__container">
                <div className="ctrow__info__container"
                     onClick={() => handleOpenCoverageOverviewModal(dataObjectRequired)}
                >
                    <InformationFilledIcon className="ctrow__icon__info"/>
                </div>


                {!isPromotional &&
                    <Button
                        type="button"
                        variant="filled"
                        color="primary"
                        enabled
                        marginX="sm"
                        size="md"
                        onClick={() => handleAddStep(tabId, rowData)}
                    >
                        Emitir
                    </Button>
                }
            </div>
        </div>
    );
}

export default CoverageTableRow;