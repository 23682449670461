import React from 'react';
import MaterialLayout from '../../components/Layout/MaterialLayout';
import withRoot from "../../withRoot";
import CheckoutEmision from "./Checkout";
import {useMediaQuery} from "@material-ui/core";


function Emision(props) {
    const isMobile = useMediaQuery('(max-width:480px)');
    return (
        <div>
            <MaterialLayout>
                <CheckoutEmision
                    continueCotizacion={true}
                    isMobile={isMobile}
                    additionalStep={props.location.state.emisionValues.additionalStep}
                    emisionValues={props.location.state.emisionValues}
                />
            </MaterialLayout>
        </div>
    )
}

export default withRoot(Emision);
