import React, {useContext, useEffect, useState} from 'react';
import './ActionsColumn.css';
import {Button} from "../../../../components/Button";
import {Switch} from '@mantine/core';
import {gql, useMutation} from "@apollo/client";
import MasterContext from "../../../../context/MasterContext";
import showNotification from "../../../../modules/utils/showNotification";
import SmallLoading from "../../../../modules/components/SmallLoading";
import UserContext from "../../../../context/UserContext";

const ENABLE_DISABLE_CD = gql`
    mutation updateCanalDigital($id: Int, $Habilitado: Boolean) {
      update_CanalDigital_PaginasPolkistas(where: {id: {_eq: $id}}, _set: {habilitada: $Habilitado}) {
        affected_rows
      }
    }
`;

function ActionsColumn(props) {
    const {row, setEditRow, setActiveTab} = props;
    const {handleModified, isModifiedReady} = useContext(MasterContext);
    const [enableDisableCD] = useMutation(ENABLE_DISABLE_CD);
    const [loading, setLoading] = useState(false);
    const {isMobile} = useContext(UserContext);

    const handleEditCondition = (checked) => {
        setLoading(true)
        enableDisableCD({
            context: {
                headers: {
                    "X-Hasura-Role": "master"
                }
            },
            variables: {
                id: row['id'],
                Habilitado: checked
            }
        }).then(() => {
            handleModified()
            showNotification({
                autoClose: 3000,
                color: 'green',
                status: 'success',
                title: 'Operación exitosa.',
                message: `El canal digital ha sido ${checked ? 'habilitado' : 'deshabilitado'}.`
            });
        }).catch((error) => {
            showNotification({
                autoClose: 4000,
                color: 'red',
                status: 'error',
                title: 'Hubo un error al modificar la condición.',
                message: `Detalles: ${error}`,
            });
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleEditButton = () => {
        setEditRow(row)
        setActiveTab(1)
    }

    useEffect(() => {
        if (isModifiedReady)
            setLoading(false)
    }, [isModifiedReady]);

    if (isMobile) {
        return (
            <div className="actionsColumn__container">
                <Button loading={loading} enabled variant="filled"
                        size="md"
                        color={row['habilitada'] ? "tertiary--pink" : "secondary"}
                        onClick={() => {
                            handleEditCondition(!row['habilitada'])
                        }}
                        className="actionsColumn__switch"
                >
                    {row['habilitada'] ? "Desactivar" : "Habilitar"}
                </Button>
                <Button variant="filled"
                        color="primary"
                        enabled
                        size="md"
                        type="button"
                        onClick={() => {
                            handleEditButton()
                        }}>
                    Editar Web
                </Button>
            </div>)
    }


    return (
        <div className="actionsColumn__container">
            <Button variant="filled"
                    color="primary"
                    size="sm"
                    enabled
                    type="button"
                    onClick={() => {
                        handleEditButton()
                    }}>
                Editar
            </Button>
            <div className="actionsColumn__switch__container">
                {loading
                    ? <SmallLoading width={40} height={40}/>
                    : <Switch checked={row['habilitada']}
                              onChange={(e) => {
                                  handleEditCondition(e.target.checked)
                              }}
                              color="teal"
                              className="actionsColumn__switch"
                    />
                }
            </div>
        </div>
    );
}

export default ActionsColumn;