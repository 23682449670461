import React from 'react';
import "./Progress.css"

function Progress(props) {
    const {current, total} = props;
    return (

        <h2 className="AProgress__questionNumber">
            Pregunta {current} de {total}
        </h2>
    );
}

export default Progress;