import React, {useState} from 'react';
import {useField} from 'formik';
import {MonthPickerInput} from '@mantine/dates';
import moment from "moment";
import {ReactComponent as ExclamationIcon} from "../../../assets/icons/ic_exclamation.svg";
import {ReactComponent as CalendarIcon} from "../../../assets/icons/ic_calendar.svg";

const MantineMonthField = (props) => {
    const {label, name} = props;
    const [field, meta, helpers] = useField(name);
    const [value, setValue] = useState(field.value === "" ? null : moment(field.value, 'YYYY-MM').toDate());
    const {setValue: setFieldValue, setTouched} = helpers;

    return (
        <MonthPickerInput
            {...props}
            label={label}
            value={value}
            onChange={(e) => setValue(moment(e, 'MM/YYYY').toDate())}
            onBlur={() => {
                setFieldValue(value)
                setTouched(name, false)
            }}
            dateParser={(dateString) => moment(dateString, 'MM/YYYY').toDate()}
            error={meta.error && meta.touched && <div>{meta.error}</div>}
            allowFreeInput
            locale="es"
            placeholder="mm/yyyy"
            inputFormat="MM/YYYY"
            valueFormat="MM/YYYY"
            classNames={{
                wrapper: 'mantine__date__wrapper',
                label: meta.error && meta.touched ? 'mantine__date__label--error' : 'mantine__date__label',
                required: meta.error && meta.touched ? 'mantine__date__required--error' : 'mantine__date__required',
                error: 'mantine__date__error',
                input: meta.error && meta.touched ? 'mantine__date__field--error' : 'mantine__date__field',
                rightSection: 'mantine__date__rightSection'
            }}
            rightSection={
                meta.error && meta.touched
                    ? <ExclamationIcon className="mantine__date__icon--error"/>
                    : <CalendarIcon className="mantine__date__icon"/>
            }
        />
    );
};

export default MantineMonthField;
