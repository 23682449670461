import * as Yup from "yup";

const moment = require('moment');

const VehiculoModel = () => {
    const formField = {
        motovehiculo: {
            marca: {
                name: 'motovehiculo.marca',
                label: 'Marca',
                requiredErrorMsg: 'Debe especificar la marca del vehículo'
            },
            version: {
                name: 'motovehiculo.version',
                label: 'Versión',
                requiredErrorMsg: 'Debe especificar la versión del vehículo'
            },
            anio: {
                name: 'motovehiculo.anio',
                label: 'Año',
                requiredErrorMsg: 'Debe especificar el año del vehículo'
            },

            esCeroKm: {
                name: 'motovehiculo.esCeroKm',
                label: '¿0 Km?'
            },
            sumaAseguradaAdicional: {
                name: 'motovehiculo.sumaAseguradaAdicional',
                label: 'Suma asegurada bienes adicionales',
                requiredErrorMsg: 'Debe especificar un valor (puede ser cero)'
            }
        },

        //CAMPOS DE CONFIGURACIÓN AVANZADA
        //Sancor
        codSancorLocalidad: {
            name: 'codSancorLocalidad',
        },
        vigencia: {
            name: 'vigencia',
            label: 'Vigencia',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        frecuenciaPago: {
            name: 'frecuenciaPago',
            label: 'Frecuencia de pago',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        cuotas: {
            name: 'cuotas',
            label: 'Cantidad de cuotas',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        ajusteAutomatico: {
            name: 'ajusteAutomatico',
            label: 'Ajuste automático',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        fechaCotizacion: {
            name: 'fechaCotizacion',
            label: 'Inicio vigencia',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        sumaAseguradaVehiculo: {
            name: 'sumaAseguradaVehiculo',
            label: 'Suma asegurada vehículo',
            requiredErrorMsg: 'Debe ingresar la suma asegurada del vehículo'
        },
        usoVehiculo: {
            name: 'usoVehiculo',
            label: 'Uso del vehículo',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        //RUS
        codRUSLocalidad: {
            name: 'codRUSLocalidad',
        },
        usoVehiculoRUS: {
            name: 'uso',
            label: 'Uso del vehículo',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        tipoVigencia: {
            name: 'tipoVigencia',
            label: 'Vigencia',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        vigenciaDesde: {
            name: 'vigenciaDesde',
            label: 'Inicio vigencia',
            requiredErrorMsg: 'Debe seleccionar una fecha'
        },
        vigenciaHasta: {
            name: 'vigenciaHasta',
            label: 'Fin vigencia',
            requiredErrorMsg: 'Debe seleccionar una fecha'
        },
        descuentos: {
            name: 'descuentos',
            label: 'Descuento',
        },
        configuracionAvanzada: {
            name: 'configuracionAvanzada',
        }
    }

    const initialValues = {
        motovehiculo: {
            marca: '',
            version: '',
            anio: '',
            esCeroKm: 'false',
            sumaAseguradaAdicional: 0,
        },

        [formField.descuentos.name]: {
            Sancor: 0,
            RUS: 0,
            Zurich: 0,
        },

        [formField.configuracionAvanzada.name]: {
            Sancor: {
                [formField.codSancorLocalidad.name]: '',
                [formField.vigencia.name]: '1',
                [formField.frecuenciaPago.name]: '5',
                [formField.cuotas.name]: '1',
                [formField.ajusteAutomatico.name]: '0',
                [formField.sumaAseguradaVehiculo.name]: 0,
                [formField.usoVehiculo.name]: '2',
                [formField.fechaCotizacion.name]: moment().format('YYYY-MM-DD'),
            },
            RUS: {
                [formField.codRUSLocalidad.name]: '',
                [formField.tipoVigencia.name]: 'SEMESTRAL',
                [formField.cuotas.name]: '1',
                [formField.ajusteAutomatico.name]: 0,
                [formField.sumaAseguradaVehiculo.name]: 0,
                [formField.vigenciaDesde.name]: moment().format('YYYY-MM-DD'),
                [formField.vigenciaHasta.name]: moment().add(1, "Y").format("YYYY-MM-DD"),
                [formField.usoVehiculoRUS.name]: 'PARTICULAR',
            },
        },
    }

    const validationSchema = Yup.object().shape({
        motovehiculo: Yup.object().shape({
            marca: Yup.string().required(`${formField.motovehiculo.marca.requiredErrorMsg}`).typeError("Ingrese una marca válida"),
            version: Yup.string().required(`${formField.motovehiculo.version.requiredErrorMsg}`).typeError("Ingrese un versión válido"),
            anio: Yup.number()
                .required(`${formField.motovehiculo.anio.requiredErrorMsg}`)
                .integer()
                .typeError("Ingrese un año válido"),
            sumaAseguradaAdicional: Yup.number().required(`${formField.motovehiculo.sumaAseguradaAdicional.requiredErrorMsg}`)
        })
    })

    return {formField, initialValues, validationSchema}
}

export {VehiculoModel};
