import React from 'react';
import'./CoveragePopoverItem.css'
import {ReactComponent as TrashIcon} from "../../assets/icons/ic_trash.svg";
import {currencyFormat} from "../../modules/utils/currencyFormat";

function CoveragePopoverItem(props) {
    const {id, companyImg, premium, removeCoverageFromCart, coverageName} = props;

    return (
        <div className="coPopoverItem__container">
            <img className="coPopoverItem__image" src={companyImg} alt="company"/>
            <p className="coPopoverItem__text">{coverageName}</p>
            <div className="coPopoverItem__premium__container">
                <p className="coPopoverItem__premium">$ {currencyFormat(premium)}</p>
            </div>
            <div onClick={() => removeCoverageFromCart(id)} className="coPopoverItem__icon__container">
                <TrashIcon className="coPopoverItem__icon"/>
            </div>
        </div>
    );
}

export default CoveragePopoverItem;