
function capitalizeString(string) {

    string = string.toLowerCase()
    const arr = string.split(" ");
    for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(" ");

    return str2
}

function toARS(v) {
    let result = v.toLocaleString('es-ar', {
        style: 'currency',
        currency: 'ARS',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
    });
    return result
}

export {toARS, capitalizeString};