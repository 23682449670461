import { useState, useLayoutEffect } from 'react';

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useLayoutEffect(() => {
        const updateWindowWidth = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', updateWindowWidth);

        updateWindowWidth();

        return () => {
            window.removeEventListener('resize', updateWindowWidth);
        };
    }, []);

    return windowWidth;
};

export default useWindowWidth;