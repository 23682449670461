export default {
    formId: 'emisionForm',
    formField: {
        vigenciaDesde: {
            name: 'vigenciaDesde',
            label: 'Vigencia Desde *',
            requiredErrorMsg: 'Debe especificar una fecha'
        },
        vigenciaHasta: {
            name: 'vigenciaHasta',
            label: 'Vigencia Hasta *',
            requiredErrorMsg: 'Debe especificar una fecha'
        },
        tipoVivienda: {
            name: 'tipoVivienda',
            label: 'Tipo de vivienda *',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        frecuenciaPago: {
            name: 'frecuenciaPago',
            label: 'Vigencia *',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        formaDePago: {
            name: 'formaDePago',
            label: 'Forma De Pago *',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        infoDePago:{
            numeroCbu: {
                name: 'numeroCbu',
                label: 'Número de CBU *',
                requiredErrorMsg: 'Debe especificar un número de CBU'
            },
            marcaTarjeta: {
                name: 'marcaTarjeta',
                label: 'Marca de la tarjeta *',
                requiredErrorMsg: 'Debe seleccionar una opción'
            },
            numeroTarjeta: {
                name: 'numeroTarjeta',
                label: 'Número de la tarjeta *',
                requiredErrorMsg: 'Debe especificar un número de tarjeta'
            },
        },
        comentariosSolicitud: {
            name: 'comentariosSolicitud',
            label: 'Comentarios de la solicitud',
            requiredErrorMsg: ''
        },
        descuentoEspecial: {
            name: 'descuentoEspecial',
            label: 'Descuento especial',
            requiredErrorMsg: ''
        },
        cliente: {
            rol: {
                name: 'rol',
                label: 'Rol *',
                requiredErrorMsg: 'Debe seleccionar un rol'
            },
            personaJuridica: {
                name: 'personaJuridica',
                label: 'Tipo Persona',
                requiredErrorMsg: 'Debe seleccionar una opción'
            },
            dni: {
                name: 'dni',
                label: 'DNI *',
                requiredErrorMsg: 'Debe especificar este campo'
            },
            cuit: {
                name: 'cuit',
                label: 'CUIT/CUIL *',
                requiredErrorMsg: 'Debe especificar el CUIT/CUIL'
            },
            nombre: {
                name: 'nombre',
                label: 'Nombre*',
                requiredErrorMsg: 'Debe especificar este campo'
            },
            apellido: {
                name: 'apellido',
                label: 'Apellido *',
                requiredErrorMsg: 'Debe especificar este campo'
            },
            fechaNacimiento: {
                name: 'fechaNacimiento',
                label: 'Fecha de nacimiento *',
                requiredErrorMsg: 'Debe especificar la fecha de nacimiento'
            },
            estadoCivil: {
                name: 'estadoCivil',
                label: 'Estado civil *',
                requiredErrorMsg: 'Debe seleccionar un estado civil'
            },
            sexo: {
                name: 'sexo',
                label: 'Sexo *',
                requiredErrorMsg: 'Debe seleccionar un sexo'
            },
            domicilio: {
                name: 'domicilio',
                label: 'Dirección de domicilio *',
                requiredErrorMsg: 'Debe especificar una dirección de domicilio'
            },
            codigosLocalidad: {
                name: 'codigosLocalidad',
                label: 'Código postal / Localidad',
                requiredErrorMsg: 'Debe seleccionar una opción'
            },
            codigoArea: {
                name: 'codigoArea',
                label: 'Código de área *',
                requiredErrorMsg: 'Debe especificar el código de área'
            },
            telefono: {
                name: 'telefono',
                label: 'Celular *',
                requiredErrorMsg: 'Debe especificar un celular'
            },
            email: {
                name: 'email',
                label: 'Email *',
                requiredErrorMsg: 'Debe especificar un email'
            },
            situacionIva: {
                name: 'situacionIva',
                label: 'Situación ante el IVA *',
                requiredErrorMsg: 'Debe seleccionar una opción'
            },
            categoriaMonotributo: {
                name: 'categoriaMonotributo',
                label: 'Categoría de monotributo *',
                requiredErrorMsg: 'Debe seleccionar una opción'
            },
            actividadPrincipal: {
                name: 'actividadPrincipal',
                label: 'Actividad principal (CIIU de AFIP) *',
                requiredErrorMsg: 'Debe seleccionar una opción'
            },
            lugarNacimiento: {
                name: 'lugarNacimiento',
                label: 'Lugar de nacimiento *',
                requiredErrorMsg: 'Debe especificar un lugar de nacimiento'
            }
        },
        cobertura: {
            sumaAsegurada: {
                name: 'sumaAsegurada',
                label: 'Suma asegurada',
                requiredErrorMsg: 'Debe completar este campo'
            },
            numeroCobertura: {
                name: 'numeroCobertura',
                label: 'Número cobertura',
                requiredErrorMsg: 'Debe completar este campo'
            },
        },
        detalleEquipoElectronico: {
            descripcionElectronico: {
                name: 'descripcionElectronico',
                label: 'Descripción',
                requiredErrorMsg: 'Debe completar este campo'
            },
            sumaAseguradaElectronico: {
                name: 'sumaAseguradaElectronico',
                label: 'Suma Asegurada',
                requiredErrorMsg: 'Debe completar este campo'
            },
            codigoElectronico: {
                name: 'codigoElectronico',
            },
            codigoGrupoElectronico: {
                name: 'codigoGrupoElectronico',
            },
        },
        detalleBicicletas: {
            descripcionBicicletas: {
                name: 'descripcionBicicletas',
                label: 'Descripción',
                requiredErrorMsg: 'Debe completar este campo'
            },
            sumaAseguradaBicicletas: {
                name: 'sumaAseguradaBicicletas',
                label: 'Suma Asegurada',
                requiredErrorMsg: 'Debe completar este campo'
            },
            codigoBicicletas: {
                name: 'codigoBicicletas',
            },
            codigoGrupoBicicletas: {
                name: 'codigoGrupoBicicletas',
            }
        },
    }
};
