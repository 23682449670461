import React, {useContext, useState} from 'react';
import {useCurrentQuotationContext} from "../../../../../../context/CurrentQuotationContext/CurrentQuotationContext";
import {Divider} from "@mantine/core";
import {currencyFormat} from "../../../../../../modules/utils/currencyFormat";
import CotizarContext from "../../../../../../context/CotizarContext";
import {Loading} from "../../../../../../components/Loading"
import {Button} from "../../../../../../components/Button";
import UserContext from "../../../../../../context/UserContext";
import axios from "axios";
import FileDownload from "js-file-download";
import moment from "moment";
import {IconFirstAidKit, IconPhone, IconPlaneDeparture} from "@tabler/icons";

const productsByKey = {
    26: "Objetos varios",
    36: "Notebook",
    37: "Tablet",
    48: "Bicicleta y/o monopatín"
}

const coberturasSinCostoIcons = {
    44: <IconPlaneDeparture className="microsegurosCot__icon--noCost"/>,
    45: <IconFirstAidKit className="microsegurosCot__icon--noCost"/>,
    50: <IconPhone className="microsegurosCot__icon--noCost"/>,
}

function CotizarSuccessView() {
    const {
        cotizacionResponse,
        finalValues
    } = useCurrentQuotationContext();
    const {
        userIdAuth,
        userName,
        userLastName,
        userMail,
        userProvince,
        userLocalidad,
        userPicture,
        userPhone,
        userMatricula,
        masterMatricula,
        userWebHabilitado,
        userLink
    } = useContext(UserContext);
    const {coberturasInfo, companiesDict, coberturasAdicionalesOrder} = useContext(CotizarContext);
    const [isDownloading, setIsDownloading] = useState(false);

    const handleDownloadPDF = () => {
        setIsDownloading(true);
        const viviendasDict = {
            '1021': 'Casa de ocupación permanente',
            '1022': 'Departamento de ocupación permanente',
            '1023': 'Country de ocupación permanente',
            '1024': 'Vivienda/Departamento de ocupación transitoria',
        };

        let coberturasPDF = Object.entries(finalValues['coberturasAdicionales'])
                .reduce((acc, [key, value]) => {
                    acc[key] = value.selectedCobertura ? currencyFormat(value.sumaAsegurada) : 0;
                    return acc;
                }, {});

        //Add coverage 38 to pdf
        cotizacionResponse[companiesDict[finalValues['company'][0]]['name']]['detalleCoberturas']['obligatorias'].map((cobertura) => {
            if (cobertura['numeroCobertura'] === "38") {
                coberturasPDF['38'] = currencyFormat(cobertura['sumaAsegurada'])
            }
        })

        let jsonData = {
            "codigosLocalidad": finalValues['codigosLocalidad'],
            "tamanioVivienda": finalValues['tamanioVivienda'],
            "tipoVivienda": viviendasDict[finalValues['tipoVivienda']],
            "coberturas": coberturasPDF,
            "bienesAsegurados":  Object.keys(finalValues['coberturasAdicionales'])
                .filter((key) => finalValues['coberturasAdicionales'][key].selectedCobertura)
                .map((key) => productsByKey[key])
                .join(", "),
            "premium": currencyFormat(cotizacionResponse[companiesDict[finalValues['company'][0]]['name']]['premioMensual']), //Here will go the logic to specify insurer company,
            'img_src': userPicture,
            'nombre': userName + " " + userLastName,
            'telefono': userPhone,
            'mail': userMail,
            'provincia_polkista': userProvince,
            "cuota_mensual": cotizacionResponse['Sancor']['premioMensual'],
            'num_cotizacion': cotizacionResponse['idCotizar'],
            'cod_postal': finalValues['codigoPostal'],
            'polkista_img': userPicture,
            'polkista_nombre': userName + " " + userLastName,
            'polkista_celular': userPhone,
            'polkista_email': userMail,
            'polkista_localidad': userLocalidad,
            'polkista_web': userWebHabilitado ? userLink.split("://")[1] : "",
            'id_Auth': userIdAuth,
            'matricula': userMatricula === "" ? masterMatricula : userMatricula,
            'fecha': moment().format('DD/MM/YYYY'),
            'localidad': finalValues['nombreLocalidad'],
            "company": finalValues['company'][0] //Here will go the logic to specify insurer company
        };

        let url = process.env.REACT_APP_PRINT_MICROSERVICE + '/newTemplate/microseguros'
        axios.post(url, jsonData, {responseType: 'blob'})
            .then((response) => {
                FileDownload(response.data, 'cotizacion.pdf');
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                setIsDownloading(false);
        });
    }

    if (isDownloading) return <Loading/>
    if (cotizacionResponse === null || finalValues === null)
        return (
            <>
                <p className="microseguros__cotSuccess__text">
                    Cotizando...
                </p>
                <Loading/>
            </>
        )
    return (
        <div className="microseguros__formStep__container">
            <p className="microseguros__cotSuccess__subtitle--highlighted">
                Resumen de cotización:
            </p>
            <p className="microseguros__cotSuccess__title">
                Cuota Mensual:
                ${currencyFormat(cotizacionResponse[companiesDict[finalValues['company'][0]]['name']]['premioMensual'].toFixed(0))}
            </p>

            <Divider my="sm" size="xs"/>

            <div className="microseguros__cotSuccess__container">
                <div className="microseguros__cotSuccess__container--block">
                    <p className="microseguros__cotSuccess__subtitle--highlighted">
                        Detalle Coberturas:
                    </p>

                    {cotizacionResponse[companiesDict[finalValues['company'][0]]['name']]['detalleCoberturas']["adicionales"].sort((a, b) => {
                        //Ordenar las coberturas de acuerdo al orden de 'coberturasAdicionalesOrder'
                        const indexA = coberturasAdicionalesOrder.indexOf(a.numeroCobertura);
                        const indexB = coberturasAdicionalesOrder.indexOf(b.numeroCobertura);
                        return indexA - indexB;
                    }).map((item) => (
                        <div className="microseguros__cotSuccess__block">
                            <p className="microseguros__cotSuccess__subtitle">{coberturasInfo[item.numeroCobertura].descripcion}</p>
                            <p className="microseguros__cotSuccess__text">{`Suma Asegurada: $ ${currencyFormat(item.sumaAsegurada)}`}</p>
                        </div>
                    ))}
                </div>
                <div className="microseguros__cotSuccess__container--block">
                    <p className="microseguros__cotSuccess__subtitle--highlighted">
                        Adicionales sin costo:
                    </p>

                    {Object.keys(coberturasSinCostoIcons).map((coverage) => (
                        <div className="microseguros__cotSuccess__block microseguros__cotSuccess__block--row">
                            {coberturasSinCostoIcons[coverage]}
                            <p className="microseguros__cotSuccess__subtitle">{coberturasInfo[coverage].descripcion}</p>
                        </div>
                    ))}
                </div>
            </div>

            <p className="microseguros__cotSuccess__note">*La aseguradora indemnizará los daños hasta las sumas aseguradas
                contratadas.</p>

            <Button
                type="button"
                variant="filled"
                color="primary"
                marginY="sm"
                enabled
                onClick={handleDownloadPDF}
            >
                Descargar PDF
            </Button>
        </div>
    );
}

export default CotizarSuccessView;