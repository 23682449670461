import {Grid} from "@mantine/core";
import React from "react";
import {ReactComponent as PlusFilledIcon} from "../../../../../../../assets/icons/ic_plus_filled-primary.svg"
import {ReactComponent as SubstractFilledIcon} from "../../../../../../../assets/icons/ic_substract_filled-primary.svg"

const ButtonsToShow = (props) => {
    const {index, length, arrayHelpers, emptyArray} = props
    if (length === 1) {
        return (<Grid.Col item xs={1}>
            <div className="hogar__detForm__icon__container"
                 onClick={() => arrayHelpers.push(emptyArray)}>
                <PlusFilledIcon className="hogar__detForm__icon"/>
            </div>
        </Grid.Col>)
    } else if (index !== length - 1) {
        return (<Grid.Col item xs={1}>
            <div className="hogar__detForm__icon__container"
                 onClick={() => arrayHelpers.remove(index)}>
                <SubstractFilledIcon className="hogar__detForm__icon"/>
            </div>
        </Grid.Col>)
    } else {
        return (
            <Grid.Col item xs={1}>
                <div className="hogar__detForm__icons__container">
                    <div className="hogar__detForm__icon__container"
                         onClick={() => arrayHelpers.remove(index)}>
                        <SubstractFilledIcon className="hogar__detForm__icon"/>
                    </div>
                    <div className="hogar__detForm__icon__container"
                         onClick={() => arrayHelpers.push(emptyArray)}>
                        <PlusFilledIcon className="hogar__detForm__icon"/>
                    </div>
                </div>
            </Grid.Col>
        )
    }
}

export default ButtonsToShow;