import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Checkbox, Grid} from '@mantine/core';
import {MantineDateField} from "../../../components/FormFields/MantineDateField";
import {MantineNumberField} from "../../../components/FormFields/MantineNumberField";
import {MantineSelectField} from "../../../components/FormFields/MantineSelectField";
import {VehiculoModel} from "../../Forms/Automotor/FormStages/Cotizar/Vehiculo/VehiculoModel";
import {useFormikContext} from "formik";
import {useCurrentQuotationContext} from "../../../context/CurrentQuotationContext/CurrentQuotationContext";
import {Button} from "../../../components/Button";
import CotizarContext from "../../../context/CotizarContext";
import {ReactComponent as InformationFilledIcon} from "../../../assets/icons/ic_info-filled.svg";
import {PopUpCardModal} from "../../../layouts/PopUpCardModal";
import {Loading} from "../../../components/Loading";
import handleAdvanceRequotation from "../utils/handleAdvanceRequotation";

const {formField} = VehiculoModel();
const {
    vigencia,
    cuotas,
    ajusteAutomatico,
    sumaAseguradaVehiculo,
    fechaCotizacion,
    vigenciaDesde,
    usoVehiculo,
    usoVehiculoRUS,
    idViaCobro,
    tipoVehiculo,
    tipoVigencia,
    multiFranquicia
} = formField;

const vigencias = {
    Sancor: [
        {value: '1', label: 'Anual'},
    ],
    Federacion_Patronal: [
        {value: 'CUATRIMESTRAL', label: 'Cuatrimestral'},
        {value: 'MENSUAL', label: 'Mensual'}
    ]
};

const cantCuotasSancor = [
    {value: '1', label: '1'},
    {value: '2', label: '2'},
    {value: '3', label: '3'},
    {value: '4', label: '4'},
    {value: '5', label: '5'},
];

const ajuste = [
    {value: '0', label: 'No aplicar'},
    {value: '30', label: 'Aplicar 30%'},
    {value: '40', label: 'Aplicar 40%'},
];

const ajustesRUS = [
    {value: 0, label: 'No aplicar'},
    {value: 20, label: 'Aplicar 20%'},
    {value: 30, label: 'Aplicar 30%'},
    {value: 40, label: 'Aplicar 40%'},
];

const usosVehiculoSancor = [
    {value: '2', label: 'Particular'},
    {value: '4', label: 'Particular y/o Comercial'}
];

const usosVehiculoRUS = [
    {value: 'PARTICULAR', label: 'Particular'},
    {value: 'COMERCIAL', label: 'Comercial'}
];

const formaPago = {
    zurich: [
        {value: '7', label: 'Débito por CBU'},
        {value: '5', label: 'Efectivo'},
        {value: '4', label: 'Tarjeta de crédito'}
    ]
};

// TODO: Reforzar la condicion de habilitar botón para que se active cuando se cambia uno de los campos avanzados en lugar de cuando está abierto config avanzada
function AdvancedFormRenderer(props) {
    const {tab, submitForm, sumAsegurada, showAdvanceConfig, showRecotizarButton, activeRecotizarButton} = props;
    const {
        cotizacionResponse,
        setCotizacionResponse,
        totalInsurableByCompany,
        discounts,
        prevDiscounts,
        setPrevDiscounts,
        finalValues,
    } = useCurrentQuotationContext();
    const {companiesDict} = useContext(CotizarContext);
    const {values, setFieldValue} = useFormikContext();
    const sumaAsegurada = totalInsurableByCompany && totalInsurableByCompany[tab] ? totalInsurableByCompany[tab].sumaAseguradaVehiculo : sumAsegurada;
    const maxSumAseguradaTab = Math.floor(sumaAsegurada * 1.4);
    const [loading, setLoading] = useState(true);
    const ableButtonBySumaAsegurada = values.configuracionAvanzada[tab].sumaAseguradaVehiculo <= maxSumAseguradaTab && sumaAsegurada <= values.configuracionAvanzada[tab].sumaAseguradaVehiculo
    const companyHasMoreInfo = useMemo(() => {
        return tab === "Zurich" || tab === "Federacion_Patronal";
    }, [tab])
    const [openMoreInfo, setOpenMoreInfo] = useState(false)

    const isNewDiscountValue = useMemo(() => {
        return finalValues && finalValues.descuentos && finalValues.descuentos[tab] ? values.descuentos[tab] !== finalValues.descuentos[tab] : values.descuentos[tab] > 0;
    }, [discounts[tab], cotizacionResponse, prevDiscounts])

    const isButtonVisible = useMemo(() => {
        return showRecotizarButton || showAdvanceConfig || (isNewDiscountValue && companiesDict[tab].descuento === "prima")
    }, [showAdvanceConfig, showRecotizarButton, isNewDiscountValue, tab])

    useEffect(() => {
        if (sumaAsegurada !== null && sumaAsegurada !== undefined && totalInsurableByCompany) {
            Object.keys(totalInsurableByCompany).forEach(company => {
                if (company === "idCotizar") return
                setFieldValue(`configuracionAvanzada.${company}.${sumaAseguradaVehiculo.name}`, totalInsurableByCompany[company].sumaAseguradaVehiculo || sumAsegurada)
            })
            setLoading(false)
        }

    }, [sumaAsegurada, setFieldValue, sumaAseguradaVehiculo.name, totalInsurableByCompany]);


    if (loading) return <Loading/>;

    // TODO: Esto puede ser mejorado. Debería ser en base a las aseguradoras que tenga el usuario (Las condiciones de sancor van a desaparecer cuando lo parametrice)
    const advancedFieldsByCompany = {
        "RUS": [
            {
                condition: true,
                input: <MantineSelectField
                    name={`configuracionAvanzada.RUS.${ajusteAutomatico.name}`}
                    label={ajusteAutomatico.label}
                    data={ajustesRUS}
                    withAsterisk
                />
            },
            {
                condition: true,
                input: <MantineNumberField
                    name={`configuracionAvanzada.RUS.${sumaAseguradaVehiculo.name}`}
                    label={sumaAseguradaVehiculo.label}
                    min={sumaAsegurada}
                    max={maxSumAseguradaTab}
                    withAsterisk
                />
            },
            {
                condition: true,
                input: <MantineDateField
                    name={`configuracionAvanzada.RUS.${vigenciaDesde.name}`}
                    label={vigenciaDesde.label}
                    withAsterisk
                />
            },
            {
                condition: true,
                input: <MantineSelectField
                    name={`configuracionAvanzada.RUS.${usoVehiculoRUS.name}`}
                    label={usoVehiculoRUS.label}
                    data={usosVehiculoRUS}
                    withAsterisk
                />
            },
        ],
        "Zurich": [
            {
                condition: true,
                input: <MantineSelectField
                    name={`configuracionAvanzada.Zurich.${idViaCobro.name}`}
                    label={idViaCobro.label}
                    data={formaPago.zurich}
                    withAsterisk
                />
            },
            {
                condition: true,
                input: <MantineDateField
                    name={`configuracionAvanzada.Zurich.${vigenciaDesde.name}`}
                    label={vigenciaDesde.label}
                    withAsterisk
                />
            },
        ],
        "Sancor": [
            {
                condition: true,
                input: <MantineSelectField
                    name={`configuracionAvanzada.Sancor.${vigencia.name}`}
                    label={vigencia.label}
                    data={vigencias.Sancor}
                    disabled
                    withAsterisk
                />
            },
            {
                condition: values.configuracionAvanzada.Sancor && values.configuracionAvanzada.Sancor.vigencia === '2',
                input: <MantineSelectField
                    name={`configuracionAvanzada.Sancor.${cuotas.name}`}
                    label={cuotas.label}
                    data={cantCuotasSancor}
                    withAsterisk
                />
            },
            {
                condition: values.configuracionAvanzada.Sancor && values.configuracionAvanzada.Sancor.vigencia === '2',
                input: <MantineSelectField
                    name={`configuracionAvanzada.Sancor.${ajusteAutomatico.name}`}
                    label={ajusteAutomatico.label}
                    data={ajuste}
                    withAsterisk
                />
            },
            {
                condition: true,
                input: <MantineDateField
                    name={`configuracionAvanzada.Sancor.${fechaCotizacion.name}`}
                    label={fechaCotizacion.label}
                    withAsterisk
                />
            },
            {
                condition: true,
                input: <MantineNumberField
                    name={`configuracionAvanzada.Sancor.${sumaAseguradaVehiculo.name}`}
                    label={sumaAseguradaVehiculo.label}
                    min={sumaAsegurada}
                    max={maxSumAseguradaTab}
                    withAsterisk
                />
            },
            {
                condition: true,
                input: <MantineSelectField
                    name={`configuracionAvanzada.Sancor.${usoVehiculo.name}`}
                    label={usoVehiculo.label}
                    data={usosVehiculoSancor}
                    withAsterisk
                />
            },
        ],
        "Federacion_Patronal": [
            {
                condition: true,
                input: <MantineSelectField
                    name={`configuracionAvanzada.Federacion_Patronal.${tipoVehiculo.name}`}
                    label={tipoVehiculo.label}
                    data={[
                        {label: "CASA RODANTE SOBRE PICK-UP CLASE A", value: 75},
                        {label: "PICK-UP CLASE A CARROZADA", value: 54},
                        {label: "PICK-UP CLASE A CARROZADA , AMBULANCIA", value: 58},
                        {label: "PICK-UP CLASE A CARROZADA ,AUX. MECANICO", value: 57},
                        {label: "PICK-UP CLASE A CARROZADA ,ESC. Y SERV.ESPECIALES", value: 56},
                        {label: "PICK-UP CLASE A CARROZADA RESTO DEL PAIS", value: 55},
                        {label: "PICK-UP CLASE A SIN CARROZAR", value: 50},
                        {label: "PICK-UP CLASE A SIN CARROZAR AUX. MECANICO", value: 53},
                        {label: "PICK-UP CLASE A SIN CARROZAR ESC. Y SERV. ESPECIALES", value: 52},
                        {label: "PICK-UP CLASE A SIN CARROZAR RESTO DEL PAIS", value: 51},
                        {label: "AUTOMOVIL AMBULANCIA", value: 43},
                        {label: "AUTOMOVIL PARTICULAR", value: 40},
                        {label: "AUTOMOVIL TRANSP.ESC, SERV.ESP y ALQ. S/CHOFER", value: 42},
                        {label: "AUTOMOVIL TRANSPORTE ESCOLAR", value: 41},
                        {label: "AUTOMOVIL IMPORTADO", value: 46},
                    ]}
                    withAsterisk
                    searchable
                    disabled
                />
            },
            {
                condition: true,
                input: <MantineSelectField
                    name={`configuracionAvanzada.Federacion_Patronal.${tipoVigencia.name}`}
                    label={tipoVigencia.label}
                    data={vigencias.Federacion_Patronal}
                    withAsterisk
                />
            },
            {
                condition: true,
                input: <Checkbox
                    checked={values.configuracionAvanzada.Federacion_Patronal && values.configuracionAvanzada.Federacion_Patronal[multiFranquicia.name]}
                    onChange={() => setFieldValue(`configuracionAvanzada.Federacion_Patronal.${multiFranquicia.name}`, !values.configuracionAvanzada.Federacion_Patronal[multiFranquicia.name])}
                    label={multiFranquicia.label}
                    className="response__tab__discountCheckboxLabel"
                    styles={
                        {
                            "input": {
                                cursor: 'pointer'
                            },
                            "label": {
                                cursor: 'pointer'
                            },
                        }
                    }
                    size="xs"/>
            }
        ]
    }

    return (
        <div>
            <div
                className={`advancedForm__inputFieldsContainer advancedForm__inputFieldsContainer--${showAdvanceConfig}`}>

                <Grid gutterXs="xs" gutter="md">
                    {advancedFieldsByCompany[tab].map((field) => {
                        return (
                            <>
                                {field.condition &&
                                    <Grid.Col xs={12} sm={6}>
                                        {field.input}
                                    </Grid.Col>
                                }
                            </>
                        )
                    })}
                </Grid>
            </div>

            {isButtonVisible &&
                <div className="advancedForm__button__container">
                    <Button
                        type="button"
                        size="md"
                        variant="filled"
                        color="primary"
                        marginY="sm"
                        enabled={activeRecotizarButton || isNewDiscountValue || (showAdvanceConfig && ableButtonBySumaAsegurada)}
                        onClick={() => handleAdvanceRequotation(setCotizacionResponse, tab, values, discounts, submitForm, cotizacionResponse, setPrevDiscounts)}
                    >
                        Cotizar
                    </Button>
                    {companyHasMoreInfo &&
                        <InformationFilledIcon className="advancedForm__icon__info"
                                               onClick={() => setOpenMoreInfo(true)}/>}
                    {openMoreInfo && <PopUpCardModal title="Acerca de la recotización" body={
                        <p className="advancedForm__popUpText"> Al modificar los valores predeterminados en
                            Configuración Avanzada o descuento, se deberá hacer clic en recotizar para actualizar el
                            costo de la
                            cotización</p>
                    }
                                                     closeAction={() => setOpenMoreInfo(false)}/>}
                </div>
            }

        </div>
    );
}

export default AdvancedFormRenderer;