import React from 'react';
import * as Yup from "yup";
import {isValidPhoneNumber} from "react-phone-number-input";

const InformacionModel = () => {
    const formField = {
        nombre: {
            name: 'nombre',
            label: 'Nombre',
            requiredErrorMsg: 'Debe especificar su nombre'
        },
        apellido: {
            name: 'apellido',
            label: 'Apellido',
            requiredErrorMsg: 'Debe especificar su apellido'
        },
        DNI: {
            name: 'DNI',
            label: 'DNI',
            requiredErrorMsg: 'Debe especificar su DNI'
        },
        CUIT: {
            name: 'CUIT',
            label: 'CUIT/CUIL',
            requiredErrorMsg: 'Debe especificar su CUIT/CUIL'
        },
        nacimiento: {
            name: 'nacimiento',
            label: 'Fecha de nacimiento',
            requiredErrorMsg: 'Debe ingresar una fecha de nacimiento'
        },
        domicilio: { //Object
            name: 'domicilio',
        },
        calle: {
            name: 'calle',
            label: 'Dirección de domicilio',
            requiredErrorMsg: 'Debe especificar una dirección de domicilio'
        },
        numeroCalle: {
            name: 'numeroCalle',
            label: 'Número de domicilio',
            requiredErrorMsg: 'Debe especificar el número de su domicilio'
        },
        opcional: {
            name: 'opcional',
            label: 'Torre / Piso / Dpto / Lote',
            requiredErrorMsg: 'Debe especificar una dirección de domicilio'
        },
        nacionalidad: {
            name: 'nacionalidad',
            label: 'Nacionalidad',
            requiredErrorMsg: 'Debe especificar una nacionalidad'
        },
        telefono: {
            name: 'telefono',
            label: 'Celular',
            requiredErrorMsg: 'Debe especificar un celular'
        },
        sexo: {
            name: 'sexo',
            label: 'Sexo',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        ocupacion: {
            name: 'ocupacion',
            label: 'Ocupación',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        IVA: {
            name: 'IVA',
            label: 'Situación ante el IVA',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        matricula: {
            name: 'matricula',
            label: 'Matrícula',
            requiredErrorMsg: 'Debe especificar un número de matrícula'
        },
        tycAccepted: {
            name: 'tycAccepted',
            label: 'Acepto los términos y condiciones',
            requiredErrorMsg: 'Es necesario aceptar los términos y condiciones'
        },
        l_nacimiento: {
            name: 'l_nacimiento',
            label: 'Lugar de nacimiento',
            requiredErrorMsg: 'Debe especificar un lugar de nacimiento'
        },
        cbu: {
            name: 'cbu',
            label: 'CBU',
            requiredErrorMsg: 'Debe indicar un número de CBU'
        },
        codigoPostal: {
            name: 'codigoPostal',
            label: 'Código postal',
            requiredErrorMsg: 'Debe especificar un código postal'
        },
        localidad: {
            name: 'localidad',
            label: 'Localidad',
            requiredErrorMsg: 'Debe especificar una localidad'
        },
        provincia: {
            name: 'provincia',
            label: 'Provincia',
            requiredErrorMsg: 'Debe especificar una provincia'
        },
    }

    const initialValues = {
        [formField.nombre.name]: '',
        [formField.apellido.name]: '',
        [formField.DNI.name]: '',
        [formField.CUIT.name]: '',
        [formField.nacimiento.name]: '',
        [formField.l_nacimiento.name]: '',
        [formField.nacionalidad.name]: '',
        [formField.telefono.name]: '',
        [formField.sexo.name]: '',
        [formField.ocupacion.name]: '',
        [formField.IVA.name]: '',
        [formField.matricula.name]: '',
        [formField.tycAccepted.name]: false,
        [formField.calle.name]: '',
        [formField.numeroCalle.name]: '',
        [formField.opcional.name]: '',
        [formField.cbu.name]: '',
        [formField.codigoPostal.name]: '',
        [formField.localidad.name]: '',
        [formField.provincia.name]: '',
    }

    const validationSchema = Yup.object().shape({
        [formField.nombre.name]: Yup.string()
            .required(`${formField.nombre.requiredErrorMsg}`)
            .matches(/^[aA-zZ\s]+$/, "Ingrese un nombre válido")
            .max(40, "El nombre ingresado es muy largo"),
        [formField.apellido.name]: Yup.string()
            .required(`${formField.apellido.requiredErrorMsg}`)
            .matches(/^[aA-zZ\s]+$/, "Ingrese un apellido válido")
            .max(40, "El valor ingresado es muy largo"),
        [formField.DNI.name]: Yup.number()
            .required(`${formField.DNI.requiredErrorMsg}`)
            .min(1000000, "El documento ingresado no es válido")
            .max(99999999, "El documento ingresado no es válido")
            .integer()
            .typeError("Introduzca únicamente números"),
        [formField.CUIT.name]: Yup.string()
            .required(`${formField.CUIT.requiredErrorMsg}`)
            .matches(/^(20|23|27|30|33)([0-9]{9}|-[0-9]{8}-[0-9]{1})$/, "El CUIT ingresado no es válido"),
        [formField.nacimiento.name]: Yup.date()
            .max(new Date(Date.now() - 567648000000), "Debe ser mayor de 18 años para utilizar Polko.")
            .required(`${formField.nacimiento.requiredErrorMsg}`),
        [formField.l_nacimiento.name]: Yup.string()
            .required(`${formField.l_nacimiento.requiredErrorMsg}`)
            .matches(/^[A-Za-z0-9 -]+$/, "Esta utilizando carácteres no válidos"),
        [formField.nacionalidad.name]: Yup.string()
            .required(`${formField.nacionalidad.requiredErrorMsg}`)
            .matches(/^[A-Za-z0-9 -]+$/, "Esta utilizando carácteres no válidos"),
        [formField.telefono.name]: Yup.string()
            .test('telefono', "El celular ingresado no es válido",
                (value) => {
                    if (typeof value === "string")
                        return (isValidPhoneNumber(value))
                    else return false
                }
            ),
        [formField.sexo.name]: Yup.string().required(`${formField.sexo.requiredErrorMsg}`),
        [formField.calle.name]: Yup.string()
            .required(`${formField.calle.requiredErrorMsg}`)
            .matches(/^(?=[a-zA-Z0-9()_+=,.:\s-]*$)/, "Esta utilizando carácteres no válidos")
            .min(4, 'Ingrese al menos 4 caracteres'),
        [formField.numeroCalle.name]: Yup.number()
            .required(`${formField.numeroCalle.requiredErrorMsg}`)
            .integer()
            .typeError("Introduzca únicamente números"),
        [formField.codigoPostal.name]: Yup.number().required(`${formField.codigoPostal.requiredErrorMsg}`),
        [formField.localidad.name]: Yup.string().required(`${formField.localidad.requiredErrorMsg}`),
        [formField.provincia.name]: Yup.string().required(`${formField.provincia.requiredErrorMsg}`),
        [formField.ocupacion.name]: Yup.string().required(`${formField.ocupacion.requiredErrorMsg}`),
        [formField.IVA.name]: Yup.string().required(`${formField.IVA.requiredErrorMsg}`),
        [formField.matricula.name]: Yup.string().when([formField.ocupacion.name], {
            is: 'Productor_de_Seguros',
            then: Yup.string().required(`${formField.matricula.requiredErrorMsg}`)
        }),
        [formField.tycAccepted.name]: Yup.string().required(`${formField.tycAccepted.requiredErrorMsg}`),
        [formField.cbu.name]: Yup.string()
            .matches(/^[0-9]+$/, "Ingrese un número válido")
            .min(22, 'El número de CBU debe tener 22 dígitos')
            .max(22, 'El número de CBU debe tener 22 dígitos')
    })

    return {formField, initialValues, validationSchema}
}

export {InformacionModel};
