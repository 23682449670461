import React, {useContext, useEffect} from 'react';
import {Grid} from '@mantine/core';
import {useFormikContext} from 'formik'
import {VehiculoModel} from "./VehiculoModel";
import {MantineSelectField} from "../../../../../../components/FormFields/MantineSelectField";
import {MantineNumberField} from "../../../../../../components/FormFields/MantineNumberField";
import {useIsMount} from "../../../../../../modules/components/useIsMount";
import DefaultValuesContext from "../../../../../../context/DefaultValuesContext";
import {MantineMonthField} from "../../../../../../components/FormFields/MantineMonthField";
import AutomotorContext from "../../../../../../context/Products/AutomotorContext/AutomotorContext";
import {findSelectedLabel} from "../../../../../../modules/utils/findSelectedLabel";

export default function VehiculoForm(props) {
    const {formField} = VehiculoModel();
    const {
        mesAnioFacturacion,
        vehiculo: {
            marca,
            modelo,
            version,
            anio,
            esCeroKm,
            tieneGnc,
            sumaAseguradaGnc,
            sumaAseguradaAdicional,
        }
    } = formField;
    const {values, setFieldValue} = useFormikContext();
    const {cotizacionRetomada} = props;
    const {
        marcas,
        anios,
        modelos,
        versiones,
        updateModels,
        updateVersions,
        isLoadingModels,
        isLoadingVersions,
        vehicleDetails,
        handleRecotizarInitialValues
    } = useContext(AutomotorContext);
    const {valuesMemo} = useContext(DefaultValuesContext);
    const isMount = useIsMount();
    const isCeroKm = values.vehiculo.esCeroKm === "true";

    const cerokm_options = [
        {value: 'true', label: 'Si'},
        {value: 'false', label: 'No'}
    ];

    const gnc_options = [
        {value: '1', label: 'Si'},
        {value: '0', label: 'No'}
    ];

    useEffect(() => {
        if (cotizacionRetomada) {
            let marcaValue = values.vehiculo.marca;
            switch (values.vehiculo.detalle_marca) {
                case 'JEEP':
                    marcaValue = "13-0";
                    break;
                case 'DODGE':
                    marcaValue = "13-1";
                    break;
                case 'RAM':
                    marcaValue = "13-2";
                    break;
                default:
                    break;
            }
            setFieldValue(marca.name, marcaValue, false);
            handleRecotizarInitialValues(values.vehiculo.modelo, values.vehiculo.detalle_modelo, values.vehiculo.version, values.vehiculo.detalle_version, values.vehiculo.detalle_marca);
        }
    }, []);

    useEffect(() => {
        if (isMount) {      // isMount is true if it's the first render of the component
            setFieldValue(sumaAseguradaGnc.name, valuesMemo['Valor_GNC'], false);
            if (cotizacionRetomada) {
                updateModels(values.vehiculo.marca, values.vehiculo.anio);
            }
        } else {
            if (values.vehiculo.anio !== '') {
                if (typeof values.vehiculo.marca !== 'string') {
                    setFieldValue(modelo.name, '', false);
                    setFieldValue(version.name, '', false);
                }
                updateModels(values.vehiculo.marca, values.vehiculo.anio, true);
            }
        }
    }, [values.vehiculo.anio, values.vehiculo.marca]);

    useEffect(() => {
        if (isMount) {
            if (cotizacionRetomada) {
                updateVersions(values.vehiculo.marca, values.vehiculo.anio, values.vehiculo.modelo);
            }
        } else {
            if (values.vehiculo.modelo !== '') {
                setFieldValue(version.name, '', false);
                updateVersions(values.vehiculo.marca, values.vehiculo.anio, values.vehiculo.modelo, true);
            }
        }
    }, [values.vehiculo.modelo]);

    return (
        <div className="automotor__formStep__container">
            <Grid gutterXs="xs" gutter="md" justify="center" align="center">
                <Grid.Col xs={12} sm={3}>
                    <MantineSelectField
                        name={marca.name}
                        label={marca.label}
                        data={marcas}
                        onChangeAction={value => {
                            vehicleDetails.current = {
                                ...vehicleDetails.current,
                                detalle_marca: findSelectedLabel(marcas, value)
                            }
                        }}
                        searchable
                        clearable
                        withAsterisk
                    />
                </Grid.Col>
                <Grid.Col xs={12} sm={2}>
                    <MantineSelectField
                        name={anio.name}
                        label={anio.label}
                        data={anios}
                        searchable
                        withAsterisk
                    />
                </Grid.Col>
                <Grid.Col xs={12} sm={3}>
                    <MantineSelectField
                        name={modelo.name}
                        label={modelo.label}
                        data={modelos}
                        onChangeAction={value => {
                            vehicleDetails.current = {
                                ...vehicleDetails.current,
                                detalle_modelo: findSelectedLabel(modelos, value)
                            }
                        }}
                        loadingData={isLoadingModels}
                        searchable
                        clearable
                        withAsterisk
                    />
                </Grid.Col>
                <Grid.Col xs={12} sm={4}>
                    <MantineSelectField
                        name={version.name}
                        label={version.label}
                        data={versiones}
                        onChangeAction={value => {
                            vehicleDetails.current = {
                                ...vehicleDetails.current,
                                detalle_version: findSelectedLabel(versiones, value)
                            }
                        }}
                        loadingData={isLoadingVersions}
                        searchable
                        clearable
                        withAsterisk
                    />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <MantineSelectField
                        name={esCeroKm.name}
                        label={esCeroKm.label}
                        data={cerokm_options}
                        withAsterisk
                    />
                </Grid.Col>
                {isCeroKm &&
                    <Grid.Col xs={12} sm={6}>
                        <MantineMonthField
                            name={`configuracionAvanzada.Zurich.${mesAnioFacturacion.name}`}
                            label={mesAnioFacturacion.label}
                            withAsterisk
                        />
                    </Grid.Col>
                }
                <Grid.Col xs={12} sm={6}>
                    <MantineNumberField
                        name={sumaAseguradaAdicional.name}
                        label={sumaAseguradaAdicional.label}
                        min={0}
                        max={valuesMemo['Valor_SAAD_Maximo']}
                        withAsterisk
                    />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <MantineSelectField
                        name={tieneGnc.name}
                        label={tieneGnc.label}
                        data={gnc_options}
                        withAsterisk
                    />
                </Grid.Col>

                <Grid.Col xs={12} sm={6}>
                    {values.vehiculo.tieneGnc === '1' && (
                        <MantineNumberField
                            name={sumaAseguradaGnc.name}
                            label={sumaAseguradaGnc.label}
                            min={valuesMemo['Valor_GNC_Minimo']}
                            max={valuesMemo['Valor_GNC_Maximo']}
                            withAsterisk
                        />
                    )}
                </Grid.Col>
            </Grid>
        </div>
    );
}
