import React from 'react';
import {Select} from '@mantine/core';
import './MantineSelectFilter.css';

function MantineSelectFilter(props) {
    const {value, setValue, data, clearable=true} = props;

    return (
        <Select
            {...props}
            data={data}
            value={value}
            onChange={(v) => {
                setValue(v)
            }}
            clearable ={clearable}
            maxDropdownHeight={200}
            nothingFound="Sin resultados"
            classNames={{
                wrapper: 'mantine__selectFilter__wrapper',
                invalid: 'mantine__selectFilter__wrapper',
                input: 'mantine__selectFilter__field',
                item: 'mantine__selectFilter__item',
                rightSection: 'mantine__selectFilter__rightSection'
            }}
        />
    );
}

export default MantineSelectFilter;