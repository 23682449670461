import React from 'react';
import './Registro.css';
import {LandingHeader} from "../../features/Landing/Header";
import RegistroUsuario from "../../features/Forms/RegistroUsuario/RegistroUsuario";
import {compose} from "recompose";
import {withCotizarContextProvider, withCotizarContextReady} from "../../context/CotizarContext";

function Registro() {
    const masterInvitationCode = window.location.href.split("code=")[1]
    return (
        <>
            <LandingHeader page="registro"/>
            <div className="registro__container">
                <RegistroUsuario masterInvitationCode={masterInvitationCode}/>
            </div>
        </>
    );
}

export default compose(withCotizarContextProvider, withCotizarContextReady)(Registro);