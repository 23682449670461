import {Divider, Grid} from "@mantine/core";
import {MantineNumberField} from "../../../../../../components/FormFields/MantineNumberField";
import {MantineInputField} from "../../../../../../components/FormFields/MantineInputField";
import {FieldArray, useFormikContext} from "formik";
import {Button} from "../../../../../../components/Button";
import {MantineSelectField} from "../../../../../../components/FormFields/MantineSelectField";
import React, {useContext, useMemo} from "react";
import {DetallesVehiculoModel} from "./DetallesVehiculoModel";
import UserContext from "../../../../../../context/UserContext";
import CotizarContext from "../../../../../../context/CotizarContext";
import {ReactComponent as CloseIcon} from "../../../../../../assets/icons/ic_close.svg";

function DetallesAdicionales() {
    const {formField} = DetallesVehiculoModel();
    const {
        descripcionGnc,
        marcaRegulador,
        numeroRegulador,
        marcaCilindro,
        numeroCilindro,
        sumaAseguradaGnc,
        identificadorSancor,
        tieneGnc,
        descripcion,
        monto
    } = formField;
    const {values, errors} = useFormikContext();
    const {isMobile} = useContext(UserContext);
    const {companiesDict} = useContext(CotizarContext)
    const bienesAdicionalesOptions = companiesDict[values.company]?.bienesAdicionales || [];
    const hasBienesAdicionalesOptions = bienesAdicionalesOptions.length > 0;
    const identificadorSancorInitialValue = values.company === "Sancor" ? "10" : "";

    const bienVacio = {
        [identificadorSancor.name]: identificadorSancorInitialValue,
        [descripcion.name]: '',
        [monto.name]: '',
    };

    const cilindroVacio = {
        [marcaCilindro.name]: '',
        [numeroCilindro.name]: '',
    };

    const summationError = useMemo(() => {
        let bienesAdicionalesError = errors?.vehiculo?.bienesAdicionales;
        if (typeof bienesAdicionalesError === 'string') {
            return bienesAdicionalesError;
        }
        else return ""
    }, [errors]);

    return (
        <>
            <Grid.Col xs={12} sm={6}>
                <MantineInputField
                    name={`vehiculo.${tieneGnc.name}`}
                    label={tieneGnc.label}
                    disabled
                />
            </Grid.Col>
            {values.sumaAseguradaAdicional > 0 &&
                <Grid.Col xs={12} sm={6}>
                    <MantineNumberField
                        name={`sumaAseguradaAdicional`}
                        label="Suma asegurada bienes adicionales"
                        disabled
                    />
                </Grid.Col>
            }
            {values.vehiculo.tieneGnc === "Si" &&
                <>
                    <Grid.Col xs={12} sm={6}>
                        <MantineNumberField
                            name={`vehiculo.gnc.${sumaAseguradaGnc.name}`}
                            label={sumaAseguradaGnc.label}
                            disabled
                        />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6}/>
                </>
            }

            {/*GNC*/}

            {values.vehiculo.tieneGnc === 'Si' && (
                <>

                    <Grid.Col xs={12} sm={12}>
                        <Divider variant="middle"/>
                    </Grid.Col>

                    <Grid.Col xs={12} sm={11}>
                        <p>Datos del equipo GNC</p>
                    </Grid.Col>


                    <Grid.Col xs={12} sm={6}>
                        <MantineInputField
                            name={`vehiculo.gnc.${descripcionGnc.name}`}
                            label={descripcionGnc.label}
                            withAsterisk
                        />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6}>
                        <MantineInputField
                            name={`vehiculo.gnc.${marcaRegulador.name}`}
                            label={marcaRegulador.label}
                            withAsterisk
                        />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6}>
                        <MantineInputField
                            name={`vehiculo.gnc.${numeroRegulador.name}`}
                            label={numeroRegulador.label}
                            withAsterisk
                        />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6}/>
                    <FieldArray name='vehiculo.gnc.cilindros'>{({remove}) => (
                        values.vehiculo.gnc.cilindros.map((e, index) => (
                            <>
                                <Grid.Col xs={12} sm={4}>
                                    <MantineInputField
                                        name={`vehiculo.gnc.cilindros[${index}].${marcaCilindro.name}`}
                                        label={marcaCilindro.label}
                                        withAsterisk
                                    />
                                </Grid.Col>
                                <Grid.Col xs={12} sm={4}>
                                    <MantineInputField
                                        name={`vehiculo.gnc.cilindros[${index}].${numeroCilindro.name}`}
                                        label={numeroCilindro.label}
                                        withAsterisk
                                    />
                                </Grid.Col>
                                {values.vehiculo.gnc.cilindros.length !== 1 && (
                                    <Grid.Col xs={12} sm={2}>
                                        <div onClick={() => remove(index)}>
                                            <CloseIcon className="autem__form__details__icon"/>
                                        </div>
                                    </Grid.Col>
                                )}
                            </>
                        ))
                    )}</FieldArray>
                    {values.vehiculo.gnc.cilindros.length < 4 && (
                        <Grid.Col xs={12} sm={12}>
                            <FieldArray name='vehiculo.gnc.cilindros'>{({push}) => (
                                <Button
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    marginY="md"
                                    enabled
                                    onClick={() => push(cilindroVacio)}
                                >
                                    Nuevo Cilindro
                                </Button>
                            )}</FieldArray>
                        </Grid.Col>
                    )}
                </>
            )}


            <Grid.Col xs={12} sm={12}>
                <Divider variant="middle"/>
            </Grid.Col>

            {values['sumaAseguradaAdicional'] > 0 &&
                <>
                    <Grid.Col xs={12} sm={12}>
                        <p>Bienes asegurados (Adicionales)</p>
                    </Grid.Col>

                    <FieldArray name='vehiculo.bienesAdicionales'>{({remove}) => (
                        values.vehiculo?.bienesAdicionales?.map((e, index) => {
                            if (isMobile) {
                                return (
                                    <div className="autem__form__details__bienesAdicionales">
                                        <Grid.Col xs={12}>
                                            <MantineInputField
                                                name={`vehiculo.bienesAdicionales[${index}].${descripcion.name}`}
                                                label={descripcion.label}
                                                withAsterisk
                                            />
                                        </Grid.Col>
                                        <Grid.Col xs={12}>
                                            <MantineInputField
                                                name={`vehiculo.bienesAdicionales[${index}].${monto.name}`}
                                                label={monto.label}
                                                withAsterisk
                                            />
                                        </Grid.Col>
                                        <Grid.Col xs={12}>
                                            <div className="autem__form__details__icon__container"
                                                 onClick={() => remove(index)}>
                                                <CloseIcon className="autem__form__details__icon"/>
                                            </div>
                                        </Grid.Col>
                                    </div>
                                )
                            } else {
                                return (
                                    <>
                                        {bienesAdicionalesOptions.length > 0 &&
                                            <Grid.Col span={4}>
                                                <MantineSelectField
                                                    name={`vehiculo.bienesAdicionales[${index}].${identificadorSancor.name}`}
                                                    label={identificadorSancor.label}
                                                    data={bienesAdicionalesOptions}
                                                    withAsterisk
                                                />
                                            </Grid.Col>
                                        }
                                        <Grid.Col span={hasBienesAdicionalesOptions ? 4 : 6}>
                                            <MantineInputField
                                                name={`vehiculo.bienesAdicionales[${index}].${descripcion.name}`}
                                                label={descripcion.label}
                                                withAsterisk
                                            />
                                        </Grid.Col>
                                        <Grid.Col span={hasBienesAdicionalesOptions ? 3 : 5}>
                                            <MantineInputField
                                                name={`vehiculo.bienesAdicionales[${index}].${monto.name}`}
                                                label={monto.label}
                                                withAsterisk
                                            />
                                        </Grid.Col>
                                        <Grid.Col span={1}>
                                            <div className="autem__form__details__icon__container"
                                                 onClick={() => remove(index)}>
                                                <CloseIcon className="autem__form__details__icon"/>
                                            </div>
                                        </Grid.Col>
                                    </>
                                )
                            }
                        })
                    )}</FieldArray>

                    {summationError !== "" &&
                        <Grid.Col xs={12}>
                            <p className="autem__form__details__errorText mantine__input__error ">{summationError}</p>
                        </Grid.Col>
                    }
                    <Grid.Col xs={12}>
                        <FieldArray name='vehiculo.bienesAdicionales'>{({push}) => (
                            <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                marginY="md"
                                enabled
                                onClick={() => push(bienVacio)}
                            >
                                Agregar bien adicional
                            </Button>
                        )}</FieldArray>
                    </Grid.Col>
                </>
            }
        </>
    );
}

export default DetallesAdicionales;