import React from 'react';
import moment from "moment";
import * as Yup from "yup";

const InspeccionModel = () => {
    const formField = {
        //    INSPECCIONPREVIA OBJ [VEHICULO > INSPECCIONPREVIA]
        fechaInspeccion: {
            name: 'fechaInspeccion',
            label: 'Fecha de la inspección',
            requiredErrorMsg: 'Debe especificar la fecha de inspección'
        },
        archivos: {
            name: 'archivos',
            label: 'Archivos'
        },
        comentariosInspeccion: {
            name: 'comentariosInspeccion',
            label: 'Comentarios de la inspección',
            requiredErrorMsg: 'Debe completar este campo'
        },
        daniosPreexistentes: {
            name: 'daniosPreexistentes',
            label: 'Daños preexistentes',
        },
    }

    const initialValues = {

        inspeccionPrevia: {
            [formField.fechaInspeccion.name]: moment().format('YYYY-MM-DD'),
            [formField.comentariosInspeccion.name]: '',
            [formField.archivos.name]: [],
            [formField.daniosPreexistentes.name]: false
        },
    }

    const validationSchema = Yup.object().shape({
        inspeccionPrevia: Yup.object().shape({
            [formField.fechaInspeccion.name]: Yup.date().required(`${formField.fechaInspeccion.requiredErrorMsg}`),
            [formField.comentariosInspeccion.name]: Yup.string().when([formField.daniosPreexistentes.name], {
                is: true,
                then: Yup.string()
                    .required(`${formField.comentariosInspeccion.requiredErrorMsg}`)
                    .matches(/^(?=[a-zA-Z0-9()_+=,.:\s-]*$)/, "Por favor, evite caracteres especiales"),
            }),
            [formField.archivos.name]: Yup.array()
                .min(1, "Debe Ingresar al menos un archivo")
                .of(
                    Yup.object().shape({
                        type: Yup.string().required("Asigne las etiquetas a los archivos")
                    })
                )
        }),
    })

    return {formField, initialValues, validationSchema}
}

export {InspeccionModel};
