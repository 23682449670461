const CuitGenerator = (dni,gender) => {
    let genderNumber = gender === '2' || gender === 'hombre' ? 20 : 27;

    const generateverificator = () => {
        const multipliers = [2, 3, 4, 5, 6, 7];

        const genderNumberAndDNI = `${genderNumber}${dni}`;

        let total = 0;
        let multpIndex = 0;
        for (let i = String(genderNumberAndDNI).length - 1; i > -1; i--) {
            const sum = genderNumberAndDNI[i] * multipliers[multpIndex];
            total += sum;
            if (multpIndex === 5) multpIndex = 0;
            else multpIndex += 1;
        }

        const verificator = 11 - (total % 11);

        if (verificator === 10) {
            genderNumber = 23;
            return generateverificator();
        }
        if (verificator === 11) return 0;
        return verificator;
    };

    const verificator = generateverificator();

    return `${genderNumber}${dni}${verificator}`
}

export {CuitGenerator};