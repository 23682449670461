import {Tabs} from "@mantine/core";
import {MainTable} from "../../../../components/MainTable";
import React from "react";

const MasterDetailsBody = ({ commissionColumns, commissionOtrosColumns, polizasEmisiones, polizasOtrosIncentivos }) => {
    return (
        <Tabs variant="pills" radius="md" defaultValue="emision">
            <Tabs.List>
                <Tabs.Tab value="emision" pt="xs">Incentivos por emisión</Tabs.Tab>
                <Tabs.Tab value="otros" pt="xs">Otros incentivos</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="emision">
                <div className="comDetModal__body__table">
                    <MainTable columns={commissionColumns} rows={polizasEmisiones}/>
                </div>
            </Tabs.Panel>
            <Tabs.Panel value="otros">
                <div className="comDetModal__body__table">
                    <MainTable columns={commissionOtrosColumns} rows={polizasOtrosIncentivos}/>
                </div>
            </Tabs.Panel>
        </Tabs>
    )
}

export default MasterDetailsBody;