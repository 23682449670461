import * as Yup from "yup";

const moment = require('moment');

const VehiculoModel = () => {
    const formField = {
        vehiculo: {
            marca: {
                name: 'vehiculo.marca',
                label: 'Marca',
                requiredErrorMsg: 'Debe especificar la marca del vehículo'
            },
            modelo: {
                name: 'vehiculo.modelo',
                label: 'Modelo',
                requiredErrorMsg: 'Debe especificar el modelo del vehículo'
            },
            anio: {
                name: 'vehiculo.anio',
                label: 'Año',
                requiredErrorMsg: 'Debe especificar el año del vehículo'
            },
            version: {
                name: 'vehiculo.version',
                label: 'Versión',
                requiredErrorMsg: 'Debe especificar la versión del vehículo'
            },
            esCeroKm: {
                name: 'vehiculo.esCeroKm',
                label: '¿0 Km?'
            },
            tieneGnc: {
                name: 'vehiculo.tieneGnc',
                label: '¿Tiene equipo de GNC?',
                requiredErrorMsg: ''
            },
            sumaAseguradaGnc: {
                name: 'vehiculo.sumaAseguradaGnc',
                label: 'Suma asegurada del equipo de GNC',
                requiredErrorMsg: 'Debe ingresar la suma asegurada del equipo de GNC'
            },
            sumaAseguradaAdicional: {
                name: 'vehiculo.sumaAseguradaAdicional',
                label: 'Suma asegurada bienes adicionales',
                requiredErrorMsg: 'Debe especificar un valor (puede ser cero)'
            },
        },


        //CAMPOS DE CONFIGURACIÓN AVANZADA
        //Sancor
        codSancorLocalidad: {
            name: 'codSancorLocalidad',
        },
        vigencia: {
            name: 'vigencia',
            label: 'Vigencia',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        frecuenciaPago: {
            name: 'frecuenciaPago',
            label: 'Frecuencia de pago',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        cuotas: {
            name: 'cuotas',
            label: 'Cantidad de cuotas',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        ajusteAutomatico: {
            name: 'ajusteAutomatico',
            label: 'Ajuste automático',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        fechaCotizacion: {
            name: 'fechaCotizacion',
            label: 'Inicio vigencia',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        sumaAseguradaVehiculo: {
            name: 'sumaAseguradaVehiculo',
            label: 'Suma asegurada vehículo',
            requiredErrorMsg: 'Debe ingresar la suma asegurada del vehículo'
        },
        usoVehiculo: {
            name: 'usoVehiculo',
            label: 'Uso del vehículo',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        //Zurich
        codZurichLocalidad: {
            name: 'codZurichLocalidad',
        },
        idViaCobro: {
            name: 'idViaCobro',
            label: "Forma De Pago",
        },
        mesAnioFacturacion: {
            name: 'mesAnioFacturacion',
            label: 'Fecha de facturación',
            requiredErrorMsg: 'Debe especificar la fecha'
        },
        //Federación Patronal
        codFederacion_PatronalLocalidad: {
            name: 'codFederacion_PatronalLocalidad',
        },
        descuentoComision: {
            name: 'descuentoComision',
        },
        franquicia: {
            name: 'franquicia',
            label: 'Franquicia',
        },
        medioPago: {
            name: 'medioPago',
            label: 'Medio de pago',
        },
        tipoVehiculo: {
            name: 'tipoVehiculo',
            label: 'Tipo de vehículo',
        },
        multiFranquicia: {
            name: 'multiFranquicia',
            label: 'Multiples franquicias',
        },
        dniCliente: {
            name: 'dniCliente',
            label: 'DNI',
            requiredErrorMsg: 'Debe especificar el DNI del cliente'
        },
        applyDiscount: {
            name: 'applyDiscount',
        },
        //RUS
        codRUSLocalidad: {
            name: 'codRUSLocalidad',
        },
        usoVehiculoRUS: {
            name: 'uso',
            label: 'Uso del vehículo',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        tipoVigencia: {
            name: 'tipoVigencia',
            label: 'Vigencia',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        vigenciaDesde: {
            name: 'vigenciaDesde',
            label: 'Inicio vigencia',
            requiredErrorMsg: 'Debe seleccionar una fecha'
        },
        vigenciaHasta: {
            name: 'vigenciaHasta',
            label: 'Fin vigencia',
            requiredErrorMsg: 'Debe seleccionar una fecha'
        },
        descuentos: {
            name: 'descuentos',
            label: 'Descuento',
        },
        configuracionAvanzada: {
            name: 'configuracionAvanzada',
        },
        codigos: {
            name: 'codigos',
        }
    }

    const initialValues = {
        vehiculo: {
            marca: '',
            modelo: '',
            version: '',
            anio: '',
            esCeroKm: 'false',
            tieneGnc: '0',
            sumaAseguradaGnc: 70000,
            sumaAseguradaAdicional: 0,
        },

        [formField.descuentos.name]: {
            Sancor: 0,
            RUS: 0,
            Zurich: 0,
            Federacion_Patronal: 0
        },

        [formField.configuracionAvanzada.name]: {
            RUS: {
                [formField.codRUSLocalidad.name]: '',
                [formField.tipoVigencia.name]: 'SEMESTRAL',
                [formField.cuotas.name]: '1',
                [formField.ajusteAutomatico.name]: 0,
                [formField.sumaAseguradaVehiculo.name]: 0,
                [formField.vigenciaDesde.name]: moment().format('YYYY-MM-DD'),
                [formField.vigenciaHasta.name]: moment().add(1, "Y").format("YYYY-MM-DD"),
                [formField.usoVehiculoRUS.name]: 'PARTICULAR',
                [formField.codigos.name]: ''
            },
            Sancor: {
                [formField.codSancorLocalidad.name]: '',
                [formField.vigencia.name]: '1',
                [formField.frecuenciaPago.name]: '5',
                [formField.cuotas.name]: '1',
                [formField.ajusteAutomatico.name]: '0',
                [formField.sumaAseguradaVehiculo.name]: 0,
                [formField.usoVehiculo.name]: '2',
                [formField.fechaCotizacion.name]: moment().format('YYYY-MM-DD'),
                [formField.codigos.name]: ''
            },
            Zurich: {
                [formField.codZurichLocalidad.name]: '',
                [formField.idViaCobro.name]: '7',
                [formField.vigenciaDesde.name]: moment().format('YYYY-MM-DD'),
                [formField.vigenciaHasta.name]: moment().add(1, "Y").format("YYYY-MM-DD"),
                [formField.cuotas.name]: '1',
                [formField.mesAnioFacturacion.name]: '',
                [formField.codigos.name]: ''
            },
            Federacion_Patronal: {
                [formField.codFederacion_PatronalLocalidad.name]: '',
                [formField.tipoVigencia.name]: 'MENSUAL',
                [formField.vigenciaDesde.name]: moment().format('DD/MM/YYYY'),
                [formField.franquicia.name]: '104',
                [formField.medioPago.name]: '1',
                [formField.descuentoComision.name]: "0",
                [formField.tipoVehiculo.name]: "",
                [formField.multiFranquicia.name]: false,
                [formField.dniCliente.name]: '',
                [formField.applyDiscount.name]: false,
                [formField.cuotas.name]: '1',
            }
        }
    }

    const validationSchema = Yup.object().shape({
        vehiculo: Yup.object().shape({
            marca: Yup.string().required(formField.vehiculo.marca.requiredErrorMsg).typeError("Ingrese una marca válida"),
            modelo: Yup.string().required(formField.vehiculo.modelo.requiredErrorMsg).typeError("Ingrese un modelo válido"),
            version: Yup.string().required(formField.vehiculo.version.requiredErrorMsg).typeError("Ingrese una versión válida"),
            anio: Yup.number()
                .required(formField.vehiculo.anio.requiredErrorMsg)
                .integer()
                .typeError("Ingrese un año válido"),
            tieneGnc: Yup.string().required(formField.vehiculo.tieneGnc.requiredErrorMsg),
            sumaAseguradaGnc: Yup.number().when('vehiculo.tieneGnc', {
                is: '1',
                then: Yup.number().required(formField.vehiculo.sumaAseguradaGnc.requiredErrorMsg)
                    .min(20000, `El valor ingresado no debe ser inferior a $${20000}`)
                    .max(1000000, `El valor ingresado no debe superar los $${1000000}`)
                    .typeError("Ingrese una suma asegurada válida"),
            }),
            sumaAseguradaAdicional: Yup.number().required(formField.vehiculo.sumaAseguradaAdicional.requiredErrorMsg)
        }),

        //    TODO: Add validation for "configuracionAvanzada" fields
    })

    return {formField, initialValues, validationSchema}
}

export {VehiculoModel};
