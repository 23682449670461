import React from "react";
import {Route, useRouteMatch} from "react-router-dom";
import {compose} from "recompose";
import {withMasterContextProvider, withMasterContextReady} from "../context/MasterContext";
import {MasterDashboard} from "../pages/MasterDashboard";
import {MasterCanalDigital} from "../pages/MasterDigitalChannel";
import {MasterOperaciones} from "../pages/MasterOperaciones";
import {withCotizarContextProvider, withCotizarContextReady} from "../context/CotizarContext";
import {AltaCompania} from "../pages/AltaCompania";
import {MisAseguradoras} from "../pages/MisAseguradoras";
import MisDocumentos from "../pages/MisDocumentos/MisDocumentos";

const MasterRoutes = () => {
    const {path} = useRouteMatch();

    return (
        <>
            <Route path={`${path}/dashboard`} component={MasterDashboard}/>
            <Route path={`${path}/operaciones`} component={MasterOperaciones}/>
            <Route path={`${path}/canaldigital`} component={MasterCanalDigital}/>
            <Route path={`${path}/alta/compania`} component={AltaCompania}/>
            <Route path={`${path}/misaseguradoras`} component={MisAseguradoras}/>
            <Route path={`${path}/misdocumentos`} component={MisDocumentos}/>
        </>
    )
}

export default compose(
    withCotizarContextProvider,
    withCotizarContextReady,
    withMasterContextProvider,
    withMasterContextReady,
)(MasterRoutes);