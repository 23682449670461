import React, {createRef, useState} from 'react';
import {Grid, Popover} from "@mantine/core";
import {FieldArray, useFormikContext} from "formik";
import {ClienteModel} from "./ClienteModel";
import {ReactComponent as EditIcon} from "../../../../../../assets/icons/ic_edit.svg";
import {ReactComponent as ExclamationIcon} from "../../../../../../assets/icons/ic_exclamation.svg";
import {ReactComponent as CircledCheckIcon} from "../../../../../../assets/icons/ic_check_circled.svg";
import {ReactComponent as CloseIcon} from "../../../../../../assets/icons/ic_close.svg";
import {Button} from '../../../../../../components/Button';
import PhoneInput from "react-phone-number-input";
import {MantineSelectField} from "../../../../../../components/FormFields/MantineSelectField";
import {MantineInputField} from "../../../../../../components/FormFields/MantineInputField";
import {MantineDateField} from "../../../../../../components/FormFields/MantineDateField";
import {MantineInputRefField} from "../../../../../../components/FormFields/MantineInputRefField";
import {CuitGenerator} from "../../../../../../modules/utils/cuitGenerator";
import MantineDependantField from "../../../../../../components/FormFields/MantineDependantField/MantineDependantField";
import ZipCodeFieldPackage from "../../../../../Cotizacion/ZipCodeFieldPackage/ZipCodeFieldPackage";
import {useCurrentQuotationContext} from "../../../../../../context/CurrentQuotationContext/CurrentQuotationContext";

export default function ClienteForm() {
    const {formField} = ClienteModel();
    const {
        rol,
        tipoPersona,
        dni,
        cuit,
        nombre,
        apellido,
        fechaNacimiento,
        sexo,
        calle, numero, opcional,
        codigosLocalidad,
        codigoPostal,
        provincia,
        telefono,
        email,
        situacionImpositiva,
    } = formField;
    const [selectedIndex, setSelectedIndex] = useState(0);
    const {values, setFieldValue, errors} = useFormikContext();
    const ref = createRef();
    const [popoverState, setPopoverState] = useState({});
    const {localidadOptions, setLocalidadOptions, provinciasOptions, setProvinciasOptions} = useCurrentQuotationContext();

    const clienteVacio = {
        [rol.name]: '',
        [tipoPersona.name]: '0',
        [dni.name]: '',
        [cuit.name]: '',
        [nombre.name]: '',
        [apellido.name]: '',
        [fechaNacimiento.name]: '',
        // [estadoCivil.name]: '2',
        [sexo.name]: '',
        [calle.name]: '',
        [numero.name]: '',
        [opcional.name]: '',
        [codigosLocalidad.name]: '',
        [codigoPostal.name]: '',
        [provincia.name]: '',
        // [codigoArea.name]: '',
        [telefono.name]: '',
        [email.name]: '',
        [situacionImpositiva.name]: '4',
        // [categoriaMonotributo.name]: '1',
        // [actividadPrincipal.name]: '10001',
        // [lugarNacimiento.name]: 'Cordoba'
    };

    const opcionesRoles = [
        {value: '1', label: 'Tomador'},
        //{value: '2', label: 'Asegurado'},
        {value: '7', label: 'Asegurado Adicional'},
        {value: '15', label: 'Acreedor Hipotecario'},
        {value: '16', label: 'Acreedor Prendario'},
        {value: '17', label: 'Beneficiario'},
        {value: '18', label: 'Beneficiario de Asistencia'},
        {value: '46', label: 'Beneficiario de Subrogación'},
        {value: '111', label: 'Asegurado Titular Póliza de AP'},
    ];

    const opcionesSexo = [
        {value: '1', label: 'Femenino'},
        {value: '2', label: 'Masculino'}
    ];

    const opcionesSituacionIva = [
        {value: '1', label: 'Responsable Inscripto'},
        // {value: '2', label: 'Responsable No Inscripto'},
        // {value: '3', label: 'Exento'},
        {value: '4', label: 'Consumidor Final'},
        // {value: '5', label: 'No Declarado'},
        // {value: '6', label: 'No Alcanzado'},
        // {value: '7', label: 'Responsable Monotributo'},
        // {value: '8', label: 'Sujeto No Categorizado'},
    ];

    const opcionesBooleanas = [
        {value: '0', label: 'Persona Física'},
        {value: '1', label: 'Persona Jurídica'}
    ];

    const updateLocalidadOptions = (newLocalidades) => {
        setLocalidadOptions((prevState) => {
            const newState =  [...prevState ];
            newState[selectedIndex] = newLocalidades;
            return newState;
        });
    };

    const handlePopOverOpen = (index) => {
        setPopoverState({
            ...popoverState,
            [index]: true
        });
    }

    const handlePopOverClose = (index) => {
        setPopoverState({
            ...popoverState,
            [index]: false
        });
    }

    const hasErrors = (e, idx) => {
        if (e.clientes !== undefined && e.clientes[idx] !== undefined) {
            return Object.keys(e.clientes[idx]).length > 0
        } else
            return values.clientes[idx].cuit === "";
    }

    const calculateCuit = () => {
        if (values.clientes[selectedIndex].dni) {
            if ((values.clientes[selectedIndex].dni).length === 8 && values.clientes[selectedIndex].sexo !== '') {
                return CuitGenerator(values.clientes[selectedIndex].dni, values.clientes[selectedIndex].sexo)
            }
        }
    }

    return (
        <React.Fragment>
            <div className="motem__clientes__layout">
                {/*FORMULARIO DE CLIENTE*/}
                <div className="motem__clientes__form__container">
                    {values.clientes.map((client, index) => {
                        if (index === selectedIndex) {
                            return (
                                <Grid justify="center" align="center" gutterXs="xs">
                                    <Grid.Col xs={4} sm={4}>
                                        <MantineSelectField
                                            name={`clientes[${selectedIndex}].${tipoPersona.name}`}
                                            label={"Tipo de Persona"}
                                            withAsterisk
                                            data={opcionesBooleanas}/>
                                    </Grid.Col>

                                    <Grid.Col xs={8} sm={8}/>
                                    {values.clientes[selectedIndex].tipoPersona === '0' && (
                                        <Grid.Col xs={12} sm={4}>
                                            <MantineInputField
                                                name={`clientes[${selectedIndex}].${nombre.name}`}
                                                label={nombre.label}
                                                withAsterisk
                                            />
                                        </Grid.Col>
                                    )}
                                    <Grid.Col xs={12} sm={4}>
                                        <MantineInputField
                                            name={`clientes[${selectedIndex}].${apellido.name}`}
                                            label={values.clientes[selectedIndex].tipoPersona === '0' ? apellido.label : "Razón Social"}
                                            withAsterisk
                                        />
                                    </Grid.Col>
                                    {values.clientes[selectedIndex].tipoPersona === '0' && (
                                        <Grid.Col xs={12} sm={4}>
                                            <MantineSelectField
                                                name={`clientes[${selectedIndex}].${sexo.name}`}
                                                label={sexo.label}
                                                data={opcionesSexo}
                                                withAsterisk
                                            />
                                        </Grid.Col>
                                    )}
                                    {values.clientes[selectedIndex].tipoPersona === '0' && (
                                        <Grid.Col xs={12} sm={6}>
                                            <MantineInputField
                                                name={`clientes[${selectedIndex}].${dni.name}`}
                                                label={dni.label}
                                                withAsterisk
                                            />
                                        </Grid.Col>
                                    )}
                                    <Grid.Col xs={12} sm={6}>
                                        <MantineDependantField
                                            test={cuit}
                                            dependantFieldName={`clientes[${selectedIndex}].${cuit.name}`}
                                            dependantFieldLabel={cuit.label}
                                            dependencies={[values.clientes[selectedIndex].dni, values.clientes[selectedIndex].sexo]}
                                            calculateValueFunction={calculateCuit}
                                            formatDisplay={(v) => v}
                                            withAsterisk
                                        />
                                    </Grid.Col>
                                    {values.clientes[selectedIndex].tipoPersona === '0' && (
                                        <Grid.Col xs={12} sm={12}>
                                            <MantineDateField
                                                name={`clientes[${selectedIndex}].${fechaNacimiento.name}`}
                                                label={fechaNacimiento.label}
                                                withAsterisk
                                            />
                                        </Grid.Col>
                                    )}
                                    <Grid.Col xs={12} sm={4}>
                                        <MantineInputField
                                            name={`clientes[${selectedIndex}].${calle.name}`}
                                            label={calle.label}
                                            withAsterisk
                                        />
                                    </Grid.Col>
                                    <Grid.Col xs={12} sm={4}>
                                        <MantineInputField
                                            name={`clientes[${selectedIndex}].${numero.name}`}
                                            label={numero.label}
                                            withAsterisk
                                        />
                                    </Grid.Col>
                                    <Grid.Col xs={12} sm={4}>
                                        <MantineInputField
                                            name={`clientes[${selectedIndex}].${opcional.name}`}
                                            label={opcional.label}
                                        />
                                    </Grid.Col>

                                    <Grid.Col xs={12} sm={6}>
                                        <MantineInputField
                                            name={`clientes[${selectedIndex}].${email.name}`}
                                            label={email.label}
                                            withAsterisk
                                        />
                                    </Grid.Col>

                                    <Grid.Col xs={12} sm={6}>
                                        <PhoneInput
                                            defaultCountry="AR"
                                            ref={ref}
                                            inputComponent={MantineInputRefField}
                                            name={`clientes[${selectedIndex}].${telefono.name}`}
                                            label={telefono.label}
                                            value={values['clientes'][selectedIndex][telefono.name]}
                                            onChange={(e) => {
                                                /** @namespace e.length **/
                                                if (e && e.length > 12) {
                                                    setFieldValue(`clientes[${selectedIndex}].${telefono.name}`, e)
                                                }
                                            }}
                                            withAsterisk
                                        />
                                    </Grid.Col>
                                    <>
                                        <ZipCodeFieldPackage
                                            localidadField={{
                                                name: `clientes[${selectedIndex}].${codigosLocalidad.name}`,
                                                label: codigosLocalidad.label
                                            }}
                                            localidadSize={6}
                                            codigoPostalField={{
                                                name: `clientes[${selectedIndex}].${codigoPostal.name}`,
                                                label: codigoPostal.label
                                            }}
                                            codigoPostalSize={12}
                                            provinciaField={{
                                                name: `clientes[${selectedIndex}].${provincia.name}`,
                                                label: provincia.label
                                            }}
                                            provinciaSize={6}
                                            localidadOptions={localidadOptions[selectedIndex] || []}
                                            setLocalidadOptions={updateLocalidadOptions}
                                            provinciasOptions={provinciasOptions}
                                            setProvinciaOptions={setProvinciasOptions}
                                        />
                                    </>
                                    {values.clientes[selectedIndex].tipoPersona === '0' && (
                                        <Grid.Col xs={12} sm={6}>
                                            <MantineSelectField
                                                name={`clientes[${selectedIndex}].${situacionImpositiva.name}`}
                                                label={situacionImpositiva.label}
                                                data={opcionesSituacionIva}
                                                withAsterisk
                                            />
                                        </Grid.Col>
                                    )}

                                </Grid>
                            )
                        }
                    })}
                </div>

                {/*LISTA DE CLIENTES*/}
                <div className="motem__clientes__list__container">
                    <FieldArray name='clientes'>{({remove}) => (
                        values.clientes.map((e, index) => (
                            <div
                                className={selectedIndex === index ? "motem__clientes__list__row--active" : "motem__clientes__list__row"}>
                                <div className="motem__clientes__input__container">
                                    <MantineSelectField
                                        name={`clientes[${index}].${rol.name}`}
                                        label={rol.label}
                                        data={index === 0 ? [{value: '2', label: 'Asegurado'}] : opcionesRoles}
                                        withAsterisk
                                    />
                                </div>
                                <div className="motem__clientes__icon__container"
                                     onClick={() => setSelectedIndex(index)}>
                                    <EditIcon className="motem__clientes__icon--edit"/>
                                </div>
                                {hasErrors(errors, index)
                                    ? (
                                        <Popover width={200} position="bottom" withArrow shadow="md"
                                                 opened={popoverState[index] || false}>
                                            <Popover.Target>
                                                <div className="motem__clientes__icon__container"
                                                     onMouseEnter={() => handlePopOverOpen(index)}
                                                     onMouseLeave={() => handlePopOverClose(index)}>
                                                    <ExclamationIcon className="motem__clientes__icon--exclamation"/>
                                                </div>
                                            </Popover.Target>
                                            <Popover.Dropdown sx={{pointerEvents: 'none'}}>
                                                <p>Tiene campos sin completar</p>
                                            </Popover.Dropdown>
                                        </Popover>
                                    )
                                    : (<div className="motem__clientes__icon__container">
                                        <CircledCheckIcon className="motem__clientes__icon--check"/>
                                    </div>)
                                }
                                {values.clientes.length !== 1 && (
                                    <div className="motem__clientes__icon__container" onClick={() => {
                                        setSelectedIndex(0);
                                        remove(index)
                                    }}>
                                        <CloseIcon className="motem__clientes__icon--close"/>
                                    </div>
                                )}
                            </div>
                        )))}
                    </FieldArray>

                    <FieldArray name='clientes'>{({push}) => (
                        <Button variant="filled"
                                color="primary"
                                marginY="lg"
                                enabled
                                size="md"
                                type="button" onClick={() => push(clienteVacio)}>nuevo cliente</Button>
                    )}</FieldArray>
                </div>
            </div>
        </React.Fragment>
    );
}