import React, {useContext, useMemo, useState} from 'react';
import {useFormikContext} from "formik";
import {currencyFormat} from "../../../../../../modules/utils/currencyFormat";
import CotizarContext from "../../../../../../context/CotizarContext";
import {Collapse} from '@mantine/core';
import {ReactComponent as PassengerIcon} from "../../../../../../assets/icons/ic_user.svg";
import {ReactComponent as ArrowIcon} from '../../../../../../assets/icons/ic_chevron_down.svg';
import "./SolicitudForm.css";
import AsistenciaViajeroContext from "../../../../../../context/Products/AsistenciaViajeroContext";
import UserContext from "../../../../../../context/UserContext";
import parseDate from "../../../../../../modules/utils/parseDate";

export default function SolicitudForm() {
    const {values} = useFormikContext();
    const {companiesDict} = useContext(CotizarContext);
    const {destinos} = useContext(AsistenciaViajeroContext)
    const {isMobile} = useContext(UserContext);
    const [openStates, setOpenStates] = useState(values.pasajeros.map(() => false));
    const formattedVigenciaDesde = useMemo(() => parseDate(values.vigenciaDesde), [values.vigenciaDesde]);
    const formattedVigenciaHasta = useMemo(() => parseDate(values.vigenciaHasta), [values.vigenciaHasta]);
    const countryName = useMemo(() => destinos.find((destino) => destino.value === values.idPais)?.label, [values.idPais])

    const toggle = index => {
        setOpenStates(currentStates =>
            currentStates.map((state, i) => (i === index ? !state : state))
        );
    };

    return (
        <React.Fragment>
            <div className="automotor__formStep__container">
                <div className="autem__solicitud__header">
                    <p className="autem__solicitud__title">Detalles de póliza</p>
                    <img className="autem__solicitud__logo" src={companiesDict['TerraWind']['logo']} alt={'TerraWind'}/>
                </div>
                <hr className="autem__solicitud__hr"/>
                <div className="asistenciaViajero__solicitud__body">
                    <div className="passengerBox__listDataContainer">
                        <p className="autem__solicitud__content__title">Datos del viaje</p>
                        <p className="passengerBox__personalInfo">Cobertura: <span>{values.nombreCobertura}</span>
                        </p>
                        <p className="passengerBox__personalInfo">Destino: {countryName}</p>
                        <p className="passengerBox__personalInfo">Fechas: <span>{formattedVigenciaDesde} - {formattedVigenciaHasta}</span>
                        </p>
                    </div>
                    {isMobile && <hr className="autem__solicitud__hr"/>}
                    <div className="passengerBox__listDataContainer">
                        <p className="autem__solicitud__content__title">Datos de los asegurados</p>
                        {values.pasajeros.map((pasajero, index) => (
                            <div key={index} className="passengerBox__clientRow">
                                <div onClick={() => toggle(index)} className="passengerBox__infoContainer">
                                    <PassengerIcon className="passengerBox__icon"/>
                                    <p className="passengerBox__personalInfo">{pasajero.nombre} {pasajero.apellido}</p>
                                    <p className="passengerBox__personalInfo">{pasajero.numeroDocumento}</p>
                                    <ArrowIcon
                                        className={`passengerBox__arrowIcon ${openStates[index] && 'passengerBox__arrowIcon--top'}`}/>
                                </div>
                                <Collapse in={openStates[index]}>
                                    <div>
                                        <p className="passengerBox__personalInfo"><span
                                            className="passengerBox__personalInfo--highlighted">Celular:</span> {pasajero.telefono}
                                        </p>
                                        <p className="passengerBox__personalInfo"><span
                                            className="passengerBox__personalInfo--highlighted">Email:</span> {pasajero.email}
                                        </p>
                                        <hr className="passengerBox__hrDivider"/>
                                        <p className="passengerBox__personalInfo--sectionTitle">Contacto de
                                            emergencia</p>
                                        <p className="passengerBox__personalInfo">{values.nombre_contacto_emergencia} {values.apellido_contacto_emergencia}</p>
                                        <p className="passengerBox__personalInfo"><span
                                            className="passengerBox__personalInfo--highlighted">Celular:</span> {values.telefono_contacto_emergencia}
                                        </p>
                                    </div>
                                </Collapse>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="autem__solicitud__footer">
                    <p className="autem__solicitud__premium">
                        Total: ${currencyFormat(parseInt(values.rowData.premium))}
                    </p>
                </div>
            </div>
        </React.Fragment>
    );
}