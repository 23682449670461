import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {fieldToTextField} from "formik-material-ui";
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

const FormikAutocomplete = ({textFieldProps, ...props}) => {
    const {
        form: {setTouched, setFieldValue},
    } = props;
    const {error, helperText, ...field} = fieldToTextField(props);
    const {name} = field;
    const options = field.options;
    const filterOptions = createFilterOptions({
        limit: 100,
        stringify: option => option.label,
    });

    return (
        <Autocomplete
            {...field}
            {...props}
            onChange={(_, data) => {
                if(data)
                    setFieldValue(name, data.value)
            }}
            getOptionLabel={(option) => {
                if(typeof option === "string"){
                    let op = options.find(i => i.value === option);
                    if(op !== undefined)
                        return op.label;
                    else
                        return option;
                }
                if(typeof option === "number"){
                    let op = options.find(i => i.value === option);
                    if(op !== undefined)
                        return op.label;
                    else
                        return option;  //return option.toString();
                }
                return option.label;
            }}
            getOptionSelected={(option, value) => {
                return option.value === value;
            }}
            onBlur={() => setTouched({ [name]: true })}
            value={field.value}
            filterOptions={filterOptions}
            renderInput={(props) => (

                <TextField
                    {...props}
                    {...textFieldProps}
                    label={field.label}
                    name={field.name}
                    helperText={helperText}
                    error={error}
                />
            )}
        />
    );
};

export default FormikAutocomplete;
