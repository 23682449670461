import React from 'react';
import './WarnBadge.css';

function WarnBadge({children}) {
    return (
        <div className="warnBadge">
            {children}
        </div>
    );
}

export default WarnBadge;