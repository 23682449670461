import React from 'react';
import './Footer.css'
import PolkoSecondary from "../../../assets/logos/polko_secondary.png";
import SSNWhite from '../../../assets/logos/ssn_white.png'

function Footer() {
    return (
        <div className="landing__footer__container">
            <div className="landing__footer__container--left">
                <img className="landing__footer__logo" src={PolkoSecondary} alt="polko"/>
                <p className="landing__footer__copyright">
                    POLKO SAS® Copyright © {new Date().getFullYear()}.
                    Todos los derechos reservados.
                </p>
            </div>
            <div className="landing__footer__container--right">
                <img className="landing__footer__logo--ssn" src={SSNWhite} alt="SSN"/>
                <p className="landing__footer__text--bold landing__footer__text--margin">Matrícula 1630</p>

                <p className="landing__footer__text">+54 9 351 868 7927</p>
                <p className="landing__footer__text">consultas@polko.com.ar</p>
                <p className="landing__footer__text--light">Av. Rafael Nuñez 3578, Córdoba. CP 5000</p>
            </div>
        </div>
    );
}

export default Footer;