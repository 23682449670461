import axios from "axios";

const loadAutomotorData = async (authToken) => {
    let urlMarcas = process.env.REACT_APP_BACK_SERVER + '/getMarcasAutos'
    const requestMarcas = axios.get(urlMarcas, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        }
    });

    let urlCoberturas = process.env.REACT_APP_QUOTATION_MICROSERVICE + '/detallesCoberturas/autos'
    const requestCoberturas = axios.get(urlCoberturas);

    try {
        const [marcas, coberturasData] = await axios.all([requestMarcas, requestCoberturas])
        return {
            marcas: marcas.data,
            coberturas: coberturasData.data
        };
    } catch (error) {
        console.error('Error loading automotor data:', error);
        throw error;
    }
};

export default loadAutomotorData;
