import React, {useEffect, useState} from 'react';
import {useField} from 'formik';
import {TextField} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    errorMessage: {
        fontSize: " 0.75rem",
        marginTop: "3px",
        textAlign: "left",
        fontFamily: "Helvetica",
        fontWeight: "400",
        lineHeight: "1.66",
        letterSpacing: "0.03333em",
        color: "red",
    },
    noErrorField: {
        border: "1px solid #949494"
    },
    errorField: {
        border: "1px solid red",
    },
    slantedText: {
        fontFamily: "Nunito",
        fontSize: "16px",
        color: "#9b9b9b",
        textAlign: "center",
        paddingTop: "3px",
    },
    zeroDiv: {
        backgroundColor: "#cccccc",
        width: "15%",
    },
    codAreaDiv: {
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        width: "25%",
    },
    fifteenDiv: {
        backgroundColor: "#cccccc",
        width: "20%",
    },
    phoneDiv: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        width: "40%",
    },
}));

export function useFastField(props) {
    const [field, meta, setFieldValue] = useField(props);
    const [value, setValue] = useState(field.value);

    field.value = value;
    field.onChange = (e) => {
        if (e && e.currentTarget) {
            setValue(e.currentTarget.value);
            setFieldValue.setTouched(e.currentTarget)
            setFieldValue.setValue(e.currentTarget.value)
        }
    };

    return [field, meta];
}

export default function PhoneNumberField(props) {
    const {codAreaName, phoneName, label, isMobile} = props;
    const [codAreaField, codAreaMeta] = useFastField(codAreaName);
    const [phoneField, phoneFieldMeta] = useFastField(phoneName);
    const [error, setError] = useState(null);
    const classes = useStyles()

    const showErrorMsg = (numError) => {
        switch (numError) {
            case 0:
                return <div className={classes.errorMessage}>Por favor, ingrese un código de área válido</div>
            case 1:
                return <div className={classes.errorMessage}>Por favor, ingrese un número válido</div>
            case 2:
                return <div className={classes.errorMessage}>Por favor, revise el número ingresado</div>
            case 3:
                return <div className={classes.errorMessage}>Por favor ingrese el código de área sin 0</div>
            case 4:
                return <div className={classes.errorMessage}>Por favor introduzca su número sin el 15</div>
            default:
                return <></>
        }
    }

    useEffect(() => {
        if (codAreaMeta.touched) {
            if (codAreaMeta.error !== undefined) {
                if (codAreaMeta.error === 'Por favor ingrese el código de área sin 0')
                    setError(3)
                else
                    setError(0)
            } else {
                setError(null)
                codAreaMeta.touched = false
                if (phoneFieldMeta.touched) {
                    if (phoneFieldMeta.error !== undefined) {
                        if (phoneFieldMeta.error === 'Por favor introduzca su número sin el 15')
                            setError(4)
                        else
                            setError(1)
                    } else {
                        if (codAreaField.value !== undefined && phoneField.value !== undefined)
                            if (codAreaField.value !== '' && phoneField.value !== '') {
                                if (codAreaField.value.length + phoneField.value.length !== 10)
                                    setError(2)
                                else
                                    setError(null)
                            }
                    }
                }
            }
        }
    }, [codAreaMeta, phoneFieldMeta]);


    return (
        <React.Fragment>
            <div style={{marginTop: !isMobile ? "-5px" : "0px"}}>
                <label
                    style={{
                        color: "rgba(0, 0, 0, 0.54)",
                        fontSize: "12px",
                    }}
                >
                    {label}
                </label>
                <Grid container spacing={0} direction="row"
                      justifyContent="center" className={error !== null ? classes.errorField : classes.noErrorField}>
                    <div style={{backgroundColor: "#cccccc", width: "14%"}}>
                        <p className={classes.slantedText}>0</p>
                    </div>
                    <div style={{display: "flex", alignItems: "center", alignContent: "center", width: "25%"}}>
                        <TextField
                            error={codAreaMeta.touched && codAreaMeta.error}
                            inputProps={{style: {textAlign: 'center'}}}
                            InputProps={{disableUnderline: true}}
                            name={codAreaName}
                            {...codAreaField}
                        />
                    </div>
                    <div style={{backgroundColor: "#cccccc", width: "22%"}}>
                        <p className={classes.slantedText}>15</p>
                    </div>

                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        width: "39%"
                    }}>
                        <TextField
                            error={phoneFieldMeta.touched && phoneFieldMeta.error}
                            inputProps={{style: {textAlign: 'center'}}}
                            InputProps={{disableUnderline: true}}
                            name={phoneName}
                            {...phoneField}
                        />
                    </div>
                </Grid>

                {showErrorMsg(error)}
            </div>
        </React.Fragment>
    );
}