import { useDisclosure } from '@mantine/hooks';
import { useContext } from 'react';
import CardFrame from "../../../../components/CardFrame/CardFrame";
import { ChevronDown } from "../../../../components/icons/ChevronDown";
import UserContext from '../../../../context/UserContext';
import "../MisAseguradorasCompanies.css";
import "./CollapsibleRow.css";

const CollapsibleRow = (props) => {
    const { rowItems, collapsedContent } = props;
    const [opened, { toggle }] = useDisclosure(false);
    const { isMobile } = useContext(UserContext);

    return (
        <div className="CollapsibleRow_CardFrame--wrapper">
            <CardFrame
                withBanner={true}
                bannerContent={collapsedContent}
                collapsed={!opened}
            >
                <div className="CollapsibleRow_row--wrapper" onClick={() => isMobile ? toggle() : null}>
                    {rowItems.map((item, index) => {
                        return (
                            <div key={index} className={`CollapsibleRow_row--item ${index > 0 ? "middle" : ""}`}>
                                {item}
                            </div>
                        );
                    })}
                    {!isMobile &&
                        <div className="CollapsibleRow_row--item last">
                            <div className="CollapsibleRow_arrow--wrapper" onClick={toggle}>
                                <ChevronDown size="sm" rotate={opened ? "180" : ""} />
                            </div>
                        </div>
                    }
                </div>
            </CardFrame>
        </div>
    );
};

export default CollapsibleRow;