import React, {useContext} from 'react';
import './CustomStepper.css';
import {StepperStep} from "../../components/StepperStep";
import UserContext from "../../context/UserContext";

function CustomStepper(props) {
    const {labels, activeStep, color = "primary"} = props;
    const {isMobile} = useContext(UserContext);

    const renderSteps = () => {
        if (isMobile) {
            return (
                labels.filter(l => !l.includes('#')).map((label, idx) => {
                    if (activeStep === idx) {
                        return (<div className={`mobileStep --${color}`}>
                            <p className="mobileStep__text">{idx + 1}</p>
                        </div>)
                    } else {
                        return (
                            <div className={`mobileStep--unactive --${color}`}/>
                        )
                    }
                })
            )
        } else {
            return (
                labels.map((label, idx) => (
                        <StepperStep color={color} isMobile={isMobile} step={idx + 1} label={label} completed={activeStep > idx} activeStep={activeStep}/>
                    ))
            )
        }
    }

    return (
        <div className="customStepper__container">
            <div className="customStepper__steps__container">
                {renderSteps()}
            </div>
            {isMobile && <p className={`stepperStep__label --${color}`}>{labels[activeStep]}</p>}
        </div>
    );
}

export default CustomStepper;