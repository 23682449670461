import React, {useContext, useState} from 'react';
import {TemplateSelect} from "../../TemplateStep/TemplateSelect";
import {useFormikContext} from "formik";
import "./VisualForm.css";
import {TemplateViewer} from "../../TemplateStep/TemplateViewer";
import UserContext from "../../../../../context/UserContext";
import {CarouselWrapper} from "../../../../../components/CarouselWrapper";

export default function VisualForm() {
    const {values, setFieldValue} = useFormikContext();
    const templateNames = ["1", "2", "3", "4"];
    const [showPreview, setShowPreview] = useState(false);
    const {isMobile} = useContext(UserContext);

    const handleTemplateClick = (idTemplate) => {
        setFieldValue("template", idTemplate)
    }

    const mobileCarouselTemplateSelector = () => {
        return (
            <div className="VForm__otherDesignsContainer">
                <hr className="VForm__separator" style={{width: "207px", backgroundColor: "black"}}/>
                <label className="VForm__sectorTitle">Otros diseños disponibles</label>
                <div className="VForm__carouselContainer">
                    <CarouselWrapper initialSlide={0} align={"start"} dragFree={true} withDots={false} slideSize={96}>
                        {templateNames.map((templateName, index) => (
                            <div className="VForm__templatesGrid">
                                <div className="VForm__templateCardContainer" onClick={() => handleTemplateClick(index)}
                                     key={index}>
                                    <TemplateSelect
                                        name={templateName}
                                        templateId={index}
                                    />
                                </div>
                            </div>
                        ))}
                    </CarouselWrapper>
                </div>
            </div>
        )
    }

    const desktopTemplateSelector = () => {
        return (
            <div className="VForm__otherDesignsContainer">
                <label className="VForm__sectorTitle">Otros diseños disponibles</label>
                <div className="VForm__templatesGrid">
                    {templateNames.map((templateName, index) => (
                        <div className="VForm__templateCardContainer" onClick={() => handleTemplateClick(index)}
                             key={index}>
                            <TemplateSelect
                                name={templateName}
                                templateId={index}
                            />
                        </div>
                    ))}
                </div>
            </div>
        )
    }


    return (
        <React.Fragment>
            <div className="VForm__container">
                <div className="VForm__templateSelectedContainer">
                    <label className="VForm__sectorTitle">
                        Diseño elegido para tu plataforma
                    </label>
                    <div className="VForm__templateSelectedPreview" onClick={() => setShowPreview(true)}>
                        <TemplateSelect templateId={values.template} name={templateNames[values.template]}
                                        highlight={true}/>
                    </div>
                </div>
                {isMobile ? mobileCarouselTemplateSelector() : desktopTemplateSelector()}
                {showPreview && <TemplateViewer templateId={values.template} name={templateNames[values.template]}
                                                closeAction={() => setShowPreview(false)}/>}
            </div>
        </React.Fragment>
    );
}
