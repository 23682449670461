import Tabs from "../../../../components/Tabs/Tabs";
import CompanySelectorItem from "../../CompanySelectorItem/CompanySelectorItem";
import {PopUpCardModal} from "../../../../layouts/PopUpCardModal";
import React, {useContext} from "react";
import UserContext from "../../../../context/UserContext";
import CotizarContext from "../../../../context/CotizarContext";
import {NewIndicator} from "../../../../components/NewIndicator";

function MasterSelector(props) {
    const {
        selectedCompaniesCompleteInfo,
        selectedCompanyKeys,
        handleSelection,
        handlePromoSelection,
        openMoreInfo,
        setOpenMoreInfo,
        productBeingModified
    } = props;
    const {userCompaniesToPromote} = useContext(UserContext);
    const {companiesDict} = useContext(CotizarContext);
    const companiesToPromote = userCompaniesToPromote.filter(company => {
        const companyProducts = companiesDict[company].products;
        return companyProducts.some(product => product.key === productBeingModified);
    })

    return (
        <div className="csm__body">
            <Tabs
                tabs={[<p className="csm__text">Mis Compañías</p>,
                    <div className="cotizacion__linkText__container">
                        <p className="csm__text">Otras Compañías</p>
                        {userCompaniesToPromote.includes("Federacion_Patronal") && <NewIndicator size="tiny" color="yellow"/>}
                    </div>
                ]}
                tabContent={[
                    <div className="csm__tabsContentContainer">
                        {selectedCompaniesCompleteInfo?.map((company, idx) => (
                            <CompanySelectorItem
                                index={idx}
                                companyKey={company.id}
                                selected={company.selected}
                                callback={handleSelection}
                            />
                        ))}
                    </div>,
                    <div className="csm__tabsContentContainer">
                        {companiesToPromote?.map((company, idx) => {
                            const companyId = companiesDict[company].id
                            const isSelected = selectedCompanyKeys.includes(companyId);
                            return (
                                <CompanySelectorItem
                                    index={idx}
                                    companyKey={companyId}
                                    selected={isSelected}
                                    callback={(idx, selected) => handlePromoSelection(companyId)}
                                />
                            )
                        })}
                    </div>
                ]}
            />
            {openMoreInfo && <PopUpCardModal title={<h1 className="csm__title">Compañías Promocionadas</h1>}
                                             body={<div className="csm__body">
                                                 <p className="csm__text">
                                                     Disfrute la experiencia de cotizar su producto con una de
                                                     nuestras compañías asociadas.
                                                 </p>
                                             </div>}
                                             closeAction={() => setOpenMoreInfo(false)}/>}
        </div>
    )
}

export default MasterSelector;