import "./NotificationsDetail.css"
import moment from "moment";

function NotificationsDetail(props) {
    const {content} = props;

    return (
        <div className="NDetail__container">
            <span className="NDetail__date">{moment(content.created_at).format("DD/MM/YYYY - HH:mm") + " hs."}</span>
            <div>
                <p className="NDetail__title">{content.titulo}
                </p>
                <p className="NDetail__body"> {content.mensaje} </p>
            </div>
        </div>
    )
}

export default NotificationsDetail;
