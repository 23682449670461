import React, {useEffect, useState} from 'react';
import {at} from 'lodash';
import {useField, useFormikContext} from 'formik';
import {TextField, useMediaQuery} from '@material-ui/core';

export function useFastField(props) {
    const [field, meta, setFieldValue] = useField(props);
    const {notCobertura} = props;
    const {values} = useFormikContext();
    const [value, setValue] = useState(field.value);

    function currencyFormat(numString) {
        numString = numString.toString().replace(/\./g, "")
        numString = numString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return numString
    }

    field.value = currencyFormat(value);
    field.onChange = (e) => {
        if (e && e.currentTarget) {
            setValue(e.currentTarget.value);
            setFieldValue.setTouched(e.currentTarget)
            setFieldValue.setValue(e.currentTarget.value.toString().replace(/\./g, ""))
        }
    };

    useEffect(() => {
        if (!notCobertura) {
            const coberturaType = field.name.split("[")[0]
            const coberturaIndex = field.name.charAt(field.name.indexOf("[") + 1)
            if (values[coberturaType][coberturaIndex] !== undefined) {
                const newValue = values[coberturaType][coberturaIndex]['sumaAsegurada'].toString().replace(/\./g, "")
                setValue(newValue)
            }
        }
    }, [values.coberturas, values.coberturasAdicionales]);

    return [field, meta];
}

export default function NumberField(props) {
    const {errorText, ...rest} = props;
    const [field, meta] = useFastField(props);
    const isMobile = useMediaQuery('(max-width:500px)');

    function _renderHelperText() {
        let [touched, error] = at(meta, 'touched', 'error');
        if (touched && error) {
            if (errorText !== null) {
                return errorText
            } else {
                return error;
            }
        }
    }

    return (
        <TextField
            InputLabelProps={{style: isMobile ? {fontSize: 12} : {fontSize: 18}}}
            error={meta.touched && meta.error}
            helperText={_renderHelperText()}
            {...field}
            {...rest}
        />
    );
}
