import React, {useContext} from 'react';
import './CommissionsDetailsModal.css';
import {CustomModal} from "../../../components/CustomModal";
import {FormCard} from "../../../components/FormCard";
import {ReactComponent as CloseIcon} from "../../../assets/icons/ic_close.svg";
import AuthContext from "../../../context/AuthContext";
import PolkistaDetailsBody from "./DetailsBody/PolkistaDetailsBody";
import MasterDetailsBody from "./DetailsBody/MasterDetailsBody";
import {commissionColumns, commissionOtrosColumns} from "./tableColumns";

function CommissionsDetailsModal(props) {
    const {onClose, polizas} = props;
    const {isMaster} = useContext(AuthContext)

    return (
        <CustomModal>
            <div className="comDetModal__wrapper">
                <FormCard>
                    <div className="comDetModal__container">
                        <div className="comDetModal__icon__container" onClick={onClose}>
                            <CloseIcon className="comDetModal__icon__close"/>
                        </div>
                        <p className="comDetModal__title">Detalle de incentivos</p>
                        {isMaster ?
                            <MasterDetailsBody commissionColumns={commissionColumns}
                                               commissionOtrosColumns={commissionOtrosColumns}
                                               polizasEmisiones={polizas.emisiones}
                                               polizasOtrosIncentivos={polizas.otros_incentivos}/>
                            :
                            <PolkistaDetailsBody commissionColumns={commissionColumns}
                                                 polizasEmisiones={polizas.emisiones}/>
                        }
                    </div>
                </FormCard>
            </div>
        </CustomModal>
    );
}

export default CommissionsDetailsModal;