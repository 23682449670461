import React, {useContext} from 'react';
import './Header.css';
import {ProfileMenu} from "../../features/ProfileMenu";
import {ReactComponent as BurguerIcon} from "../../assets/icons/ic_menu.svg";
import UserContext from "../../context/UserContext";
import {Notifications} from "../../features/Notifications"
import {AcademyButton} from "../../features/Academy/AcademyButton";
import {ReactComponent as WappIcon} from "../../assets/icons/ic_whatsapp.svg";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from '../../components/Button';
import AuthContext from '../../context/AuthContext';

function Header() {
    const {setSidebarOpen, userName, userSexo, isMobile} = useContext(UserContext);
    const {isMaster} = useContext(AuthContext);
    const history = useHistory();

    function welcome() {
        if (userSexo === "hombre") return "¡Bienvenido"
        else if (userSexo === "mujer") return "¡Bienvenida"
        else return "¡Hola";
    }

    const welcomeText = welcome();

    const HeaderButton = () => {
        return (
            isMaster ?
                <Button
                    variant="filled"
                    color="primary"
                    enabled
                    onClick={(e) => {
                        e.stopPropagation();
                        history.push("/u/m/misaseguradoras")
                    }}
                >
                    Mis Aseguradoras
                </Button>
                :
                <AcademyButton />
        )
    }

    return (
        <div className="layout__header__container">
            <div className="layout__header__container--menu">
                <div className="layout__header__menu">
                    <BurguerIcon className="layout__header__burguer"
                                 onClick={() => setSidebarOpen(true)}
                    />
                </div>
                <h2 className="layout__header__title">
                    {welcomeText} <span className="layout__header__title--bold">{userName}</span>!
                </h2>
            </div>
            <div className="layout__header__container--actions">
                <div className="layout__header__icon__container">
                    {!isMobile && <HeaderButton/>}
                </div>
                <div className="layout__header__icon__container">
                    <WappIcon
                        className="layout__header__icon"
                        onClick={() => window.open("https://wa.me/+5493518687927", "_blank")}
                    />
                </div>
                <div className="layout__header__icon__container">
                    <Notifications/>
                </div>
                <ProfileMenu/>
            </div>
        </div>
    );
}

export default Header;