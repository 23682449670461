import {gql} from "@apollo/client";

const INSERT_NEW_DESCRIPTION = gql`
    mutation InsertDescripcion($Texto: String) {
      insert_CanalDigital_Descripcion(objects: {texto: $Texto}) {
        affected_rows
        returning {
          id
          texto
        }
      }
    }
   `

const UPDATE_DESCRIPTION = gql`
    mutation UpdateDescripcion($Texto: String, $id: Int) {
      update_CanalDigital_Descripcion(where: {id: {_eq: $id}}, _set: {texto: $Texto}) {
        returning {
          id
        }
      }
    }
    `

function insertCustomDescription(insertDescription, customDescription) {
    return new Promise((resolve, reject) => {
        insertDescription({
            context: {
                headers: {
                    "X-Hasura-Role": "master",
                },
            },
            variables: {
                Texto: customDescription,
            },
        }).then((resp) => {
            resolve(resp['data']['insert_CanalDigital_Descripcion']['returning'][0]);
        }).catch((err) => {
            reject(err);
        });
    });
}

function updateCustomDescription(updateDescription, customDescription, updateId) {
    return new Promise((resolve, reject) => {
        updateDescription({
            context: {
                headers: {
                    "X-Hasura-Role": "master",
                },
            },
            variables: {
                Texto: customDescription,
                id: updateId
            },
        }).then((resp) => {
            resolve(resp);
        }).catch((err) => {
            reject(err);
        });
    });
}

const saveDescriptionsHandler = async (
    customDescriptionObj,
    descripciones,
    defaultDescripcionesId,
    initialDescriptions,
    insertDescription,
    updateDescription,
    receivedData
) => {
    let jsonData = {...receivedData};
    //First condition: if there is a custom description selected on values
    const isThereCustomDescription = customDescriptionObj['selected'];
    //Second condition: if there was an initial custom description
    let alreadyHasCustomDescription = false;
    let customDescriptionId = null; //It could be used to check the same. Just kept for easier understanding
    //Third condition: if there is a custom description and it has changed
    let hasChangedCustomDescription = false;

    if (initialDescriptions) { //Calculate conditions when editing
        customDescriptionId = initialDescriptions.find(id => !defaultDescripcionesId.includes(id));
        if (customDescriptionId) {
            alreadyHasCustomDescription = true;
            hasChangedCustomDescription = descripciones[customDescriptionId] !== customDescriptionObj['text'];
        }
    }

    if (isThereCustomDescription) {
        if (!alreadyHasCustomDescription) {
            //Insert new custom description
            const resp = await insertCustomDescription(insertDescription, customDescriptionObj['text']);
            jsonData['descripcion'] = [...jsonData['descripcion'], resp['id']]
        } else if (alreadyHasCustomDescription && hasChangedCustomDescription) {
            //Update custom description
            await updateCustomDescription(updateDescription, customDescriptionObj['text'], customDescriptionId);
            jsonData['descripcion'] = [...jsonData['descripcion'], customDescriptionId]
        } else {
            //Do nothing, custom description is the same
            jsonData['descripcion'] = [...jsonData['descripcion'], customDescriptionId]
        }
    } else {
        //Do nothing, there is no custom description
    }

    return jsonData;
}

export {INSERT_NEW_DESCRIPTION, UPDATE_DESCRIPTION, saveDescriptionsHandler}