import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '../components/Button';
import Typography from '../components/Typography';
import {Grid} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import imagen
    from "../../assets/images/landing/depositphotos-238390814-stock-illustration-vector-city-street-map-texture@3x.png";
import {copyrightGenerator} from "../utils/copyrightGenerator";


const styles = (theme) => ({
    divFooter: {
        width: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center",
        backgroundColor: '#383442',

        [theme.breakpoints.down('xl')]: {
            height: '150px'
        },
        [theme.breakpoints.between(1100, 1290)]: {
            height: '150px'
        },
        [theme.breakpoints.down('sm')]: {
            height: '200px',
        },
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
        },
        backgroundImage: `url(${imagen})`,
        backgroundPosition: 'top center',
        backgroundSize: 'cover',
        padding: '30px'
    },
    gridFooter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    titulo: {
        fontFamily: "Ubuntu",
        fontSize: 34,
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: -1.33,
        color: "#ffffff",
        [theme.breakpoints.down('sm')]: {
            fontSize: 30,
            paddingBottom: '30px'
        },

    },
    texto: {
        fontFamily: "Ubuntu",
        fontSize: 24,
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "center",
        color: "#ffffff",


        [theme.breakpoints.between(1100, 1290)]: {
            fontSize: 22,
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
    },
    gridContacto: {
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'

        },
    },
    botonGrid: {
        backgroundColor: '#62c08a',
        boxShadow: 'inset 0 0 8px 0 rgba(112, 232, 163, 0.5)',
        '&:hover': {
            backgroundColor: '#62c08a',
            borderColor: '#62c08a',
            boxShadow: 'none',
        },
        marginBottom: '20px'
    },
    botonGridTexto: {
        textAlign: 'center',
        fontSize: '17px',
        color: '#fff',
        fontFamily: 'Ubuntu',
        fontWeight: 'bold',
        textShadowColor: "rgba(82, 82, 82, 0.15)",
        textShadowOffset: {
            width: 0,
            height: 1
        },
        textShadowRadius: 2,
        textDecoration: 'underline #62c08a'
    },
});


function Contacto2(props) {
    const {classes} = props;


    const Mailto = ({ email, subject = '', body = '', children }) => {
        let params = subject || body ? '?' : '';
        if (subject) params += `subject=${encodeURIComponent(subject)}`;
        if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

        return <a href={`mailto:${email}${params}`}>{children}</a>;
    };


    return (
        <>
            <div className={classes.divFooter}>
                <Grid container direction={"row"} className={classes.gridFooter}>

                    <Grid item container direction={"column"} xl={5} lg={9} md={8} sm={8} xs={12}>
                        <Grid item>
                            <Typography className={classes.titulo}>
                                Contacto
                            </Typography>

                        </Grid>
                        <Grid item>
                            <Typography className={classes.texto}>
                                Puedes comunicarte con nosotros a través del boton de contacto de la derecha
                            </Typography>

                        </Grid>
                    </Grid>
                    <Grid item xl={1} lg={1} md={2} sm={3} xs={12} className={classes.gridContacto}>
                                     <Button className={classes.botonGrid}>

                                         <Mailto
                                             body = 'Estimados, buenas tardes.

                                             Mi nombre es
                                             Me gustaria ponerme en contacto con ustedes para consultarles sobre
                                             Desde ya muchas gracias.'
                                             subject = 'Consulta Polko'
                                             email = 'polko@gmail.com'

                                         >
                                             <Typography
                                                 className={classes.botonGridTexto}>
                                                 Consultar
                                             </Typography>
                                         </Mailto>
                                     </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Box pt={4} style={{width: '100%'}}>
                            {copyrightGenerator}
                        </Box>
                    </Grid>
                </Grid>

            </div>
        </>)
}

Contacto2.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Contacto2);