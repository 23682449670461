import axios from "axios";
import showNotification from "./showNotification";

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null
};

const handleDownloadDocs = (url, company, document, identificadores, authToken, userCompanyCodes) => {
    let body = {
        "company": company,
        "documento": document,
    };

    if (userCompanyCodes) {
        body = {
            ...body,
            ...userCompanyCodes
        };
    }

    identificadores.map(id => {
        body[id.key] = id.valor.toString()
    })

    let headers = {
        'Content-Type': 'application/pdf',
        'Authorization': `Bearer ${authToken}`,
    };

    let config = {headers: headers, responseType: "blob"};

    return axios.post(url, body, config)
        .then(res => {
            const documentUrl = URL.createObjectURL(res.data);
            openInNewTab(documentUrl);
        }).catch(err => {
            showNotification({
                autoClose: 3000,
                color: 'red',
                status: 'error',
                title: 'Error al descargar la documentación.',
                message: err.response?.data?.detail || err.toString() || "Hubo un problema en la comunicación con el servicio.",
            });
        });
};

export {handleDownloadDocs};