import {CustomModal} from "../../../../components/CustomModal";
import {ReactComponent as CloseIcon} from "../../../../assets/icons/ic_close.svg";
import {ReactComponent as StarFilled} from "../../../../assets/icons/ic_star_filled.svg";
import React, {useContext, useEffect, useState} from "react";
import './AgreementPopUp.css'
import {useDisclosure} from '@mantine/hooks';
import {CustomAgreement} from "./CustomAgreement";
import CardFrame from "../../../../components/CardFrame/CardFrame";
import {Button} from "../../../../components/Button";
import AuthContext from "../../../../context/AuthContext";
import showNotification from "../../../../modules/utils/showNotification";
import axios from "axios";
import {addDays, format} from "date-fns";
import {Loading} from "../../../../components/Loading";
import DashboardContext from "../../../../context/DashboardContext";
import {currencyFormat} from "../../../../modules/utils/currencyFormat";
import {AgreementCardColumn} from "./AgreementCardColumn";
import {ConfirmAgreement} from "./ConfirmAgreement";

const MYWALLET_DEFAULT_AGREEMENTS = 'getAcuerdosResp';

const AgreementPopUp = (props) => {
    const {closeAction} = props;
    const [agreements, setAgreements] = useState(null);
    const [selectedCard, setSelectedCard] = useState(0);
    const [customAgreement, setCustomAgreement] = useState({});
    const [data, setData] = useState(null);
    const customAgreementIndex = agreements?.length - 1;

    const {authToken} = useContext(AuthContext);
    const {setCurrentAcuerdo} = useContext(DashboardContext);

    const [opened, {toggle}] = useDisclosure(false);
    const [popupIsLoading, setPopupIsLoading] = useState(true);
    const [buttonIsLoading, setButtonIsLoading] = useState(false);
    const [renderConfirmModal, setRenderConfirmModal] = useState(false);

    useEffect(() => {
        const fetchAcuerdos = async () => {
            if (!sessionStorage.getItem(MYWALLET_DEFAULT_AGREEMENTS)) {
                let url = process.env.REACT_APP_BACK_SERVER + '/getAcuerdos';
                try {
                    const response = await axios.get(url, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${authToken}`,
                        }
                    });
                    sessionStorage.setItem(MYWALLET_DEFAULT_AGREEMENTS, JSON.stringify(response.data));
                } catch (err) {
                    closeAction();
                    showNotification({
                        autoClose: 4000,
                        color: 'red',
                        status: 'error',
                        title: 'No se han podido cargar los acuerdos disponibles.',
                        message: 'Intente más tarde',
                    });
                }
            }
        }
        
        fetchAcuerdos().then(() => {
            const defaultAgreements = JSON.parse(sessionStorage.getItem(MYWALLET_DEFAULT_AGREEMENTS));
            setData(defaultAgreements);
            setAgreements(defaultAgreements);
            setPopupIsLoading(false);
        })
    }, []);

    useEffect(() => {
        if (data) {
            setAgreements([...data, {
                "emisiones": customAgreement.cantidadEmisiones,
                "monto": -1,
                "dias": customAgreement.cantidadMeses * 30,
                "motivo": customAgreement.motivo,
                "plus": null,
            },]);
        }
    }, [data, customAgreement]);

    const handleBack = () => {
        setRenderConfirmModal(false);
    }

    const sendAgreement = (data) => {
        setButtonIsLoading(true);
        const body = {
            ...data,
            "inicio": format(new Date, "yyyy-MM-dd'T'HH:mm:ss"),
            "estado": selectedCard === customAgreementIndex ? "PENDIENTE" : "EN CURSO",
        }

        let url = process.env.REACT_APP_BACK_SERVER + '/createAcuerdo';
        axios.post(url, body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            }
        }).then(() => {
            closeAction();
            setCurrentAcuerdo(
                {
                    "emisiones_success": [],
                    "emisiones_pending": [],
                    "emisiones": agreements[selectedCard].emisiones,
                    "dias": agreements[selectedCard].dias,
                    "estado": selectedCard === customAgreementIndex ? "PENDIENTE" : "EN CURSO",
                    "monto": agreements[selectedCard].monto,
                    "inicio": format(new Date, "yyyy-MM-dd'T'HH:mm:ss"),
                },
            );
            showNotification({
                autoClose: 4000,
                color: 'green',
                status: 'success',
                title: 'El acuerdo se registró con éxito.',
            });
        }).catch(err => {
            showNotification({
                autoClose: 4000,
                color: 'red',
                status: 'error',
                title: 'El acuerdo no se ha podido registrar.',
                message: 'Intente más tarde',
            });
        }).finally( () => {
            setButtonIsLoading(false);
        });
    }

    return (
        <CustomModal>
            <div className="AgreementsPopUp__container">
                <CloseIcon className="AgreementsPopUp_iconClose" onClick={closeAction}/>
                {popupIsLoading
                    ?
                        <Loading/>
                    :
                    renderConfirmModal ?
                            <ConfirmAgreement buttonIsLoading={buttonIsLoading} sendAgreement={sendAgreement} selectedAgreement={agreements[selectedCard]} handleBack={handleBack}/>
                            :
                    <div className="AgreementsPopUp">
                        <div className="AgreementsPopUp__header">
                            <h1 className="AgreementsPopUp__header--title-small">Incentivo</h1>
                            <h1 className="AgreementsPopUp__roundedBoxFilled AgreementsPopUp__header--title">
                                {
                                    selectedCard === customAgreementIndex ? <em>Pendiente</em> :
                                        <>
                                            <div>
                                                ${currencyFormat(agreements[selectedCard]?.monto)}
                                            </div>
                                            {agreements[selectedCard]?.plus &&
                                                <div className="AgreementsPopUp__roundedBoxFilled-plus">
                                                    +${currencyFormat(agreements[selectedCard].plus)}
                                                </div>
                                            }
                                        </>
                                }
                            </h1>
                            <h2 className="AgreementsPopUp__header--subtitle">Emitiendo <b>{selectedCard !== customAgreementIndex ?
                                agreements[selectedCard].emisiones : (customAgreement.cantidadEmisiones || "-")} pólizas</b>
                            </h2>
                        </div>
                        <div className="AgreementCards">
                            {data.map((row, index) => {
                                return (
                                    <CardFrame highlight={selectedCard === index}
                                               onClick={() => setSelectedCard(index)}
                                               withBanner={row.plus}
                                               bannerContent={
                                                   <div className={"Agreement__banner"}>
                                                       <StarFilled className={"StarFilled__Icon"}/> Aprovechá un plus de
                                                       ${currencyFormat(Number(row.plus))} en esta opción
                                                   </div>}>
                                        <div className="AgreementCard__container">
                                            <AgreementCardColumn header={"Incentivo"} value={`$${currencyFormat(row.monto)}`}/>
                                            <AgreementCardColumn header={"Objetivo"} value={row.emisiones} bigValue={true}/>
                                            <AgreementCardColumn header={"Días"} value={row.dias}/>
                                            <AgreementCardColumn header={"Finaliza"}
                                                    value={format(addDays(new Date, row.dias), 'dd/MM/yyyy')}/>
                                        </div>
                                    </CardFrame>
                                );
                            })}
                            {<CustomAgreement highlight={selectedCard === customAgreementIndex} opened={opened}
                                              toggle={toggle}
                                              onClick={() => {
                                                  setSelectedCard(customAgreementIndex);
                                              }
                                              }
                                              setData={setCustomAgreement}/>
                            }
                        </div>

                        <Button type="button"
                            enabled={selectedCard !== customAgreementIndex ||
                                (selectedCard === customAgreementIndex 
                                    && !opened
                                    && JSON.stringify(customAgreement) !== '{}')} 
                            variant="filled"
                            color="primary"
                            onClick={() => {
                                setRenderConfirmModal(true);
                            }}>
                            Siguiente
                        </Button>
                    </div>}
            </div>
        </CustomModal>
    );
}

export default AgreementPopUp;