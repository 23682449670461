import React from 'react';
import './BikePhotosDialog.css';
import {CustomModal} from "../../../components/CustomModal";
import {PopUpCard} from "../../../components/PopUpCard";
import bikePhoto from '../../../assets/_images/bike_photo_dialog.png';
import scooterPhoto from '../../../assets/_images/scooter_photo_dialog.png';
import {ReactComponent as CloseIcon} from "../../../assets/icons/ic_close.svg";

function BikePhotosDialog({onClose}) {
    return (
        <CustomModal>
            <PopUpCard>
                <div className="bikePhDialog__container">
                    <div className="bikePhDialog__icon__container" onClick={onClose}>
                        <CloseIcon className="bikePhDialog__icon"/>
                    </div>
                    <p className="bikePhDialog__title">Pautas de suscripción Bicicletas y/o Monopatines:</p>
                    <ul className="bikePhDialog__text">
                        <li>Fotos del bien donde se aprecie el estado del mismo.</li>
                        <li>Fotos del número de serie, en caso de poseerlo.</li>
                        <li>Fotos de la factura de compra. En caso de no poseerla disponer de print de pantalla con
                            el valor de referencia del bien en el mercado o presupuesto autentificado.
                        </li>
                    </ul>

                    <div className="bikePhDialog__block">
                        <p className="bikePhDialog__note">*El número de serie de una bicicleta suele estar situado en la parte baja del cuadro, en el
                            lateral del tubo vertical, o en la zona del eje de pedalier.</p>
                    </div>

                    <div className="bikePhDialog__block">
                        <img src={bikePhoto} alt="" className="bikePhDialog__image"/>
                        <img src={scooterPhoto} alt="" className="bikePhDialog__image"/>
                    </div>
                </div>
            </PopUpCard>
        </CustomModal>
    );
}

export default BikePhotosDialog;