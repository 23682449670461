import * as Yup from "yup";

const DetallesModel = () => {
    const formField = {
        archivosBici: {
            name: 'archivosBici',
            label: 'Archivos adjuntos'
        },
        archivosElectronicos: {
            name: 'archivosElectronicos',
            label: 'Archivos adjuntos'
        },
        detalleEquipoElectronico: { //Object
            name: 'detalleEquipoElectronico',
        },
        descripcionElectronico: {
            name: 'descripcionElectronico',
            label: 'Descripción',
            requiredErrorMsg: 'Debe completar este campo'
        },
        sumaAseguradaElectronico: {
            name: 'sumaAseguradaElectronico',
            label: 'Suma Asegurada',
            requiredErrorMsg: 'Debe completar este campo'
        },
        codigoElectronico: {
            name: 'codigoElectronico',
        },
        codigoGrupoElectronico: {
            name: 'codigoGrupoElectronico',
        },

        detalleBicicletas: { //Object
            name: 'detalleBicicletas',
        },
        descripcionBicicletas: {
            name: 'descripcionBicicletas',
            label: 'Descripción',
            requiredErrorMsg: 'Debe completar este campo'
        },
        sumaAseguradaBicicletas: {
            name: 'sumaAseguradaBicicletas',
            label: 'Suma Asegurada',
            requiredErrorMsg: 'Debe completar este campo'
        },
        codigoBicicletas: {
            name: 'codigoBicicletas',
        },
        codigoGrupoBicicletas: {
            name: 'codigoGrupoBicicletas',
        }
    }

    const initialValues = {
        [formField.archivosBici.name]: [],
        [formField.archivosElectronicos.name]: [],
        [formField.detalleEquipoElectronico.name]: [
            {
                [formField.descripcionElectronico.name]: "",
                [formField.sumaAseguradaElectronico.name]: "",
                [formField.codigoElectronico.name]: 201,
                [formField.codigoGrupoElectronico.name]: 34,
            },
        ],
        [formField.detalleBicicletas.name]: [
            {
                [formField.descripcionBicicletas.name]: "",
                [formField.sumaAseguradaBicicletas.name]: "",
                [formField.codigoBicicletas.name]: 209,
                [formField.codigoGrupoBicicletas.name]: 31,
            },
        ],
    }

    let equipoElectronicoSelected = false;
    let bicicletasSelected = false;
    const validationSchema = Yup.object().shape({
        //DummyValue for trigger callback method
        dummyValue: Yup.string(),
        [formField.archivosBici.name]: Yup
            .array()
            .test('required-if-cobertura-48', 'Debe Ingresar al menos un archivo', (sumas, context) => {
                const coberturasAdicionales = context.parent.coberturasAdicionales;
                const files = context.parent.archivosBici;
                if (coberturasAdicionales['48'].selectedCobertura) {
                    return files.length > 0;
                } else {
                    return true
                }
            }),
        [formField.archivosElectronicos.name]: Yup
            .array()
            .test('required-if-cobertura-48', 'Debe Ingresar al menos un archivo', (sumas, context) => {
                const coberturasAdicionales = context.parent.coberturasAdicionales;
                const files = context.parent.archivosElectronicos;
                if (coberturasAdicionales['26'].selectedCobertura) {
                    return files.length > 0;
                } else {
                    return true
                }
            }),
        [formField.detalleBicicletas.name]: Yup.array().of(
            Yup.object().shape({
                [formField.sumaAseguradaBicicletas.name]: Yup.number()
                    .integer()
                    .typeError("Introduzca únicamente números")
                    .when("dummyValue", {
                        is: () => {
                            return bicicletasSelected === true
                        },
                        then: Yup.number()
                            .required(`${formField.sumaAseguradaBicicletas.requiredErrorMsg}`)
                            .typeError("Introduzca únicamente números")
                            .test('notZeroInsuredSum', "La suma debe ser superior a cero", (value) => value > 0)
                    }),
                [formField.descripcionBicicletas.name]: Yup.string()
                    .matches(/^[A-Za-z0-9 ,-]+$/, "Ingrese una descripción válida")
                    .when("dummyValue", {
                        is: () => {
                            return bicicletasSelected === true
                        },
                        then: Yup.string().required(`${formField.descripcionBicicletas.requiredErrorMsg}`)
                    }),
            })).test('insuredSumsAgreggationBicicletas', "Las sumas declaradas deben coincidir con las aseguradas", (sumas, context) => {
                const coberturas = context.parent.coberturasAdicionales;
                let sumaAsegurada = 0;
                bicicletasSelected = false

                if (coberturas["48"].selectedCobertura) {
                    bicicletasSelected = true
                    sumaAsegurada = coberturas["48"].sumaAsegurada
                } else return true

                let totalInsuredValue = 0;
                sumas.map((item) => {
                    if (item.sumaAseguradaBicicletas !== undefined)
                        totalInsuredValue += parseInt(item.sumaAseguradaBicicletas.toString().replace(/\./g, ""));
                })

                return (sumaAsegurada === totalInsuredValue)
            }
        ),
        [formField.detalleEquipoElectronico.name]: Yup.array().of(
            Yup.object().shape({
                [formField.sumaAseguradaElectronico.name]: Yup.number()
                    .integer()
                    .typeError("Introduzca únicamente números")
                    .when("dummyValue", {
                        is: () => {
                            return equipoElectronicoSelected === true
                        },
                        then: Yup.number()
                            .required(`${formField.sumaAseguradaElectronico.requiredErrorMsg}`)
                            .typeError("Introduzca únicamente números")
                            .test('notZeroInsuredSum', "La suma debe ser superior a cero", (value) => value > 0)
                    }),
                [formField.descripcionElectronico.name]: Yup.string()
                    .matches(/^[A-Za-z0-9 ,-]+$/, "Ingrese una descripción válida")
                    .when("dummyValue", {
                        is: () => {
                            return equipoElectronicoSelected === true
                        },
                        then: Yup.string().required(`${formField.descripcionElectronico.requiredErrorMsg}`)
                    }),
            })).test('insuredSumsAgreggationElectrónicos', "Las sumas declaradas deben coincidir con las aseguradas", (sumas, context) => {
                //TODO: adjust validation
                const coberturas = context.parent.coberturasAdicionales;
                let sumaAsegurada = 0;
                equipoElectronicoSelected = false

                if (coberturas["26"].selectedCobertura) {
                    equipoElectronicoSelected = true
                    sumaAsegurada = coberturas["26"].sumaAsegurada
                } else return true

                let totalInsuredValue = 0;
                sumas.map((item) => {
                    if (item.sumaAseguradaElectronico !== undefined)
                        totalInsuredValue += parseInt(item.sumaAseguradaElectronico.toString().replace(/\./g, ""));
                })

                return (sumaAsegurada === totalInsuredValue)
            }
        ),
    })

    return {formField, initialValues, validationSchema}
}

export {DetallesModel};
