import React, {useState} from 'react';
import './CustomSlider.css';
import {Slider} from "@mantine/core";

function CustomSlider(props) {
    const {form: {setFieldValue}, field, max, interval, min} = props;
    const [sliderValue, setSliderValue] = useState(field.value);

    let marks = [];

    for(let i=0; i<=max; i+=interval){
        let item = {value: i, label: `${i}%`};
        marks.push(item)
    }

    const saveValue = (v) => {
        setFieldValue(field.name, v);
    }

    return (
        <Slider
            classNames={{
                root: "customSlider__root",
                track: "customSlider__track",
                mark: "customSlider__mark",
                markLabel: "customSlider__markLabel",
                thumb: "customSlider__thumb",
                bar: "customSlider__bar",
                // label: "customSlider__label",
                // dragging: "customSlider__dragging",
            }}
            name={field.name}
            min={min}
            max={max}
            marks={marks}
            value={sliderValue}
            onChange={setSliderValue}
            onChangeEnd={(v) => saveValue(v)}/>
    );
}

export default CustomSlider;