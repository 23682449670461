/**
 * Divide una cadena de texto en dos partes, si es que encuentra un punto dentro del rango indicado.
 * @param text cadena de texto a dividir
 * @param startIndex índice de inicio del rango
 * @param endIndex índice de fin del rango
 * @returns {[undefined, undefined]|[undefined]} arreglo con dos cadenas de texto si se encontró un punto dentro del rango, o un arreglo con una cadena de texto si no se encontró un punto dentro del rango.
 */
const splitTextInRange = (text, startIndex, endIndex) => {
    const dotIndex = text.indexOf(".", startIndex);
    if (dotIndex > -1 && dotIndex < endIndex -1) { //-1 para excluir el punto final
        // se encontró un punto dentro del rango, dividir la cadena en dos partes
        const firstPart = text.slice(0, dotIndex + 1);
        const secondPart = text.slice(dotIndex + 1, text.length);
        return [firstPart, secondPart];
    } else {
        // no se encontró un punto dentro del rango, devolver la cadena completa
        return [text];
    }
}

export {splitTextInRange};