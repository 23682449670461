import React from 'react';
import './DescargablesColumn.css';
import TextLink from "../../../../components/TextLink/TextLink";

function DescargablesColumn(props) {
    const {row, setModalOpen} = props;

    const handleOpenModal = () => {
        setModalOpen({
            modalName: "Descargables",
            values: {row}
        })
    }

    return (
        <>
            <TextLink
                size={"sm"}
                action={() => handleOpenModal()}
            >
                Ver Descargables
            </TextLink>
        </>
    );
}

export default DescargablesColumn;