import * as Yup from 'yup';
import checkoutFormModel from './formModel';
import {CardNumberValidator} from "../../../../utils/cardNumberValidator";

const moment = require('moment');

const {
    formField: {
        vigenciaDesde,
        vigenciaHasta,
        frecuenciaPago,
        cuotas,
        ajusteAutomatico,
        formaDePago,
        infoDePago: {
            numeroCbu,
            marcaTarjeta,
            numeroTarjeta,
            // vencimientoTarjeta,
        },
        // emailDespachoElectronico,
        // despachoElectronico,
        moduloCobertura,
        bienesAsegurados: {
            id,
            descripcion,
            suma,
        },
        cliente: {
            rol,
            personaJuridica,
            dni,
            cuit,
            nombre,
            apellido,
            fechaNacimiento,
            estadoCivil,
            sexo,
            domicilio,
            codigosLocalidad,
            codigoArea,
            telefono,
            email,
            situacionIva,
            categoriaMonotributo,
            actividadPrincipal,
            lugarNacimiento
        },
        vehiculo: {
            patente,
            motor,
            chasis,
            marca,
            modelo,
            anio,
            tipoUso,
            valor,
            tieneGnc,
            ceroKm,
            equipoDeRastreo,
            gnc: {
                descripcionGnc,
                marcaRegulador,
                numeroRegulador,
                cilindros: {
                    marcaCilindro,
                    numeroCilindro,
                },
                valorTotal,
            },
            inspeccionPrevia: {
                fechaInspeccion,
                tipoCombustible,
                estadoCristales,
                resultadoInspeccion,
                kilometraje,
                colorVehiculo,
            },
        },
        comentariosSolicitud
    }
} = checkoutFormModel;
let tipoTarjeta;


export default [
    Yup.object().shape({
        [frecuenciaPago.name]: Yup.string().required(`${frecuenciaPago.requiredErrorMsg}`),
        [cuotas.name]: Yup.string().when([frecuenciaPago.name], {
            is: '7',
            then: Yup.string().required(`${cuotas.requiredErrorMsg}`)
        }),
        [ajusteAutomatico.name]: Yup.string().when([frecuenciaPago.name], {
            is: '7',
            then: Yup.string().required(`${ajusteAutomatico.requiredErrorMsg}`)
        }),
        [vigenciaDesde.name]: Yup.date()
            .required(`${vigenciaDesde.requiredErrorMsg}`)
            .min(moment().format('YYYY-MM-DD'), "La fecha no puede ser anterior al dia de hoy")
            .max(moment().add(45, "days").format('YYYY-MM-DD'), "La fecha no puede superar los 45 dias"),
        [vigenciaHasta.name]: Yup.date()
            .when([frecuenciaPago.name], {
                is: '7',
                then: Yup.date()
                    .required(`${ajusteAutomatico.requiredErrorMsg}`)
                    .when([vigenciaDesde.name], (vigenciaDesde, schema) => schema.max(moment(vigenciaDesde).add(6, "M"),
                        "Las fechas deben tener una separación máxima de 6 meses"))
            })
            .when([frecuenciaPago.name], {
                is: '5',
                then: Yup.date()
                    .required(`${ajusteAutomatico.requiredErrorMsg}`)
                    .when([vigenciaDesde.name], (vigenciaDesde, schema) => schema.min(moment(vigenciaDesde).add(1, "Y"),
                        "Las fechas deben tener una separación mínima de un año"))
            }),
        [formaDePago.name]: Yup.string().required(`${formaDePago.requiredErrorMsg}`),
        infoDePago: Yup.object().when([formaDePago.name], {
            is: '4',    // Débito por CBU
            then: Yup.object().shape({
                [numeroCbu.name]: Yup.string()
                    .required(`${numeroCbu.requiredErrorMsg}`)
                    .matches(/^[0-9]+$/, "Ingrese un número válido")
                    .min(22, 'El número de CBU debe tener 22 dígitos')
                    .max(22, 'El número de CBU debe tener 22 dígitos'),
            })
        }).when([formaDePago.name], {
            is: '2',    // Tarjeta de crédito
            then: Yup.object().shape({
                [marcaTarjeta.name]: Yup.string().test('cardType', 'Seleccione un tipo de tarjeta', type => {
                    tipoTarjeta = type;
                    if (tipoTarjeta !== null) return true;
                }).required(`${marcaTarjeta.requiredErrorMsg}`),
                [numeroTarjeta.name]: Yup
                    .string()
                    .test('cardNumber',
                        'El número de tarjeta ingresado es inválido', //validation message
                        value => {
                            if (tipoTarjeta === "9") {
                                if (value !== undefined && value.length === 15)
                                    return CardNumberValidator(tipoTarjeta, value)
                            } else {
                                if (value !== undefined && value.length === 16)
                                    return CardNumberValidator(tipoTarjeta, value)
                            }
                        })
                    .required()
            })
        }),
        [moduloCobertura.name]: Yup.string().required(`${moduloCobertura.requiredErrorMsg}`),
    }),
    Yup.object().shape({
        clientes: Yup.array().of(
            Yup.object().shape({
                [rol.name]: Yup.string().required(`${rol.requiredErrorMsg}`),
                [dni.name]: Yup.number().when([personaJuridica.name], {
                    is: '0',
                    then: Yup.number()
                        .required(`${dni.requiredErrorMsg}`)
                        .min(1000000, "El documento ingresado no es válido")
                        .max(99999999, "El documento ingresado no es válido")
                        .integer()
                        .typeError("Introduzca únicamente números"),
                }),
                [cuit.name]: Yup.string()
                    .required(`${cuit.requiredErrorMsg}`)
                    .matches(/^(20|23|27|30|33)([0-9]{9}|-[0-9]{8}-[0-9]{1})$/, "El CUIT ingresado no es válido"),

                [nombre.name]: Yup.string().when([personaJuridica.name], {
                    is: '0',
                    then: Yup.string()
                        .required(`${nombre.requiredErrorMsg}`)
                        .matches(/^[aA-zZ\s]+$/, "Ingrese un nombre válido")
                        .max(40, "El nombre ingresado es muy largo"),
                }),
                [apellido.name]: Yup.string()

                    .when([personaJuridica.name], {
                        is: '1',
                        then: Yup.string()
                            .required(`${apellido.requiredErrorMsg}`)
                            .matches(/^[aA-zZ.\s]+$/, "Ingrese una Razón Social válida")
                            .max(40, "La Razón Social ingresada no es valida"),
                    })
                    .when([personaJuridica.name], {
                        is: '0',
                        then: Yup.string()
                            .required(`${apellido.requiredErrorMsg}`)
                            .matches(/^[aA-zZ\s]+$/, "Ingrese un apellido válido")
                            .max(40, "El valor ingresado es muy largo")
                    }),
                [fechaNacimiento.name]: Yup.date().when([personaJuridica.name], {
                    is: '0',
                    then: Yup.date()
                        .max(new Date(Date.now() - 567648000000), "El cliente debe tener al menos 18 años para contratar")
                        .required(`${fechaNacimiento.requiredErrorMsg}`),
                }),
                [estadoCivil.name]: Yup.string().when([personaJuridica.name], {
                    is: '0',
                    then: Yup.string().required(`${estadoCivil.requiredErrorMsg}`),
                }),
                [sexo.name]: Yup.string().when([personaJuridica.name], {
                    is: '0',
                    then: Yup.string().required(`${sexo.requiredErrorMsg}`),
                }),
                [domicilio.name]: Yup.string()
                    .required(`${domicilio.requiredErrorMsg}`)
                    .matches(/^(?=[a-zA-Z0-9()_+=,.:\s-]*$)/, "Esta utilizando carácteres no válidos")
                    .min(6, 'Ingrese al menos 6 caracteres'),
                [codigosLocalidad.name]: Yup.string().required(`${codigosLocalidad.requiredErrorMsg}`),
                [codigoArea.name]: Yup.number()
                    .required(`${codigoArea.requiredErrorMsg}`)
                    .integer()
                    .min(10, 'Introduzca un código de área valido')
                    .max(9999, 'Introduzca un código de área valido')
                    .typeError("Introduzca únicamente números")
                    .test(
                        'no-leading-zero',
                        'Por favor ingrese el código de área sin 0',
                        (value, context) => {
                            return context.originalValue && !context.originalValue.startsWith('0');
                        }
                    ),
                [telefono.name]: Yup.number()
                    .required(`${telefono.requiredErrorMsg}`)
                    .integer()
                    .min(100000, 'Introduzca un número de celular valido')
                    .max(99999999, 'Introduzca un número de celular valido')
                    .typeError("Introduzca únicamente números")
                    .test(
                        'no-leading-zero',
                        'Por favor introduzca su número sin el 15',
                        (value, context) => {
                            return context.originalValue && !context.originalValue.startsWith('15');
                        }
                    ),
                [email.name]: Yup.string()
                    .required(`${email.requiredErrorMsg}`)
                    .email("Ingrese un email válido"),
                [situacionIva.name]: Yup.string().required(`${situacionIva.requiredErrorMsg}`),
                [categoriaMonotributo.name]: Yup.string().required(`${categoriaMonotributo.requiredErrorMsg}`),
                [actividadPrincipal.name]: Yup.string().when([personaJuridica.name], {
                    is: '0',
                    then: Yup.string().required(`${actividadPrincipal.requiredErrorMsg}`),
                }),
                [lugarNacimiento.name]: Yup.string().when([personaJuridica.name], {
                    is: '0',
                    then: Yup.string()
                        .required(`${lugarNacimiento.requiredErrorMsg}`)
                        .matches(/^([A-Za-z0-9 -]+)$/, "Esta utilizando carácteres no válidos"),
                }),
            })
        )
    }),
    Yup.object().shape({
        [comentariosSolicitud.name]: Yup.string().matches(/^(?=[a-zA-Z0-9()_+=,.\/%$:\s-]*$)/, "Por favor, evite caracteres especiales"),
        bienesAsegurados: Yup.array().of(
            Yup.object().shape({
                [id.name]: Yup.string().required(`${id.requiredErrorMsg}`),
                [descripcion.name]: Yup.string()
                    .required(`${descripcion.requiredErrorMsg}`)
                    .matches(/^([A-Za-z0-9 -]+)$/, "Esta utilizando carácteres no válidos"),
                [suma.name]: Yup.number()
                    .required(`${suma.requiredErrorMsg}`)
                    .min(30000, "El monto mínimo es $30.000")
                    .max(1000000, "El monto máximo es $1.000.000")
                    .typeError("El valor ingresado no es válido"),

            })
        ),
        vehiculo: Yup.object().shape({
            [patente.name]: Yup.string().when([ceroKm.name], {
                is: (val) => {
                    return (val !== 'true')
                },
                then:
                    Yup.string()
                        .required(`${patente.requiredErrorMsg}`)
                        .matches(/^[a-zA-Z]{2,2}[0-9]{3,3}[a-zA-Z]{2,2}|[a-zA-Z]{3,3}[0-9]{3,3}$/, "La patente ingresada no es válida"),
            }),
            [motor.name]: Yup.string()
                .required(`${motor.requiredErrorMsg}`)
                .min(4, 'El número ingresado es muy corto')
                .max(20, "El número ingresado es muy largo"),
            [chasis.name]: Yup.string()
                .required(`${chasis.requiredErrorMsg}`)
                .min(4, 'El número ingresado es muy corto')
                .max(20, "El número ingresado es muy largo"),

            [marca.name]: Yup.string().required(`${marca.requiredErrorMsg}`),
            [modelo.name]: Yup.string().required(`${modelo.requiredErrorMsg}`),
            [anio.name]: Yup.number()
                .required(`${anio.requiredErrorMsg}`)
                .integer()
                .min(2001, "El año ingresado es muy antiguo")
                .max(moment().format("YYYY"), "Ingrese un año válido")
                .typeError("Ingrese un año válido"),
            [tipoUso.name]: Yup.string().required(`${tipoUso.requiredErrorMsg}`),
            [valor.name]: Yup.number()
                .required(`${valor.requiredErrorMsg}`)
                .typeError("El valor ingresado no es válido"),
            [tieneGnc.name]: Yup.string().required(`${tieneGnc.requiredErrorMsg}`),
            [equipoDeRastreo.name]: Yup.string().required(`${equipoDeRastreo.requiredErrorMsg}`),
            [ceroKm.name]: Yup.string().when([anio.name], {
                is: moment().format('YYYY'),
                then: Yup.string().required(`${ceroKm.requiredErrorMsg}`),
            }),
            gnc: Yup.object().when([tieneGnc.name], {
                is: 'true',
                then: Yup.object().shape({
                    [descripcionGnc.name]: Yup.string()
                        .required(`${descripcionGnc.requiredErrorMsg}`)
                        .matches(/^([A-Za-z0-9 -]+)$/, "Esta utilizando carácteres no válidos"),
                    [marcaRegulador.name]: Yup.string()
                        .required(`${marcaRegulador.requiredErrorMsg}`)
                        .matches(/^([A-Za-z0-9 -]+)$/, "Esta utilizando carácteres no válidos"),
                    [numeroRegulador.name]: Yup.string()
                        .required(`${numeroRegulador.requiredErrorMsg}`)
                        .matches(/^([A-Za-z0-9 -]+)$/, "Esta utilizando carácteres no válidos"),
                    [valorTotal.name]: Yup.number()
                        .required(`${valorTotal.requiredErrorMsg}`)
                        .min(30000, "El monto mínimo es $30.000")
                        .max(2000000, "El monto máximo es $2.000.000"),
                    cilindros: Yup.array().of(
                        Yup.object().shape({
                            [marcaCilindro.name]: Yup.string()
                                .required(`${marcaCilindro.requiredErrorMsg}`)
                                .matches(/^([A-Za-z0-9 -]+)$/, "Esta utilizando carácteres no válidos"),
                            [numeroCilindro.name]: Yup.string()
                                .required(`${numeroCilindro.requiredErrorMsg}`)
                                .matches(/^([A-Za-z0-9 -]+)$/, "Esta utilizando carácteres no válidos"),
                        })
                    )
                })
            }),
            inspeccionPrevia: Yup.object().shape({
                [fechaInspeccion.name]: Yup.date().required(`${fechaInspeccion.requiredErrorMsg}`),
                [tipoCombustible.name]: Yup.string().required(`${tipoCombustible.requiredErrorMsg}`),
                [estadoCristales.name]: Yup.string().required(`${estadoCristales.requiredErrorMsg}`),
                [resultadoInspeccion.name]: Yup.string().required(`${resultadoInspeccion.requiredErrorMsg}`),
                [kilometraje.name]: Yup.number()
                    .required(`${kilometraje.requiredErrorMsg}`)
                    .integer()
                    .typeError("El valor ingresado no es válido"),
                [colorVehiculo.name]: Yup.string()
                    .required(`${colorVehiculo.requiredErrorMsg}`)
                    .matches(/^[A-Za-z ]+$/, "Utilice letras mayúsculas y números"),
                archivos: Yup.array().min(1, "Debe Ingresar al menos un archivo")
            }),
        })
    }),
    Yup.object().shape({})
];
