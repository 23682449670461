function linkSorter(initialArray) {
    let ordenArray = ['', '', '', ''];

    for (let link of initialArray) {
        if (link.includes('facebook')) {
            ordenArray[0] = link;
        } else if (link.includes('instagram')) {
            ordenArray[1] = link;
        } else if (link.includes('linkedin')) {
            ordenArray[2] = link;
        } else { // Other links
            ordenArray[3] = link;
        }
    }

    return ordenArray;
}

export {linkSorter}