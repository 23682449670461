import React, {useContext} from 'react';
import QuizContext from '../../context/QuizContext';

function Question() {
    const {state} = useContext(QuizContext);
    const {currentQuestion, questions} = state;
    const question = questions[currentQuestion];
    return <p className="AQuestion__text">{question.question}</p>;
}

export default Question;