import React, {useContext, useEffect, useState} from 'react';
import {useCurrentQuotationContext} from "../../../context/CurrentQuotationContext/CurrentQuotationContext";
import {FormCard} from "../../../components/FormCard";
import {Loading} from "../../../components/Loading";
import AuthContext from "../../../context/AuthContext";
import UserContext from "../../../context/UserContext";
import CotizarContext from "../../../context/CotizarContext";
import {FormRenderer} from "../../../components/FormRenderer";
import {EntradaCotizarModel} from "./FormStages/Cotizar/EntradaCotizar/EntradaCotizarModel";
import EntradaCotizarForm from "./FormStages/Cotizar/EntradaCotizar/EntradaCotizarForm";
import {useHistory, useLocation} from "react-router-dom";
import {
    AsistenciaViajeroContextProvider, withAsistenciaViajeroContextProvider,
    withAsistenciaViajeroContextReady
} from "../../../context/Products/AsistenciaViajeroContext";
import axios from "axios";
import {ErrorModal} from "../../../layouts/ErrorModal";
import {SuccessModal} from "../../../layouts/SuccessModal";
import {Form, Formik} from "formik";
import {MantineSelectField} from "../../../components/FormFields/MantineSelectField";
import {Button} from "../../../components/Button";
import {format} from "date-fns";
import {CotizarSuccessView} from "./FormStages/Cotizar/CotizarSuccess";
import ClienteForm from "./FormStages/Emitir/Cliente/ClienteForm";
import {ClienteModel} from "./FormStages/Emitir/Cliente/ClienteModel";
import SolicitudForm from "./FormStages/Emitir/Solicitud/SolicitudForm";
import {isResponseSuccessful} from "../../../modules/utils/isResponseSuccessful";
import {handleDownloadDocs} from "../../../modules/utils/handleDownloadDocs";
import showNotification from "../../../modules/utils/showNotification";
import "./AsistenciaViajero.css";
import FormaPagoForm from "./FormStages/Emitir/formaPago/FormaPagoForm";
import {FormaPagoModel} from "./FormStages/Emitir/formaPago/FormaPagoModel";
import nextStepsText from "./FormStages/Emitir/formaPago/nextStepsText";
import {RoundedPanel} from "../../../components/RoundedPanel";
import _ from "lodash";

function AsistenciaViajero(props) {
    const {performReset, action, recotizarValues} = props;
    const {
        setCotizacionResponse,
        currentFormData,
        setCurrentFormData,
        setFormData,
        formData,
        activeStep,
        setActiveStep,
        setEmisionResponse,
        emisionResponse,
        cotizacionResponse,
        setFinalValues,
    } = useCurrentQuotationContext();
    const {authToken, isMaster} = useContext(AuthContext);
    const {companiesDict} = useContext(CotizarContext);
    const {
        activeFormProduct,
        setActiveFormProduct,
        userDetalleComisiones,
        idMaster
    } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [errorModalContent, setErrorModalContent] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const location = useLocation();
    const history = useHistory();
    const downloadPdfJsonTemplate = ["nombrePais", "vigenciaDesde", "vigenciaHasta", "pasajeros", "travelDaysCounter"];
    const [emissionIdentifiers, setEmissionIdentifiers] = useState(null);
    const [emissionNextStepsText, setEmissionNextStepsText] = useState(null);
    const [vigenciaHastaMinFlagged, setVigenciaHastaMinFlagged] = useState(null);

    //Cotizar
    const {
        initialValues: entradaCotizarInitialValues,
        validationSchema: entradaCotizarValidations
    } = EntradaCotizarModel();
    const {initialValues: clienteInitialValues, validationSchema: clienteValidationSchema} = ClienteModel();
    const {initialValues: formaPagoInitialValues, validationSchema: formaPagoValidationSchema} = FormaPagoModel();

    const valuesAdjusterFromRecotizar = () => {
        if (recotizarValues) {
            const quotationData = {
                ...recotizarValues,
                ...recotizarValues.asistencia_viajero,
                _id: recotizarValues._id,
            }
            const {idCanalDigital, cliente, ...filteredQuotationData} = quotationData;
            if (recotizarValues.cliente !== "Sin datos") {
                filteredQuotationData.cliente = {
                    ...recotizarValues.cliente,
                }
            }
            return filteredQuotationData;
        } else return null
    }

    //Se define cada paso
    const initialQuotationForm = [
        {
            name: "Información",
            stepButtonName: "Cotizar",
            condition: true,
            form: <EntradaCotizarForm cotizacionRetomada={recotizarValues !== undefined}/>,
            initialValues: entradaCotizarInitialValues,
            validationSchema: entradaCotizarValidations
        },
        {
            name: "Cotización",
            stepButtonName: "Siguiente",
            condition: true,
            form: <CotizarSuccessView submitForm={_submitCotizarForm}/>,
            initialValues: valuesAdjusterFromRecotizar(), //Include it in last step so it overrides previous initial values
            validationSchema: null
        },
        {
            name: "#Emisión",
            condition: true,
            form: null,
            initialValues: null,
            validationSchema: null
        },
    ]

    const emisionFormData = [
        {
            name: "Pago",
            stepButtonName: "Siguiente",
            form: <FormaPagoForm/>,
            initialValues: formaPagoInitialValues,
            validationSchema: formaPagoValidationSchema
        },
        {
            name: "Cliente",
            stepButtonName: "Siguiente",
            form: <ClienteForm/>,
            initialValues: clienteInitialValues,
            validationSchema: clienteValidationSchema
        },
        {
            name: "Solicitud",
            stepButtonName: "Emitir",
            form: <SolicitudForm/>,
            initialValues: null,
            validationSchema: null
        },
    ]

    async function _submitCotizarForm(values, actions) {
        let url = process.env.REACT_APP_BACK_SERVER + '/newcotizar/asistencia-viajero';

        if (action) {
            values.idCotizar = values['_id']
        }

        if (!recotizarValues) {
            let realPassengersArray = [];
            values.pasajeros.map((pasajero) => {
                if (typeof pasajero.edad === 'number') {
                    realPassengersArray.push(pasajero)
                }
            })
            actions.setFieldValue('pasajeros', realPassengersArray);
        }

        if (values.travelDaysCounter < 5) {
            setVigenciaHastaMinFlagged(values.vigenciaHasta);
            let date = new Date(values.vigenciaDesde);
            date.setDate(date.getDate() + 4);
            values.vigenciaHasta = date;
            actions.setFieldValue('vigenciaHasta', date);
        }

        const valuesClone = _.cloneDeep(values)
        const jsonData = {
            ...valuesClone,
            vigenciaDesde: typeof valuesClone.vigenciaDesde === 'string' ? valuesClone.vigenciaDesde : format(valuesClone.vigenciaDesde, 'dd/MM/yyyy'),
            vigenciaHasta: typeof valuesClone.vigenciaHasta === 'string' ? valuesClone.vigenciaHasta : format(valuesClone.vigenciaHasta, 'dd/MM/yyyy')
        }

        axios.post(url, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            }
        }).then(res => {
            setCotizacionResponse(res.data);
            values.idCotizar = res.data['idCotizar']
        }).catch(err => {
            setErrorModalContent({
                title: "Hubo un problema al cotizar, por favor vuelva a intentarlo más tarde.",
                typeError: "Ha ocurrido un error en la comunicación con el servicio",
                suggestedActions: ["Revisá que la información ingresada sea correcta", "Reintentá el proceso una vez más", "Si el problema persiste ponete en contacto con el área de soporte"],
                detailedError: err.response?.data?.detail?.message || err.toString() || "Sin datos del error",
            })
            setErrorModalOpen(true)
        }).finally(() => {
            if (actions) actions.setSubmitting(false);
            setFinalValues(values);
        });
    }

    async function _submitEmitirForm(values, actions) {
        let url = process.env.REACT_APP_BACK_SERVER + '/newemitir/asistencia-viajero';
        const formaDePagoCompanyValue = companiesDict[values.company].codigoFormasDePago[values.infoDePago];

        setEmissionNextStepsText(nextStepsText(values.infoDePago, "end"));
        values.pasajeros.forEach(pasajero => {
            pasajero.nombre_contacto_emergencia = values.nombre_contacto_emergencia;
            pasajero.apellido_contacto_emergencia = values.apellido_contacto_emergencia;
            pasajero.telefono_contacto_emergencia = values.telefono_contacto_emergencia;
        })

        const jsonData = {
            vigenciaDesde: format(values.vigenciaDesde, 'dd/MM/yyyy'),
            vigenciaHasta: format(values.vigenciaHasta, 'dd/MM/yyyy'),
            idPais: values.idPais,
            idProducto: values.rowData.id,
            nombreCobertura: values.rowData.name,
            premium: values.rowData.premium,
            comision: userDetalleComisiones.TerraWind.ASISTENCIA_VIAJERO.general,
            comision_polkista: userDetalleComisiones.TerraWind.ASISTENCIA_VIAJERO.polkista,
            pasajeros: values.pasajeros,
            nombrePais: values.nombrePais,
            infoDePago: formaDePagoCompanyValue,
            idCotizacion: values.idCotizar || cotizacionResponse.idCotizar,
        }

        if (!isMaster) {
            jsonData.idMaster = idMaster
        }

        axios.post(url, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            }
        }).then(res => {
            setEmisionResponse(res.data)
            if (res.data || res.data.pasajeros) {
                setEmissionIdentifiers(res.data.pasajeros.reduce((acc, pasajero) => {
                    pasajero.identificadores.forEach(identificador => {
                        if (!acc[identificador.nombre]) {
                            acc[identificador.nombre] = [];
                        }
                        if (identificador.nombre === "Número de voucher") acc[identificador.nombre].push(identificador.valor);
                    });
                    return acc;
                }, {}))
            }
            if (isResponseSuccessful(res.data["status_code"])) {
                setSuccessModalOpen(true)
            } else {
                setErrorModalContent({
                    title: "Hubo un problema al emitir, por favor vuelva a intentarlo más tarde.",
                    typeError: res.data['detail']['code'],
                    suggestedActions: ["Revisá que la información ingresada sea correcta", "Evitá incluir caracteres especiales", "Reintente el proceso"],
                    detailedError: res.data['detail']['message'],
                })
                setErrorModalOpen(true);
            }
            setIsLoading(false)
        }).catch(err => {
            let errorModalContent = {
                title: "Hubo un problema al emitir, por favor vuelva a intentarlo más tarde.",
                typeError: "Ha ocurrido un error en la comunicación con el servicio",
                suggestedActions: ["Revisá que la información ingresada sea correcta", "Reintentá el proceso una vez más", "Si el problema persiste ponete en contacto con el área de soporte"],
                detailedError: "Sin datos del error",
            }
            if (err.response && err.response.data) {
                errorModalContent["typeError"] = err.response.data.detail.code
                errorModalContent["detailedError"] = err.response.data.detail.message
            }
            setErrorModalContent(errorModalContent)
            setErrorModalOpen(true);
            setIsLoading(false)
        }).finally(() => {
            if (actions)
                actions.setSubmitting(false);
        });
    }

    const downloadDocumentation = async (formValues, setSubmitting) => {
        setSubmitting(true)

        let url = process.env.REACT_APP_QUOTATION_MICROSERVICE + '/documentacion/asistencia-viajero'
        let passengerSelected = {}
        emisionResponse['pasajeros'].forEach((pasajero) => {
            pasajero?.documentacion?.forEach(documento => {
                if (documento === formValues.documento) {
                    passengerSelected = pasajero
                }
            })
        })
        try {
            await handleDownloadDocs(
                url,
                'TerraWind',
                formValues.documento,
                passengerSelected['identificadores'],
                authToken
            );
        } catch (error) {
            showNotification({
                autoClose: 3000,
                color: 'red',
                status: 'error',
                title: 'Error al descargar la documentación.',
                message: error,
            });
        }

        setSubmitting(false)
    }

    function _handleSubmit(values, actions) {
        let emisionStep = formData['cotizacion'].length + formData['emision'].length - 1;

        actions.setSubmitting(true);
        if (activeStep === emisionStep) {
            setIsLoading(true)
            _submitEmitirForm(values, actions);
        } else {
            setActiveStep(activeStep + 1);
            if (activeStep === (formData['cotizacion'].length - 3)) {
                _submitCotizarForm(values, actions);
            } else {
                actions.setTouched({});
                actions.setSubmitting(false);
            }
        }
    }

    function _handleBack(setFieldValue) {
        if (activeStep === 1) {
            setCotizacionResponse(null);
            if (vigenciaHastaMinFlagged) {
                setFieldValue('vigenciaHasta', vigenciaHastaMinFlagged);
                setVigenciaHastaMinFlagged(null)
            }
        }
        if (activeStep > 0)
            setActiveStep(activeStep - 1);
    }

    useEffect(() => {
        setCurrentFormData(initialQuotationForm)
        setFormData({
            "cotizacion": initialQuotationForm,
            "emision": emisionFormData
        })

        setActiveStep(0) //Reset active step
        setCotizacionResponse(null) //Reset cotizacion response
        setActiveFormProduct("asistencia_viajero") //Name has to be the route path

        if (action === "emitir") {
            setActiveStep(initialQuotationForm.length - 2)
            _submitCotizarForm(valuesAdjusterFromRecotizar(), null);
        }
    }, [performReset]);

    if (!currentFormData || location.pathname.split("/")[3] !== activeFormProduct) return <FormCard active={activeStep}><Loading/></FormCard>
    return (
        <AsistenciaViajeroContextProvider>
            <div className="asistenciaViajero__form__container">
                <FormCard>
                    <>
                        <FormRenderer
                            handleSubmit={_handleSubmit}
                            handleBack={_handleBack}
                            isLoading={isLoading}
                            doNotDisplayNextButton={[formData['cotizacion'].length - 2]}
                            product={"asistencia_viajero"}
                            performReset={performReset}
                            clientsFieldName="pasajeros"
                            downloadPdfJsonTemplate={downloadPdfJsonTemplate}
                        />
                    </>
                </FormCard>

                {errorModalOpen &&
                    <ErrorModal
                        closeAction={() => {
                            if (currentFormData[0]['name'] === "#Cotización") { //Indicates an error during emision
                                setActiveStep(formData['cotizacion'].length)
                                setEmisionResponse(null)
                            } else {
                                setActiveStep(0)
                                setCotizacionResponse(null)
                            }
                            setErrorModalOpen(false)
                        }}
                        title={errorModalContent.title}
                        typeError={errorModalContent.typeError}
                        suggestedActions={errorModalContent.suggestedActions}
                        detailedError={errorModalContent.detailedError}
                    />
                }

                {successModalOpen &&
                    <SuccessModal
                        closeAction={() => {
                            setSuccessModalOpen(false)
                            setCotizacionResponse(null)
                            setEmisionResponse(null)
                            history.push({pathname: '/u/dashboard'});
                        }}
                        title={"¡Felicitaciones, la operación se ha realizado con éxito!"}
                        body={
                            <div className="successModalAsistenciaViajero__body__container">
                                {emissionNextStepsText &&
                                    <div className="successModal__body__nextStepsBlock">
                                        <RoundedPanel color="base-light">
                                            {emissionNextStepsText}
                                        </RoundedPanel>
                                    </div>
                                }
                                {Object.keys(emissionIdentifiers).map((key) => (
                                    emissionIdentifiers[key].length > 0 &&
                                    <div key={key} className="successModal__body__block">
                                        <p className="successModal__subtitle">{key}</p>
                                        <ul className="successModal__list">
                                            {emissionIdentifiers[key].map((value, index) => (
                                                <>
                                                    <p className="successModal__text successModal__text--normal">{emisionResponse?.pasajeros[index]?.documentacion[0]}: </p>
                                                    <p key={index} className="successModal__text">{value}</p>
                                                </>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        }
                        footer={
                            <div className="autem__success__footer__container autem__success__footer__container--TerraWind">
                                <p className="autem__success__footer__subtitle">Documentación</p>
                                <Formik
                                    initialValues={{
                                        documento: emisionResponse?.pasajeros[0]['documentacion'][0]
                                    }}
                                    onSubmit={() => {
                                    }}
                                >
                                    {({isSubmitting, values, setSubmitting}) => (
                                        <Form method="post" style={{width: "70%"}}>
                                            <MantineSelectField
                                                name={`documento`}
                                                label={""}
                                                data={
                                                    emisionResponse?.pasajeros.map(pasajero => {
                                                        return pasajero['documentacion'][0]
                                                    })
                                                }
                                            />
                                            <div className="autem__success__footer__button__container">
                                                <Button
                                                    type="button"
                                                    disabled={isSubmitting}
                                                    variant="filled"
                                                    color="primary"
                                                    marginY="sm"
                                                    enabled
                                                    loading={isSubmitting}
                                                    onClick={() => downloadDocumentation(values, setSubmitting)}
                                                >
                                                    Descargar
                                                </Button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>

                                <p className="autem__success__footer__text">
                                    Descargá los documentos y anotá los
                                    números de identificación para próximas consultas.
                                </p>

                                <img className="autem__success__footer__logo"
                                     src={companiesDict['TerraWind']['logo']}
                                     alt='TerraWind'/>
                            </div>
                        }
                    />
                }
            </div>
        </AsistenciaViajeroContextProvider>
    );
}

export default withAsistenciaViajeroContextProvider(withAsistenciaViajeroContextReady(AsistenciaViajero));