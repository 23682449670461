import React from 'react';
import './FlipCard.css';

function FlipCard(props) {
    const {frontSide, backSide} = props;
    return (
        <div className="flipCard__container">
            <div className="flipCard">
                <div className="flipCard__side flipCard__side--front">
                    {frontSide}
                </div>
                <div className="flipCard__side flipCard__side--back">
                    {backSide}
                </div>
            </div>
        </div>
    );
}

export default FlipCard;