import {
    SET_ANSWERS,
    SET_CURRENT_QUESTION,
    SET_CURRENT_ANSWER,
    SET_ERROR,
    SET_SHOW_RESULTS,
    RESET_QUIZ,
    SET_QUIZ_STATE
} from './types.js';

/**
 * SET_CURRENT_ANSWER: Sets the user's currently selected answer. Current answer is the letter of the answer.
 * SET_CURRENT_QUESTION: Sets question to show. Current question is the index of the question.
 * SET_ERROR: Sets the error message if there's a problem.
 * SET_SHOW_RESULTS: Shows the quiz results when the quiz is finished. showResults is a boolean.
 * SET_ANSWERS: Saves the user's answers. Save in array like that: {questionId: 1, answer: 'a'}
 * RESET_QUIZ: Resets the quiz to the start.
 * SET_QUIZ_STATE: Sets the state of the quiz to 'Aprobado' if the user has approved. Should be 'Aprobado' or ''.
 */

function quizReducer(state, action) {
    switch (action.type) {
        case SET_CURRENT_ANSWER:
            return {
                ...state,
                currentAnswer: action.currentAnswer,
            };
        case SET_CURRENT_QUESTION:
            return {
                ...state,
                currentQuestion: action.currentQuestion,
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case SET_SHOW_RESULTS:
            return {
                ...state,
                showResults: action.showResults,
            };
        case SET_ANSWERS:
            return {
                ...state,
                answers: action.answers,
            };
        case RESET_QUIZ:
            return {
                ...state,
                answers: [],
                currentQuestion: 0,
                currentAnswer: '',
                showResults: false,
                error: '',
                quizState: '',
                quizNumber: 1,
            };
        case SET_QUIZ_STATE:
            return {
                ...state,
                quizState: action.quizState,
            };

        default:
            return state;
    }
}

export default quizReducer;