import axios from "axios";

async function fedPatGetCarInfo(carVersion, carYear) {
    const url = process.env.REACT_APP_BACK_SERVER + '/getExtraInfoFederacion';
    try {
        const response = await axios.get(url, {params: {
                "codia": carVersion,
                "anio": carYear
            }});
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export {fedPatGetCarInfo}