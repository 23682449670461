import { Grid } from "@mantine/core";
import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { MantineRadioButtonsField } from "../../../components/FormFields/MantineRadioButtonsField";
import { MantineSelectField } from "../../../components/FormFields/MantineSelectField";
import UserContext from "../../../context/UserContext";
import InitialValues from "./model/InitialValues";
import Model from "./model/Model";
import { renderNonEmptyFields } from "./utils/renderNonEmptyFields";
import MasterContext from "../../../context/MasterContext/MasterContext";

const { formField } = Model;
const {
    nombre,
    apellido,
    mail,
    celular,
    compania,
    numero_organizador,
    numero_productor,
    cuit,
    oficina,
    fecha_nacimiento,
    estado_civil,
    domicilio,
    codigo_postal,
    localidad,
    provincia,
    experiencia,
    select_companies,
    tiene_codigo,
} = formField;

const DatosPersonalesForm = (props) => {
    const { hasCodes, setHasCodes } = props;
    const { userCompaniesToPromote } = useContext(UserContext);
    const { altaCompaniaTickets } = useContext(MasterContext);
    const initialValues = InitialValues();

    const [localidadOptions, setLocalidadOptions] = useState([]);
    const [provinciasOptions, setProvinciasOptions] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);

    useEffect(() => {
        setCompanyOptions([
            { id: "Sancor", value: 'Sancor', label: 'Sancor' },
            { id: "RUS", value: 'RUS', label: 'Río Uruguay' },
            { id: "Zurich", value: 'Zurich', label: 'Zurich' },
            { id: "Federacion_Patronal", value: 'Federacion_Patronal', label: 'Federación Patronal' },
        ].filter(company => userCompaniesToPromote.includes(company.id) && !altaCompaniaTickets.find(obj => obj.hasOwnProperty(company.id))));
    }, [altaCompaniaTickets]);

    const location = useLocation();
    if (location.state) {
        initialValues[compania.name] = location.state.companyName;
        initialValues[tiene_codigo.name] = false;
    }

    const baseFields = [nombre, apellido, mail, celular];
    const hasCodeFields = [numero_productor, numero_organizador];
    const noCodeFields = [provincia, codigo_postal, localidad, domicilio, fecha_nacimiento, cuit, estado_civil, select_companies, experiencia, oficina];
    const [fieldsToCheck, setFieldsToCheck] = useState(baseFields);
    const nonEmptyFieldsRendered = useMemo(() => renderNonEmptyFields(initialValues, fieldsToCheck, localidadOptions, setLocalidadOptions, provinciasOptions, setProvinciasOptions), [fieldsToCheck, localidadOptions, provinciasOptions]);

    useEffect(() => {
        if (hasCodes === true) setFieldsToCheck(baseFields.concat(hasCodeFields))
        else if (hasCodes === false) setFieldsToCheck(baseFields.concat(noCodeFields))
    }, [hasCodes])

    return (
        <>
            <Grid align="center">
                <Grid.Col xs={12} sm={6}>
                    <MantineSelectField
                        name={formField.compania.name}
                        label={formField.compania.label}
                        data={companyOptions || []}
                    />
                </Grid.Col>

                <Grid.Col xs={12} sm={6}>
                    <MantineRadioButtonsField
                        name={formField.tiene_codigo.name}
                        label={formField.tiene_codigo.label}
                        options={[{ value: true, label: 'Si' }, { value: false, label: 'No' }]}
                        areBooleanValues
                        onChangeAction={setHasCodes}
                    />
                </Grid.Col>

                {nonEmptyFieldsRendered}
            </Grid>
        </>
    );
}

export default DatosPersonalesForm;
