import React, {
    useEffect,
    useState,
    useMemo, useContext, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import UserContext from "./UserContext";
import axios from "axios";
import AuthContext from "../AuthContext";
import {ReactComponent as HomeIcon} from "../../assets/icons/ic_home.svg";
import {ReactComponent as CarIcon} from "../../assets/icons/ic_auto.svg";
import {ReactComponent as DocumentIcon} from "../../assets/icons/ic_document.svg";
import {ReactComponent as WalletIcon} from "../../assets/icons/ic_wallet.svg";
import {ReactComponent as MktIcon} from "../../assets/icons/ic_mkt.svg";
import {ReactComponent as BookIcon} from "../../assets/icons/ic_book.svg";
import {ReactComponent as CaseIcon} from "../../assets/icons/ic_case.svg";
import {ReactComponent as HelpIcon} from "../../assets/icons/ic_help.svg";
import {ReactComponent as MisAseguradorasIcon} from "../../assets/icons/ic_aseguradoras.svg";
import {ReactComponent as CalendarIcon} from "../../assets/icons/ic_calendar.svg";
import {useHistory} from "react-router-dom";
import useWindowDimensions from "../../modules/utils/useWindowDimensions";
import {productsByCompany} from "../../constants/auxPersonalData";

const comissionObjectConverter = (originalObj) => {
    const nuevoObjeto = {};
    for (const [compania, datos] of Object.entries(originalObj)) {
        const objValues = Object.values(datos);
        const comisionesObj = objValues.find(value => value.hasOwnProperty("comisiones"));
        nuevoObjeto[compania] = comisionesObj.comisiones;
    }
    return nuevoObjeto;
}

function extractInsuranceCodesInfo(insuranceObj) {
    const result = {};
    for (const [company, value] of Object.entries(insuranceObj)) {
        for (const level2Key in value) {
            const level2Value = value[level2Key];
            if (!result[company]) result[company] = [];
            result[company].push(level2Value.codigos);
        }
    }
    return result;
}

function extractDiscountsFromCodesInfo(json) {
    const descuentosTodasLasCompanias = {};

    for (const compania in json) {
        const valoresCompania = json[compania];
        for (const clave in valoresCompania) {
            if (valoresCompania[clave]["descuentos"]) {
                if (!descuentosTodasLasCompanias[compania]) {
                    descuentosTodasLasCompanias[compania] = {};
                }
                descuentosTodasLasCompanias[compania] = {
                    ...descuentosTodasLasCompanias[compania],
                    ...valoresCompania[clave]["descuentos"],
                };
            }
        }
    }

    return descuentosTodasLasCompanias;
}

const UserContextProvider = ({
                                 children,
                             }) => {

    const {authToken, isMaster} = useContext(AuthContext)

    const [userIdAuth, setUserIdAuth] = useState(null)
    const [userName, setUserName] = useState(null)
    const [personalRanking, setPersonalRanking] = useState(null);
    const [userLastName, setUserLastName] = useState(null)
    const [userMail, setUserMail] = useState(null)
    const [userPicture, setUserPicture] = useState(null)
    const [userPhone, setUserPhone] = useState(null)
    const [userProvince, setUserProvince] = useState(null)
    const [userIdProvince, setUserIdProvince] = useState(null)
    const [userCargo, setUserCargo] = useState(null)
    const [userMatricula, setUserMatricula] = useState(null)
    const [masterMatricula, setMasterMatricula] = useState(null)
    const [userDNI, setUserDNI] = useState(null)
    const [userCUIT, setUserCUIT] = useState(null)
    const [userDireccion, setUserDireccion] = useState(null)
    const [userFechaNacimiento, setUserFechaNacimiento] = useState(null)
    const [userSexo, setUserSexo] = useState(null)
    const [userNacionalidad, setUserNacionalidad] = useState(null)
    const [userLugarNacimiento, setUserLugarNacimiento] = useState(null)
    const [userSituacionIVA, setUserSituacionIVA] = useState(null)
    const [userOcupacion, setUserOcupacion] = useState(null)
    const [userPais, setUserPais] = useState(null)
    const [userCBU, setUserCBU] = useState(null)
    const [userCodigoLocalidad, setUserCodigoLocalidad] = useState(null)
    const [userLocalidad, setUserLocalidad] = useState(null)
    const [userCodigoPostal, setUserCodigoPostal] = useState(null)
    const [userWebHabilitado, setUserWebHabilitado] = useState(null)
    const [userQR, setUserQR] = useState(null)
    const [userLink, setUserLink] = useState(null)
    const [userDetalleComisiones, setUserDetalleComisiones] = useState(null);
    const [userCodigosOperacion, setUserCodigosOperacion] = useState(null);
    const [masterName, setMasterName] = useState('');
    const [masterLastName, setMasterLastName] = useState('');
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [activeFormProduct, setActiveFormProduct] = useState(null);
    const [userCompanies, setUserCompanies] = useState(null);
    const [userCompaniesNames, setUserCompaniesNames] = useState(null);
    const [userCompaniesToPromote, setUserCompaniesToPromote] = useState(null);
    const [userDiscounts, setUserDiscounts] = useState(null);
    const [idMaster, setIdMaster] = useState(null);
    const [courses, setCourses] = useState([]);
    const [lastCourses, setLastCourses] = useState([]);
    const [isReady, setIsReady] = useState(false);
    const [sidebarProducts, setSideBarProducts] = useState([]);
    const [idPolkista, setIdPolkista] = useState(null);
    const history = useHistory();
    const {width} = useWindowDimensions();
    const isMobile = width <= 480;

    const [modified, setModified] = useState(false);
    const handleModified = useCallback(() => {
        setModified(!modified)
    }, [modified]);

    const products = [
        {
            id: null,
            name: "Automotor",
            action: () => {
                history.push('/u/cotizar/automotor')
                delayedSidebarClose()
            },
            children: null,
            newIndicator: true
        },
        {
            id: null,
            name: "Motovehículo",
            action: () => {
                history.push('/u/cotizar/motovehiculo')
                delayedSidebarClose();
            },
            children: null,
        },
        {
            id: null,
            name: "Hogar",
            action: () => {
                history.push('/u/cotizar/hogar')
                delayedSidebarClose()
            },
            children: null
        },
        {
            id: null,
            name: "Microseguros",
            action: () => {
                history.push('/u/cotizar/microseguros')
                delayedSidebarClose()
            },
            children: null
        },
        {
            id: null,
            name: "Asistencia al viajero",
            action: () => {
                history.push('/u/cotizar/asistencia_viajero')
                delayedSidebarClose()
            },
            children: null
        }
    ]

    /* Calculates the products available for a user based on the user's companies */
    const getUserProducts = (userCompanies, userCompaniesToPromote) => {
        const allUserProducts = new Set();
        const allUserCompanies = [...userCompanies, ...userCompaniesToPromote];

        allUserCompanies.forEach(company => {
            productsByCompany[company]?.forEach(product => {
                allUserProducts.add(product);
            });
        });

        const userProducts = products.filter(product => allUserProducts.has(product.name));
        for (let i = 0; i < userProducts.length; i++) {
            userProducts[i].id = `1.${i+1}`;
        }

        return userProducts;
    };

    const delayedSidebarClose = () => {
        setTimeout(() => {
            setSidebarOpen(false)
        }, 50)
    }

    const sidebarItems = {
        bodyItems: [
            {
                id: 0,
                icon: <HomeIcon className="sidebar__item__icon"/>,
                name: "Inicio",
                renderCondition: true,
                action: () => {
                    history.push('/u/dashboard')
                    delayedSidebarClose()
                },
                children: null
            },
            {
                id: 1,
                icon: <CarIcon className="sidebar__item__icon"/>,
                name: "Productos",
                renderCondition: true,
                action: () => console.log("Productos"),
                children: sidebarProducts
            },
            {
                id: 2,
                icon: <DocumentIcon className="sidebar__item__icon"/>,
                name: "Documentación",
                renderCondition: true,
                action: () => {
                    history.push('/u/documentacion')
                    delayedSidebarClose()
                },
                children: null
            },
            {
                id: 3,
                icon: <WalletIcon className="sidebar__item__icon"/>,
                name: "Billetera",
                renderCondition: true,
                action: () => {
                    history.push('/u/billetera');
                    delayedSidebarClose()
                },
                children: null
            },
            {
                id: 4,
                icon: <MktIcon className="sidebar__item__icon"/>,
                name: "Marketing",
                renderCondition: true,
                action: () => {
                    history.push('/u/marketing');
                    delayedSidebarClose();
                },
                children: null
            },
            {
                id: 5,
                icon: <BookIcon className="sidebar__item__icon"/>,
                name: "Academia",
                renderCondition: true,
                action: () => {
                    history.push('/u/academia')
                    delayedSidebarClose()
                },
                children: null
            },
            {
                id: 6,
                icon: <MisAseguradorasIcon className="sidebar__item__icon"/>,
                name: "Mis Aseguradoras",
                renderCondition: isMaster,
                action: () => {
                    history.push('/u/m/misaseguradoras')
                    delayedSidebarClose()
                },
                children: null
            },
            {
                id: 7,
                icon: <CaseIcon className="sidebar__item__icon"/>,
                name: "Master",
                renderCondition: isMaster,
                action: () => console.log("Master"),
                children: [
                    {
                        id: 7.1,
                        name: "Dashboard",
                        action: () => {
                            history.push('/u/m/dashboard');
                            delayedSidebarClose()
                        },
                        children: null
                    },
                    {
                        id: 7.2,
                        name: "Canal Digital",
                        action: () => {
                            history.push('/u/m/canaldigital');
                            delayedSidebarClose()
                        },
                        children: null
                    },
                    {
                        id: 7.3,
                        name: "Operaciones",
                        action: () => {
                            history.push('/u/m/operaciones');
                            delayedSidebarClose()
                        },
                        children: null
                    },
                ]
            },
        ],
        footerItems: [
            {
                id: 8,
                icon: <CalendarIcon className="sidebar__item__icon"/>,
                name: "Agendar cita",
                action: () => {
                    window.open('https://calendar.app.google/nmrrCKJSqtq9f4RYA', '_blank');
                    delayedSidebarClose()
                },
                children: null
            },
            {
                id: 9,
                icon: <HelpIcon className="sidebar__item__icon"/>,
                name: "Ayuda",
                action: () => {
                    window.open('https://polko.atlassian.net/servicedesk/customer/portals', '_blank');
                    delayedSidebarClose()
                },
                children: null
            },
            /*{
                id: 9,
                icon: <ConfigIcon className="sidebar__item__icon"/>,
                name: "Configuración",
                action: () => console.log("Configuración"),
                children: null
            }*/
        ]
    }

    useEffect(() => {

        let shouldUpdate = true;

        const init = async () => {

            const url = isMaster
                ? `${process.env.REACT_APP_BACK_SERVER}/newGetDatosUsuario?es_master=true`
                : `${process.env.REACT_APP_BACK_SERVER}/newGetDatosUsuario`;

            axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                }
            }).then((resp) => {
                const datosPolkista = resp['data']['datos_polkista'][0];
                const datosMaster = resp['data']['datos_master'];
                const datosCanalDigital = resp['data']['canal_digital'][0];
                const companiesWithCode = Object.keys(resp.data['aseguradoras'])
                setUserCompaniesNames(companiesWithCode.map(company => ({
                    label: company.replace(/_/g, ' '),
                    value: company
                })));
                const companiesToPromote = resp.data?.aseguradoras_promocionables ? resp.data?.aseguradoras_promocionables?.filter(company => company !== "Experta") || [] : [];

                setUserDetalleComisiones(comissionObjectConverter(resp['data']['aseguradoras']))
                setUserCodigosOperacion(extractInsuranceCodesInfo(resp['data']['aseguradoras']))
                setUserDiscounts(extractDiscountsFromCodesInfo(resp['data']['aseguradoras']))

                setUserCompanies(companiesWithCode)
                setUserCompaniesToPromote(companiesToPromote)
                setUserIdAuth(datosPolkista['id_auth'])
                setUserName(datosPolkista['Nombre'])
                setUserLastName(datosPolkista['Apellido'])
                setUserMail(datosPolkista['Email'])
                setUserPhone(datosPolkista['Telefono'])
                setUserPicture(datosPolkista['auth_datos']['picture'])
                setUserCargo(datosPolkista['Cargo'])

                setUserMatricula(datosPolkista['matricula'])
                setMasterMatricula(datosMaster['matricula'])
                setMasterName(datosMaster['nombre'])
                setMasterLastName(datosMaster['apellido'])
                setIdMaster(datosMaster['id_master'])

                setUserDNI(datosPolkista['Dni'])
                setUserCUIT(datosPolkista['cuit_cuil'])
                setUserDireccion(datosPolkista['Direccion'])
                setUserFechaNacimiento(datosPolkista['fecha_nacimiento'])
                setUserSexo(datosPolkista['Sexo'])
                setUserNacionalidad(datosPolkista['Nacionalidad'])
                setUserLugarNacimiento(datosPolkista['lugar_nacimiento'])
                setUserSituacionIVA(datosPolkista['situacion_iva'])
                setUserOcupacion(datosPolkista['Ocupacion'])
                setUserPais(datosPolkista['Pais'])
                setUserCBU(datosPolkista['cvu_cbu'])
                setUserCodigoPostal(datosPolkista['c_postal'].toString())
                setUserCodigoLocalidad(datosPolkista['c_localidad'].toString())
                setUserLocalidad(datosPolkista['localidades']['Localidad'])
                setUserProvince(datosPolkista['localidades']['provincia']['nombreProvincia'])
                setUserIdProvince(datosPolkista['localidades']['provincia']['idProvincia'].toString())
                setSideBarProducts(getUserProducts(companiesWithCode, companiesToPromote));
                setIdPolkista(datosPolkista['id_polkista'])

                if (datosCanalDigital) {
                    setUserQR(datosCanalDigital['QR'])
                    setUserLink(datosCanalDigital['URL'])
                    setUserWebHabilitado(datosCanalDigital['Habilitado'])
                } else {
                    setUserWebHabilitado(false)
                }

                setIsReady(true)
            })
        }

        init();
        return () => {
            /* This cleanup function is used to prevent updating the state
            when the component is unmounted */
            shouldUpdate = false;
        };
    }, [authToken, modified])

    const context = useMemo(() => ({
        userIdAuth,
        userPhone,
        userPicture,
        setUserPicture,
        userName,
        userLastName,
        userMail,
        userProvince,
        userIdProvince,
        userCargo,
        userMatricula,
        masterMatricula,
        masterName,
        masterLastName,
        userDNI,
        userCUIT,
        userDireccion,
        userSituacionIVA,
        userOcupacion,
        userFechaNacimiento,
        userNacionalidad,
        userSexo,
        userLugarNacimiento,
        userPais,
        userCBU,
        userCodigoLocalidad,
        userLocalidad,
        userCodigoPostal,
        userDetalleComisiones,
        userCodigosOperacion,
        userWebHabilitado,
        userLink,
        userQR,
        userCompanies,
        userCompaniesNames,
        userCompaniesToPromote,
        userDiscounts,
        idMaster,
        sidebarItems,
        sidebarOpen,
        setSidebarOpen,
        isMobile,
        activeFormProduct,
        setActiveFormProduct,
        courses,
        setCourses,
        lastCourses,
        setLastCourses,
        handleModified,
        setPersonalRanking,
        personalRanking,
        idPolkista,
        isReady
    }), [
        userIdAuth,
        userPhone,
        userPicture,
        setUserPicture,
        userName,
        userLastName,
        userMail,
        userProvince,
        userIdProvince,
        userCargo,
        userMatricula,
        masterMatricula,
        masterName,
        masterLastName,
        userDNI,
        userCUIT,
        userDireccion,
        userSituacionIVA,
        userOcupacion,
        userFechaNacimiento,
        userNacionalidad,
        userSexo,
        userLugarNacimiento,
        userPais,
        userCBU,
        userCodigoLocalidad,
        userLocalidad,
        userCodigoPostal,
        userDetalleComisiones,
        userCodigosOperacion,
        userWebHabilitado,
        userLink,
        userQR,
        userCompanies,
        userCompaniesNames,
        userCompaniesToPromote,
        userDiscounts,
        idMaster,
        sidebarItems,
        sidebarOpen,
        setSidebarOpen,
        activeFormProduct,
        setActiveFormProduct,
        isMobile,
        courses,
        setCourses,
        lastCourses,
        setLastCourses,
        handleModified,
        setPersonalRanking,
        personalRanking,
        idPolkista,
        isReady
    ]);
    return (
        <UserContext.Provider value={context}>
            {children}
        </UserContext.Provider>
    );
};

UserContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default UserContextProvider;