import React from 'react';
import './Loading.css';
import {Loader} from "@mantine/core";

function Loading() {
    return (
        <div className="loading__container">
            <Loader size="lg" color="gray"/>
        </div>
    );
}

export default Loading;