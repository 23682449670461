import React, {useContext, useState} from 'react';
import {Popover} from "@mantine/core";
import {IconFileAlert} from '@tabler/icons';
import {ReactComponent as ExclamationIcon} from "../../../assets/icons/ic_exclamation.svg";
import {ReactComponent as PercentageIcon} from "../../../assets/icons/ic_percentage.svg";
import UserContext from "../../../context/UserContext";

const dropdownFueraPauta = (onOutStandard, type) => {
    let bodyText = "";
    switch (type) {
        case "applyDiscount":
            bodyText = "Solicitar autorización previa a la carga de la solicitud";
            break;
        case "emitir":
            bodyText = "Solicitar excepción por emisión";
            break;
        default:
            break;
    }
    return <div className="ctrow__popover__dropdown">
        <p className="ctrow__text">
            Cobertura Fuera de Pauta. <br/>
            {bodyText}:
        </p>
        <IconFileAlert className="ctrow__icon__fileAlert" onClick={() => onOutStandard(type)}/>
    </div>
}

const dropdownNotSupportsAdditional = <div className="ctrow__popover__dropdown">
    <p className="ctrow__text">
        Esta cobertura no admite Bienes Adicionales ni Accesorios.
    </p>
</div>

const dropdownPercentage = (_descFP) => {
    return <div className="ctrow__popover__dropdown">
        <p className="ctrow__text">
            Cobertura sujeta a suscripción de la compañía. <br/>
            Descuento {_descFP}% aplicado.
        </p>
    </div>
}

const OutStandardIcons = (props) => {
    const {parametrosAdicionales, companyName, onOutStandard} = props;
    const [popoverOpened, setPopoverOpened] = useState(false);
    const [popoverPercentageOpened, setPopoverPercentageOpened] = useState(false);
    const {userDiscounts} = useContext(UserContext)
    const notSupportsAdditional = parametrosAdicionales.hasOwnProperty('admiteBienesAdicionales')
        ? !parametrosAdicionales['admiteBienesAdicionales']
        : companyName === 'Federacion_Patronal';

    //TODO: Change this to a more generic way of getting the discount based on company and product
    const descuentoFP = userDiscounts['Sancor']['AUTOMOTOR']['fuera_pauta'];

    const createPopover = (_popoverDropdown, _open, _setOpen, _icon) => (
        <Popover width={112} withArrow arrowPosition="side" position="bottom-end" shadow="0 2px 2px rgba(0, 0, 0, 0.25)"
                 opened={_open} onChange={_setOpen} onClick={() => _setOpen(!_open)}
                 classNames={{dropdown: "automotor__cotSuccess__popover"}}>
            <Popover.Target>
                <div>{_icon}</div>
            </Popover.Target>
            <Popover.Dropdown>
                {_popoverDropdown}
            </Popover.Dropdown>
        </Popover>
    );

    const icons = [];

    if (companyName === 'Sancor') {
    if (parametrosAdicionales['outStandard'] === "true") {
        icons.push(
            createPopover(
                dropdownFueraPauta(onOutStandard, "applyDiscount"),
                popoverOpened,
                setPopoverOpened,
                <ExclamationIcon className="ctrow__icon__exclamation"/>
            ));
        icons.push(
            createPopover(
                dropdownPercentage(descuentoFP),
                popoverPercentageOpened,
                setPopoverPercentageOpened,
                <PercentageIcon className="ctrow__icon__percentage"/>
            ));
    } else if (parametrosAdicionales['outStandardSancor'] === "true") {
        icons.push(
            createPopover(
                dropdownPercentage(descuentoFP),
                popoverPercentageOpened,
                setPopoverPercentageOpened,
                <PercentageIcon className="ctrow__icon__percentage"/>
            ));
    } else if (notSupportsAdditional) {
        icons.push(createPopover(
            dropdownNotSupportsAdditional,
            popoverOpened,
            setPopoverOpened,
            <ExclamationIcon className="ctrow__icon__exclamation"/>
        ))
    }
    } else if (companyName === 'Zurich') {
        if (parametrosAdicionales['outStandard']) {
            icons.push(
                createPopover(
                    dropdownFueraPauta(onOutStandard, "emitir"),
                    popoverOpened,
                    setPopoverOpened,
                    <ExclamationIcon className="ctrow__icon__exclamation"/>
                ));
        }
    }

    return (
        <div className="ctrow__icon__container">
            {icons.map((icon, index) => <div key={index}>{icon}</div>)}
        </div>
    );
}

export default OutStandardIcons;