
function isObject(item) {
    return (item && typeof item === 'object');
}

/**
 * The deepMerge() function takes two objects as arguments and recursively merges their properties.
 * If a property is an object, the function will call itself with the corresponding properties from
 * the target and source objects as arguments. If a property is not an object, it will be directly
 * assigned to the output object.
 * @param target
 * @param source
 * @returns deeplyMergedObject
 */

function deepMerge(target, source) {
    let output = Object.assign({}, target);
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach(key => {
            if (isObject(source[key])) {
                if (!(key in target))
                    Object.assign(output, {[key]: source[key]});
                else
                    output[key] = deepMerge(target[key], source[key]);
            } else {
                Object.assign(output, {[key]: source[key]});
            }
        });
    }
    return output;
}

export {deepMerge}