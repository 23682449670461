import {useContext} from 'react';
import formModel from './profileFormModel';
import UserContext from "../../../../context/UserContext";

const {
    formField: {
        nombre, apellido, DNI, CUIT, nacimiento, l_nacimiento, nacionalidad, email, telefono, sexo, ocupacion, IVA,
        matricula, direccion, CBU, codigoPostal, idProvincia, codigosLocalidad
    },
} = formModel;


function FormInitialValues() {
    const {
        userDNI,
        // userPicture,
        userName,
        userLastName,
        userMail,
        userMatricula,
        userPhone,
        userCUIT,
        userDireccion,
        userFechaNacimiento,
        userNacionalidad,
        userSexo,
        userLugarNacimiento,
        userOcupacion,
        userSituacionIVA,
        userCBU,
        userCodigoLocalidad,
        userCodigoPostal,
        userProvince
    } = useContext(UserContext);

    return {
        [nombre.name]: userName,
        [apellido.name]: userLastName,
        [DNI.name]: userDNI,
        [CUIT.name]: userCUIT,
        [nacimiento.name]: userFechaNacimiento,
        [l_nacimiento.name]: userLugarNacimiento,
        [nacionalidad.name]: userNacionalidad,
        [email.name]: userMail,
        [telefono.name]: userPhone.includes('+') ? userPhone : `+54${userPhone}`,
        [sexo.name]: userSexo,
        [ocupacion.name]: userOcupacion,
        [IVA.name]: userSituacionIVA,
        [matricula.name]: userMatricula ? userMatricula : '',
        // [pais.name]: userPais,
        [direccion.name]: userDireccion,
        [CBU.name]: userCBU ? userCBU : '',
        // TODO: revisar si cambia el formato en el que llegan código postal y código localidad
        [codigosLocalidad.name]: userCodigoLocalidad,
        [codigoPostal.name]: userCodigoPostal,
        [idProvincia.name]: userProvince
    };
}

export default FormInitialValues;