import React from 'react';
import './FormCard.css'

function FormCard({children}) {
    return (
        <div className="formCard__container">
            {children}
        </div>
    );
}

export default FormCard;