import React from 'react';
import './App.css';
import {Switch, Route} from "react-router-dom";
import PrivateRoute from './modules/components/PrivateRoute';
import {useAuth0} from "@auth0/auth0-react";
import Loading from "./modules/components/Loading";
import Callback from "./modules/components/Callback";
import {Landing} from './pages/Landing'
import UserRoutes from "./routes/UserRoutes";
import ProtectedRoute from "./modules/components/ProtectedRoute";
import {Registro} from "./pages/Registro";
import {TerminosYCondiciones} from "./pages/TerminosYCondiciones";


function App() {
    const {isLoading} = useAuth0();

    if (isLoading) {
        return <Loading/>;
    }

    return (
        <Switch>
            {/*POLKISTAS*/}
            <Route path={`/`} exact component={Landing}/>
            <Route path={`/terminos`} component={TerminosYCondiciones}/>
            <PrivateRoute path={`/form`} component={Registro}/>
            <ProtectedRoute path={`/callback`} component={Callback}/>
            <ProtectedRoute path="/u" component={UserRoutes}/>
        </Switch>
    );
}

export default App;
