export function calculatePosition(elementRef, arrowRef, tooltipRef, position, isFixedItem = false) {
    const referenceRect = elementRef.getBoundingClientRect();
    const arrowRect = arrowRef.getBoundingClientRect();
    const tooltipRect = tooltipRef.getBoundingClientRect();
    let top = 0;
    let left = 0;

    switch (position) {
        case 'top':
            top = referenceRect.bottom + 10;
            left = referenceRect.left;
            break;
        case 'down':
            top = referenceRect.top - tooltipRect.height - 10;
            left = referenceRect.left;
            break;
        case 'left':
            top = referenceRect.top + referenceRect.height / 2 - arrowRect.height / 2;
            left = referenceRect.right + 16;
            break;
        case 'right':
            top = referenceRect.top + referenceRect.height / 2 - arrowRect.height / 2;
            left = referenceRect.left - arrowRect.width - 10;
            break;
        default:
            break;
    }

    if (!isFixedItem) {
        top = top + window.scrollY;
        left = left + window.scrollX;
    }

    return { top, left, width: tooltipRect.width, height: tooltipRect.height };
}