import React from 'react';
import './CardsContainer.css'
import Icon_1 from '../../../assets/_images/ld_card_ic_1.png'
import Icon_2 from '../../../assets/_images/ld_card_ic_2.png'
import Icon_3 from '../../../assets/_images/ld_card_ic_3.png'
import Icon_4 from '../../../assets/_images/ld_card_ic_4.png'
import {FlipCard} from "../../../components/FlipCard";

function CardsContainer() {
    return (
        <div className="landing__flipCards__container">
            <FlipCard
                frontSide={
                    <>
                        <img className="landing__flipCards__icon" src={Icon_1} alt="_icon"/>
                        <p className="landing__flipCards__text">Nuestros productos</p>
                    </>
                }
                backSide={
                    <div className="landing__flipCards__list">
                        <p className="landing__flipCards__text--backside">Auto</p>
                        <p className="landing__flipCards__text--backside">Hogar</p>
                        <p className="landing__flipCards__text--backside">Microseguros (notebook, bicicleta, tablet,
                            otros objetos)</p>
                    </div>
                }
            />

            <FlipCard
                frontSide={
                    <>
                        <img className="landing__flipCards__icon" src={Icon_2} alt="_icon"/>
                        <p className="landing__flipCards__text">Página web personalizada</p>
                    </>
                }
                backSide={
                    <p className="landing__flipCards__text--backside">
                        Creá tu página web
                        personalizada en
                        tres minutos y llegá a tus
                        clientes de manera digital
                        con todos los productos
                        disponibles.
                    </p>
                }
            />

            <FlipCard
                frontSide={
                    <>
                        <img className="landing__flipCards__icon" src={Icon_3} alt="_icon"/>
                        <p className="landing__flipCards__text">Cotizá y emití</p>
                    </>
                }
                backSide={
                    <p className="landing__flipCards__text--backside">
                        Elaborá tus presupuestos
                        al mejor precio y cerrá las
                        ventas de manera virtual
                        y desde cualquier lugar.
                    </p>
                }
            />

            <FlipCard
                frontSide={
                    <>
                        <img className="landing__flipCards__icon" src={Icon_4} alt="_icon"/>
                        <p className="landing__flipCards__text">Ingresos por venta</p>
                    </>
                }
                backSide={
                    <p className="landing__flipCards__text--backside">
                        Mejoramos cualquier
                        herramienta del mercado
                        otorgando los mejores
                        incentivos para
                        rentabilizar tus ventas
                        de la mejor manera.
                    </p>
                }
            />
        </div>
    );
}

export default CardsContainer;