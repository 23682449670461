import {makeStyles} from '@material-ui/core/styles';

export default makeStyles(theme => ({
    sinCostoIcons: {
        marginRight: "16px",
        color: "#452d7b",
        fontSize: "32px",
        [theme.breakpoints.down('xs')]: {
            fontSize: "24px",
        },
    },
    gridContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(3)
    },
    centerText: {
        textAlign: 'center'
    },
    formControl: {
        width: '100%'
    },

    coberturasTable: {
        padding: theme.spacing(1),
    },
    errorMessage: {
        color: "red",
        fontSize: "12px",
        textAlign: "center",
        marginTop: "4px"
    },
    fieldArrayButtons: {
        padding: "0",
        marginTop: "16px",
        minWidth: "28px",
        maxWidth: "28px"
    },
    fieldArrayButtonsDiv: {
        display: "flex",
        flexDirection: "row",
        width: "64px",
        justifyContent: "space-between"
    },
    boxPrice: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "120px",
        backgroundColor: "#452d7b"
    },
    note: {
        fontFamily: 'Nunito',
        fontSize: 12,
        color: theme.palette.grey.A200,
        [theme.breakpoints.down('xs')]: {
            textAlign: "center"
        },
    },
    tableRowDetailLabel: {
        fontFamily: 'Nunito',
        color: "#969292",
        fontSize: "14px"
    },
    priceTitle: {
        fontFamily: 'Nunito',
        fontWeight: 400,
        fontSize: 18,
        color: "#fff"
    },
    priceText: {
        fontFamily: 'Nunito',
        fontWeight: 700,
        fontSize: 32,
        color: "#fff"
    },
    tableHeader: {
        fontFamily: 'Montserrat',
        fontSize: 20,
        margin: "0px 16px 16px 16px",
        [theme.breakpoints.down('xs')]: {
            fontSize: 16,
        },
    },
    tableRowLabel: {
        fontWeight: 500,
        fontSize: 18,
        color: "#000",
        fontFamily: 'Nunito',
        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
        },
    },
    tableRowData: {
        fontWeight: 300,
        fontSize: 14,
        color: "#000",
        fontFamily: 'Nunito'
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    buttonContained: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        backgroundColor: '#452d7b',
        '&:hover': {
            backgroundColor: '#38217b',
            boxShadow: 'none',
        },
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%'
    },
    step: {
        "& $completed": {
            color: '#452d7b'
        },
        "& $active": {
            color: '#452d7b'
        },
    },
    active: {},
    completed: {},
    alert: {
        backgroundColor: theme.palette.warning.dark
    },
}));
