import React from 'react';
import "./MasterDigitalChannel.css";
import {ContentCard} from "../../features/MasterDigitalChannel/ContentCard";

function MasterDigitalChannel() {
    return (
        <div className="MCDigital__container">
            <div className="MCDigital__welcomeTextContainer">
                <p className="MCDigital__title"> Canales digitales</p>
                <p className="MCDigital__subtitle"> Gestiona los canales de tus Polkistas y crea su plataforma web.</p>
                <ContentCard/>
            </div>
        </div>
    );
}

export default MasterDigitalChannel;