import React, {useContext, useEffect} from 'react';
import './MobileSidebarItem.css';
import {ReactComponent as ArrowDownIcon} from "../../assets/icons/ic_arrow-down.svg";
import {SidebarChildItem} from "../SidebarChildItem";
import UserContext from "../../context/UserContext";

function MobileSidebarItem(props) {
    const {id, icon, name, action, children, menuOpened, setMenuOpened} = props;
    const {sidebarOpen, setSidebarOpen} = useContext(UserContext);

    const handleClick = () => {
        if (children) {
            if (menuOpened === id) {
                setMenuOpened(null)
            } else {
                setMenuOpened(id)
            }
        } else {
            setSidebarOpen(false)
            action()
        }
    }

    useEffect(() => {
        if (!sidebarOpen)
            setMenuOpened(null)
    }, [sidebarOpen]);

    return (
        <>
            <div className="mobSidebar__item__container" onClick={handleClick}>
                <div className="mobSidebar__item__container--left">
                    {icon}
                    <span className="mobSidebar__item__text">{name}</span>
                </div>
                {children && <ArrowDownIcon className="mobSidebar__item__button"/>}
            </div>
            {menuOpened === id && sidebarOpen &&
                <div className="mobSidebar__subitems__container">
                    {children.map((child) => <SidebarChildItem name={child.name}
                                                               action={() => {
                                                                   setSidebarOpen(false)
                                                                   child.action()
                                                               }}
                                                               newIndicator={child.newIndicator}/>)}
                </div>
            }
        </>
    );
}

export default MobileSidebarItem;