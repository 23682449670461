import {PopUpCardModal} from "../../../../layouts/PopUpCardModal";
import React, {useState} from "react";
import {sendWhatsapp} from "../../../../modules/utils/sendWhatsapp";
import {Mailto} from "../../../../modules/utils/Mailto";
import moment from "moment";
import './ClientDetails.css';
import {ReactComponent as CopyIcon} from "../../../../assets/icons/ic_copy.svg";

function ClientDetails(props) {
    const {name, lastName, email, phone, setClientInfo, setOpenClientInfo, quotationDetail} = props;
    const [copiedMail, setCopiedMail] = useState(false);
    const [copiedPhone, setCopiedPhone] = useState(false);
    return (
        <PopUpCardModal fullSpace={false} body={
            <div className="CDetails__container">
                <div className="CDetails__section">
                    <p className="CDetails__infoTitle">Nombre y apellido</p>
                    <p className="CDetails__content">{name} {lastName}</p>
                </div>
                <div className="CDetails__section">
                    <p className="CDetails__infoTitle">Mail</p>
                    <div className="CDetails__contentCopyable">
                        <Mailto body=
                                    {`¡Buenos días, ${name}!
Me pongo en contacto con usted para consultarle sobre la cotización de su ${(quotationDetail.product).toLowerCase()} ${quotationDetail.detail} que realizó el día ${moment(quotationDetail.date).format('DD/MM/YYYY')}.
                        
Cualquier consulta, quedo a su disposición!
Saludos.`}
                                subject='Consulta cotización realizada'
                                email={email}>{<p
                            className="CDetails__content CDetails__content--clickable">{email}</p>}</Mailto>
                        <CopyIcon className="CDetails__copyIcon" onClick={() => {
                            navigator.clipboard.writeText(email)
                            setCopiedMail(true)
                            setCopiedPhone(false)
                        }}/>
                    </div>
                </div>
                {copiedMail && <p className="CDetails__copyAlert">Mail copiado exitosamente</p>}
                <div className="CDetails__section">
                    <p className="CDetails__infoTitle">Celular</p>
                    <div className="CDetails__contentCopyable">
                        <p className="CDetails__content CDetails__content--clickable" onClick={() =>
                            sendWhatsapp('https://wa.me/' + phone + '?text=¡Buenos%20días,%20' + name + '!%0aMe%20pongo%20en%20contacto%20con%20usted%20para%20consultarle%20sobre%20la%20cotización%20de%20su%20' + (quotationDetail.product).toLowerCase() + '%20' + quotationDetail.detail + '%20que%20realizó%20el%20día%20%20' + '%20' + moment(quotationDetail.date).format('DD/MM/YYYY') + '.')
                        }>{phone}</p>
                        <CopyIcon className="CDetails__copyIcon" onClick={() => {
                            navigator.clipboard.writeText(phone)
                            setCopiedPhone(true)
                            setCopiedMail(false)
                        }}/>
                    </div>
                </div>
                {copiedPhone && <p className="CDetails__copyAlert">Celular copiado exitosamente</p>}
            </div>
        } title="Información de contacto" closeAction={() => {
            setClientInfo(null)
            setOpenClientInfo(false)
            setCopiedPhone(false)
            setCopiedMail(false)
        }}/>
    );
}

export default ClientDetails;