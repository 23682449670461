import React from 'react';
import {
    SET_CURRENT_ANSWER,
    SET_ERROR,
} from '../../../reducers/types.js';
import {Circle} from "../../../../../../components/Circle";

function Answer(props) {
    let classes = ['answer'];
    const {dispatch, selected, letter, answer} = props;

    const handleClick = e => {
        dispatch({type: SET_CURRENT_ANSWER, currentAnswer: e.target.value});
        dispatch({type: SET_ERROR, error: ''});

    };

    if (selected) {
        classes.push('selected');
    }
    return (
        <button value={letter} className={classes.join(' ')}
                onClick={handleClick}>
            <div className="Answer__circleContainer"><Circle variant="md"> <span
                className="Answer__letter">{letter}</span></Circle></div>
            {answer}
        </button>
    );
}

export default Answer;