import React from 'react';
import './CollapsibleBox.css';
import {ReactComponent as ChevronDown} from '../../assets/icons/ic_chevron_down.svg';

function CollapsibleBox(props) {
    const {collapse, content, header, withButton, setCollapse} = props;

    return (
        <div className={`${collapse ? "CBox__container--close" : "CBox__container"}`}>
            <div className={`CBox__header ${collapse ? "CBox__header--collapsed" : ""}`}>
                <div onClick={withButton ? () => setCollapse(!collapse) : null}>
                    {header}
                </div>
                {withButton &&
                    <ChevronDown className={`CBox__arrowDown ${collapse ? '' : 'rotated'}`}/>}
            </div>
            <>
                {!collapse && content}
            </>
        </div>

    );
}

export default CollapsibleBox;