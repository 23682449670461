import React from 'react';
import './ProductsColumn.css';
import TextLink from "../../../../components/TextLink/TextLink";

function ProductsColumn(props) {
    const {row, setModalOpen} = props;
    const {productos} = row;

    const handleOpenModal = () => {
        setModalOpen({
            modalName: "Products",
            values: {row: row, products: productos}
        })
    }

    return (
        <>
            <TextLink
                size={"sm"}
                action={() => handleOpenModal()}>
                Ver productos
            </TextLink>
        </>
    );
}

export default ProductsColumn;