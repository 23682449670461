import "./RoundedPanel.css"

function RoundedPanel(props) {
    const {children, color} = props;
    return (
        <div className={`roundedPanel__container roundedPanel__container--${color}`}>
            {children}
        </div>
    )
}

export default RoundedPanel;
