import React, {useContext} from 'react';
import './Ranking.css'
import '../RankingCard.css'
import {capitalizeString} from "../../../../modules/utils/stringFormat";

function Ranking(props) {
    const {rankingEmisores} = props;

    function AddZero(num) {
        let arg = num.toString()
        if (arg.length === 1) {
            arg = arg.padStart(2, '0')
        }
        return arg;
    }

    function nameFormat(names) {
        const result = names.split(' ');
        if (result.length > 1) {
            const secondName = result[1].charAt(0);
            return `${result[0]} ${secondName}.`;
        }
        return names
    }

    return (
        <div className="Ranking__container">
            <p className="Ranking__title">Ranking</p>
            <table className="Ranking__table">
                <thead>
                <tr className="Ranking__headers">
                    <th className="Ranking__nameHeader">Nombre</th>
                    <th className="Ranking__emitHeader">Emisiones</th>
                </tr>
                </thead>
                <tbody>
                {(rankingEmisores !== null) &&
                    (rankingEmisores["Ranking"]?.map((row, index) => {
                        const isMyInfo = index + 1 === rankingEmisores["Personal"].Posicion;
                        if (0 <= index && index < 3) {
                            return (
                                <tr key={index}
                                    className={`Ranking__cells ${isMyInfo ? "Ranking__personal" : ""}`}>
                                    <td className={`Ranking__position--generic Ranking__emit 
                                                        ${isMyInfo ? "Ranking__personal" : ""}`}>
                                        <div className={"Ranking__emitBackground Ranking__top"}>
                                            {AddZero(index + 1)}
                                        </div>
                                    </td>
                                    <td className={`Ranking__nameContainer 
                                                    ${isMyInfo ? "Ranking__personal" : "Ranking__top"}`}>
                                        {capitalizeString(nameFormat(row.Nombre))} {capitalizeString(row.Apellido)}
                                    </td>
                                    <td className={`Ranking__emit--generic 
                                                        ${isMyInfo ? "Ranking__emit--personal" : ""} Ranking__top`}>
                                        {row.Puntaje}
                                    </td>
                                </tr>
                            )
                        } else {
                            return (
                                <tr key={index}
                                    className={`Ranking__cells ${isMyInfo ? "Ranking__personal" : ""}`}>
                                    <td className={`Ranking__position--generic Ranking__emit 
                                                        ${isMyInfo ? "Ranking__personal" : ""} Ranking__emit--bottom`}>
                                        <div className={`Ranking__emitBackground Ranking__bottom`}>
                                            {AddZero(index + 1)}
                                        </div>
                                    </td>
                                    <td className={`Ranking__nameContainer 
                                                    ${isMyInfo ? "Ranking__personal" : ""}`}>
                                        {capitalizeString(nameFormat(row.Nombre))} {capitalizeString(row.Apellido)}
                                    </td>
                                    <td className={`Ranking__emit--generic 
                                                        ${isMyInfo ? "Ranking__emit--personal" : ""} 
                                                        Ranking__emit--generic`}>
                                        {row.Puntaje}
                                    </td>
                                </tr>
                            )
                        }
                    }))}
                </tbody>
            </table>
        </div>
    );
}

export default Ranking;