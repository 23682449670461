import React, {useEffect} from "react";
import withRoot from '../withRoot';
import Loading from './Loading';
import Axios from 'axios';
import {useAuth0} from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";

function Callback() {
    const {getIdTokenClaims, isAuthenticated} = useAuth0();
    const history = useHistory();

    async function getToken() {
        let claims = await getIdTokenClaims();
        return claims.__raw;
    }

    useEffect(() => {
        if(isAuthenticated){
            getToken().then(
                id_token => {
                    Axios.post(process.env.REACT_APP_AUTH0_AUDIENCE, {
                            query: `query MyQuery {
                              DatosUsuarios_datos_polkista {
                                id_polkista
                              }
                            }`
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${id_token}`,
                            }
                        }).then((result) => {
                        if(result.data.data['DatosUsuarios_datos_polkista'][0] === undefined){
                            history.push('/form');
                        }else{
                            history.push('/u/dashboard');
                        }
                    });
                }
            );
        }else {
            history.push('/');
        }
    });

    return (
        <Loading/>
    )
}

export default withRoot(Callback);
