import React, {useContext} from 'react';
import {Divider, Grid} from "@mantine/core";
import {useFormikContext} from "formik";
import moment from "moment";
import {PolizaModel} from "./PolizaModel";
import {MantineDateField} from "../../../../../../components/FormFields/MantineDateField";
import MantineDependantField from "../../../../../../components/FormFields/MantineDependantField/MantineDependantField";
import {MantineSelectField} from "../../../../../../components/FormFields/MantineSelectField";
import {MantineInputField} from "../../../../../../components/FormFields/MantineInputField";
import CotizarContext from "../../../../../../context/CotizarContext";

export default function PolizaForm() {
    const {formField} = PolizaModel();
    const {vigenciaDesde, vigenciaHasta, formaDePago, marcaTarjeta, numeroTarjeta, numeroCbu} = formField;

    const {companiesDict, genericOptionsFormasDePago} = useContext(CotizarContext);
    const {values} = useFormikContext();

    const formasDePago = values.company ? companiesDict[values.company].codigoFormasDePago : {};
    const marcasTarjeta = values.company ? companiesDict[values.company].idsTarjeta : [];
    const filteredOptions = genericOptionsFormasDePago.filter(option =>
        formasDePago.hasOwnProperty(option.value)
    );

    const calculateVigenciaHasta = () => {
        if (values.company === "Sancor") {
            if (values.parametrosAdicionales.frecuenciaPago === "5") {
                return moment(values.vigenciaDesde, "YYYY-MM-DD").add(1, "Y").toDate()
            } else {
                return moment(values.vigenciaDesde, "YYYY-MM-DD").add(6, "M").toDate()
            }
        } else if (values.company === "RUS") {
            if (values.parametrosAdicionales.tipoVigencia === "ANUAL") {
                return moment(values.vigenciaDesde, "YYYY-MM-DD").add(1, "Y").toDate()
            } else if (values.parametrosAdicionales.tipoVigencia === "TRIMESTRAL") {
                return moment(values.vigenciaDesde, "YYYY-MM-DD").add(3, "M").toDate()
            }
            else {
                return moment(values.vigenciaDesde, "YYYY-MM-DD").add(6, "M").toDate()
            }
        }
        else if (values.company === "Zurich") {
            return moment(values.vigenciaDesde, "YYYY-MM-DD").add(1, "Y").toDate()
        }
        else if (values.company === "Federacion_Patronal") {
            if (values.parametrosAdicionales.tipoVigencia === "CUATRIMESTRAL") {
                return moment(values.vigenciaDesde, "YYYY-MM-DD").add(4, "M").toDate()
            } else if (values.parametrosAdicionales.tipoVigencia === "MENSUAL") {
                return moment(values.vigenciaDesde, "YYYY-MM-DD").add(1, "M").toDate()
            }
        }
    }

    const formatVigenciaDisplay = (toConvert) => {
        return moment(toConvert, 'YYYY-MM-DD').format('DD/MM/YYYY')
    }

    return (
        <div className="automotor__formStep__container">
            <Grid gutter="md" justify="center" align="center">
                <Grid.Col xs={12} sm={6}>
                    <MantineDateField
                        name={vigenciaDesde.name}
                        label={vigenciaDesde.label}
                        withAsterisk
                    />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <MantineDependantField
                        dependantFieldName={vigenciaHasta.name}
                        dependantFieldLabel={vigenciaHasta.label}
                        dependencies={[values.vigenciaDesde]}
                        calculateValueFunction={calculateVigenciaHasta}
                        formatDisplay={formatVigenciaDisplay}
                        disabled
                        withAsterisk
                    />
                </Grid.Col>

                <Divider size="sm"/>

                <Grid.Col xs={12} sm={6}>
                    <MantineSelectField
                        name={formaDePago.name}
                        label={formaDePago.label}
                        data={filteredOptions}
                        withAsterisk
                    />
                </Grid.Col>

                <Grid.Col xs={12} sm={6}/>

                {values.formaDePago === 'TC' && (
                    <>
                        <Grid.Col xs={12} sm={6}>
                            <MantineSelectField
                                name={`infoDePago.${marcaTarjeta.name}`}
                                label={marcaTarjeta.label}
                                data={marcasTarjeta}
                                withAsterisk
                            />
                        </Grid.Col>
                        <Grid.Col xs={12} sm={6}>
                            <MantineInputField
                                name={`infoDePago.${numeroTarjeta.name}`}
                                label={numeroTarjeta.label}
                                withAsterisk
                            />
                        </Grid.Col>
                    </>
                )}

                {values.formaDePago === 'CBU' && (
                    <>
                        <Grid.Col xs={12} sm={6}>
                            <MantineInputField
                                name={`infoDePago.${numeroCbu.name}`}
                                label={numeroCbu.label}
                                withAsterisk
                            />
                        </Grid.Col>
                        <Grid.Col xs={12} sm={6}/>
                    </>
                )}
            </Grid>
        </div>
    );
}