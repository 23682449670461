import {
    createMuiTheme,
    responsiveFontSizes,
    makeStyles, withStyles,
} from '@material-ui/core/styles';
import {cyan, green, red} from '@material-ui/core/colors';
import MuiTreeItem from "@material-ui/lab/TreeItem";
let theme = createMuiTheme({
    palette: {
        primary: {
            light: '#35277F',
            main: '#0D2048',
            dark: '#0d1f3e',
        },
        secondary: {
            light: '#8E66A5',
            main: '#6eb97e',
            dark: '#6CC2C1',
        },

        warning: {
            main: '#ffc071',
            dark: '#ffb25e',
        },
        error: {
            xLight: red[50],
            main: red[500],
            dark: red[700],
        },
        success: {
            xLight: green[50],
            main: green[500],
            dark: green[700],
        }, },
});
theme = responsiveFontSizes(theme);
const useStyle = makeStyles(() => ({
    root: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(700 + theme.spacing(2) * 2)]: {
            width: 700,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        "&.MuiTreeItem-root > .MuiTreeItem-content .MuiTreeItem-label ": {
            fontFamily: "Ubuntu",
            fontSize: 16,
            fontWeight: "bold",
            fontStyle: "normal",
            letterSpacing: 0,
            color: "#fff",
        },
    backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },




}));
export { theme, useStyle };

