import moment from "moment";
import * as Yup from "yup";

const DetallesVehiculoModel = () => {
    const formField = {
        comentariosSolicitud: {
            name: 'comentariosSolicitud',
            label: 'Comentarios de la solicitud',
            requiredErrorMsg: ''
        },
        //   VEHICULO OBJ
        marca: {
            name: 'marca',
            label: 'Marca',
            requiredErrorMsg: ''
        },
        version: {
            name: 'version',
            label: 'Versión',
            requiredErrorMsg: ''
        },
        anio: {
            name: 'anio',
            label: 'Año',
            requiredErrorMsg: 'Debe especificar el año del modelo'
        },
        sumaAseguradaVehiculo: {
            name: 'sumaAseguradaVehiculo',
            label: 'Suma asegurada del vehículo',
            requiredErrorMsg: 'Debe especificar la suma asegurada del vehículo'
        },
        patente: {
            name: 'patente',
            label: 'Patente',
            requiredErrorMsg: 'Debe especificar la patente'
        },
        motor: {
            name: 'motor',
            label: 'Número de motor',
            requiredErrorMsg: 'Debe especificar el número de motor'
        },
        chasis: {
            name: 'chasis',
            label: 'Número de chasis',
            requiredErrorMsg: 'Debe especificar el número de chasis'
        },
        esCeroKm: {
            name: 'esCeroKm',
            label: 'Vehículo 0 Km',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        //    BIENESADICIONALES OBJ [VEHICULO > BIENESADICIONALES]
        identificadorSancor: {
            name: 'identificadorSancor',
            label: 'Bien adicional asegurado',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        descripcion: {
            name: 'descripcion',
            label: 'Descripción',
            requiredErrorMsg: 'Debe completar la descripción'
        },
        monto: {
            name: 'monto',
            label: 'Suma asegurada',
            requiredErrorMsg: 'Debe especificar el monto'
        },
    }

    const initialValues = {

        [formField.comentariosSolicitud.name]: '',
        motovehiculo: {
            [formField.marca.name]: '',
            [formField.version.name]: '',
            [formField.anio.name]: '',
            [formField.sumaAseguradaVehiculo.name]: '',
            [formField.patente.name]: '',
            [formField.motor.name]: '',
            [formField.chasis.name]: '',
            [formField.esCeroKm.name]: 'false',
            bienesAdicionales: [],
        },

    }

    const validationSchema = Yup.object().shape({
        [formField.comentariosSolicitud.name]: Yup.string()
            .matches(/^(?=[a-zA-Z0-9()_+=,.\/%$:\s-]*$)/, "Por favor, evite caracteres especiales"),
        motovehiculo: Yup.object().shape({
            [formField.sumaAseguradaVehiculo.name]: Yup.number()
                .required(`${formField.sumaAseguradaVehiculo.requiredErrorMsg}`)
                .typeError("El valor ingresado no es válido"),
            [formField.patente.name]: Yup.string().test('patente', 'La patente ingresada no es válida', (value, context) => {
                const regex1 = /^[A-Z]\d{3}[a-zA-Z]{3}$/i;
                const regex2 = /^\d{3}[a-zA-Z]{3}$/i;
                const ceroKmFieldValue = context.parent.esCeroKm;

                /**
                 * If ceroKmFieldValue === "true"
                 *     ? value could be undefined or (if its defined) pass the regex test
                 *     : value has to pass the regex test
                 * */
                return ceroKmFieldValue === "true"
                    ? !value || [regex1, regex2].some(e => e.test(value))
                    : [regex1, regex2].some(e => e.test(value));
            }),
            [formField.motor.name]: Yup.string()
                .required(`${formField.motor.requiredErrorMsg}`)
                .min(4, 'El número ingresado es muy corto')
                .matches(/^(?=[a-zA-Z0-9()_+=,.:\s-]*$)/, "Por favor, evite caracteres especiales")
                .max(20, "El número ingresado es muy largo"),
            [formField.chasis.name]: Yup.string()
                .required(`${formField.chasis.requiredErrorMsg}`)
                .matches(/^(?=[a-zA-Z0-9()_+=,.:\s-]*$)/, "Por favor, evite caracteres especiales")
                .min(4, 'El número ingresado es muy corto')
                .max(20, "El número ingresado es muy largo"),

            [formField.marca.name]: Yup.string().required(`${formField.marca.requiredErrorMsg}`),
            [formField.version.name]: Yup.string().required(`${formField.version.requiredErrorMsg}`),
            [formField.anio.name]: Yup.number()
                .required(`${formField.anio.requiredErrorMsg}`)
                .integer()
                .max(moment().format("YYYY"), "Ingrese un año válido")
                .typeError("Ingrese un año válido"),
            [formField.esCeroKm.name]: Yup.string().when([formField.anio.name], {
                is: moment().format('YYYY'),
                then: Yup.string().required(`${formField.esCeroKm.requiredErrorMsg}`),
            }),
            bienesAdicionales: Yup.array().of(
                Yup.object().shape({
                    [formField.identificadorSancor.name]: Yup.string().required(`${formField.identificadorSancor.requiredErrorMsg}`),
                    [formField.descripcion.name]: Yup.string()
                        .required(`${formField.descripcion.requiredErrorMsg}`)
                        .matches(/^([A-Za-z0-9 -]+)$/, "Esta utilizando carácteres no válidos"),
                    [formField.monto.name]: Yup.number()
                        .required(`${formField.monto.requiredErrorMsg}`)
                        .min(30000, "El monto mínimo es $30.000")
                        .max(1000000, "El monto máximo es $1.000.000")
                        .typeError("El valor ingresado no es válido"),
                })
            ),
        })
    })

    return {formField, initialValues, validationSchema}
}

export {DetallesVehiculoModel};
