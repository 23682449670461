import React, {useMemo, useState} from 'react';
import './ErrorModal.css';
import {PopUpCard} from "../../components/PopUpCard";
import {ReactComponent as CloseIcon} from "../../assets/icons/ic_close.svg";
import {ReactComponent as ExclamationIcon} from "../../assets/icons/ic_exclamation.svg";
import {CustomModal} from "../../components/CustomModal";

function ErrorModal(props) {
    const {closeAction, title, typeError, suggestedActions, detailedError} = props;
    const detail = useMemo(() => {
        if (detailedError.constructor === Object) {
            return Object.keys(detailedError).map(key => (
                <p className="errorModal__text">
                    {key}: {detailedError[key]}
                </p>
            ))
        } else {
            return detailedError
        }
    }, []);
    const [verMasOpen, setVerMasOpen] = useState(false);

    return (
        <CustomModal>
            <PopUpCard>
                <div className="errorModal__container">

                    <div className="errorModal__header">
                        <div className="errorModal__icon__container" onClick={closeAction}>
                            <CloseIcon className="errorModal__icon__close"/>
                        </div>
                        <div className="errorModal__title">
                            <ExclamationIcon className="errorModal__icon"/>
                            {title}
                        </div>
                    </div>

                    <div className={`errorModal__body errorModal__body--${verMasOpen}`}>
                        <div className="errorModal__body__block">
                            <p className="errorModal__subtitle">Error</p>
                            <p className="errorModal__text">{typeError}</p>
                        </div>

                        <div className="errorModal__body__block">
                            <p className="errorModal__subtitle">Acciones recomendadas</p>
                            {suggestedActions.map(action => (
                                <p className="errorModal__text">{action}</p>
                            ))}
                        </div>
                    </div>

                    <div className={`errorModal__body__block errorModal__body__block--${verMasOpen}`}>
                        <p className="errorModal__subtitle--link" onClick={() => setVerMasOpen(!verMasOpen)}>
                            Ver {verMasOpen ? "menos" : "más"} información del problema</p>

                        <p className={`errorModal__text errorModal__text--${verMasOpen}`}>
                            {detail}
                        </p>
                    </div>
                </div>
            </PopUpCard>
        </CustomModal>
    );
}

export default ErrorModal;