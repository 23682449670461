import {Modal} from "rsuite";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import ReactCrop from 'react-image-crop';
import "react-image-crop/dist/ReactCrop.css";
import Axios from "axios";
import AuthContext from "../../../context/AuthContext";
import car_icon from "../../../assets/images/icons/car_icon.png";
import house_icon from "../../../assets/images/icons/house_icon.png";
import microseguros_icon from "../../../assets/images/icons/microseg_icon.png";
import TextField from "../../components/TextField";
import {Formik, Form, FieldArray} from 'formik';
import tilde from "../../../assets/images/icons/tilde.png";
import cruz from "../../../assets/images/icons/cruz.png";
import Alert from "@material-ui/lab/Alert";
import {Button, Checkbox, Grid, Snackbar, Tooltip} from "@material-ui/core";
import MasterContext from "../../../context/MasterContext";
import {InputField} from "../../components/FormFields";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import SocialMediaLinks from "./SocialMediaLinks";
import {gql, useMutation} from "@apollo/client";
import PiezaGrafica from "../../components/PiezaGrafica";
import {IconCopy} from "@tabler/icons"

const FileDownload = require('js-file-download');
const useStyles = makeStyles(theme => ({
    uploadRectangle: {
        borderStyle: "dashed",
        borderWidth: "1px",
        borderColor: "#9A9A9A",
        borderRadius: "10px",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        textAlign: "center"
    },
    modalColumn: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly"
    },
    modalRow: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    modalRowToColumn: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        maxWidth: "550px",
        maxHeight: "330px",
        overflow: "auto",
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: "1fr",
        },
    },
    modalSpacedRow: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        margin: "8px 0px"
    },
    modalBlock: {
        margin: "8px 16px"
    },
    modalFooter: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "16px"
    },
    modalTitle: {
        fontFamily: "Ubuntu",
        fontWeight: '500',
        fontSize: "20px",
        color: "#452d7b",
        textAlign: "center",
        marginBottom: "16px"
    },
    modalSubtitle: {
        fontFamily: "Ubuntu",
        fontWeight: "700",
        fontSize: "16px",
        textAlign: "center"
    },
    iconCopy: {
      marginLeft: "16px",
      cursor: "pointer"
    },
    modalText: {
        fontFamily: "Ubuntu",
        fontWeight: "400",
        fontSize: "14px",
        textAlign: 'center',
        width: "100%"
    },
    coloredModalText: {
        fontFamily: "Ubuntu",
        fontWeight: "300",
        fontSize: "16px",
        textAlign: 'center',
        lineHeight: "1",
        color: '#452d7b'
    },
    btnShare: {
        borderRadius: "30px",
        color: "#fff",
        fontSize: "14px",
        background: "#452d7b",
        padding: "10px 30px 10px 30px",
        margin: "10px 5px -10px 5px",
        textDecoration: "none",
        border: "solid #452d7b 0px",
        fontFamily: "Ubuntu",
        '&:hover': {
            backgroundColor: "#62C08A",
        },
    },
    activatedProd: {
        width: "72px",
        height: "72px",
        margin: "0px 4px",
        background: "#62C08A",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: '3px 3px 6px rgba(48, 48, 48, 0.3)',
        cursor: "pointer"
    },
    disabledProd: {
        width: "72px",
        height: "72px",
        margin: "0px 4px",
        background: "#d0d0d0",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: '3px 3px 6px rgba(48, 48, 48, 0.3)',
        cursor: "pointer"
    },
    activatedProdTiny: {
        width: "32px",
        height: "32px",
        margin: "0px 4px",
        background: "#62C08A",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: '3px 3px 6px rgba(48, 48, 48, 0.3)',
    },
    disabledProdTiny: {
        width: "32px",
        height: "32px",
        margin: "0px 4px",
        background: "#d0d0d0",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: '3px 3px 6px rgba(48, 48, 48, 0.3)',
    },
    iconProduct: {
        width: "56px",
        height: "56px",
    },
    iconTiny: {
        width: "16px",
        height: "16px",
    },
    descriptionCard: {
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        borderRadius: "4px",
        transition: "0.3s",
        width: "400px",
        padding: "8px",
        margin: "16px 8px",
    },
    cellLogo: {
        width: "auto",
        height: "24px",
        objectFit: "contain"
    },
    alert: {
        backgroundColor: theme.palette.warning.dark
    },
    buttonContained: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        backgroundColor: '#452d7b',
        '&:hover': {
            backgroundColor: '#38217b',
            boxShadow: 'none',
        },
    },
    buttons: {
        display: "flex",
        justifyContent: "center"
    },
    linksDiv: {
        display: "flex",
        justifyContent: "center",
        width: "100%"
    },
    fieldArrayButtons: {
        padding: "0",
        marginTop: "16px",
        minWidth: "28px",
        maxWidth: "28px"
    },
    fieldArrayButtonsDiv: {
        display: "flex",
        flexDirection: "row",
        width: "64px",
        justifyContent: "space-between"
    },
    descriptionGrid: {
        display: "flex"
    },
    cardHighlight: {
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        borderRadius: "4px",
        width: "300px",
        margin: "16px",
        padding: "16px",
    },
    descriptionText: {
        fontFamily: "Ubuntu",
        fontWeight: "400",
        fontSize: "14px",
        textAlign: 'center',
        width: "100%"
    },
}));

const Mailto = ({email, subject, body, children}) => {
    return (
        <a href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>{children}</a>
    );
};

const sendWapp = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

const ModalURLandQR = (props) => {
    const classes = useStyles();
    const {setOpen, open, urlInfo, qrInfo, fromMaster, setRowDataSelected} = props;
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [openErrorSnackBar, setOpenErrorSnackBar] = useState(false);

    return (
        <Modal backdrop={true} show={open} onHide={() => {
            setOpen(!open);
            if (fromMaster)
                setRowDataSelected(null)
        }} style={{top: '10%'}}>
            <Modal.Header/>
            <Modal.Body>
                <div className={classes.modalColumn}>
                    <p className={classes.modalTitle}>
                        Información del Canal Digital
                    </p>

                    <div className={classes.modalBlock}>
                        <p className={classes.modalSubtitle}>Link:</p>
                        <div className={classes.modalRow}>
                            <a className={classes.modalText} href={urlInfo}
                               target="_blank">{urlInfo}</a>
                            <Tooltip title={"Copiar"}>
                                <div>
                            <IconCopy className={classes.iconCopy} onClick={() => {
                                let textarea = document.createElement("textarea");
                                textarea.textContent = urlInfo;
                                textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
                                document.body.appendChild(textarea);
                                textarea.select();
                                try {
                                    let copied = document.execCommand("copy");
                                    setOpenSnackBar(true)
                                    return copied;  // Security exception may be thrown by some browsers.
                                }
                                catch (ex) {
                                    console.warn("Copy to clipboard failed.", ex);
                                    return prompt("Copy to clipboard: Ctrl+C, Enter", urlInfo);
                                }
                                finally {
                                    document.body.removeChild(textarea);
                                }
                            }}/>
                                </div>
                            </Tooltip>
                        </div>

                    </div>

                    <div className={classes.modalBlock}>
                        <p className={classes.modalSubtitle}>QR:</p>
                        <img src={qrInfo} width={"200px"} height={"200px"}/>
                    </div>

                </div>
            </Modal.Body>
            {fromMaster &&
            <Modal.Footer>
                <div className={classes.modalFooter}>
                    <button className={classes.btnShare} onClick={
                        () => sendWapp('https://wa.me/?text=Me%20complace%20informarle%20que%20su%20módulo%20web%20de%20venta%20directa%20se%20encuentra%20habilitado%20y%20puede%20comenzar%20a%20operar%20a%20través%20del%20mismo.%20Estos%20son%20los%20datos%20que%20necesitará%20para%20comenzar%20a%20operar:%20' + urlInfo)
                    }>
                        Compartir vía WhatsApp
                    </button>

                    <button className={classes.btnShare} onClick={() => {
                        fetch(qrInfo)
                            .then(res => res.blob()) // Gets the response and returns it as a blob
                            .then(blob => {
                                FileDownload(blob, 'CanalDigitalQR.png');
                            })
                            .catch(() => setOpenErrorSnackBar(true));
                    }}>
                        Descargar
                    </button>
                </div>
            </Modal.Footer>
            }

            <Snackbar open={openSnackBar} autoHideDuration={4000} onClose={() => setOpenSnackBar(false)}>
                <Alert onClose={() => setOpenSnackBar(false)} severity="success" elevation={6} variant="filled">
                    ¡Copiado en el portapapeles!
                </Alert>
            </Snackbar>

            <Snackbar open={openErrorSnackBar} autoHideDuration={4000} onClose={() => setOpenErrorSnackBar(false)}>
                <Alert onClose={() => setOpenSnackBar(false)} severity="error" elevation={6} variant="filled">
                    Hubo un problema al intentar descargar el QR. Intente de nuevo
                </Alert>
            </Snackbar>

        </Modal>
    )
}


const ModalMarketing = (props) => {
    const classes = useStyles();
    const {setOpen, open, activeProducts, qr, logo, setRowDataSelected, setLoading} = props;

    return (
        <Modal backdrop={true} show={open} onHide={() => {
            setOpen(!open)
        }} style={{top: '10%', maxHeight: "80%"}}>
            <Modal.Header/>
            <Modal.Body>
                <div className={classes.modalColumn}>
                    <p className={classes.modalTitle}>
                        Piezas gráficas disponibles para este canal
                    </p>

                    <p className={classes.modalSubtitle}>Productos habilitados:</p>

                    <div className={classes.modalRowToColumn}>

                        {activeProducts[0] && activeProducts[1] && activeProducts[2] &&
                        <PiezaGrafica
                            preview={"https://polko-images.s3.us-east-2.amazonaws.com/CanalesDigitales/folletos/folleto_2.jpg"}
                            alternative={2}
                            logo={logo}
                            qr={qr}
                            activeProducts={activeProducts}
                            setLoading={setLoading}
                            setOpen={setOpen}
                            setRowDataSelected={setRowDataSelected}
                            name={"Todos los productos"}
                        />
                        }

                        {activeProducts[0] &&
                        <PiezaGrafica
                            preview={"https://polko-images.s3.us-east-2.amazonaws.com/CanalesDigitales/folletos/folleto_0.jpg"}
                            alternative={0}
                            logo={logo}
                            qr={qr}
                            activeProducts={activeProducts}
                            setLoading={setLoading}
                            setOpen={setOpen}
                            setRowDataSelected={setRowDataSelected}
                            name={"Automotor"}
                        />
                        }

                        {activeProducts[1] &&
                        <PiezaGrafica
                            preview={"https://polko-images.s3.us-east-2.amazonaws.com/CanalesDigitales/folletos/folleto_1.jpg"}
                            alternative={1}
                            logo={logo}
                            qr={qr}
                            activeProducts={activeProducts}
                            setLoading={setLoading}
                            setOpen={setOpen}
                            setRowDataSelected={setRowDataSelected}
                            name={"Hogar"}
                        />
                        }

                        {activeProducts[2] &&
                        <>
                            <PiezaGrafica
                                preview={"https://polko-images.s3.us-east-2.amazonaws.com/CanalesDigitales/folletos/folleto_3.jpg"}
                                alternative={3}
                                logo={logo}
                                qr={qr}
                                activeProducts={activeProducts}
                                setLoading={setLoading}
                                setOpen={setOpen}
                                setRowDataSelected={setRowDataSelected}
                                name={"Bicicleta y monopatín"}
                            />
                            <PiezaGrafica
                                preview={"https://polko-images.s3.us-east-2.amazonaws.com/CanalesDigitales/folletos/folleto_4.jpg"}
                                alternative={4}
                                logo={logo}
                                qr={qr}
                                activeProducts={activeProducts}
                                setLoading={setLoading}
                                setOpen={setOpen}
                                setRowDataSelected={setRowDataSelected}
                                name={"Notebook y tablet"}
                            />
                        </>
                        }
                    </div>

                </div>
            </Modal.Body>
        </Modal>
    )
}

const ModalLogo = (props) => {
    const classes = useStyles();
    const {setOpen, open, currentLogo, id, setLoading, setRowDataSelected} = props;
    const {authToken} = useContext(AuthContext);
    const {infoMaster, handleModified} = useContext(MasterContext);


    const hiddenFileInput = useRef(null);
    const [imgSrc, setImgSrc] = useState('')
    const [userFinalImage, setUserFinalImage] = useState(null);
    const [userImageUrl, setUserImageUrl] = useState(null);
    const imgRef = useRef(null)
    const [cropOpen, setCropOpen] = useState(false);
    const [crop, setCrop] = useState({
        unit: '%', // Can be 'px' or '%'
        x: 25,
        y: 25,
        aspect: 1,
        width: 50,
        height: 50
    })

    const handleCropOpen = () => {
        setOpen(false)
        setCropOpen(true)
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const saveLogo = async () => {
        const url = process.env.REACT_APP_BACK_SERVER + `/editarTemplatePolkista`;

        const base64 = await convertBase64(userFinalImage)
        const jsonData = {
            operacion: "logo",
            id: id,
            idTemplateMaster: infoMaster["idMaster"],
            logo: base64,
        }

        setLoading(true)
        setOpen(false)
        setRowDataSelected(null)
        Axios.post(url, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            }
        }).then(res => {
            console.log(res)
            handleModified()
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        });
    }


    async function onSelectFile(e) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                setImgSrc(reader.result?.toString() || ''),
            )
            reader.readAsDataURL(e.target.files[0])

            setUserFinalImage(e.target.files[0])
        }
    }

    useEffect(() => {
        if (userFinalImage)
            setUserImageUrl(URL.createObjectURL(userFinalImage))
    }, [userFinalImage]);

    return (
        <>
            <Modal backdrop={true} show={open} onHide={() => {
                setOpen(!open);
                setRowDataSelected(null)
            }} style={{top: '10%'}}>
                <Modal.Header/>
                <Modal.Body>
                    <div className={classes.modalColumn}>
                        <p className={classes.modalTitle}>
                            Modificar el logo de este canal
                        </p>

                        <div className={classes.modalRow}>
                            <div className={classes.modalBlock}>
                                <p className={classes.modalSubtitle}>Logo Actual</p>
                                <img width="200" height="200" style={{objectFit: 'contain'}} src={currentLogo}/>
                            </div>
                            <div className={classes.modalBlock}>
                                <p className={classes.modalSubtitle}>Logo Nuevo</p>

                                <div
                                    className={classes.uploadRectangle}
                                    style={{height: `200px`, width: `200px`}}
                                    onClick={() => hiddenFileInput.current.click()}>
                                    <input
                                        type="file"
                                        accept="image/png, image/jpeg"
                                        style={{display: "none"}}
                                        onChange={onSelectFile}
                                        ref={hiddenFileInput}
                                    />
                                    {userImageUrl
                                        ? <img width={200} height={200} style={{objectFit: 'contain'}}
                                               src={userImageUrl}/>
                                        : <p style={{maxWidth: "160px"}}>{"Click para buscar el archivo"}</p>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className={classes.modalFooter}>
                        <button className={classes.btnShare} onClick={() => handleCropOpen()}>
                            Recortar
                        </button>
                        <button className={classes.btnShare} onClick={() => saveLogo()}>
                            Guardar
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>

            {cropOpen &&
            <ModalCrop
                setOpen={setCropOpen}
                open={cropOpen}
                setLogoOpen={setOpen}
                setRowDataSelected={setRowDataSelected}
                imgSrc={imgSrc}
                imgRef={imgRef}
                crop={crop}
                setCrop={setCrop}
                setUserFinalImage={setUserFinalImage}
            />
            }
        </>
    )
}

const ModalCrop = (props) => {
    const classes = useStyles();
    const {setOpen, open, setLogoOpen, setRowDataSelected, imgSrc, crop, imgRef, setCrop, setUserFinalImage} = props;
    const [completedCrop, setCompletedCrop] = useState()

    function getCroppedImg(image, _crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = _crop.width;
        canvas.height = _crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            _crop.x * scaleX,
            _crop.y * scaleY,
            _crop.width * scaleX,
            _crop.height * scaleY,
            0,
            0,
            _crop.width,
            _crop.height,
        );

        return new Promise((resolve) => {
            canvas.toBlob(blob => {
                blob.name = fileName;
                resolve(blob);
            }, 'image/png, image/jpeg', 1);
        });
    }

    const handleUpload = async () => {
        if (!imgRef || !completedCrop)
            return;
        const croppedImg = await getCroppedImg(imgRef.current, completedCrop, `defaultName`)

        setUserFinalImage(croppedImg)
        setOpen(false)
        setLogoOpen(true)
    };

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    return (
        <>
            <Modal backdrop={true} show={open} onHide={() => {
                setOpen(!open);
                setRowDataSelected(null)
            }} style={{top: '10%', maxHeight: "80%"}}>
                <Modal.Header/>
                <Modal.Body>
                    <div className={classes.modalColumn}>
                        <p className={classes.modalTitle}>
                            Seleccione el área de recorte
                        </p>

                        <div className={classes.modalBlock}>
                            {!!imgSrc && (
                                <ReactCrop
                                    src={imgSrc}
                                    style={{maxWidth: "400px", maxHeight: "auto"}}
                                    crop={crop}
                                    onImageLoaded={onLoad}
                                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                                    onComplete={(c) => setCompletedCrop(c)}
                                />
                            )}
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className={classes.modalFooter}>
                        <button className={classes.btnShare} onClick={handleUpload}>
                            Guardar
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const ModalProducts = (props) => {
    const classes = useStyles();
    const {setOpen, open, activatedProducts, id, setLoading, setRowDataSelected} = props;
    const {authToken} = useContext(AuthContext);
    const {handleModified} = useContext(MasterContext);
    const [activatedProductsState, setActivatedProductsState] = useState(activatedProducts);
    const [openSnackBar, setOpenSnackBar] = useState(false);

    const saveProducts = () => {
        if (activatedProductsState.includes(true)) {
            const url = process.env.REACT_APP_BACK_SERVER + `/editarTemplatePolkista`;

            const jsonData = {
                operacion: "productos",
                id: id,
                productos: activatedProductsState
            }

            setLoading(true)
            setOpen(false)
            setRowDataSelected(null)
            Axios.post(url, jsonData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                }
            }).then(() => {
                setLoading(false)
                handleModified()
            }).catch(() => {
                setLoading(false)
            });
        } else {
            setOpenSnackBar(true)
        }
    }

    const handleActivateProduct = (prod) => {
        let items = [...activatedProductsState];
        items[prod] = !items[prod]

        setActivatedProductsState(items)
    }

    return (
        <>
            <Modal backdrop={true} show={open} onHide={() => {
                setOpen(!open);
                setRowDataSelected(null)
            }} style={{top: '10%'}}>
                <Modal.Header/>
                <Modal.Body>
                    <div className={classes.modalColumn}>
                        <p className={classes.modalTitle}>
                            Modificar los productos habilitados para este canal
                        </p>

                        <div className={classes.modalBlock}>
                            <div className={classes.modalRow}>
                                <div
                                    className={activatedProductsState[0] ? classes.activatedProd : classes.disabledProd}
                                    onClick={() => handleActivateProduct(0)}>
                                    <img className={classes.iconProduct} src={car_icon}/>
                                </div>
                                <div
                                    className={activatedProductsState[1] ? classes.activatedProd : classes.disabledProd}
                                    onClick={() => handleActivateProduct(1)}>
                                    <img className={classes.iconProduct} src={house_icon}/>
                                </div>
                                <div
                                    className={activatedProductsState[2] ? classes.activatedProd : classes.disabledProd}
                                    onClick={() => handleActivateProduct(2)}>
                                    <img className={classes.iconProduct} src={microseguros_icon}/>
                                </div>
                            </div>
                        </div>

                        <p className={classes.modalText}>Haga click sobre los productos que desea habilitar</p>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className={classes.modalFooter}>
                        <button className={classes.btnShare} onClick={saveProducts}>
                            Guardar
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Snackbar open={openSnackBar} autoHideDuration={5000} onClose={() => setOpenSnackBar(false)}>
                <Alert onClose={() => setOpenSnackBar(false)} severity="warning" elevation={6} variant="filled"
                       classes={{filledWarning: classes.alert}}
                >
                    {"Debe seleccionar al menos un producto"}
                </Alert>
            </Snackbar>
        </>
    )
}

const INSERT_NEW_DESCRIPTION = gql`
    mutation MyMutation($Texto: String) {
      insert_Templates_Descripcion(objects: {Texto: $Texto}) {
        affected_rows
        returning {
          id
        }
      }
    }
   `

const UPDATE_DESCRIPTION = gql`
    mutation MyMutation($Texto: String, $id: Int) {
      insert_Templates_Descripcion(objects: {Texto: $Texto, id: $id}, on_conflict: {constraint: Descripcion_pkey, update_columns: Texto}) {
        affected_rows
        returning {
          id
        }
      }
    }
    `

const ModalDescripcion = (props) => {
    const classes = useStyles();
    const {setOpen, open, id, setLoading, setRowDataSelected, selectedDescriptions} = props;
    const {authToken} = useContext(AuthContext);
    const {idDescripcionMaster, defaultDescripcionesId, descripciones, handleModified} = useContext(MasterContext);
    const [descriptionSelected, setDescriptionSelected] = useState(selectedDescriptions);
    const [holdDescription, setHoldDescription] = useState(descripciones[selectedDescriptions.filter(x => !defaultDescripcionesId.includes(x))[0]]);

    const [insertDescription] = useMutation(INSERT_NEW_DESCRIPTION);
    const [updateDescription] = useMutation(UPDATE_DESCRIPTION);

    const handleCardClick = (check, idDescripcion) => {
        if (!check) {
            setDescriptionSelected(prevState => [...prevState, idDescripcion])
        } else {
            setDescriptionSelected(prevState => (prevState.filter(id => id !== idDescripcion)));
        }
    }

    const saveDescripcion = () => {
        const url = process.env.REACT_APP_BACK_SERVER + `/editarTemplatePolkista`;

        const jsonData = {
            operacion: "descripcion",
            id: id,
            descripcion: descriptionSelected,
        }

        setLoading(true)
        setOpen(false)
        setRowDataSelected(null)

        //Comprobar si la descripcion personalizada cambió
        if (holdDescription !== descripciones[selectedDescriptions.filter(x => !defaultDescripcionesId.includes(x))[0]]) {
            if (idDescripcionMaster === null || idDescripcionMaster === selectedDescriptions.filter(x => !defaultDescripcionesId.includes(x))[0]) {
                //Insertar si coincide el id del master con el id recibido
                insertDescription({
                    context: {
                        headers: {
                            "X-Hasura-Role": "master"
                        }
                    },
                    variables: {
                        Texto: holdDescription
                    }
                }).then((resp) => {
                    jsonData['descripcion'] = [...jsonData['descripcion'].filter(x => defaultDescripcionesId.includes(x)), resp['data']['insert_Templates_Descripcion']['returning'][0]['id']]
                    Axios.post(url, jsonData, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${authToken}`,
                        }
                    }).then(res => {
                        handleModified()
                        setLoading(false)
                        console.log(res)
                    }).catch(err => {
                        setLoading(false)
                        console.log(err)
                    });
                })
            } else {
                //Actualizar si no coincide, lo que implica que es un idDescripcion propio del polkista
                updateDescription({
                    context: {
                        headers: {
                            "X-Hasura-Role": "master"
                        }
                    },
                    variables: {
                        Texto: holdDescription,
                        id: selectedDescriptions.filter(x => !defaultDescripcionesId.includes(x))[0]
                    }
                }).then((resp) => {
                    Axios.post(url, jsonData, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${authToken}`,
                        }
                    }).then(res => {
                        handleModified()
                        setLoading(false)
                        console.log(res)
                    }).catch(err => {
                        setLoading(false)
                        console.log(err)
                    });
                })
            }
        } else {
            Axios.post(url, jsonData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                }
            }).then(res => {
                handleModified()
                setLoading(false)
                console.log(res)
            }).catch(err => {
                setLoading(false)
                console.log(err)
            });
        }
    }

    return (
        <Modal backdrop={true} show={open} onHide={() => {
            setOpen(!open);
            setRowDataSelected(null)
        }} style={{top: '10%'}}>
            <Modal.Header/>
            <Modal.Body>
                <div className={classes.modalColumn}>
                    <p className={classes.modalTitle}>
                        Modificar la descripción del polkista
                    </p>

                    <div className={classes.modalBlock}>
                        {defaultDescripcionesId.map((option) => {
                            return (
                                // Campos de descripciones por defecto
                                <div className={classes.modalRow}>
                                    <Checkbox
                                        style={{color: "#452d7b"}}
                                        checked={descriptionSelected.includes(option)}
                                        onChange={() => handleCardClick(descriptionSelected.includes(option), option)}
                                    />
                                    <div className={classes.cardHighlight}>
                                        <p className={classes.descriptionText}>
                                            {descripciones[option]}
                                        </p>
                                    </div>
                                </div>
                            )
                        })}
                        {/*Campo de descripcion personalizada*/}
                        <div className={classes.modalRow}>
                            <Checkbox
                                style={{color: "#452d7b"}}
                                checked={descriptionSelected.includes(
                                    selectedDescriptions.filter(x => !defaultDescripcionesId.includes(x))[0]
                                )}
                                onChange={(e) => handleCardClick(!e.target.checked, selectedDescriptions.filter(x => !defaultDescripcionesId.includes(x))[0])}
                            />
                            <div className={classes.cardHighlight}>
                                <TextField
                                    value={holdDescription}
                                    fullWidth
                                    placeholder="Escriba aquí si desea agregar una nueva descripción"
                                    multiline
                                    rows={2}
                                    maxRows={4}
                                    onChange={(e) => setHoldDescription(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className={classes.modalFooter}>
                    <button className={classes.btnShare} onClick={saveDescripcion}>
                        Guardar
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

const ModalRRSS = (props) => {
    const classes = useStyles();
    const {setOpen, open, currentLinks, id, setLoading, setRowDataSelected} = props;
    const {authToken} = useContext(AuthContext);
    const {handleModified} = useContext(MasterContext);

    const saveLinks = (values) => {
        const url = process.env.REACT_APP_BACK_SERVER + `/editarTemplatePolkista`;

        const jsonData = {
            operacion: "rrss",
            id: id,
            rrss: values.links
        }

        setLoading(true)
        setOpen(false)
        setRowDataSelected(null)
        Axios.post(url, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            }
        }).then(() => {
            handleModified()
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        });
    }

    const buttonsToShow = (index, length, arrayHelpers) => {
        if (length === 1) {
            return (<Grid item xs={3}>
                <Button variant='outlined' size="small"
                        style={{padding: "0", marginTop: "16px"}}
                        onClick={() => arrayHelpers.push('')}>
                    <AddIcon/>
                </Button>
            </Grid>)
        } else if (index !== length - 1) {
            return (<Grid item xs={3}>
                <Button variant='outlined' size="small"
                        style={{padding: "0", marginTop: "16px"}}
                        onClick={() => arrayHelpers.remove(index)}>
                    <RemoveIcon/>
                </Button>
            </Grid>)
        } else if (index < 3) {
            return (
                <Grid item xs={3}>
                    <div className={classes.fieldArrayButtonsDiv}>
                        <Button variant='outlined' size="small"
                                className={classes.fieldArrayButtons}
                                onClick={() => arrayHelpers.remove(index)}>
                            <RemoveIcon/>
                        </Button>
                        <Button variant='outlined' size="small"
                                className={classes.fieldArrayButtons}
                                onClick={() => arrayHelpers.push('')}>
                            <AddIcon/>
                        </Button>
                    </div>
                </Grid>
            )
        }
    }

    function validateLink(value) {
        let error;
        if (!value) {
            error = 'Por favor, ingrese un valor';
        } else if (!/^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/.test(value)) {
            error = 'Ingrese una URL válida';
        }
        return error;
    }

    return (
        <Modal backdrop={true} show={open} onHide={() => {
            setOpen(!open);
            setRowDataSelected(null)
        }} style={{top: '10%'}}>
            <Modal.Header/>
            <Modal.Body>
                <div className={classes.modalColumn}>
                    <p className={classes.modalTitle}>
                        Modificar las redes sociales del polkista
                    </p>

                    <div className={classes.modalBlock}>
                        <Formik
                            initialValues={{links: currentLinks}}
                            onSubmit={saveLinks}
                        >
                            {({isSubmitting, values}) => (
                                <Form>
                                    <FieldArray name='links'>{(arrayHelpers) => (
                                        values.links.map((item, index) => (
                                            <Grid key={index} item container spacing={2} direction='row'
                                                  style={{alignItems: "center", justifyContent: "center"}}>
                                                <Grid item xs={index < 3 ? 9 : 12}>
                                                    <InputField
                                                        validate={validateLink}
                                                        name={`links[${index}]`}
                                                        value={item}
                                                        label={"Link"}
                                                        fullWidth
                                                        textTransform={'capitalize'}/>
                                                </Grid>
                                                {buttonsToShow(index, values.links.length, arrayHelpers)}
                                            </Grid>

                                        ))
                                    )}</FieldArray>
                                    <div className={classes.buttons}>
                                        <Button
                                            disabled={isSubmitting}
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={classes.buttonContained}
                                        >
                                            Guardar
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>

                </div>
            </Modal.Body>
        </Modal>
    )
}

const ModalResumen = (props) => {
    const classes = useStyles();
    const {setOpen, open, rowData, setRowDataSelected} = props;

    if (!rowData) return null;
    return (
        <Modal backdrop={true} show={open} onHide={() => {
            setOpen(!open);
            setRowDataSelected(null)
        }} style={{top: '10%'}}>
            <Modal.Header/>
            <Modal.Body>
                <div className={classes.modalColumn}>
                    <p className={classes.modalTitle}>
                        Resumen del canal digital
                    </p>

                    <div className={classes.modalBlock} style={{width: "90%"}}>
                        <div className={classes.modalSpacedRow}>
                            <p className={classes.modalSubtitle}>Nombre: <span
                                className={classes.modalText}>{`${rowData['DatosPolkista']['Nombre']} ${rowData['DatosPolkista']['Apellido']}`}</span>
                            </p>
                            <img className={classes.cellLogo} src={rowData['Logo']} alt="web_logo"/>
                        </div>
                        <div className={classes.modalSpacedRow}>
                            <p className={classes.modalSubtitle}>Celular: <span
                                className={classes.modalText}>{rowData['DatosPolkista']['Telefono']}</span></p>
                        </div>
                        <div className={classes.modalSpacedRow}>
                            <p className={classes.modalSubtitle}>Link: <a href={rowData['URL']}
                                                                          className={classes.modalText}>{rowData['URL']}</a>
                            </p>
                        </div>
                        <div className={classes.modalSpacedRow}>
                            <p className={classes.modalSubtitle} style={{textAlign: "start"}}>Productos Habilitados:</p>
                            <div className={classes.modalRow} style={{justifyContent: "flex-end"}}>
                                <div
                                    className={rowData['TieneAuto'] ? classes.activatedProdTiny : classes.disabledProdTiny}>
                                    <img className={classes.iconTiny} src={car_icon}/>
                                </div>
                                <div
                                    className={rowData['TieneCombinado'] ? classes.activatedProdTiny : classes.disabledProdTiny}>
                                    <img className={classes.iconTiny} src={house_icon}/>
                                </div>
                                <div
                                    className={rowData['TieneMicro'] ? classes.activatedProdTiny : classes.disabledProdTiny}>
                                    <img className={classes.iconTiny} src={microseguros_icon}/>
                                </div>
                            </div>
                        </div>
                        <div className={classes.modalSpacedRow}>
                            <p className={classes.modalSubtitle} style={{textAlign: "start"}}>Redes Sociales:</p>
                            <div className={classes.modalRow} style={{justifyContent: "flex-end"}}>
                                <SocialMediaLinks links={rowData['RedesSociales']}/>
                            </div>
                        </div>
                        <div className={classes.modalSpacedRow} style={{justifyContent: "start"}}>
                            <p className={classes.modalSubtitle}>Estado:</p>
                            {rowData['Habilitado']
                                ? <img src={tilde} className={classes.iconTiny} style={{marginLeft: "8px"}}/>
                                : <img src={cruz} className={classes.iconTiny} style={{marginLeft: "8px"}}/>}
                        </div>
                    </div>

                </div>
            </Modal.Body>
        </Modal>
    )
}

export {
    ModalURLandQR,
    ModalLogo,
    ModalMarketing,
    ModalProducts,
    ModalDescripcion,
    ModalRRSS,
    ModalResumen
}