import React, {useContext, useState} from 'react';
import './QuotationsTable.css';
import MainTable from "../../../components/MainTable/MainTable";
import {MantineInputFilter} from "../../../components/Filters/MantineInputFilter";
import moment from "moment/moment";
import {MantineSelectFilter} from "../../../components/Filters/MantineSelectFilter";
import Tabs from "../../../components/Tabs/Tabs";
import CotizarContext from "../../../context/CotizarContext";
import {ClientDetails} from "./ClientDetails";
import {DeleteAlert} from "./DeleteAlert";
import {MantineDateFilter} from "../../../components/Filters/MantineDateFilter";
import {FormCard} from "../../../components/FormCard";
import UserContext from "../../../context/UserContext";
import {QuotationsTableColumns} from "./QuotationsTableColumns";
import axios from "axios";
import AuthContext from "../../../context/AuthContext";
import {productIdentifier} from "../../../modules/utils/productIdentifier";
import DashboardContext from "../../../context/DashboardContext";
import showNotification from "../../../modules/utils/showNotification";

function QuotationsTable(props) {
    const {cotizacionesPolko, cotizacionesCD, isMobile} = props;
    const {handleModified} = useContext(DashboardContext);
    const {companiesDict} = useContext(CotizarContext);
    const {userCompaniesNames} = useContext(UserContext);
    const {authToken} = useContext(AuthContext);

    const [rows, setRows] = useState(cotizacionesPolko);
    const [filteredRows, setFilteredRows] = useState(cotizacionesPolko);

    const [clientInfo, setClientInfo] = useState(null)
    const [openClientInfo, setOpenClientInfo] = useState(false)
    const [fstTabActive, setFstTabActive] = useState(true)
    const [isOpenDeleteAlert, setIsOpenDeleteAlert] = useState(false)
    const [detailedRow, setDetailedRow] = useState(null)
    const [loading, setLoading] = useState(false)
    const allColumns = QuotationsTableColumns({setIsOpenDeleteAlert, setDetailedRow, setClientInfo, setOpenClientInfo});
    const polkoColumns = [allColumns.Companies, allColumns.Date, allColumns.Product, allColumns.Detail, allColumns.Buttons]
    const clientColumns = [allColumns.Companies, allColumns.Date, allColumns.Product, allColumns.Client, allColumns.Detail, allColumns.Buttons]
    const polkoMobileColumns = [allColumns.Date, allColumns.Detail, allColumns.MobileButtons]
    const clientMobileColumns = [allColumns.Date, allColumns.Client, allColumns.Detail, allColumns.MobileButtons]

    //FILTERS
    const [filters, setFilters] = useState({
        aseguradora: '',
        producto: '',
        fecha: [],
        searchText: ''
    });

    function filterEmisiones(filters) {
        let cotizacionesFiltradas = rows;

        if (filters.aseguradora) {
            cotizacionesFiltradas = cotizacionesFiltradas.filter(cotizacion =>
                cotizacion.companies.includes(companiesDict[filters.aseguradora]['id'])
            );
        }

        if (filters.producto) {
            if (filters.producto === "microseguros") {
                cotizacionesFiltradas = cotizacionesFiltradas.filter(cotizacion =>
                    !cotizacion.hasOwnProperty('vehiculo') && !cotizacion.hasOwnProperty('hogar')
                );
            } else {
                cotizacionesFiltradas = cotizacionesFiltradas.filter(cotizacion =>
                    cotizacion.hasOwnProperty(filters.producto)
                );
            }
        }

        if (filters.fecha) {
            if (filters.fecha[0] && filters.fecha[1]) {
                cotizacionesFiltradas = cotizacionesFiltradas.filter(cotizacion => {
                    const itemDate = moment(cotizacion.fecha);
                    const startDate = moment(filters.fecha[0]);
                    const endDate = moment(filters.fecha[1]);
                    return itemDate.isBetween(startDate, endDate, null, "[]");
                })
            } else if (filters.fecha[0] && !filters.fecha[1]) {
                cotizacionesFiltradas = cotizacionesFiltradas.filter((cotizacion) => {
                        const itemDate = moment(cotizacion.fecha);
                        const startDate = moment(filters.fecha[0]);
                        return itemDate.isAfter(startDate);
                    }
                );
            }
        }
        if (filters.searchText) {
            cotizacionesFiltradas = cotizacionesFiltradas.filter(cotizacion =>
                JSON.stringify(cotizacion).toLowerCase().includes(filters.searchText.toLowerCase())
            );
        }

        return cotizacionesFiltradas;
    }

    const handleFilterChange = (filterName, value) => {
        let valueToSet = value ? value : "";

        setFilters((prevState) => ({...prevState, [filterName]: valueToSet}));
        const filteredEmisiones = filterEmisiones({...filters, [filterName]: valueToSet});

        setFilteredRows(filteredEmisiones);
    };

    const handleDeleteQuotation = (_rowData) => {
        let producto = productIdentifier(_rowData);
        const cotizacionId = _rowData._id;

        switch (producto) {
            case "AUTOMOTOR":
                producto = "VEHICULO";
                break;
            case "ASISTENCIA AL VIAJERO":
                producto = "ASISTENCIA_VIAJERO";
                break;
            default:
                break;
        }

        const url = `${process.env.REACT_APP_BACK_SERVER}/setShow/${producto}/${cotizacionId}`;
        setLoading(true)
        return axios.put(url, {},{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            }
        ).then(res => {
            return res;
        }).catch(err => {
            throw new Error(err.toString());
        });
    }

    const deleteQuotation = (id) => {
        const rowFound = rows.find((item) => item._id === id);

        handleDeleteQuotation(rowFound)
            .then(res => {
                if (res.status === 200) {
                    handleModified()
                    const newData = rows.filter((item) => item._id !== id);
                    setRows(newData);
                    setFilteredRows(newData);
                } else {
                    throw new Error(res.data?.error?.message || "Error de comunicación con el servidor.");
                }
            })
            .catch(err => {
                showNotification({
                    autoClose: 4000,
                    color: 'red',
                    status: 'error',
                    title: 'Error al eliminar la cotización.',
                    message: err.toString() || "Sin datos del error",
                });
            })
            .finally(() => {
                setLoading(false)
                setIsOpenDeleteAlert(false);
            });
    }

    const createQuotationsTable = (quotationColumns, quotationRows) => {
        return (
            <div className="QT__width__100">
                <div className={`QT__filters__container`}>
                    <div className="QT__filter__company">
                        <MantineSelectFilter
                            value={filters.aseguradora}
                            setValue={(value) => handleFilterChange('aseguradora', value.replace(/ /g, "_"))}
                            data={userCompaniesNames}
                            clearable
                            placeholder={"Aseguradora"}
                        />
                    </div>
                    <div className="QT__filter__product">
                        <MantineSelectFilter
                            value={filters.producto}
                            setValue={(value) => handleFilterChange('producto', value)}
                            data={filters.aseguradora ? companiesDict[filters.aseguradora].products : companiesDict["Sancor"].products}
                            clearable
                            placeholder={"Producto"}
                        />
                    </div>
                    <div className="QT__filter__date">
                        <MantineDateFilter
                            placeholder={"Fecha"}
                            value={filters.fecha}
                            setValue={(value) => handleFilterChange('fecha', value)}
                            type="range"
                        />
                    </div>
                    <div className="QT__filter__search">
                        <MantineInputFilter
                            value={filters.searchText}
                            setValue={(value) => handleFilterChange('searchText', value)}
                            placeholder={"Buscar"}
                        />
                    </div>
                </div>

                <hr className="QT__hr"/>
                <div className={quotationRows.length === 0 ? "QT__table__noDataContainer" : "QT__table__container"}>
                    <MainTable columns={quotationColumns} rows={quotationRows} pagination/>
                </div>
            </div>
        )
    }

    const createMobileQuotationsTable = (quotationColumns, quotationRows) => {
        return (
            <div className="QT__width__100">
                <div className={`QT__filter__searchMobile`}>
                    <MantineInputFilter
                        value={filters.searchText}
                        setValue={(value) => handleFilterChange('searchText', value)}
                        placeholder={"Buscar"}
                    />
                </div>

                <hr className="QT__hr"/>
                <div className={quotationRows.length === 0 ? "QT__table__noDataContainer" : "QT__table__container"}>
                    <div style={{width: "100%"}}>
                        <MainTable columns={quotationColumns} rows={quotationRows} pagination/>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="QT__componentContainer">
            <p className="DStatistics__title">Últimas cotizaciones</p>
            <FormCard>
                <div className="QT__container">
                    <Tabs tabs={[
                        <span onClick={() => {
                            setRows(cotizacionesPolko)
                            setFilteredRows(cotizacionesPolko)
                            setFstTabActive(true)
                        }}
                              className={fstTabActive ? "QT__tab QT__tab__active" : "QT__tab"}>POLKO</span>,
                        <span onClick={() => {
                            setRows(cotizacionesCD)
                            setFilteredRows(cotizacionesCD)
                            setFstTabActive(false)
                        }}
                              className={fstTabActive ? "QT__tab" : "QT__tab QT__tab__active"}>CLIENTES</span>
                    ]}
                          tabContent={isMobile ?
                              [
                                  createMobileQuotationsTable(polkoMobileColumns, filteredRows),
                                  createMobileQuotationsTable(clientMobileColumns, filteredRows)
                              ]
                              :
                              [
                                  createQuotationsTable(polkoColumns, filteredRows),
                                  createQuotationsTable(clientColumns, filteredRows)
                              ]}
                    />

                    {openClientInfo &&
                        <ClientDetails name={clientInfo.nombre} lastName={clientInfo.apellido} email={clientInfo.email}
                                       phone={clientInfo.telefono} setClientInfo={setClientInfo}
                                       setOpenClientInfo={setOpenClientInfo} quotationDetail={detailedRow}/>}
                    {isOpenDeleteAlert &&
                        <DeleteAlert description={detailedRow.description} date={detailedRow.date}
                                     fullName={detailedRow.clientName} loading={loading}
                                     idQuotation={detailedRow.idQuotation} setIsOpenDeleteAlert={setIsOpenDeleteAlert}
                                     deleteQuotation={deleteQuotation}/>}
                </div>
            </FormCard>
        </div>

    )
}

export default QuotationsTable;