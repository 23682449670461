import React from "react";
import {Route, useRouteMatch} from "react-router-dom";
import {compose} from "recompose";
import {withUserContextProvider, withUserContextReady} from "../context/UserContext";
import PolkistasRoutes from "./PolkistasRoutes";
import MasterRoute from "../modules/components/MasterRoute";
import MasterRoutes from "./MasterRoutes";
import {MainDrawer} from "../components/MainDrawer";
import {Navigation} from "../layouts/Navigation";

const UserRoutes = () => {
    const {path} = useRouteMatch();

    return (
        <>
            <Navigation>
                <MainDrawer/>
                <Route path={`${path}`} component={PolkistasRoutes}/>
                <MasterRoute path={`${path}/m`} component={MasterRoutes}/>
            </Navigation>
        </>
    );
};

export default compose(
    withUserContextProvider,
    withUserContextReady,
)(UserRoutes);