import React from 'react';
import './CoverageTableHeader.css';
import {currencyFormat} from "../../modules/utils/currencyFormat";

function CoverageTableHeader(props) {
    const {companyName, companyLogo, coverage, premium} = props;
    return (
        <div className="ctheader__container">
            <div className="ctheader__logo__container">
                <img className="ctheader__logo" src={companyLogo} alt={companyName}/>
            </div>
            <div className="ctheader__text__container">
                <p className="ctheader__text">{coverage}</p>
            </div>
            <p className="ctheader__text--highlighted">${currencyFormat(premium)}</p>
        </div>
    );
}

export default CoverageTableHeader;