import React from 'react';

const MasterContext = React.createContext({

    defaultDescripcionesId: null,
    masterPolkistas: null,
    infoPagesPolkistas:null,
    descripciones:null,
    modified: null,
    isModifiedReady: null,
    handleModified: null,
    socialMediaDict: null,
    companiesDict: null,
    codesToShareOptions: null,
    idPolkistaMaster: null,
    emissionStatistics: null,
    polkistasStatistics: null,
    generalStatistics: null,
    availableCredits: null,
    open: false,
    altaCompaniaTickets: null,
    setAltaCompaniaTickets: null,
});

export default MasterContext;