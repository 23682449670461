import {Button} from "../../../../components/Button";
import {sendWhatsapp} from "../../../../modules/utils/sendWhatsapp";
import {ReactComponent as TrashIcon} from "../../../../assets/icons/ic_trash.svg";
import {ReactComponent as WsspIcon} from "../../../../assets/icons/ic_whatsapp.svg";
import moment from "moment";
import React from "react";
import useWindowDimensions from "../../../../modules/utils/useWindowDimensions";
import "./QTableButtons.css"
import {useHistory} from "react-router-dom";

function QTableButtons(props) {
    const {valuesRow, setIsOpenDeleteAlert, setDetailedRow, client, product, detail} = props;
    const {width} = useWindowDimensions();
    const history = useHistory();
    const pathProduct = product === "ASISTENCIA AL VIAJERO" ? "asistencia_viajero" : product.toLowerCase();

    const handleTrashButton = (idQuotation, description, clientName, date) => {
        setIsOpenDeleteAlert(true)
        setDetailedRow({idQuotation, description, clientName, date})
    }

    return (
        <div className="QT__row__buttons">
            <Button
                type="button"
                variant="filled"
                color="primary"
                enabled
                size={width <= 1025 ? "sm" : "md"}
                marginX="xs"
                onClick={() => {
                    history.push({
                        pathname: `/u/cotizar/${pathProduct}`,
                        state: {
                            rowData: valuesRow,
                            action: "emitir"
                        }
                    });
                }}
            >
                Emitir
            </Button>
            <Button
                type="button"
                variant="filled"
                color="primary"
                enabled
                size={width <= 1025 ? "sm" : "md"}
                marginX="xs"
                onClick={() => {
                    history.push({
                        pathname: `/u/cotizar/${pathProduct}`,
                        state: {
                            rowData: valuesRow,
                            action: "recotizar"
                        }
                    });
                }}
            >
                Recotizar
            </Button>
            {client && <a className="QTable__wsspContainer" onClick={() =>
                sendWhatsapp('https://wa.me/' + valuesRow['clientes'][0]['telefono'] + '?text=¡Buenos%20días,%20' + valuesRow['clientes'][0]['nombre'] + '!%0aMe%20pongo%20en%20contacto%20con%20usted%20para%20consultarle%20sobre%20la%20cotización%20de%20su%20' + product.toLowerCase() + '%20' + detail + '%20que%20realizó%20el%20día%20%20' + '%20' + moment(valuesRow['fecha']).format('DD/MM/YYYY') + '.')
            }><WsspIcon className="QT__wssp__icon"/></a>}
            <div className="QTable__trashContainer"
                 onClick={() => {
                     if (client) {
                         handleTrashButton(valuesRow['_id'], detail, valuesRow['clientes'][0]['nombre'] + " " + valuesRow['clientes'][0]['apellido'], valuesRow['fecha'])
                     } else {
                         handleTrashButton(valuesRow['_id'], detail, "", valuesRow['fecha'])
                     }
                 }}>
                <TrashIcon className="QT__trash__icon"/>
            </div>
        </div>
    );
}

export default QTableButtons;