import React from 'react';
import {Typography} from "@material-ui/core";

function SlantedField(props) {
    return (
        <>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <Typography style={{
                    fontFamily: 'Work Sans, sans-serif',
                    fontWeight: 400,
                    fontSize: '12px',
                    color: 'rgba(0, 0, 0, 0.54)'

                }}>{props.label}</Typography>
                <div style={{
                    marginTop: '4px',
                    padding: '4px 8px',
                    border: 'solid 1px #c5c5c5',
                    width: '100%',
                    borderRadius: '4px',
                    backgroundColor: '#d5d5d5',
                    boxShadow: '0 2px 3px 0 rgba(56,56,56,0.15)'
                }}>
                    <Typography style={{
                        // fontFamily:'Nunito',
                        color: '#808080',
                        letterSpacing: '-0.5px',
                        fontSize: '14px'
                    }}>{props.value}</Typography>
                </div>

            </div>
        </>
    );
}

export default SlantedField;
