import { notifications } from '@mantine/notifications';
import { IconCircleCheck, IconAlertCircle, IconCircleX } from '@tabler/icons';

function updateNotification({
                                id,
                                color = 'red',
                                status = null,
                                title,
                                message,
                                icon = null,
                                autoClose = 5000,
                                style = {},
                                loading = false,
                                notificationMaxHeight = 300,
                            }) {
    const notificationProps = {
        id,
        color,
        style,
        loading,
        title,
        message,
        autoClose,
        notificationMaxHeight,
    };

    if (icon !== null) {
        notificationProps.icon = icon;
    } else if (status !== null) {
        switch (status) {
            case 'success':
                notificationProps.icon = (
                    <IconCircleCheck style={{ width: '24px', height: '24px', stroke: 'white' }} />
                );
                break;
            case 'warning':
                notificationProps.icon = (
                    <IconAlertCircle style={{ width: '24px', height: '24px', stroke: 'white' }} />
                );
                break;
            case 'error':
                notificationProps.icon = (
                    <IconCircleX style={{ width: '24px', height: '24px', stroke: 'white' }} />
                );
                break;
        }
    }

    notifications.update(notificationProps);
}

export default updateNotification;
