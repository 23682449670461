import React, {useEffect, useState} from 'react';
import {Select} from '@mantine/core';
import {useField} from "formik";
import './MantineSelectField.css';
import {ReactComponent as ExclamationIcon} from "../../../assets/icons/ic_exclamation.svg";
import {Loading} from "../../Loading";

function MantineSelectField(props) {
    const [field, meta, helpers] = useField(props.name);
    const [value, setValue] = useState(field.value);
    const {setValue: setFieldValue, setTouched} = helpers;

    useEffect(() => {
        setValue(field.value);
    }, [field.value]);

    return (
        <Select
            {...props}
            value={value}
            maxDropdownHeight={200}
            nothingFound={props.loadingData ? <Loading/> : "Sin resultados"}
            onChange={(v) => {
                setValue(v)
                setFieldValue(v)
                setTouched(props.name, false)
                if (props.onChangeAction) {
                    props.onChangeAction(v)
                }
            }}
            error={meta.error && meta.touched && <div>{meta.error}</div>}
            classNames={{
                wrapper: 'mantine__select__wrapper',
                invalid: 'mantine__select__wrapper',
                label: meta.error && meta.touched ? 'mantine__select__label--error' : 'mantine__select__label',
                input: meta.error && meta.touched ? 'mantine__select__field--error' : 'mantine__select__field',
                required: meta.error && meta.touched ? 'mantine__select__required--error' : 'mantine__select__required',
                error: 'mantine__select__error',
                item: 'mantine__select__item',
                rightSection: 'mantine__select__rightSection',
                dropdown: props.notShowData && 'mantine__select__dropdownNotVisible',
            }}
            rightSection={
                meta.error && meta.touched && <ExclamationIcon className="mantine__select__icon"/>
            }
        />
    );
}

export default MantineSelectField;