import {MainTable} from "../../../../components/MainTable";
import React from "react";

const PolkistaDetailsBody = ({commissionColumns, polizasEmisiones}) => {
    return (
        <div className="comDetModal__body__table">
            <MainTable columns={commissionColumns} rows={polizasEmisiones}/>
        </div>
    )
}

export default PolkistaDetailsBody;