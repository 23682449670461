import React, {useContext, useReducer, useState} from 'react';
import {ReactComponent as BackIcon} from "../../../../assets/icons/ic_back-button.svg";
import './Mchoice.css'
import Progress from "./Progress/Progress";
import Question from "./Question/Question";
import Answers from "./Answers/Answers";
import QuizContext from "../context/QuizContext";
import {
    SET_ANSWERS,
    SET_CURRENT_QUESTION,
    SET_CURRENT_ANSWER,
    SET_SHOW_RESULTS,
    RESET_QUIZ,
    SET_QUIZ_STATE,
} from '../reducers/types.js';
import quizReducer from "../reducers/QuizReducer";
import {Button} from "../../../../components/Button";
import {useHistory} from "react-router-dom";
import MChoiceResult from "./MChoiceResult/MChoiceResult";
import userContext from "../../../../context/UserContext";
import {certificado} from "./Utils/certificado"
import {enviarPuntaje} from "./Utils/enviarPuntaje"
import AuthContext from "../../../../context/AuthContext";


function MChoice(props) {
    const [count, setCount] = useState(0);
    const [porcentaje, setPorcentaje] = useState(0);
    const [aprobo, setAprobo] = useState(null);
    const [openResults, setOpenResults] = useState(false);
    const {authToken} = useContext(AuthContext)
    const history = useHistory();

    const questions = props.location.state.questions;
    const id = props.location.state.id_Curso;
    const {userPicture, userName, userLastName} = useContext(userContext);

    const initialState = {
        setCount: 0,
        currentQuestion: 0,
        questions,
        currentAnswer: '',
        answers: [],
        showResults: false,
        error: '',
        quizState: '',

    };

    const [state, dispatch] = useReducer(quizReducer, initialState);
    const {currentQuestion, currentAnswer, answers, showResults} = state;
    const question = questions[currentQuestion];
    const [downloading, setDownloading] = useState(false);
    const [error, setError] = useState(null);


    const restart = () => {
        setCount(0);
        // Reset values
        dispatch({type: RESET_QUIZ});
        setOpenResults(false);
    };

    const next = () => {
        const answer = {questionId: question.id, answer: currentAnswer};
        if (question.correct_answer === answer.answer) {
            setCount(count + 1);
        }

        answers.push(answer);
        // Update answer array with the new user answer
        dispatch({type: SET_ANSWERS, answers});
        // Reset current answer
        dispatch({type: SET_CURRENT_ANSWER, currentAnswer: ''});

        if (currentQuestion + 1 < questions.length) {
            // Set current question to show
            dispatch({type: SET_CURRENT_QUESTION, currentQuestion: currentQuestion + 1});
            return;
        }
        // When are all questions answered, show results.
        dispatch({type: SET_SHOW_RESULTS, showResults: true});

    }


    const handleStateChange = async () => {
        // Update quiz state.
        dispatch({type: SET_QUIZ_STATE, quizState: 'Aprobado'});
    }

    function handlerBack() {
        history.push({
            pathname: "/u/academia"
        });
    }

    if (showResults && openResults === false) {

        let porcentajeActual = 0;
        let aprueba = false;

        porcentajeActual = Math.floor((count * 100) / questions.length);
        if (porcentajeActual >= 50) {
            aprueba = true;
            enviarPuntaje(id, authToken);

        }
        setPorcentaje(porcentajeActual)
        setAprobo(aprueba)
        setOpenResults(true)

    }
    const plataforma = props.location.state.Plataforma;
    const titulo = props.location.state.Titulo;
    const modulo = props.location.state.Modulo;


    return (
        <QuizContext.Provider value={{state, dispatch}}>
            <div className="container">
                <BackIcon className="MChoice__backIcon" onClick={() => {
                    handlerBack()
                }}/>
                <Progress total={questions.length} current={currentQuestion + 1}/>
                <Question/>
                <div className="AAnswers__container">
                    <Answers/>
                </div>
                <Button variant="filled" enabled={currentAnswer} color="primary" onClick={next}>
                    Continuar
                </Button>

                {openResults &&
                    <div>
                        {aprobo ?
                            <MChoiceResult title="¡Felicitaciones, aprobaste!" bodyItems={[{
                                name: <span className="MChoice__resultSubtitle">Cantidad de aciertos</span>,
                                value: <span className="MChoice__resultPositive">{porcentaje}%</span>
                            }, {
                                name: <span className="MChoice__resultSubtitle">Cantidad de desaciertos</span>,
                                value: <span className="MChoice__resultNegative">{100 - porcentaje}%</span>
                            }]} footer={<div>
                                <Button enabled loading={downloading} variant="filled" color="primary"
                                                onClick={() => certificado(plataforma, titulo, modulo, userName, userLastName, userPicture, setDownloading, setError)}>
                                    Descargar mi certificado
                                </Button>
                                <p className="MChoice__error">{error && error.title}</p>
                            </div>} closeAction={() => {
                                handlerBack()
                                handleStateChange()
                            }} success/>
                            :
                            <MChoiceResult title="¡Uy! Desaprobaste." bodyItems={[{
                                name: <span className="MChoice__resultSubtitle">Cantidad de aciertos</span>,
                                value: <span className="MChoice__resultPositive">{porcentaje}%</span>
                            }, {
                                name: <span className="MChoice__resultSubtitle">Cantidad de desaciertos</span>,
                                value: <span className="MChoice__resultNegative">{100 - porcentaje}%</span>
                            }]} footer={<Button enabled variant="filled" color="primary" onClick={restart}>Comenzar
                                de nuevo</Button>} closeAction={handlerBack}/>}
                    </div>}
            </div>
        </QuizContext.Provider>

    )

}

export default MChoice;
