import React from 'react';
import './ShareCodeBarModal.css';
import {CustomModal} from "../../../components/CustomModal";
import {PopUpCard} from "../../../components/PopUpCard";
import {ReactComponent as CloseIcon} from "../../../assets/icons/ic_close.svg";
import {ReactComponent as PeopleTalkingImage} from "../../../assets/images/masterPage/peopleTalking.svg";
import {NumberedList} from "../../../components/NumberedList";

function ShareCodeBarModal({onClose}) {
    const listItems = ['Seleccioná los códigos con los que quieras que el nuevo polkista pueda operar.', <span>Presiona el botón <span className="shareCodeBar__highlightText">COPIAR</span> para obtener el link de invitación en portapapales.</span>, 'Comparte el link con la persona que quieres invitar.', 'Una vez registrado, tu invitado ya podrá operar en Polko con la configuración que le hayas seleccionado.']
    return (
        <CustomModal>
            <PopUpCard>
                <div className="shareCodeBarModal__container">
                    <div className="shareCodeBarModal__icon__container" onClick={onClose}>
                        <CloseIcon className="shareCodeBarModal__icon"/>
                    </div>
                    <PeopleTalkingImage className="shareCodeBarModal__image"/>
                    <p className="shareCodeBarModal__title">¡Invitá a polkistas a vincularse a Polko!</p>
                    <div>
                        <NumberedList items={listItems} color="primary"/>
                    </div>
                    <p className="shareCodeBarModal__footerText">¡Siempre podrás darle seguimiento desde tu listado de
                        Polkistas!</p>
                </div>
            </PopUpCard>
        </CustomModal>
    );
}

export default ShareCodeBarModal;