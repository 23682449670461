import React, {useEffect, useState} from 'react';
import {useField, useFormikContext} from "formik";
import {Input} from "@mantine/core";
import './MantineDependantField.css';
import {ReactComponent as ExclamationIcon} from "../../../assets/icons/ic_exclamation.svg";

function MantineDependantField(props) {
    const {dependantFieldName, dependantFieldLabel, dependencies, calculateValueFunction, formatDisplay} = props;
    const [field, meta] = useField(dependantFieldName);


    const {setFieldValue, setFieldTouched} = useFormikContext();
    const [value, setValue] = useState(field.value);
    const [hasBeenTouched, setHasBeenTouched] = useState(false);

    useEffect(() => {
        let condition = dependencies.every(dependency => dependency && dependency !== "")
        if (condition) {
            setFieldValue(dependantFieldName, calculateValueFunction());
            setFieldTouched(dependantFieldName, true);
            setValue(calculateValueFunction())
        }
    }, [...dependencies]);

    return (
        <Input.Wrapper
            {...props}
            id={`wrapper-${dependantFieldName}`}
            label={dependantFieldLabel}
            error={meta.error && hasBeenTouched && <div>{meta.error}</div>}
            classNames={{
                wrapper: 'mantine__dependant__wrapper',
                invalid: 'mantine__dependant__wrapper',
                label: meta.error && meta.touched ? 'mantine__dependant__label--error' : 'mantine__dependant__label',
                required: meta.error && meta.touched ? 'mantine__dependant__required--error' : 'mantine__dependant__required',
                error: 'mantine__dependant__error',
            }}
        >
            <Input
                {...props}
                id={dependantFieldName}
                value={formatDisplay(value)}
                onBlur={() => {
                    setFieldValue(dependantFieldName, value)

                    setHasBeenTouched(true)
                    //Used in replacement of:
                    //setTouched(dependantField.name, false)
                }}
                onChange={(e) => setValue(e.target.value)}
                classNames={{
                    input: meta.error && meta.touched ? 'mantine__dependant__field--error' : 'mantine__dependant__field',
                }}
                rightSection={
                    meta.error && meta.touched && <ExclamationIcon className="mantine__dependant__icon"/>
                }
            />
        </Input.Wrapper>
    );
}

export default MantineDependantField;
