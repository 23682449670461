import React, {useRef, useState} from 'react';
import {useFormikContext} from "formik";
import "./LogoForm.css";
import {CropImage} from "../../../Utils/CropImage";
import {ImagesGrid} from "../../../Utils/ImagesGrid";


export default function LogoForm(props) {
    const {
        formField: {
            logo
        },
    } = props;
    const {values, setFieldValue} = useFormikContext();
    const imgRef = useRef(null);
    const [srcFile, setSrcFile] = useState(null);
    const [srcImage, setSrcImage] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [crop, setCrop] = useState({unit: '%', aspect: 16/9});
    // Image format to show in the frontend
    const [croppedImageUrl, setCroppedImageUrl] = useState(null);

    const handleUpload = async (base64) => {
        setFieldValue(logo.name, base64);
        setOpenDialog(false);
        setCrop({
            "unit": "px",
            "x": 0,
            "y": 0,
            "aspect": 1,
            "width": 0,
            "height": 0
        });
    };

    return (
        <div>
            <ImagesGrid setSrcImage={setSrcImage} setOpenDialog={setOpenDialog} setSrcFile={setSrcFile}
                        croppedImageUrl={croppedImageUrl} initialUrlImage={values.logo}/>
            {openDialog &&
                <CropImage srcImage={srcImage} crop={crop} setCrop={setCrop}
                           setOpenDialog={setOpenDialog} handleUpload={handleUpload} imgRef={imgRef}
                           setCroppedImageUrl={setCroppedImageUrl} srcFile={srcFile}/>
            }
        </div>
    );
};