import React, {useContext} from 'react';
import './MasterDashboard.css';
import {ShareCodeBar} from "../../features/MasterDashboard/ShareCodeBar";
import {MasterDashboardTable} from "../../features/MasterDashboard/MasterDashboardTable";
import {MasterDashboardStatistics} from "../../features/MasterDashboard/MasterDashboardStatistics";
import MasterContext from "../../context/MasterContext";

function MasterDashboard() {
    const {emissionStatistics, polkistasStatistics, generalStatistics} = useContext(MasterContext);
    const generalStatisticsData = [
        {
            "title": "Cotizaciones totales",
            "value": generalStatistics['cantidad_cotizaciones']
        },
        {
            "title": "Emisiones totales",
            "value": generalStatistics['cantidad_emisiones']
        },
        {
            "title": "Conversión",
            "value": generalStatistics['porcentaje_conversion']
        },
        {
            "title": "Cuota promedio",
            "value": `$ ${parseFloat(generalStatistics['premium']).toFixed(2)}`
        },
        {
            "title": "Cantidad de polkistas",
            "value": generalStatistics['cantidad_polkistas']
        }
    ]
    return (
        <div className="masterDashboard__wrapper">
            <div className="masterDashboard__container">
                <ShareCodeBar/>

                <div className="masterDashboard__statistics">
                    <MasterDashboardStatistics
                        data={generalStatisticsData}
                    />
                </div>

                <div className="masterDashboard__table">
                    <MasterDashboardTable
                        emissionStatistics={emissionStatistics}
                        polkistasStatistics={polkistasStatistics}
                    />
                </div>
            </div>
        </div>
    );
}

export default MasterDashboard;