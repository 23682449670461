import {PopOverInfo} from "../../../../../../../components/PopOverInfo";
import {Grid, Group} from "@mantine/core";
import {Dropzone, MIME_TYPES} from "@mantine/dropzone";
import {saveFileContent} from "../../../../../../../modules/utils/saveFileContent";
import {IconPhoto, IconUpload, IconX} from "@tabler/icons";
import {FieldArray, useFormikContext} from "formik";
import DropzoneFileNoLabel from "../../../../../../../components/DropzoneFileNoLabel/DropzoneFileNoLabel";
import React, {useRef} from "react";
import normalizeString from "../../../../../../utils/normalizeString";

function RenderDropzone(props) {
    const {coverageGroup, fieldName, error, setError, setOpenPhotoHelp} = props
    const {values, setFieldValue} = useFormikContext();
    const currentWeight = useRef(0);
    return (
        values && <div className="hogarCot__dropzone__container">
            <div>
                <div className="hogarCot__dropzone__header">
                    <p className="hogarCot__dropzone__title" style={{marginRight: "8px"}}>Archivos</p>
                    {coverageGroup === "bicicletas" &&
                        <PopOverInfo descripcion={"Ayuda"} onClick={() => setOpenPhotoHelp(true)}/>}
                </div>
                <Grid gutter="xs" justify="center" align="center">
                    <Grid.Col xs={12}>
                        <Dropzone
                            className={values[fieldName].length >= 6 && "disabled"}
                            onDrop={(files) => {
                                let json = []
                                let len = values[fieldName].length
                                setError(null)
                                files.map(async function (file, index) {
                                    const filename = file.name.split(/[.]+/)[0];
                                    currentWeight.current += file.size;
                                    if (len < 10 && currentWeight.current < 10485760) {
                                        let extension = file.name.split(/[.]+/).pop().toUpperCase();
                                        if (extension !== 'JFIF') {
                                            if (filename.length === 0) {
                                                setError('El nombre del archivo debe tener entre al menos 1 caracter.')
                                                currentWeight.current -= file.size
                                            } else {
                                                const fileData = file.slice();
                                                const normalizedFilename = normalizeString(filename, null, true, "_").substring(0, 24);
                                                const normalizedFilePath = `${normalizedFilename}.${extension}`;

                                                json.push(                                                    {
                                                    "name": normalizedFilePath,
                                                    "extension": file.type.split("/")[1],
                                                    "content": "",
                                                    "file": new File([fileData], normalizedFilePath, {
                                                        path: normalizedFilePath,
                                                        size: fileData.size,
                                                        type: file.type,
                                                    }),
                                                    "type": null,
                                                    "coverageGroup": coverageGroup
                                                })

                                                saveFileContent(file).then((fileContent) => {
                                                    json[index].content = fileContent;
                                                });
                                            }
                                        } else {
                                            setError('El formato .JFIF no es válido.')
                                            currentWeight.current -= file.size
                                        }
                                    } else {
                                        setError('El tamaño total supera los 10MB.')
                                        currentWeight.current -= file.size
                                    }
                                    len += 1
                                })
                                setFieldValue(fieldName, values[fieldName].concat(json), false);
                            }}
                            onReject={() => console.log("El archivo no pudo ser cargado")}
                            maxSize={3 * 1024 ** 2}
                            maxFiles={10}
                            accept={[MIME_TYPES.png, MIME_TYPES.jpeg, MIME_TYPES.pdf]}
                            disabled={values[fieldName].length >= 10}
                        >
                            <Group position="center" spacing="xl" className="autem__inspeccion__dropzone">
                                <Dropzone.Accept>
                                    <IconUpload
                                        className="autem__inspeccion__dropzone__icon"
                                        stroke={1.5}
                                    />
                                </Dropzone.Accept>
                                <Dropzone.Reject>
                                    <IconX
                                        className="autem__inspeccion__dropzone__icon"
                                        stroke={1.5}
                                    />
                                </Dropzone.Reject>
                                <Dropzone.Idle>
                                    <IconPhoto
                                        className="autem__inspeccion__dropzone__icon"
                                        stroke={1.5}
                                    />
                                </Dropzone.Idle>

                                <div>
                                    <p className="autem__inspeccion__dropzone__title">
                                        Arrastre o haga clic para seleccionar los archivos
                                    </p>
                                    <p className="autem__inspeccion__dropzone__text">
                                        Puede adjuntar un máximo de 10 archivos (imágenes o pdf), cada archivo no
                                        debe superar los 5mb y en total no pueden exceder los 10mb.
                                    </p>
                                </div>
                            </Group>
                        </Dropzone>
                        {error &&
                            <div className="autem__inspeccion__dropzone__error__container">
                                <p className="autem__inspeccion__dropzone__error">{error}</p>
                            </div>
                        }
                    </Grid.Col>
                </Grid>
            </div>
            <div className="hogarCot__dropzoneFiles__container">
                <FieldArray name={fieldName}>{({remove}) => (
                    values[fieldName].map((file, index) => {
                            return <DropzoneFileNoLabel
                                file={file}
                                index={index}
                                withLabelSelection={false}
                                onDelete={() => {
                                    remove(index)
                                    currentWeight.current -= file.file.size
                                }}
                            />
                        }
                    ))}</FieldArray>
            </div>
        </div>
    )
}

export default RenderDropzone;