import React, {useEffect, useState} from 'react';
import {VictoryPie, VictoryLabel} from "victory";
import "./Chart.css"

const CustomLabel = () => null; // Componente que no muestra nada


export default function Chart(props) {
    const {active, data, setTarget, target} = props;
    const [graphicData, setGraphicData] = useState(null);
    const [totalQuantity, setTotalQuantity] = useState(1);
    const [selectedSliceIndex, setSelectedSliceIndex] = useState(null);
    const COLORS = ['#8200FF', '#2F0066', '#5B02C1', '#915AEB'];
    const formatData = () => {
        let cantidadTotal = 0;
        const datos = [];
        Object.entries(data[active.product][active.company]).forEach((item) => {
            cantidadTotal += item[1].cantidad;
            datos.push({
                x: item[0], y: item[1].cantidad
            })
        });
        setGraphicData(datos)
        setTotalQuantity(cantidadTotal);
        const initTarget = Object.entries(data[active.product][active.company])[0];
        setTarget(data[active.product][active.company][initTarget])
        setSelectedSliceIndex(0)
    }

    const handleSliceClick = (event, newData) => {
        setTarget(Object.entries(data[active.product][active.company])[newData.index])
        setSelectedSliceIndex(newData.index)
    };


    useEffect(() => {
        if (data !== null) {
            formatData()
        }
        setTarget(Object.entries(data[active.product][active.company])[0])
    }, [active.product, active.company, data])

    if (!data) return null;
    return (
        <React.Fragment>
            {data[active.product][active.company] ?
                <div className="Chart__container">
                    <svg viewBox="0 0 400 400" width="100%" height="100%">
                        <VictoryPie
                            standalone={false}
                            data={graphicData}
                            events={[{target: "data", eventHandlers: {onClick: handleSliceClick}}]}
                            innerRadius={(data) => data.index === selectedSliceIndex ? 115 : 122}
                            style={{data: {cursor: "pointer",}}}
                            colorScale={COLORS}
                            labelComponent={<CustomLabel/>}
                        />
                        <VictoryLabel
                            textAnchor="middle"
                            style={{fontSize: "48px", fontWeight: "600", lineHeight: "52px", fill: "var(--primary)"}}
                            x={200} y={200}
                            text={`${target ? Math.round((target[1].cantidad * 100) / totalQuantity) : (Object.entries(data[active.product][active.company])[0][1].cantidad * 100) / totalQuantity}%`}
                        />
                    </svg>
                </div> : <p className="Chart__withoutData">No hay datos disponibles</p>}
        </React.Fragment>
    );
}