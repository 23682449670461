import axios from "axios";
import showNotification from "../../../modules/utils/showNotification";

const saveConfiguration = async (jsonData, authToken, isEdit) => {
    let url = process.env.REACT_APP_BACK_SERVER + '/newinsertCanalDigital';

    return axios.post(url, jsonData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        }
    }).then(() => {
        showNotification({
            autoClose: 3000,
            color: 'green',
            status: 'success',
            title: isEdit ? 'Edición exitosa' : 'Creación exitosa.',
            message: `El canal digital se ha ${isEdit ? 'editado' : 'creado'} correctamente.`,
        });
    }).catch((err) => {
        throw err.response;
    });
}

export {saveConfiguration}