import React from 'react';
import {Select} from '@mantine/core';
import './DropdownSearchField.css';

function DropdownSearchField(props) {
    const {value, setValue, data, clearable = true} = props;

    return (
        <Select
            {...props}
            data={data}
            value={value}
            onChange={(v) => {
                setValue(v)
            }}
            clearable={clearable}
            nothingFound="Sin resultados"
            classNames={{
                wrapper: 'mantine__DropdownSearchField__wrapper',
                input: 'mantine__DropdownSearchField__field',
                item: 'mantine__DropdownSearchField__item',
                itemsWrapper: 'mantine__DropdownSearchField__itemsWrapper',
                separatorLabel: 'mantine__DropdownSearchField__separatorLabel'
            }}
        />
    );
}

export default DropdownSearchField;