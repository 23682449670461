import React, {forwardRef} from 'react';
import './FilterCard.css';

const FilterCard = forwardRef((props, ref) => {
    const {children} = props;
    return (
        <div className="filterCard__container" ref={ref}>
            {children}
        </div>
    );
});

export default FilterCard;
