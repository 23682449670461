import Select, {components} from 'react-select';
import {useEffect, useState} from "react";
import {ReactComponent as SearchIcon} from "../../assets/icons/ic_search.svg";
import "./MantineMultiSelect.css"

const customStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: 'transparent',
    }),
    valueContainer: (provided) => ({
        ...provided,
        overflow: 'auto',
        height: 'auto',
        flexWrap: 'nowrap',
    }),
};


const MantineMultiSelect = ({
                                data,
                                label,
                                placeholder,
                                maxSelectedValues,
                                onChange,
                                disabled,
                                value,
                                action
                            }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        setSelectedOptions(value.label);
    }, [value]);

    const handleChange = (selectedValues) => {
        if (selectedValues.length <= maxSelectedValues) {
            setSelectedOptions(selectedValues);
            onChange(selectedValues);
        }
    };

    const MultiValue = (props) => {
        const { data, selectProps } = props;
        const { value } = selectProps;

        const currentIndex = value.findIndex((item) => item.value === data.value);

        if (currentIndex < maxSelectedValues) {
            return <components.MultiValue {...props} />;
        } else {
            return null;
        }
    };

    const CustomValueContainer = ({ children, ...props }) => {
        return (
            <components.ValueContainer {...props}>
                {children}
            </components.ValueContainer>
        );
    };

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props} >
                {/*Rara esa funcion onTouchStart pero funciona, el problema era que no funcionaba con onClick*/}
                <div onTouchStart={() => value.length === maxSelectedValues ? action(value) : null}>
                    <SearchIcon className={value.length === maxSelectedValues ? "MMSelect__searchIcon--active" : "MMSelect__searchIcon"} /> {/* Reemplaza esto con tu ícono personalizado */}
                </div>
            </components.DropdownIndicator>
        );
    };

    const Menu = props => {
        const { menuIsOpen } = props.innerProps;

        if (value.length >= maxSelectedValues && menuIsOpen) {
            props.innerProps.onMouseLeave(); // Cerrar el menú de opciones si está abierto
        }

        return (
            <components.Menu {...props} className={value.length === maxSelectedValues ? "MMSelect__menu--invisible" : ""} />
        )
    }


    return (
        <div>
            {label && <label>{label}</label>}
            <Select
                styles={customStyles}
                options={data}
                noOptionsMessage={() => "No se encontraron resultados"}
                onChange={handleChange}
                isLoading={disabled}
                placeholder={placeholder}
                value={selectedOptions}
                isMulti
                isClearable
                isSearchable
                components={{ ValueContainer: CustomValueContainer, MultiValue, DropdownIndicator: DropdownIndicator, Menu }}
            />
        </div>
    );
};

export default MantineMultiSelect;