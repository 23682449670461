import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {Button} from "../../../components/Button";
import "./AcademyCourse.css"
import PDFViewer from "./PDFViewer/PDFViewer";
import {ReactComponent as StatusIcon} from "../../../assets/icons/ic_book.svg";
import {ReactComponent as DurationIcon} from "../../../assets/icons/ic_time_clock.svg";
import {ReactComponent as LevelIcon} from "../../../assets/icons/ic_level.svg";
import {ReactComponent as BackIcon} from "../../../assets/icons/ic_back-button.svg";
import axios from "axios";
import AuthContext from "../../../context/AuthContext";
import userContext from "../../../context/UserContext";
import {certificado} from "../Multiplechoice/MChoice/Utils/certificado";
import {enviarPuntaje} from "../Multiplechoice/MChoice/Utils/enviarPuntaje";


function AcademyCourse(props) {
    const history = useHistory();
    const courseInfo = props.location.state.curso;
    const {authToken} = useContext(AuthContext);
    const [questions, setQuestions] = useState([])
    const {userPicture, userName, userLastName} = useContext(userContext);
    const [downloading, setDownloading] = useState(false);
    const [viewAllCourse, setViewAllCourse] = useState(false);
    const [examIsReady, setExamIsReady] = useState(false);
    const [error, setError] = useState(null);
    console.log(error)

    async function getPreguntas() {
        let url = process.env.REACT_APP_BACK_SERVER + '/getPreguntasCursos?idcurso=' + courseInfo.id_Curso;
        axios.post(url, [], {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            }
        }).then((resp) => {
            setQuestions(resp.data)
            setExamIsReady(true)
        });
    }


    useEffect(() => {
        getPreguntas();
    }, []);


    useEffect(() => {
        if (viewAllCourse && courseInfo.Accion === "Informativo") {
            enviarPuntaje(courseInfo.id_Curso, authToken);
        }
    }, [viewAllCourse]);

    return (
        <div className="ACourse__container">
            <div className="ACourse__titleContainer">
                <BackIcon className="ACourse__backIcon" onClick={() => history.push('/u/academia')}/>
                <p className="ACourse__title">{courseInfo.Titulo}</p>
            </div>
            <div className="ACourse__courseInfoContainer">
                <p className="ACourse__infoField"><DurationIcon
                    className="ACourse__infoIcon"/> Duración: <span>{courseInfo.Duracion} minutos</span></p>
                <p className="ACourse__infoField"><LevelIcon className="ACourse__infoIcon"/> Nivel de
                    dificultad: <span>{courseInfo.Dificultad}</span></p>
                <p className="ACourse__infoField"><StatusIcon
                    className="ACourse__infoIcon"/> Estado: <span
                    className={courseInfo.Estado === "En Curso" ? "ACourse__infoField--EnCurso" : `ACourse__infoField--${courseInfo.Estado}`}>{courseInfo.Estado}</span>
                </p>
            </div>
            <div className="ACourse__pdfContainer">
                <PDFViewer
                    src={courseInfo.PDF}
                    urlVideo={courseInfo.Video}
                    title={courseInfo.Titulo}
                    setAprove={setViewAllCourse}
                />
            </div>
            {courseInfo.Accion !== "Informativo" &&
                <div className="ACourse__buttonContainer">{courseInfo.Estado !== "Aprobado" ?
                    <Button variant="filled" enabled loading={!examIsReady} color="primary"
                            onClick={() => {
                                history.push({
                                    pathname: "/u/academia/preguntas/" + +String(courseInfo.id_Curso).padStart(2, '0'),
                                    state: {
                                        ...courseInfo,
                                        questions: questions,
                                    }
                                })
                            }
                            }>Rendir
                    </Button> :
                    <Button variant="filled" enabled color="primary" loading={downloading}
                            onClick={() => certificado(courseInfo.Plataforma, courseInfo.Titulo, courseInfo.Modulo, userName, userLastName, userPicture, setDownloading, setError)
                            }>Descargar certificado
                    </Button>
                }
                </div>
            }
        </div>
    )
}

export default AcademyCourse;