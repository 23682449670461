import {Dropzone} from "@mantine/dropzone";
import {Group} from "@mantine/core";
import {IconPhoto, IconUpload, IconX} from "@tabler/icons";
import React from "react";
import "./MantineDropzone.css";

/**
 * @returns {Component} The table rendered.
 * onDrop {Function} The onDrop mantine prop
 * onReject {Function} The onReject mantine prop
 * maxSize {Number} The maxSize mantine prop
 * maxFiles {Number} The maxFiles mantine prop
 * accept {Array} The accept mantine prop
 * disabled {Boolean} The disabled mantine prop
 */

function MantineDropzone(props) {
    const {clarificationText} = props;

    return (
        <Dropzone
            {...props}
        >
            <Group position="center" spacing="xl" className="MDropzone__dropzone">
                <Dropzone.Accept>
                    <IconUpload
                        className="MDropzone__dropzone__icon"
                        stroke={1.5}
                    />
                </Dropzone.Accept>
                <Dropzone.Reject>
                    <IconX
                        className="MDropzone__dropzone__icon"
                        stroke={1.5}
                    />
                </Dropzone.Reject>
                <Dropzone.Idle>
                    <IconPhoto
                        className="MDropzone__dropzone__icon"
                        stroke={1.5}
                    />
                </Dropzone.Idle>

                <div>
                    <p className="MDropzone__dropzone__title">
                        Arrastre o haga clic para seleccionar los archivos
                    </p>
                    <p className="MDropzone__dropzone__text">
                        {clarificationText}
                    </p>
                </div>
            </Group>
        </Dropzone>);
}

export default MantineDropzone;
