import CryptoJS from 'crypto-js';

const encrypt = (data) => {
    return CryptoJS['AES'].encrypt(JSON.stringify(data), process.env.REACT_APP_ENCRYPTION_KEY).toString();
}

const decrypt = (data) => {
    let bytes  = CryptoJS.AES.decrypt(data, process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

export {encrypt, decrypt};