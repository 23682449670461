export default function isValidDni(dni) {
    if (typeof dni === 'string') {
        if (dni.length === 8 || dni.length === 7) {
            return true;
        }
    }
    else if (typeof dni === 'number') {
        if (1000000 <= dni && dni <= 99999999) {
            return true;
        }
    }
    else return false;
}