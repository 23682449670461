import React from 'react';
import './Tab.css';

function Tab(props) {
    const {key, active, children, onClick, type="tab"} = props;
    return (
        <div key={key} className={active ? `${type} ${type}--active` : `${type}`} onClick={onClick}>
            {children}
        </div>
    );
}

export default Tab;