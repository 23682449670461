export default {
    formId: 'profileForm',
    formField: {
        nombre: {
            name: 'nombre',
            label: 'Nombre',
            requiredErrorMsg: 'Debe especificar su nombre'
        },
        apellido: {
            name: 'apellido',
            label: 'Apellido',
            requiredErrorMsg: 'Debe especificar su apellido'
        },
        DNI: {
            name: 'DNI',
            label: 'DNI',
            requiredErrorMsg: 'Debe especificar su DNI'
        },
        CUIT: {
            name: 'CUIT',
            label: 'CUIT / CUIL',
            requiredErrorMsg: 'Debe especificar su CUIT o CUIL'
        },
        nacimiento: {
            name: 'nacimiento',
            label: 'Fecha de nacimiento',
            requiredErrorMsg: 'Debe ingresar una fecha de nacimiento'
        },
        // pais: {
        //     name: 'pais',
        //     label: 'País',
        //     requiredErrorMsg: 'Debe especificar un país'
        // },
        direccion: {
            name: 'direccion',
            label: 'Dirección',
            requiredErrorMsg: 'Debe especificar una dirección'
        },

        // </DIRECCION>

        nacionalidad: {
            name: 'nacionalidad',
            label: 'Nacionalidad',
            requiredErrorMsg: 'Debe especificar una nacionalidad'
        },
        email: {
            name: 'email',
            label: 'E-mail de contacto',
            requiredErrorMsg: 'Debe especificar un E-mail'
        },
        telefono: {
            name: 'telefono',
            label: 'Celular',
            requiredErrorMsg: 'Debe especificar un número de celular'
        },
        sexo: {
            name: 'sexo',
            label: 'Sexo',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        ocupacion: {
            name: 'ocupacion',
            label: 'Ocupación',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        IVA: {
            name: 'IVA',
            label: 'Situación ante el IVA',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        matricula: {
            name: 'matricula',
            label: 'Matrícula',
            requiredErrorMsg: 'Debe especificar un número de matrícula'
        },
        l_nacimiento: {
            name: 'l_nacimiento',
            label: 'Lugar de nacimiento',
            requiredErrorMsg: 'Lugar de nacimiento is required'
        },
        CBU: {
            name: 'cbu',
            label: 'CBU / CVU',
            requiredErrorMsg: 'Debe indicar un número de CBU o CVU'
        },
        codigosLocalidad: {
            name: 'codigosLocalidad',
            label: 'Localidad',
            requiredErrorMsg: 'Debe especificar una localidad'
        },
        codigoPostal: {
            name: 'codigoPostal',
            label: 'Código Postal',
            requiredErrorMsg: 'Debe especificar un código postal'
        },
        idProvincia: {
            name: 'idProvincia',
            label: 'Provincia',
            requiredErrorMsg: 'Debe especificar una provincia'
        },
    }
};
