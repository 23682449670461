import React from 'react';
import './ResumenSection.css';

function ResumenSection(props) {
    const {title, body} = props;
    return (
        <div className="RSection__container">
            <div className="RSection__text RSection__text--title">{title}</div>
            <div className="RSection__text RSection__text--body">{body}</div>
        </div>
    )
}

export default ResumenSection;