import { makeStyles } from '@material-ui/core/styles';
export default makeStyles(theme => ({
  boxPrice: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "120px",
    margin: "16px 0",
    backgroundColor: "#452d7b"
  },
  priceTitle: {
    fontFamily: 'Nunito',
    fontWeight: 400,
    fontSize: 18,
    color: "#fff"
  },
  priceText: {
    fontFamily: 'Nunito',
    fontWeight: 700,
    fontSize: 32,
    color: "#fff"
  },
  listRow: {
    alignItems: 'center',
  },
  buttonForm: {
    backgroundColor: '#452d7b',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#38217b',
      boxShadow: 'none',
    },
  },
  coberturasTable: {
    padding: theme.spacing(1),
  },
  tableHeaderCoberturas: {
    fontFamily: 'Montserrat',
    fontSize: 20
  },
  tableRowLabel: {
    fontFamily: 'Nunito'
  },
  aditionalsContent: {
    paddingTop: '32px !important'
  },
  centerContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  note: {
    fontFamily: 'Nunito',
    fontSize: 12,
    color: theme.palette.grey.A200
  },
  dataText: {
    fontFamily: 'Nunito',
    fontSize: 20
  },
  fieldArrayButtons: {
    padding: "0",
    marginTop: "16px",
    minWidth: "28px",
    maxWidth: "28px"
  },
  fieldArrayButtonsDiv: {
    display: "flex",
    flexDirection: "row",
    width: "64px",
    justifyContent: "space-between"
  },
  tableHeader: {
    fontFamily: 'Nunito',
    fontSize: "18px",
    lineHeight: "1"
  },
  errorMessage: {
    color: "red",
    fontSize: "12px",
    textAlign: "center",
    marginTop: "4px"
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  buttonContained: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    backgroundColor: '#452d7b',
    '&:hover': {
      backgroundColor: '#38217b',
      boxShadow: 'none',
    },
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%'
  },
  step: {
    "& $completed": {
      color: '#452d7b'
    },
    "& $active": {
      color: '#452d7b'
    },
  },
  active: {},
  completed: {},
  alert: {
    backgroundColor: theme.palette.warning.dark
  },
}));
