import React, {useContext, useEffect, useState} from 'react';
import './CotizarHogar.css';
import {InformacionModel} from "./FormStages/Cotizar/Informacion/InformacionModel";
import {CoberturasModel} from "./FormStages/Cotizar/Coberturas/CoberturasModel";
import InformacionForm from "./FormStages/Cotizar/Informacion/InformacionForm";
import CoberturasForm from "./FormStages/Cotizar/Coberturas/CoberturasForm";
import CotizarSuccessView from "./FormStages/Cotizar/CotizarSuccess/CotizarSuccessView";
import {useCurrentQuotationContext} from "../../../context/CurrentQuotationContext/CurrentQuotationContext";
import axios from "axios";
import moment from "moment";
import {FormCard} from "../../../components/FormCard";
import {Loading} from "../../../components/Loading";
import {FormRenderer} from "../../../components/FormRenderer";
import {ErrorModal} from "../../../layouts/ErrorModal";
import AuthContext from "../../../context/AuthContext";
import {DetallesModel} from "./FormStages/Emitir/Detalles/DetallesModel";
import {SolicitudModel} from "./FormStages/Emitir/Solicitud/SolicitudModel";
import {PolizaModel} from "./FormStages/Emitir/Poliza/PolizaModel";
import {ClienteModel} from "./FormStages/Emitir/Cliente/ClienteModel";
import DetallesForm from "./FormStages/Emitir/Detalles/DetallesForm";
import PolizaForm from "./FormStages/Emitir/Poliza/PolizaForm";
import ClienteForm from "./FormStages/Emitir/Cliente/ClienteForm";
import SolicitudForm from "./FormStages/Emitir/Solicitud/SolicitudForm";
import {formatPhoneNumberIntl} from "react-phone-number-input";
import {isResponseSuccessful} from "../../../modules/utils/isResponseSuccessful";
import {SuccessModal} from "../../../layouts/SuccessModal";
import {Form, Formik} from "formik";
import {MantineSelectField} from "../../../components/FormFields/MantineSelectField";
import {Button} from "../../../components/Button";
import {useHistory, useLocation} from "react-router-dom";
import UserContext from "../../../context/UserContext";
import CotizarContext from "../../../context/CotizarContext";
import {handleDownloadDocs} from "../../../modules/utils/handleDownloadDocs";
import showNotification from "../../../modules/utils/showNotification";
import {buildLocationCode} from "../../Cotizacion/utils/buildLocationCode";
import normalizeString from "../../utils/normalizeString";
import {getLocalidadOptionsByZipCode} from "../../Cotizacion/utils/getLocalidadOptionsByZipCode";

function coberturasDictFromArray(array) {
    const diccionario = {};
    array.forEach(item => {
        diccionario[item.numeroCobertura] = {"sumaAsegurada": item.sumaAsegurada};
    });
    return diccionario;
}

function coberturasAdicionalesDictFromArray(array) {
    const diccionario = {
        "26": {"sumaAsegurada": 0, "selectedCobertura": false},
        "36": {"sumaAsegurada": 0, "selectedCobertura": false},
        "37": {"sumaAsegurada": 0, "selectedCobertura": false},
        "48": {"sumaAsegurada": 0, "selectedCobertura": false},
    };

    array.forEach(item => {
        const cobertura = diccionario[item.numeroCobertura];
        if (cobertura) {
            cobertura.sumaAsegurada = item.sumaAsegurada;
            cobertura.selectedCobertura = true;
        }
    });

    return diccionario;
}

function CotizarHogar(props) {
    const {recotizarValues, action, performReset} = props;
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalContent, setErrorModalContent] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const {
        cotizacionResponse,
        setCotizacionResponse,
        setFinalValues,
        currentFormData,
        setCurrentFormData,
        setFormData,
        formData,
        activeStep,
        setActiveStep,
        setEmisionResponse,
        emisionResponse,
        setUpdatingForm,
        finalValues,
        localidadOptions
    } = useCurrentQuotationContext();
    const {authToken} = useContext(AuthContext);
    const {
        activeFormProduct,
        setActiveFormProduct,
        userCodigosOperacion,
        userDetalleComisiones
    } = useContext(UserContext);
    const {companiesDict} = useContext(CotizarContext);
    const hardcodedCompany = "Sancor";

    //Cotizar
    const {initialValues: informacionInitialValues, validationSchema: informacionValidationSchema} = InformacionModel();
    const {initialValues: coberturasInitialValues, validationSchema: coberturasValidationSchema} = CoberturasModel();
    //Emitir
    const {initialValues: detallesInitialValues, validationSchema: detallesValidationSchema} = DetallesModel();
    const {initialValues: polizaInitialValues, validationSchema: polizaValidationSchema} = PolizaModel();
    const {initialValues: clienteInitialValues, validationSchema: clienteValidationSchema} = ClienteModel();
    const {initialValues: solicitudInitialValues, validationSchema: solicitudValidationSchema} = SolicitudModel();

    const valuesAdjusterFromRecotizar = () => {
        if (action === "shortcut") {
            return recotizarValues;
        } else if (recotizarValues) {
            const quotationData = {
                ...recotizarValues,
                "company": recotizarValues.companies,
                "coberturasAdicionales": coberturasAdicionalesDictFromArray(recotizarValues['coberturas']['adicionales']),
                "coberturas": coberturasDictFromArray(recotizarValues['coberturas']['obligatorias']),
                "tipoVivienda": recotizarValues['hogar']['tipoVivienda'],
                "tamanioVivienda": recotizarValues['hogar']['tamanioVivienda'],
            }
            // Create return object omitting "idCanalDigital" property
            // Also prevent to send customer nonsense data
            const { idCanalDigital, cliente, ...filteredQuotationData } = quotationData;
            if (recotizarValues.cliente !== "Sin datos") {
                filteredQuotationData.cliente = {
                    ...recotizarValues.cliente,
                }
            }
            return filteredQuotationData;
        } else return null
    }

    //Se define cada paso
    const initialQuotationForm = [
        {
            name: "Información",
            stepButtonName: "Siguiente",
            form: <InformacionForm isRecotizar={action === "recotizar"}/>,
            initialValues: informacionInitialValues,
            validationSchema: informacionValidationSchema
        },
        {
            name: "Coberturas",
            stepButtonName: "Cotizar",
            form: <CoberturasForm/>,
            initialValues: coberturasInitialValues,
            validationSchema: coberturasValidationSchema
        },
        {
            name: "Cotización",
            stepButtonName: "Siguiente",
            form: <CotizarSuccessView submitForm={_submitCotizarForm}/>,
            initialValues: valuesAdjusterFromRecotizar(), //Include it in last step so it overrides previous initial values
            validationSchema: null
        },
        {
            name: "#Emisión",
            stepButtonName: "Siguiente",
            form: null,
            initialValues: null,
            validationSchema: null
        },
    ]

    const emisionFormData = [
        {
            name: "Detalles",
            stepButtonName: "Siguiente",
            form: <DetallesForm/>,
            initialValues: detallesInitialValues,
            validationSchema: detallesValidationSchema
        },
        {
            name: "Póliza",
            stepButtonName: "Siguiente",
            form: <PolizaForm/>,
            initialValues: polizaInitialValues,
            validationSchema: polizaValidationSchema
        },
        {
            name: "Clientes",
            stepButtonName: "Siguiente",
            form: <ClienteForm/>,
            initialValues: clienteInitialValues,
            validationSchema: clienteValidationSchema
        },
        {
            name: "Solicitud",
            stepButtonName: "Emitir",
            form: <SolicitudForm/>,
            initialValues: solicitudInitialValues,
            validationSchema: solicitudValidationSchema
        },
    ]

    async function _submitCotizarForm(values, actions) {
        let url = process.env.REACT_APP_BACK_SERVER + '/newcotizar/hogar';

        values.company = [1]
        const companiesName = values.company.map(company => companiesDict[company].name)
        const locationCodes = await buildLocationCode(companiesName, values.codigosLocalidad, companiesDict);
        values.codigoPostal = parseInt(values.codigoPostal);
        let selectedLocation = null;

        if (cotizacionResponse !== null) {
            values.is_saved = true
            values.idCotizar = cotizacionResponse['idCotizar']
        }
        if (action && action !== "shortcut") {
            values.idCotizar = values['_id']
            if (action === "emitir") {
                const locations = await getLocalidadOptionsByZipCode(values.codigoPostal)
                const intCodigoLocalidad = parseInt(values.codigosLocalidad);
                selectedLocation = locations.find(localidad => localidad.value === intCodigoLocalidad)
            } else if (action === "recotizar") {
                selectedLocation = localidadOptions[0].find(localidad => localidad.value === values.codigosLocalidad)
            }
        } else if ((action === "shortcut" || !action) && !values.applyAdvancedConfiguration) { // Only apply this if it's a new quotation
            //Apply advancedConfiguration based on selected and allowed companies
            selectedLocation = localidadOptions[0].find(localidad => localidad.value === values.codigosLocalidad)
            values.company.forEach(company => {
                const companyName = companiesDict[company].name;
                if (companyName === 'Sancor') {
                    values['configuracionAvanzada']['Sancor'].codSancorLocalidad = `${locationCodes.CodigoLocalidadSancor}`
                }
            })
        }

        values.nombreLocalidad = selectedLocation ? selectedLocation.label.split(') ')[1] : "";

        const jsonData = {
            ...values,
            "companies": values['company'],
            "coberturas": Object.entries(values.coberturas).map(([numeroCobertura, {sumaAsegurada}]) => ({
                numeroCobertura,
                sumaAsegurada: sumaAsegurada
            })),
            "codigosLocalidades": locationCodes,
            "codigoPostal": parseInt(values.codigoPostal),
            "coberturasAdicionales": Object.entries(values.coberturasAdicionales).map(([numeroCobertura, {sumaAsegurada}]) => {
                if (values.coberturasAdicionales[numeroCobertura].selectedCobertura) {
                    return {
                        numeroCobertura,
                        sumaAsegurada: sumaAsegurada
                    }
                }
            }).filter(e => e),
            "configuracionAvanzada": {...values['configuracionAvanzada'], },
            "parametrosAdicionales": { //TODO: move this to configuracionAvanzada object & inside the company
                "codigos": userCodigosOperacion['Sancor'][0],
            },
            "origen": "POLKO",
        }

        axios.post(url, {...jsonData, ...{saveToDatabase: true}}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            }
        }).then(res => {
            //TODO: Review and improve quotation error
            if (res.data.Sancor.error && !isResponseSuccessful(res.data.Sancor.error.status_code)) {
                let errorData = res.data.Sancor.error;
                setErrorModalContent({
                    title: errorData['detail']['code'],
                    typeError: "Ha ocurrido un error en la comunicación con el servicio",
                    suggestedActions: ["Revisá que la información ingresada sea correcta", "Reintentá el proceso una vez más", "Si el problema persiste ponete en contacto con el área de soporte"],
                    detailedError: errorData['detail']['message'],
                })
                setErrorModalOpen(true);
            } else {
                setCotizacionResponse(res.data);
            }
        }).catch((err) => {
            setErrorModalContent({
                title: "Hubo un problema al cotizar, por favor vuelva a intentarlo más tarde.",
                typeError: "Ha ocurrido un error en la comunicación con el servicio",
                suggestedActions: ["Revisá que la información ingresada sea correcta", "Reintentá el proceso una vez más", "Si el problema persiste ponete en contacto con el área de soporte"],
                detailedError: err.message,
            })
            setErrorModalOpen(true);
        }).finally(() => {
            setFinalValues(values);
            if (actions)
                actions.setSubmitting(false);
        });
    }

    async function _submitEmitirForm(values, actions) {
        let url = process.env.REACT_APP_BACK_SERVER + '/newemitir/hogar';

        for (let cliente of values.clientes) {
            let _telefono = formatPhoneNumberIntl(cliente.telefono).split(" ")
            cliente['codigoArea'] = _telefono[1]
            // cliente['telefono'] = _telefono[2] + '' + _telefono[3]
            cliente['tipoPersona'] = '0'
            cliente['fechaNacimiento'] = moment(cliente.fechaNacimiento).format('YYYY-MM-DD')
        }

        //Add coberturasAdicionales to coberturas array
        let coberturasFinal = Object.entries(values.coberturasAdicionales).map(([numeroCobertura, {sumaAsegurada}]) => {
            if (values.coberturasAdicionales[numeroCobertura].selectedCobertura)
                return {numeroCobertura, sumaAsegurada}
        }).filter(e => e);

        //Handle detallesAdicionales
        let detallesAdicionales = {}
        if (coberturasFinal.length > 0) {
            coberturasFinal.map(cob => {
                if (cob.numeroCobertura === "48") {
                    detallesAdicionales['bicicletas'] = []

                    values['detalleBicicletas'].forEach(bike => {
                        detallesAdicionales['bicicletas'].push({
                            "descripcion": bike['descripcionBicicletas'],
                            "sumaAsegurada": bike['sumaAseguradaBicicletas'],
                            "codigo": bike['codigoBicicletas'],
                            "codigoGrupo": bike['codigoGrupoBicicletas'],
                        })
                    })

                }

                if (cob.numeroCobertura === "26") {
                    detallesAdicionales['equiposElectronicos'] = []

                    values['detalleEquipoElectronico'].forEach(eqElectronico => {
                        detallesAdicionales['equiposElectronicos'].push({
                            "descripcion": eqElectronico['descripcionElectronico'],
                            "sumaAsegurada": eqElectronico['sumaAseguradaElectronico'],
                            "codigo": eqElectronico['codigoElectronico'],
                            "codigoGrupo": eqElectronico['codigoGrupoElectronico']
                        })
                    })
                }
            })
        }
        //Adding required coverages to coberturas
        coberturasFinal.push(...Object.entries(values.coberturas).map(([numeroCobertura, {sumaAsegurada}]) => ({
                numeroCobertura, sumaAsegurada
            }))
        )

        const selectedDiscount = values['configuracionAvanzada'][hardcodedCompany]['descuento'];

        let jsonData = {
            "idCotizacion": cotizacionResponse.idCotizar,
            "vigenciaDesde": moment(values['vigenciaDesde']).format('YYYY-MM-DD'),
            "vigenciaHasta": moment(values['vigenciaHasta']).format('YYYY-MM-DD'),
            "tipoVivienda": values['tipoVivienda'],
            "tamanioVivienda": values['tamanioVivienda'],
            "formaDePago": values['formaDePago'],
            "infoDePago": values['infoDePago'],
            "comentariosSolicitud": normalizeString(values['comentariosSolicitud'], ['\\', '#', '$', '%', '\'', '\"', '*', '{', '}'], false, ""),
            "clientes": values['clientes'],
            "coberturas": coberturasFinal,
            //Depends on selected company
            "company": companiesDict[values['company'][0]]['name'],
            "premium": cotizacionResponse[companiesDict[values['company'][0]]['name']]['premioMensual'],
            "parametrosAdicionales": {
                "frecuenciaPago": values['frecuenciaPago'],
                "descuento": selectedDiscount !== 0 ? selectedDiscount : 1,
                "codSancorLocalidad": values['configuracionAvanzada']['Sancor'].codSancorLocalidad,
                "codigos": userCodigosOperacion['Sancor'][0],
                "comision": userDetalleComisiones['Sancor']['HOGAR'].general,
            }
        }

        if (Object.keys(detallesAdicionales).length > 0) {
            jsonData['detallesAdicionales'] = detallesAdicionales
            jsonData['detallesAdicionales']['archivos'] = values.archivosBici.concat(values.archivosElectronicos)
        }

        axios.post(url, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            }
        }).then(res => {
            let filteredDocumentacion = [];

            if ('documentacion' in res.data && Array.isArray(res.data['documentacion'])) {
                filteredDocumentacion = res.data['documentacion'].filter(doc => doc !== "Certificado de Cobertura Provisoria");
            }

            setEmisionResponse({
                ...res.data,
                documentacion: filteredDocumentacion
            });
            if (isResponseSuccessful(res.status)) {
                setSuccessModalOpen(true)
            } else {
                setErrorModalContent({
                    title: "Hubo un problema al emitir, por favor vuelva a intentarlo más tarde.",
                    typeError: res.data['detail']['code'],
                    suggestedActions: ["Revisá que la información ingresada sea correcta", "Evitá incluir caracteres especiales", "Reintente el proceso"],
                    detailedError: res.data['detail']['message'],
                })
                setErrorModalOpen(true);
            }
        }).catch((err) => {
            setErrorModalContent({
                title: "Hubo un problema al emitir, por favor vuelva a intentarlo más tarde.",
                typeError: err.response.data.detail.code,
                suggestedActions: ["Revisá que la información ingresada sea correcta", "Reintentá el proceso una vez más", "Si el problema persiste ponete en contacto con el área de soporte"],
                detailedError: err.response.data.detail.message,
            })
            setErrorModalOpen(true);
        }).finally(() => {
            setFinalValues(values);
            setIsLoading(false)
            actions.setSubmitting(false);
        });
    }

    const downloadDocumentation = async (formValues, setSubmitting) => {
        setSubmitting(true)

        let url = process.env.REACT_APP_QUOTATION_MICROSERVICE + '/documentacion/hogar'
        try {
            await handleDownloadDocs(
                url,
                finalValues.company,
                formValues.documento,
                emisionResponse['identificadores'],
                authToken
            );
        } catch (error) {
            showNotification({
                autoClose: 3000,
                color: 'red',
                status: 'error',
                title: 'Error al descargar la documentación.',
                message: error,
            });
        }

        setSubmitting(false)
    }

    const handleQuotationToEmission = (coberturasAdicionales) => {
        const cotizarDisabledStep = {
            name: "#Cotización",
            form: null,
            initialValues: null,
            validationSchema: null
        }

        setUpdatingForm(true)

        const condition1 = formData['emision'][0]['name'] === 'Detalles';
        const condition2 = coberturasAdicionales['26'].selectedCobertura;
        const condition3 = coberturasAdicionales['48'].selectedCobertura;

        if (condition1 && !condition2 && !condition3) {
            formData['emision'].shift()
        }

        setCurrentFormData([cotizarDisabledStep, ...formData['emision']]);
    }

    function _handleSubmit(values, actions) {
        let emisionStep = formData['cotizacion'].length + formData['emision'].length - 1;

        actions.setSubmitting(true);
        if (activeStep === emisionStep) {
            setIsLoading(true)
            _submitEmitirForm(values, actions);
        } else {
            if (activeStep === 1) {
                setActiveStep(activeStep + 1);
                _submitCotizarForm(values, actions);
            } else if (activeStep === 2) {
                handleQuotationToEmission(values.coberturasAdicionales)
                actions.setSubmitting(false);
            } else {
                setActiveStep(activeStep + 1);
                actions.setTouched({});
                actions.setSubmitting(false);
            }
        }
    }

    function _handleBack() {
        if (activeStep === initialQuotationForm.length - 2) {
            setCotizacionResponse(null);
        }
        if (activeStep > 0)
            setActiveStep(activeStep - 1);
    }

    useEffect(() => {
        setCurrentFormData(initialQuotationForm)
        setFormData({
            "cotizacion": initialQuotationForm,
            "emision": emisionFormData
        })

        setActiveStep(0) //Reset active step
        setCotizacionResponse(null) //Reset cotizacion response
        setActiveFormProduct("hogar") //Name has to be the route path

        if (action === "emitir") {
            setActiveStep(initialQuotationForm.length - 2)
            _submitCotizarForm(valuesAdjusterFromRecotizar(), null);
        }
    }, [performReset]);

    if (!currentFormData || location.pathname.split("/")[3] !== activeFormProduct) return <FormCard active={activeStep}><Loading/></FormCard>
    return (
        <div className="hogar__form__container">
            <FormCard active={activeStep}>
                <>
                    <FormRenderer
                        handleSubmit={_handleSubmit}
                        handleBack={_handleBack}
                        isLoading={isLoading}
                        doNotDisplayNextButton={[]}
                        product={"HOGAR"}
                        performReset={performReset}
                        clientsFieldName="clientes"
                    />
                </>
            </FormCard>

            {errorModalOpen &&
                <ErrorModal
                    closeAction={() => {
                        if (currentFormData[0]['name'] === "#Cotización") { //Indicates an error during emision
                            setActiveStep(formData['cotizacion'].length)
                            setEmisionResponse(null)
                        } else {
                            setActiveStep(0)
                            setCotizacionResponse(null)
                        }
                        setErrorModalOpen(false)
                    }}
                    title={errorModalContent.title}
                    typeError={errorModalContent.typeError}
                    suggestedActions={errorModalContent.suggestedActions}
                    detailedError={errorModalContent.detailedError}
                />
            }

            {successModalOpen &&
                <SuccessModal
                    closeAction={() => {
                        setSuccessModalOpen(false)
                        setCotizacionResponse(null)
                        setEmisionResponse(null)
                        history.push({pathname: '/u/dashboard'});
                    }}
                    title={"¡Felicitaciones, la operación se ha realizado con éxito!"}
                    body={
                        emisionResponse['identificadores'].filter(v => v.key !== "certificateNumber" && v.key !== "documentacion").map(id => (
                            <div className="successModal__body__block">
                                <p className="successModal__subtitle">{id.nombre}</p>
                                <p className="successModal__text">{id.valor}</p>
                            </div>))
                    }
                    footer={
                        <div className="autem__success__footer__container">
                            {emisionResponse['status'] !== "Pending" && emisionResponse['documentacion'].length > 0 &&
                                <div className="autem__success_footer__wrapper">
                                    <p className="autem__success__footer__subtitle">Documentación</p>
                                    <Formik
                                        initialValues={{documento: emisionResponse['documentacion'][0]}}
                                        onSubmit={() => {
                                        }}
                                    >
                                        {({isSubmitting, values, setSubmitting}) => (
                                            <Form method="post" style={{width: "70%"}}>
                                                <MantineSelectField
                                                    name={`documento`}
                                                    label={""}
                                                    data={
                                                        emisionResponse['documentacion']
                                                            .filter(doc => doc !== "Certificado de Cobertura Provisoria")
                                                            .map(doc => {
                                                                return { value: doc, label: doc };
                                                            })
                                                    }
                                                />
                                                <div className="autem__success__footer__button__container">
                                                    <Button
                                                        type="button"
                                                        disabled={isSubmitting}
                                                        variant="filled"
                                                        color="primary"
                                                        marginY="sm"
                                                        enabled
                                                        onClick={() => downloadDocumentation(values, setSubmitting)}
                                                    >
                                                        Descargar
                                                    </Button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                    <p className="autem__success__footer__text">
                                        Descargá los documentos y anotá los
                                        números de identificación para próximas consultas.
                                    </p>
                                </div>
                            }

                            <img className="autem__success__footer__logo"
                                 src={companiesDict[finalValues.company]['logo']}
                                 alt={finalValues.company}/>
                        </div>
                    }
                />
            }
        </div>
    );
}

export default CotizarHogar;