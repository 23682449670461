import React from 'react';
import './PendingCommisssionsInfoModal.css';
import {InformationModal} from "../../../layouts/InformationModal";

function PendingCommisssionsInfoModal({onClose}) {
    return (
        <InformationModal
            closeAction={onClose}
            title={"Importante"}
            body={
                <div className="penComInfoModal__container">
                    <p className="penComInfoModal__title">Incentivos Pendientes</p>
                    <div className="penComInfoModal__block">
                        <p className="penComInfoModal__text">
                            <span className="penComInfoModal__text--bold">Pólizas Emitidas: </span>
                            pasados los 15 días de vigencia, pasarán a estado "Incentivos Disponibles" y disponibles para
                            su retiro.

                            <p style={{fontStyle: "italic", display: "inline"}}>*Emisiones cuya forma de pago corresponda "Efectivo" se
                                autorizará una vez abonada la primera cuota.</p>
                        </p>
                    </div>

                    <div className="penComInfoModal__block">
                        <p className="penComInfoModal__text">
                            <span className="penComInfoModal__text--bold">Pólizas Pendientes: </span>
                            una vez completado el proceso de suscripción en la compañía, y habiéndo emitido la póliza,
                            desde Operaciones de Polko, se autoriza el cobro del incentivo correspondiente. Este
                            proceso puede demorar hasta 5 días hábiles. Luego, se inicia el proceso de "Póliza Emitida".
                        </p>
                    </div>

                    <div className="penComInfoModal__block">
                        <p className="penComInfoModal__text">
                            <span className="penComInfoModal__text--bold">Pólizas Rechazadas: </span>
                            solicitudes cargadas que presenten alguna irregularidad o demora no gestionada, pasados los
                            20 días se cancelarán y no se autorizará incentivo alguno.
                        </p>
                    </div>
                </div>
            }
        />
    );
}

export default PendingCommisssionsInfoModal;