const currencyFormat = (numString) => {
    if (!numString) return ""
    if (!isNaN(numString)) numString = numString.toString()

    numString = numString.replace(/\./g, "")
    numString = numString.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return numString
}

export {currencyFormat};