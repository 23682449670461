import React, {useContext} from "react";
import {Route, Redirect} from 'react-router-dom';
import AuthContext, {withAuthContextProvider, withAuthContextReady} from "../../context/AuthContext";
import {compose} from "recompose";


const ProtectedRoute = ({component: Component, ...rest}) => {
    const {isAuth} = useContext(AuthContext)
    return (
        <Route {...rest} render={props => (
            isAuth ?
                <Component {...props} />
                : <Redirect to="/"/>
        )}/>
    );
};

export default compose(withAuthContextProvider, withAuthContextReady)(ProtectedRoute);
