import {useContext} from 'react';
import UserContext from "../../../../context/UserContext";
import Model from "./Model";

const {
    formField: {
        nombre,
        apellido,
        celular,
        mail,
        numero_productor,
        numero_organizador,
        compania,
        tiene_codigo,
        codigo_postal,
        domicilio,
        estado_civil,
        fecha_nacimiento,
        localidad,
        provincia,
        cuit,
        oficina,
        experiencia,
        select_companies,
        files,
    },
} = Model;


function InitialValues() {
    const {
        userName,
        userLastName,
        userMail,
        userPhone,
        userFechaNacimiento,
        userDireccion,
        userIdProvince,
        userCodigoLocalidad,
        userCodigoPostal,
        userCUIT
    } = useContext(UserContext);

    return {
        [nombre.name]: userName,
        [apellido.name]: userLastName,
        [mail.name]: userMail,
        [celular.name]: userPhone.includes('+') ? userPhone : `+54${userPhone}`,
        [numero_productor.name]: '',
        [numero_organizador.name]: '',
        [compania.name]: '',
        [tiene_codigo.name]: '',
        [codigo_postal.name]: userCodigoPostal,
        [domicilio.name]: userDireccion,
        [estado_civil.name]: '',
        [fecha_nacimiento.name]: userFechaNacimiento,
        [localidad.name]: userCodigoLocalidad,
        [provincia.name]: userIdProvince,
        [cuit.name]: userCUIT,
        [oficina.name]: '',
        [experiencia.name]: '',
        [select_companies.name]: '',
        [files.name]: [],
    };
}

export default InitialValues;