import React from 'react';
import './StatisticsCard.css';

function StatisticsCard(props) {
    const {key, value, title, fullHeight, variant} = props;
    return (
        <div className={`statisticsCard__container ${fullHeight && 'fullHeight'} ${variant}`} key={key}>
            <p className="statisticsCard__value">{value}</p>
            <p className="statisticsCard__title">{title}</p>
        </div>
    );
}

export default StatisticsCard;