import React from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "./Loading";
import {withAuthContextProvider, withAuthContextReady} from "../../context/AuthContext";
import {compose} from "recompose";

const PrivateRoute = ({ component, ...args }) => (
    <Route
        component={withAuthenticationRequired(component, {
            onRedirecting: () => <Loading />,
        })}
        {...args}
    />
);

export default compose(withAuthContextProvider, withAuthContextReady)(PrivateRoute);
