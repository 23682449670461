import React, {useContext, useEffect, useState} from 'react';
import {currencyFormat} from "../../../modules/utils/currencyFormat";
import TextLink from "../../../components/TextLink/TextLink";
import {AdvancedFormRenderer} from "../AdvancedFormRenderer";
import {CustomSlider} from "../../../components/CustomSlider";
import {Field, useFormikContext} from "formik";
import {useCurrentQuotationContext} from "../../../context/CurrentQuotationContext/CurrentQuotationContext";
import './GenericQuotationResponseTab.css';
import {Loading} from "../../../components/Loading";
import useWindowDimensions from "../../../modules/utils/useWindowDimensions";
import UserContext from "../../../context/UserContext";
import {CoverageOverviewModal} from "../CoverageOverviewModal";
import {QuotationResponseTabError} from "../QuotationResponseTabError";
import {CarouselWrapper} from "../../../components/CarouselWrapper";
import CotizarContext from "../../../context/CotizarContext";
import DefaultValuesContext from "../../../context/DefaultValuesContext";
import UifFormWarn from "../UIFFormWarn/UIFFormWarn";
import {ReactComponent as InformationFilledIcon} from "../../../assets/icons/ic_info-filled.svg";
import {QuotationButtonInfoModal} from "../QuotationButtonInfoModal";
import {useHistory} from "react-router-dom";
import {Button} from "../../../components/Button";
import {GenericCoverageTableRow} from "../../../components/GenericCoverageTableRow";
import GenericCoverageCard from "../../../components/GenericCoverageCard/GenericCoverageCard";

//TODO: En un futuro deberia reemplazar a QuotationResponseTab.
//TODO: Falta añadir promo correctamente.
function GenericQuotationResponseTab(props) {
    const {
        company,
        displayConfiguracionAvanzada,
        setDisplayConfiguracionAvanzada,
        submitForm,
        addCoverageToCart,
        getRowData,
        getCoverageName,
        coveragesArray,
        quotationError,
        hasNotSumaAsegurada,
        hasCompanyFields = true
    } = props;
    const {
        setDiscounts,
        cotizacionResponse,
        setCurrentFormData,
        formData,
        setUpdatingForm,
        resumeKeyConstructor,
        coverageRenderRowConstructor,
    } = useCurrentQuotationContext()
    const {isMobile, userDiscounts, activeFormProduct, userCompaniesToPromote} = useContext(UserContext);
    const {companiesDict, coberturasProductsInfo} = useContext(CotizarContext);
    const {valuesMemo} = useContext(DefaultValuesContext)
    const {width} = useWindowDimensions();
    const {values, setFieldValue} = useFormikContext();
    const [coverageOverviewModalOpen, setCoverageOverviewModalOpen] = useState(false);
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [rowData, setRowData] = useState(null);
    const isPromotional = userCompaniesToPromote.includes(companiesDict[company].name)
    const history = useHistory();

    //Replicate changes applied to this function into CoverageComparison.js
    // TODO: Esta accion falta generalizarla.
    const handleAddStep = (companyId, rd) => {
        const cotizarDisabledStep = {
            name: "#Cotización",
            form: null,
            initialValues: null,
            validationSchema: null
        }

        const coverageName = rd.nombre ? rd.nombre : getCoverageName(
            companiesDict[company]['name'],
            coberturasProductsInfo[activeFormProduct],
            rd
        )

        setFieldValue("company", companiesDict[companyId]['name'])
        setFieldValue("companyId", companyId)
        setFieldValue("nombreCobertura", coverageName)
        setFieldValue("rowData", rd)

        setUpdatingForm(true)
        setCurrentFormData([cotizarDisabledStep, ...formData['emision']]);
    }

    const handleOpenCoverageOverviewModal = (rowDataReceived) => {
        setRowData(rowDataReceived);
        setCoverageOverviewModalOpen(true)
    }

    const coverageComponent = () => {
        if (isMobile) {
            return (coveragesArray.length > 1 ?
                <div className="coveragesCard__carousel" style={{width: `${width * 0.85 - 16 - 32}px`}}>
                    {/*    width * 0.85 (formCard width - 16 formCard padding - 32 body padding)*/}

                        <CarouselWrapper slideSize={176}>
                            {coveragesArray.map(cobertura => (
                                <GenericCoverageCard
                                    tabId={company}
                                    tab={companiesDict[company]['name']}
                                    companyName={companiesDict[company]['name']}
                                    rowData={getRowData(cobertura)}
                                    coverageName={getRowData(cobertura).nombre}
                                    onEmitirClic={handleAddStep}
                                    onCompararClic={addCoverageToCart}
                                    handleOpenCoverageOverviewModal={handleOpenCoverageOverviewModal}
                                    columns={coverageRenderRowConstructor}
                                />
                            ))}
                        </CarouselWrapper>
                </div>
                        :
                        <div className="coveragesCard__oneCoverageContainer">
                            <GenericCoverageCard
                                tabId={company}
                                tab={companiesDict[company]['name']}
                                companyName={companiesDict[company]['name']}
                                rowData={getRowData(coveragesArray[0])}
                                coverageName={getRowData(coveragesArray[0]).nombre}
                                onEmitirClic={handleAddStep}
                                onCompararClic={addCoverageToCart}
                                handleOpenCoverageOverviewModal={handleOpenCoverageOverviewModal}
                                columns={coverageRenderRowConstructor}
                            />
                        </div>
                    )
        } else {
            return coveragesArray.map(cobertura => (
                <GenericCoverageTableRow
                    addCoverageToCart={addCoverageToCart}
                    rowData={getRowData(cobertura)}
                    tab={companiesDict[company]['name']}
                    tabId={company}
                    handleAddStep={handleAddStep}
                    coverageName={getRowData(cobertura).nombre}
                    handleOpenCoverageOverviewModal={handleOpenCoverageOverviewModal}
                    appliedZurichAgreement={false}
                    columns={coverageRenderRowConstructor}
                />
            ))
        }
    }

    const handleRedirectToAltaForm = () => {
        const companyName = companiesDict[company]['name']
        let companyNameValue = companyName === "RUS" ? "Rio Uruguay" : companyName
        history.push("/u/m/alta/compania", {companyName: companyNameValue})
    }

    useEffect(() => {
        setDiscounts(values.descuentos)
    }, [values.descuentos]);

    if (!coveragesArray) return <div
        className="response__tab__body--empty">
        <Loading/></div>
    return (
        <>
            {quotationError
                ? <>
                    <div className="response__tab__body">
                        <QuotationResponseTabError
                            title={"Hubo un problema al comunicarse con el servicio de cotización de la aseguradora."}
                            typeError={cotizacionResponse[companiesDict[company]['name']]['detail']['code']}
                            suggestedActions={["Revisá que la información ingresada sea correcta", "Evitá incluir caracteres especiales", "Reintente el proceso"]}
                            detailedError={cotizacionResponse[companiesDict[company]['name']]['detail']['message']}
                        />
                    </div>
                </>
                : <div className="response__tab__body">

                    {!hasNotSumaAsegurada && cotizacionResponse[companiesDict[company]['name']]['sumaAseguradaVehiculo'] >= valuesMemo['Valor_DIC_Requerido'] &&
                        <UifFormWarn tipoPersona={values['tipoPersona']}/>
                    }

                    {hasCompanyFields && <div className="response__tab__common">
                        <div className="response__tab__common--left">
                            {!hasNotSumaAsegurada &&
                                <p className="response__tab__text">Capital asegurable total:
                                    cotizacionResponse[companiesDict[company]['name']]['sumaAseguradaVehiculo'] &&` $
                                    ${currencyFormat(cotizacionResponse[companiesDict[company]['name']]['sumaAseguradaVehiculo']) || 0}`
                                </p>
                            }
                            {!isPromotional && !hasNotSumaAsegurada &&
                                <TextLink
                                    size={"md"}
                                    action={() => setDisplayConfiguracionAvanzada(!displayConfiguracionAvanzada)}>
                                    Configuración avanzada
                                </TextLink>
                            }
                        </div>
                        <div className="response__tab__common--right">
                            {!isPromotional && userDiscounts[companiesDict[company]['name']] && userDiscounts[companiesDict[company]['name']][activeFormProduct.toUpperCase()]['descuento'] > 0 &&
                                <>
                                    <label className="customSlider__label--discount">Descuento extra</label>
                                    <div className="customSlider__container">
                                        <div className="customSlider__inputContainer">
                                            <Field
                                                name={`descuentos.${companiesDict[company]['name']}`}
                                                max={userDiscounts[companiesDict[company]['name']][activeFormProduct.toUpperCase()]['descuento']}
                                                min={0}
                                                interval={5}
                                                component={CustomSlider}
                                            />
                                        </div>
                                        {companiesDict[company]['descuento'] === "prima" &&
                                            <InformationFilledIcon className="ctrow__icon__info"
                                                                   onClick={() => setOpenInfoModal(true)}/>
                                        }
                                    </div>
                                </>
                            }
                        </div>
                    </div>}
                    {!hasNotSumaAsegurada &&
                        <div
                            className={`response__tab__advancedForm response__tab__advancedForm--${displayConfiguracionAvanzada}`}>
                            <AdvancedFormRenderer
                                tab={companiesDict[company]['name']}
                                submitForm={submitForm}
                                showAdvanceConfig={displayConfiguracionAvanzada}
                                sumAsegurada={!hasNotSumaAsegurada ? cotizacionResponse[companiesDict[company]['name']]['sumaAseguradaVehiculo'] : 0}
                            />
                        </div>}
                    <div className="response__tab__table">
                        {coverageComponent()}
                        {isPromotional &&
                            <div className="response__tab__promotionBlock">
                                <Button size="lg" variant="filled" color="primary" type="button"
                                        onClick={handleRedirectToAltaForm} enabled>SOLICITAR APERTURA</Button>
                            </div>
                        }
                    </div>
                </div>
            }

            {coverageOverviewModalOpen &&
                <CoverageOverviewModal
                    rowData={{company: company, ...rowData}}
                    coveragesBenefits={true}
                    closeAction={() => setCoverageOverviewModalOpen(false)}
                    buttonAction={() => handleAddStep(company, rowData)}
                    resumeKeyConstructor={resumeKeyConstructor}
                />
            }

            {openInfoModal &&
                <QuotationButtonInfoModal setShow={setOpenInfoModal}/>
            }
        </>

    );
}

export default GenericQuotationResponseTab;