import React from 'react';
import './CoveragesInformationPopover.css';
import {InformationModal} from "../../../layouts/InformationModal";

function CoveragesInformationPopover({onClose, title, descripcion}) {
    return (
        <InformationModal
            closeAction={onClose}
            title={"Información de la cobertura"}
            severity={"info"}
            body={
                <div className="covInfoPop__container">
                    <p className="covInfoPop__title">{title}</p>
                    {descripcion.length > 1
                        ? <>
                            <div className="covInfoPop__block">
                                <p className="covInfoPop__text">{descripcion[0]}</p>
                            </div>
                            <div className="covInfoPop__block">
                                <p className="covInfoPop__text">{descripcion[1]}</p>
                            </div>
                        </>
                        : <p className="covInfoPop__text">{descripcion[0]}</p>
                    }
                </div>
            }
        />
    );
}

export default CoveragesInformationPopover;