import React, {useContext, useEffect, useRef, useMemo, useState, useCallback} from 'react';
import {currencyFormat} from "../../../modules/utils/currencyFormat";
import TextLink from "../../../components/TextLink/TextLink";
import {AdvancedFormRenderer} from "../AdvancedFormRenderer";
import {CoverageTableRow} from "../../../components/CoverageTableRow";
import {CustomSlider} from "../../../components/CustomSlider";
import {Field, useFormikContext} from "formik";
import {useCurrentQuotationContext} from "../../../context/CurrentQuotationContext/CurrentQuotationContext";
import './QuotationResponseTab.css';
import {Loading} from "../../../components/Loading";
import useWindowDimensions from "../../../modules/utils/useWindowDimensions";
import {CoverageCard} from "../../../components/CoverageCard";
import UserContext from "../../../context/UserContext";
import {CoverageOverviewModal} from "../CoverageOverviewModal";
import {CarouselWrapper} from "../../../components/CarouselWrapper";
import {isResponseSuccessful} from "../../../modules/utils/isResponseSuccessful";
import CotizarContext from "../../../context/CotizarContext";
import DefaultValuesContext from "../../../context/DefaultValuesContext";
import UifFormWarn from "../UIFFormWarn/UIFFormWarn";
import {todoRiesgoAmountReplacer} from "../../../modules/utils/todoRiesgoAmountReplacer";
import {ReactComponent as InformationFilledIcon} from "../../../assets/icons/ic_info-filled.svg";
import {ReactComponent as HelpFilledIcon} from "../../../assets/icons/ic_help_circle_filled.svg";
import {QuotationButtonInfoModal} from "../QuotationButtonInfoModal";
import isZurichAppliedAgreement from "./utils/Zurich/isZurichAppliedAgreement";
import {ZurichAgreement} from "./utils/Zurich/ZurichAgreement";
import {Button} from "../../../components/Button";
import {Checkbox, Popover} from '@mantine/core';
import {useDisclosure} from "@mantine/hooks";
import RenderError from "./utils/RenderError";
import startEmissionSteps from "./utils/startEmissionSteps";

function QuotationResponseTab(props) {
    const {
        company,
        displayConfiguracionAvanzada,
        setDisplayConfiguracionAvanzada,
        submitForm,
        addCoverageToCart,
        showPopup,
        onEmitirClick
    } = props;
    const {
        setDiscounts,
        cotizacionResponse,
        setCurrentFormData,
        formData,
        setUpdatingForm,
        finalValues
    } = useCurrentQuotationContext()
    const {isMobile, userDiscounts, activeFormProduct, userCompaniesToPromote} = useContext(UserContext);
    const {companiesDict, coberturasProductsInfo} = useContext(CotizarContext);
    const {valuesMemo} = useContext(DefaultValuesContext)
    const {width} = useWindowDimensions();
    const {values, setFieldValue} = useFormikContext();
    const [coverageOverviewModalOpen, setCoverageOverviewModalOpen] = useState(false);
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [rowData, setRowData] = useState(null);
    const convenioZurichApplied = isZurichAppliedAgreement(company, values.anio, values.marca);
    const isPromotional = userCompaniesToPromote.includes(companiesDict[company].name)
    const [opened, {close, open, toggle}] = useDisclosure(false);
    const antiguaty = new Date().getFullYear() - parseInt(values?.vehiculo?.anio) + 1;
    const hasSancorExtraDiscount = company === 1 && activeFormProduct === 'automotor' && antiguaty <= 10;
    const hasFedPatExtraDiscount = company === 5 && activeFormProduct === 'automotor' && antiguaty <= 10;
    const [showRecotizarButton, setShowRecotizarButton] = useState(false);
    const [activeRecotizarButton, setActiveRecotizarButton] = useState(false);
    const advancedConfigRef = useRef(null)
    const hasDIC = useMemo(() => companiesDict[company].products.find(product => product.key === activeFormProduct).hasDeclaracionJurada, [company]);
    const showGnc = companiesDict[companiesDict[company]['name']].equipoGncId && companiesDict[companiesDict[company]['name']].bienesAdicionales

    const moveToAdvancedConfig = () => {
        if (advancedConfigRef.current) {
            advancedConfigRef.current.scrollIntoView({block: "center", behavior: "smooth"})
            setDisplayConfiguracionAvanzada(true)
        }
    }

    const {resumeKeyConstructor} = useCurrentQuotationContext();
    const keyMapping = ["sumaAseguradaAdicional", "sumaAseguradaGnc"]
    const capitalTotal = useCallback(() => {
        if (!cotizacionResponse || !cotizacionResponse[companiesDict[company]['name']])
            return 0
        return keyMapping.reduce((acc, key) => {
            if (values[key] && (key !== "sumaAseguradaGnc" || (key === "sumaAseguradaGnc" && values['tieneGnc'] !== "0"))) return acc + values[key]
            else return acc
        }, 0) + parseInt(cotizacionResponse[companiesDict[company]['name']]['sumaAseguradaVehiculo'])
    }, [cotizacionResponse, company, values])

    //Replicate changes applied to this function into CoverageComparison.js
    const handleAddStep = (companyId, rd) => {
        setFieldValue("company", companiesDict[companyId]['name'])
        setFieldValue("companyId", companyId)
        setFieldValue("nombreCobertura", todoRiesgoAmountReplacer(
            companiesDict[company]['name'],
            coberturasProductsInfo[activeFormProduct],
            rd
        ))
        setFieldValue("rowData", rd)

        if (onEmitirClick && onEmitirClick(rd)) {
            return;
        }

        startEmissionSteps(companyId, companiesDict, setCurrentFormData, formData, setUpdatingForm)
    }

    const handleOpenCoverageOverviewModal = (rowDataReceived) => {
        setRowData(rowDataReceived);
        setCoverageOverviewModalOpen(true)
    }

    const getRowData = (_cobertura) => {
        return {
            sumaAseguradaVehiculo: cotizacionResponse[companiesDict[company]['name']]['sumaAseguradaVehiculo'],
            nombreModelo: values.detalle_version,
            marca: values.detalle_marca,
            anio: values.anio,
            //parametrosAdicionales are added here. If a transformation is needed, work it here.
            ..._cobertura,
            nombre: todoRiesgoAmountReplacer(
                companiesDict[company]['name'],
                coberturasProductsInfo[activeFormProduct],
                _cobertura
            ),
            franquicia: _cobertura.franquicia
        }
    }
    const coverageDisplayName = (_company, _coverage) => {
        if (coberturasProductsInfo[activeFormProduct][_company][_coverage.id.toString()]) {
            return todoRiesgoAmountReplacer(
                companiesDict[company]['name'],
                coberturasProductsInfo[activeFormProduct],
                _coverage
            )
        } else if (_coverage.nombre) {
            return _coverage.nombre
        } else {
            return _coverage.id
        }
    }


    const coverageComponent = () => {
        if (isMobile) {
            return (<div className="coveragesCard__carousel" style={{width: `${width * 0.85 - 16 - 32}px`}}>
                {/*    width * 0.85 (formCard width - 16 formCard padding - 32 body padding)*/}
                <CarouselWrapper slideSize={176}>
                    {cotizacionResponse[companiesDict[company]['name']]['coberturas'].map(cobertura => (
                        <CoverageCard
                            tabId={company}
                            tab={companiesDict[company]['name']}
                            companyName={companiesDict[company]['name']}
                            rowData={getRowData(cobertura)}
                            coverageName={coverageDisplayName(companiesDict[company]['name'], cobertura)}
                            onEmitirClic={handleAddStep}
                            onCompararClic={addCoverageToCart}
                            handleOpenCoverageOverviewModal={handleOpenCoverageOverviewModal}
                        />
                    ))}
                </CarouselWrapper>
            </div>)
        } else {
            return cotizacionResponse[companiesDict[company]['name']]['coberturas'].map(cobertura => (
                <CoverageTableRow
                    addCoverageToCart={addCoverageToCart}
                    rowData={getRowData(cobertura)}
                    tab={companiesDict[company]['name']}
                    tabId={company}
                    handleAddStep={handleAddStep}
                    coverageName={coverageDisplayName(companiesDict[company]['name'], cobertura)}
                    handleOpenCoverageOverviewModal={handleOpenCoverageOverviewModal}
                    appliedZurichAgreement={convenioZurichApplied}
                />
            ))
        }
    }

    const handleRedirectToAltaForm = () => {
        window.open(`${process.env.REACT_APP_URL}u/m/alta/compania`, '_blank');
    }

    useEffect(() => {
        setDiscounts(values.descuentos)
    }, [values.descuentos]);

    if (!cotizacionResponse || !cotizacionResponse[companiesDict[company]['name']]) return <div
        className="response__tab__body--empty">
        <Loading/></div>
    return (
        <>
            {!isResponseSuccessful(cotizacionResponse[companiesDict[company]['name']]['status_code'])
                ?
                <RenderError code={cotizacionResponse[companiesDict[company]['name']]['detail']['code']} compania={companiesDict[company]['name']} message={cotizacionResponse[companiesDict[company]['name']]['detail']['message']}/>
                : <div className="response__tab__body">

                    {hasDIC && cotizacionResponse[companiesDict[company]['name']]['sumaAseguradaVehiculo'] >= valuesMemo['Valor_DIC_Requerido'] &&
                        <UifFormWarn tipoPersona={values['tipoPersona']} companyId={company}/>
                    }

                    {convenioZurichApplied &&
                        <ZurichAgreement/>
                    }
                    <div className="response__tab__common">
                        <div className="response__tab__common--left">
                            <p className="response__tab__text">Suma Asegurada del Vehículo:
                                {cotizacionResponse[companiesDict[company]['name']]['sumaAseguradaVehiculo']
                                    ? ` $ ${currencyFormat(cotizacionResponse[companiesDict[company]['name']]['sumaAseguradaVehiculo'])}`
                                    : ` $ 0`
                                }
                            </p>
                            {showGnc && <p className="response__tab__text">Capital asegurable total:
                                ${currencyFormat(capitalTotal()) || 0}
                            </p>}
                            {!isPromotional &&
                                <TextLink
                                    refElement={advancedConfigRef}
                                    size={"md"}
                                    action={() => setDisplayConfiguracionAvanzada(!displayConfiguracionAvanzada)}>
                                    Configuración avanzada
                                </TextLink>
                            }

                        </div>
                        <div className="response__tab__common--right">
                            {!isPromotional && userDiscounts[companiesDict[company]['name']][activeFormProduct.toUpperCase()]['general'] > 0 &&
                                <>
                                    <div className="customSlider__container">
                                        <label className="customSlider__label--discount">Descuento extra</label>
                                        <div className="customSlider__inputContainer">
                                            <Field
                                                name={`descuentos.${companiesDict[company]['name']}`}
                                                max={userDiscounts[companiesDict[company]['name']][activeFormProduct.toUpperCase()]['general']}
                                                min={0}
                                                interval={5}
                                                component={CustomSlider}
                                            />
                                        </div>
                                        {companiesDict[company]['descuento'] === "prima" &&
                                            <InformationFilledIcon className="ctrow__icon__info"
                                                                   onClick={() => setOpenInfoModal(true)}/>
                                        }
                                    </div>
                                </>
                            }
                            {hasSancorExtraDiscount &&
                                <Popover position="bottom" withArrow opened={opened} width="target">
                                    <>
                                        <Popover.Target>
                                            <div onMouseEnter={open} onMouseLeave={close}
                                                 className="response__tab__discountCheckbox">
                                                <Checkbox size="xs" checked onChange={() => {
                                                }} label={<p onClick={() => {
                                                    if (isMobile)
                                                        return toggle()
                                                }} className="response__tab__discountCheckboxLabel">Promo 20%
                                                    OFF <HelpFilledIcon
                                                        className="response__tab__discountCheckboxHelpIcon"/></p>}
                                                          classNames={{
                                                              input: "response__tab__discountCheckboxInput",
                                                              body: "response__tab__discountCheckboxBody"
                                                          }}/>
                                            </div>
                                        </Popover.Target>
                                        <Popover.Dropdown>
                                            <p className="response__tab__discountCheckboxPopover">Descuento del 20%
                                                aplicado
                                                en los tres primeros meses de facturación a vehículos de hasta 10 años
                                                de
                                                antigüedad.</p>
                                        </Popover.Dropdown>
                                    </>
                                </Popover>
                            }

                            {hasFedPatExtraDiscount && !isPromotional &&
                                <div className="response__tab__discountCheckboxContainer --labelMargin">
                                    <div onMouseEnter={open} onMouseLeave={close}
                                         className="response__tab__discountCheckbox">
                                        <Checkbox
                                            checked={values.configuracionAvanzada.Federacion_Patronal.applyDiscount}
                                            onChange={(e) => {
                                                if (e.target.checked !== finalValues.configuracionAvanzada.Federacion_Patronal.applyDiscount) {
                                                    setShowRecotizarButton(true)
                                                    setActiveRecotizarButton(true)
                                                } else {
                                                    setShowRecotizarButton(false)
                                                    setActiveRecotizarButton(false)
                                                }
                                                setFieldValue(`configuracionAvanzada.Federacion_Patronal.applyDiscount`, e.target.checked)
                                                if (e.target.checked) setFieldValue(`configuracionAvanzada.Federacion_Patronal.descuentoComision`, "-5")
                                                else setFieldValue(`configuracionAvanzada.Federacion_Patronal.descuentoComision`, "0")
                                            }}
                                            label={
                                                <>
                                                    <p onClick={() => {
                                                        if (isMobile)
                                                            return toggle()
                                                    }} className="response__tab__discountCheckboxLabel">
                                                        Descuento cliente nuevo
                                                    </p>
                                                    <InformationFilledIcon className="advancedForm__icon__info"
                                                                           onClick={(e) => {
                                                                               e.preventDefault()
                                                                               showPopup(true)
                                                                           }}/>
                                                </>
                                            }
                                            className="response__tab__discountCheckboxLabel"
                                            size="xs"
                                            classNames={{
                                                input: "response__tab__discountCheckboxInput",
                                                label: "response__tab__discountCheckboxLabel",
                                                body: "response__tab__discountCheckboxBody"
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div
                        className={`response__tab__advancedForm response__tab__advancedForm--${displayConfiguracionAvanzada}`}>
                        <AdvancedFormRenderer
                            tab={companiesDict[company]['name']}
                            submitForm={submitForm}
                            showAdvanceConfig={displayConfiguracionAvanzada}
                            sumAsegurada={cotizacionResponse[companiesDict[company]['name']]['sumaAseguradaVehiculo']}
                            showRecotizarButton={showRecotizarButton}
                            activeRecotizarButton={activeRecotizarButton}
                        />
                    </div>
                    <div className="response__tab__table">
                        {coverageComponent()}
                    </div>
                        {!isPromotional &&
                            <p className="automotor__cotSuccess__legendText">
                                Para personalizar aún más tu cotización, revisá <span
                                onClick={moveToAdvancedConfig} className="--highlight">configuración avanzada</span>.
                            </p>
                        }
                        {isPromotional &&
                            <div className="response__tab__promotionBlock">
                                <Button size="lg" variant="filled" color="primary" type="button"
                                        onClick={handleRedirectToAltaForm} enabled>SOLICITAR CODIGO</Button>
                            </div>
                        }
                </div>
            }

            {coverageOverviewModalOpen &&
                <CoverageOverviewModal
                    rowData={{company: company, ...rowData}}
                    closeAction={() => setCoverageOverviewModalOpen(false)}
                    buttonAction={() => handleAddStep(company, rowData)}
                    resumeKeyConstructor={resumeKeyConstructor}
                />
            }

            {openInfoModal &&
                <QuotationButtonInfoModal setShow={setOpenInfoModal}/>
            }
        </>

    );
}

export default QuotationResponseTab;