import React, {useState, useEffect, useContext} from 'react';
import formModel from "./FormModel/profileFormModel";
import validationSchema from './FormModel/validationSchema';
import FormContent from './Forms/FormContent';
import {Formik, Form} from "formik";
import {useHistory} from "react-router-dom";
import {gql, useMutation} from "@apollo/client";
import UserContext from "../../../context/UserContext";
import {Button} from "../../../components/Button";
import FormInitialValues from "./FormModel/formInitialValues";
import "./ProfileForm.css";
import axios from "axios";
import {SuccessModal} from "../../../layouts/SuccessModal";
import {ErrorModal} from "../../../layouts/ErrorModal";
import AuthContext from "../../../context/AuthContext";

const {formId, formField} = formModel;

const UPDATE_USER_DATA = gql`
    mutation MyMutation($_id_auth: String, $Apellido1: String, $Direccion1: String, $Dni: Int, $Nacionalidad1: String, $Email: String, $Nombre1: String, $Ocupacion1: String, $Pais: String, $Sexo1: String, $Telefono1: String, $c_localidad1: Int, $c_postal1: Int, $cuit_cuil: numeric, $cvu_cbu: String, $fecha_nacimiento: String, $lugar_nacimiento: String, $matricula: Int, $situacion_iva: String) {
      update_DatosUsuarios_datos_polkista(where: {id_auth: {_eq: $_id_auth}}, _set: {Apellido: $Apellido1, Direccion: $Direccion1, Dni: $Dni, Nacionalidad: $Nacionalidad1, Email: $Email, Nombre: $Nombre1, Ocupacion: $Ocupacion1, Pais: $Pais, Sexo: $Sexo1, Telefono: $Telefono1, c_localidad: $c_localidad1, c_postal: $c_postal1, cuit_cuil: $cuit_cuil, cvu_cbu: $cvu_cbu, fecha_nacimiento: $fecha_nacimiento, lugar_nacimiento: $lugar_nacimiento, matricula: $matricula, situacion_iva: $situacion_iva}) {
        affected_rows
      }
    }
`;

export default function ProfileForm(props) {
    const {setUserImage, newImageData, newImageUrl} = props;
    const {
        isMobile,
        userIdAuth,
        userPicture,
        setUserPicture,
        handleModified,
    } = useContext(UserContext);
    const {authToken} = useContext(AuthContext);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openWarning, setOpenWarning] = useState(false);
    const [submittedImage, setSubmittedImage] = useState(null);
    const [errorModalContent, setErrorModalContent] = useState(null);
    const [isDirty, setIsDirty] = useState(false);
    const history = useHistory();

    const [updateUserData] = useMutation(UPDATE_USER_DATA);

    const {
        nombre, apellido, DNI, CUIT, nacimiento, l_nacimiento, nacionalidad, email, telefono, sexo, ocupacion, IVA,
        matricula, direccion, CBU, codigosLocalidad, codigoPostal, idProvincia
    } = formField;

    const getUserInfo = () => {
        setUserImage(userPicture)
    };

    const handleUpload = async () => {
        // TODO: Hay que cambiar el endpoint del back.
        return axios.post(process.env.REACT_APP_BACK_SERVER + '/subirImagen', newImageData, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        })
    };

    async function _submitForm(values, actions) {
        try {
            let requests = [];
            if (isDirty){
                const updateUserDataPromise =
                    updateUserData({
                    variables: {
                        "_id_auth": userIdAuth,
                        "Apellido1": values[apellido.name],
                        "Direccion1": values[direccion.name],
                        "Dni": values[DNI.name],
                        "Nacionalidad1": values[nacionalidad.name],
                        "Nombre1": values[nombre.name],
                        "Ocupacion1": values[ocupacion.name],
                        // "Pais": "",
                        "Sexo1": values[sexo.name],
                        "Telefono1": values[telefono.name],
                        "c_localidad1": parseInt(values[codigosLocalidad.name]),
                        "c_postal1": parseInt(values[codigoPostal.name]),
                        "cuit_cuil": values[CUIT.name],
                        "cvu_cbu": values[CBU.name],
                        "fecha_nacimiento": values[nacimiento.name],
                        "matricula": values[matricula.name] === '' ? null : parseInt(values[matricula.name]),
                        "lugar_nacimiento": values[l_nacimiento.name],
                        "situacion_iva": values[IVA.name],
                        "Email": values[email.name],
                    }
                });
                requests.push(updateUserDataPromise);
            }

            if (newImageData) {
                requests.push(handleUpload());
            }

            const responses = await Promise.all(requests);

            setOpenSuccess(true);
            if (newImageData) {
                setUserImage(responses[responses.length - 1].data);
                setSubmittedImage(newImageData);
                setUserPicture(newImageUrl);
            }

            //Update UserContext
            handleModified()
        } catch (err) {
            setErrorModalContent({
                title: "Ocurrió un error al actualizar sus datos.",
                typeError: "Ha ocurrido un error en la comunicación con el servicio",
                suggestedActions: ["Reintentá el proceso una vez más", "Si el problema persiste ponete en contacto con el área de soporte"],
                detailedError: err || "Sin datos del error",
            })
            setOpenWarning(true);
        }

        actions.setSubmitting(false);
    }

    useEffect(() => {
        getUserInfo();
    }, []);

    const handleEnterKey = (e, values, isDirty) => {
        if (e.key === 'Enter' && !isDirty) {
            e.preventDefault();
        }
    }

    return (
        <div>
            <Formik
                initialValues={FormInitialValues()}
                validationSchema={validationSchema}
                validateOnBlur={false}
                onSubmit={_submitForm}
            >
                {({isSubmitting, values, dirty}) =>

                    (
                    <Form id={formId} onKeyDown={(e) => handleEnterKey(e, values, dirty)}>
                        <FormContent formField={formField}/>
                        <div className="PForm__buttonsContainer">
                            {/*TODO: Hacer una variante de Button que sea el del figma*/}
                            <Button enabled variant="outlined" color="primary"
                                    onClick={() => history.push('/u/dashboard')} type="button">
                                Cancelar
                            </Button>
                            <div className="PForm__submitContainer">
                                <Button
                                    enabled={dirty || newImageData !== submittedImage}
                                    type="submit"
                                    variant="filled"
                                    color="primary"
                                    loading={isSubmitting}
                                    onClick={() => setIsDirty(dirty)}
                                >
                                    {isMobile ? "Guardar" : "Guardar Cambios"}
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            {openSuccess &&
                <SuccessModal
                    title={"Se actualizaron sus datos correctamente."}
                    closeAction={() => {
                        setOpenSuccess(false)
                    }}/>
            }
            {openWarning && errorModalContent &&
                <ErrorModal
                    title={errorModalContent.title}
                    typeError={errorModalContent.typeError}
                    suggestedActions={errorModalContent.suggestedActions}
                    detailedError={errorModalContent.detailedError}
                    closeAction={() => setOpenWarning(false)}/>
            }
        </div>
    )
}
