import {BoxDialog} from "../../../components/BoxDialog";
import "./ProfileDropdown.css"
import React, {useContext} from "react";
import UserContext from "../../../context/UserContext";
import {Button} from "../../../components/Button";
import {useAuth0} from "@auth0/auth0-react";
import {useHistory} from 'react-router-dom';
import AuthContext from "../../../context/AuthContext";

function ProfileDropdown(props) {
    const {userName, userLastName, userMail, isMobile, masterName, masterLastName, personalRanking} = useContext(UserContext);
    const {setDisplay} = props;
    const {isMaster} = useContext(AuthContext);
    const {logout} = useAuth0();
    const history = useHistory();

    function nameFormat(names) {
        const result = names.split(' ');
        if (result.length > 1) {
            const secondName = result[1].charAt(0);
            return `${result[0]} ${secondName}.`;
        }
        return names
    }

    const masterFullName = `${nameFormat(masterName)} ${masterLastName}`;

    const handleLogout = () => {
        sessionStorage.clear();
        localStorage.removeItem('companiesByProduct');
        logout({returnTo: window.location.origin})
    }


    return (
        <div className="PDropdown__boxContainer">
            <BoxDialog withArrow={!isMobile} setDisplay={setDisplay}>
                <div className="PDropdown__container">
                    <div className="PDropdown__contentContainer">
                        <div className="PDropdown__rowInfo">
                            <p className="PDropdown__label">Nombre</p>
                            <p className="PDropdown__label PDropdown__label--userInfo">{userName} {userLastName}</p>
                        </div>
                        <div className="PDropdown__rowInfo">
                            <p className="PDropdown__label">Email</p>
                            <p className="PDropdown__label PDropdown__label--userInfo">{userMail}</p>
                        </div>
                        <div className="PDropdown__rowInfo">
                            <p className="PDropdown__label">Usuario</p>
                            <p className="PDropdown__label PDropdown__label--userInfo">{isMaster ? "MASTER" : "POLKISTA"}</p>
                        </div>
                        {!isMaster &&
                            <div className="PDropdown__rowInfo">
                                <p className="PDropdown__label">Master</p>
                                <p className="PDropdown__rankingMasterName">{masterFullName}</p>
                            </div>
                        }
                        <div className="PDropdown__rowInfo PDropdown__rowInfo--ranking">
                            <p className="PDropdown__label PDropdown__rankingLabel">Ranking</p>
                            <div className="PDropdown__rankingContainer">
                                <span className="PDropdown__rankingUsername">
                                    {`${userName} ${userLastName}`}
                                </span>
                                <span className="PDropdown__rankingPosition">
                                    {personalRanking ? personalRanking['Posicion'] : "-"}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="PDropdown__buttonsContainer">
                        <Button size={isMobile ? "lg" : "md"} enabled color="primary" variant="filled" onClick={() => {
                            history.push("/u/perfil")
                            setDisplay(false)
                        }}>
                            Editar perfil
                        </Button>
                        <p className="PDropdown__label PDropdown__label--userInfo PDropdown__logoutButton"
                           onClick={handleLogout}>Cerrar sesión</p>
                    </div>
                </div>
            </BoxDialog>
        </div>
    );
}

export default ProfileDropdown;