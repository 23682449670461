import React from 'react';
import './WappFAB.css';
import WappColoredIcon from '../../assets/icons/wapp_colored.png'

const sendWapp = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

function WappFab(props) {
    const {url} = props;
    return (
        <div className="whatsapp__fab__container">
            <img onClick={() => sendWapp(url)} className="whatsapp__fab__icon" src={WappColoredIcon} alt="Wapp"/>
        </div>
    );
}

export default WappFab;