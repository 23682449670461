import React from 'react';
import './PopUpCardModal.css';
import {PopUpCard} from "../../components/PopUpCard";
import {ReactComponent as CloseIcon} from "../../assets/icons/ic_close.svg";
import {ReactComponent as ArrowLeftIcon} from "../../assets/icons/ic_arrow_left.svg";
import {CustomModal} from "../../components/CustomModal";

function PopUpCardModal(props) {
    const {body, title, closeAction, back, footer, fullSpace, autoWidth, withDivider} = props;
    return (
        <CustomModal>
            <PopUpCard fullSpace={fullSpace} autoWidth={autoWidth}>
                <div className="pcmodal__container">

                    <div className="pcmodal__header">
                        <div className="pcmodal__icon__container" onClick={closeAction}>
                            <CloseIcon className="pcmodal__icon"/>
                        </div>
                        {back ? <button className="pcmodal__back__container" onClick={() => back()}><ArrowLeftIcon className="pcmodal__icon"/></button> : ""}
                        <p className="pcmodal__title">{title}</p>
                        {withDivider && <hr/>}
                    </div>

                    <div className="pcmodal__body">
                        {body}
                    </div>

                    <div className="pcmodal__footer">
                        {footer}
                    </div>
                </div>
            </PopUpCard>
        </CustomModal>
    );
}

export default PopUpCardModal;