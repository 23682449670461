import React, {useContext, useState} from 'react';
import './RankingCard.css'
import {CollapsibleBox} from "../../../components/CollapsibleBox";
import UserContext from "../../../context/UserContext";
import {capitalizeString} from "../../../modules/utils/stringFormat";
import DashboardContext from "../../../context/DashboardContext";
import {Ranking} from "./Ranking";
import {Summary} from "./Summary";
import '../DashboardStatistics/DashboardStatistics.css'

function RankingCard(props) {
    const {collapse, setCollapse} = props;
    const {isMobile} = useContext(UserContext);
    const {rankingEmisores, beneficiosDisponibles} = useContext(DashboardContext);

    const summaryItems = [
        {
            name: "Puesto",
            value: rankingEmisores.Personal.Posicion,
            clickable: false,
        },
        {
            name: "Emisiones",
            value: rankingEmisores.Personal.Puntaje,
            clickable: false,
        },
        ...(beneficiosDisponibles.length > 0 ? [{
            name: "Campaña",
            value: beneficiosDisponibles.length,
            clickable: true,
        }] : []),
    ];

    function nameFormat(names) {
        const result = names.split(' ');
        if (result.length > 1) {
            const secondName = result[1].charAt(0);
            return `${result[0]} ${secondName}.`;
        }
        return names
    }

    return (
        <>
            <CollapsibleBox
                collapse={collapse}
                header={
                    collapse ?
                        <>
                            <div className="DStatistics__container--header-title">Ranking</div>
                            <div className="Ranking__collapseResume">
                                <span className="Ranking__collapseEmissions">
                                    {rankingEmisores.Personal?.Posicion ?? "-"}
                                </span>
                                <span className="Ranking__collapseUsername">
                                    {capitalizeString(nameFormat(rankingEmisores['Personal'].Nombre))}
                                    {" "}
                                    {capitalizeString(rankingEmisores['Personal'].Apellido)}
                                </span>
                            </div>
                        </>
                        :
                        <div className='RankingCard__header'>
                            <div className='RankingCard__title'>Resumen</div>
                        </div>
                }
                content={
                    <div className="RankingCard__container">
                        <Summary summaryItems={summaryItems} beneficiosDisponibles={beneficiosDisponibles}/>
                        <Ranking rankingEmisores={rankingEmisores} />
                    </div>
                }
                withButton={isMobile} setCollapse={setCollapse}
            />
        </>
    );
}

export default RankingCard;