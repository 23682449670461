import React, {createRef, useContext, useState} from 'react';
import {Checkbox, Divider, Grid} from "@mantine/core";
import {useFormikContext} from "formik";
import {InformacionModel} from "./InformacionModel";
import {MantineDateField} from "../../../../../components/FormFields/MantineDateField";
import MantineDependantField from "../../../../../components/FormFields/MantineDependantField/MantineDependantField";
import {MantineSelectField} from "../../../../../components/FormFields/MantineSelectField";
import {MantineInputField} from "../../../../../components/FormFields/MantineInputField";
import PhoneInput from "react-phone-number-input";
import {MantineInputRefField} from "../../../../../components/FormFields/MantineInputRefField";
import {CuitGenerator} from "../../../../../modules/utils/cuitGenerator";
import TextLink from "../../../../../components/TextLink/TextLink";
import { ZipCodeFieldPackage } from '../../../../Cotizacion/ZipCodeFieldPackage';

const ocupaciones = [
    {value: 'Contador', label: 'Contador'},
    {value: 'Ingeniero', label: 'Ingeniero'},
    {value: 'Abogado', label: 'Abogado'},
    {value: 'Medico', label: 'Medico'},
    {value: 'Productor_de_Seguros', label: 'Productor de Seguros'},
    {value: 'Mandatario_del_Automotor', label: 'Mandatario del Automotor'},
    {value: 'Corredor_inmobiliario', label: 'Corredor inmobiliario'},
    {value: 'Estudiante', label: 'Estudiante'},
    {value: 'Otro', label: 'Otro'}
];

const situacionesIva = [
    {value: 'Monotributista', label: 'Monotributista'},
    {value: 'Responsable_Inscripto', label: 'Responsable Inscripto'},
    {value: 'Sujeto_no_registrado', label: 'Sujeto no registrado'},
    {value: 'Consumidor_final', label: 'Consumidor final'},
];

const sexos = [
    {value: 'hombre', label: 'Hombre'},
    {value: 'mujer', label: 'Mujer'},
    {value: 'otro', label: 'Otro'},
    {value: 'no_contesta', label: 'Prefiero no contestar'}
];

export default function InformacionForm(props) {
    const {setTycModalOpen} = props;
    const {formField} = InformacionModel();
    const {
        tycAccepted,
        DNI,
        apellido,
        nombre,
        sexo,
        calle, numeroCalle, opcional,
        telefono,
        CUIT,
        nacimiento,
        l_nacimiento,
        cbu,
        IVA,
        matricula,
        nacionalidad,
        ocupacion,
        codigoPostal,
        provincia,
        localidad,
    } = formField;
    const {values, setFieldValue} = useFormikContext();
    const ref = createRef();

    const calculateCuit = () => {
        if (values.DNI && values.DNI.length === 8 && values.sexo !== '' && values.sexo !== 'no_contesta' && values.sexo !== 'otro') {
            return CuitGenerator(values.DNI, values.sexo)
        }
    }

    const [localidadOptions, setLocalidadOptions] = useState([]);
    const [provinciasOptions, setProvinciasOptions] = useState([]);

    return (
        <div >
            <Grid gutter="md" justify="center" align="center">
                <Grid.Col xs={12} sm={6}>
                    <MantineInputField
                        name={nombre.name}
                        label={nombre.label}
                        withAsterisk
                    />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <MantineInputField
                        name={apellido.name}
                        label={apellido.label}
                        withAsterisk
                    />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <MantineInputField
                        name={DNI.name}
                        label={DNI.label}
                        withAsterisk
                    />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <MantineSelectField
                        name={sexo.name}
                        label={sexo.label}
                        data={sexos}
                        withAsterisk
                    />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <MantineDependantField
                        test={CUIT}
                        dependantFieldName={CUIT.name}
                        dependantFieldLabel={CUIT.label}
                        dependencies={[values.DNI, values.sexo]}
                        calculateValueFunction={calculateCuit}
                        formatDisplay={(v) => v}
                        withAsterisk
                    />
                </Grid.Col>

                <Grid.Col xs={12} sm={6}>
                    <PhoneInput
                        defaultCountry="AR"
                        ref={ref}
                        inputComponent={MantineInputRefField}
                        name={telefono.name}
                        label={telefono.label}
                        value={values[telefono.name]}
                        onChange={(e) => {
                            /** @namespace e.length **/
                            if (e && e.length > 12) {
                                setFieldValue(`${telefono.name}`, e)
                            }
                        }}
                        withAsterisk
                    />
                </Grid.Col>


                <Grid.Col xs={12}>
                    <Divider size="xs" style={{margin:"16px 8px"}}/>
                </Grid.Col>

                <Grid.Col xs={12} sm={6}>
                    <MantineInputField
                        name={nacionalidad.name}
                        label={nacionalidad.label}
                        withAsterisk
                    />
                </Grid.Col>

                <Grid.Col xs={12} sm={6}>
                    <MantineInputField
                        name={l_nacimiento.name}
                        label={l_nacimiento.label}
                        withAsterisk
                    />
                </Grid.Col>
                <>
                    <ZipCodeFieldPackage localidadField={localidad} localidadSize={6}
                                         codigoPostalField={codigoPostal} codigoPostalSize={6}
                                         provinciaField={provincia} provinciaSize={6}
                                         localidadOptions={localidadOptions || []} setLocalidadOptions={setLocalidadOptions}
                                         provinciasOptions={provinciasOptions}
                                         setProvinciaOptions={setProvinciasOptions}
                    />
                </>
                <Grid.Col xs={12} sm={6}>
                    <MantineInputField
                        name={calle.name}
                        label={calle.label}
                        withAsterisk
                    />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <MantineInputField
                        name={numeroCalle.name}
                        label={numeroCalle.label}
                        withAsterisk
                    />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <MantineInputField
                        name={opcional.name}
                        label={opcional.label}
                    />
                </Grid.Col>


                <Grid.Col xs={12} sm={6}>
                    <MantineDateField
                        name={nacimiento.name}
                        label={nacimiento.label}
                        withAsterisk
                    />
                </Grid.Col>


                <Grid.Col sm={6}/>

                <Grid.Col xs={12}>
                    <Divider size="xs" style={{margin:"16px 8px"}}/>
                </Grid.Col>


                <Grid.Col xs={12} sm={6}>
                    <MantineSelectField
                        name={ocupacion.name}
                        label={ocupacion.label}
                        data={ocupaciones}
                        withAsterisk
                    />
                </Grid.Col>

                {values.ocupacion === 'Productor_de_Seguros' && (
                    <Grid.Col xs={12} sm={6}>
                        <MantineInputField
                            name={matricula.name}
                            label={matricula.label}
                            withAsterisk
                        />
                    </Grid.Col>
                )}

                <Grid.Col xs={12} sm={6}>
                    <MantineSelectField
                        name={IVA.name}
                        label={IVA.label}
                        data={situacionesIva}
                        withAsterisk
                    />
                </Grid.Col>

                <Grid.Col xs={12} sm={6}>
                    <MantineInputField
                        name={cbu.name}
                        label={cbu.label}
                    />
                </Grid.Col>

                <Grid.Col sm={6}/>

                <div style={{marginTop: "16px"}}>

                </div>
                <Grid.Col xs={12} sm={6}>
                    <div className="registroUsuario__tyc__container">
                        <TextLink
                            size={"md"}
                            action={() => {
                                setTycModalOpen(true)}
                            }>
                            Ver términos y condiciones
                        </TextLink>
                    </div>
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <div className="registroUsuario__tyc__container">
                    <Checkbox
                        name={tycAccepted.name}
                        label={tycAccepted.label}
                        checked={values.tycAccepted}
                        classNames={{
                            input: "autem__inspeccion__checkbox"
                        }}
                        onChange={(e) => setFieldValue("tycAccepted", e.currentTarget.checked)}
                    />
                    </div>
                </Grid.Col>
            </Grid>

        </div>
    );
}