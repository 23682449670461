import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Grid, Typography} from '@material-ui/core';
import {InputField} from '../../../../components/FormFields';
import {useFormikContext} from 'formik'
import Divider from '@material-ui/core/Divider';
import PopOverCustom from "../../../../components/PopOver";
import Loading from "../../../../components/Loading";
import {currencyFormat} from "../../../../utils/currencyFormat";
import useStyles from '../styles';
import CotizarContext from "../../../../../context/CotizarContext";

export default function CoberturasForm(props) {
    const classes = useStyles();
    const {
        formField: {
            comentariosSolicitud,
        }
    } = props;
    const {values} = useFormikContext();
    const {coberturasInfo} = useContext(CotizarContext);
    const [dictCoberturas, setDictCoberturas] = useState(null);

    const viviendasDict =  {
        '1021': 'Casa de ocupación permanente',
        '1022': 'Departamento de ocupación permanente',
        '1023': 'Vivienda en barrio cerrado',
        '1024': 'Vivienda/Departamento de ocupación transitoria',
    };

    useEffect(() => {
        values.coberturas.sort((a, b) => parseInt(a.numeroCobertura) - parseInt(b.numeroCobertura));
        setDictCoberturas(coberturasInfo)
    }, []);

    if (!dictCoberturas) return <Loading/>;
    return (
        <React.Fragment>
            <Grid container spacing={3} direction="column">
                <Grid item container spacing={1} direction="column">
                    <Grid item container spacing={2} direction='row'>
                        <Grid item xs={7}>
                            <Typography className={classes.tableHeaderCoberturas}>Cobertura</Typography>
                        </Grid>
                        <Grid item xs={1}/>
                        <Grid item xs={4}>
                            <Typography className={classes.tableHeaderCoberturas}>Suma Asegurada</Typography>
                        </Grid>
                    </Grid>
                    {values.coberturas.map((item, index) => (
                        <Fragment>
                            <Grid key={index} item container spacing={2} direction='row' alignItems='center'>
                                <Grid item xs={7}>
                                    <Typography className={classes.tableRowLabel}>
                                        {dictCoberturas[item.numeroCobertura].descripcion}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} className={classes.centerContent}>
                                    <PopOverCustom
                                        descripcion={dictCoberturas[item.numeroCobertura].descripcionCompleta}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography className={classes.tableRowLabel}>${currencyFormat(item.sumaAsegurada)}</Typography>
                                </Grid>
                            </Grid>
                            <Divider light/>
                        </Fragment>
                    ))}
                </Grid>
                <Grid item container spacing={2} direction="column">
                    <Grid item container direction="row" spacing={1} justify='center' alignItems='baseline'>
                        <Grid item>
                            <Typography className={classes.tableHeaderCoberturas}>Tipo de vivienda:</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.dataText}>{viviendasDict[values.tipoVivienda]}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container direction="row" spacing={1} justify='center' alignItems='baseline'>
                        <Grid item>
                            <Typography className={classes.tableHeaderCoberturas}>Descuento:</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.dataText}>{values.descuentoEspecial}%</Typography>
                        </Grid>
                    </Grid>
                    <div className={classes.boxPrice}>
                        <Typography className={classes.priceTitle}>Cuota mensual:</Typography>
                        <Typography className={classes.priceText}>$ {values.premium}</Typography>
                    </div>
                </Grid>
                <Grid item>
                    <Typography className={classes.note}>
                        Nota: si deseas modificar las coberturas seleccionadas debes realizar una nueva cotización con las coberturas deseadas.
                    </Typography>
                </Grid>
                <Grid item container spacing={1}>
                    <Grid item xs={12}>
                        <InputField
                            name={comentariosSolicitud.name}
                            label={comentariosSolicitud.label}
                            multiline
                            rows={2}
                            fullWidth
                            onKeyDown={(e) => {
                                if (e.which === 13) {
                                    e.preventDefault();
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
