import axios from "axios";

const loadMotovehiculoData = async (authToken) => {
    let requestMarcas = process.env.REACT_APP_BACK_SERVER + '/getMarcasMotos'

    try {
        const response = await axios.get(requestMarcas, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error loading automotor data:', error);
        throw error;
    }
};

export default loadMotovehiculoData;
