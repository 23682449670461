import React, {useContext, useState} from 'react';
import './CompanySelectorItem.css'
import {ReactComponent as CheckFilledIcon} from "../../../assets/icons/ic_check-filled.svg";
import {Circle} from "../../../components/Circle";
import CotizarContext from "../../../context/CotizarContext";
import {Badge} from "../../../components/Badge";

function CompanySelectorItem(props) {
    const {companyKey, index, selected: selectedReceived, callback} = props;
    const [selected, setSelected] = useState(selectedReceived);
    const {companiesDict} = useContext(CotizarContext);

    const handleSelection = () => {
        callback(index, !selected)
        setSelected(!selected)
    }

    return (
        <div className="csm__circle" onClick={() => handleSelection()}>
            {selected && <CheckFilledIcon className="csm__check"/>}
            <Circle variant={"xs"}>
                <div className="csm__contentContainer">
                    <img
                        className="csm__logo"
                        src={companiesDict[parseInt(companyKey)]['logo']}
                        alt={companiesDict[parseInt(companyKey)]['name']}/>
                </div>
                    <div className="csm__logo--new">
                        {companyKey === 5 && <Badge size="subHeading" color="primary">NUEVO</Badge>}
                    </div>
            </Circle>
        </div>
    );
}

export default CompanySelectorItem;