import React from 'react';
import './BilleteraCardPrimary.css';
import {ReactComponent as InfoIcon} from "../../assets/icons/ic_info.svg";
import {Button} from "../Button";
import {currencyFormat} from "../../modules/utils/currencyFormat";
import {Skeleton} from "@mantine/core";
import useWindowDimensions from "../../modules/utils/useWindowDimensions";

function BilleteraCardPrimary(props) {
    const {infoAction, totalAmount, text, buttonAction, buttonText, buttonColor, loading} = props;
    const {width} = useWindowDimensions();

    if (loading) return (
        <Skeleton height={"100%"}>
            <div className="billeteraCard__container"/>
        </Skeleton>
    )
    return (
        <div className="billeteraCard__container">
            <div className="billeteraCard__icon__container" onClick={infoAction}>
                <InfoIcon className="billeteraCard__icon"/>
            </div>

            <div className="billeteraCard__body">
                <p className="billeteraCard__amount">$ {currencyFormat(totalAmount)}</p>
                <p className="billeteraCard__text">{text}</p>
            </div>

            <Button
                type="button"
                variant="filled"
                color={buttonColor}
                enabled={totalAmount !== 0}
                marginY="sm"
                size={width < 1025 ? "md" : "lg"}
                onClick={buttonAction}
            >
                {buttonText}
            </Button>
        </div>
    );
}

export default BilleteraCardPrimary;