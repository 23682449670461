import React, {useContext, useEffect, useMemo, useState} from 'react';
import './Documentacion.css';
import {FormCard} from "../../components/FormCard";
import axios from "axios";
import AuthContext from "../../context/AuthContext";
import {DocumentacionCard} from "../../components/DocumentacionCard";
import {Pagination} from "../../components/Pagination";
import {MantineSelectFilter} from "../../components/Filters/MantineSelectFilter";
import {MantineInputFilter} from "../../components/Filters/MantineInputFilter";
import {ReactComponent as FilterIcon} from "../../assets/icons/ic_filter.svg";
import {Loading} from "../../components/Loading";
import moment from "moment"
import {MantineMonthFilter} from "../../components/Filters/MantineMonthFilter";
import UserContext from "../../context/UserContext";
import {DocumentacionCardMobile} from "../../components/DocumentacionCardMobile";
import {handleDownloadDocs} from "../../modules/utils/handleDownloadDocs";
import showNotification from "../../modules/utils/showNotification";

const productos = [
    {value: 'AUTOMOTOR', label: 'AUTOMOTOR'},
    {value: 'HOGAR', label: 'HOGAR'},
    {value: 'MICROSEGUROS', label: 'MICROSEGUROS'},
    {value: 'MOTOVEHICULO', label: 'MOTOVEHÍCULO'},
    {value: 'ASISTENCIA_VIAJERO', label: 'ASISTENCIA AL VIAJERO'}
];

function Documentacion() {
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isDownloading, setIsDownloading] = useState(null);
    const {authToken} = useContext(AuthContext);
    const {isMobile, userCodigosOperacion, userCompanies} = useContext(UserContext);
    const aseguradoras = useMemo(() => userCompanies.map(company => ({value: company, label: company.replace(/_/g, ' ')})), [userCompanies]);
    // const {filter: filterReceived} = props?.location?.state; //Todo: handle filter recevided from Cotizacion Form
    //FILTERS
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState({
        aseguradora: '',
        producto: '',
        fecha: '',
        searchText: ''
    });

    function filterEmisiones(filtersReceived) {
        let emisionesFiltradas = rows;

        if (filtersReceived.aseguradora) {
            emisionesFiltradas = emisionesFiltradas.filter(emision =>
                emision.aseguradora.includes(filtersReceived.aseguradora)
            );
        }

        if (filtersReceived.producto) {
            emisionesFiltradas = emisionesFiltradas.filter(emision =>
                emision.producto.includes(filtersReceived.producto)
            );
        }

        if (filtersReceived.fecha) {
            emisionesFiltradas = emisionesFiltradas.filter(emision =>
                moment(emision.fecha).format("MM") === moment(filtersReceived.fecha).format("MM")
            );
        }

        if (filtersReceived.searchText) {
            emisionesFiltradas = emisionesFiltradas.filter(emision =>
                JSON.stringify(emision).toLowerCase().includes(filtersReceived.searchText.toLowerCase())
            );
        }

        return emisionesFiltradas;
    }

    const handleFilterChange = (filterName, value) => {
        let valueToSet = value ? value : "";

        setFilters((prevState) => ({...prevState, [filterName]: valueToSet}));
        const filteredEmisiones = filterEmisiones({...filters, [filterName]: valueToSet});

        setFilteredRows(filteredEmisiones);
    };

    //PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = isMobile ? 4 : 3;
    const [startIndex, setStartIndex] = useState((currentPage - 1) * itemsPerPage);
    const [endIndex, setEndIndex] = useState(startIndex + itemsPerPage);

    //DOCS
    const handleDocs = () => {
        let url = process.env.REACT_APP_BACK_SERVER + '/getDocumentacion';
        axios.get(url, {headers: {'Authorization': `Bearer ${authToken}`}}
        ).then(res => {
            const modifiedData = res.data.map(row => {
                let rowDetail = [{title: "Bien Asegurado", value: row.descripcion.producto}];

                if (row.producto === "AUTOMOTOR")
                    rowDetail.unshift({title: "Cobertura", value: row.descripcion.cobertura});

                if (row.producto === "ASISTENCIA_VIAJERO")
                    rowDetail = [{title: "Viaje", value: row.descripcion.producto}];

                return {...row, detalle: rowDetail};
            }).sort((a, b) => {
                const fechaA = moment(a.fecha, 'YYYY-MM-DD');
                const fechaB = moment(b.fecha, 'YYYY-MM-DD');
                return fechaB - fechaA;
            });
            setRows(modifiedData)
            setFilteredRows(modifiedData)
        }).catch(err => {
            console.log(err);
            setRows([])
            setFilteredRows([])
        }).finally(() => setIsLoading(false));
    }

    const productToUrlDict = {
        "AUTOMOTOR": "auto",
        "HOGAR": "hogar",
        "MICROSEGUROS": "microseguros",
        "MOTOVEHICULO": "motovehiculo",
        "ASISTENCIA_VIAJERO": "asistencia-viajero",
    }

    const handleDownload = async (index, product, company, document, documentCodes) => {

        let url = `${process.env.REACT_APP_QUOTATION_MICROSERVICE}/documentacion/${productToUrlDict[product]}`;
        setIsDownloading(index)

        try {
            await handleDownloadDocs (
                url,
                company,
                document,
                documentCodes,
                authToken,
                userCodigosOperacion[company][0]
            );
        } catch (error) {
            showNotification({
                autoClose: 3000,
                color: 'red',
                status: 'error',
                title: 'Error al descargar la documentación.',
                message: error.response?.data?.detail || "Hubo un problema en la comunicación con el servicio.",
            });
        } finally {
            setIsDownloading(null);
        }
    }

    useEffect(() => {
        handleDocs()
    }, []);

    useEffect(() => {
        setStartIndex((currentPage - 1) * itemsPerPage)
        setEndIndex((currentPage - 1) * itemsPerPage + itemsPerPage)
    }, [currentPage]);

    return (
        <div className="documentacion__container">
            <div className="documentacion__content">
                <FormCard>
                    <div className="documentacion__form__container">
                        <div className="documentacion__filter" onClick={() => {
                            if (!isLoading)
                                setShowFilters(!showFilters)
                        }}>
                            <FilterIcon className="documentacion__filter__icon"/>
                            Filtros
                        </div>
                        <div
                            className={`documentacion__header__container documentacion__header__container--${showFilters}`}>
                            <MantineSelectFilter
                                value={filters.aseguradora}
                                setValue={(value) => handleFilterChange('aseguradora', value)}
                                data={aseguradoras}
                                searchable
                                clearable
                                placeholder={"Aseguradora"}
                            />
                            <MantineSelectFilter
                                value={filters.producto}
                                setValue={(value) => handleFilterChange('producto', value)}
                                data={productos}
                                searchable
                                clearable
                                placeholder={"Producto"}
                            />
                            <MantineMonthFilter
                                placeholder={"Fecha"}
                                value={filters.fecha}
                                setValue={(value) => handleFilterChange('fecha', value)}
                            />
                            <MantineInputFilter
                                value={filters.searchText}
                                setValue={(value) => handleFilterChange('searchText', value)}
                                placeholder={"Buscar"}
                            />
                        </div>

                        <hr className="documentacion__hr"/>

                        {isLoading
                            ? <Loading/>
                            : <>
                                <div className="documentacion__body__container">
                                    {filteredRows.length !== 0 ?
                                        filteredRows.slice(startIndex, endIndex).map((row, index) => {
                                            if (isMobile) {
                                                return (
                                                    <DocumentacionCardMobile
                                                        index={index} isDownloading={isDownloading}
                                                        downloadDocument={handleDownload} data={row}/>
                                                )
                                            } else {
                                                return (
                                                    <DocumentacionCard
                                                        index={index} isDownloading={isDownloading}
                                                        downloadDocument={handleDownload} data={row}/>
                                                )
                                            }
                                        })
                                        : <p className="documentacion__noData_text">Sin datos para mostrar</p>
                                    }
                                </div>
                                {filteredRows.length !== 0 &&
                                <div className="documentacion__footer__container">
                                    <Pagination
                                        totalItems={filteredRows.length}
                                        itemsPerPage={itemsPerPage}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}/>
                                </div>
                                }
                            </>
                        }
                    </div>
                </FormCard>
            </div>
        </div>
    );
}

export default Documentacion;