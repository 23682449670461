import React from 'react';
import './SidebarChildItem.css'
import {NewIndicator} from "../NewIndicator";

function SidebarChildItem(props) {
    const {name, action, newIndicator} = props;
    return (
        <div className="sidebar__childitem__container" onClick={action}>
            <span className="sidebar__item__text">
                {name}
                {newIndicator &&
                    <div className="sidebar__item__text--withIndicator">
                        <NewIndicator size="tiny" color="yellow">NUEVO</NewIndicator>
                    </div>
                }
            </span>
        </div>
    );
}

export default SidebarChildItem;
