function getBirthDateForAge(age) {
    if (isNaN(age)) {
        return age;
    }
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const birthYear = currentYear - age;

    currentDate.setFullYear(birthYear);
    currentDate.setDate(currentDate.getDate() - 1);
    const isoBirthDate = currentDate.toISOString();

    return isoBirthDate;
}

export { getBirthDateForAge };
