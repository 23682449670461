import * as Yup from 'yup';
import checkoutFormModel from './formModel';

const {
    formField: {
        products,
        description,
        links
    }
} = checkoutFormModel;

const domainsArray = ['facebook', 'instagram', 'linkedin', ''];

function validateLinkByIndex(value, index) {
    if (!value) return true;
    const domainMap = {
        0: 'facebook',
        1: 'instagram',
        2: 'linkedin'
    };
    const domain = domainMap[index];
    if (domain) {
        // Verifica que el link sea válido y corresponda al dominio esperado.
        return new RegExp(`^(https?:\\/\\/)?(www\\.)?${domain}\\.com.*$`).test(value);
    } else {
        // Para el índice 3, permite cualquier URL válida.
        return /^(https?:\/\/)?(www\.)?[a-zA-Z0-9][a-zA-Z0-9-]+\.(com|net|org|io).*$/.test(value);
    }
}

export default [
    Yup.object().shape({}),
    Yup.object().shape({}),
    Yup.object().shape({
        [products.name]: Yup.object().test('active-test', 'Debe seleccionar al menos un producto y compañía.',
            (prods) => {
                const productKeys = Object.keys(prods);
                return productKeys.some(key => prods[key] && prods[key].active);
            }
        ),
    }),
    Yup.object().shape({
        [description.name]: Yup.array()
            .test(
                'min-max-selected',
                'Seleccione como mínimo 1 y como máximo 3 opciones.',
                (value) => {
                    let seleccionados = 0;
                    for (const option of value) {
                        if (option.selected) {
                            seleccionados++;
                        }
                    }
                    return seleccionados >= 1 && seleccionados <= 3;
                }
            )
    }),
    Yup.object().shape({
        [links.name]: Yup.array()
            .of(
                Yup.string()
                    .test(
                        'is-valid-url',
                        (value, testContext) => {
                            const index = parseInt(testContext.path.split('[')[1].slice(0, -1));
                            if (!validateLinkByIndex(value, index)) {
                                return testContext.createError({
                                    message: `Ingrese una URL de ${domainsArray[index]}`
                                });
                            }
                            return true;
                        }
                    )
            )
    })
];
