import React from 'react';
import './Button.css';
import SmallLoading from "../../components/SmallLoading/SmallLoading";

function Button(props) {
    const { size = "md",
        variant,
        color,
        enabled,
        marginY,
        marginX,
        children,
        loading,
        inputFile = false,
        borderRadius = 'normal',
        fullWidth = false,
        fullHeight,
        onClick,
        toUpperCase = true
    } = props;

    const childrenRender = () => {
        if (typeof children === 'string' && toUpperCase) return children.toUpperCase();
        else return children;
    };

    const handleClick = (event) => {
        if (enabled) {
            onClick && onClick(event);
        } else {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    return (
        inputFile ?
            (<label
                {...props}
                onClick={handleClick}
                className={
                    enabled
                        ? `button button--${size} button--${size}--border-${borderRadius} ${variant}__${color} marginX__${marginX} marginY__${marginY} ${fullWidth ? 'button-fullWidth' : ''}`
                        : `button button--${size} button--${size}--border-${borderRadius} disabled marginX__${marginX} marginY__${marginY} ${fullWidth ? 'button-fullWidth' : ''}`}
            >
                {children}
            </label>)
            :
            (<button
                {...props}
                onClick={handleClick}
                className={
                    enabled && !loading
                        ? `button button--${size} button--${size}--border-${borderRadius} ${variant}__${color} marginX__${marginX} marginY__${marginY} ${fullWidth ? 'fullWidth' : ''} ${fullHeight && 'fullHeight'}`
                        : `button button--${size} button--${size}--border-${borderRadius} disabled marginX__${marginX} marginY__${marginY} ${fullWidth ? 'fullWidth' : ''} ${fullHeight && 'fullHeight'}`}
            >
                {loading ?
                    <SmallLoading
                        scale={[0.2, 0.2, 0.2, 0.2]}
                        height={32}
                        width={32}
                    />
                    :
                    childrenRender()
                }
            </button>)
    );
}

export default Button;