import React, {useContext} from 'react';
import "./RedesForm.css";
import {LinkInput} from "../../SocialMediaStep/LinkInput";
import MasterContext from "../../../../../context/MasterContext";

export default function RedesForm(props) {
    const {
        formField: {
            links
        },
    } = props;
    const {socialMediaDict} = useContext(MasterContext);

    return (
        <React.Fragment>
            <div className="RForm__formContainer">
                <label className="RForm__label">{links.label}</label>
                <div className="RForm__container">
                    {Object.keys(socialMediaDict).map((rrss, index) => (
                        <LinkInput key={index} value={socialMediaDict[rrss]} isForm/>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
}
