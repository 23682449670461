import React from 'react';
import './RegimenComisionarioCard.css';

function RegimenComisionarioCard(props) {
    const {companyImg, comisionInfo} = props;

    return (
        <div className="regComCard__container">
            <div className="regComCard__header">
                <img className="regComCard__image" src={companyImg} alt="aseguradora"/>
            </div>
            <div className="regComCard__body">
                {Object.keys(comisionInfo).map(key => (
                    <div className="regComCard__item">
                        <p className="regComCard__text">
                            <span className="regComCard__text--highlighted">{`${key.replace(/_/g, " ")}: `}</span>
                            {comisionInfo[key]}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default RegimenComisionarioCard;