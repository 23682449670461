import {MantineSelectField} from "../../../../../../components/FormFields/MantineSelectField";
import React, {useContext, useEffect, useRef, useState} from "react";
import {EntradaCotizarModel} from "./EntradaCotizarModel";
import {FieldArray, useFormikContext} from "formik";
import AsistenciaViajeroContext from "../../../../../../context/Products/AsistenciaViajeroContext";
import {MantineNumberField} from "../../../../../../components/FormFields/MantineNumberField";
import {ReactComponent as AddPassenger} from "../../../../../../assets/icons/ic_plus_filled-primary.svg";
import {ReactComponent as RemovePassenger} from "../../../../../../assets/icons/ic_substract_filled-primary.svg"
import {ReactComponent as InformationFilledIcon} from "../../../../../../assets/icons/ic_info-filled.svg";
import {Checkbox, Grid} from "@mantine/core";
import "./EntradaCotizarForm.css";
import {MantineDatePickerTwoFields} from "../../../../../../components/FormFields/MantineDatePickerTwoFields";
import moment from "moment/moment";
import ModalIndicator from "../../../../../../components/ModalIndicator/ModalIndicator";
import {Message, Title} from "./infoRangeTexts";
import UserContext from "../../../../../../context/UserContext";

//TODO: Deberia desaparecer la key de fecha y que sea realmente vigenciaDesde, vigenciaHasta.
//TODO: No se esta validando correctamente que fecha sea required.
export default function EntradaCotizarForm(props) {
    const {cotizacionRetomada} = props;
    const {formField} = EntradaCotizarModel();
    const {
        idPais,
        nombrePais,
        vigenciaDesde,
        vigenciaHasta,
        pasajeros
    } = formField;
    const {destinos, minDatesWarning, setMinDatesWarning} = useContext(AsistenciaViajeroContext)
    const {isMobile } = useContext(UserContext);
    const {values, setFieldValue} = useFormikContext();
    const [isMultipleDestinations, setIsmultipleDestinations] = useState(false);
    const infoRangeRef = useRef(null);

    const handleDaysCounter = (range) => {
        if (range[0] && range[1]) {
            const startDate = moment(range[0]);
            const endDate = moment(range[1]);
            const days = endDate.diff(startDate, 'days') + 1;
            if (days) {
                setFieldValue("travelDaysCounter", days)
                if (days < 5) {
                    setMinDatesWarning({
                        icon: true,
                        modal: true,
                        fadeOut: true
                    });
                } else {
                    setMinDatesWarning({
                        icon: false,
                        modal: false,
                        fadeOut: false
                    });
                }
            } else setFieldValue("travelDaysCounter", 0);
        } else setFieldValue("travelDaysCounter", 0);
    }

    const getDestinoLabel = (value) => {
        const destino = destinos.find((destino) => destino.value === value);
        setFieldValue(nombrePais.name, destino.label);
    }

    useEffect(() => {
        if (values.idPais && destinos && destinos.length > 0) {
            getDestinoLabel(values.idPais);
        }
    }, [destinos, values.idPais])

    useEffect(() => {
        if (cotizacionRetomada) {
            setFieldValue(vigenciaDesde.name, moment(values.vigenciaDesde, 'DD/MM/YYYY').toDate());
            setFieldValue(vigenciaHasta.name, moment(values.vigenciaHasta, 'DD/MM/YYYY').toDate());
        }
    }, []);

    return (
        <>
            <Grid align="start">
                <Grid.Col xs={12} sm={6}>
                    <div className="EntradaCotizarForm__destinoField">
                        <MantineSelectField
                            name={idPais.name}
                            label={idPais.label}
                            data={destinos}
                            searchable
                            withAsterisk
                            disabled={isMultipleDestinations}
                            filter={(value, item) => (item.label.toLowerCase().includes(value.toLowerCase()) && item.value !== "9999")}
                        />
                        <Checkbox
                            checked={isMultipleDestinations}
                            onChange={(event) => {
                                setIsmultipleDestinations(event.currentTarget.checked)
                                if (event.currentTarget.checked) {
                                    setFieldValue(idPais.name, "9999")
                                } else {
                                    setFieldValue(idPais.name, "")
                                }
                            }}
                            label="Multiples destinos"
                            classNames={{
                                input: 'EntradaCotizarForm__multipleDestinationsCheckbox',
                                label: 'EntradaCotizarForm__multipleDestinationsCheckbox',
                                root: 'EntradaCotizarForm__multipleDestinationsCheckboxContainer'
                            }}
                            size="xs"
                        />
                    </div>
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <div className="EntradaCotizarForm__destinoField">
                            <MantineDatePickerTwoFields
                                name={[vigenciaDesde.name, vigenciaHasta.name]}
                                label={vigenciaHasta.label}
                                minDate={new Date()}
                                withAsterisk
                                type="range"
                                onChangeAction={handleDaysCounter}
                            />
                            {minDatesWarning.icon &&
                                <InformationFilledIcon ref={infoRangeRef} className="EntradaCotizarForm__infoIcon"
                                                       onMouseEnter={() => setMinDatesWarning({
                                                           ...minDatesWarning,
                                                           modal: true,
                                                           fadeOut: false
                                                       })}
                                                       onMouseLeave={() => setMinDatesWarning({
                                                           ...minDatesWarning,
                                                           modal: false,
                                                           fadeOut: false
                                                       })}
                                                       onClick={() => setMinDatesWarning(prev => ({
                                                           ...prev,
                                                           modal: !prev.modal,
                                                           fadeOut: isMobile
                                                       }))}/>}
                        {values?.travelDaysCounter > 0 &&
                            <p className="EntradaCotizarForm__travelDaysCounter">{values.travelDaysCounter} días de
                                cobertura</p>
                        }
                    </div>
                </Grid.Col>
                <FieldArray name={pasajeros.name}>
                    {({push, remove}) => (
                        <>
                            {values.pasajeros.map((pasajero, index) => (
                                <Grid.Col key={index} xs={3.5} span={8}>
                                    <MantineNumberField
                                        name={`${formField.pasajeros.name}[${index}].edad`}
                                        label={`Edad pasajero ${index + 1}`}
                                        withAsterisk
                                        min={1}
                                        max={150}
                                        notFormat
                                    />
                                </Grid.Col>
                            ))}
                            <Grid.Col xs={1.5} span={4}>
                                <div className="EntradaCotizarForm__actionButtonsContainer">
                                    {values.pasajeros.length < 5 && <AddPassenger onClick={() => push({edad: ''})}
                                                                                  className="EntradaCotizarForm__actionButton"/>}
                                    {values.pasajeros.length > 1 &&
                                        <RemovePassenger onClick={() => remove(values.pasajeros.length - 1)}
                                                         className="EntradaCotizarForm__actionButton"/>}
                                </div>
                            </Grid.Col>
                        </>
                    )}
                </FieldArray>
                {minDatesWarning.modal &&
                    <ModalIndicator arrowDirection="top" title={Title} message={Message} setVisible={(isVisible) => setMinDatesWarning({...minDatesWarning, modal: isVisible})}
                                    setDisplay={() => {
                                    }} referenceElement={infoRangeRef} fadeOut={minDatesWarning.fadeOut}/>
                }
            </Grid>
        </>
    );
}
