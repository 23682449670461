import React, {useContext, useEffect, useMemo, useState} from 'react';
import _ from 'lodash';
import './CotizarAutomotor.css'
import {VehiculoModel} from "./FormStages/Cotizar/Vehiculo/VehiculoModel";
import VehiculoForm from "./FormStages/Cotizar/Vehiculo/VehiculoForm";
import axios from "axios";
import AuthContext from "../../../context/AuthContext";
import TitularForm from "./FormStages/Cotizar/Titular/TitularForm";
import {TitularModel} from "./FormStages/Cotizar/Titular/TitularModel";
import CotizarSuccessView from "./FormStages/Cotizar/CotizarSuccess/CotizarSuccessView";
import {FormCard} from "../../../components/FormCard";
import {useCurrentQuotationContext} from "../../../context/CurrentQuotationContext/CurrentQuotationContext";
import {FormRenderer} from "../../../components/FormRenderer";
import {Loading} from '../../../components/Loading'
import {ErrorModal} from "../../../layouts/ErrorModal";
import {PolizaModel} from "./FormStages/Emitir/Poliza/PolizaModel";
import {ClienteModel} from "./FormStages/Emitir/Cliente/ClienteModel";
import {InspeccionModel} from "./FormStages/Emitir/Inspeccion/InspeccionModel";
import PolizaForm from "./FormStages/Emitir/Poliza/PolizaForm";
import ClienteForm from "./FormStages/Emitir/Cliente/ClienteForm";
import DetallesVehiculoForm from "./FormStages/Emitir/DetallesVehiculo/DetallesVehiculoForm";
import SolicitudForm from "./FormStages/Emitir/Solicitud/SolicitudForm";
import InspeccionForm from "./FormStages/Emitir/Inspeccion/InspeccionForm";
import {DetallesVehiculoModel} from "./FormStages/Emitir/DetallesVehiculo/DetallesVehiculoModel";
import {SuccessModal} from "../../../layouts/SuccessModal";
import {Form, Formik} from "formik";
import {MantineSelectField} from "../../../components/FormFields/MantineSelectField";
import {Button} from "../../../components/Button";
import {useHistory, useLocation} from "react-router-dom";
import {isResponseSuccessful} from "../../../modules/utils/isResponseSuccessful";
import CotizarContext from "../../../context/CotizarContext";
import UserContext from "../../../context/UserContext";
import showNotification from "../../../modules/utils/showNotification";
import {handleDownloadDocs} from "../../../modules/utils/handleDownloadDocs";
import {advancedConfigSancor} from "./AuxFunctions/advancedConfigSancor";
import {advancedConfigRUS} from "./AuxFunctions/advancedConfigRUS";
import {advancedQuotation} from "./AuxFunctions/advancedQuotation";
import {getActiveCompaniesIdsOfProduct} from "../../../modules/utils/getActiveCompaniesIdsOfProduct";
import {emissionDataGenerator} from "./AuxFunctions/emissionDataGenerator";
import {buildLocationCode} from "../../Cotizacion/utils/buildLocationCode";
import {advancedConfigZurich} from "./AuxFunctions/advancedConfigZurich";
import {ScoringModel} from "./FormStages/Cotizar/Scoring/ScoringModel";
import ScoringForm from "./FormStages/Cotizar/Scoring/ScoringForm";
import {getBirthDateForAge} from "../../../modules/utils/getBirthDateForAge";
import {getAgeForBirthDate} from "../../../modules/utils/getAgeForBirthDate";
import loadAutomotorData from "../../../context/Products/AutomotorContext/loadAutomotorData";
import ContextLoading from "../../../modules/components/ContextLoading";
import fetchContextData from "../../../context/utils/fetchContextData";
import AutomotorContextProvider from "../../../context/Products/AutomotorContext/AutomotorContextProvider";
import AutomotorContext from "../../../context/Products/AutomotorContext/AutomotorContext";
import {getLocalidadOptionsByZipCode} from "../../Cotizacion/utils/getLocalidadOptionsByZipCode";
import handleJeepCase from "../../../context/Products/AutomotorContext/services/handleJeepCase";
import {advancedConfigFederacionPatronal} from "./AuxFunctions/advancedConfigFederacionPatronal";
import {fedPatGetCarInfo} from "./services/federacionPatronal/fedPatGetCarInfo";

const moment = require('moment');

function CotizarAutomotor(props) {
    const {recotizarValues, action, performReset} = props;
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalContent, setErrorModalContent] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const {
        selectedCompanies,
        setSelectedCompanies,
        cotizacionResponse,
        setCotizacionResponse,
        finalValues,
        setFinalValues,
        currentFormData,
        setCurrentFormData,
        formData,
        setFormData,
        activeStep,
        setActiveStep,
        emisionResponse,
        setEmisionResponse,
        setCartCoverages,
        setTotalInsurableByCompany,
        localidadOptions,
        selectedCompaniesToPromote,
    } = useCurrentQuotationContext();
    const {companiesDict} = useContext(CotizarContext);
    const {authToken} = useContext(AuthContext);
    const {
        activeFormProduct,
        setActiveFormProduct,
        userCodigosOperacion,
        userCompaniesToPromote,
        userDetalleComisiones,
        userDiscounts
    } = useContext(UserContext);
    const userCompaniesToPromoteByProduct = useMemo(() => {
        return userCompaniesToPromote.filter(company => companiesDict[company].products.some(product => product.key === activeFormProduct))
    }, [userCompaniesToPromote, activeFormProduct])
    const history = useHistory();
    const location = useLocation();
    const {coberturasProductsInfo} = useContext(CotizarContext);
    const downloadPdfJsonTemplate = ["vehiculo.detalle_marca", "vehiculo.detalle_version", "vehiculo.tieneGnc", "vehiculo.anio", "codigoPostal", "vehiculo.sumaAseguradaAdicional", "nombreLocalidad"];
    const {vehicleDetails} = useContext(AutomotorContext);
    //Cotizar
    const {
        initialValues: cotizarVehiculoInitialValues,
        validationSchema: cotizarVehiculoValidationSchema
    } = VehiculoModel();
    const {initialValues: titularInitialValues, validationSchema: titularValidationSchema} = TitularModel();
    const {initialValues: scoringInitialValues, validationSchema: scoringValidationSchema} = ScoringModel();
    //Emitir
    const {initialValues: polizaInitialValues, validationSchema: polizaValidationSchema} = PolizaModel();
    const {initialValues: clienteInitialValues, validationSchema: clienteValidationSchema} = ClienteModel();
    const {
        initialValues: detallesVehiculoInitialValues,
        validationSchema: detallesVehiculoValidationSchema
    } = DetallesVehiculoModel();

    const {initialValues: inspeccionInitialValues, validationSchema: inspeccionValidationSchema} = InspeccionModel();

    useEffect(() => {
        if (recotizarValues) {
            setSelectedCompanies(prev => {
                const updatedAutomotor = {};
                Object.keys(prev.automotor).forEach(key => {
                    updatedAutomotor[key] = recotizarValues.companies.includes(parseInt(key));
                });
                const newValue = {
                    ...prev,
                    'automotor': updatedAutomotor
                }
                localStorage.setItem('companiesByProduct', JSON.stringify(newValue));
                return newValue
            })
        }
    }, [recotizarValues]);

    const valuesAdjusterFromRecotizar = () => {
        if (recotizarValues) {
            let companiesDiscount = {};

            let adjustedConfiguracionAvanzada = {}

            for (let company in recotizarValues.configuracionAvanzada) {
                companiesDiscount[company] = 0;
                let companyConfig = recotizarValues.configuracionAvanzada[company]

                let newDates = {}
                if (company === "RUS") {
                    newDates = {
                        "vigenciaDesde": moment().format("YYYY-MM-DD"),
                        "vigenciaHasta": moment().add(1, "Y").format("YYYY-MM-DD"),
                    }
                } else if (company === "Federacion_Patronal") {
                    newDates = {
                        "vigenciaDesde": moment().format("DD/MM/YYYY"),
                    }
                } else { // "Sancor"
                    newDates = {
                        "fechaCotizacion": moment().format("YYYY-MM-DD"),
                    }
                }

                if (companyConfig) {
                    adjustedConfiguracionAvanzada[company] = {
                        ...companyConfig,
                        ...newDates
                    }
                }
            }

            const quotationData = {
                ...recotizarValues,
                _id: recotizarValues._id,
                "configuracionAvanzada": adjustedConfiguracionAvanzada,
                descuentos: companiesDiscount,
            }

            vehicleDetails.current = {
                detalle_marca: recotizarValues.vehiculo.detalle_marca || "",
                detalle_modelo: recotizarValues.vehiculo.detalle_modelo || "",
                detalle_version: recotizarValues.vehiculo.detalle_version || "",
            }

            // Create return object omitting "idCanalDigital" property
            // Also prevent to send customer nonsense data
            const {idCanalDigital, cliente, ...filteredQuotationData} = quotationData;
            if (recotizarValues.cliente !== "Sin datos") {
                filteredQuotationData.cliente = {
                    ...recotizarValues.cliente,
                }
            }
            return filteredQuotationData;
        } else return null
    }

    const checkForCompanyActive = (_companyToCheck) => {
        if (selectedCompanies && activeFormProduct) {
            return getActiveCompaniesIdsOfProduct(selectedCompanies, 'automotor').includes(_companyToCheck)
        } else return true
    }

    //Se define cada paso
    const initialQuotationForm = [
        {
            name: "Vehículo",
            stepButtonName: "Siguiente",
            condition: true,
            form: <VehiculoForm cotizacionRetomada={recotizarValues !== undefined}/>,
            initialValues: cotizarVehiculoInitialValues,
            validationSchema: cotizarVehiculoValidationSchema,
            onNext: (values) => {
                if (checkForCompanyActive(5)) {
                    fedPatGetCarInfo(values.vehiculo.version, values.vehiculo.anio).then(res => values.configuracionAvanzada.Federacion_Patronal = {
                        ...values.configuracionAvanzada.Federacion_Patronal,
                        ...res
                    })
                }
            }
        },
        {
            name: "Titular",
            stepButtonName: checkForCompanyActive(3) ? "Siguiente" : "Cotizar",
            condition: true,
            form: <TitularForm/>,
            initialValues: titularInitialValues,
            validationSchema: titularValidationSchema
        },
        {
            name: "Scoring",
            stepButtonName: "Cotizar",
            condition: checkForCompanyActive(3),
            form: <ScoringForm/>,
            initialValues: scoringInitialValues,
            validationSchema: scoringValidationSchema
        },
        {
            name: "Cotización",
            stepButtonName: "Siguiente",
            condition: true,
            form: <CotizarSuccessView submitForm={_submitCotizarForm}/>,
            initialValues: valuesAdjusterFromRecotizar(), //Include it in last step so it overrides previous initial values
            validationSchema: null
        },
        {
            name: "#Emisión",
            form: null,
            condition: true,
            initialValues: null,
            validationSchema: null
        },
    ];

    const emisionFormDataByCompany = {
        "Sancor": [
            {
                name: "Póliza",
                stepButtonName: "Siguiente",
                form: <PolizaForm/>,
                initialValues: polizaInitialValues,
                validationSchema: polizaValidationSchema
            },
            {
                name: "Cliente",
                stepButtonName: "Siguiente",
                form: <ClienteForm/>,
                initialValues: clienteInitialValues,
                validationSchema: clienteValidationSchema
            },
            {
                name: "Vehículo",
                stepButtonName: "Siguiente",
                form: <DetallesVehiculoForm/>,
                initialValues: detallesVehiculoInitialValues,
                validationSchema: detallesVehiculoValidationSchema
            },
            {
                name: "Inspección",
                stepButtonName: "Siguiente",
                form: <InspeccionForm/>,
                initialValues: inspeccionInitialValues,
                validationSchema: inspeccionValidationSchema
            },
            {
                name: "Solicitud",
                stepButtonName: "Emitir",
                form: <SolicitudForm/>,
                initialValues: null,
                validationSchema: null
            },
        ],
        "Zurich": [
            {
                name: "Póliza",
                stepButtonName: "Siguiente",
                form: <PolizaForm/>,
                initialValues: polizaInitialValues,
                validationSchema: polizaValidationSchema
            },
            {
                name: "Cliente",
                stepButtonName: "Siguiente",
                form: <ClienteForm/>,
                initialValues: clienteInitialValues,
                validationSchema: clienteValidationSchema
            },
            {
                name: "Vehículo",
                stepButtonName: "Siguiente",
                form: <DetallesVehiculoForm/>,
                initialValues: detallesVehiculoInitialValues,
                validationSchema: detallesVehiculoValidationSchema
            },
            {
                name: "Solicitud",
                stepButtonName: "Emitir",
                form: <SolicitudForm/>,
                initialValues: null,
                validationSchema: null
            },
        ],
        "RUS": [
            {
                name: "Póliza",
                stepButtonName: "Siguiente",
                form: <PolizaForm/>,
                initialValues: polizaInitialValues,
                validationSchema: polizaValidationSchema
            },
            {
                name: "Cliente",
                stepButtonName: "Siguiente",
                form: <ClienteForm/>,
                initialValues: clienteInitialValues,
                validationSchema: clienteValidationSchema
            },
            {
                name: "Vehículo",
                stepButtonName: "Siguiente",
                form: <DetallesVehiculoForm/>,
                initialValues: detallesVehiculoInitialValues,
                validationSchema: detallesVehiculoValidationSchema
            },
            {
                name: "Inspección",
                stepButtonName: "Siguiente",
                form: <InspeccionForm/>,
                initialValues: inspeccionInitialValues,
                validationSchema: inspeccionValidationSchema
            },
            {
                name: "Solicitud",
                stepButtonName: "Emitir",
                form: <SolicitudForm/>,
                initialValues: null,
                validationSchema: null
            },
        ],
        "Federacion_Patronal": [
            {
                name: "Póliza",
                stepButtonName: "Siguiente",
                form: <PolizaForm/>,
                initialValues: polizaInitialValues,
                validationSchema: polizaValidationSchema
            },
            {
                name: "Cliente",
                stepButtonName: "Siguiente",
                form: <ClienteForm/>,
                initialValues: clienteInitialValues,
                validationSchema: clienteValidationSchema
            },
            {
                name: "Vehículo",
                stepButtonName: "Siguiente",
                form: <DetallesVehiculoForm/>,
                initialValues: detallesVehiculoInitialValues,
                validationSchema: detallesVehiculoValidationSchema
            },
            {
                name: "Inspección",
                stepButtonName: "Siguiente",
                form: <InspeccionForm/>,
                initialValues: inspeccionInitialValues,
                validationSchema: inspeccionValidationSchema
            },
            {
                name: "Solicitud",
                stepButtonName: "Emitir",
                form: <SolicitudForm/>,
                initialValues: null,
                validationSchema: null
            },
        ],
    }

    //FUNCTIONAL METHODS

    async function _submitCotizarForm(values, actions) {
        const errorStates = {};
        let url = process.env.REACT_APP_BACK_SERVER + '/newcotizar';
        if (action !== "shortcut") values.companies = getActiveCompaniesIdsOfProduct(selectedCompanies, 'automotor').concat(selectedCompaniesToPromote);
        if (!action && values.companies.length < 4) {
            if (selectedCompaniesToPromote.length < userCompaniesToPromoteByProduct.length) {
                const newCompanies = userCompaniesToPromoteByProduct.filter(company =>
                    !selectedCompaniesToPromote.includes(company) && !values.companies.includes(companiesDict[company].id)
                );
                newCompanies.forEach(company => {
                    if (values.companies.length < 4) {
                        values.companies.push(companiesDict[company].id);
                    }
                });
            }
        }
        const companiesName = values.companies.map(company => companiesDict[company].name)
        const locationCodes = await buildLocationCode(companiesName, values.codigosLocalidad, companiesDict);
        values.codigoPostal = parseInt(values.codigoPostal);
        let selectedLocation = null;

        if (cotizacionResponse !== null) {
            values.is_saved = true;
            values.idCotizar = cotizacionResponse['idCotizar']
        }

        if (action && action !== "shortcut") {
            values.idCotizar = values['_id']
            if (action === "emitir") {
                const locations = await getLocalidadOptionsByZipCode(values.codigoPostal)
                const intCodigoLocalidad = parseInt(values.codigosLocalidad);
                selectedLocation = locations.find(localidad => localidad.value === intCodigoLocalidad)
            } else if (action === "recotizar" && localidadOptions.length > 0) {
                selectedLocation = localidadOptions.length > 0 && localidadOptions[0].find(localidad => localidad.value === values.codigosLocalidad);
            }
        } else if (action === "shortcut" || !action) { // Only apply this if it's a new quotation
            if (localidadOptions.length > 0) selectedLocation = localidadOptions[0].find(localidad => localidad.value === values.codigosLocalidad);
            for (const company of values.companies) {
                const companyName = companiesDict[company].name;
                const isPromotedCompany = userCompaniesToPromoteByProduct.includes(companyName)
                if (companyName === 'Sancor') {
                    advancedConfigSancor(recotizarValues, values, userCodigosOperacion, locationCodes, isPromotedCompany, userDiscounts)
                }
                if (companyName === 'RUS') {
                    advancedConfigRUS(recotizarValues, values, userCodigosOperacion, locationCodes, isPromotedCompany)
                }
                if (companyName === 'Zurich') {
                    advancedConfigZurich(recotizarValues, values, userCodigosOperacion, locationCodes, isPromotedCompany)
                }
                if (companyName === 'Federacion_Patronal') {
                    advancedConfigFederacionPatronal(recotizarValues, values, userCodigosOperacion, locationCodes, isPromotedCompany)
                    if (values['configuracionAvanzada']['Federacion_Patronal']) {
                        if (!values['configuracionAvanzada']['Federacion_Patronal']['grua'] || !values['configuracionAvanzada']['Federacion_Patronal']['alarma']) {
                            const extraCarInfo = await fedPatGetCarInfo(values.vehiculo.version, values.vehiculo.anio)
                            values.configuracionAvanzada.Federacion_Patronal = {
                                ...values.configuracionAvanzada.Federacion_Patronal,
                                ...extraCarInfo
                            }
                        }
                    }
                }
            }
        }

        if (!values.nombreLocalidad) values.nombreLocalidad = selectedLocation ? selectedLocation.label.split(') ')[1] : "";

        if (!values.scoring || values.scoring.sexo === '') {
            values.scoring = {}
            values.scoring.fechaNacimiento = getBirthDateForAge(45)
            values.scoring.sexo = "2"
        } else values.scoring.fechaNacimiento = getBirthDateForAge(values.scoring.fechaNacimiento);

        values.vehiculo = {
            ...values.vehiculo,
            ...vehicleDetails.current
        }

        let jsonData = {...values};
        jsonData.codigosLocalidades = locationCodes;
        jsonData.vehiculo.marca = handleJeepCase(values.vehiculo.marca)

        //Advanced quotation of only one company
        if (values.applyAdvancedConfiguration) {
            jsonData = advancedQuotation(
                jsonData,
                values.companyToQuote,
                companiesDict[values.companyToQuote]['id'],
                values.configuracionAvanzada
            )
        }

        axios.post(url, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            }
        }).then(res => {
            for (const company in res.data) {
                const companyRes = res.data[company];
                if (companyRes.status_code && companyRes.status_code !== 200) {
                    errorStates[company] = companyRes.detail.message;
                }
            }

            if (res.data.Sancor && isResponseSuccessful(res.data.Sancor["status_code"])) {
                const coverageIdsToRemove = [11, 19]
                res.data.Sancor.coberturas = res.data.Sancor.coberturas.filter(cob => !coverageIdsToRemove.includes(cob.id))
            }
            if (res.data.RUS && isResponseSuccessful(res.data.RUS["status_code"])) {
                res.data.RUS.coberturas = res.data.RUS.coberturas.map(cob => {
                    if (coberturasProductsInfo['automotor']['RUS'].hasOwnProperty(cob.id)) {
                        cob["nombre"] = coberturasProductsInfo['automotor']['RUS'][cob.id.toString()]['nombre_comercial'];
                    } else {
                        cob["nombre"] = cob["nombre"] || "Nombre no disponible";
                    }
                    return cob;
                });
            }
            if (res.data.Federacion_Patronal && isResponseSuccessful(res.data.Federacion_Patronal["status_code"])) {
                res.data.Federacion_Patronal.coberturas = res.data.Federacion_Patronal.coberturas.map(cob => {
                    if (coberturasProductsInfo['automotor']['Federacion_Patronal'].hasOwnProperty(cob.id)) {
                        cob["nombre"] = coberturasProductsInfo['automotor']['Federacion_Patronal'][cob.id.toString()]['nombre_comercial'];
                    } else {
                        cob["nombre"] = cob["nombre"] || "Nombre no disponible";
                    }
                    return cob;
                });
                if (res.data.Federacion_Patronal.tipo_vehiculo) {
                    values.configuracionAvanzada.Federacion_Patronal.tipoVehiculo = res.data.Federacion_Patronal.tipo_vehiculo
                }
            }
            if (values.applyAdvancedConfiguration) {
                setCotizacionResponse(prevState => {
                    let dict = {...prevState}
                    dict[values.companyToQuote] = res.data[values.companyToQuote]
                    return dict
                })
            } else {
                setCotizacionResponse(res.data);
                setTotalInsurableByCompany(res.data)
            }

            jsonData['companies'].map(company => {
                if (res.data[companiesDict[company]['name']].sumaAseguradaVehiculo) {
                    values.configuracionAvanzada[companiesDict[company]['name']].sumaAseguradaVehiculo = res.data[companiesDict[company]['name']].sumaAseguradaVehiculo
                } else {
                    res.data[companiesDict[company]['name']].sumaAseguradaVehiculo = 0
                    values.configuracionAvanzada[companiesDict[company]['name']].sumaAseguradaVehiculo = 0
                }
            })
        }).catch(err => {
            let detailedError = errorStates
            if (Object.keys(detailedError).length === 0) {
                detailedError = err.toString()
                if (!detailedError) {
                    detailedError = "Sin datos del error"
                }
            }
            setErrorModalContent({
                title: "Hubo un problema al cotizar, por favor vuelva a intentarlo más tarde.",
                typeError: "Ha ocurrido un error en la comunicación con el servicio",
                suggestedActions: ["Revisá que la información ingresada sea correcta", "Reintentá el proceso una vez más", "Si el problema persiste ponete en contacto con el área de soporte"],
                detailedError: detailedError,
            })
            setErrorModalOpen(true)
            if (jsonData.companies.find(company => company === 3) && values.scoring) values.scoring.fechaNacimiento = getAgeForBirthDate(values.scoring.fechaNacimiento)
        }).finally(() => {
            if (actions)
                actions.setSubmitting(false);

            if (values.applyAdvancedConfiguration) {
                setFinalValues(prevState => {
                    let dict = {...prevState}
                    dict.configuracionAvanzada[values.companyToQuote] = jsonData.configuracionAvanzada[values.companyToQuote]
                    dict.descuentos[values.companyToQuote] = jsonData.descuentos[values.companyToQuote]
                    return dict
                })
            } else {
                setFinalValues(values);
            }
        });
    }

    async function _submitEmitirForm(values, actions) {
        let url = process.env.REACT_APP_BACK_SERVER + '/newemitir';
        const formaDePagoCompanyValue = companiesDict[values.company].codigoFormasDePago[values.formaDePago]
        const companyEquipoGncId = companiesDict[values.company].equipoGncId

        setFinalValues(values)
        const jsonData = emissionDataGenerator(_.cloneDeep(values), formaDePagoCompanyValue, companyEquipoGncId, userDetalleComisiones)

        axios.post(url, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            }
        }).then(res => {
            setEmisionResponse(res.data)
            if (isResponseSuccessful(res.data["status_code"])) {
                setSuccessModalOpen(true)
            } else {
                setErrorModalContent({
                    title: "Hubo un problema al emitir, por favor vuelva a intentarlo más tarde.",
                    typeError: res.data['detail']['code'],
                    suggestedActions: ["Revisá que la información ingresada sea correcta", "Evitá incluir caracteres especiales", "Reintente el proceso"],
                    detailedError: res.data['detail']['message'],
                })
                setErrorModalOpen(true);
            }
            setIsLoading(false)
        }).catch(err => {
            let errorModalContent = {
                title: "Hubo un problema al emitir, por favor vuelva a intentarlo más tarde.",
                typeError: "Ha ocurrido un error en la comunicación con el servicio",
                suggestedActions: ["Revisá que la información ingresada sea correcta", "Reintentá el proceso una vez más", "Si el problema persiste ponete en contacto con el área de soporte"],
                detailedError: "Sin datos del error",
            }
            if (err.response && err.response.data) {
                errorModalContent["typeError"] = err.response.data.detail.code
                errorModalContent["detailedError"] = err.response.data.detail.message
            }
            setErrorModalContent(errorModalContent)
            setErrorModalOpen(true);
            setIsLoading(false)
        }).finally(() => {
            if (actions)
                actions.setSubmitting(false);
        });
    }

    const downloadDocumentation = async (formValues, setSubmitting) => {
        setSubmitting(true)

        let url = process.env.REACT_APP_QUOTATION_MICROSERVICE + '/documentacion/auto'
        try {
            await handleDownloadDocs(
                url,
                finalValues.company,
                formValues.documento,
                emisionResponse['identificadores'],
                authToken,
                userCodigosOperacion[finalValues.company][0]
            );
        } catch (error) {
            showNotification({
                autoClose: 3000,
                color: 'red',
                status: 'error',
                title: 'Error al descargar la documentación.',
                message: error,
            });
        }

        setSubmitting(false)
    }

    //FORM CONTROL METHODS

    function _handleSubmit(values, actions) {
        let emisionStep = 99;
        if (values['company'])
            emisionStep = formData['cotizacion'].length + formData['emision'][values['company']].length - 1;

        if (currentFormData[activeStep]?.onNext) currentFormData[activeStep].onNext(values);

        actions.setSubmitting(true);
        if (activeStep === emisionStep) {
            setIsLoading(true)
            _submitEmitirForm(values, actions);
        } else {
            setActiveStep(activeStep + 1);
            if (activeStep === (formData['cotizacion'].length - 3)) {
                _submitCotizarForm(values, actions);
            } else {
                actions.setTouched({});
                actions.setSubmitting(false);
            }
        }
    }

    function _handleBack(setFieldValue) {
        if (currentFormData[activeStep - 1]?.name === "Scoring") {
            setFieldValue("scoring.fechaNacimiento", getAgeForBirthDate(finalValues.scoring.fechaNacimiento))
        }
        if (currentFormData[activeStep]?.name === "Cotización") {
            setFieldValue("configuracionAvanzada", cotizarVehiculoInitialValues.configuracionAvanzada)
            setCartCoverages([])
            setCotizacionResponse(null);

        }
        if (activeStep > 0)
            setActiveStep(activeStep - 1);
    }

    useEffect(() => {
        const filteredQuotationForm = initialQuotationForm.filter((step) => step.condition)
        setCurrentFormData(filteredQuotationForm)
        setFormData({
            "cotizacion": filteredQuotationForm,
            "emision": emisionFormDataByCompany
        })
        setActiveStep(0) //Reset active step
        setCartCoverages([]) //Reset cart coverages
        setActiveFormProduct("automotor") //Name has to be the route path

        if (action === "emitir") {
            setActiveStep(filteredQuotationForm.length - 2)
            _submitCotizarForm(valuesAdjusterFromRecotizar(), null);
        }
    }, [performReset, selectedCompanies, activeFormProduct]);


    if (!currentFormData || (activeFormProduct && location.pathname.split("/")[3] !== activeFormProduct)) return <FormCard><Loading/></FormCard>
    return (
        <div className="automotor__form__container">
            <FormCard>
                <FormRenderer
                    handleSubmit={_handleSubmit}
                    handleBack={_handleBack}
                    isLoading={isLoading}
                    doNotDisplayNextButton={[formData['cotizacion'].length - 2]}
                    product={"AUTOMOTOR"}
                    performReset={performReset}
                    downloadPdfJsonTemplate={downloadPdfJsonTemplate}
                    clientsFieldName="clientes"
                />
            </FormCard>

            {errorModalOpen &&
                <ErrorModal
                    closeAction={() => {
                        if (currentFormData[0]['name'] === "#Cotización") { //Indicates an error during emision
                            setActiveStep(formData['cotizacion'].length)
                            setEmisionResponse(null)
                        } else {
                            setActiveStep(0)
                            setCotizacionResponse(null)
                        }
                        setErrorModalOpen(false)
                    }}
                    title={errorModalContent.title}
                    typeError={errorModalContent.typeError}
                    suggestedActions={errorModalContent.suggestedActions}
                    detailedError={errorModalContent.detailedError}
                />
            }

            {successModalOpen &&
                <SuccessModal
                    closeAction={() => {
                        setSuccessModalOpen(false)
                        setCotizacionResponse(null)
                        setEmisionResponse(null)
                        history.push({pathname: '/u/dashboard'});
                    }}
                    title={"¡Felicitaciones, la operación se ha realizado con éxito!"}
                    body={
                        emisionResponse['identificadores'].filter(v => v.key !== "certificateNumber").map(id => (
                            <div className="successModal__body__block">
                                <p className="successModal__subtitle">{id.nombre}</p>
                                <p className="successModal__text">{id.valor}</p>
                            </div>
                        ))
                    }
                    footer={
                        <div className="autem__success__footer__container">
                            <p className="autem__success__footer__subtitle">Documentación</p>
                            <Formik
                                initialValues={{documento: emisionResponse['documentacion'][0]}}
                                onSubmit={() => {
                                }}
                            >
                                {({isSubmitting, values, setSubmitting}) => (
                                    <Form method="post" style={{width: "70%"}}>
                                        <MantineSelectField
                                            name={`documento`}
                                            label={""}
                                            data={
                                                emisionResponse['documentacion'].map(doc => {
                                                    return {value: doc, label: doc}
                                                })
                                            }
                                        />
                                        <div className="autem__success__footer__button__container">
                                            <Button
                                                type="button"
                                                disabled={isSubmitting}
                                                variant="filled"
                                                color="primary"
                                                marginY="sm"
                                                enabled
                                                onClick={() => downloadDocumentation(values, setSubmitting)}
                                            >
                                                Descargar
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                            <p className="autem__success__footer__text">
                                Descargá los documentos y anotá los
                                números de identificación para próximas consultas.
                            </p>

                            <img className="autem__success__footer__logo"
                                 src={companiesDict[finalValues.company]['logo']}
                                 alt={finalValues.company}/>
                        </div>
                    }
                />
            }
        </div>
    );
}

function withAutomotorData(WrappedComponent) {
    return function WrappedWithAutomotorData(props) {
        const [loadingPage, setLoadingPage] = useState(true);
        const [errorPage, setErrorPage] = useState(null);
        const [automotorData, setAutomotorData] = useState(null);
        const {authToken} = useContext(AuthContext);

        useEffect(() => {
            fetchContextData([() => loadAutomotorData(authToken)], [setAutomotorData], setLoadingPage, ['automotorMarcas'])
                .then(errors => {
                        if (errors.length > 0) {
                            setErrorPage({
                                title: "Hubo un problema al cargar la información del usuario, por favor vuelva a intentarlo más tarde.",
                                typeError: "Ha ocurrido un error en la comunicación con el servicio",
                                suggestedActions: ["Reintentá el proceso una vez más", "Si el problema persiste ponete en contacto con el área de soporte"],
                                detailedError: errors[0].error.response?.data?.detail || "Sin datos del error",
                            })
                        }
                    }
                )
        }, [authToken]);

        if (loadingPage) return <ContextLoading/>

        if (errorPage) return <ErrorModal closeAction={() => setErrorPage(null)} title={errorPage.title}
                                          typeError={errorPage.typeError}
                                          suggestedActions={errorPage.suggestedActions}
                                          detailedError={errorPage.detailedError}/>

        return (
            <AutomotorContextProvider initialData={automotorData}>
                <WrappedComponent {...props} />
            </AutomotorContextProvider>
        );
    };
}

export default withAutomotorData(CotizarAutomotor);