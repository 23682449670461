import { useContext } from "react";
import { Button } from "../../../../components/Button";
import DownloadArrow from "../../../../components/icons/DownloadArrow/DownloadArrow";
import "./CompanySummaryCard.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import MasterContext from "../../../../context/MasterContext/MasterContext";

const CompanySummaryCard = ({company, details, products, benefits, links, extras}) => {
    const { companiesDict } = useContext(MasterContext)
    const companyLogo = companiesDict[company]['logo'];
    const showExtras = extras.files?.length > 0 || extras.prestigio?.length > 0;

    return (
        <div className="CompanySummaryCard__container">
            <div className="CompanySummaryCard__bigSection--container">
                <div className="CompanySummaryCard__info--container">
                    <div className="company logo">
                        <img className="CompanySummaryCard__info--logo" src={companyLogo} alt="aseguradora" />
                    </div>
                    <div className="CompanySummaryCard__info--section">
                        <div className="CompanySummaryCard__info--row-container">
                            {details.map((info, i) => (
                                <div className="CompanySummaryCard__info--row" key={i}>
                                    <span className="CompanySummaryCard__info--key">{Object.keys(info)[0]}: </span>
                                    <span className="CompanySummaryCard__info--value">{Object.values(info)[0]}</span>
                                </div>
                            ))}
                        </div>
                        <div className="CompanySummaryCard__button--wrapper">
                            <Link className="CompanySummaryCard__button--link" to={{ pathname: `https://${links.site}` }} target="_blank">
                                <Button color="primary" variant="filled" size="md" enabled>
                                    {links.site}
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="CompanySummaryCard__inventory--container">
                    <div className="CompanySummaryCard__inventory--section">
                        <h1 className="CompanySummaryCard__title">Productos</h1>
                        <div className="CompanySummaryCard__inventory--list">
                            {products.map((product, i) => (
                                <div className="CompanySummaryCard__inventory--listItem" key={i}>
                                    {product}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="CompanySummaryCard__inventory--section">
                        <h1 className="CompanySummaryCard__title">Beneficios</h1>
                        <div className="CompanySummaryCard__inventory--list">
                            {benefits.map((benefit, i) => (
                                <div className="CompanySummaryCard__inventory--listItem" key={i}>
                                    {benefit}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {showExtras &&
                <div className="CompanySummaryCard__brochure--container">
                    {extras.prestigio?.length > 0 &&
                        <div className="CompanySummaryCard__prestigio--container">
                            <h1 className="CompanySummaryCard__title">Prestigio Seguros:</h1>
                            <div className="CompanySummaryCard__prestigio--list">
                                {extras.prestigio.map((prestigio, i) => (
                                    <div key={i} className="CompanySummaryCard__prestigio--listItem">
                                        {prestigio}
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                    {extras.files?.length > 0 &&
                        <div className="CompanySummaryCard__brochure--files">
                            {extras.files.map((extra, i) => {
                                return (
                                    <div
                                        key={i}
                                        className="CompanySummaryCard__brochure--header">
                                        <h1 className="CompanySummaryCard__title">{extra.nombre}</h1>
                                        <a href={extra.url} target="_blank" rel="noreferrer">
                                            <DownloadArrow size="sm" />
                                        </a>
                                    </div>
                                )
                            })}
                        </div>
                    }
                </div>
            }
        </div>
    );
}

export default CompanySummaryCard;