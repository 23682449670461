import React, {useContext, useMemo} from 'react';
import './CoverageOverviewModal.css';
import {PopUpCardModal} from "../../../layouts/PopUpCardModal";
import {CoveragesOverviewTable} from "../CoveragesOverviewTable";
import {Button} from "../../../components/Button";
import UserContext from "../../../context/UserContext";
import {useFormikContext} from "formik";
import {format} from "date-fns";


function CoverageOverviewModal(props) {
    const {rowData, closeAction, buttonAction, resumeKeyConstructor} = props;
    const {detalle_marca, anio, detalle_version} = rowData;
    const {values} = useFormikContext();
    const {userCompanies} = useContext(UserContext);
    const userHasThisCompany = useMemo(() => userCompanies.includes(rowData.companyName), [userCompanies, rowData.companyName])
    let resumeName = "";

    if (resumeKeyConstructor) {
        resumeKeyConstructor.forEach((key) => {
            const splitKey = key.split(".");
            let valueToPush = "";
            if (splitKey.length === 1) {
                valueToPush = values[splitKey[0]] || splitKey[0];
                if (valueToPush instanceof Date) {
                    valueToPush = format(valueToPush, 'dd/MM/yyyy');
                }
            } else if (splitKey.length === 2) {
                valueToPush = values[splitKey[0]] ? values[splitKey[0]][splitKey[1]] : key;
            }
            resumeName = resumeName + " " + valueToPush;
        })
    } else {
        resumeName = `${detalle_marca} ${anio} - ${detalle_version}`
    }

    const detallesCobertura = {
        companies: [rowData.company],
        nombreCobertura: [rowData.nombre],
        premium: [rowData.premium],
        actualPremium: [rowData.actualPremium],
        plans: [rowData.id],
        franquicia: [rowData.franquicia],
        beneficios: rowData.benefits ? [rowData.benefits] : null,
    }
    return (
        <PopUpCardModal
            title={"Resumen de la cobertura"}
            body={
                <div className="com__container">
                    <CoveragesOverviewTable
                        nombreModelo={resumeName}
                        detallesCobertura={detallesCobertura}
                        title={""}
                    />
                </div>
            }
            footer={
                <div className="com__footer__container">
                    <Button variant="filled"
                            color="primary"
                            marginX="sm"
                            type="button"
                            enabled={userHasThisCompany}
                            onClick={buttonAction}>
                        Emitir
                    </Button>
                </div>
            }
            closeAction={closeAction}
        />
    );
}

export default CoverageOverviewModal;