import React from 'react';
import './Navigation.css';
import {Sidebar} from "../Sidebar";
import {Header} from "../Header";
import useWindowDimensions from "../../modules/utils/useWindowDimensions";

function Navigation({children}) {
    const {width} = useWindowDimensions();
    const isMobile = width < 480;

    return (
        <div className="app__container">
            <header className="app__header"><Header/></header>
            {!isMobile && <div className="app__sidebar"><Sidebar/></div>}
            <div className="app__main">{children}</div>
        </div>
    );
}

export default Navigation;