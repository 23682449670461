import formModel from './formModel';
import defaultLogo from "../../../../assets/logos/polko_primary.png";

const {
    formField: {
        logo,
        template,
        products,
        description,
        links
    }
} = formModel;

export default {
    [logo.name]: defaultLogo,
    [template.name]: 0,
    [products.name]: {
        "vehiculo": {
            "idCodigosAseguradoras": [],
            "active": false
        },
        "motovehiculo": {
            "idCodigosAseguradoras": [],
            "active": false
        },
        "hogar": {
            "idCodigosAseguradoras": [],
            "active": false
        },
        "microseguros": {
            "idCodigosAseguradoras": [],
            "active": false
        },
        "asistencia_viajero": {
            "idCodigosAseguradoras": [],
            "active": false
        }
    },
    [description.name]: [],
    [links.name]: [''],
};
