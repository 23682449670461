import React from 'react';
import MaterialLayout from '../../components/Layout/MaterialLayout';
import withRoot from "../../withRoot";
import CheckoutEmision from "./Checkout";
import { useHistory } from "react-router-dom";

function Emision(props) {
    const history = useHistory();

    return (
        <div>
            <MaterialLayout>
                {typeof props.location.state !== 'undefined'
                    ? (<CheckoutEmision
                        continueCotizacion={true}
                        emisionValues={props.location.state.emisionValues}
                        idCotizacion={props.location.state.idCotizacion}
                        additionalStep={props.location.state.emisionValues.additionalStep}
                    />)
                    : history.push('/u/dashboard')}
            </MaterialLayout>
        </div>
    )
}
export default withRoot(Emision);
