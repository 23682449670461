import React from 'react';

const CotizarContext = React.createContext({

    coberturasInfo:null,
    coberturasProductsInfo: null,
    companiesDict: null,
    genericOptionsFormasDePago: null,
    coberturasAdicionalesOrder: null,
    isReady: null

});

export default CotizarContext;