import React from 'react';
import './UIFFormWarn.css';
import {downloadFile} from "../../../modules/utils/downloadFile";
import {WarnBadge} from "../../../components/WarnBadge";
const BASE_DIC_LINK = "https://polko-images.s3.us-east-2.amazonaws.com/Formularios/"

function getFilename(company, typePerson) {
    switch (company) {
        case 1:
            if (typePerson === "0") {
                return "DIC_Sancor_Persona_Humana.pdf";
            } else if (typePerson === "1") {
                return "DIC_Sancor_Persona_Juridica.pdf";
            }
            break;
        case 3:
            return "DDJJ_Zurich.pdf";
        default:
            break;
    }
}

function UifFormWarn({tipoPersona, companyId}) {
    const fileName = getFilename(companyId, tipoPersona);
    const downloadLink = `${BASE_DIC_LINK}${fileName}`;
    return (
        <div className="uifWarn__container">
            <WarnBadge>
                <p className="uifWarn__text">
                    Usted está emitiendo un vehículo de alta gama, recuerde que deberá adjuntar la
                    <span>{" "}
                        <a className="uifWarn__link"
                           onClick={() => downloadFile(downloadLink, fileName)}>
                            Declaración Jurada de Identificación de Cliente
                </a>{" "}
            </span>
                    solicitada por la UIF.
                </p>
            </WarnBadge>
        </div>
    );
}

export default UifFormWarn;