import React, {useContext, useState} from 'react';
import {ErrorMessage, useFormikContext} from "formik";
import {Checkbox, Grid} from '@mantine/core';
import {IconBike, IconDeviceLaptop, IconDeviceTablet, IconLock} from "@tabler/icons";
import CotizarContext from "../../../../../../context/CotizarContext";
import {MantineNumberField} from "../../../../../../components/FormFields/MantineNumberField";
import UserContext from "../../../../../../context/UserContext";
import {CoberturasModel} from "./CoberturasModel";
import {ReactComponent as InformationFilledIcon} from "../../../../../../assets/icons/ic_info-filled.svg";
import {CoveragesInformationPopover} from "../../../../../Cotizacion/CoveragesInformationPopover";
import {splitTextInRange} from "../../../../../../modules/utils/splitTextInRange";
import {ZipCodeFieldPackage} from "../../../../../Cotizacion/ZipCodeFieldPackage";
import {useCurrentQuotationContext} from "../../../../../../context/CurrentQuotationContext/CurrentQuotationContext";

const coberturasAdicionalesIcons = {
    26: <IconLock className="microsegurosCot__icon"/>,
    48: <IconBike className="microsegurosCot__icon"/>,
    36: <IconDeviceLaptop className="microsegurosCot__icon"/>,
    37: <IconDeviceTablet className="microsegurosCot__icon"/>,
}

export default function CoberturasForm() {
    const {coberturasInfo, coberturasAdicionalesOrder} = useContext(CotizarContext);
    const {isMobile} = useContext(UserContext);
    const {formField} = CoberturasModel();
    const {codigosLocalidad, codigoPostal, idProvincia} = formField;
    const {values, setFieldValue} = useFormikContext();
    const [coberturaForPopover, setCoberturaForPopover] = useState(null);
    const {
        localidadOptions,
        setLocalidadOptions,
        provinciasOptions,
        setProvinciasOptions
    } = useCurrentQuotationContext();

    const updateLocalidadOptions = (newLocalidades) => {
        setLocalidadOptions((prevState) => {
            const newState =  [...prevState ];
            newState[0] = newLocalidades;
            return newState;
        });
    };

    return (
        <div className="microseguros__formStep__container">
            <Grid gutterXs="xs" gutter="md" align="center">
                <>
                    <ZipCodeFieldPackage localidadField={codigosLocalidad} localidadSize={12}
                                         codigoPostalField={codigoPostal} codigoPostalSize={6}
                                         provinciaField={idProvincia} provinciaSize={6}
                                         localidadOptions={localidadOptions[0] || []} setLocalidadOptions={updateLocalidadOptions}
                                         provinciasOptions={provinciasOptions} setProvinciaOptions={setProvinciasOptions}
                    />
                </>
                {coberturasAdicionalesOrder.map((cobertura) => {
                    const coberturaValue = values.coberturasAdicionales[cobertura];
                    return (
                        <>
                            <Grid.Col span="content"  xs={1} sm={1}>
                                <Checkbox
                                    checked={coberturaValue.selectedCobertura}
                                    classNames={{
                                        input: "microseguros__cotSuccess__checkbox"
                                    }}
                                    size="sm"
                                    onChange={(e) => {
                                        const {checked} = e.target;
                                        setFieldValue(`coberturasAdicionales.${cobertura}`, {
                                            selectedCobertura: checked,
                                            sumaAsegurada: coberturasInfo[cobertura].sumaChica
                                        });
                                    }}
                                />
                            </Grid.Col>
                            <Grid.Col span="content"  xs={6} sm={1}>
                                {coberturasAdicionalesIcons[cobertura]}
                            </Grid.Col>
                            <Grid.Col span="content"  xs={6} sm={5}
                                      style={{width: "50%"}}
                            >
                                <p className="microsegurosCot__text">{coberturasInfo[cobertura].descripcion}</p>
                            </Grid.Col>
                            {isMobile &&
                            <Grid.Col span="content" xs={1} sm={1}>
                                <div className="microseguros__coberturasForm__infoIcon__container" onClick={() => setCoberturaForPopover(cobertura)}>
                                    <InformationFilledIcon className="microseguros__coberturasForm__infoIcon"/>
                                </div>
                            </Grid.Col>
                            }
                            <Grid.Col xs={4} sm={4}
                                      style={{
                                          visibility: coberturaValue?.selectedCobertura ? "visible" : "hidden",
                                          height: isMobile
                                              ? (coberturaValue?.selectedCobertura ? "100%" : "0px")
                                              : "100%"
                                      }}>
                                <MantineNumberField
                                    name={`coberturasAdicionales.${cobertura}.sumaAsegurada`}
                                    min={coberturasInfo[cobertura]['sumaMinima']}
                                    max={coberturasInfo[cobertura]['sumaMaxima']}
                                />
                            </Grid.Col>
                            {!isMobile &&
                            <Grid.Col xs={1} sm={1}>
                                <div className="microseguros__coberturasForm__infoIcon__container" onClick={() => setCoberturaForPopover(cobertura)}>
                                    <InformationFilledIcon className="microseguros__coberturasForm__infoIcon"/>
                                </div>
                            </Grid.Col>
                            }
                        </>
                    )
                })}
            </Grid>

            <>
                <ErrorMessage name='coberturasAdicionales'
                              render={msg => <div
                                  className="microseguros__detForm__error"
                              >{msg}</div>}/>
            </>

            {coberturaForPopover &&
            <CoveragesInformationPopover
                onClose={() => setCoberturaForPopover(null)}
                title={coberturasInfo[coberturaForPopover].descripcion}
                descripcion={splitTextInRange(coberturasInfo[coberturaForPopover].descripcionCompleta, 350, 500)}
            />
            }
        </div>
    );
}
