import {formatPhoneNumberIntl} from "react-phone-number-input";
import normalizeString from "../../../utils/normalizeString";

const moment = require("moment");

export const emissionDataGenerator = (values, formaDePagoCompanyValue, companyEquipoGncId, userDetalleComisiones) => {
    const isCashPenalty = values.formaDePago === "EF" && userDetalleComisiones[values.company].AUTOMOTOR.hasOwnProperty("efectivo")
    for (const cliente of values.clientes) {
        if (values.company === "Federacion_Patronal") {
            const formattedPhoneNumber = formatPhoneNumberIntl(cliente.telefono).split(" ");
            cliente['telefono'] = {
                "codigoArea": formattedPhoneNumber[1],
                "caracteristica": formattedPhoneNumber[2],
                "numero": formattedPhoneNumber[3],
            }
        } else {
            cliente['codigoArea'] = formatPhoneNumberIntl(cliente.telefono).split(" ")[1]
        }

        if (cliente['tipoPersona'] === '1') { //Si es personaJurídica
            cliente['situacionImpositiva'] = '1'
            cliente['dni'] = cliente['cuit']
        } else {
            cliente['fechaNacimiento'] = moment(cliente['fechaNacimiento'], 'YYYY-MM-DD').format('YYYY-MM-DD')
        }
    }

    let bienesAdicionalesSend = []
    values.vehiculo.bienesAdicionales.forEach(bien => {
        bienesAdicionalesSend.push(bien)
    })
    if (values.vehiculo.tieneGnc === 'Si') {
        bienesAdicionalesSend.push({
            identificadorSancor: companyEquipoGncId,
            descripcion: values.vehiculo.gnc.descripcionGnc,
            monto: values.vehiculo.gnc.sumaAseguradaGnc
        })
    }

    let inspeccionPreviaData = {}

    if (values.inspeccionPrevia) {
        inspeccionPreviaData = {
            ...values.inspeccionPrevia,
            "comentariosInspeccion": values.inspeccionPrevia.daniosPreexistentes
                ? values.inspeccionPrevia.comentariosInspeccion
                : ""
        };
    }

    return {
        "vigenciaDesde": moment(values.vigenciaDesde, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        "vigenciaHasta": moment(values.vigenciaHasta, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        "formaDePago": formaDePagoCompanyValue,
        "codigoPostal": values.codigoPostal,
        "idProvincia": values.idProvincia,
        "infoDePago": {
            "numeroCbu": values.infoDePago.numeroCbu,
            "marcaTarjeta": values.infoDePago.marcaTarjeta,
            "numeroTarjeta": values.infoDePago.numeroTarjeta
        },
        "clientes": [
            ...values.clientes
        ],
        "comentariosSolicitud": normalizeString(values.comentariosSolicitud, ['\\', '#', '$', '%', '\'', '\"', '*', '{', '}'], false, ""),
        "vehiculo": {
            "marca": values.vehiculo.marca,
            "detalle_marca": values.vehiculo.detalle_marca,
            "modelo": values.vehiculo.modelo,
            "detalle_modelo": values.vehiculo.detalle_modelo,
            "anio": values.vehiculo.anio,
            "version": values.vehiculo.version,
            "detalle_version": values.vehiculo.detalle_version,
            "sumaAseguradaVehiculo": values.vehiculo.sumaAseguradaVehiculo,
            "patente": values.vehiculo.patente,
            "motor": values.vehiculo.motor,
            "chasis": values.vehiculo.chasis,
            "tieneGnc": values.vehiculo.tieneGnc === "Si" ? "true" : "false",
            "esCeroKm": values.vehiculo.esCeroKm,
            "gnc": {
                ...values.vehiculo.gnc
            },
            "inspeccionPrevia": inspeccionPreviaData,
            "bienesAdicionales": bienesAdicionalesSend
        },
        "idCobertura": values.idCobertura,
        "nombreCobertura": values.nombreCobertura,
        "franquicia": values.rowData.franquicia ?? null,
        "idCotizacion": values.idCotizacion,
        "company": values.company,
        "premio": Math.ceil(values.premio),
        "parametrosAdicionales": {
            ...values.parametrosAdicionales,
            'comision': isCashPenalty ? userDetalleComisiones[values.company].AUTOMOTOR.efectivo : userDetalleComisiones[values.company].AUTOMOTOR.general,
            'idViaCobro': formaDePagoCompanyValue,
            'idInstitucionFinanciera': values.formaDePago === 'TC' ? values.infoDePago.marcaTarjeta : formaDePagoCompanyValue
        }
    }
}