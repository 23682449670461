import React from 'react';
import * as Yup from "yup";
import moment from "moment/moment";

const CHARACTER_LIMIT = 280;

const CustomAgreementModel = () => {
    const formField = {
        cantidadEmisiones: {
            name: 'cantidadEmisiones',
            label: 'Cantidad de emisiones',
            requiredErrorMsg: 'Debe especificar la cantidad de emisiones'
        },
        cantidadMeses: {
            name: 'cantidadMeses',
            label: 'Cantidad de meses',
            requiredErrorMsg: 'Debe especificar la cantidad de meses'
        },
        motivo: {
            name: 'motivo',
            label: 'Motivo',
            requiredErrorMsg: 'Debe especificar una motivo'
        },
    }

    const initialValues = {
        [formField.cantidadEmisiones.name]: '',
        [formField.cantidadMeses.name]: '',
        [formField.motivo.name]: '',
    }


    const validationSchema = Yup.object().shape({
        [formField.cantidadEmisiones.name]: Yup.number()
            .required(`${formField.cantidadEmisiones.requiredErrorMsg}`)
            .integer()
            .min(1, "La cantidad de emisiones debe ser mayor a 0")
            .max(5000, "La cantidad de emisiones debe ser menor que 5000")
            .typeError("Ingrese un número válido"),
        [formField.cantidadMeses.name]: Yup.number().required(`${formField.cantidadMeses.requiredErrorMsg}`),
        [formField.motivo.name]: Yup.string()
            .required(`${formField.motivo.requiredErrorMsg}`)
            .max(CHARACTER_LIMIT, `El motivo no puede tener más de ${CHARACTER_LIMIT} caracteres`),
    })

    return {formField, initialValues, validationSchema}
}

export {CustomAgreementModel};
