import React from 'react';
import {
    Typography,
    Grid,
    DialogTitle,
    Dialog,
    DialogContent
} from "@material-ui/core";
import samplePhotos from "../../../../../assets/images/emision/SamplePhotos1.jpg"

export default function CarPhotosDialog(props) {
    const {onClose, open} = props;

    return (
        <Dialog onClose={onClose} open={open} maxWidth="md">
            <DialogTitle>¿Cómo deben tomarse las fotos del vehículo?</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant='subtitle1'>
                            Deben tomarse 4 (cuatro) fotos con luz diurna: desde una perspectiva frontal, otra posterior
                            y dos laterales.
                        </Typography>
                        <Typography variant='subtitle1'>
                            Por ejemplo:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{textAlign: "center", alignItems: "center"}}>
                        <img src={samplePhotos} alt="" style={{maxWidth: '100%', maxHeight: '100%'}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='subtitle1'>
                            NOTA: Si el auto es 0km puede adjuntar el certificado de no rodamiento en lugar de las
                            fotos.
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
