import React from 'react';
import './EnlacesColumn.css';
import TextLink from "../../../../components/TextLink/TextLink";

function EnlacesColumn(props) {
    const {row, setModalOpen} = props;
    const {qr: QR, pagina_url: URL} = row;

    const handleOpenModal = () => {
        setModalOpen({
            modalName: "Enlaces",
            values: {row, QR, URL}
        })
    }

    return (
        <>
            <TextLink
                size={"sm"}
                action={() => handleOpenModal()}
            >
                Ver Enlace
            </TextLink>
        </>
    );
}

export default EnlacesColumn;