import React, {useState} from 'react';
import {MultiSelect} from '@mantine/core';
import {useField} from "formik";
import './MantineMultiSelectField.css';
import {ReactComponent as ExclamationIcon} from "../../../assets/icons/ic_exclamation.svg";

function MantineMultiSelectField(props) {
    const [field, meta, helpers] = useField(props.name);
    const [value, setValue] = useState(field.value);
    const {setValue: setFieldValue, setTouched} = helpers;

    return (
        <MultiSelect
            {...props}
            value={value}
            maxDropdownHeight={200}
            nothingFound="Sin resultados"
            onChange={(v) => {
                setValue(v)
                setFieldValue(v)
                setTouched(props.name, false)
            }}
            error={meta.error && meta.touched && <div>{meta.error}</div>}
            classNames={{
                input: meta.error && meta.touched ? 'mantine__multiSelect__field--error' : 'mantine__multiSelect__field',
                item: 'mantine__select__item',
                label: meta.error && meta.touched ? 'mantine__select__label--error' : 'mantine__select__label',
            }}
            rightSection={
                meta.error && meta.touched && <ExclamationIcon className="mantine__select__icon"/>
            }
        />
    );
}

export default MantineMultiSelectField;