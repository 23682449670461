import React, {useEffect, useState} from 'react';
import {Input} from '@mantine/core';
import {useField} from 'formik';
import './MantineInputField.css';
import {ReactComponent as ExclamationIcon} from "../../../assets/icons/ic_exclamation.svg"

function MantineInputField(props) {
    const {label, name, onBlurAction, errorMessage} = props;
    const [field, meta, helpers] = useField(name);
    const [value, setValue] = useState(field.value);
    const {setValue: setFieldValue, setTouched} = helpers;

    useEffect(() => {
        setValue(field.value);
    }, [field.value]);

    return (
        <Input.Wrapper
            {...props}
            id={`wrapper-${name}`}
            label={label}
            error={meta.error && meta.touched && <div>{meta.error}</div>}
            classNames={{
                wrapper: 'mantine__input__wrapper',
                invalid: 'mantine__input__wrapper',
                label: errorMessage || (meta.error && meta.touched) ? 'mantine__input__label--error' : 'mantine__input__label',
                required: errorMessage || (meta.error && meta.touched) ? 'mantine__input__required--error' : 'mantine__input__required',
                error: 'mantine__input__error',
            }}
        >
            <Input id={name}
                   {...props}
                   value={value}
                   onBlur={(e) => {
                       setFieldValue(e.target.value)
                       setTouched(name, false)
                       if (onBlurAction) onBlurAction(e.target.value)
                   }}
                   onChange={(e) => setValue(e.target.value)}
                   classNames={{
                       input: errorMessage || (meta.error && meta.touched) ? 'mantine__input__field--error' : 'mantine__input__field',
                   }}
                   rightSection={
                       errorMessage !== null || (meta.error && meta.touched) && <ExclamationIcon className="mantine__input__icon"/>
                   }
            />
            {errorMessage && errorMessage !== "" && <Input.Error>{errorMessage}</Input.Error>}
        </Input.Wrapper>
    );
}

export default MantineInputField;