import React, {useState} from 'react';
import './SearchZipCodeModal.css';
import {PopUpCardModal} from "../../../layouts/PopUpCardModal";
import {Grid} from "@mantine/core";
import {useFormikContext} from "formik";
import {Button} from "../../../components/Button";
import {getLocalidadOptionsByMoreData} from "../utils/getLocalidadOptionsByMoreData";
import {MantineSelectField} from "../../../components/FormFields/MantineSelectField";
import {get} from "lodash";
import {provinciasList} from "../../../constants/auxPersonalData";

function SearchZipCodeModal(props) {
    const {
        codigosLocalidadField,
        codigoPostalField,
        provinciaField,
        setModalToSearch,
        localidadOptions,
        setLocalidadOptions,
        setProvinciasOptions
    } = props;
    const {values, setFieldValue} = useFormikContext();
    const [localidadName, setLocalidadName] = useState('');
    const [loading, setLoading] = useState(false);
    const actualLocalidadOptions = localidadOptions || [];

    const getLocalidadOptionsAsync = async () => {
        if (loading) return
        try {
            const response = await getLocalidadOptionsByMoreData(get(values, provinciaField.name), localidadName);
            setLocalidadOptions(response);
        } catch (error) {
            console.error('Error al obtener opciones de localidad', error);
        } finally {
            setLoading(false);
        }
    };

    const handleConfirmLocation = () => {
        const objetoDeseado = localidadOptions.find(obj => obj.value === get(values, codigosLocalidadField.name));
        const provinceSelected = provinciasList.find(provincia => provincia.value === objetoDeseado.idProvincia)
        setFieldValue(codigoPostalField.name, objetoDeseado.CodPostal);
        setFieldValue(provinciaField.name, provinceSelected.value);
        setProvinciasOptions([provinceSelected]);
        setModalToSearch(false);
    };

    const handleProvinceChange = () => {
        setLocalidadName('');
        setFieldValue(codigosLocalidadField.name, '');
        setLocalidadOptions([]);
    }

    const handleLocalidadChange = (event) => {
        setLocalidadName(event);

        if (event.length === 4) {
            getLocalidadOptionsAsync();
        }

        if (event.length < 4) {
            setLocalidadOptions([]);
        }
    };


    return (
        <PopUpCardModal
            title="Localizar Código Postal"
            body={
                <div className="searchZipCodeModal__container">
                    <Grid>
                        <Grid.Col xs={12} sm={6}>
                            <MantineSelectField
                                name={provinciaField.name}
                                label={provinciaField.label}
                                data={provinciasList}
                                withAsterisk
                                searchable
                                onChangeAction={handleProvinceChange}
                            />
                        </Grid.Col>
                        <Grid.Col xs={12} sm={6}>
                            <MantineSelectField
                                name={codigosLocalidadField.name}
                                label={codigosLocalidadField.label}
                                searchValue={localidadName}
                                onSearchChange={handleLocalidadChange}
                                data={actualLocalidadOptions}
                                searchable
                                withAsterisk
                                notShowData={actualLocalidadOptions.length === 0}
                                disabled={get(values, provinciaField.name) === ''}
                                filter={(value, item) => {
                                    if (actualLocalidadOptions.length === 0 || value.length < 4) {
                                        return true;
                                    }
                                    return item.label.toLowerCase().includes(value.toLowerCase().trim());
                                }}
                            />
                        </Grid.Col>
                    </Grid>
                </div>
            }
            closeAction={() => setModalToSearch(false)}
            footer={
                <div className="csm__button__container">
                    <Button variant="filled"
                            color="primary"
                            marginX="sm"
                            type="button"
                            enabled={get(values, codigosLocalidadField.name) !== ''}
                            onClick={() => handleConfirmLocation()}
                    >
                        Confirmar
                    </Button>
                </div>
            }
        />
    );
}

export default SearchZipCodeModal;