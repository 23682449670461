import React from "react";
import PdfIcon from "../../assets/images/PDF_icon.svg";

export default class Thumb extends React.Component {
    state = {
        loading: false,
        thumb: undefined,
    };

    componentWillReceiveProps(nextProps) {
        if (!nextProps.file) {
            return;
        }
        this.setState({loading: true}, () => {
            let reader = new FileReader();
            reader.onloadend = () => {
                this.setState({loading: false, thumb: reader.result});
                nextProps.values.vehiculo.inspeccionPrevia.archivos[nextProps.index].content = reader.result.split(',')[1];
            };
            reader.readAsDataURL(nextProps.file);
        });
    }

    render() {
        const {file} = this.props;
        const {loading, thumb} = this.state;

        if (!file) {
            return null;
        }

        if (loading) {
            return <p>loading...</p>;
        }

        return (
            <img
                style={{borderRadius: '10%', boxShadow: '0px 1px 1px 1px rgba(0,0,0,0.15)', objectFit: 'contain'}}
                src={file.type === 'application/pdf' ? PdfIcon : thumb}
                alt={file.name}
                className="img-thumbnail mt-2"
                height={125}
                width={125}
            />
        );
    }
}
