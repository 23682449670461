import moment from "moment";
import * as Yup from "yup";


const DetallesVehiculoModel = () => {
    const formField = {
        comentariosSolicitud: {
            name: 'comentariosSolicitud',
            label: 'Comentarios de la solicitud',
            requiredErrorMsg: ''
        },
        //   VEHICULO OBJ
        marca: {
            name: 'marca',
            label: 'Marca',
            requiredErrorMsg: ''
        },
        modelo: {
            name: 'modelo',
            label: 'Modelo',
            requiredErrorMsg: ''
        },
        version: {
            name: 'version',
            label: 'Versión',
            requiredErrorMsg: ''
        },
        anio: {
            name: 'anio',
            label: 'Año',
            requiredErrorMsg: 'Debe especificar el año del modelo'
        },

        sumaAseguradaVehiculo: {
            name: 'sumaAseguradaVehiculo',
            label: 'Suma asegurada del vehículo',
            requiredErrorMsg: 'Debe especificar la suma asegurada del vehículo'
        },
        patente: {
            name: 'patente',
            label: 'Patente',
            requiredErrorMsg: 'Debe especificar la patente'
        },
        motor: {
            name: 'motor',
            label: 'Número de motor',
            requiredErrorMsg: 'Debe especificar el número de motor'
        },
        chasis: {
            name: 'chasis',
            label: 'Número de chasis',
            requiredErrorMsg: 'Debe especificar el número de chasis'
        },
        esCeroKm: {
            name: 'esCeroKm',
            label: 'Vehículo 0 Km',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        tieneGnc: {
            name: 'tieneGnc',
            label: 'Tiene equipo de GNC',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        //    GNC OBJ [VEHICULO > GNC]
        sumaAseguradaGnc: {
            name: 'sumaAseguradaGnc',
            label: 'Suma asegurada del equipo GNC',
            requiredErrorMsg: 'Debe especificar el valor del equipo'
        },
        descripcionGnc: {
            name: 'descripcionGnc',
            label: 'Descripción',
            requiredErrorMsg: 'Debe completar la descripción'
        },
        marcaRegulador: {
            name: 'marcaRegulador',
            label: 'Marca del regulador',
            requiredErrorMsg: 'Debe especificar la marca del regulador'
        },
        numeroRegulador: {
            name: 'numeroRegulador',
            label: 'Número del regulador',
            requiredErrorMsg: 'Debe especificar el número del regulador'
        },
        //    CILINDROS OBJ [VEHICULO > GNC > CILINDROS]
        marcaCilindro: {
            name: 'marcaCilindro',
            label: 'Marca del cilindro',
            requiredErrorMsg: 'Debe especificar la marca del cilindro'
        },
        numeroCilindro: {
            name: 'numeroCilindro',
            label: 'Número del cilindro',
            requiredErrorMsg: 'Debe especificar el número del cilindro'
        },
        //    BIENESADICIONALES OBJ [VEHICULO > BIENESADICIONALES]
        identificadorSancor: {
            name: 'identificadorSancor',
            label: 'Bien adicional asegurado',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        descripcion: {
            name: 'descripcion',
            label: 'Descripción',
            requiredErrorMsg: 'Debe completar la descripción'
        },
        monto: {
            name: 'monto',
            label: 'Suma asegurada',
            requiredErrorMsg: 'Debe especificar el monto'
        },
        ableToHaveBienesAdicionales: {
            name: 'ableToHaveBienesAdicionales',
            label: '¿Desea agregar bienes adicionales?',
            requiredErrorMsg: 'Debe seleccionar una opción'
        }
    }

    const initialValues = {

        [formField.comentariosSolicitud.name]: '',
        vehiculo: {
            [formField.marca.name]: '',
            [formField.modelo.name]: '',
            [formField.version.name]: '',
            [formField.anio.name]: '',
            [formField.sumaAseguradaVehiculo.name]: '',
            [formField.patente.name]: '',
            [formField.motor.name]: '',
            [formField.chasis.name]: '',
            [formField.tieneGnc.name]: '',
            [formField.esCeroKm.name]: 'false',
            gnc: {
                [formField.sumaAseguradaGnc.name]: '',
                [formField.descripcionGnc.name]: '',
                [formField.marcaRegulador.name]: '',
                [formField.numeroRegulador.name]: '',
                cilindros: [
                    {
                        [formField.marcaCilindro.name]: '',
                        [formField.numeroCilindro.name]: '',
                    },
                ],
            },
            bienesAdicionales: [],
        },

    }

    const validationSchema = Yup.object().shape({
        [formField.comentariosSolicitud.name]: Yup.string()
            .matches(/^(?=[a-zA-Z0-9()_+=,.\/%$:\s-]*$)/, "Por favor, evite caracteres especiales"),
        vehiculo: Yup.object().shape({
            [formField.sumaAseguradaVehiculo.name]: Yup.number()
                .required(`${formField.sumaAseguradaVehiculo.requiredErrorMsg}`)
                .typeError("El valor ingresado no es válido"),
            [formField.patente.name]: Yup.string().when([formField.esCeroKm.name], {
                is: (val) => {
                    return (val !== 'true')
                },
                then:
                    Yup.string()
                        .required(`${formField.patente.requiredErrorMsg}`)
                        .matches(/^[a-zA-Z]{2,2}[0-9]{3,3}[a-zA-Z]{2,2}|[a-zA-Z]{3,3}[0-9]{3,3}$/, "La patente ingresada no es válida"),
            }),
            [formField.motor.name]: Yup.string()
                .required(`${formField.motor.requiredErrorMsg}`)
                .min(4, 'El número ingresado es muy corto')
                .matches(/^(?=[a-zA-Z0-9()_+=,.:\s-]*$)/, "Por favor, evite caracteres especiales")
                .max(20, "El número ingresado es muy largo"),
            [formField.chasis.name]: Yup.string()
                .required(`${formField.chasis.requiredErrorMsg}`)
                .matches(/^(?=[a-zA-Z0-9()_+=,.:\s-]*$)/, "Por favor, evite caracteres especiales")
                .min(4, 'El número ingresado es muy corto')
                .max(20, "El número ingresado es muy largo"),

            [formField.marca.name]: Yup.string().required(`${formField.marca.requiredErrorMsg}`),
            [formField.modelo.name]: Yup.string().required(`${formField.modelo.requiredErrorMsg}`),
            [formField.version.name]: Yup.string().required(`${formField.version.requiredErrorMsg}`),
            [formField.anio.name]: Yup.number()
                .required(`${formField.anio.requiredErrorMsg}`)
                .integer()
                .max(moment().format("YYYY"), "Ingrese un año válido")
                .typeError("Ingrese un año válido"),
            [formField.tieneGnc.name]: Yup.string().required(`${formField.tieneGnc.requiredErrorMsg}`),
            [formField.esCeroKm.name]: Yup.string().when([formField.anio.name], {
                is: moment().format('YYYY'),
                then: Yup.string().required(`${formField.esCeroKm.requiredErrorMsg}`),
            }),
            gnc: Yup.object().when([formField.tieneGnc.name, formField.ableToHaveBienesAdicionales.name], {
                is: (tieneGnc, ableToHaveBienesAdicionales) => tieneGnc === 'Si' && ableToHaveBienesAdicionales,
                then: Yup.object().shape({
                    [formField.descripcionGnc.name]: Yup.string()
                        .required(`${formField.descripcionGnc.requiredErrorMsg}`)
                        .matches(/^([A-Za-z0-9 -]+)$/, "Esta utilizando carácteres no válidos"),
                    [formField.marcaRegulador.name]: Yup.string()
                        .required(`${formField.marcaRegulador.requiredErrorMsg}`)
                        .matches(/^([A-Za-z0-9 -]+)$/, "Esta utilizando carácteres no válidos"),
                    [formField.numeroRegulador.name]: Yup.string()
                        .required(`${formField.numeroRegulador.requiredErrorMsg}`)
                        .matches(/^([A-Za-z0-9 -]+)$/, "Esta utilizando carácteres no válidos"),
                    [formField.sumaAseguradaGnc.name]: Yup.number()
                        .required(`${formField.sumaAseguradaGnc.requiredErrorMsg}`)
                        .min(30000, "El monto mínimo es $30.000")
                        .max(2000000, "El monto máximo es $2.000.000"),
                    cilindros: Yup.array().of(
                        Yup.object().shape({
                            [formField.marcaCilindro.name]: Yup.string()
                                .required(`${formField.marcaCilindro.requiredErrorMsg}`)
                                .matches(/^([A-Za-z0-9 -]+)$/, "Esta utilizando carácteres no válidos"),
                            [formField.numeroCilindro.name]: Yup.string()
                                .required(`${formField.numeroCilindro.requiredErrorMsg}`)
                                .matches(/^([A-Za-z0-9 -]+)$/, "Esta utilizando carácteres no válidos"),
                        })
                    )
                })
            }),
            bienesAdicionales: Yup.array().of(
                Yup.object().shape({
                    [formField.identificadorSancor.name]: Yup.string().required(`${formField.identificadorSancor.requiredErrorMsg}`),
                    [formField.descripcion.name]: Yup.string()
                        .required(`${formField.descripcion.requiredErrorMsg}`)
                        .matches(/^([A-Za-z0-9 -]+)$/, "Esta utilizando carácteres no válidos"),
                    [formField.monto.name]: Yup.number()
                        .required(`${formField.monto.requiredErrorMsg}`)
                        .min(30000, "El monto mínimo es $30.000")
                        .max(1000000, "El monto máximo es $1.000.000")
                        .typeError("El valor ingresado no es válido"),
                })
            )
                .test('sumatoria-menor-sumaAsegurada', 'La suma asegurada de los bienes adicionales no puede superar la suma asegurada del vehículo', function (bienesAdicionales) {
                    const sumaAsegurada = this.from[1].value.sumaAseguradaAdicional;
                    if (!sumaAsegurada || sumaAsegurada <= 0) {
                        return true;
                    }
                    const total = bienesAdicionales.reduce((acc, curr) => acc + curr.monto, 0);
                    return total <= sumaAsegurada;
                })
        })
    })

    return {formField, initialValues, validationSchema}
}

export {DetallesVehiculoModel};
