import React, {Fragment, useState} from 'react';
import {Snackbar, Typography} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ErrorIcon from '@material-ui/icons/Error';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import axios from "axios";
import {useAuth0} from "@auth0/auth0-react";
import {useHistory} from "react-router-dom";
import SmallLoading from "../../../components/SmallLoading";
import Alert from "@material-ui/lab/Alert";
import useStyles from './styles';

function EmisionSuccess(props) {
    const classes = useStyles();
    const {finalValues, emisionResponse, idCotizacion, setEmisionResponse, setActiveStep} = props;
    const history = useHistory();
    const [selectedDocument, setSelectedDocument] = useState(emisionResponse['status'] === "Pending"
        ? "ConstanciaCoberturaRC" : "Caratula");
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const {getIdTokenClaims} = useAuth0();

    const [loading, setLoading] = useState(false);

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null
    };

    const handleOpenAlert = (message) => {
        setOpenAlert(true);
        setAlertMessage(message);
    };
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
        setAlertMessage(null);
    };

    async function getDocument() {
        const claims = await getIdTokenClaims();
        const id_token = claims.__raw;
        setLoading(true)

        let url = process.env.REACT_APP_BACK_SERVER + '/documentacion/Combinado';

        let body = {
            "document": selectedDocument,
            "officialNumber": emisionResponse['officialNumber'],
            "referenceNumber": emisionResponse['referenceNumber'],
        };
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${id_token}`,
        };

        axios.post(url, body, {headers: headers}
        ).then(res => {
            setLoading(false)
            //FileDownload(res.data, `${selectedDocument}${emisionResponse['referenceNumber']}.pdf`);
            openInNewTab(res.data)
        }).catch( err => {
            setLoading(false)
            handleOpenAlert("Ocurrió un error al descargar el documento. " + err.toString())
            console.log(err);
        });
    }

    return (
        <Fragment>
            {emisionResponse['status'] !== "Failed"
                ? (
                    <Fragment>
                        <Typography variant="h5" gutterBottom>
                            Número de Referencia: {emisionResponse['referenceNumber']}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            Número Oficial de Póliza: {emisionResponse['status'] === "Pending"
                            ? "Pendiente"
                            : emisionResponse['officialNumber']}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            Número de Trámite: {emisionResponse['managementNumber']}
                        </Typography>
                        <Divider/>
                        <Grid container spacing={3} className={classes.gridContainer}>
                            <Grid item xs={12} sm={4} className={classes.centerText}>
                                <Typography variant="h6">
                                    Documentación
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="documentSelect">Documento</InputLabel>
                                    {emisionResponse['status'] === "Success"
                                        ? (
                                            <Select
                                                value={selectedDocument}
                                                onChange={(e) => setSelectedDocument(e.target.value)}
                                                name='documentSelect'
                                            >
                                                <MenuItem key={0} value={"Caratula"}>Póliza</MenuItem>
                                                {emisionResponse["DebitType"] === '0'   // Efectivo
                                                    ? <MenuItem key={1} value={"ReciboConCodigoDeBarra"}>Cupón de Pago</MenuItem>
                                                    : <MenuItem key={1} value={"Factura"}>Factura</MenuItem>
                                                }
                                                <MenuItem key={2} value={"ConstanciaDeCoberturaPE"}>
                                                    Certificado de Cobertura
                                                </MenuItem>
                                            </Select>
                                        )
                                        : (
                                            <Select
                                                value={selectedDocument}
                                                onChange={(e) => setSelectedDocument(e.target.value)}
                                                name='documentSelect'
                                            >
                                                <MenuItem key={0} value={"ConstanciaCoberturaRC"}>
                                                    Certificado de Cobertura Provisoria
                                                </MenuItem>
                                            </Select>
                                        )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} className={classes.centerText}>
                                {!loading ?
                                    <Button
                                        onClick={() => {
                                            getDocument()
                                        }}
                                        variant='contained'
                                        color="primary"
                                        className={classes.buttonContained}
                                    >
                                        Obtener
                                    </Button>
                                    :
                                    <SmallLoading /> }
                            </Grid>

                            {emisionResponse['status'] === "Pending" && (
                                <Fragment>
                                    <Divider />
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            El certificado de cobertura provisoria es un documento válido para constatar su cobertura.
                                            En el transcurso de 5 días hábiles estará disponible el resto de la documentación.
                                            En caso de que exista alguna irregularidad, un asesor se pondrá en contacto con usted.
                                        </Typography>
                                    </Grid>
                                </Fragment>
                            )}
                        </Grid>
                    </Fragment>
                )
                : (
                    <Fragment>
                        <Typography variant="h5" gutterBottom>
                            Ocurrió un error al procesar el pedido.
                        </Typography>
                        <List>
                            {emisionResponse?.errors.map((row) => (
                                <ListItem>
                                    <ListItemIcon>
                                        <ErrorIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={row['detail'] === 'Internal Error' ? 'Hay un problema con el servicio, vuelva a intentarlo más tarde.' : row['detail']}
                                    />
                                </ListItem>
                            ))}
                        </List>
                        <div className={classes.buttons}>
                            <Button
                                variant='contained'
                                color="primary"
                                className={classes.buttonContained}
                                onClick={() => {
                                    history.push({
                                        pathname: '/u/emitir/microseguro',
                                        state: {
                                            emisionValues: finalValues,
                                            idCotizacion: idCotizacion
                                        }
                                    })
                                    setEmisionResponse(null)
                                    setActiveStep(0)
                                }}
                            >
                                Volver al Formulario
                            </Button>
                        </div>
                    </Fragment>
                )}
            <Snackbar open={openAlert && alertMessage} autoHideDuration={5000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity="warning" elevation={6} variant="filled"
                       classes={{filledWarning: classes.alert}}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
        </Fragment>
    );
}

export default EmisionSuccess;
