import { Collapse } from '@mantine/core';
import './CardFrame.css';

const CardFrame = (props) => {
    const { children, withBanner, bannerContent, highlight, onClick, variant = null, collapsed = false } = props;

    return (
        <div className={`CardFrame__container ${highlight ? 'highlight' : ''} ${variant}`} onClick={onClick}>
            {children}
            {withBanner &&
                <div className="CardFrame_collapse--wrapper">
                    <Collapse in={!collapsed}>
                        <hr className={`CardFrame__divider ${highlight ? 'highlight' : ''} ${variant}`} />
                        <div className={`CardFrame__banner ${highlight ? 'highlight' : ''}`}>
                            {bannerContent}
                        </div>
                    </Collapse >
                </div>
            }
        </div>
    );
}

export default CardFrame;