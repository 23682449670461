const handleAdvanceRequotation = (setCotizacionResponse, tab, values, discounts, submitForm, cotizacionResponse, setPrevDiscounts, checkFedPatNewClient, setLoadingByNewClient) => {
    if (!checkFedPatNewClient) {
        setCotizacionResponse(prevState => {
            let dict = {...prevState}
            dict[tab] = null
            return dict
        })
    }
    setPrevDiscounts(discounts)

    const sendValues = {
        applyAdvancedConfiguration: true,
        companyToQuote: tab,
        idCotizar: cotizacionResponse.idCotizar,
        ...values
    }

    submitForm(sendValues, null, checkFedPatNewClient, setLoadingByNewClient)
}

export default handleAdvanceRequotation;