import React from 'react';
import './MantineMultiSelectFilter.css';
import { MultiSelect } from '@mantine/core';

function MantineMultiSelectFilter(props) {
    const {value, setValue, data, ...rest} = props;
    return (
        <MultiSelect
            value={value}
            onChange={setValue}
            data={data}
            classNames={{
                wrapper: 'mantine__multiSelectFilter__wrapper',
                input: 'mantine__multiSelectFilter__field',
                item: 'mantine__multiSelectFilter__item',
                rightSection: 'mantine__multiSelectFilter__rightSection',
                value: 'mantine__multiSelectFilter__value',
                values: 'mantine__multiSelectFilter__values',
                searchInput: 'mantine__multiSelectFilter__searchInput',
            }}
            {...rest}
        />
    );
}

export default MantineMultiSelectFilter;