import React, {useContext, useState} from 'react';
import moment from "moment";
import {Popover} from "@mantine/core";
import {QTableButtons, QTableMobileButtons} from "../TableButtons";
import CotizarContext from "../../../../context/CotizarContext";
import "./QuotationsTableColumns.css";
import {productIdentifier} from "../../../../modules/utils/productIdentifier";
import {productDescriptionGenerator} from "../../../../modules/utils/productDescriptionGenerator";

const QuotationsTableColumns = (props) => {
    const {setIsOpenDeleteAlert, setDetailedRow, setClientInfo, setOpenClientInfo} = props;
    const [openMoreInfoId, setOpenMoreInfoId] = useState(-1)
    const {companiesDict, coberturasInfo} = useContext(CotizarContext);
    const numberOfCompanies = Object.keys(companiesDict).length / 2;

    function getMoreDetails(productName, value) {
        switch (productName) {
            case "HOGAR":
                return (
                    <>
                        <p className="QT__popoverTitle">Suma asegurada por cobertura</p>
                        <ul className="QT__popoverList">
                            {value.coberturas.obligatorias.map(cobertura => (
                                <li key={cobertura.numeroCobertura}
                                    className="QT__popoverText">{coberturasInfo[cobertura.numeroCobertura].descripcion}:
                                    <span className="QT__popoverText--bold">${cobertura.sumaAsegurada}</span></li>
                            ))}
                            {value.coberturas.adicionales.map(cobertura => (
                                <li key={cobertura.numeroCobertura}
                                    className="QT__popoverText">{coberturasInfo[cobertura.numeroCobertura].descripcion}:
                                    <span className="QT__popoverText--bold">${cobertura.sumaAsegurada}</span></li>
                            ))}
                        </ul>
                    </>
                )
            case "MICROSEGUROS":
                return (
                    <>
                        <p className="QT__popoverTitle">Suma asegurada por cobertura</p>
                        <ul className="QT__popoverList">
                            {value.coberturas.adicionales.map(cobertura => (
                                <li key={cobertura.numeroCobertura}
                                    className="QT__popoverText">{coberturasInfo[cobertura.numeroCobertura].descripcion}:
                                    <span className="QT__popoverText--bold">${cobertura.sumaAsegurada}</span></li>
                            ))}
                        </ul>
                    </>
                )
            case "ASISTENCIA AL VIAJERO":
                return (
                    <div>
                        <p className="QT__popoverTitle">Pasajeros</p>
                        <ul className="QT__popoverList">
                            {value.asistencia_viajero.pasajeros.map((pasajero, index) => (
                                <li key={index}
                                    className="QT__popoverText">Pasajero {index + 1}: {pasajero.edad} años</li>
                            ))}
                        </ul>
                    </div>
                )
        }
    }

    return {
        Companies: {
            title: "",
            key: "companies",
            render: (value) => <div className="QT__generic__cell QT__companies__list">{value.map((logoIndex) =>
                logoIndex <= numberOfCompanies &&
                <img
                    className="QT__companies" src={companiesDict[logoIndex]['isologo']}
                    alt={companiesDict[logoIndex]['name']}/>)} </div>
        },
        Date: {
            title: "",
            key: "updated_at",
            render: (value) =>
                <span
                    className="QT__generic__cell QT__date">{moment(value).format('DD/MM/YYYY')}</span>
        },
        Product: {
            title: "",
            key: "row",
            render: (value) =>
                <span className="QT__generic__cell QT__product">{productIdentifier(value)}</span>
        },
        Detail: {
            title: "",
            key: "row",
            render: (value) => {
                const isClientSmallDetail = value.origen === "CLIENTES" ? "QT__clients__details--small" : "QT__clients__details";
                const hasMoreDetails = value.hogar || (!value.hogar && !value.vehiculo && !value.motovehiculo);
                const hasDetails = hasMoreDetails ? `${isClientSmallDetail} QT__clients__moreDetails` : isClientSmallDetail;
                const finalClass = `QT__generic__cell ${hasDetails}`;

                return (<Popover id={value._id} width={200} arrowPosition="center"
                                 position="bottom"
                                 withArrow shadow="md" radius="6px"
                                 opened={openMoreInfoId === value._id}
                                 onChange={() => setOpenMoreInfoId(value._id)}><Popover.Target
                    className={finalClass}><span
                    onClick={() => {
                        if (openMoreInfoId !== -1) {
                            setOpenMoreInfoId(-1)
                        } else {
                            setOpenMoreInfoId(value._id)
                        }
                    }}
                    onMouseEnter={() => setOpenMoreInfoId(value._id)}
                    onMouseLeave={() => setOpenMoreInfoId(-1)}>{productDescriptionGenerator(productIdentifier(value), value, coberturasInfo)}</span></Popover.Target>
                    {hasMoreDetails && <Popover.Dropdown>{
                        getMoreDetails(productIdentifier(value), value)
                    }
                    </Popover.Dropdown>}

                </Popover>)
            }
        },
        Client: {
            title: "",
            key: "row",
            render: (value) => {
                if (!value || value.cliente === null || value.cliente === undefined) return null;
                let displayName = ""
                let customerData = {}

                if (value.cliente.Nombre === undefined) {
                    displayName = `${value.cliente.nombre} ${value.cliente.apellido}`
                    customerData = {
                        nombre: value.cliente.nombre,
                        apellido: value.cliente.apellido,
                        telefono: value.cliente.telefono,
                        email: value.cliente.email
                    }
                } else {
                    displayName = `${value.cliente.Nombre} ${value.cliente.Apellido}`
                    customerData = {
                        nombre: value.cliente.Nombre,
                        apellido: value.cliente.Apellido,
                        telefono: value.cliente.Telefono,
                        email: value.cliente.Email
                    }
                }

                return (
                    <span
                        className="QT__generic__cell QT__client"
                        onClick={() => {
                            setClientInfo(customerData)
                            const detail = productDescriptionGenerator(productIdentifier(value), value, coberturasInfo);
                            const productCategory = productIdentifier(value);
                            setDetailedRow({detail: detail, date: value.created_at, product: productCategory})
                            setOpenClientInfo(true)
                        }}
                    >
                        {displayName}
                    </span>
                )
            }
        },
        Buttons: {
            title: "",
            key: "row",
            render: (value) => <QTableButtons valuesRow={value}
                                              setIsOpenDeleteAlert={setIsOpenDeleteAlert}
                                              setDetailedRow={setDetailedRow} product={productIdentifier(value)}
                                              detail={productDescriptionGenerator(productIdentifier(value), value, coberturasInfo)}/>
        },
        MobileButtons: {
            title: "",
            key: "row",
            render: (value) => <QTableMobileButtons valuesRow={value}
                                                    setIsOpenDeleteAlert={setIsOpenDeleteAlert}
                                                    setDetailedRow={setDetailedRow} product={productIdentifier(value)}
                                                    detail={productDescriptionGenerator(productIdentifier(value), value, coberturasInfo)}/>
        }
    }
}

export default QuotationsTableColumns;