import React, {useContext, useState} from 'react';
import './DesignTab.css';
import {CustomStepper} from "../../../layouts/CustomStepper";
import formInitialValues from "./FormModel/formInitialValues";
import {Form, Formik} from "formik";
import MasterContext from "../../../context/MasterContext";
import {Button} from "../../../components/Button";
import formModel from "./FormModel/formModel";
import AuthContext from "../../../context/AuthContext";
import {useMutation} from "@apollo/client";
import validationSchema from "./FormModel/validationSchema";
import VisualForm from "./Forms/VisualForm/VisualForm";
import DescripcionForm from "./Forms/DescripcionForm/DescripcionForm";
import RedesForm from "./Forms/RedesForm/RedesForm";
import LogoForm from "./Forms/LogoForm/LogoForm";
import {ReactComponent as BackIcon} from "../../../assets/icons/ic_arrow_left.svg";
import ProductsForm from "./Forms/ProductsForm/ProductsForm";
import {ErrorModal} from "../../../layouts/ErrorModal";
import {buildBaseDescriptions, buildInitialDescriptions} from "../../../modules/utils/descriptionObjectBuilder";
import {
    INSERT_NEW_DESCRIPTION,
    saveDescriptionsHandler,
    UPDATE_DESCRIPTION
} from "../../../modules/utils/saveDescriptionsHandler";
import {saveConfiguration} from "../Utils/saveConfiguration";
import {linkSorter} from "../Utils/linkSorter";
import {getAllowedProducts} from "../Utils/getAllowedProducts";
import {productsGeneratorWithCheck} from "../Utils/productsGeneratorWithCheck";
import UserContext from "../../../context/UserContext";

const {formId, formField} = formModel;


function _renderStepContent(step, setActiveStep, editRow) {
    switch (step) {
        case 0:
            return <VisualForm formField={formField} setActiveStep={setActiveStep}/>;
        case 1:
            return <LogoForm formField={formField}/>;
        case 2:
            return <ProductsForm formField={formField} isEdit={editRow !== null}/>;
        case 3:
            return <DescripcionForm formField={formField}/>;
        case 4:
            return <RedesForm formField={formField}/>;
    }
}

function DesignTab(props) {
    const {editRow, idPolkistaToCreate, setActiveTab, setEditRow} = props;
    const {
        descripciones,
        handleModified,
        defaultDescripcionesId,
        idPolkistaMaster,
        masterPolkistas,
        companiesDict
    } = useContext(MasterContext);
    const {userDiscounts, userDetalleComisiones} = useContext(UserContext);
    const {authToken} = useContext(AuthContext);
    const [activeStep, setActiveStep] = useState(0);
    const stepLabels = ["Diseño", "Logo", "Productos", "Contenido", "Redes"];
    const stepKeys = ["visual", "logo", "products", "description", "links"];
    const isLastStep = activeStep === stepLabels.length - 1;
    const [insertDescription] = useMutation(INSERT_NEW_DESCRIPTION);
    const [updateDescription] = useMutation(UPDATE_DESCRIPTION);
    const currentValidationSchema = validationSchema[activeStep];
    const [errorContent, setErrorContent] = useState(null);
    const [loading, setLoading] = useState(false);

    let modifiedInitialValues;

    if (editRow) {
        const customDescrFiltered = editRow['descripcion'].filter(item => !defaultDescripcionesId.includes(item));
        const customDescription = customDescrFiltered.length > 0 ? descripciones[customDescrFiltered[0]] : '';

        //TODO: Import formField and use names from model
        modifiedInitialValues = {
            "logo": editRow.logo,
            "template": editRow.template,
            "products": productsGeneratorWithCheck(
                editRow.productos,
                getAllowedProducts(masterPolkistas[idPolkistaToCreate]['codigos_aseguradoras'], companiesDict),
                userDiscounts,
                userDetalleComisiones
            ),
            "description": buildInitialDescriptions(editRow.descripcion, defaultDescripcionesId, descripciones, descripciones[editRow.descripcion.find(id => !defaultDescripcionesId.includes(id))]),
            "customDescription": customDescription,
            "links": linkSorter(editRow.redes_sociales),
            "idPolkistaToCreate": editRow['id_polkista'],
        }
    } else {
        modifiedInitialValues = {
            ...formInitialValues,
            "products": getAllowedProducts(masterPolkistas[idPolkistaToCreate]['codigos_aseguradoras'], companiesDict),
            "description": buildBaseDescriptions(descripciones, defaultDescripcionesId),
            "idPolkistaToCreate": idPolkistaToCreate,
        };
    }

    async function _submitForm(values) {
        setLoading(true);

        //Transform products object to match backend format
        let transformedProducts = JSON.parse(JSON.stringify(values.products));
        for (let producto in transformedProducts) {
            const idCodigosAseguradoras = transformedProducts[producto].idCodigosAseguradoras;
            idCodigosAseguradoras.forEach((valor, indice) => {
                idCodigosAseguradoras[indice] = parseInt(valor);
            });
        }

        let jsonData = {
            "logo": values.logo,
            "template": values.template,
            "productos": transformedProducts,
            "descripcion": values.description.map(descr => {
                if (descr.selected && descr.variant === 'option')
                    return parseInt(descr.id)
            }).filter(i => i),
            "idMaster": idPolkistaMaster,
            "links": values.links.filter(l => l !== "")
        }

        const customDescriptionObj = values.description.find(d => d.id === '+');

        jsonData = await saveDescriptionsHandler(
            customDescriptionObj,
            descripciones,
            defaultDescripcionesId,
            editRow !== null ? editRow['descripcion'] : null,
            insertDescription,
            updateDescription,
            jsonData //Requires a key called 'descripcion': [Int]
        )

        if (editRow) {
            jsonData = {
                ...jsonData,
                "idCanalDigital": editRow['id'],
                "idPolkista": editRow['id_polkista'],
            }
        } else {
            jsonData = {
                ...jsonData,
                "idPolkista": idPolkistaToCreate,
            }
        }

        await saveConfiguration(jsonData, authToken, editRow)
            .then(() => {
                handleModified();
                setEditRow(null);
                setActiveTab(0);
            })
            .catch(err => {
                setErrorContent({
                    title: "Hubo un problema al subir los valores.",
                    typeError: err.data.detail.code || "Ha ocurrido un error en la comunicación con el servidor",
                    suggestedActions: ["Reintentá el proceso una vez más", "Si el problema persiste ponete en contacto con el área de soporte"],
                    detailedError: err.data.detail.message || "Sin datos del error.",
                })
                setActiveStep(0)
            }).finally(() => {
                setLoading(false);
            });
    }

    function _handleSubmit(values, setTouched) {
        if (isLastStep) {
            _submitForm(values);
        } else {
            setActiveStep(activeStep + 1);
            setTouched({});
            setLoading(false);
        }
    }

    function _handleBack() {
        setActiveStep(activeStep - 1);
    }

    return (
        <div className="DTab__container">
            <div className="DTab__stepperContainer">
                {activeStep > 0 && <BackIcon className="DTab__backIcon" onClick={_handleBack}/>}
                <CustomStepper labels={stepLabels} activeStep={activeStep}/>
            </div>
            <Formik
                initialValues={modifiedInitialValues}
                validationSchema={currentValidationSchema}
                validateOnBlur={false}
                onSubmit={_handleSubmit}
            >
                {({errors, values, setTouched }) => (
                    <Form id={formId}>
                        {_renderStepContent(activeStep, setActiveStep, editRow)}
                        <div className="DTab__buttonContainer">
                            <Button variant="filled" color="primary"
                                    type='button'
                                    onClick={() => _handleSubmit(values, setTouched)}
                                    enabled={!errors[stepKeys[activeStep]]}
                                    loading={loading}
                            >
                                {isLastStep ? 'Guardar' : "Continuar"}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
            {errorContent &&
            <ErrorModal
                closeAction={() => {
                    setLoading(false)
                    setErrorContent(null)
                }}
                title={errorContent.title}
                typeError={errorContent.typeError}
                suggestedActions={errorContent.suggestedActions}
                detailedError={errorContent.detailedError}/>
            }
        </div>
    );
}

export default DesignTab;