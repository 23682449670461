import { makeStyles } from '@material-ui/core/styles';
export default makeStyles(theme => ({
  boxPrice: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "120px",
    backgroundColor: "#452d7b"
  },
  priceTitle: {
    fontFamily: 'Nunito',
    fontWeight: 400,
    fontSize: 18,
    color: "#fff"
  },
  priceText: {
    fontFamily: 'Nunito',
    fontWeight: 700,
    fontSize: 32,
    color: "#fff"
  },
  tableHeader: {
    fontFamily: 'Montserrat',
    fontSize: 20
  },
  tableRowLabel: {
    fontWeight: 500,
    fontSize: 18,
    color:"#000",
    fontFamily: 'Nunito'
  },
  tableRowData: {
    fontWeight: 300,
    fontSize: 14,
    color:"#000",
    fontFamily: 'Nunito'
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  buttonContained: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    backgroundColor: '#452d7b',
    '&:hover': {
      backgroundColor: '#38217b',
      boxShadow: 'none',
    },
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%'
  },
  step: {
    "& $completed": {
      color: '#452d7b'
    },
    "& $active": {
      color: '#452d7b'
    },
  },
  active: {},
  completed: {},
  alert: {
    backgroundColor: theme.palette.warning.dark
  },
}));
