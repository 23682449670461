import Typography from "../components/Typography";
import Link from "@material-ui/core/Link";
import React from "react";

const copyrightGenerator = () => {
    return (
        <Typography
            style={{
                fontFamily: "Montserrat",
                fontSize: 14,
                fontWeight: "normal",
                fontStyle: "normal",
                letterSpacing: 0,
                color: '#8a8597'
            }}
            align="center">
            <Link color="inherit" href="https://www.polko.com.ar/">
                POLKO SAS®
            </Link>{' '}
            {'Copyright © '}


            {new Date().getFullYear()}
            {'.'}
            {'Todos los derechos reservados.'}
        </Typography>
    );
}

export {copyrightGenerator};