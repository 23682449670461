import React, {useContext, useState} from 'react';
import './WithdrawFundsModal.css';
import {CustomModal} from "../../../components/CustomModal";
import {ReactComponent as CloseIcon} from "../../../assets/icons/ic_close.svg";
import {MainTable} from "../../../components/MainTable";
import {PopUpCard} from "../../../components/PopUpCard";
import UserContext from "../../../context/UserContext";
import {TextLink} from "../../../components/TextLink";
import {Button} from "../../../components/Button";
import {currencyFormat} from "../../../modules/utils/currencyFormat";
import axios from "axios";
import AuthContext from "../../../context/AuthContext";
import {useHistory} from "react-router-dom";
import {Loading} from '../../../components/Loading';


const MINAMOUNTTOEXTRACT = 6000;

function WithdrawFundsModal(props) {
    const {
        onClose,
        totalAmount,
        emmissionAmount,
        othersAmount,
        polizas,
        setErrorModalContent,
        setErrorModalOpen,
        setSuccessModalOpen,
        setLoading
    } = props;
    const [isLoading, setIsLoading] = useState(false);
    const {userCBU, userCUIT, userName, userLastName} = useContext(UserContext)
    const {authToken} = useContext(AuthContext);
    const history = useHistory();
    const allowToExtract = userCBU && totalAmount >= MINAMOUNTTOEXTRACT;
    const withdrawFundsColumns = [
        {title: "Fecha", key: "fecha", render: (value) => <span>{value}</span>},
        {title: "Nº de poliza", key: "numeroPoliza", render: (value) => <span>{value}</span>},
        {title: "Nº de trámite", key: "numeroTramite", render: (value) => <span>{value}</span>},
        {title: "Comisión", key: "comision", render: (value) => <span>${value}</span>},
    ];

    const withdrawOtrosFundsColumns = [
        {title: "Fecha", key: "fecha", render: (value) => <span>{value}</span>},
        {title: "Descripción", key: "descripcion", render: (value) => <span>{value}</span>},
        {title: "Comisión", key: "comision", render: (value) => <span>${value}</span>},
    ];

    const handleExtraccion = async () => {
        setIsLoading(true);
        let url = process.env.REACT_APP_BACK_SERVER + '/newOperacion';

        const jsonData = {
            "polkista": {
                "cvu": userCBU,
                "cuit": userCUIT,
                "nombre": `${userName} ${userLastName}`,
            },
            "operaciones": polizas
        }

        axios.post(url, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            }
        }).then(() => {
            setLoading(true);
            setSuccessModalOpen(true);
        }).catch((err) => {
            setErrorModalContent({
                title: "Hubo un problema al solicitar los fondos, por favor vuelva a intentarlo más tarde.",
                typeError: err.response?.data?.detail?.code || "Ha ocurrido un error en la comunicación con el servicio",
                suggestedActions: ["Reintentá el proceso una vez más", "Si el problema persiste ponete en contacto con el área de soporte"],
                detailedError: err.response?.data?.detail?.message || "Sin datos del error",
            })
            setErrorModalOpen(true);
        }).finally(() => {
            onClose();
            setIsLoading(false)
        });
    }

    return (
        <CustomModal>
            <PopUpCard>
                <div className="witFundModal__container">

                    <div className="witFundModal__header">
                        <div className="witFundModal__icon__container" onClick={onClose}>
                            <CloseIcon className="witFundModal__icon__close"/>
                        </div>
                        <p className="witFundModal__title">
                            Confirmar el retiro de fondos
                        </p>
                    </div>

                    <div className="witFundModal__body">
                        <div>
                            <p className="witFundModal__subtitle">Monto a extraer</p>
                            <p className="witFundModal__amount">${currencyFormat(totalAmount)}</p>
                        </div>

                        <hr/>

                        <div className="witFundModal__table__container">
                            {isLoading
                                ? <div className="witFundModal__loading__container">
                                    <Loading/>
                                </div>
                                : <>
                                    <p className="witFundModal__subtitle witFundModal__margin--bottom">Resumen</p>
                                    <p className="witFundModal__subtitle witFundModal__subtitle--reference">Emisiones</p>
                                    <div className="witFundModal__table">
                                        <MainTable
                                            columns={withdrawFundsColumns}
                                            rows={polizas.emisiones}
                                            pagination={polizas.emisiones.length > 5}
                                            itemsPerPage={5}
                                        />
                                        <p className="witFundModal__subtitle witFundModal__subtotal">Subtotal:
                                            ${currencyFormat(emmissionAmount)}</p>
                                    </div>

                                    <p className="witFundModal__subtitle witFundModal__subtitle--reference">Otras
                                        operaciones</p>
                                    <div className="witFundModal__table">
                                        <MainTable
                                            columns={withdrawOtrosFundsColumns}
                                            rows={polizas.otros_incentivos}
                                            pagination={polizas.otros_incentivos.length > 5}
                                            itemsPerPage={5}
                                        />
                                        <p className="witFundModal__subtitle witFundModal__subtotal">Subtotal:
                                            ${currencyFormat(othersAmount)}</p>
                                    </div>
                                </>
                            }
                        </div>

                        <hr/>

                        <div className="witFundModal__cbuContainer">
                            <p className="witFundModal__subtitle witFundModal__margin--top">Tu CBU/CVU: {userCBU}</p>
                            <TextLink size={"md"} action={() => history.push('/u/perfil')}>
                                Modificar
                            </TextLink>
                        </div>
                    </div>

                    <div className="witFundModal__footer">
                        <Button variant="filled"
                                color="secondary"
                                marginX="sm"
                                enabled={allowToExtract}
                                type="button"
                                fullWidth
                                onClick={() => handleExtraccion()}>
                            Extraer
                        </Button>
                        {!allowToExtract &&
                            <p className="witFundModal__legendText">
                                El monto mínimo de extracción es de ${currencyFormat(MINAMOUNTTOEXTRACT)}
                            </p>
                        }
                        {!userCBU &&
                            <p className="witFundModal__errorText">Clickee en modificar para añadir su CBU/CVU</p>}
                    </div>
                </div>
            </PopUpCard>
        </CustomModal>
    );
}

export default WithdrawFundsModal;