import React, {useContext, useEffect, useReducer, useState} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '../../components/Button';
import Typography from '../../components/Typography';
import imagenBalizas from '../../../assets/images/productos/balizas.png';
import imagenCasa from '../../../assets/images/productos/Producto _ Casa.png';
import proximamente from '../../../assets/images/productos/proximamente.png';


import {Grid, Modal} from "@material-ui/core";
import {Gif} from "@material-ui/icons";

const styles = (theme) => ({
        background: {
            width: '98vw',
            maxWidth: '1400px',
        },
        rectangulo: {
            marginBottom: '32px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '98vw',
            maxWidth: '1400px',
            height: 208,
            backgroundColor: "#ffffff",
            shadowColor: "rgba(70, 70, 70, 0.15)",
            shadowOffset: {
                width: 0,
                height: 5
            },
            shadowRadius: 40,
            shadowOpacity: 1,

        },
        titulo: {
            fontFamily: "Ubuntu",
            fontSize: 24,
            fontWeight: "bold",
            fontStyle: "normal",
            letterSpacing: -0.5,
            color: '#452d7b',
        },
        subtitulo: {
            fontFamily: "Montserrat",
            fontSize: 14,
            fontWeight: "normal",
            fontStyle: "normal",
            letterSpacing: 0,
            textAlign: "justify",
            color: '#8a8597'
        },

        botonGridTexto: {
            fontFamily: "Ubuntu",
            fontSize: 20,
            fontWeight: "bold",
            fontStyle: "normal",
            letterSpacing: 0,
            textAlign: "center",
            color: "#ffffff",
            textShadowColor: "rgba(82, 82, 82, 0.15)",
            textShadowOffset: {
                width: 0,
                height: 1
            },
            textShadowRadius: 2
        },
        botonGridTexto1: {
            fontFamily: "Ubuntu",
            fontSize: 20,
            fontWeight: "bold",
            fontStyle: "normal",
            letterSpacing: 0,
            textAlign: "center",
            color: '#452d7b',
            textShadowColor: "rgba(82, 82, 82, 0.15)",
            textShadowOffset: {
                width: 0,
                height: 1
            },
            textShadowRadius: 2
        },

        botonGridTexto2: {
            fontFamily: "Ubuntu",
            fontSize: 20,
            fontWeight: "bold",
            fontStyle: "normal",
            letterSpacing: 0,
            textAlign: "center",
            color: "#ffffff",
            textShadowColor: "rgba(82, 82, 82, 0.15)",
            textShadowOffset: {
                width: 0,
                height: 1
            },
            textShadowRadius: 2
        },
        botonGrid1: {
            backgroundColor: '#62c08a',
            boxShadow: 'inset 0 0 8px 0 rgba(112, 232, 163, 0.5)',
            width: '160px',
            '&:hover': {
                backgroundColor: '#4fa477',
                borderColor: '#4fa477',
                boxShadow: 'none',
            },
            marginRight: '16px',


        },
        botonGrid2: {
            backgroundColor: '#973379',
            width: '160px',
            boxShadow: 'inset 0 0 8px 0 rgba(112, 232, 163, 0.5)',
            '&:hover': {
                backgroundColor: '#7a2862',
                borderColor: '#7a2862',
                boxShadow: 'none',
            },
            marginRight: '16px',
        },
        botonGrid3: {
            width: '160px',
            height: '60px',
            borderStyle: "solid",
            borderWidth: 3,
            borderColor: '#452d7b',
            marginRight: '16px',
        },
        botonGrid4: {
            backgroundColor: '#35303f',
            marginRight: '16px',
            boxShadow: 'inset 0 0 8px 0 rgba(112, 112, 163, 0.5)',
            '&:hover': {
                backgroundColor: '#554f62',
                borderColor: '#554f62',
                boxShadow: 'none',
            },
        },
        proximamente:{
            width: 40,
            height: 166,
            transform:"rotate(-315deg)",
            backgroundColor: "#e42222",
            position: 'absolute',
            top: -70,
            left: 5
        },

    }
);

function LandingProductos(props) {

    const {classes} = props;

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            paddingLeft: '70px',
            paddingRight: '70px',
            width: '100%',
            backgroundColor: '#f5f6f8',
            marginBottom: '0px',
            paddingTop: '40px'
        }}>
            <Grid container direction={"column"} md={12} style={{
                display: 'flex', justifyContent: 'center',

            }}>
                <Grid item style={{
                    display: 'flex', justifyContent: 'center',
                }}>
                    <Grid container direction={'row'} md={12} className={classes.rectangulo}>
                        <Grid item md={2} xs={12} style={{paddingLeft: '20px'}}>
                            <div style={{position: 'relative'}}>
                                <img src={imagenBalizas} style={{width: '150px', height: '150px', margin: '20px'}}/>
                            </div>
                        </Grid>

                        <Grid item>
                            <Grid container direction={"column"}>
                                <Grid item>
                                    <Typography className={classes.titulo}>Seguro Automotor</Typography>
                                    <Typography className={classes.subtitulo}>Elegí el seguro automotor que mejor se
                                        adapte a
                                        las necesidades de tus clientes.</Typography>
                                </Grid>
                                <Grid item style={{marginTop: '40px'}}>
                                    <Button className={classes.botonGrid1} href={'/u/cotizar/vehiculo'}>
                                        <Typography className={classes.botonGridTexto}>
                                            Cotizar
                                        </Typography>
                                    </Button>

                                    <Button className={classes.botonGrid2} href={'/u/cotizaciones'}>
                                        <Typography className={classes.botonGridTexto}>
                                            Emitir
                                        </Typography>
                                    </Button>

                                    <Button className={classes.botonGrid3} href={'/u/documentacion'} >
                                        <Typography className={classes.botonGridTexto1} >
                                            Consultar
                                        </Typography>
                                    </Button>



                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item style={{
                    display: 'flex', justifyContent: 'center',
                }}>
                    <Grid container direction={'row'} md={12} className={classes.rectangulo}>
                        <Grid item md={2} xs={12} style={{paddingLeft: '20px'}}>
                            <div style={{position: 'relative'}}>
                                <img src={imagenCasa} style={{width: '150px', height: '150px', margin: '20px', display:'block'}}/>
                                <img src={proximamente} style={{
                                    width: '80px',
                                    height: '80px',
                                    position: 'absolute',
                                    top: 0,
                                    left: 20}}/>
                            </div>
                        </Grid>

                        <Grid item>
                            <Grid container direction={"column"}>
                                <Grid item>
                                    <Typography className={classes.titulo}>Seguro del Hogar</Typography>
                                    <Typography className={classes.subtitulo}>Elegí el seguro para el hogar que mejor se
                                        adapte a las necesidades de tus clientes.</Typography>
                                </Grid>
                                <Grid item style={{marginTop: '40px'}}>
                                    <Button className={classes.botonGrid1} href={'/u/cotizar/combinado'}>
                                        <Typography className={classes.botonGridTexto}>
                                            Cotizar
                                        </Typography>
                                    </Button>

                                    <Button className={classes.botonGrid2} href={'/u/cotizaciones'} disabled={true}>
                                        <Typography className={classes.botonGridTexto}>
                                            Emitir
                                        </Typography>
                                    </Button>

                                    <Button className={classes.botonGrid3} disabled={true}>
                                        <Typography className={classes.botonGridTexto1}>
                                            Consultar
                                        </Typography>
                                    </Button>



                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

LandingProductos.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LandingProductos);
