export const CardNumberValidator = (cardName, numeroTarjeta) => {
    const NARANJA = /^(589562|402917|402918|527571|527572|0377798|0377799)[0-9]*$/;
    const AMEX = /^(34|37)\d+$/;
    const isMastercard = new RegExp("\\b" + "Mastercard" + "\\b");
    const isVisa = new RegExp("\\b" + "Visa" + "\\b");
    const masterCardTest = /^(222[1-9]|22[3-6][0-9]|27[01][0-9]|2720)|^(5[0-5])/;
    const visaTest = /^4/;

    if (cardName === "Naranja") {
        return NARANJA.test(numeroTarjeta);
    } else if (cardName === "Amex" || cardName === "American Express") {
        return AMEX.test(numeroTarjeta)
    } else if (isMastercard.test(cardName)) {
        return masterCardTest.test(numeroTarjeta)
    } else if (isVisa.test(cardName)) {
        return visaTest.test(numeroTarjeta)
    }
    else {
        return luhnCheck(numeroTarjeta)
    }

    function luhnCheck(val) {
        let sum = 0;
        for (let i = 0; i < val.length; i++) {
            let intVal = parseInt(val.substr(i, 1));
            if (i % 2 === 0) {
                intVal *= 2;
                if (intVal > 9) {
                    intVal = 1 + (intVal % 10);
                }
            }
            sum += intVal;
        }
        return (sum % 10) === 0;
    }
};