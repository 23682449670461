import React, {useContext, useEffect} from 'react';
import {Grid} from '@mantine/core';
import {MantineSelectField} from "../../../../../../components/FormFields/MantineSelectField";
import CotizarContext from "../../../../../../context/CotizarContext";
import {InformacionModel} from "./InformacionModel";
import {useFormikContext} from "formik";
import {ZipCodeFieldPackage} from "../../../../../Cotizacion/ZipCodeFieldPackage";
import {useCurrentQuotationContext} from "../../../../../../context/CurrentQuotationContext/CurrentQuotationContext";

export default function InformacionForm(props) {
    const {formField} = InformacionModel();
    const {
        tamanioVivienda,
        tipoVivienda,
        codigosLocalidad,
        codigoPostal,
        idProvincia
    } = formField;
    const {
        localidadOptions,
        setLocalidadOptions,
        provinciasOptions,
        setProvinciasOptions
    } = useCurrentQuotationContext();
    const {isRecotizar} = props;
    const {coberturasInfo} = useContext(CotizarContext);
    const {values, setFieldValue} = useFormikContext();

    const tiposViviendas = [
        {value: '1021', label: 'Casa'},
        {value: '1022', label: 'Departamento'},
        {value: '1023', label: 'Vivienda en barrio cerrado'}
    ];

    const tamaniosVivienda = [
        {value: 'Chica', label: 'Pequeña (Hasta 80 mts²)'},
        {value: 'Mediana', label: 'Mediana (Hasta 150 mts²)'},
        {value: 'Grande', label: 'Grande (150 mts² o más)'},
    ];

    const updateLocalidadOptions = (newLocalidades) => {
        setLocalidadOptions((prevState) => {
            const newState =  [...prevState ];
            newState[0] = newLocalidades;
            return newState;
        });
    };

    useEffect(() => {
        if (!isRecotizar) {
            Object.keys(values.coberturas).map(cobertura => {
                if (values.tamanioVivienda === 'Chica')
                    setFieldValue(`coberturas.[${cobertura}].sumaAsegurada`, coberturasInfo[cobertura].sumaChica)
                if (values.tamanioVivienda === 'Mediana')
                    setFieldValue(`coberturas.[${cobertura}].sumaAsegurada`, coberturasInfo[cobertura].sumaMediana)
                if (values.tamanioVivienda === 'Grande')
                    setFieldValue(`coberturas.[${cobertura}].sumaAsegurada`, coberturasInfo[cobertura].sumaGrande)
            })
            Object.keys(values.coberturasAdicionales).map(cobertura => {
                if (values.tamanioVivienda === 'Chica')
                    setFieldValue(`coberturasAdicionales.[${cobertura}].sumaAsegurada`, coberturasInfo[cobertura].sumaChica)
                if (values.tamanioVivienda === 'Mediana')
                    setFieldValue(`coberturasAdicionales.[${cobertura}].sumaAsegurada`, coberturasInfo[cobertura].sumaMediana)
                if (values.tamanioVivienda === 'Grande')
                    setFieldValue(`coberturasAdicionales.[${cobertura}].sumaAsegurada`, coberturasInfo[cobertura].sumaGrande)
            })
        } else {
            Object.keys(values.coberturasAdicionales).map(cobertura => {
                if (!values.coberturasAdicionales[cobertura].selectedCobertura) {
                    if (values.tamanioVivienda === 'Chica')
                        setFieldValue(`coberturasAdicionales.[${cobertura}].sumaAsegurada`, coberturasInfo[cobertura].sumaChica)
                    if (values.tamanioVivienda === 'Mediana')
                        setFieldValue(`coberturasAdicionales.[${cobertura}].sumaAsegurada`, coberturasInfo[cobertura].sumaMediana)
                    if (values.tamanioVivienda === 'Grande')
                        setFieldValue(`coberturasAdicionales.[${cobertura}].sumaAsegurada`, coberturasInfo[cobertura].sumaGrande)
                }
            })
        }

    }, [values.tamanioVivienda]);

    return (
        <div className="hogar__formStep__container">
            <Grid gutterXs="xs" gutter="md" align="center">
                <Grid.Col xs={12} sm={6}>
                    <MantineSelectField
                        name={tipoVivienda.name}
                        label={tipoVivienda.label}
                        data={tiposViviendas}
                        withAsterisk
                    />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <MantineSelectField
                        name={tamanioVivienda.name}
                        label={tamanioVivienda.label}
                        data={tamaniosVivienda}
                        withAsterisk
                    />
                </Grid.Col>
                <>
                    <ZipCodeFieldPackage localidadField={codigosLocalidad} localidadSize={6}
                                         codigoPostalField={codigoPostal} codigoPostalSize={6}
                                         provinciaField={idProvincia} provinciaSize={6}
                                         localidadOptions={localidadOptions[0] || []} setLocalidadOptions={updateLocalidadOptions}
                                         provinciasOptions={provinciasOptions} setProvinciaOptions={setProvinciasOptions}
                    />
                </>
            </Grid>
        </div>
    );
}
