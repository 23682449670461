import React from 'react';
import {Grid} from '@material-ui/core';
import {useFormikContext} from "formik";
import useStyles from "../styles";
import Typography from "../../../../components/Typography";
import {currencyFormat} from "../../../../utils/currencyFormat";

export default function SolicitudForm(props) {
    const classes = useStyles();
    const {values} = useFormikContext();

    return (
        <React.Fragment>
            <Grid container spacing={2} justifyContent={"center"}>
                <Typography className={classes.tableHeader}>Detalles de póliza</Typography>
                <Grid item xs={12} sm={11}>
                    <Typography className={classes.tableRowLabel}>
                        Datos del vehículo
                    </Typography>
                    <ul>
                        <li className={classes.tableRowData}>{values.vehiculo.marca + " " + values.vehiculo.nombreModelo + " " + values.vehiculo.anio}</li>
                        <li className={classes.tableRowData}>Patente: {values.vehiculo.patente}</li>
                        <li className={classes.tableRowData}>Valor vehículo:
                            ${currencyFormat(values.vehiculo.valor)}</li>
                        <li className={classes.tableRowData}>GNC: {values.vehiculo.tieneGnc === "true" ? "Sí" : "No"}</li>
                    </ul>
                </Grid>
                <Grid item xs={12} sm={11}>
                    <Typography className={classes.tableRowLabel}>
                        Datos del asegurado
                    </Typography>
                    <ul>
                        <li className={classes.tableRowData}>{values.clientes[0].nombre + " " + values.clientes[0].apellido}</li>
                        <li className={classes.tableRowData}>
                            {values.clientes[0]['personaJuridica'] === '1' ? `CUIT: ${values.clientes[0]['cuit']}` :
                                `DNI: ${values.clientes[0].dni}`}
                        </li>
                        <li className={classes.tableRowData}>Celular: {values.clientes[0].codigoArea + "-" + values.clientes[0].telefono}</li>
                    </ul>
                </Grid>
                <div className={classes.boxPrice}>
                    <Typography className={classes.priceTitle}>Cuota mensual:</Typography>
                    <Typography className={classes.priceText}>$ {values.premium}</Typography>
                </div>
            </Grid>
        </React.Fragment>
    );
}
