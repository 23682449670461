import React, {useContext, useState} from 'react';
import './MasterPolkistasTable.css';
import {MantineInputFilter} from "../../../components/Filters/MantineInputFilter";
import {MainTable} from "../../../components/MainTable";
import MasterContext from "../../../context/MasterContext";

function MasterPolkistasTable(props) {
    const {rows} = props;
    const [filteredRows, setFilteredRows] = useState(rows);
    const {masterPolkistas} = useContext(MasterContext);

    //FILTERS
    const [searchFilter, setSearchFilter] = useState('');

    const handleSearchFilter = (value) => {
        let valueToSet = value ? value : "";
        setSearchFilter(valueToSet);

        let polkistasFiltrados = rows;
        polkistasFiltrados = polkistasFiltrados.filter(polkista =>
            JSON.stringify(polkista).toLowerCase().includes(valueToSet.toLowerCase())
        );

        setFilteredRows(polkistasFiltrados);
    };

    //COLUMNS
    const polkistasColumns = [
        {title: "Nombre", key: "idAuth", render: (value) => (
            <span>
                {Object.values(masterPolkistas)
                    .find(polkista => polkista.datos_polkista.id_auth === value)['datos_polkista']['NombreCompleto']}
            </span>)
        },
        {title: "Cotizaciones", key: "cantidad_cotizaciones", render: (value) => <span>{value}</span>},
        {title: "Emisiones", key: "cantidad_emisiones", render: (value) => <span>{value}</span>},
        {title: "% de Conversión", key: "porcentaje_conversion", render: (value) => <span>{value}</span>},
        {title: "Cuota promedio", key: "premium", render: (value) => <span>$ {parseFloat(value).toFixed(2)}</span>},
        {title: "% de Bancarización", key: "bancarizacion", render: (value) => <span>{`${parseFloat(value*100).toFixed(2)}%`}</span>},
    ];

    return (
        <div className="masterPolkistasTable__container">
            <div className="masterPolkistasTable__header">
                <div className="masterPolkistasTable__filters--search">
                    <MantineInputFilter
                        value={searchFilter}
                        setValue={(value) => handleSearchFilter(value)}
                        placeholder={"Buscar"}
                    />
                </div>
            </div>
            <hr className="masterPolkistasTable__hr"/>
            <div className="masterPolkistasTable__body">
                <div className="masterPolkistasTable__table__container">
                    <MainTable columns={polkistasColumns} rows={filteredRows}/>
                </div>
                <p className="masterPolkistasTable__note">*Sólo se mostrarán aquellos polkistas que cuenten con operaciones en la plataforma</p>
            </div>
        </div>
    );
}

export default MasterPolkistasTable;