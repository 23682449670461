import formsModel from "./formsModel";
import moment from "moment";

const {
    formField: {
        tipoVivienda,
        tamanioVivienda,
        descuentoEspecial,
        coberturas: {
            sumaAsegurada,
            selectedCobertura,
            numeroCobertura
        },
        ajusteAutomatico,
        vigenciaDesde,
        formaDePago,
        vigenciaHasta,
        cuotas,
        frecuenciaPago,
        comentariosSolicitud,
        infoDePago: {
            numeroTarjeta,
            marcaTarjeta,
            numeroCbu
        },
        detalleEquipoElectronico: {
            codigoElectronico,
            sumaAseguradaElectronico,
            codigoGrupoElectronico,
            descripcionElectronico
        },
        detalleBicicletas: {
            codigoBicicletas,
            sumaAseguradaBicicletas,
            codigoGrupoBicicletas,
            descripcionBicicletas
        },
        cliente: {
            email,
            telefono,
            nombre,
            codigosLocalidad,
            codigoArea,
            fechaNacimiento,
            apellido,
            situacionIva,
            domicilio,
            cuit,
            dni,
            sexo,
            estadoCivil,
            rol,
            actividadPrincipal,
            categoriaMonotributo,
            lugarNacimiento,
            personaJuridica
        }
    }
} = formsModel;

const polizaIntialValues = {
    [frecuenciaPago.name]: '5',
    [cuotas.name]: '',
    [ajusteAutomatico.name]: '',
    [formaDePago.name]: '4',
    [vigenciaDesde.name]: moment().format('YYYY-MM-DD'),
    [vigenciaHasta.name]: moment().add(1,"Y").format("YYYY-MM-DD"),
    [comentariosSolicitud.name]: '',
    infoDePago: {
        [numeroCbu.name]: '',
        [marcaTarjeta.name]: '',
        [numeroTarjeta.name]: '',
        // [vencimientoTarjeta.name]: '',
    },
}

const coberturasMicroInitialValues =  [
    {
        [sumaAsegurada.name]: 0,
        [numeroCobertura.name]: "26",
        [selectedCobertura.name]: false,
    },
    {
        [sumaAsegurada.name]: 0,
        [numeroCobertura.name]: "36",
        [selectedCobertura.name]: false,
    },
    {
        [sumaAsegurada.name]: 0,
        [numeroCobertura.name]: "37",
        [selectedCobertura.name]: false,
    },
    {
        [sumaAsegurada.name]: 0,
        [numeroCobertura.name]: "48",
        [selectedCobertura.name]: false,
    },
];


const detallesInitialValues = {
    detalleEquipoElectronico: [
        {
            [descripcionElectronico.name]: "",
            [sumaAseguradaElectronico.name]: "",
            [codigoElectronico.name]: 201,
            [codigoGrupoElectronico.name]: 34,
        },
    ],
    detalleBicicletas: [
        {
            [descripcionBicicletas.name]: "",
            [sumaAseguradaBicicletas.name]: "",
            [codigoBicicletas.name]: 209,
            [codigoGrupoBicicletas.name]: 31,
        },
    ],
}

const clienteInitialValues =
    {
        [rol.name]: '2',
        [personaJuridica.name]: '0',
        [dni.name]: '',
        [cuit.name]: '',
        [nombre.name]: '',
        [apellido.name]: '',
        [fechaNacimiento.name]: '',
        [estadoCivil.name]: '2',
        [sexo.name]: '',
        [domicilio.name]: '',
        [codigosLocalidad.name]: '',
        [codigoArea.name]: '',
        [telefono.name]: '',
        [email.name]: '',
        [situacionIva.name]: '4',
        [categoriaMonotributo.name]: '1',
        [actividadPrincipal.name]: '10001',
        [lugarNacimiento.name]: 'Cordoba'
    };



const cotizarMicroseguroInitialValues = {
    [tamanioVivienda.name]: "Chica",
    [tipoVivienda.name]: "1022",
    [codigosLocalidad.name]: "",
    [descuentoEspecial.name]: 20,
    coberturas: coberturasMicroInitialValues,
};


const emitirMicrosegurosInitialValues = {
    [tipoVivienda.name]: "1022",
    ...polizaIntialValues,
    [descuentoEspecial.name]: 20,
    clientes: [clienteInitialValues],
    coberturas: coberturasMicroInitialValues,
    ...detallesInitialValues,
};




export {
    cotizarMicroseguroInitialValues,
    emitirMicrosegurosInitialValues
}
