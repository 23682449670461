import React, {useState} from 'react'
import './Landing.css'
import {LandingHeader} from "../../features/Landing/Header"
import {LandingHero} from "../../features/Landing/Hero";
import {CardsContainer} from "../../features/Landing/CardsContainer";
import {LandingBanner} from "../../features/Landing/Banner";
import {LandingTestimonials} from "../../features/Landing/Testimonials";
import {LandingFooter} from "../../features/Landing/Footer";
import {LandingDrawer} from "../../features/Landing/Drawer";
import WappFab from "../../components/WappFAB/WappFAB";

function Landing() {
    const [drawerOpened, setDrawerOpened] = useState(false);
    return (
        <div>
            <LandingHeader
                setDrawerOpened={setDrawerOpened}
            />
            <LandingDrawer
                drawerOpened={drawerOpened}
                setDrawerOpened={setDrawerOpened}
            />
            <LandingHero/>
            <div className="landing__container--cards">
                <CardsContainer/>
            </div>
            <div className="landing__container--banner">
                <LandingBanner/>
            </div>
            <div className="landing__container--testimonials">
                <LandingTestimonials/>
            </div>
            <LandingFooter/>
            <WappFab url={'https://wa.me/' + "3518687927" + '?text=¡Hola%20buenos%20días!%20Tengo%20una%20consulta'}/>
        </div>
    );
}

export default Landing;