function isPercentage(cadena) {
    // Regex para verificar si el string es un número seguido de un símbolo de porcentaje
    if (typeof cadena !== 'string') return false;
    const regex = /^\d+%$/;

    // Comprobamos si la cadena cumple con la regex
    if (regex.test(cadena)) {
        // Extraemos el número y verificamos si está entre 0 y 100
        const numero = parseInt(cadena.slice(0, -1), 10);
        return numero >= 0 && numero <= 100;
    }

    // Si no cumple con la regex, no es un porcentaje válido
    return false;
}

export default isPercentage;