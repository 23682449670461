import './CustomAgreementBanner.css';

const CustomAgreementBanner = (props) => {
    const {agreement} = props;

    return (
        <div className="CustomAgreementBanner__container">
            {Object.keys(agreement).map((item) => {
                return (
                    <div className="CustomAgreementBanner__column">
                        <div className="CustomAgreementBanner__column--title">
                            {item}
                        </div>
                        <div
                            className={`CustomAgreementBanner__column--value ${(item === "Objetivo") ? "big" : ""}`}>
                            {agreement[item]}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default CustomAgreementBanner;
