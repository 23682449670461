import React, {useContext, useMemo, useState} from 'react';
import './MasterOperacionesTable.css';
import {currencyFormat} from "../../../modules/utils/currencyFormat";
import {MainTable} from "../../../components/MainTable";
import moment from "moment/moment";
import MasterContext from "../../../context/MasterContext";
import {MantineMonthFilter} from "../../../components/Filters/MantineMonthFilter";
import {MantineSelectFilter} from "../../../components/Filters/MantineSelectFilter";
import {MantineInputFilter} from "../../../components/Filters/MantineInputFilter";
import UserContext from "../../../context/UserContext";

//TODO: Move to utils
const filterByDate = (op, date) => {
    if (op.fecha.split("-")[1] === moment(date).format("MM"))
        return true
}

const productsDictForFilter = {
    "vehiculo": "AUTOMOTOR",
    "microseguros": "MICROSEGUROS",
    "hogar": "HOGAR",
    "motovehiculo": "MOTOVEHÍCULO",
    "asistencia_viajero": "ASISTENCIA AL VIAJERO"
}

function MasterOperacionesTable(props) {
    const {rows} = props;
    const [filteredRows, setFilteredRows] = useState(rows);
    const {companiesDict} = useContext(MasterContext);
    const {userCompanies} = useContext(UserContext);
    const companiesNames = useMemo(() => {
        return userCompanies.map(company => company.replace(/_/g, " "));
    }, [userCompanies]);

    //FILTERS
    const [filters, setFilters] = useState({
        aseguradora: '',
        producto: '',
        fecha: '',
        searchText: ''
    });

    function filterEmisiones(fltr) {
        let operacionesFiltradas = rows;

        if (fltr.aseguradora) {
            operacionesFiltradas = operacionesFiltradas.filter(operacion =>
                operacion.aseguradora === fltr.aseguradora
            );
        }

        if (fltr.producto) {
            operacionesFiltradas = operacionesFiltradas.filter(operacion =>
                operacion.producto === productsDictForFilter[fltr.producto]
            );
        }

        if (fltr.fecha) {
            operacionesFiltradas = operacionesFiltradas.filter(operacion =>
                filterByDate(operacion, fltr.fecha)
            );
        }

        if (fltr.searchText) {
            operacionesFiltradas = operacionesFiltradas.filter(operacion =>
                JSON.stringify(operacion).toLowerCase().includes(fltr.searchText.toLowerCase())
            );
        }

        return operacionesFiltradas;
    }

    const handleFilterChange = (filterName, value) => {
        let valueToSet = value ? value : "";

        setFilters((prevState) => ({...prevState, [filterName]: valueToSet}));
        const filteredEmisiones = filterEmisiones({...filters, [filterName]: valueToSet});

        setFilteredRows(filteredEmisiones);
    };

    //COLUMNS
    const ultimasOperacionesColumns = [
        {title: "ID Emisión", key: "idEmision", render: (value) => <span>{value}</span>},
        {title: "Producto", key: "producto", render: (value) => <span>{value}</span>},
        {title: "Aseguradora", key: "aseguradora", render: (value) => <span>{value}</span>},
        // {title: "N° de trámite", key: "numeroTramite", render: (value) => <span>{value}</span>}, //TODO: Add this field to the API
        {title: "Premio", key: "premio", render: (value) => <span>$ {currencyFormat(value)}</span>},
        {title: "Detalle", key: "descripcion", render: (value) => <span>{value['producto']}</span>},
        {title: "Inicio vigencia", key: "fecha", render: (value) => <span>{value}</span>},
        {
            title: "Estado",
            key: "estado",
            render: (value) => {
                if (value === "Success")
                    return <span className="comDetModal__column__state--success">{value}</span>
                else
                    return <span className="comDetModal__column__state--failed">{value}</span>
            }
        }
    ];


    return (
        <div className="masterOperacionesTable__container masterOperacionesTable__variables">
            <div className="masterOperacionesTable__header">
                <div className="masterOperacionesTable__filters--company">
                    <MantineSelectFilter
                        value={filters.aseguradora}
                        setValue={(value) => handleFilterChange('aseguradora', value)}
                        data={companiesNames}
                        clearable
                        placeholder={"Aseguradora"}
                    />
                </div>
                <div className="masterOperacionesTable__filters--product">
                    <MantineSelectFilter
                        value={filters.producto}
                        setValue={(value) => handleFilterChange('producto', value)}
                        data={filters.aseguradora ? companiesDict[filters.aseguradora.replace(/ /g, "_")].products : companiesDict["Sancor"].products}
                        clearable
                        placeholder={"Producto"}
                    />
                </div>
                <div className="masterOperacionesTable__filters--date">
                    <MantineMonthFilter
                        placeholder={"Fecha"}
                        value={filters.fecha}
                        setValue={(value) => handleFilterChange('fecha', value)}
                    />
                </div>
                <div className="masterOperacionesTable__filters--search">
                    <MantineInputFilter
                        value={filters.searchText}
                        setValue={(value) => handleFilterChange('searchText', value)}
                        placeholder={"Buscar"}
                    />
                </div>
            </div>
            <hr className="masterOperacionesTable__hr"/>
            <div className="masterOperacionesTable__body">
                <div className="masterOperacionesTable__table__container">
                    <MainTable columns={ultimasOperacionesColumns} rows={filteredRows}/>
                </div>
            </div>
        </div>
    );
}

export default MasterOperacionesTable;