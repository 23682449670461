import moment from "moment";
import {VehiculoModel} from "../FormStages/Cotizar/Vehiculo/VehiculoModel";

// TODO: Los 4 advancedConfig deberian estar parametrizado por compañia
const advancedConfigFederacionPatronal = (recotizarValues, values, userCodigosOperacion, locationCodes, isPromotedCompany) => {
    const {initialValues: cotizarVehiculoInitialValues} = VehiculoModel();
    const antiguaty = new Date().getFullYear() - parseInt(values.vehiculo.anio) + 1;
    const hasFedPatExtraDiscount = antiguaty <= 10;
    const notHasMensual = antiguaty > 20;

    values['fecha'] = moment().format('YYYY-MM-DD');
    if (values['configuracionAvanzada']['Federacion_Patronal']) {
        if (recotizarValues) {
            values['configuracionAvanzada']['Federacion_Patronal']['vigenciaDesde'] = moment().format('YYYY-MM-DD');
        }
        if (!isPromotedCompany){
            values['configuracionAvanzada']['Federacion_Patronal']['codigos'] = userCodigosOperacion['Federacion_Patronal'][0];
        } else if (hasFedPatExtraDiscount) {
            values['configuracionAvanzada']['Federacion_Patronal']['applyDiscount'] = true;
            values['configuracionAvanzada']['Federacion_Patronal']['descuentoComision'] = "-5";
        }
        if (notHasMensual) {
            values['configuracionAvanzada']['Federacion_Patronal']['tipoVigencia'] = "CUATRIMESTRAL";
        }
        values['configuracionAvanzada']['Federacion_Patronal']['codFederacion_PatronalLocalidad'] = locationCodes.CodigoLocalidadFederacion_Patronal
    }
    else {
        values['configuracionAvanzada']['Federacion_Patronal'] = {
            ...cotizarVehiculoInitialValues['configuracionAvanzada']['Federacion_Patronal'],
            ...(!isPromotedCompany && {
                "codigos": userCodigosOperacion['Federacion_Patronal'][0],
            }),
            ...(notHasMensual && {
                "vigencia": "CUATRIMESTRAL"
            }),
            "vigenciaDesde": moment().format('YYYY-MM-DD'),
            "codFederacion_PatronalLocalidad": locationCodes.CodigoLocalidadFederacion_Patronal,
        };

    }
    values['configuracionAvanzada']['Federacion_Patronal']['isPromotedCompany'] = isPromotedCompany;
}

export {advancedConfigFederacionPatronal}