import React from 'react';
import './Banner.css';

function Banner() {
    return (
        <div className="landing__banner__container">
            <div className="landing__banner__container--left">
                <h2 className="landing__banner__title">¿En qué me beneficia Polko?</h2>
            </div>
            <div className="landing__banner__container--right">
                <h2 className="landing__banner__title--mobile">¿En qué me beneficia Polko?</h2>
                <ul className="landing__banner__list">
                    <li className="landing__banner__item">
                        Acceso a las mejores aseguradoras.
                    </li>
                    <li className="landing__banner__item">
                        Trabajá de manera simple y ágil
                        desde cualquier dispositivo.
                    </li>
                    <li className="landing__banner__item">
                        Página Web personalizada que te
                        permite vender sin límite a todo el País.
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Banner;