import React, {useState, useCallback, useRef} from 'react';
import ReactCrop from 'react-image-crop';
import "react-image-crop/dist/ReactCrop.css";
import "./ProfileImageUpload.css";
import {Button} from "../../../../components/Button";
import {PopUpCardModal} from "../../../../layouts/PopUpCardModal";
import {Loader} from '@mantine/core';


export default function ProfileImageUpload(props) {
    const {userImage, setNewImageData, setUserImage, setNewImageUrl} = props;
    const imgRef = useRef(null);
    const [srcFile, setSrcFile] = useState(null);
    const [srcImage, setSrcImage] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [crop, setCrop] = useState({unit: '%', aspect: 1});
    const [completedCrop, setCompletedCrop] = useState(null);
    const [croppedImageUrl, setCroppedImageUrl] = useState(null);
    const fileInputRef = useRef(null);

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setCrop({
            "unit": "px",
            "x": 0,
            "y": 0,
            "aspect": 1,
            "width": 0,
            "height": 0
        });
    };

    const handleImageSelect = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            const file = event.target.files[0];
            reader.addEventListener('load', () => {
                    setSrcImage(reader.result);
                    setSrcFile(file);
                    setOpenDialog(true);
                }
            );
            reader.readAsDataURL(file);

            if (fileInputRef.current) {
                fileInputRef.current.value = null;
            }
        }
    };

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    function getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve) => {
            canvas.toBlob(blob => {
                const file = new File([blob], fileName, { type: srcFile.type });
                resolve(file);
            }, 'image/png, image/jpeg', 1);
        });
    }

    const handleUpload = async () => {
        if (!imgRef || !completedCrop)
            return;

        const croppedImg = await getCroppedImg(imgRef.current, completedCrop, srcFile.name);

        const croppedImageUrl = URL.createObjectURL(croppedImg);
        setCroppedImageUrl(croppedImageUrl);
        setUserImage(croppedImageUrl);
        let formData = new FormData();
        formData.append("file", croppedImg);
        setNewImageUrl(croppedImageUrl);
        setNewImageData(formData);

        setOpenDialog(false);
        setCrop({
            "unit": "px",
            "x": 0,
            "y": 0,
            "aspect": 1,
            "width": 0,
            "height": 0
        });
    };

    return (
        <div>
            <div className="PIUpload__container">
                {userImage === null
                    ? <Loader className="PIUpload__loader"/>
                    :
                    <label>
                        <img
                            aria-label="account of current user"
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            src={croppedImageUrl ? croppedImageUrl : userImage}
                            className="PIUpload__imageContainer"
                            alt="Foto de perfil"
                        />
                        <input
                            name="file"
                            type="file"
                            ref={fileInputRef}
                            hidden
                            onChange={(event) => handleImageSelect(event)}
                            accept="image/png, image/jpeg"
                        />
                    </label>
                }
                <div>
                    <Button variant="filled" color="primary" enabled inputFile>
                        MODIFICAR FOTO DE PERFIL
                        <input
                            name="file"
                            type="file"
                            hidden
                            onChange={(event) => handleImageSelect(event)}
                            accept="image/png, image/jpeg"
                        />
                    </Button>
                </div>
            </div>
            {openDialog &&
                <PopUpCardModal
                    title="Recorte su imagen"
                    closeAction={handleCloseDialog}
                    body={
                        <div className="PIUpload__cropContainer">
                            {srcImage && (
                                <div className="PIUpload__popUpContent">
                                    <ReactCrop
                                        src={srcImage}
                                        crop={crop}
                                        onImageLoaded={onLoad}
                                        onComplete={(c) => {
                                            setCompletedCrop(c)
                                        }}
                                        onChange={(c) => {
                                            setCrop(c)
                                        }}

                                    />
                                    <p className="PIUpload__tutorialText">Arrastre sobre la imagen para seleccionar y
                                        recortar la zona deseada.</p>
                                </div>
                            )}
                        </div>
                    }
                    footer={
                        <div className="PIUpload__popUpButtons">
                            <Button onClick={handleCloseDialog} variant="outlined" color="primary" enabled>
                                Cerrar
                            </Button>
                            <div className="PIUpload__uploadButton">
                                <Button onClick={handleUpload} variant="filled" color="primary"
                                        enabled={crop.height !== 0}>
                                    Subir
                                </Button>
                            </div>
                        </div>
                    }
                />}
        </div>
    )
}
