import React from 'react';
import './OptionCard.css';
import {Textarea} from "@mantine/core";

function OptionCard(props) {
    const {id, text, variant, selected, handleTextChange, handleSelection} = props;

    return (
        <div
            className={selected ? "optionCard__container optionCard__container--active" : "optionCard__container"}
            onClick={() => handleSelection(id)}
        >
            <div className="optionCard__option">{id}</div>

            {variant === "input"
                ? //Descripcion personalizada
                <div className="optionCard__text__container">
                    <Textarea
                        autosize
                        value={text}
                        onChange={(event) => {
                            if (!selected) {
                                handleSelection(id)
                            }
                            handleTextChange(id, event.currentTarget.value)
                        }}
                        placeholder="Agrega una descripción personalizada"
                        classNames={{
                            input: "optionCard__text__input",
                        }}
                        styles={{root: {width: "100%"}}}
                    />
                </div>
                : //Descripciones por defecto
                <div className="optionCard__text__container">
                    <p className="optionCard__text">{text}</p>
                </div>
            }
        </div>
    );
}

export default OptionCard;