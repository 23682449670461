import React, {useEffect, useMemo, useState, useRef} from 'react';
import './MainTable.css';
import {TextLink} from "../TextLink";
import {ReactComponent as ArrowDownIcon} from '../../assets/icons/ic_arrow-down.svg';
import {Pagination} from "../Pagination";
import {Tabs} from "@mantine/core";
import getValueByPath from "../../features/utils/getValueByPath";

function SimpleTable({columns, rows}) {
    const memoizedColumns = useMemo(() => columns, [columns]);
    const memoizedRows = useMemo(() => rows, [rows]);

    return (
        <table className="simpleTable__table">
            <thead>
            <tr>
                {memoizedColumns.map((column, index) => (
                    <th key={index}>{column.title}</th>
                ))}
            </tr>
            </thead>
            <tbody>
            {memoizedRows.map((row, rowIndex) => (
                <React.Fragment key={rowIndex}>
                    <tr key={rowIndex}>
                        {/*TODO: Review merge conflict*/}
                        {memoizedColumns.map((column, columnIndex) => (
                            row === null
                                ? <td key={columnIndex}/>
                                : column.key === "row" ? <td key={columnIndex}>{column.render(row)}</td>
                                    : <td key={columnIndex}>{column.render(getValueByPath(row, column.key))}</td>
                        ))}
                    </tr>
                </React.Fragment>
            ))}
            </tbody>
        </table>
    );
}

/**
 * This component renders a table given a set of columns with its specific render methods
 * It also allow to display an (simple) inner table with its own given set of columns too.
 *
 * @param {Array[Object]} columns The columns to be rendered[title, key, render function].
 * @param {Object} rows An object to render rows based on the "key" prop received in columns
 * @param {Boolean} isExpandable Indicates if rows can be expanded.
 * @param {Array[Object]} innerColumns The inner columns to be rendered[title, key, render function].
 * @param {String} innerRowsKey The key in which there are the innerRows inside rows
 * @param {Boolean} pagination - Indicates if the table should be paginated.
 * @param {number} itemsPerPage - The number of items to display per page.
 * @param {String} expandibleText - The text to display in the expandible button.
 * @param {ReactComponent} expandibleIcon - The icon to display in the expandible button.
 * @returns {Component} The table rendered.
 */

function MainTable({
                       columns,
                       rows,
                       isExpandable = false,
                       innerRowsKey,
                       pagination,
                       itemsPerPage = 10,
                       expandibleText = "Ver detalles",
                       expandibleIcon = <ArrowDownIcon className="mainTable__details__icon--default"/>
                   }) {

    const rowRef = useRef(null);
    const [rowHeight, setRowHeight] = useState(32); // 32px es el valor inicial dado por el padding.
    const [currentPage, setCurrentPage] = useState(1);
    const memoizedColumns = useMemo(() => columns, [columns]);
    const memoizedRows = useMemo(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        // TODO: Tener en cuenta que slicedRows contiene filas nulas si estamos en la última pagina de la tabla.
        const slicedRows = pagination ? rows.slice(startIndex, endIndex) : rows;

        if (pagination && slicedRows.length < itemsPerPage) {
            const emptyRows = new Array(itemsPerPage - slicedRows.length).fill(null);
            return slicedRows.concat(emptyRows);
        }

        return slicedRows;
    }, [pagination, rows, currentPage, itemsPerPage]);


    useEffect(() => {
        if (rowRef.current) {
            setRowHeight(rowRef.current.offsetHeight);
        }
    }, [memoizedRows]);


    //Expandir múltiples filas
    // const [expandedRows, setExpandedRows] = useState([]);
    // const handleExpand = (rowIndex) => {
    //     setExpandedRows((prevExpandedRows) => {
    //         const expanded = [...prevExpandedRows];
    //         if (expanded.includes(rowIndex)) {
    //             const index = expanded.indexOf(rowIndex);
    //             expanded.splice(index, 1);
    //         } else {
    //             expanded.push(rowIndex);
    //         }
    //         return expanded;
    //     });
    // };
    //Expandir una sola fila
    const [expandedRow, setExpandedRow] = useState(-1);

    const handleExpand = (index) => {
        setExpandedRow(expandedRow === index ? -1 : index);
    };

    return (
        <div>
            {memoizedRows.length !== 0 && memoizedRows[0] !== null ?
                <div>
                    <div className="mainTable__container">
                        <table className="mainTable__table">
                            <thead>
                            <tr>
                                {memoizedColumns.map((column, index) => (
                                    <th key={index}>{column.title}</th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {memoizedRows.map((row, rowIndex) => (
                                <React.Fragment key={rowIndex}>
                                    <tr key={rowIndex} ref={rowIndex === 0 ? rowRef : null}
                                        className={row === null ? "mainTable__emptyRow" : "mainTable__contentRow"}>
                                        {memoizedColumns.map((column, columnIndex) => (
                                            row === null
                                                ? <td key={columnIndex} style={{height: `${rowHeight}px`}}/>
                                                : column.key === "row"
                                                    ? <td key={columnIndex}>{column.render(row, rowIndex)}</td>
                                                    : <td key={columnIndex}>{column.render(getValueByPath(row, column.key), rowIndex)}</td>
                                        ))}
                                        {isExpandable && row &&
                                            <td>
                                                <TextLink size={"sm"}
                                                          action={() => handleExpand(rowIndex)}>
                                                    <div className="mainTable__details__container">
                                                        {expandibleText}
                                                        {/*Expandir múltiples filas*/}
                                                        {/*{expandedRows.includes(rowIndex)*/}
                                                        {expandedRow === rowIndex
                                                            ?
                                                            <div
                                                                className="mainTable__details__icon--up">{expandibleIcon}</div>
                                                            : <div
                                                                className="mainTable__details__icon">{expandibleIcon}</div>
                                                        }
                                                    </div>
                                                </TextLink>
                                            </td>
                                        }
                                    </tr>
                                    {/*Expandir múltiples filas*/}
                                    {/*{expandedRows.includes(rowIndex) && (*/}
                                    {/*    <tr>*/}
                                    {/*        <td colSpan={memoizedColumns.length + 1}>*/}
                                    {/*            <div>*/}
                                    {/*                <h3>Additional Information:</h3>*/}
                                    {/*                <TextTable rows={["Some additional text"]} />*/}
                                    {/*            </div>*/}
                                    {/*        </td>*/}
                                    {/*    </tr>*/}
                                    {/*)}*/}
                                    {expandedRow === rowIndex && (
                                        <tr className="mainTable__innerTable__container">
                                            <td colSpan={memoizedColumns.length + 1}>
                                                {/*TODO: Review merge conflict*/}
                                                <Tabs variant="pills" radius="md" defaultValue={innerRowsKey[0].key} classNames={{tab: "mainTable__innerTab", tabsList: "mainTable__innerTabsList"}}>
                                                    <Tabs.List>
                                                        {innerRowsKey.map((tab, index) => (
                                                            <Tabs.Tab key={index} value={tab.key}>{tab.label}</Tabs.Tab>
                                                        ))}
                                                    </Tabs.List>
                                                    {innerRowsKey.map((tab, index) => (
                                                        <Tabs.Panel key={index} value={tab.key}>
                                                                <SimpleTable columns={tab.columns} rows={tab.key === "row" ? [memoizedRows[expandedRow]] : getValueByPath(memoizedRows[expandedRow], tab.key)}/>
                                                        </Tabs.Panel>
                                                    ))}
                                                </Tabs>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    {pagination && <Pagination totalItems={rows.length}
                                               itemsPerPage={itemsPerPage}
                                               currentPage={currentPage}
                                               setCurrentPage={setCurrentPage}
                    />
                    }
                </div>
                :
                <div className="mainTable__noData">Sin datos</div>
            }

        </div>
    );
}

export default MainTable;