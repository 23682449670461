import React from 'react';
import './Circle.css';

function Circle(props) {
    const {children, variant} = props;
    return (
        <div className={`circle--${variant}`}>
            {children}
        </div>
    );
}

export default Circle;