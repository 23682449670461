import React from 'react';
import MaterialLayout from '../../components/Layout/MaterialLayout';
import withRoot from "../../withRoot";
import CheckoutEmision from "./Checkout";
function Emision(props) {
    return (
        <div>
            <MaterialLayout>
                {typeof props.location.state !== 'undefined'
                    ? (<CheckoutEmision
                        continueCotizacion={true}
                        emisionValues={props.location.state.emisionValues}
                        applyDiscounts={!props.location.state.recotizar}
                    />)
                    : (<CheckoutEmision continueCotizacion={false} emisionValues={null} applyDiscounts={true}/>)}
            </MaterialLayout>
        </div>
    )
}
export default withRoot(Emision);
