
function buildBaseDescriptions(descripciones, defaultIds) {
    let objeto = [];

    for (let id in descripciones) {
        if (defaultIds.includes(parseInt(id))) {
            objeto.push({
                id: id.toString(),
                selected: true,
                variant: "option",
                text: descripciones[id]
            });
        }
    }

    objeto.push({
        id: "+",
        selected: false,
        variant: "input",
        text: ""
    });

    return objeto;
}

function buildInitialDescriptions(ids, defaultIds, descripciones, customDescription) {
    const objetoBase = buildBaseDescriptions(descripciones, defaultIds);

    return objetoBase.map((item) => {
        if (ids.includes(parseInt(item.id))) {
            return {
                ...item,
                selected: true
            };
        } else if (item.id === "+") {
            const noEncontrado = !ids.every(id => defaultIds.includes(id));
            return {
                ...item,
                selected: noEncontrado,
                text: customDescription ? customDescription : ""
            };
        } else {
            return item;
        }
    });
}

export { buildInitialDescriptions, buildBaseDescriptions };