import React, { useState, useEffect } from "react";
import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core/styles";
import {
  ListItemText,
  ListItemIcon,
  Menu,
  MenuItem,
  Divider,
  Button,
} from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import IconButton from "@material-ui/core/IconButton";
import { useSubscription, gql } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import Typography from "../components/Typography";
import NotificationsCenterDialog from "./NotificationsCenterDialog";
import store from "store";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  menuPaper: {
    //backgroundColor: "#35303f",
    borderRadius: "0",
    marginTop: "6px",
    width: "320px",
    //height: '100%',
    shadowColor: "rgba(0, 0, 0, 0.23)",
    shadowOffset: {
      width: -5,
      height: 0,
    },
    shadowRadius: 10,
    shadowOpacity: 1,
  },
  menuList: {
    padding: 0,
  },
  menuItem: {
    padding: 0,
    "&:hover": {
      backgroundColor: "#f3f4f8",
    },
  },
  menuItemRead: {
    padding: 0,
    "&:hover": {
      backgroundColor: "#eaebef",
    },
    backgroundColor: "#f3f4f8",
  },
  readIndicator: {
    width: "8px",
    height: "96px",
    margin: "0 16px 0 0",
    backgroundColor: "#62c08a",
  },
  readPlaceholder: {
    width: "8px",
    height: "96px",
    margin: "0 16px 0 0",
    backgroundColor: "#f3f4f8",
  },
  globalPlaceholder: {
    width: "8px",
    height: "96px",
    margin: "0 16px 0 0",
    backgroundColor: "#452d7b",
  },
  listText: {
    fontFamily: "Ubuntu",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#35303f",
  },
  listTextDetail: {
    fontFamily: "Montserrat",
    fontSize: "13px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.54",
    letterSpacing: "normal",
    textAlign: "justify",
    color: "#8a8597",
  },
  notificationInfo: {
    width: "100%",
    paddingRight: theme.spacing(2),
  },
  date: {
    width: "100%",
    fontFamily: "Ubuntu",
    fontSize: "12px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "right",
    color: "#973379",
  },
  moreNotifications: {
    //width: 560px;
    height: "96px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    backgroundColor: "#35303f",
    //padding: 0 0 24px;
  },
  botonMenu: {
    borderRadius: 8,
    backgroundColor: "#62c08a",
    boxShadow: "inset 0 0 8px 0 rgba(112, 232, 163, 0.5)",
    "&:hover": {
      backgroundColor: "#48926b",
      borderColor: "#48926b",
      boxShadow: "none",
    },
    textTransform: "none",
    padding: theme.spacing(1),
  },
  botonText: {
    fontFamily: "Ubuntu",
    fontSize: 18,
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: 0,
    textAlign: "center",
    color: "#ffffff",
    textShadowColor: "rgba(82, 82, 82, 0.15)",
    textShadowOffset: {
      width: 0,
      height: 1,
    },
    textShadowRadius: 2,
  },
}));

const NEW_NOTIFICATION = gql`
  subscription MySubscription {
    General_Notificaciones(order_by: { created_at: desc }) {
      id
      id_Auth
      mensaje
      titulo
      created_at
      leido
    }
  }
`;

function Notifications() {
  const { isAuthenticated } = useAuth0();
  const classes = useStyles();
  const { data, loading } = useSubscription(NEW_NOTIFICATION);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notification, setNotification] = useState(null);
  const [openCenter, setOpenCenter] = useState(false);
  const [orderedNotifications, setOrderedNotifications] = useState(null);
  const [localStorageChanged, setLocalStorageChanged] = useState(true);

  const readGlobalNotifications = store.get("readGlobalNotifications");
  if (readGlobalNotifications === undefined)
    store.set("readGlobalNotifications", []);

  const handleOpenNotification = (row, index) => {
    setNotification({ row, index });
    setAnchorEl(null);
    setOpenCenter(true);
  };

  const handleOpenCenter = () => {
    setAnchorEl(null);
    setOpenCenter(true);
  };

  const handleCloseCenter = () => {
    setOpenCenter(false);
  };

  const getNotificationCount = (data) => {
    let unreadNotificationsCount = data?.General_Notificaciones.filter(
      (d) => !d.leido
    ).length;
    let readGlobalNotificationsCount = readGlobalNotifications?.length;
    let total = unreadNotificationsCount - readGlobalNotificationsCount;
    if (total < 0) total = 0;
    return total;
  };

  const orderData = () => {
    let unread = [];
    let read = [];

    if (data.General_Notificaciones?.length !== 0) {
      for (let i = 0; i < data.General_Notificaciones.length; i++) {
        if (data.General_Notificaciones[i].id_Auth === null) {
          if (readGlobalNotifications.includes(data.General_Notificaciones[i].id))
            read.push(data.General_Notificaciones[i]);
          else unread.push(data.General_Notificaciones[i]);
        } else {
          if (data.General_Notificaciones[i].leido)
            read.push(data.General_Notificaciones[i]);
          else unread.push(data.General_Notificaciones[i]);
        }
      }
      unread.push.apply(unread, read);
      setOrderedNotifications(unread);
    }
  };

  useEffect(() => {
    if (data) {
      orderData();
      if (localStorageChanged) setLocalStorageChanged(false);
    }
  }, [data, localStorageChanged]);

  return (
    isAuthenticated && (
      <>
          <IconButton
            
            aria-label="PROXIMAMENTE"
            style={{ color: "#fff" }}
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
          >
            <Badge
              badgeContent={data ? getNotificationCount(data) : 0}
              color="error"
              className="tour-notificaciones"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Menu
            id="notifications-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            classes={{ paper: classes.menuPaper, list: classes.menuList }}
          >
            {orderedNotifications ? (
              orderedNotifications?.map((row, index) => {
                const leido =
                  row.leido ||
                  store.get("readGlobalNotifications")?.includes(row.id);
                return (
                  index < 5 && (
                    <>
                      <MenuItem
                        key={index}
                        className={
                          leido ? classes.menuItemRead : classes.menuItem
                        }
                        onClick={() => handleOpenNotification(row, index)}
                      >
                        {row.id_Auth === null && !leido ? (
                          <div className={classes.globalPlaceholder} />
                        ) : leido ? (
                          <div className={classes.readPlaceholder} />
                        ) : (
                          <div className={classes.readIndicator} />
                        )}
                        <div className={classes.notificationInfo}>
                          <ListItemText
                            classes={{
                              primary: classes.listText,
                              secondary: classes.listTextDetail,
                            }}
                            primary={row.titulo}
                            secondary={
                              row.mensaje.length > 28
                                ? row.mensaje.slice(0, 28) + "..."
                                : row.mensaje
                            }
                          />
                          <div className={classes.date}>
                            {moment(row.created_at).format("DD/MM/YYYY HH:mm")}
                          </div>
                        </div>
                      </MenuItem>
                      <Divider />
                    </>
                  )
                );
              })
            ) : (
              <>
                <MenuItem>
                  <ListItemIcon>
                    <NotificationsNoneIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      loading ? "Cargando..." : "No tienes Notificaciones."
                    }
                  />
                </MenuItem>
              </>
            )}
            {data ? (
              <>
                <div className={classes.moreNotifications}>
                  <Button
                    className={classes.botonMenu}
                    onClick={handleOpenCenter}
                  >
                    <Typography className={classes.botonText}>
                      Ver Notificaciones
                    </Typography>
                  </Button>
                </div>
              </>
            ) : null}
          </Menu>
          <NotificationsCenterDialog
            open={openCenter}
            onClose={handleCloseCenter}
            data={orderedNotifications}
            notification={notification}
            setLocalStorageChanged={setLocalStorageChanged} // workaround
          />
      </>
    )
  );
}

export default Notifications;
