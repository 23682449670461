import React from "react";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

import IconButton from "@material-ui/core/IconButton";

import { useAuth0 } from "@auth0/auth0-react";
import Grid from "@material-ui/core/Grid";
import Typography from "../components/Typography";

/**
 * It creates a WhatsApp button that opens a new tab with the WhatsApp web app.
 * @returns A function that returns a button that opens a new tab with the whatsapp link.
 */
function WhatsappButton() {
  const { isAuthenticated } = useAuth0();

  return (
    isAuthenticated && (
      <IconButton
        onClick={() => window.open("https://wa.me/+5493518687927", "_blank")}
      >
        <WhatsAppIcon className="tour-wapp-consulta" fontSize={"large"} style={{ color: "white" }} />
      </IconButton>
    )
  );
}

/**
 * It checks if the user is authenticated, if so, it renders a button with a WhatsApp icon and the text "Contacto" (Contact
 * in Spanish)
 * @returns A Grid component with a container, direction, style, and two Grid items.
 */
function WhatsappButtonText() {
  const { isAuthenticated } = useAuth0();

  return (
    isAuthenticated && (
      <Grid
        
        container
        direction={"row"}
        style={{
          borderRadius: 8,
          backgroundColor: "#39af30",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "4px 6px",
        }}
      >
        <Grid item>
          <IconButton
            onClick={() =>
              window.open("https://wa.me/+5493518687927", "_blank")
            }
            style={{ padding: "0 5px 2px 0" }}
          >
            <WhatsAppIcon
              fontSize={"small"}
              style={{ color: "#fff", padding: 0 }}
            />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography
            style={{
              textShadow: "0 1px 2px rgba(82, 82, 82, 0.15)",
              fontFamily: "Ubuntu",
              fontSize: 18,
              fontWeight: "bold",
              fontStyle: "normal",
              letterSpacing: 0,
              textAlign: "center",
              color: "#ffffff",
            }}
          >
            {" "}
            Contacto{" "}
          </Typography>
        </Grid>
      </Grid>
    )
  );
}

export { WhatsappButton, WhatsappButtonText };
