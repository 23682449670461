import React from 'react';
import './EnlacesModal.css';
import {PopUpCardModal} from "../../../../layouts/PopUpCardModal";
import {Button} from "../../../../components/Button";
import FileDownload from "js-file-download";
import showNotification from "../../../../modules/utils/showNotification";
import {sendWhatsapp} from "../../../../modules/utils/sendWhatsapp";
import {IconCopy} from "@tabler/icons";

function EnlacesModal(props) {
    const {setOpen, values} = props;
    const {QR, URL} = values;

    return (
        <PopUpCardModal
            title={"Enlaces del canal digital"}
            body={
                <div className="enlacesModal__body">
                    <img className="enlacesModal__image" src={QR} alt="Canal digital QR"/>
                    <div className="enlacesModal__link__container">
                        <p className="enlacesModal__linkText">{URL}</p>
                        <IconCopy className="enlacesModal__icon" onClick={() => {
                            let textarea = document.createElement("textarea");
                            textarea.textContent = URL;
                            textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
                            document.body.appendChild(textarea);
                            textarea.select();
                            try {
                                let copied = document.execCommand("copy");
                                showNotification({
                                    autoClose: 3000,
                                    color: 'green',
                                    status: 'success',
                                    title: '¡Copiado!',
                                    message: 'Ya puede compartir su link de canal digital.',
                                });
                                return copied;
                            }
                            catch (ex) {
                                console.warn("Copy to clipboard failed.", ex);
                                return prompt("Copy to clipboard: Ctrl+C, Enter", URL);
                            }
                            finally {
                                document.body.removeChild(textarea);
                            }
                        }}/>
                    </div>
                </div>
            }
            closeAction={() => setOpen({modalName: "", values: null})}
            footer={
                <div className="enlacesModal__button__container">
                    <Button variant="filled"
                            color="primary"
                            marginX="sm"
                            type="button"
                            enabled
                            onClick={() => {
                                fetch(QR)
                                    .then(res => res.blob())
                                    .then(blob => {
                                        FileDownload(blob, 'CanalDigitalQR.png');
                                    })
                                    .catch((error) => {
                                        showNotification({
                                            autoClose: 3000,
                                            color: 'red',
                                            status: 'error',
                                            title: 'Error al descargar el QR.',
                                            message: error,
                                        });
                                    });
                            }}
                    >
                        Descargar QR
                    </Button>
                    <Button variant="filled"
                            color="primary"
                            marginX="sm"
                            type="button"
                            enabled
                            onClick={
                                () => sendWhatsapp('https://wa.me/?text=Me%20complace%20informarle%20que%20su%20módulo%20web%20de%20venta%20directa%20se%20encuentra%20habilitado%20y%20puede%20comenzar%20a%20operar%20a%20través%20del%20mismo.%20Estos%20son%20los%20datos%20que%20necesitará%20para%20comenzar%20a%20operar:%20' + URL)
                            }
                    >
                        Compartir por Whatsapp
                    </Button>
                </div>
            }
        />
    );
}

export default EnlacesModal;