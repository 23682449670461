import React, {createRef, useState} from 'react';
import {useFormikContext} from "formik";
import {MantineInputField} from "../../../../components/FormFields/MantineInputField";
import {MantineSelectField} from "../../../../components/FormFields/MantineSelectField";
import {MantineDateField} from "../../../../components/FormFields/MantineDateField";
import PhoneInput from "react-phone-number-input";
import {MantineInputRefField} from "../../../../components/FormFields/MantineInputRefField";
import { Grid } from '@mantine/core';
import { ZipCodeFieldPackage } from '../../../Cotizacion/ZipCodeFieldPackage';

const ocupaciones = [
    {value: 'Contador', label: 'Contador'},
    {value: 'Ingeniero', label: 'Ingeniero'},
    {value: 'Abogado', label: 'Abogado'},
    {value: 'Medico', label: 'Medico'},
    {value: 'Productor_de_Seguros', label: 'Productor de Seguros'},
    {value: 'Mandatario_del_Automotor', label: 'Mandatario del Automotor'},
    {value: 'Corredor_inmobiliario', label: 'Corredor inmobiliario'},
    {value: 'Estudiante', label: 'Estudiante'},
    {value: 'Otro', label: 'Otro'}
];

const situaciones = [
    {value: 'Monotributista', label: 'Monotributista'},
    {value: 'Responsable_Inscripto', label: 'Responsable Inscripto'},
    {value: 'Sujeto_no_registrado', label: 'Sujeto no registrado'},
    {value: 'Consumidor_final', label: 'Consumidor final'}
];

const sexos = [
    {value: 'hombre', label: 'Hombre'},
    {value: 'mujer', label: 'Mujer'},
    {value: 'otro', label: 'Otro'},
    {value: 'no_contesta', label: 'Prefiero no contestar'}
];

export default function FormContent(props) {
    const {
        formField: {
            nombre, apellido, DNI, CUIT, nacimiento, l_nacimiento, nacionalidad, email, telefono, sexo, ocupacion, IVA,
            matricula, direccion, CBU, codigoPostal, codigosLocalidad, idProvincia
        },
    } = props;
    const {values, setFieldValue} = useFormikContext();
    const ref = createRef();

    const [localidadOptions, setLocalidadOptions] = useState([]);
    const [provinciasOptions, setProvinciasOptions] = useState([]);

    return (
        <div className="FContent__container">
            <Grid gutterXs="xl" gutter="xl" align="center">
                <Grid.Col xs={12} sm={6}>
                    <MantineInputField name={nombre.name} label={nombre.label} required />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <MantineInputField name={apellido.name} label={apellido.label} required />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <MantineSelectField name={sexo.name} label={sexo.label} data={sexos} required />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <MantineDateField name={nacimiento.name} label={nacimiento.label} required />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <MantineInputField name={DNI.name} label={DNI.label} required />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <MantineInputField name={CUIT.name} label={CUIT.label} required />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <MantineInputField name={email.name} label={email.label} required />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <PhoneInput
                        defaultCountry="AR"
                        ref={ref}
                        inputComponent={MantineInputRefField}
                        name={telefono.name}
                        label={telefono.label}
                        value={values.telefono}
                        onChange={(e) => {
                            /** @namespace e.length **/
                            if (e && e.length > 12) {
                                setFieldValue(telefono.name, e)
                            }
                        }}
                        withAsterisk
                    />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <MantineInputField name={l_nacimiento.name} label={l_nacimiento.label} required />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <MantineInputField name={nacionalidad.name} label={nacionalidad.label} required />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <MantineInputField name={direccion.name} label={direccion.label} required />
                </Grid.Col>
                <>
                    <ZipCodeFieldPackage localidadField={codigosLocalidad} localidadSize={6}
                                         codigoPostalField={codigoPostal} codigoPostalSize={6}
                                         provinciaField={idProvincia} provinciaSize={6}
                                         localidadOptions={localidadOptions || []} setLocalidadOptions={setLocalidadOptions}
                                         provinciasOptions={provinciasOptions}
                                         setProvinciaOptions={setProvinciasOptions}
                    />
                </>
                <Grid.Col xs={12} sm={6}>
                    <MantineSelectField name={ocupacion.name} label={ocupacion.label} data={ocupaciones} required />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <MantineSelectField name={IVA.name} label={IVA.label} data={situaciones} required />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <MantineInputField name={CBU.name} label={CBU.label} />
                </Grid.Col>

                {values.ocupacion === 'Productor_de_Seguros' && (
                    <Grid.Col xs={12} sm={6}>
                        <MantineInputField name={matricula.name} label={matricula.label} />
                    </Grid.Col>)}
            </Grid>
        </div>

    )
}
