import React from 'react';
import {MantineInputField} from "../../../../components/FormFields/MantineInputField";
import {Grid} from "@mantine/core";
import {MantineSelectField} from "../../../../components/FormFields/MantineSelectField";
import {MantineRadioButtonsField} from "../../../../components/FormFields/MantineRadioButtonsField";
import {MantineNumberField} from "../../../../components/FormFields/MantineNumberField";
import {MantineDateField} from "../../../../components/FormFields/MantineDateField";
import {MantineMultiSelectField} from "../../../../components/FormFields/MantineMultiSelectField";
import {ZipCodeFieldPackage} from "../../../Cotizacion/ZipCodeFieldPackage";

/**
 * Función que recibe el objeto initialValues y devuelve un array de JSX
 * para los campos con valores no vacíos.
 * @param {Object} initialValues - Valores iniciales del formulario.
 * @param {Object[]} fieldsToCheck - Campos a chequear y renderizar.
 * @param localidadOptions
 * @param setLocalidadOptions
 * @param provinciasOptions
 * @param setProvinciasOptions
 * @return {Array} Array de JSX.
 */
export const renderNonEmptyFields = (initialValues, fieldsToCheck, localidadOptions, setLocalidadOptions, provinciasOptions, setProvinciasOptions) => {
    return fieldsToCheck
        .filter((fieldName) => initialValues[fieldName.name] === '' || (Array.isArray(initialValues[fieldName.name]) && initialValues[fieldName.name].length === 0))
        .map((fieldName) => {
            switch (fieldName.type) {
                case "text":
                    return (
                        <Grid.Col xs={12} sm={6}>
                            <MantineInputField
                                name={fieldName.name}
                                label={fieldName.label}
                            />
                        </Grid.Col>
                    )
                case "select":
                    return (
                        <Grid.Col xs={12} sm={6}>
                            <MantineSelectField
                                name={fieldName.name}
                                label={fieldName.label}
                                data={fieldName.options}
                            />
                        </Grid.Col>
                    )
                case "radio":
                    return (
                        <Grid.Col xs={12} sm={6}>
                            <MantineRadioButtonsField
                                name={fieldName.name}
                                label={fieldName.label}
                                options={fieldName.options}
                            />
                        </Grid.Col>
                    )
                case "number":
                    return (
                        <Grid.Col xs={12} sm={6}>
                            <MantineNumberField
                                name={fieldName.name}
                                label={fieldName.label}
                                notFormat
                            />
                        </Grid.Col>
                    )
                case "date":
                    return (
                        <Grid.Col xs={12} sm={6}>
                            <MantineDateField
                                name={fieldName.name}
                                label={fieldName.label}
                            />
                        </Grid.Col>
                    )
                case "multiSelect":
                    return (
                        <Grid.Col xs={12} sm={6}>
                            <MantineMultiSelectField
                                name={fieldName.name}
                                label={fieldName.label}
                                data={fieldName.options}
                                multiple
                            />
                        </Grid.Col>
                    )
                case "localidad":
                    return (
                        <>
                            <ZipCodeFieldPackage
                                localidadField={{name: 'localidad', label: 'Localidad'}}
                                localidadSize={6}
                                provinciaField={{name: 'provincia', label: 'Provincia'}}
                                provinciaSize={6}
                                codigoPostalField={{name: 'codigo_postal', label: 'Código postal'}}
                                codigoPostalSize={6}
                                localidadOptions={localidadOptions || []}
                                setLocalidadOptions={setLocalidadOptions}
                                provinciasOptions={provinciasOptions}
                                setProvinciaOptions={setProvinciasOptions}
                            />
                        </>
                    )
                default:
                    return (
                        <Grid.Col xs={12} sm={6}>
                            <MantineInputField
                                name={fieldName.name}
                                label={fieldName.label}
                            />
                        </Grid.Col>
                    )
            }
        });
};
