const normalizeString = (str, specialChars, fileNameExceptions, replaceChar) => {
    let symbolsExceptions = [];
    if (fileNameExceptions) {
        symbolsExceptions = ['&', '/', '\\', '#', ',', '+', '(', ')', '$', '~', '%', '\'', '\"', ':', '*', '?', '<', '>', '{', '}', '-'];
    } else {
        symbolsExceptions = specialChars;
    }
    const withoutAccents = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    const specialCharsRegex = new RegExp(`[${symbolsExceptions.join('').replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')}]`, 'g');

    return withoutAccents.replace(specialCharsRegex, replaceChar);
}

export default normalizeString;