import React from 'react';
import { Paper } from '@material-ui/core';
import { useStyle } from './styles';
import NavBar from "../../views/NavBar/NavBar";
import Typography from "../Typography";
import Link from "@material-ui/core/Link";


function Copyright() {
    return (
        <Typography
            style={{
                fontFamily: "Montserrat",
                fontSize: 14,
                fontWeight: "normal",
                fontStyle: "normal",
                letterSpacing: 0,
                color: '#8a8597'
            }}
            align="center">
            <Link color="inherit" href="https://www.polko.com.ar/">
                POLKO SAS®
            </Link>{' '}
            {'Copyright © '}


            {new Date().getFullYear()}
            {'. '}
            {'Todos los derechos reservados.'}
        </Typography>
    );
}

export default function MaterialLayout(props) {
    const { children } = props;
    const classes = useStyle();

    return (
        <div>
            <NavBar />
            <div className={classes.root} style={{ backgroundColor:"#fff"}}>
                <Paper className={classes.paper}>
                    {children}
                </Paper>
            </div>
            <Copyright/>
        </div>
    );
}
