import React from 'react';
import {useFormikContext} from "formik";
import {DescriptionOptions} from "../../../Utils/DescriptionOption";
import "./DescripcionForm.css";


export default function DescripcionForm(props) {
    const {formField: {description}} = props;
    const {values, setFieldValue, errors} = useFormikContext();

    const setDescriptions = (newDescriptions) => {
        setFieldValue(description.name, newDescriptions)
    }

    return (
        <React.Fragment>
            <div className="DForm__container">
                <label className="DForm__labelText">{description.label}</label>
                <div className="DForm__descriptionCardsContainer">
                    <DescriptionOptions options={values.description} setOptions={setDescriptions}/>
                </div>
                <p className="DForm__error">
                    {errors[description.name]}
                </p>
            </div>
        </React.Fragment>
    );
}
