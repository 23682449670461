import React, {useContext, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {useAuth0} from "@auth0/auth0-react";
import Axios from "axios";
import {withStyles} from '@material-ui/core/styles';
import Typography from '../Typography';
import PropTypes from "prop-types";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faIdCard, faBoxOpen, faFolderOpen} from "@fortawesome/free-solid-svg-icons";
import medallaoro from "../../../assets/images/medallas/medallaOro.svg";
import medallaplata from "../../../assets/images/medallas/medallaPlata.svg";
import medallacobre from "../../../assets/images/medallas/medallaCobre.svg";
import {Grid, Modal} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import {Link as RouterLink, useHistory} from 'react-router-dom';
import {useQuery, gql} from "@apollo/client";

//ASSETS PARA MARKETING
import tarjetaPolko from "../../../assets/images/tarjetaPolko.jpg";
import plantilla3 from "../../../assets/images/plantilla3.png";


//PRODUCTO
import producto1 from "../../../assets/images/marketing/productos/Productos. Placa seguro de auto 1.0.jpg";
import producto2 from "../../../assets/images/marketing/productos/Productos. Placa seguro de autos 1.1.jpg";
import producto3 from "../../../assets/images/marketing/productos/Productos. Placa seguro de autos 1.2.jpg";
import producto4 from "../../../assets/images/marketing/productos/Productos. Seguro de autos 0km.jpg";
import producto5 from "../../../assets/images/marketing/productos/Llamando al doctor.jpg";

//COMPLEMENTARIOS
import complementario1
    from "../../../assets/images/marketing/complementarios/Complementario. Conduccion en rotondas.jpg";
import complementario2
    from "../../../assets/images/marketing/complementarios/Complementario. Consejo uso de aire acondicionado.jpg";
import complementario3
    from "../../../assets/images/marketing/complementarios/Complementario. Inhibidores de alarmas.jpg";
import complementario4
    from "../../../assets/images/marketing/complementarios/Complementario. Recordatorio control de documentacion.jpg";
import complementario5
    from "../../../assets/images/marketing/complementarios/Complementario. Recordatorio de plazo para formalizar la denuncia.jpg";
import complementario6
    from "../../../assets/images/marketing/complementarios/Complementarios. Como actuar en caso de siniestros.jpg";
import complementario7
    from "../../../assets/images/marketing/complementarios/Complementarios. Cuidado del vehiculo en invierno.jpg";
import complementario8
    from "../../../assets/images/marketing/complementarios/Complementarios. Recordatorio antes de viajar (1).jpg";
import AuthContext from "../../../context/AuthContext";
import UserContext from "../../../context/UserContext";
import {ModalURLandQR} from "../../MasterPage/CanalDigital/CanalDigitalTableModals";


const FileDownload = require('js-file-download');
const tarjetaDefault = tarjetaPolko;
const tarjetaPersonal3 = plantilla3;


const styles = (theme) => ({
    modalColumn: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly"
    },
    modalSubtitle: {
        fontFamily: "Ubuntu",
        fontWeight: "700",
        fontSize: "16px",
        textAlign: "center"
    },
    modalText: {
        fontFamily: "Ubuntu",
        fontWeight: "400",
        fontSize: "14px",
        textAlign: 'center',
        width: "100%"
    },
    modalBlock: {
        margin: "8px 16px"
    },
    rightLink: {
        fontSize: 14,
        color: theme.palette.common.white,
        cursor: 'pointer'
    },
    nombreMenu: {
        fontSize: '16px',
        color: '#f3f4f8',
        fontFamily: 'Ubuntu',
        fontWeight: 'bold',
    },
    nivelMenu: {
        fontSize: 13,
        color: '#c0c0c0',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        fontStyle: 'normal'
    },

    divMenu: {
        width: '320px',
        height: '100%',
        margin: '0 0',
        padding: '0 0 50px',
        backgroundColor: '#35303f',
        shadowColor: "rgba(0, 0, 0, 0.23)",
        shadowOffset: {
            width: -5,
            height: 0
        },
        shadowRadius: 10,
        shadowOpacity: 1
    },
    menuPaper: {
        backgroundColor: "#35303f",
        borderRadius: '0',
        paddingTop: 0,
        marginTop: '54px',
        width: '320px',
        height: 'auto',
        shadowColor: "rgba(0, 0, 0, 0.23)",
        shadowOffset: {
            width: -5,
            height: 0
        },
        shadowRadius: 10,
        shadowOpacity: 1
    },
    tituloMenu: {
        fontFamily: 'Ubuntu',
        fontSize: 14,
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: '#c0c0c0'
    },
    infoMenu: {
        fontFamily: "Ubuntu",
        fontSize: 18,
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        color: '#f3f4f8',
        marginBottom: '10px'
    },
    botonMenu: {
        width: 210,
        height: 48,
        borderRadius: 8,
        backgroundColor: '#62c08a',
        boxShadow: 'inset 0 0 8px 0 rgba(112, 232, 163, 0.5)',
        '&:hover': {
            backgroundColor: '#48926b',
            borderColor: '#48926b',
            boxShadow: 'none',
        },
        textTransform: 'none',
        margin: '20px 0 ',

    },

    botonText: {
        fontFamily: "Ubuntu",
        fontSize: 20,
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "center",

        color: "#ffffff",
        textShadowColor: "rgba(82, 82, 82, 0.15)",
        textShadowOffset: {
            width: 0,
            height: 1
        },
        textShadowRadius: 2
    },
    posicionMenu: {
        fontFamily: "Ubuntu",
        fontSize: 18,
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: '#35303f',
        textAlign: 'center'
    },

    medallasMenu: {
        fontFamily: "Catamaran",
        fontSize: 15,
        margin: '8px',
        fontStyle: "normal",
        letterSpacing: 0,
        color: '#8a8597'
    },

    //MEDALLAS

    medallaOro: {
        color: '#cdbc10',
        fontSize: '23px',
        textAlign: 'center',
        marginBottom: '4px',
        marginLeft: '3px'
    },

    medallaVinito: {
        color: '#780132',
        fontSize: '23px',
        textAlign: 'center',
        marginBottom: '4px',
        marginLeft: '3px'
    },

    marketing: {
        fontFamily: "Ubuntu",
        fontSize: 20,
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "center",
        color: "#ffffff",
        textShadowColor: "rgba(82, 82, 82, 0.15)",
        textShadowOffset: {
            width: 0,
            height: 1
        },
        textShadowRadius: 2,
        paddingTop: '10px',
        marginBottom: '15px'
    },
    drawerInfo: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: theme.spacing(6)
    },
    rectangle: {
        width: ' 160px',
        borderRadius: '8px',
        boxShadow: '0 5px 40px 0 rgba(70, 70, 70, 0.1)',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center', alignItems: 'center',
        marginBottom: '15px'

    },
    rectangle2: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center', alignItems: 'center',
    },

    rectangleTitulo: {
        textAlign: 'center',
        fontSize: 18,
        color: '#8a8597',
        fontFamily: 'Catamaran',
        fontWeight: 'bold',
        marginTop: '6px'
    },

    linkText: {
        textAlign: 'center',
        fontSize: 16,
        color: '#35303f',
        fontFamily: 'Ubuntu',
        fontWeight: 'bold',
        margin: '0 0 0 10px'
    },

    //MODAL
    modal: {
        position: 'absolute',
        width: '80vw',
        maxWidth: '800px',
        height: '90%',
        overflowY: 'auto',
        backgroundColor: '#fff',
        boxShadow: '0 5px 40px 0 rgba(70, 70, 70, 0.15)',
        padding: theme.spacing(2, 4, 3, 4),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        [theme.breakpoints.down('lg')]: {
            height: 'auto',
            maxHeight: '660px',
            width: '90vw',
            padding: theme.spacing(2, 3, 3, 3),
        },
        // [theme.breakpoints.down('xs')]:{
        //
        // },


    },

    titulomodal: {
        textAlign: 'center',
        fontSize: '24px',
        color: '#452d7b',
        fontFamily: 'Ubuntu',
        fontWeight: 'bold',
        letterSpacing: '-0.5',
        marginBottom: '15px'
    },

    rootModal: {
        display: 'flex', alignItems: 'center', justifyContent: 'center',

    },

    gridTarjeta: {
        width: '85%',
        borderRadius: '8px',
        boxShadow: '0 5px 40px 0 rgba(70, 70, 70, 0.2)',
        backgroundColor: '#fff',
        margin: '10px 0 10px',
        flexDirection: 'row',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            padding: '10px'
        },
    },
    gridTarjetaImagen: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
    },

    image1: {
        width: '100%',
        maxWidth: '120px',
        padding: '10px 0 10px 10px'
    },

    subtituloGrid: {
        textAlign: 'center',
        fontSize: '20px',
        color: '#35303f',
        fontFamily: 'Ubuntu',
        fontWeight: 'bold',
    },

    botonGrid: {
        backgroundColor: '#62c08a',
        boxShadow: 'inset 0 0 8px 0 rgba(112, 232, 163, 0.5)',
        '&:hover': {
            backgroundColor: '#48926b',
            borderColor: '#48926b',
            boxShadow: 'none',
        },
    },

    botonGridTexto: {
        textAlign: 'center',
        fontSize: '20px',
        color: '#fff',
        fontFamily: 'Ubuntu',
        fontWeight: 'bold',
        textShadowColor: "rgba(82, 82, 82, 0.15)",
        textShadowOffset: {
            width: 0,
            height: 1
        },
        textShadowRadius: 2
    },


    //FIN DEL MODAL
    logoutButton: {
        fontSize: '18px',
        color: '#c0c0c0',
        fontFamily: 'Ubuntu',
        fontWeight: 'bold',
        textShadowColor: "rgba(82, 82, 82, 0.15)",
        textShadowOffset: {
            width: 0,
            height: 1
        },
        textShadowRadius: 2
    },

    //ARREGLOS MOBILE
    nombreDiv: {
        [theme.breakpoints.down('lg')]: {
            display: 'none'
        },
    },
    imagenPerfil: {
        width: '48px', height: '48px', borderRadius: '50%',
        border: '2px solid #fff',
        margin: '0 10px',
        cursor: 'pointer',
        [theme.breakpoints.down('md')]: {
            margin: 0
        },
    },
    divMobile: {
        display: 'flex', alignItems: 'center', margin: '0 20px',
        [theme.breakpoints.down('lg')]: {
            margin: 0
        },
    },

    divCerrarSesion: {
        display: 'flex', justifyContent: 'flex-end',
        [theme.breakpoints.down('lg')]: {
            justifyContent: 'center',
        },
    },


    gridConstructorTarjetas: {
        display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 30px'
        , [theme.breakpoints.down('xs')]: {
            justifyContent: 'center'
        },
    },
    imagenConstructorTarjetas: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
    }


});

const GET_PIEZAS_MASTER = gql`
    query MyQuery {
        General_Piezas_Graficas_Master(order_by: {id: desc}) {
            Nombre
            id
        }
    }
`;

function ProfileMenu(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const {userMatricula,userNiveles,userIdAuth,userProvince,userMail,userPhone,userName, userLastName,userPicture,userCargo} = useContext(UserContext);
    const {classes} = props;

    const {isMaster, isAuth} = useContext(AuthContext);
    const {userWebHabilitado, userLink, userQR} = useContext(UserContext);
    const {logout} = useAuth0();

    const history = useHistory();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        if (modalCanalDigital) {
            setModalCanalDigital(!modalCanalDigital)
        } else {
            setAnchorEl(null);
        }
    };

    //MODAL
    const [modal, setModal] = useState(false);
    const abrirCerrarModal = () => {
        setModal(!modal);
    }
    const [modal1, setModal1] = useState(false);
    const abrirCerrarModal1 = () => {
        setModal1(!modal1);
    }
    const [modal2, setModal2] = useState(false);
    const abrirCerrarModal2 = () => {
        setModal2(!modal2);
    }
    const [modal3, setModal3] = useState(false);
    const abrirCerrarModal3 = () => {
        setModal3(!modal3);
    }
    const [modalCanalDigital, setModalCanalDigital] = useState(false);


    function ConstructorTarjetas(props) {
        let titulo = props.titulo;
        let descarga = props.descarga;

        return (
            <Grid container direction={"row"} className={classes.gridConstructorTarjetas}>
                <Grid item className={classes.imagenConstructorTarjetas}>
                    <img className={classes.image1}
                         src={tarjetaDefault}/>
                </Grid>

                <Grid item>
                    <Typography className={classes.subtituloGrid}>
                        {titulo}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button className={classes.botonGrid}
                            href={descarga}
                            download
                            target={"_blank"}>
                        <Typography className={classes.botonGridTexto}>
                            Descargar
                        </Typography>
                    </Button>
                </Grid>

            </Grid>
        )
    };


    //PERSONALES


    const bodyPersonales = (
        <div className={classes.modal}>
            <Typography className={classes.titulomodal}>
                Kit de marketing personal
            </Typography>

            <Grid container direction={"column"} className={classes.rootModal}>
                <div className={classes.gridTarjeta}>
                    <Grid container style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
                        <Grid item className={classes.gridTarjetaImagen}>
                            <img className={classes.image1}
                                 src={tarjetaPersonal3}/>
                        </Grid>

                        <Grid item>
                            <Typography className={classes.subtituloGrid}>
                                Tarjeta Personal
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button className={classes.botonGrid} onClick={tarjetapersonal}>
                                <Typography className={classes.botonGridTexto}>
                                    Descargar
                                </Typography>
                            </Button>
                        </Grid>

                    </Grid>
                </div>

                <div className={classes.gridTarjeta}>
                    <Grid container direction={"row"}
                          style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
                        <Grid item className={classes.gridTarjetaImagen}>
                            <img className={classes.image1}
                                 src={tarjetaDefault}/>
                        </Grid>

                        <Grid item>
                            <Typography className={classes.subtituloGrid}>
                                Membrete de Mail
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button className={classes.botonGrid}
                                    onClick={pieMail}
                            >

                                <Typography className={classes.botonGridTexto}>
                                    Descargar
                                </Typography>
                            </Button>
                        </Grid>

                    </Grid>
                </div>

                {userWebHabilitado &&
                    <div className={classes.gridTarjeta}>
                        <Grid container direction={"row"}
                              style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
                            <Grid item className={classes.gridTarjetaImagen}>
                                <img className={classes.image1}
                                     src={tarjetaDefault}/>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.subtituloGrid}>
                                    Web de venta directa
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button className={classes.botonGrid}
                                        onClick={ventaDirecta}
                                >
                                    <Typography className={classes.botonGridTexto}>
                                        Ver datos
                                    </Typography>
                                </Button>
                            </Grid>

                        </Grid>
                    </div>
                }

            </Grid>
        </div>
    )


    //PRODUCTOS

    let productosCombinado = ['https://polko-images.s3.us-east-2.amazonaws.com/Asistente_de_MKT/Productos/SEGURO+HOGAR-01.jpg',
        'https://polko-images.s3.us-east-2.amazonaws.com/Asistente_de_MKT/Productos/SEGURO+HOGAR-02.jpg',
        'https://polko-images.s3.us-east-2.amazonaws.com/Asistente_de_MKT/Productos/SEGURO+HOGAR-03.jpg',
        'https://polko-images.s3.us-east-2.amazonaws.com/Asistente_de_MKT/Productos/SEGURO+HOGAR-04.jpg',
        'https://polko-images.s3.us-east-2.amazonaws.com/Asistente_de_MKT/Productos/SEGURO+HOGAR-05.jpg']

    const bodyProductos = (
        <div className={classes.modal}>
            <Typography className={classes.titulomodal}>
                Kit de marketing de productos
            </Typography>

            <Grid container direction={"column"} className={classes.rootModal}>
                <div className={classes.gridTarjeta}>
                    <ConstructorTarjetas
                        titulo='Seguro de Autos'
                        descarga={producto1}/>
                </div>

                <div className={classes.gridTarjeta}>
                    <ConstructorTarjetas
                        titulo='Seguro de Autos 2'
                        descarga={producto2}/>
                </div>

                <div className={classes.gridTarjeta}>
                    <ConstructorTarjetas
                        titulo='Seguro de Autos 3'
                        descarga={producto3}/>
                </div>

                <div className={classes.gridTarjeta}>
                    <ConstructorTarjetas
                        titulo='Seguro de Autos 0km'
                        descarga={producto4}/>
                </div>

                <div className={classes.gridTarjeta}>
                    <ConstructorTarjetas
                        titulo='Llamando al Doctor'
                        descarga={producto5}/>
                </div>

                {productosCombinado.map((piezas, index) =>

                    <div className={classes.gridTarjeta}>
                        <ConstructorTarjetas
                            titulo={'Seguro del Hogar ' + (index + 1)}
                            descarga={piezas}/>
                    </div>
                )}

            </Grid>
        </div>
    )


    const bodyComplementarios = (
        <div className={classes.modal}>
            <Typography className={classes.titulomodal}>
                Kit de marketing de complementarios
            </Typography>

            <Grid container direction={"column"} className={classes.rootModal}>

                <div className={classes.gridTarjeta}>
                    <ConstructorTarjetas
                        titulo='Conduccion en rotondas'
                        descarga={complementario1}/>
                </div>

                <div className={classes.gridTarjeta}>
                    <ConstructorTarjetas
                        titulo='Aire Acondicionado'
                        descarga={complementario2}/>
                </div>

                <div className={classes.gridTarjeta}>
                    <ConstructorTarjetas
                        titulo='Inhibidores de alarmas'
                        descarga={complementario3}/>
                </div>

                <div className={classes.gridTarjeta}>
                    <ConstructorTarjetas
                        titulo='Documentacion'
                        descarga={complementario4}/>
                </div>

                <div className={classes.gridTarjeta}>
                    <ConstructorTarjetas
                        titulo='Plazo formalizar denuncia'
                        descarga={complementario5}/>
                </div>

                <div className={classes.gridTarjeta}>
                    <ConstructorTarjetas
                        titulo='Siniestros'
                        descarga={complementario6}/>
                </div>
                <div className={classes.gridTarjeta}>
                    <ConstructorTarjetas
                        titulo='Cuidado vehiculo invierno'
                        descarga={complementario7}/>
                </div>
                <div className={classes.gridTarjeta}>
                    <ConstructorTarjetas
                        titulo='Recordatorio Viajes'
                        descarga={complementario8}/>
                </div>


            </Grid>
        </div>
    )


    const [piezasMaster, setPiezasMaster] = useState(null)
    const {data: piezasData} = useQuery(GET_PIEZAS_MASTER);


    function tarjetapersonal() {

        let mail = userMail;
        let telefono = userPhone;
        let ubicacion = `${userProvince}, Argentina`;
        let nombre = userName + " " + userLastName;
        let cargo = userCargo;
        let matriculaMaster = userMatricula;

        Axios.post(process.env.REACT_APP_BACK_SERVER + `/tarjetapersonal?nombre=${nombre}&mail=${mail}&telefono=${telefono}&ubicacion=${ubicacion}&cargo=${cargo}&matriculaMaster=${matriculaMaster}`, {}, {responseType: 'blob'})
            .then((response) => {
                FileDownload(response.data, 'tarjeta.png');
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function pieMail() {

        let mail = userMail;
        let telefono = userPhone;
        let ubicacion = `${userProvince}, Argentina`;
        let nombre = userName + " " + userLastName;
        let cargo = userCargo;
        let matriculaMaster = userMatricula;
        let foto = userPicture;

        Axios.post(process.env.REACT_APP_BACK_SERVER + `/piedemail?nombre=${nombre}&mail=${mail}&telefono=${telefono}&ubicacion=${ubicacion}&image=${foto}&cargo=${cargo}&matriculaMaster=${matriculaMaster}`, {}, {responseType: 'blob'})
            .then((response) => {
                FileDownload(response.data, 'piemail.png');
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function ventaDirecta() {
        abrirCerrarModal()
        setModalCanalDigital(!modalCanalDigital)
    }

    useEffect(() => {
        if (typeof userIdAuth === "undefined")
            history.push('/form')
        if (piezasData && isMaster) {
            setPiezasMaster(piezasData['General_Piezas_Graficas_Master'])
        }
    }, [piezasData, isMaster]);


    return isAuth && (
        <div style={{display: 'flex'}}>

            <div className={classes.divMobile}>

                <div style={{textAlign: 'end'}} className={classes.nombreDiv}>
                    <Typography className={classes.nombreMenu}>{userName + " " + userLastName}</Typography>
                    <Typography className={classes.nivelMenu}>Polkista Nivel {userNiveles['Nivel']}</Typography>
                </div>
                <img
                    aria-label="account of current user"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    src={userPicture}
                    className={`${classes.imagenPerfil} tour-perfil`}
                />

                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted

                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    classes={{paper: classes.menuPaper}}
                >
                    <div style={{margin: '10px 35px'}}>
                        <Typography className={classes.tituloMenu}>NOMBRE:</Typography>
                        <Typography className={classes.infoMenu}>{userName + " " + userLastName}</Typography>

                        <Typography className={classes.tituloMenu}>EMAIL:</Typography>
                        <Typography className={classes.infoMenu}>{userMail}</Typography>

                        <Typography className={classes.tituloMenu}>NIVEL:</Typography>
                        <Typography className={classes.infoMenu}
                                    style={{
                                        color: 'fff',
                                        fontWeight: 'bold'
                                    }}>POLKISTA {userNiveles['Nivel']}</Typography>
                    </div>
                    <div style={{textAlign: 'center'}}>
                        <RouterLink style={{textDecoration: 'None'}} to={"/u/perfil"}>
                            <Button className={classes.botonMenu}>
                                <Typography className={classes.botonText}>Editar Perfil</Typography>
                            </Button>
                        </RouterLink>
                    </div>

                    <div style={{backgroundColor: '#fff', textAlign: 'center'}}>

                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Typography className={classes.posicionMenu}>
                                Ránking: {userNiveles['Posicion']}
                            </Typography>

                            <ArrowDropUpIcon style={{
                                color: '#62c08a',
                                fontSize: '50px',
                                textAlign: 'center',
                                marginLeft: '1px'
                            }}/>
                        </div>

                        <Typography className={classes.medallasMenu}>
                            Medallas
                        </Typography>


                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingBottom: '10px'
                        }}>

                            {userNiveles['Medallas'].length !== 0 &&
                                userNiveles['Medallas'].map(item => {
                                    switch (item) {
                                        case 'oro':
                                            return <img src={medallaoro}
                                                        style={{width: '48px', maxHeight: '50px', marginRight: '5px'}}/>;
                                        case 'plata':
                                            return <img src={medallaplata}
                                                        style={{width: '48px', maxHeight: '50px', marginRight: '5px'}}/>;
                                        case 'cobre':
                                            return <img src={medallacobre}
                                                        style={{width: '48px', maxHeight: '50px', marginRight: '5px'}}/>;
                                    }
                                })
                            }
                        </div>

                    </div>

                    <div>
                        <Typography className={classes.marketing}>
                            Asistente de MKT
                        </Typography>

                        <div className={classes.drawerInfo}>

                            <div className={classes.rectangle}>
                                <Typography className={classes.rectangleTitulo}>Personales</Typography>
                                <div className={classes.rectangle2}>
                                    <FontAwesomeIcon icon={faIdCard} style={{
                                        color: '#62c08a',
                                        fontSize: '40px',
                                        textAlign: 'center',
                                        marginTop: '4px',
                                        marginBottom: '5px'
                                    }}/>
                                    <Modal
                                        open={modal}
                                        onClose={abrirCerrarModal}
                                    >
                                        {bodyPersonales}

                                    </Modal>
                                    <Link onClick={() => abrirCerrarModal()}>
                                        <Typography className={classes.linkText}> Ver lista</Typography>
                                    </Link>
                                </div>

                            </div>


                            <div className={classes.rectangle}>
                                <Typography className={classes.rectangleTitulo}>Productos</Typography>

                                <div className={classes.rectangle2}>
                                    <FontAwesomeIcon icon={faBoxOpen} style={{
                                        color: '#62c08a',
                                        fontSize: '40px',
                                        textAlign: 'center',
                                        marginTop: '4px',
                                        marginBottom: '5px'
                                    }}/>

                                    <Modal
                                        open={modal1}
                                        onClose={abrirCerrarModal1}
                                    >
                                        {bodyProductos}

                                    </Modal>
                                    <Link onClick={() => abrirCerrarModal1()}>
                                        <Typography className={classes.linkText}> Ver lista</Typography>
                                    </Link>
                                </div>
                            </div>

                            <div className={classes.rectangle}>
                                <Typography className={classes.rectangleTitulo}>Complementarios</Typography>

                                <div className={classes.rectangle2}>
                                    <FontAwesomeIcon icon={faFolderOpen} style={{
                                        color: '#62c08a',
                                        fontSize: '40px',
                                        textAlign: 'center',
                                        marginTop: '4px',
                                        marginBottom: '5px'
                                    }}/>
                                    <Modal
                                        open={modal2}
                                        onClose={abrirCerrarModal2}
                                    >
                                        {bodyComplementarios}

                                    </Modal>


                                    <Link onClick={() => abrirCerrarModal2()}>
                                        <Typography className={classes.linkText}> Ver lista</Typography>
                                    </Link>
                                </div>
                            </div>

                            <Modal
                                open={modalCanalDigital}
                                onClose={ventaDirecta}
                            >
                                <div className={classes.modal} style={{width: "500px"}}>
                                    <Typography className={classes.titulomodal}>
                                        Información del Canal Digital
                                    </Typography>
                                    <div className={classes.modalColumn}>

                                        <div className={classes.modalBlock}>
                                            <p className={classes.modalSubtitle}>Link:</p>
                                            <a className={classes.modalText} href={userLink}
                                               target="_blank">{userLink}</a>
                                        </div>

                                        <div className={classes.modalBlock}>
                                            <p className={classes.modalSubtitle}>QR:</p>
                                            <img src={userQR} width={"200px"} height={"200px"}/>
                                        </div>
                                    </div>
                                </div>
                            </Modal>

                            {isMaster && piezasMaster !== null ?
                                <div className={classes.rectangle}>
                                    <Typography className={classes.rectangleTitulo}>Master</Typography>

                                    <div className={classes.rectangle2}>
                                        <FontAwesomeIcon icon={faFolderOpen} style={{
                                            color: '#62c08a',
                                            fontSize: '40px',
                                            textAlign: 'center',
                                            marginTop: '4px',
                                            marginBottom: '5px'
                                        }}/>
                                        <Modal
                                            open={modal3}
                                            onClose={abrirCerrarModal3}
                                        >

                                            <>
                                                <div className={classes.modal}>
                                                    <Typography className={classes.titulomodal}>
                                                        Kit de marketing de usuarios master
                                                    </Typography>

                                                    {piezasMaster.length === 0 ?
                                                        <Grid container direction={"column"}
                                                              className={classes.rootModal}>
                                                            <div className={classes.gridTarjeta}
                                                                 style={{padding: '30px'}}>


                                                                <Typography style={{
                                                                    fontFamily: 'Ubuntu',
                                                                    fontSize: '30px',
                                                                    fontWeight: 500,
                                                                    color: '#8a8597',
                                                                    textAlign: "center",
                                                                }}>
                                                                    Aun no hay piezas graficas disponibles
                                                                </Typography>
                                                            </div>

                                                        </Grid>
                                                        :

                                                        <Grid container direction={"column"}
                                                              className={classes.rootModal}>
                                                            {piezasMaster.map((piezas) =>
                                                                <div className={classes.gridTarjeta}>
                                                                    <ConstructorTarjetas
                                                                        titulo={piezas['Nombre']}
                                                                        descarga={'https://polko-images.s3.us-east-2.amazonaws.com/Admin-MKT/' + piezas['id'] + '.jpg'}/>

                                                                </div>
                                                            )}
                                                        </Grid>
                                                    }
                                                </div>
                                            </>


                                        </Modal>


                                        <Link onClick={() => abrirCerrarModal3()}>
                                            <Typography className={classes.linkText}> Ver lista</Typography>
                                        </Link>
                                    </div>
                                </div>
                                : <></>
                            }
                        </div>
                    </div>
                    <div className={classes.divCerrarSesion}>
                        <MenuItem
                            onClick={() => logout({returnTo: window.location.origin})}
                            className={classes.logoutButton}
                        >
                            CERRAR SESION
                        </MenuItem>
                    </div>
                </Menu>
            </div>
        </div>
    );
}

ProfileMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProfileMenu);
