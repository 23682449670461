import React, {useContext} from 'react';
import './DocumentacionCard.css';
import {Menu} from '@mantine/core';
import {ReactComponent as DownloadDocIcon} from "../../assets/icons/ic_download_doc.svg"
import CotizarContext from "../../context/CotizarContext";
import {Loading} from "../../components/Loading"
import {Button} from "../Button";
import AuthContext from "../../context/AuthContext";
import copyToClipboard from "../../modules/utils/copyToClipboard";

function DocumentacionCard(props) {
    const {companiesDict} = useContext(CotizarContext);
    const {isMaster} = useContext(AuthContext)
    const {data, downloadDocument, isDownloading, index} = props;
    const {
        aseguradora,
        producto,
        valor,
        numeroReferencia,
        estado,
        clientes,
        fecha,
        detalle,
        documentacion,
        identificadores,
        estado_pago,
        link_pago
    } = data;
    const cliente = clientes[0];
    const status = companiesDict[aseguradora].emissionStatus[estado];
    const STATUS_MAPPINGS = {
        "rejected": {style: "documentacionCard__text--cancel", text: "Cancelada"},
        "pending": {style: "documentacionCard__text--pending", text: "Pendiente"},
        "approved": {style: "documentacionCard__text--success", text: "Emitida"}
    };
    const PAY_STATUS_MAPPINGS = {
        "SIN_ENVIAR": {style: "documentacionCard__text--pending", text: "En proceso"},
        "ENVIADO": {style: "documentacionCard__text--pending", text: "En proceso"},
        "PAGADO": {style: "documentacionCard__text--success", text: "Pagado"}
    };

    const {style: statusStyle, text: statusText} = STATUS_MAPPINGS[status] || {};
    const {style: payStatusStyle, text: payStatusText} = PAY_STATUS_MAPPINGS[estado_pago] || {};
    let realDocumentation = documentacion;
    let realProducto = producto;

    if (aseguradora === "TerraWind") {
        if (!realDocumentation) realDocumentation = [];
        data?.pasajeros.forEach((pasajero) => {
            if (pasajero.documentacion) {
                realDocumentation = realDocumentation.concat(pasajero.documentacion);
            }
        });
        realProducto = "ASISTENCIA AL VIAJERO";
    }

    const showRetencionButton = isMaster && statusText === "Emitida" && producto === "AUTOMOTOR";

    return (
        <div className="documentacionCard__container">
            <img className="documentacionCard__img" src={companiesDict[aseguradora]['logo']} alt="aseguradora"/>
            <div className="documentacionCard__item__container">
                <p className="documentacionCard__title--highlighted">{realProducto}</p>

                <p className="documentacionCard__text"><span>Valor:</span> ${valor} </p>
                {numeroReferencia &&
                    <p className="documentacionCard__text"><span>Referencia:</span> {numeroReferencia} </p>}
                <p className={`documentacionCard__text--highlighted ${statusStyle}`}><span>Estado:</span> {statusText}
                </p>
                {estado_pago &&
                    <p className={`documentacionCard__text ${payStatusStyle}`}><span>Pago:</span> {payStatusText} </p>}
            </div>
            <div className="documentacionCard__item__container">
                <p className="documentacionCard__title">Cliente</p>
                <div className="documentacionCard__columnValues">
                    <p className="documentacionCard__text"><span>{cliente.nombre} {cliente.apellido}</span></p>
                    <p className="documentacionCard__text"><span>Doc:</span> {cliente.cuit} </p>
                </div>
            </div>
            <div className="documentacionCard__item__container">
                <p className="documentacionCard__title">Fecha</p>
                <div className="documentacionCard__columnValues">
                    <p className="documentacionCard__text"><span>{fecha}</span></p>
                </div>
            </div>
            <div className="documentacionCard__item__container">
                <p className="documentacionCard__title">Detalle</p>
                <div className="documentacionCard__columnValues">
                    {detalle.map(detail => (
                        <p className="documentacionCard__text documentacionCard__text--ellipsis" title={detail.value}>
                            <span>{detail.title}:</span> {detail.value}</p>
                    ))}
                </div>
            </div>
            <div className="documentacionCard__item__container--last">
                {isDownloading === index
                    ? <Loading/>
                    :
                    <Menu shadow="md" trigger="hover" openDelay={100} closeDelay={100} position="bottom-end" withArrow>
                        <Menu.Target>
                            <DownloadDocIcon
                                className={`documentacionCard__icon ${estado === "Cancel" ? "documentacionCard__icon--disabled" : ""}`}/>
                        </Menu.Target>

                        {estado !== "Cancel" && <Menu.Dropdown>
                            <Menu.Label>Descargar documento</Menu.Label>
                            <Menu.Divider/>
                            {realDocumentation.map((doc, idx) => (
                                <Menu.Item key={idx}
                                           onClick={() => {
                                               let realIdentificadores = identificadores;
                                               if (aseguradora === "TerraWind") {
                                                   data?.pasajeros.forEach((pasajero) => {
                                                       pasajero?.documentacion?.forEach(documento => {
                                                           if (documento === doc) {
                                                               realIdentificadores = pasajero['identificadores'];
                                                           }
                                                       })
                                                   })
                                               }
                                               downloadDocument(index, producto, aseguradora, doc, realIdentificadores)
                                           }}>
                                    {doc}
                                </Menu.Item>
                            ))}
                        </Menu.Dropdown>
                        }
                    </Menu>}
                {showRetencionButton &&
                    <Button enabled variant="filled" color="primary" size="sm" onClick={() => {
                        window.open('https://helpdeskgrupopas.atlassian.net/servicedesk/customer/portal/3/group/40/create/67', '_blank')
                    }}>Retencion
                    </Button>
                }
                {estado_pago && estado_pago !== "ENVIADO" &&
                    <Button enabled variant="filled" color="primary" size="sm"
                            onClick={() => copyToClipboard(link_pago)}>Link de pago
                    </Button>
                }
            </div>
        </div>

    );
}

export default DocumentacionCard;