import React, {useContext, useEffect} from 'react';
import './CoverageCard.css';
import {Button} from "../Button";
import {TextLink} from "../TextLink";
import {useFormikContext} from "formik";
import {useCurrentQuotationContext} from "../../context/CurrentQuotationContext/CurrentQuotationContext";
import CotizarContext from "../../context/CotizarContext";
import {OutStandardIcons} from "../../features/Cotizacion/OutStandardIcons";
import {currencyFormat} from "../../modules/utils/currencyFormat";
import UserContext from "../../context/UserContext";
import requestException from "../../features/Cotizacion/QuotationResponseTab/utils/requestException";

function CoverageCard(props) {
    const {rowData, tab, tabId, onEmitirClic, onCompararClic, handleOpenCoverageOverviewModal, coverageName} = props;
    const {id, premium, parametrosAdicionales} = rowData;
    const {values} = useFormikContext();
    const {cartCoverages, updateCartCoverages} = useCurrentQuotationContext();
    const {companiesDict} = useContext(CotizarContext);
    const {userCompanies, userName, userLastName} = useContext(UserContext);
    const cartCoveragesIds = cartCoverages.map(cov => cov.id);
    const selectedDiscountForCompany = values['descuentos'] ? values['descuentos'][companiesDict[tabId]['name']] : 0
    let descuento = values['descuentos'] ? values['descuentos'][tab] : 0;
    if ((rowData.compania === "RUS" || tab === "RUS") && descuento === 15) descuento = 14.7;
    const companyDiscountType = companiesDict[tab].descuento;
    const coveragePremium = companyDiscountType === "prima" || parametrosAdicionales?.outStandardSancor === "true" ? parseInt(premium) :
        Math.ceil(premium * (1 - descuento / 100));
    //Changes here must be replicated into same object of CoverageTableRow
    const dataObjectRequired = {
        company: tabId,
        companyName: companiesDict[tabId]['name'],
        ...rowData,
        discount: selectedDiscountForCompany,
        premium: premium,
        actualPremium: coveragePremium,
    };
    const isPromotional = !userCompanies.includes(tab)

    useEffect(() => {
        updateCartCoverages(rowData.id, coveragePremium);
    }, [values.descuentos]);

    const handleOutStandard = (type) => {
        requestException(type, values?.vehiculo, {...rowData, coverageName, coveragePremium, discount: values.descuentos.Zurich}, {userName, userLastName})
    };

    return (
        <>
            <div className="coverageCard__container">
                <div className="coverageCard__icon__container">
                    {parametrosAdicionales &&
                        <OutStandardIcons parametrosAdicionales={parametrosAdicionales} companyName={rowData.compania} onOutStandard={handleOutStandard}/>
                    }
                </div>

                <p className="coverageCard__id">{id}</p>
                <div className="coverageCard__name__container">
                    <p className="coverageCard__name">{coverageName}</p>
                </div>

                <TextLink size={"sm"}
                          action={() => handleOpenCoverageOverviewModal(dataObjectRequired)}>
                    Ver detalles
                </TextLink>

                <p className="coverageCard__premium">
                    ${currencyFormat(coveragePremium)}
                </p>

                <div className="coverageCard__buttons__container">
                    {!isPromotional &&
                        <Button
                            type="button"
                            variant="filled"
                            color="primary"
                            marginX="xs"
                            enabled
                            onClick={() => onEmitirClic(tabId, rowData)}
                        >
                            Emitir
                        </Button>
                    }
                    {cartCoveragesIds.includes(id)
                        ? <Button
                            type="button"
                            variant="filled"
                            color="red"
                            marginX="xs"
                            enabled
                            onClick={() => onCompararClic(dataObjectRequired)}
                        >
                            Quitar
                        </Button>
                        : <Button
                            type="button"
                            variant="filled"
                            color="secondary"
                            marginX="xs"
                            enabled
                            onClick={() => onCompararClic(dataObjectRequired)}
                        >
                            Comparar
                        </Button>
                    }

                </div>
            </div>

        </>
    );
}

export default CoverageCard;