import { useEffect, useRef } from 'react';

function useClickOutside(ref, callback) {
    const callbackRef = useRef(callback);

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref && ref.current && !ref.current.contains(event.target)) {
                callbackRef.current(event);
            }
        }

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [ref]);
}

export default useClickOutside;