import React from 'react';
import MisAseguradorasCompanies from '../../features/MisAseguradoras/MisAseguradorasCompanies/MisAseguradorasCompanies';
import { MisAseguradorasResumen } from '../../features/MisAseguradoras/MisAseguradorasResumen';
import './MisAseguradoras.css';

const MisAseguradoras = () => {

    return (
        <div className="MisAseguradoras__wrapper">
            <div className="MisAseguradoras__container">
                <MisAseguradorasResumen />
                <MisAseguradorasCompanies />
            </div>
        </div>
    );
}

export default MisAseguradoras;