import React, {useState} from 'react';
import {Document, Page} from 'react-pdf/dist/umd/entry.webpack';
import ReactPlayer from 'react-player/youtube'
import {ReactComponent as ArrowIcon} from "../../../../assets/icons/ic_arrow-down.svg";
import {ReactComponent as DownloadIcon} from "../../../../assets/icons/ic_download.svg";
import "./PDFViewer.css"
import axios from "axios";
import FileDownload from "js-file-download";


export default function PDFViewer(props) {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const {src, urlVideo, title, setAprove} = props;

    const handleDownload = (url, filename) => {
        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {
                FileDownload(res.data, filename)
            })
    }

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
    }

    const handleNext = () => {
        if (urlVideo && urlVideo !== "") {
            if (pageNumber < numPages + 1) {
                setPageNumber((prevPageNumber) => prevPageNumber + 1);
                if (pageNumber + 1 === numPages + 1) {
                    setAprove(true);
                }
            }
        } else {
            if (pageNumber < numPages) {
                setPageNumber((prevPageNumber) => prevPageNumber + 1);
                if (pageNumber + 1 === numPages) {
                    setAprove(true);
                }
            }
        }
    };

    const handleBack = () => {
        if (pageNumber > 1) {
            setPageNumber((prevPageNumber) => prevPageNumber - 1);
        }
    };

    return (
        <div className="PDFViewer__container">
            <div className="PDFViewer__mainScreen">
                <ArrowIcon onClick={() => handleBack()}
                           className={pageNumber === 1 ? "PDFViewer__leftArrow PDFViewer__arrow--disabled" : "PDFViewer__leftArrow"}/>
                <Document
                    file={src}
                    onLoadSuccess={onDocumentLoadSuccess}
                    renderMode={"svg"}
                >
                    {pageNumber === numPages + 1 ?
                        <div className="PDFViewer__videoContainer">
                            <ReactPlayer controls={true}
                                         url={urlVideo}
                                         className="PDFViewer__video"
                            />
                        </div>
                        :
                        <Page size="A4"
                              pageNumber={pageNumber}
                              renderAnnotationLayer={false}
                              renderTextLayer={false}
                              renderMode="canvas"/>
                    }

                </Document>
                {urlVideo && urlVideo !== "" ? <ArrowIcon onClick={() => handleNext()}
                                                          className={pageNumber < numPages + 1 ? "PDFViewer__rightArrow" : "PDFViewer__rightArrow PDFViewer__arrow--disabled"}/>
                    : <ArrowIcon onClick={() => handleNext()}
                                 className={pageNumber < numPages ? "PDFViewer__rightArrow" : "PDFViewer__rightArrow PDFViewer__arrow--disabled"}/>
                }
            </div>
            <div className="PDFViewer__footer">
                <p>{pageNumber}/{urlVideo && urlVideo !== "" ? numPages + 1 : numPages}
                    <DownloadIcon onClick={() => handleDownload(src, `${title}.pdf`)}
                                  className="PDFViewer__downloadIcon"/>
                </p>
            </div>
        </div>
    );
}