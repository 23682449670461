import React, {
    useEffect,
    useState,
    useMemo,
} from 'react';

import PropTypes from 'prop-types';
import axios from "axios";
import AsistenciaViajeroContext from "./AsistenciaViajeroContext";

const AsistenciaViajeroContextProvider = ({
                                              children,
                                          }) => {
    const [destinos, setDestinos] = useState([]);
    const [minDatesWarning, setMinDatesWarning] = useState({
        icon: false,
        modal: false,
        fadeOut: true,
    });
    const [asistenciaViajeroContextError, setAsistenciaViajeroContextError] = useState(null);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        let shouldUpdate = true;

        const init = async () => {
            let urlRequestCountries = process.env.REACT_APP_BACK_SERVER + '/getCountries'
            axios.get(urlRequestCountries)
                .then(res => {
                    if (shouldUpdate) {
                        setDestinos(res.data);
                    }
                })
                .catch(error => {
                    setAsistenciaViajeroContextError({
                        title: "Hubo un problema al cargar el listado de destinos disponibles, por favor vuelva a intentarlo más tarde.",
                        typeError: "Ha ocurrido un error en la comunicación con el servicio",
                        suggestedActions: ["Reintentá el proceso una vez más", "Si el problema persiste ponete en contacto con el área de soporte"],
                        detailedError: error.response?.data?.detail?.message || error.toString() || "Sin datos del error",
                    })
                })
                .finally(() => {
                    setIsReady(true);
                });
        }

        init();
        return () => {
            /* This cleanup function is used to prevent updating the state
            when the component is unmounted */
            shouldUpdate = false;
        };

    }, [])

    const context = useMemo(() => ({
        destinos,
        minDatesWarning,
        setMinDatesWarning,
        asistenciaViajeroContextError,
        setAsistenciaViajeroContextError,
        isReady
    }), [
        destinos,
        minDatesWarning,
        setMinDatesWarning,
        asistenciaViajeroContextError,
        setAsistenciaViajeroContextError,
        isReady
    ]);
    return (
        <AsistenciaViajeroContext.Provider value={context}>
            {children}
        </AsistenciaViajeroContext.Provider>
    );
}
AsistenciaViajeroContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AsistenciaViajeroContextProvider;