import React from 'react';
import './OvalTab.css';

function OvalTab(props) {
    const {key, active, children, onClick, tiny, color="primary-alternative"} = props;
    return (
        <div key={key} className={tiny ? (active ? `tinyOvalTab tinyOvalTab--${color} tinyOvalTab--${color}Active` : `tinyOvalTab tinyOvalTab--${color}`) : (active ? `ovalTab ovalTab--${color} ovalTab--${color}Active` : `ovalTab ovalTab--${color}`)} onClick={onClick}>
            {children}
        </div>
    );
}

export default OvalTab;