import React, {Fragment, useState} from 'react';
import {
    Stepper,
    Step,
    StepLabel,
    Button,
    Typography,
    CircularProgress,
    Snackbar
} from '@material-ui/core';
import {Formik, Form} from 'formik';

import CoberturasForm from './Forms/CoberturasForm';
import PolizaForm from './Forms/PolizaForm';
import ClienteForm from './Forms/ClienteForm';

import validationSchema from './FormModel/validationSchema';
import formModel from './FormModel/formModel';
import formInitialValues from './FormModel/formInitialValues';

import useStyles from './styles';
import axios from 'axios';

import {useAuth0} from "@auth0/auth0-react";
import EmisionSuccess from "./EmisionSuccess";
import Loading from "../../../components/Loading";
import Alert from '@material-ui/lab/Alert';
import DetailsForm from "./Forms/DetailsForm";

const {formId, formField} = formModel;


function _renderStepContent(step, additionalStep, errors) {
    if (additionalStep) {
        switch (step) {
            case 0:
                return <DetailsForm formField={formField} errors={errors}/>;
            case 1:
                return <PolizaForm formField={formField}/>;
            case 2:
                return <ClienteForm formField={formField} errors={errors}/>;
            case 3:
                return <CoberturasForm formField={formField}/>;
            default:
                return <div>Not Found</div>;
        }
    } else {
        switch (step) {
            case 0:
                return <PolizaForm formField={formField}/>;
            case 1:
                return <ClienteForm formField={formField} errors={errors}/>;
            case 2:
                return <CoberturasForm formField={formField}/>;
            default:
                return <div>Not Found</div>;
        }
    }
}

export default function CheckoutPage(props) {
    const classes = useStyles();
    const {continueCotizacion, emisionValues, idCotizacion, additionalStep} = props;
    const steps = additionalStep ? ['Detalle', 'Información', 'Cliente', 'Coberturas'] : ['Información', 'Cliente', 'Coberturas'];
    const [activeStep, setActiveStep] = useState(0);
    const currentValidationSchema = validationSchema[additionalStep ? activeStep : activeStep + 1];
    const isLastStep = activeStep === steps.length - 1;
    const [emisionResponse, setEmisionResponse] = useState(null);
    const [finalValues, setFinalValues] = useState(null);
    const {getIdTokenClaims} = useAuth0();

    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);

    const handleOpenAlert = (message) => {
        setOpenAlert(true);
        setAlertMessage(message);
    };

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
        setAlertMessage(null);
    };

    async function _submitForm(values, actions) {
        const claims = await getIdTokenClaims();
        const id_token = claims.__raw;

        let url = process.env.REACT_APP_BACK_SERVER + '/emitir/combinado';

        values.premium = emisionValues.premium;

        values.clientes.map(cliente => {
            if (cliente.personaJuridica === '1')
                cliente.dni = cliente.cuit
            cliente.dni = cliente.dni.toString()
        })

        let equipoElectronicoValues = []

        values.detalleBicicletas.map(item => {
            if (item.sumaAseguradaBicicletas !== '')
                equipoElectronicoValues.push({
                    descripcion: item.descripcionBicicletas,
                    sumaAsegurada: parseInt(item.sumaAseguradaBicicletas),
                    codigo: item.codigoBicicletas,
                    codigoGrupo: item.codigoGrupoBicicletas
                })
        })

        values.detalleEquipoElectronico.map(item => {
            if (item.sumaAseguradaElectronico !== '')
                equipoElectronicoValues.push({
                    descripcion: item.descripcionElectronico,
                    sumaAsegurada: parseInt(item.sumaAseguradaElectronico),
                    codigo: item.codigoElectronico,
                    codigoGrupo: item.codigoGrupoElectronico
                })
        })

        if (equipoElectronicoValues.length !== 0)
            values.equipoElectronico = equipoElectronicoValues

        setFinalValues(values);


        axios.post(url, {...values, idCotizacion},{
            headers:{
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${id_token}`,
            }}).then(res => {
                // console.log(res.data)
                setEmisionResponse(res.data)
        }).catch( err => {
            console.log(err);
            alert('No se pudo completar la operación, vuelva a cargar la página')
        });

        actions.setSubmitting(false);
        setActiveStep(activeStep + 1);
    }

    function _handleSubmit(values, actions) {
        if (isLastStep) {
            _submitForm(values, actions);
        } else {
            setActiveStep(activeStep + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    }

    function _handleBack() {
        setActiveStep(activeStep - 1);
    }

    return (
        <React.Fragment>
            <Typography style={{
                fontFamily: "Ubuntu",
                fontSize: 36,
                fontWeight: 400,
                fontStyle: "normal",
                letterSpacing: -0.5,
                color: '#35303f',
                textAlign:'center'
            }}>
                Emisión
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
                {steps.map(label => (
                    <Step key={label}
                          classes={{
                              root: classes.step,
                              completed: classes.completed,
                              active: classes.active
                          }}
                    >
                        <StepLabel
                            StepIconProps={{
                                classes: {
                                    root: classes.step,
                                    completed: classes.completed,
                                    active: classes.active,
                                }
                            }}
                        >
                            {label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Fragment>
                {activeStep === steps.length ? (
                    <Fragment>
                        {(emisionResponse !== null && finalValues !== null)
                            ? <EmisionSuccess finalValues={finalValues} emisionResponse={emisionResponse} setEmisionResponse={setEmisionResponse} setActiveStep={setActiveStep}/>
                            : (
                                <Fragment>
                                    <Typography variant="h5" gutterBottom>
                                        Procesando el pedido. Por favor espere...
                                    </Typography>
                                    <Loading/>
                                    <Typography variant="body1" gutterBottom align="center">
                                        Esto puede demorar hasta 60 segundos.
                                    </Typography>
                                </Fragment>
                            )
                        }
                    </Fragment>
                ) : (
                    <Formik
                        initialValues={continueCotizacion ? emisionValues : formInitialValues}
                        validationSchema={currentValidationSchema}
                        validateOnBlur={false}
                        onSubmit={_handleSubmit}
                    >
                        {({isSubmitting, errors}) => (
                            <Form id={formId}>
                                {_renderStepContent(activeStep, additionalStep, errors)}
                                <div className={classes.buttons}>
                                    {activeStep !== 0 && (
                                        <Button onClick={_handleBack} className={classes.button}>
                                            Atras
                                        </Button>
                                    )}
                                    <div className={classes.wrapper}>
                                        <Button
                                            disabled={isSubmitting}
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={classes.buttonContained}
                                        >
                                            {isLastStep ? 'Emitir' : 'Siguiente'}
                                        </Button>
                                        {isSubmitting && (
                                            <CircularProgress
                                                size={24}
                                                className={classes.buttonProgress}
                                            />
                                        )}
                                    </div>
                                </div>
                                {isSubmitting && errors?.clientes !== undefined && (
                                    <div>
                                        {handleOpenAlert("Algunos de los campos ingresados no son válidos, revise nuevamente los clientes ingresados.")}
                                    </div>
                                )}
                            </Form>
                        )}
                    </Formik>
                )}
            </Fragment>
            <Snackbar open={openAlert && alertMessage} autoHideDuration={5000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity="warning" elevation={6} variant="filled" classes={{filledWarning: classes.alert}}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}
