import React from 'react';
import './InformationModal.css';
import {PopUpCard} from "../../components/PopUpCard";
import {ReactComponent as CloseIcon} from "../../assets/icons/ic_close.svg";
import {ReactComponent as ExclamationIcon} from "../../assets/icons/ic_exclamation.svg";
import {CustomModal} from "../../components/CustomModal";

function InformationModal(props) {
    const {closeAction, title, body, severity = "warning"} = props;

    return (
        <CustomModal>
            <PopUpCard>
                <div className="informationModal__container">

                    <div className="informationModal__header">
                        <div className="informationModal__icon__container" onClick={closeAction}>
                            <CloseIcon className="informationModal__icon__close"/>
                        </div>
                        <div className={`informationModal__title informationModal__title--${severity}`}>
                            <ExclamationIcon className={`informationModal__icon--${severity}`}/>
                            {title}
                        </div>
                    </div>

                    <div className="informationModal__body">
                        {body}
                    </div>
                </div>
            </PopUpCard>
        </CustomModal>
    );
}

export default InformationModal;