import * as React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Menu from 'material-ui-popup-state/HoverMenu'
import MenuItem from '@material-ui/core/MenuItem'
import ChevronRight from '@material-ui/icons/ChevronRight'
import { useHistory } from "react-router-dom";
import {
    usePopupState,
    bindHover,
    bindMenu,
} from 'material-ui-popup-state/hooks'
import Typography from "../../components/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles(theme => ({
    menu: {

        "& .MuiPaper-root": {
            backgroundColor: "#333",
            borderRadius: '4px',
        }
    },
    textoLink: {
        fontFamily: "Ubuntu",
        fontSize: 18,
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        lineHeight: 0,
        color: "#ffffff",
        padding:'20px 0'
    },

    menuLink:{
        color:'#fff',
        fontFamily:'Ubuntu',
        fontSize:'16px',
        fontWeight:"bold",
        letterSpacing:0
    }

}));


const ParentPopupState = React.createContext(null)

const ProductosDropDown = () => {
    const classes = useStyles();
    const history = useHistory();
    const popupState = usePopupState({
        popupId: 'demoMenu',
        variant: 'popover',
        deferOpenClose: true,

    })
    return (
        <div >
            <Link {...bindHover(popupState)} underline='none' >

                <Typography className={`${classes.textoLink} tour-productos`}>
                    Productos
                </Typography>


            </Link>
            <ParentPopupState.Provider value={popupState}>
                <Menu

                    {...bindMenu(popupState)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top' , horizontal: 'left' }}
                    getContentAnchorEl={null}
                    className={classes.menu}
                >

                    <Submenu popupId="moreChoicesMenu" title="Automotor" >
                        <MenuItem onClick={() => {history.push('/u/cotizar/vehiculo')}} className={classes.menuLink}>
                            Cotizar
                        </MenuItem>
                        <MenuItem onClick={() => {history.push({
                            pathname:'/u/cotizaciones/'+'vehiculo',
                            state:{
                                producto: 'vehiculo'
                            }

                        })}} className={classes.menuLink}>
                            Emitir
                        </MenuItem>
                        <MenuItem onClick={() => {history.push({
                            pathname:'/u/documentacion/'+'vehiculo',
                            state:{
                                producto: 'vehiculo'
                            }

                        })}} className={classes.menuLink}>
                            Consultar
                        </MenuItem>
                    </Submenu>
                    <Submenu popupId="moreChoicesMenu" title="Hogar" >
                        <MenuItem onClick={() => {history.push('/u/cotizar/combinado')}} className={classes.menuLink}>
                            Cotizar
                        </MenuItem>
                        <MenuItem onClick={() => {history.push({
                            pathname:'/u/cotizaciones/'+'hogar',
                            state:{
                                producto: 'hogar'
                            }

                        })}} className={classes.menuLink}>
                            Emitir
                        </MenuItem>
                        <MenuItem onClick={()=> {history.push({
                            pathname:'/u/documentacion/'+'hogar',
                            state:{
                                producto: 'hogar'
                            }
                        })}} className={classes.menuLink}>
                            Consultar
                        </MenuItem>
                    </Submenu>
                    <Submenu popupId="moreChoicesMenu" title="Microseguros" >
                        <MenuItem onClick={() => {history.push('/u/cotizar/microseguro')}} className={classes.menuLink}>
                            Cotizar
                        </MenuItem>
                        <MenuItem onClick={() => {history.push({
                            pathname:'/u/cotizaciones/'+'microseguro',
                            state:{
                                producto: 'microseguro'
                            }

                        })}} className={classes.menuLink}>
                            Emitir
                        </MenuItem>
                        <MenuItem onClick={()=> {history.push({
                            pathname:'/u/documentacion/'+'microseguro',
                            state:{
                                producto: 'microseguro'
                            }
                        })}} className={classes.menuLink}>
                            Consultar
                        </MenuItem>
                    </Submenu>
                    {/*<MenuItem onClick={popupState.close} className={classes.menuLink} style={{lineHeight:3}}>*/}
                    {/*    Proximos Productos*/}
                    {/*</MenuItem>*/}

                </Menu>
            </ParentPopupState.Provider>
        </div>
    )
}

export default ProductosDropDown

const submenuStyles = (theme) => ({
    menu: {
        marginTop: theme.spacing(-1),
        backgroundColor: "#413d3d",
    },
    title: {
        flexGrow: 1,
        color:'#fff',
        fontFamily:'Ubuntu',
        fontSize:'16px',
        fontWeight:"bold",
        letterSpacing:0,
        lineHeight:3
    },
    moreArrow: {
        marginRight: theme.spacing(-1),
        color:'#fff'
    },
})

const Submenu = withStyles(submenuStyles)(
    React.forwardRef(({ classes, title, popupId, children, ...props }, ref) => {
        const parentPopupState = React.useContext(ParentPopupState)
        const popupState = usePopupState({
            popupId,
            variant: 'popover',
            parentPopupState,
            deferOpenClose: true,
        })
        return (
            <ParentPopupState.Provider value={popupState}>
                <MenuItem
                    {...bindHover(popupState)}
                    selected={popupState.isOpen}
                    ref={ref}
                >
                    <span className={classes.title}>{title}</span>
                    <ChevronRight className={classes.moreArrow} />
                </MenuItem>
                <Menu
                    {...bindMenu(popupState)}
                    classes={{ paper: classes.menu }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    getContentAnchorEl={null}
                    {...props}
                >
                    {children}
                </Menu>
            </ParentPopupState.Provider>
        )
    })
)
