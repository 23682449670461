function getAllowedProducts(codigos_aseguradoras, companies_dict) {
    const productosHabilitados = {};

    codigos_aseguradoras.forEach((codigoAseguradora) => {
        const idAseguradora = codigoAseguradora.id_aseguradora.toString();
        if (companies_dict.hasOwnProperty(idAseguradora)) {
            const products = companies_dict[idAseguradora].products;
            products.forEach((product) => {
                productosHabilitados[product.value] = true;
            });
        }
    });

    // Construir el objeto de productos
    const productos = {};
    Object.keys(productosHabilitados).forEach((productKey) => {
        productos[productKey] = {
            idCodigosAseguradoras: [],
            active: false
        };
    });

    return productos;
}

export {getAllowedProducts}