import React, {useContext} from 'react';
import {Button} from "../Button";
import {useFormikContext} from "formik";
import {useCurrentQuotationContext} from "../../context/CurrentQuotationContext/CurrentQuotationContext";
import CotizarContext from "../../context/CotizarContext";
import {renderCardRows} from "../GenericCoverageTableRow/getColumnsArray";

function GenericCoverageCard(props) {
    const {
        rowData,
        tabId,
        onEmitirClic,
        onCompararClic,
        handleOpenCoverageOverviewModal,
        columns,
        emitirInvisible
    } = props;
    const {values} = useFormikContext();
    const {cartCoverages} = useCurrentQuotationContext();
    const {companiesDict} = useContext(CotizarContext);
    const cartCoveragesIds = cartCoverages.map(cov => cov.id);
    const selectedDiscountForCompany = values['descuentos'] ? values['descuentos'][companiesDict[tabId]['name']] : 0

    //Changes here must be replicated into same object of CoverageTableRow
    const dataObjectRequired = {
        company: tabId,
        companyName: companiesDict[tabId]['name'],
        ...rowData,
        discount: selectedDiscountForCompany,
        premium: parseInt(rowData.premium),
        actualPremium: parseInt(rowData.premium),
    };

    return (
        <div className="coverageCard__container">

            {renderCardRows(columns, rowData, handleOpenCoverageOverviewModal, dataObjectRequired)}

            <div className="coverageCard__buttons__container">
                {!emitirInvisible &&
                    <Button
                        type="button"
                        variant="filled"
                        color="primary"
                        marginX="xs"
                        enabled
                        onClick={() => onEmitirClic(tabId, rowData)}
                    >
                        Emitir
                    </Button>
                }
                {cartCoveragesIds.includes(rowData.id)
                    ? <Button
                        type="button"
                        variant="filled"
                        color="red"
                        marginX="xs"
                        enabled
                        onClick={() => onCompararClic(dataObjectRequired)}
                    >
                        Quitar
                    </Button>
                    :
                    <Button
                        type="button"
                        variant="filled"
                        color="secondary"
                        marginX="xs"
                        enabled
                        onClick={() => onCompararClic(dataObjectRequired)}
                    >
                        Comparar
                    </Button>
                }
            </div>
        </div>
    );
}

export default GenericCoverageCard;