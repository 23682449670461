import formsModel from './formsModel'
import * as Yup from 'yup';
import {CardNumberValidator} from '../utils/cardNumberValidator';
const moment = require('moment');

const {
    formField: {
        tipoVivienda,
        tamanioVivienda,
        descuentoEspecial,
        coberturas: {
            sumaAsegurada,
            selectedCobertura,
            numeroCobertura
        },
        ajusteAutomatico,
        vigenciaDesde,
        formaDePago,
        vigenciaHasta,
        cuotas,
        frecuenciaPago,
        comentariosSolicitud,
        infoDePago: {
            numeroTarjeta,
            marcaTarjeta,
            numeroCbu
        },
        detalleEquipoElectronico: {
            codigoElectronico,
            sumaAseguradaElectronico,
            codigoGrupoElectronico,
            descripcionElectronico
        },
        detalleBicicletas: {
            codigoBicicletas,
            sumaAseguradaBicicletas,
            codigoGrupoBicicletas,
            descripcionBicicletas
        },
        cliente: {
            email,
            telefono,
            nombre,
            codigosLocalidad,
            codigoArea,
            fechaNacimiento,
            apellido,
            situacionIva,
            domicilio,
            cuit,
            dni,
            sexo,
            estadoCivil,
            rol,
            actividadPrincipal,
            categoriaMonotributo,
            lugarNacimiento,
            personaJuridica
        }
    }
} = formsModel;

let tipoTarjeta;
let equipoElectronicoSelected = false;
let bicicletasSelected = false;


const clienteValidation = {
    [rol.name]: Yup.string().required(`${rol.requiredErrorMsg}`),
    [dni.name]: Yup.number().when([personaJuridica.name], {
        is: '0',
        then: Yup.number()
            .required(`${dni.requiredErrorMsg}`)
            .min(1000000, "El documento ingresado no es válido")
            .max(99999999, "El documento ingresado no es válido")
            .integer()
            .typeError("Introduzca únicamente números"),
    }),
    [cuit.name]: Yup.string()
        .required(`${cuit.requiredErrorMsg}`)
        .matches(/^(20|23|27|30|33)([0-9]{9}|-[0-9]{8}-[0-9]{1})$/, "El CUIT ingresado no es válido"),
    [nombre.name]: Yup.string().when([personaJuridica.name], {
        is: '0',
        then: Yup.string()
            .required(`${nombre.requiredErrorMsg}`)
            .matches(/^[aA-zZ\s]+$/, "Ingrese un nombre válido")
            .max(40, "El nombre ingresado es muy largo"),
    }),
    [apellido.name]: Yup.string()
        .required(`${apellido.requiredErrorMsg}`)
        .matches(/^[aA-zZ\s]+$/, "Ingrese un apellido válido")
        .max(40, "El valor ingresado es muy largo"),
    [fechaNacimiento.name]: Yup.date().when([personaJuridica.name], {
        is: '0',
        then: Yup.date()
            .max(new Date(Date.now() - 567648000000), "El cliente debe tener al menos 18 años para contratar")
            .required(`${fechaNacimiento.requiredErrorMsg}`),
    }),
    [estadoCivil.name]: Yup.string().when([personaJuridica.name], {
        is: '0',
        then: Yup.string().required(`${estadoCivil.requiredErrorMsg}`),
    }),
    [sexo.name]: Yup.string().when([personaJuridica.name], {
        is: '0',
        then: Yup.string().required(`${sexo.requiredErrorMsg}`),
    }),
    [domicilio.name]: Yup.string()
        .required(`${domicilio.requiredErrorMsg}`)
        .matches(/^[A-Za-z0-9 .-]+$/, "Esta utilizando carácteres no válidos"),
    [codigoArea.name]: Yup.number()
        .required(`${codigoArea.requiredErrorMsg}`)
        .integer()
        .min(10, 'Introduzca un código de área valido')
        .max(9999, 'Introduzca un código de área valido')
        .typeError("Introduzca únicamente números")
        .test(
            'no-leading-zero',
            'Por favor ingrese el código de área sin 0',
            (value, context) => {
                return context.originalValue && !context.originalValue.startsWith('0');
            }
        ),
    [telefono.name]: Yup.number()
        .required(`${telefono.requiredErrorMsg}`)
        .integer()
        .min(100000, 'Introduzca un número de teléfono valido')
        .max(99999999, 'Introduzca un número de teléfono valido')
        .typeError("Introduzca únicamente números")
        .test(
            'no-leading-zero',
            'Por favor introduzca su número sin el 15',
            (value, context) => {
                return context.originalValue && !context.originalValue.startsWith('15');
            }
        ),
    [email.name]: Yup.string()
        .required(`${email.requiredErrorMsg}`)
        .email("Ingrese un email válido"),
    [situacionIva.name]: Yup.string().required(`${situacionIva.requiredErrorMsg}`),
    [categoriaMonotributo.name]: Yup.string().required(`${categoriaMonotributo.requiredErrorMsg}`),
    [actividadPrincipal.name]: Yup.string().when([personaJuridica.name], {
        is: '0',
        then: Yup.string().required(`${actividadPrincipal.requiredErrorMsg}`),
    }),
    [lugarNacimiento.name]: Yup.string().when([personaJuridica.name], {
        is: '0',
        then: Yup.string()
            .required(`${lugarNacimiento.requiredErrorMsg}`)
            .matches(/^([A-Za-z0-9 -]+)$/, "Esta utilizando carácteres no válidos"),
    }),
}

const infoDePagoValidation = {
    infoDePago: Yup.object().when([formaDePago.name], {
        is: '4',    // Débito por CBU
        then: Yup.object().shape({
            [numeroCbu.name]: Yup.string()
                .required(`${numeroCbu.requiredErrorMsg}`)
                .matches(/^[0-9]+$/, "Ingrese un número válido")
                .min(22, 'El número de CBU debe tener 22 dígitos')
                .max(22, 'El número de CBU debe tener 22 dígitos'),
        })
    }).when([formaDePago.name], {
        is: '2',    // Tarjeta de crédito
        then: Yup.object().shape({
            [marcaTarjeta.name]: Yup.string().test('cardType', 'Seleccione un tipo de tarjeta', type => {
                tipoTarjeta = type;
                if (tipoTarjeta !== null) return true;
            }).required(`${marcaTarjeta.requiredErrorMsg}`),
            [numeroTarjeta.name]: Yup
                .string()
                .test('cardNumber',
                    'El número de tarjeta ingresado es inválido', //validation message
                    value => {
                        if (tipoTarjeta === "9") {
                            if (value !== undefined && value.length === 15)
                                return CardNumberValidator(tipoTarjeta, value)
                        } else {
                            if (value !== undefined && value.length === 16)
                                return CardNumberValidator(tipoTarjeta, value)
                        }
                    })
                .required()
        })
    })
}

const detallesValidation = {
    //DummyValue for trigger callback method
    dummyValue: Yup.string(),
    detalleBicicletas: Yup.array().of(
        Yup.object().shape({
            [sumaAseguradaBicicletas.name]: Yup.number()
                .integer()
                .typeError("Introduzca únicamente números")
                .when("dummyValue", {
                    is: () => {
                        return bicicletasSelected === true
                    },
                    then: Yup.number()
                        .required(`${sumaAseguradaBicicletas.requiredErrorMsg}`)
                        .typeError("Introduzca únicamente números")
                        .test('notZeroInsuredSum', "La suma debe ser superior a cero", (value) => value > 0)
                }),
            [descripcionBicicletas.name]: Yup.string()
                .matches(/^[A-Za-z0-9 ,-]+$/, "Ingrese una descripción válida")
                .when("dummyValue", {
                    is: () => {
                        return bicicletasSelected === true
                    },
                    then: Yup.string().required(`${descripcionBicicletas.requiredErrorMsg}`)
                }),
        })).test('insuredSumsAgreggationBicicletas', "Las sumas declaradas deben coincidir con las aseguradas", (sumas, context) => {
            const coberturas = context.parent.coberturas;
            let sumaAsegurada = 0;
            bicicletasSelected = false
            coberturas.map((cob) => {
                if (cob.numeroCobertura === '48') {
                    // DISTINTO PARA MICRO (Consulta si fue seleccionada) Y HOGAR
                    if (cob.selectedCobertura !== undefined) {
                        if (cob.selectedCobertura) {
                            bicicletasSelected = true
                            sumaAsegurada = cob.sumaAsegurada
                        }
                    } else {
                        bicicletasSelected = true
                        sumaAsegurada = cob.sumaAsegurada
                    }
                } else return true
            })

            let totalInsuredValue = 0;
            sumas.map((item) => {
                if (item.sumaAseguradaBicicletas !== undefined)
                    totalInsuredValue += parseInt(item.sumaAseguradaBicicletas.toString().replace(/\./g, ""));
            })

            return (sumaAsegurada === totalInsuredValue)
        }
    ),
    detalleEquipoElectronico: Yup.array().of(
        Yup.object().shape({
            [sumaAseguradaElectronico.name]: Yup.number()
                .integer()
                .typeError("Introduzca únicamente números")
                .when("dummyValue", {
                    is: () => {
                        return equipoElectronicoSelected === true
                    },
                    then: Yup.number()
                        .required(`${sumaAseguradaElectronico.requiredErrorMsg}`)
                        .typeError("Introduzca únicamente números")
                        .test('notZeroInsuredSum', "La suma debe ser superior a cero", (value) => value > 0)
                }),
            [descripcionElectronico.name]: Yup.string()
                .matches(/^[A-Za-z0-9 ,-]+$/, "Ingrese una descripción válida")
                .when("dummyValue", {
                    is: () => {
                        return equipoElectronicoSelected === true
                    },
                    then: Yup.string().required(`${descripcionElectronico.requiredErrorMsg}`)
                }),
        })).test('insuredSumsAgreggationElectrónicos', "Las sumas declaradas deben coincidir con las aseguradas", (sumas, context) => {
            const coberturas = context.parent.coberturas;
            let sumaAsegurada = 0;
            equipoElectronicoSelected = false
            coberturas.map((cob) => {
                if (cob.numeroCobertura === '26') {
                    // DISTINTO PARA MICRO (Consulta si fue seleccionada) Y HOGAR
                    if (cob.selectedCobertura !== undefined) {
                        if (cob.selectedCobertura) {
                            equipoElectronicoSelected = true
                            sumaAsegurada = cob.sumaAsegurada
                        }
                    } else {
                        equipoElectronicoSelected = true
                        sumaAsegurada = cob.sumaAsegurada
                    }
                } else return true
            })

            let totalInsuredValue = 0;
            sumas.map((item) => {
                if (item.sumaAseguradaElectronico !== undefined)
                    totalInsuredValue += parseInt(item.sumaAseguradaElectronico.toString().replace(/\./g, ""));
            })

            return (sumaAsegurada === totalInsuredValue)
        }
    ),
}


const cotizarMicroseguroValidation = [
    Yup.object().shape({
        [codigosLocalidad.name]: Yup.string().required(`${codigosLocalidad.requiredErrorMsg}`),
        coberturas: Yup.array().of(
            Yup.object().shape(
                {
                    [sumaAsegurada.name]: Yup.number().required(),
                    [numeroCobertura.name]: Yup.string().required(),
                    [selectedCobertura.name]: Yup.boolean().required(),
                }
            )
        ).test('product-selection', 'Debe seleccionar al menos un producto', coberturas => {
            let coberturasArray = []
            for (let cob of coberturas) {
                coberturasArray.push(cob.selectedCobertura)
            }
            return coberturasArray.some(cobertura => cobertura)
        })
    }),
];

const emitirMicrosegurosValidation = [
    Yup.object().shape({
        ...detallesValidation,
    }),
    Yup.object().shape({
        [vigenciaDesde.name]: Yup.date()
            .required(`${vigenciaDesde.requiredErrorMsg}`)
            .min(moment().format('YYYY-MM-DD'), "La fecha no puede ser anterior al dia de hoy")
            .max(moment().add(45, "days").format('YYYY-MM-DD'), "La fecha no puede superar los 45 dias"),

        [formaDePago.name]: Yup.string().required(`${formaDePago.requiredErrorMsg}`),
        ...infoDePagoValidation,
    }),
    Yup.object().shape({
        clientes: Yup.array().of(
            Yup.object().shape({
                ...clienteValidation,
                [codigosLocalidad.name]: Yup.string().required(`${codigosLocalidad.requiredErrorMsg}`),
            })
        )
    }),
    Yup.object().shape({}),
    Yup.object().shape({})
];

export {
    cotizarMicroseguroValidation,
    emitirMicrosegurosValidation
};

