import React, {useContext, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import logo from "../../../assets/logo-white.png";
import Link from "@material-ui/core/Link";
import Typography from "../../components/Typography";
import ProductosDropDown from "./ProductosDropDown";
import { WhatsappButton } from "../WhatsappButton";
import Notifications from "../Notifications";
import ProfileMenu from "../../components/menus/ProfileMenu";
import TemporaryDrawer from "./mobileNavBar";
import AuthContext from "../../../context/AuthContext";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "70px",
    width: "auto",
    backgroundImage: "linear-gradient(to right, #452d7b 26%, #973379 74%)",
    display: "flex",
    alignItems: "center",
    padding: "0 60px ",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },

  imagen: {
    width: "136px",
    height: "auto",
  },
  links: {
    fontFamily: "Ubuntu",
    fontSize: 18,
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#ffffff",
    margin: "0 20px",
    cursor: "pointer"
  },

  headerRoot: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: "center",
    // [theme.breakpoints.down('lg')]: {
    //     justifyContent: 'space-evenly',
    // },
  },
  menu: {
    marginTop: "54px",
  },
  dropdown: {
    margin: "0 20px",
  },

  //MOBILE

  rootMobile: {
    height: "70px",
    width: "auto",
    backgroundImage: "linear-gradient(to right, #452d7b 26%, #973379 74%)",
    display: "flex",
    alignItems: "center",
    padding: "0 60px 0 60px",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  gridMobile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textoDrawer: {
    fontFamily: "Ubuntu",
    fontSize: 18,
    fontWeight: 500,
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#35303f",
    [theme.breakpoints.down("md")]: {
      paddingRight: "50px",
    },
    //margin: '0 20px'
  },
}));
function NavBar(props) {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const {isMaster} = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Grid
          container
          direction={"row"}
          xs={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid
            item
            xs={3}
            lg={2}
            xl={3}
            justify={"flex-start"}
            className="tour-academia"
          >
            <img src={logo} className={classes.imagen} />
          </Grid>
          <Grid
            container
            direction={"row"}
            xs={6}
            lg={6}
            style={{ display: "flex", alignItems: "center" }}
            justify={"center"}
          >
            <Grid item>
                <Typography onClick={() => history.push({pathname: '/u/dashboard'})} className={classes.links}>Inicio</Typography>
            </Grid>

            <Grid item>
                <Typography onClick={() => history.push({pathname: '/u/academia'})} className={`${classes.links} tour-academia2`}>
                  Academia
                </Typography>
            </Grid>

            <Grid item className={classes.dropdown}>
              <ProductosDropDown />
            </Grid>
            <Grid item>
                <Typography onClick={() => history.push({pathname: '/u/billetera'})} className={`${classes.links} tour-billetera`}>
                  Billetera
                </Typography>
            </Grid>
            {isMaster &&
                <Typography onClick={() => history.push({pathname: '/u/m/dashboard'})} className={classes.links}> MASTER</Typography>
            }
          </Grid>
          <Grid
            container
            xs={3}
            lg={2}
            xl={3}
            direction={"row"}
            justify={"flex-end"}
          >
            <WhatsappButton />

            <Notifications />
            <ProfileMenu/>
          </Grid>
        </Grid>
      </div>

      {/* __  __  ____  ____ _____ _      ______
            |  \/  |/ __ \|  _ \_   _| |    |  ____|
            | \  / | |  | | |_) || | | |    | |__
            | |\/| | |  | |  _ < | | | |    |  __|
            | |  | | |__| | |_) || |_| |____| |____
            |_|  |_|\____/|____/_____|______|______|
         */}

      <div className={classes.rootMobile}>
        <Grid
          container
          direction={"row"}
          xs={12}
          className={classes.gridMobile}
        >
          <Grid item>
            <TemporaryDrawer />
          </Grid>

          <Grid item style={{ paddingRight: "8px" }}>
            <Notifications />
          </Grid>
        </Grid>
      </div>

      {/*<div className={classes.rootMobile}>*/}
      {/*    <Grid container direction={"row"} xs={12} className={classes.gridMobile}>*/}
      {/*        <Grid item style={{marginRight:'25px'}}>*/}
      {/*            <ProfileMenu/>*/}
      {/*        </Grid>*/}
      {/*        <Grid item>*/}
      {/*            <img src={logo} className={classes.imagen}/>*/}
      {/*        </Grid>*/}
      {/*        <Grid item style={{display:'flex', alignItems:'center'}}>*/}
      {/*            <Notifications/>*/}
      {/*            <MenuIcon*/}
      {/*                style={{width: '25px', height: 'auto', color:'#fff'}}*/}
      {/*                onClick={() => {*/}
      {/*                    setOpen(true)*/}
      {/*                }}/>*/}
      {/*        </Grid>*/}

      {/*    </Grid>*/}
      {/*    <SwipeableDrawer*/}
      {/*        containerStyle={{height: 'calc(100%-200px'}}*/}
      {/*        //classes={{paper: classes.paper1}}*/}
      {/*        anchor={"right"}*/}
      {/*        open={open}*/}
      {/*        onClose={() => {*/}
      {/*            setOpen(false)*/}

      {/*        }}*/}

      {/*     >*/}

      {/*        <div*/}
      {/*            style={{*/}
      {/*                width: '100%', display: 'flex', //alignItems: 'center',*/}
      {/*                justifyContent: 'center',*/}
      {/*                height: 'auto'*/}
      {/*            }}*/}
      {/*        >*/}

      {/*            <List className={classes.list}>*/}
      {/*                <Link href={'/dashboard'} underline='none'>*/}
      {/*                    <ListItem key={1} button divider className={classes.textoDrawer}> Inicio </ListItem>*/}
      {/*                </Link>*/}
      {/*                <Link href={'/cotizar'} underline='none'>*/}
      {/*                    <ListItem key={1} button divider className={classes.textoDrawer}> Cotizar </ListItem>*/}
      {/*                </Link>*/}
      {/*                <Link href={'/cotizaciones'} underline='none'>*/}
      {/*                    <ListItem key={1} button divider className={classes.textoDrawer}> Emitir </ListItem>*/}
      {/*                </Link>*/}
      {/*                <Link href={'/billetera'} underline='none'>*/}
      {/*                    <ListItem key={1} button divider className={classes.textoDrawer}> Billetera </ListItem>*/}
      {/*                </Link>*/}

      {/*                {isMaster === true ?*/}

      {/*                    <Link href='/master' underline='none'>*/}
      {/*                        <ListItem key={1} button divider className={classes.textoDrawer}> MASTER </ListItem></Link> : <a/>*/}

      {/*                }*/}

      {/*            </List>*/}

      {/*            <div style={{*/}
      {/*                display:'flex',*/}
      {/*                justifyContent:'center',*/}
      {/*                alignItems:'center',*/}
      {/*                position: 'fixed',*/}

      {/*                bottom: '10px',*/}
      {/*               // width: '100%',*/}
      {/*            }}>*/}

      {/*                <Grid container direction={"column"}*/}
      {/*                style={{display:'flex',*/}
      {/*                 justifyContent:'center'}}*/}
      {/*                >*/}
      {/*                    <Grid item>*/}
      {/*                        <WhatsappButtonText />*/}
      {/*                    </Grid>*/}

      {/*                </Grid>*/}

      {/*            </div>*/}
      {/*        </div>*/}

      {/*    </SwipeableDrawer>*/}
      {/*</div>*/}
    </React.Fragment>
  );
}

export default NavBar;
