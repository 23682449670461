import React, {useContext} from 'react';
import './ResumenModal.css';
import {PopUpCardModal} from "../../../../layouts/PopUpCardModal";
import {ReactComponent as CheckCircledIcon} from "../../../..//assets/icons/ic_check_circled.svg";
import {ReactComponent as CrossIcon} from "../../../..//assets/icons/ic_cross-filled.svg";
import {ResumenSection} from "./ResumenSection";
import MasterContext from "../../../../context/MasterContext";
import {IconBike, IconCar, IconHome, IconMotorbike} from "@tabler/icons";

const productIconsDict = {
    "vehiculo": <IconCar className="resumenModal__products__icon"/>,
    "motovehiculo": <IconMotorbike className="resumenModal__products__icon"/>,
    "hogar": <IconHome className="resumenModal__products__icon"/>,
    "microseguros": <IconBike className="resumenModal__products__icon"/>
}

function ResumenModal(props) {
    const {setOpen, values} = props;
    const {masterPolkistas} = useContext(MasterContext);
    const {
        row: {
            pagina_url: link,
            logo: logo,
            qr: QR,
            redes_sociales: socialMedia,
            habilitada: status,
            productos: products
        }
    } = values;
    const polkista = masterPolkistas[values.row.id_polkista];
    const {socialMediaDict} = useContext(MasterContext);
    const userSocialMedia = socialMedia.map((url) => {
        const foundOption = Object.keys(socialMediaDict).find(option => url.toLowerCase().includes(option.toLowerCase()));
        return foundOption ? foundOption : "otro";
    });

    return (
        <PopUpCardModal
            title={"Resumen del canal digital"}
            body={
                <div className="resumenModal__body">
                    <div className="resumenModal__imagesColumn">
                        <img src={logo} alt="logo" className="resumenModal__img"/>
                        <img src={QR} alt="logo" className="resumenModal__img"/>
                    </div>
                    <div className="resumenModal__infoColumn">
                        <ResumenSection title="Nombre" body={`${polkista['datos_polkista']['NombreCompleto']}`}/>
                        {/*<ResumenSection title="Celular" body={polkista['Telefono']}/>*/}
                        <ResumenSection title="Link" body={
                            <span className="RModal__textLink">
                            {link.includes('https://')
                                ? link.split('https://')[1]
                                : link}
                            </span>
                        }
                        />
                        <ResumenSection title="Productos Habilitados" body={
                            <div className="resumenModal__body__products">
                                {Object.keys(products).map((product) => {
                                    return (
                                        <div
                                            className={products[product].active
                                                ? "resumenModal__products"
                                                : "resumenModal__products--disabled"}
                                        >
                                            {productIconsDict[product]}
                                        </div>
                                    )
                                })}
                            </div>
                        }/>
                        <ResumenSection
                            title="Redes Sociales"
                            body={
                                userSocialMedia.map((domain, index) =>
                                    <a href={`//${socialMedia[index]}`} target="_blank" key={index}>
                                        {socialMediaDict[domain].icon}
                                    </a>
                                )
                            }
                        />
                        <ResumenSection title="Estado" body={
                            status
                                ? <CheckCircledIcon className="resumenModal__icon"/>
                                : <CrossIcon className="resumenModal__icon--close"/>
                        }/>
                    </div>

                </div>
            }
            closeAction={() => setOpen({modalName: "", values: null})}
        />
    );
}

export default ResumenModal;