import * as Yup from "yup";

const TitularModel = () => {
    const formField = {
        tipoPersona: {
            name: 'tipoPersona',
            label: 'Tipo de persona',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        situacionImpositiva: {
            name: 'situacionImpositiva',
            label: 'Situación impositiva',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        codigosLocalidad: {
            name: 'codigosLocalidad',
            label: 'Localidad',
            requiredErrorMsg: 'Debe especificar una localidad'
        },
        codigoPostal: {
            name: 'codigoPostal',
            label: "Código postal",
        },
        idProvincia: {
            name: 'idProvincia',
            label: 'Provincia',
        },
    }

    const initialValues = {
        [formField.tipoPersona.name]: '0',
        [formField.situacionImpositiva.name]: '4',
        [formField.codigosLocalidad.name]: '',
        [formField.codigoPostal.name]: '',
        [formField.idProvincia.name]: '',
    }

    const validationSchema = Yup.object().shape({
        [formField.tipoPersona.name]: Yup.string().required(`${formField.tipoPersona.requiredErrorMsg}`),
        [formField.situacionImpositiva.name]: Yup.string().required(`${formField.situacionImpositiva.requiredErrorMsg}`),
        [formField.codigosLocalidad.name]: Yup.string().required(`${formField.codigosLocalidad.requiredErrorMsg}`)
    })

    return {formField, initialValues, validationSchema}
}

export {TitularModel};
