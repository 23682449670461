import { Button } from "../../../../components/Button";
import { PopUpCardModal } from "../../../../layouts/PopUpCardModal";
import "../CodigosContainer/CodigosContainer.css";
import "./CodigosPopUp.css";

const columnTitle = {
    codigo_productor: "Productor",
    codigo_organizador: "Organizador",
    codigo_solicitante: "Solicitante",
    familia_id: "Familia",
    plan_id: "Plan",
    segmento_id: "Segmento",
    sociedad_id: "Sociedad",
}

const CodigosPopUp = (props) => {
    const { closeAction, codigos } = props;

    return (
        <PopUpCardModal
            withDivider
            title={
                <h1 className="CodigosContainer__popup--title">Códigos</h1>
            }
            body={
                <section className="CodigosPopUp__table">
                    <div className="CodigosPopUp__table--body">
                        {
                            <>
                                <p className="CodigosPopUp__table--row">
                                    {Object.entries(codigos[0]).map(([name, codigo]) => (
                                        <div className="CodigosPopUp__table--item">
                                            <p className="CodigosPopUp__table--title">{columnTitle[name]}</p>
                                            <div className="CodigosContainer__button--wrapper">
                                                {
                                                    codigo ?
                                                        <Button variant="filled" color="lavanda" size="md" enabled fullWidth fullHeight>
                                                            <span className="CodigosContainer__button--text">
                                                                {codigo || "-"}
                                                            </span>
                                                        </Button> :
                                                        <p>-</p>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </p>
                            </>

                        }
                    </div>
                </section>
            }
            closeAction={closeAction}
        />
    );
}

export default CodigosPopUp;
