import React from "react";
import {PopUpCardModal} from "../../../../layouts/PopUpCardModal";
import "./AgreementMoreInfo.css";


const AgreementMoreInfo = (props) => {
    const {closeAction} = props;


    return (
        <PopUpCardModal autoWidth title="Acuerdos" body={
            <div className="AMInfo__popUpContent">
                <section className="AMInfo__section1">
                    <strong>Medios de pago:</strong>
                    <ul>
                        <li>Solo se aceptan pólizas con pago por débito por CBU y
                            tarjeta de crédito.
                        </li>
                    </ul>
                </section>
                <section className="AMInfo__section2">
                    <strong>Pago del acuerdo:</strong>
                    <ul>
                        <li>El acuerdo se abonará hasta 15 días después de su fecha de finalización.</li>
                    </ul>
                </section>
                <section className="AMInfo__section3">
                    <strong>Periodo de espera:</strong>
                    <ul>
                        <li>No se podrá suscribir otro acuerdo hasta 30 días después de la finalización del anterior.
                        </li>
                    </ul>
                </section>
                <section className="AMInfo__section4">
                    <strong>Cálculo del acuerdo:</strong>
                    <ul>
                        <li>Se considerarán todas las emisiones realizadas en POLKO, de cualquier producto y compañía.
                        </li>
                    </ul>
                </section>
                <section className="AMInfo__section5">
                    <strong>Acuerdo personalizado:</strong>
                    <ul>
                        <li>El propósito de este acuerdo es que puedas financiar acciones que te ayuden a crecer.
                        </li>
                        <li>
                            Ingresá el motivo y el volumen de operaciones que tenes como objetivo y el equipo comercial
                            de Polko asignara el monto coherente a tus necesidades y objetivos.
                        </li>
                    </ul>
                </section>
            </div>
        }
                        closeAction={() => closeAction(false)}/>
    );
}

export default AgreementMoreInfo;