import React from 'react'
import {PublicPage} from "../../layouts/PublicPage";
import "./TerminosYCondiciones.css"

function TerminosYCondiciones() {
    return (
        <PublicPage>
            <div className="TerminosYCondiciones__container">
                <h1>Términos y condiciones de servicio - POLKO SAS</h1>

                <section>
                    <h2>1. PRELIMINARES</h2>
                    <p>
                        1.1. POLKO S.A. (en adelante, “POLKO”), sociedad en formación, con domicilio y sede social en
                        calle Av.
                        Rafael Núñez N° 3578 B° Cerro de las Rosas, ciudad de Córdoba, Provincia de Córdoba, es la
                        Titular y
                        Administradora de la plataforma virtual (en adelante la “plataforma web”) que proporciona una
                        variedad
                        de servicios y funciones que se detallarán a lo largo del documento.
                    </p>
                    <p>
                        1.2. El presente contrato, describe los términos y condiciones generales (en adelante los
                        "Términos y
                        Condiciones Generales") aplicables al uso de los servicios ofrecidos por POLKO, (en adelante los
                        “Servicios") dentro de la plataforma web denominada Polko y el sitio web www.polko.com.ar (en
                        adelante
                        "Polko" o el "Sitio").
                    </p>
                    <p>
                        1.3. Cualquier persona en ejercicio de su labor profesional (en adelante el "Usuario" o en
                        plural
                        "Usuarios") que desee acceder y/o usar la plataforma web, el Sitio o los Servicios, podrá
                        hacerlo
                        sujetándose a los Términos y Condiciones Generales respectivos, junto con todas las demás
                        políticas y
                        principios que rigen POLKO y que son incorporados al presente por referencia.
                        <br/>
                        CUALQUIER PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES GENERALES, LOS CUALES TIENEN UN
                        CARÁCTER
                        OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE UTILIZAR LA PLATAFORMA WEB, EL SITIO Y/O LOS
                        SERVICIOS.
                    </p>
                    <p>
                        1.4. Como condición previa y esencial a la utilización de la plataforma web, el Sitio o los
                        Servicios
                        ofrecidos por POLKO, el Usuario debe leer íntegramente y en forma completa, entender y aceptar
                        todas las
                        condiciones establecidas en los Términos y Condiciones Generales y en las Políticas de
                        Privacidad, así
                        como en los demás documentos incorporados a los mismos por referencia, previo a su registración
                        como
                        Usuario de POLKO, optando de forma expresa por recibir los mismos y toda otra información
                        relevante por
                        los medios digitales establecidos.
                    </p>
                    <p>
                        1.5. A través de la plataforma virtual del sitio web, POLKO facilita la vinculación entre
                        Usuarios
                        independientes denominados Polkistas y las personas humanas o destinatarios finales (en adelante
                        los
                        “Consumidores”) que requieran de los servicios de seguros y financieros, que son ofrecidos a
                        través de
                        POLKO. El servicio a ser prestado por los Polkistas a los Consumidores, a efectos de los
                        presentes
                        Términos y Condiciones Generales se denominará como “Servicio Polkista”, ejecutado a través de
                        un
                        contrato de mandato, donde el Polkista actúa como gestor y mandatario, y el Consumidor se
                        constituye
                        como mandante en dicha relación. La plataforma web únicamente constituye un mecanismo
                        intermediación
                        entre los Polkistas y los Consumidores.
                    </p>
                    <p>
                        1.6. Bajo ninguna circunstancia los Usuarios o Polkistas serán considerados empleados de POLKO
                        ni de
                        ninguno de sus afiliados. Los Usuarios prestan el Servicio Polkista por cuenta y riesgo propios
                        y
                        liberan a POLKO de cualquier responsabilidad que pudiera surgir durante la prestación del mismo.
                    </p>
                    <p>
                        1.7. A través de la plataforma web, POLKO junto con sus Polkistas, facilitan la vinculación
                        entre los
                        Consumidores y una serie de entidades que ofrecen servicios de seguros y financieros a través de
                        la
                        misma (en adelante los “Entidades Asociadas”), para que los Consumidores puedan celebrar
                        contratos de
                        seguros y/o financieros o la prestación de servicios con dichos Entidades Asociadas.
                    </p>
                    <p>
                        1.8. El Usuario conoce y acepta que POLKO no es parte en las operaciones concertadas o de la
                        prestación
                        de servicios que puedan acordarse en el marco de la Aplicación entre el Consumidor y los
                        Entidades
                        Asociadas o cualquier otra entidad. Cualquier reclamo o requerimiento al respecto deberá
                        realizarse a la
                        Entidad Asociada o con la que se haya celebrado la contratación correspondiente.
                    </p>
                </section>

                <section>

                    <h2>2. REGISTRO </h2>
                    <p>
                        2.1. Podrán ser Usuarios las personas humanas mayores de edad y con capacidad plena para
                        contratar, en
                        la medida en que realicen el proceso de Registro (conforme el mismo se define más adelante), y
                        el mismo
                        sea aceptado por POLKO. A tales efectos, Usted afirma que o es mayor de 18 años, o un menor
                        emancipado o
                        que posee permiso legal de sus padres y/o tutor y que está completamente habilitado y competente
                        para
                        entrar en estos términos, condiciones, obligaciones, afirmaciones, representaciones, y garantías
                        establecidas en estos Términos y Condiciones Generales, y para respetar y cumplir con estos
                        Términos y
                        Condiciones Generales.
                    </p>
                    <p>
                        2.2. El acceso a la plataforma web es gratuito, salvo en lo relativo al costo de la conexión a
                        través de
                        la red de telecomunicaciones suministrada por el proveedor de acceso contratado (ISP) por el
                        Usuario,
                        que estará a su exclusivo cargo. El Usuario únicamente podrá acceder a la plataforma web a
                        través de los
                        medios autorizados. Para acceder y utilizar los servicios de polko, el Usuario deberá contar con
                        un
                        Smartphone con navegador web, o en su caso, con una computadora con acceso a internet y deberá
                        completar
                        todos los campos del formulario de inscripción correspondiente a los Usuarios con datos válidos,
                        como
                        así también aceptar las Políticas de Privacidad y los presentes Términos y Condiciones
                        Generales.
                    </p>
                    <p>
                        2.3. Quien aspire a convertirse en Usuario o Polkista, tendrá la obligación de completar el
                        formulario
                        de registración en todos sus campos con datos válidos para poder utilizar los servicios que
                        brinda
                        POLKO. El Usuario deberá verificar que la información que pone a disposición de POLKO sea
                        exacta,
                        precisa y verdadera (en adelante los “Datos Personales”). El Usuario presta expresa conformidad
                        con que
                        POLKO utilice diversos medios para identificar sus Datos Personales, asumiendo la obligación de
                        revisarlos y mantenerlos actualizados periódicamente cada vez que los mismos sufran
                        modificaciones.
                        POLKO podrá utilizar diversos medios para identificar a los Usuarios, pero POLKO no garantiza a
                        los
                        Usuarios por la certeza de los Datos Personales que el resto de los Usuarios pongan a su
                        disposición.
                        Los Usuarios garantizan y responden, en cualquier caso, por la veracidad, exactitud, vigencia y
                        autenticidad de los Datos Personales puestos a disposición de POLKO. Esto no obsta a la
                        obligación de
                        POLKO de cumplir con las disposiciones establecidas en la Ley N° 25.326 y en sus Políticas de
                        Privacidad.
                    </p>
                    <p>
                        2.4. Los Datos Personales que el Usuario proporcione se integrarán en una base de datos
                        personales del
                        que es responsable POLKO.
                    </p>
                    <p>
                        2.5. POLKO se reserva el derecho de solicitar algún comprobante y/o dato adicional a efectos de
                        corroborar los Datos Personales, así como de suspender temporal o definitivamente a aquellos
                        Usuarios
                        cuyos datos no hayan podido ser confirmados. En estos casos de inhabilitación, se dará de baja a
                        la
                        cuenta personal.
                    </p>
                    <p>
                        2.6. Nuestra plataforma utiliza Auth0 para el proceso de autenticación de usuarios. Auth0 es un
                        servicio
                        de autenticación y autorización que proporciona una forma segura y confiable de verificar la
                        identidad
                        de los usuarios en nuestra plataforma. Al utilizar nuestra plataforma, aceptas los términos y
                        condiciones de Auth0, que puedes revisar en su sitio web oficial. El Usuario será responsable
                        por todas
                        las operaciones efectuadas a través de su Cuenta, pues el acceso a la misma está restringido al
                        ingreso
                        y uso de su Clave de Seguridad, de conocimiento exclusivo del Usuario
                    </p>
                    <p>
                        2.7. La Cuenta es personal, única e intransferible, y está prohibido que un mismo Usuario
                        registre o
                        posea más de una Cuenta. En caso que POLKO detecte distintas Cuentas que contengan datos
                        coincidentes o
                        relacionados, podrá cancelar, suspender o inhabilitarlas a su entera discreción.
                    </p>
                    <p>
                        2.8. POLKO se reserva el derecho de rechazar cualquier solicitud de registración o de cancelar
                        una
                        registración previamente aceptada, sin que esté obligado a comunicar o exponer las razones de su
                        decisión y sin que ello genere algún derecho a indemnización o resarcimiento.
                    </p>
                </section>

                <section>
                    <h2>3. PRIVACIDAD DE LA INFORMACIÓN</h2>
                    <p>
                        3.1. Para utilizar los Servicios ofrecidos por POLKlO, los Usuarios deberán facilitar
                        determinados datos
                        de carácter personal. Su información personal se procesa y almacena en servidores o medios
                        magnéticos
                        que mantienen altos estándares de seguridad y protección tanto física como tecnológica. Para
                        mayor
                        información sobre la privacidad de los Datos Personales y casos en los que será revelada la
                        información
                        personal, se pueden consultar nuestras Políticas de Privacidad.
                    </p>
                </section>

                <section>
                    <h2>4. MODIFICACIONES DEL ACUERDO</h2>
                    <p>
                        4.1. POLKO podrá modificar los Términos y Condiciones Generales en cualquier momento haciendo
                        públicos
                        en la plataforma web y/o el Sitio, los términos modificados. Todos los términos modificados
                        entrarán en
                        vigencia a partir del décimo día de su publicación. Dichas modificaciones serán comunicadas por
                        POLKO a
                        los Usuarios que en la Configuración de su Cuenta, hayan indicado que desean recibir
                        notificaciones de
                        los cambios en estos Términos y Condiciones Generales. Todo usuario que no esté de acuerdo con
                        las
                        modificaciones efectuadas por POLKO podrá solicitar la baja de la cuenta.
                    </p>
                    <p>
                        4.2. El uso de la plataforma web y/o el Sitio y/o sus Servicios implica la aceptación de estos
                        Términos
                        y Condiciones generales de uso de POLKO.
                    </p>
                </section>

                <section>
                    <h2>5. OPERATORIA DE LA APLICACIÓN</h2>
                    <p>
                        5.1. La utilización de la plataforma web y del Sitio, se encuentran limitadas y dirigidas
                        exclusivamente
                        a los Usuarios.
                    </p>
                    <p>
                        5.2. El Usuario podrá encontrar en la plataforma web y el Sitio, productos y servicios de las
                        Entidades
                        Asociadas, destinados a ser ofrecidos a los potenciales Consumidores. El Consumidor pagará el
                        precio
                        correspondiente a la Entidad Asociada mediante la gestión que realizará el Usuario o Polkista a
                        través
                        de la plataforma web.
                    </p>
                    <p>
                        5.3. Un mismo Usuario podrá ofrecer un número razonable de Servicios Polkistas disponibles en la
                        Aplicación por día. Los Usuarios aceptan en forma irrevocable que POLKO se reserva el derecho de
                        limitar
                        o bloquear, a su solo arbitrio, a cualquier Usuario ante cualquier sospecha de fraude, estafa,
                        uso de
                        datos ajenos, etc., o por cualquier otra sospecha de riesgo, cualquiera fuese su naturaleza.
                    </p>
                    <p>
                        5.4. POLKO podrá, a su exclusivo criterio, rechazar y/o suspender y/o cancelar cualquier
                        contrato
                        celebrado realizado por un Usuario a través de la plataforma web o Sitio siempre y cuando dicho
                        contratación incumpla: (i) cualquier disposición contenida las presentes en los Términos y
                        Condiciones
                        Generales o de cualquier ley o regulación aplicable a los mismos; (ii) al derecho aplicable en
                        general y
                        a los derechos de terceros incluyendo, a título meramente enunciativo, otros Usuarios; y (iii)
                        al uso
                        permitido de la plataforma web. En caso de cancelación realizada por POLKO, el Consumidor tendrá
                        derecho
                        al reembolso por hasta el valor de la totalidad del contrato celebrado.
                    </p>
                    <p>
                        5.5. Una vez solicitado y/o efectuado la concertación de algún Servicio Polkista, la plataforma
                        web
                        enviará un correo electrónico al Usuario con las condiciones del Servicio Polkista (en adelante
                        “Resumen
                        Operación”). El Resumen Operación incluirá el detalle del contrato celebrado entre el Usuario y
                        el
                        Consumidor final, para ser entregado una copia a este último como constancia de la operación
                        concertada.
                    </p>
                    <p>
                        5.6. Cualquier cambio en el Servicio Polkista será informado al Usuario al teléfono de contacto
                        y/o al
                        correo electrónico informado por el Usuario en su Cuenta.
                    </p>
                    <p>
                        5.7. El Usuario o Polkista cumplirá el Servicio Polkista entregando una copia del contrato
                        celebrado que
                        se emitirá y estará disponible en el Resumen Operación, para que sea entregado al Consumidor,
                        siendo el
                        Consumidor el único responsable por la veracidad de los datos informados al Usuario o Polkista.
                    </p>
                    <p>
                        5.8. Se encuentra totalmente prohibido utilizar el Servicio Polkista para celebrar contratos con
                        fines
                        ilícitos, ilegales, contrarios a lo establecido en los presentes Términos y Condiciones
                        Generales, a la
                        buena fe y al orden público, lesivos de los derechos e intereses de terceros.
                    </p>
                </section>

                <section>
                    <h2>6. VIOLACIÓN AL SISTEMA O BASE DE DATOS</h2>
                    <p>
                        6.1. No está permitida ninguna acción o uso de dispositivo, software, u otro medio tendiente a
                        interferir tanto en las actividades y operatoria de POLKO. Cualquier intromisión, tentativa o
                        actividad
                        violatoria o contraria a las leyes sobre derecho de propiedad intelectual y/o a las
                        prohibiciones
                        estipuladas en este contrato harán pasible a su responsable de las acciones legales pertinentes,
                        y a las
                        sanciones previstas por este acuerdo, así como lo hará responsable de indemnizar los daños
                        ocasionados.
                    </p>
                </section>

                <section>

                    <h2>7. SANCIONES</h2>
                    <p>
                        7.1. Sin perjuicio de otras medidas, POLKO podrá advertir, suspender en forma temporal o
                        definitivamente
                        la Cuenta de un Usuario, aplicar una sanción que impacte negativamente en la reputación de un
                        Usuario,
                        iniciar las acciones que estime pertinentes y/o suspender la prestación de sus Servicios si (a)
                        se
                        quebrantara alguna ley, o cualquiera de las estipulaciones de los Términos y Condiciones
                        Generales y
                        demás políticas de POLKO; (b) si incumpliera sus compromisos como Usuario; (c) si se incurriera
                        a
                        criterio de POLKO en conductas o actos dolosos o fraudulentos; (d) no pudiera verificarse la
                        identidad
                        del Usuario o cualquier información proporcionada por el mismo fuere errónea. En el caso de la
                        suspensión de un Usuario, sea temporal o definitiva, su Cuenta quedará inhabilitada para todo
                        uso,
                        incluso para disponer de los fondos existentes en la misma.
                    </p>
                </section>

                <section>
                    <h2>8. RESPONSABILIDAD</h2>
                    <p>
                        8.1. POLKO sólo pone a disposición del Usuario un espacio virtual que les permite ofrecer a los
                        potenciales consumidores, los Servicios Polkistas mediante la plataforma web o el Sitio. POLKO
                        no
                        interviene en el perfeccionamiento de las operaciones realizadas entre el Usuario y el
                        Consumidor, por
                        ello no será responsable respecto de la calidad, cantidad, estado, integridad o legitimidad de
                        los
                        productos o servicios ofrecidos por el Usuario, así como de la capacidad para contratar del
                        Usuario, ni
                        otorga garantía sobre la veracidad de sus datos personales por ellos ingresados. Esto no obsta a
                        la
                        obligación de POLKO de rectificar, actualizar o suprimir los datos incorrectos y demás
                        obligaciones
                        dispuestas en la Ley N° 25.326 y en sus Políticas de Privacidad.
                    </p>
                    <p>
                        8.2. Se aclara también que POLKO tampoco tiene injerencia ni será responsable por los productos
                        y/o
                        servicios de las Entidades Asociadas o demás entidades a los que se acceda a través de la
                        Aplicación y/o
                        el Sitio, incluyendo, pero sin limitarse a características de los mismos, condiciones de
                        adquisición, de
                        comercialización, de contratación, tarifas, promociones, descuentos, entre otros, todo lo cual
                        es
                        definido por la Entidad Asociada o demás entidades. Cualquier requerimiento o reclamo al
                        respecto deberá
                        realizarse a la Entidad Asociada o entidad correspondiente.
                    </p>
                    <p>
                        8.3. POLKO NO GARANTIZA, NI SE ADHIERE, NI ASUME RESPONSABILIDAD POR NINGUN PRODUCTO, O SERVICIO
                        OFRECIDO POR UN TERCERO A TRAVES DE LA PLATAFORMA WEB O DE OTROS SITIOS ACCEDIDOS A TRAVÉS DE
                        LOS
                        ENLACES EN LA PLATAFORMA WEB.
                    </p>
                    <p>
                        8.4. El Usuario conoce y acepta que, al realizar operaciones con la plataforma web y/o Sitio,
                        con la
                        Entidad Asociada y demás entidades lo hace bajo su propio riesgo. En ningún caso POLKO será
                        responsable
                        por lucro cesante, o por cualquier otro daño y/o perjuicio que haya podido sufrir, el Usuario,
                        el
                        Consumidor, la Entidad Asociada, las demás entidades, o cualquier otra persona debido a la
                        utilización
                        de la plataforma web y/o el Sitio.
                    </p>
                    <p>
                        8.5. El Usuario deberá utilizar la plataforma web responsablemente, verificando en todos los
                        casos la
                        correspondencia de las operaciones y los montos indicados en la pantalla. Las órdenes efectuadas
                        mediante la Cuenta podrán ser revertidas, revocadas o anuladas por el Usuario, previa
                        verificación y
                        autorización por POLKO.
                    </p>
                    <p>
                        8.6. POLKO recomienda actuar con prudencia y sentido común al momento de utilizar la plataforma
                        web y/o
                        el Sitio. En caso que uno o más Consumidores o algún tercero inicien cualquier tipo de reclamo o
                        acciones legales contra un Usuario, una Entidad Asociada o cualquier otra entidad, todos y cada
                        uno de
                        los involucrados en dichos reclamos o acciones eximen de toda responsabilidad a POLKO, sus
                        sociedades
                        vinculadas, directores, socios, empleados, representantes, apoderados y agentes.
                    </p>
                    <p>
                        8.7. Sitios Enlazados. La plataforma web puede contener enlaces y/o referencias a otras
                        aplicaciones o
                        sitios web de terceros (en adelante, “Sitios Enlazados”), lo cual no indica que ellos sean
                        propiedad de
                        POLKO u operados por POLKO. La instalación de estos enlaces o referencias en la plataforma web
                        y/o el
                        Sitio se limita a facilitar al Usuario la búsqueda y acceso a la información allí disponible. En
                        virtud
                        de que POLKO no tiene control sobre tales aplicaciones y sitios, POLKO NO será responsable por
                        los
                        contenidos, materiales, acciones y/o servicios prestados por los mismos, ni por daños o pérdidas
                        ocasionadas a los Usuarios, a los Consumidores, a las Entidades Asociadas o a otras entidades
                        por la
                        utilización de los mismos. La presencia de enlaces a otras plataformas o sitios web no implica
                        una
                        sociedad, relación, aprobación y/o respaldo de POLKO a dichas plataformas o sitios y sus
                        contenidos. Si
                        Ud. decide dejar la plataforma web y acceder a Sitios Enlazados, Ud. entiende que lo hace bajo
                        su propio
                        riesgo. La conexión que el Usuario realice con otros Sitios Enlazados queda totalmente bajo la
                        responsabilidad de dicho Usuario. POLKO no ha controlado de ninguna manera los Sitios Enlazados
                        y no se
                        hace responsable por el contenido, exactitud o autenticidad expresados en estos sitios web. La
                        inclusión
                        de estos enlaces en la plataforma web y/o el Sitio no implica aprobación o respaldo por POLKO de
                        los
                        Sitios Enlazados, sus entidades o productos y servicios.
                    </p>
                    <p>
                        8.8. EN NINGÚN CASO POLKO SERÁ RESPONSABLE POR DAÑOS Y PERJUICIOS DIRECTOS, INDIRECTOS,
                        IMPREVISTOS,
                        MEDIATOS O PUNITORIOS (INCLUSO SI POLKO FUE ADVERTIDO SOBRE DICHOS DAÑOS Y PERJUICIOS), QUE
                        RESULTEN DE
                        ALGÚN ASPECTO DEL USO QUE EL USUARIO HAGA DE LA PLATAFORMA WEB Y/O EL SITIO, YA SEA QUE LOS
                        DAÑOS Y
                        PERJUICIOS SURJAN A PARTIR DEL USO O EL MAL USO DE LA PLATAFORMA WEB O EL SERVICIO DE POLKO,
                        ANTE LA
                        INCAPACIDAD DE UTILIZAR LA PLATAFORMA WEB Y/O EL SITIO, O LA INTERRUPCIÓN, SUSPENSIÓN,
                        MODIFICACIÓN,
                        ALTERACIÓN O CANCELACIÓN DE LA PLATAFORMA WEB. DICHA LIMITACIÓN TAMBIÉN SERÁ APLICABLE CON
                        RELACIÓN A
                        LOS DAÑOS Y PERJUICIOS EN QUE SE INCURRAN A RAÍZ DE OTROS SERVICIOS O PRODUCTOS RECIBIDOS A
                        TRAVÉS O CON
                        RELACIÓN A LA PLATAFORMA WEB O LOS SITIOS ENLAZADOS EN LA PLATAFORMA WEB, ASÍ COMO TAMBIÉN A
                        CAUSA DE LA
                        INFORMACIÓN O EL CONSEJO RECIBIDO A TRAVÉS DE LA PLATAFORMA WEB Y/O EL SITIO, O PUBLICADO CON
                        RELACIÓN A
                        ESTOS O UNO DE LOS SITIOS ENLAZADOS EN LA PLATAFORMA WEB. LAS LIMITACIONES QUE ANTECEDEN SE
                        APLICARÁN
                        DENTRO DEL MÁXIMO ALCANCE PERMITIDO POR LA LEY.
                    </p>
                    <p>
                        8.9. Son los Usuarios Polkistas quienes utilizan POLKO para ofrecer los Servicios Polkistas.
                    </p>
                </section>

                <section>
                    <h2>9. ALCANCE DE LOS SERVICIOS DE POLKO</h2>
                    <p>
                        9.1. Este acuerdo no crea ningún contrato de sociedad, de mandato, de franquicia, o relación
                        laboral
                        entre POLKO y el Usuario. El usuario reconoce y acepta que POLKO no es parte en ninguna
                        operación, ni
                        tiene control alguno la capacidad de los Usuarios para concertar las operaciones con los
                        Consumidores.
                        POLKO no garantiza la veracidad de la publicidad de terceros que aparezca en la Aplicación y/o
                        el sitio.
                    </p>
                </section>

                <section>
                    <h2>10. FALLAS EN EL SISTEMA</h2>
                    <p>
                        10.1. POLKO no se responsabiliza por cualquier daño, perjuicio o pérdida al usuario causados por
                        fallas
                        en el sistema, en el servidor o en Internet. POLKO tampoco será responsable por cualquier virus
                        que
                        pudiera infectar el equipo del Usuario como consecuencia del acceso, uso o examen de su
                        plataforma web o
                        sitio web o a raíz de cualquier transferencia de datos, archivos, imágenes, textos, o audio
                        contenidos
                        en el mismo. Los usuarios NO podrán imputarle responsabilidad alguna ni exigir pago por lucro
                        cesante,
                        en virtud de perjuicios resultantes de dificultades técnicas o fallas en los sistemas o en
                        Internet.
                        POLKO no garantiza el acceso y uso continuado o ininterrumpido de su plataforma web o sitio. El
                        sistema
                        puede eventualmente no estar disponible debido a dificultades técnicas o fallas de Internet, o
                        por
                        cualquier otra circunstancia ajena a POLKO; en tales casos se procurará restablecerlo con la
                        mayor
                        celeridad posible sin que por ello pueda imputársele algún tipo de responsabilidad. POLKO no
                        será
                        responsable por ningún error u omisión contenidos en su plataforma web o sitio web.
                    </p>
                </section>

                <section>
                    <h2>11. PROHIBICIÓN DE CEDER</h2>
                    <p>
                        11.1. El Usuario no podrá ceder sus derechos y obligaciones dimanantes de los presentes Términos
                        y
                        Condiciones Generales sin el previo consentimiento escrito de POLKO. POLKO podrá ceder,
                        transferir,
                        delegar o disponer de los derechos u obligaciones derivados de la presente vinculación
                        (incluyendo su
                        posición contractual), total o parcialmente, para lo cual, en virtud de su aceptación de los
                        presentes
                        Términos y Condiciones Generales, los Usuarios otorgan su consentimiento expreso y de manera
                        previa para
                        la realización de dichas acciones.
                    </p>
                </section>

                <section>
                    <h2>12. PROPIEDAD INTELECTUAL</h2>
                    <p>
                        12.1. POLKO y/o sus sociedades controlantes, controladas, filiales o subsidiarias se reservan
                        todos los derechos, incluyendo los derechos de propiedad intelectual e industrial, asociados con
                        los servicios de POLKO, sus sitios web, los contenidos de sus pantallas, programas, bases de
                        datos, redes, códigos, desarrollo, software, arquitectura, hardware, contenidos, información,
                        tecnología, fases de integración, funcionalidades, dominios, archivos que permiten al Usuario
                        acceder y crear su Cuenta, herramientas de gestión, marcas, patentes, derechos de autor, diseños
                        y modelos industriales, nombres comerciales, entre otros, y declara que están protegidos por
                        leyes nacionales e internacionales vigentes.
                    </p>
                    <p>
                        12.2. En ningún caso se entenderá que el Usuario tendrá algún tipo de derecho sobre los mismos
                        excepto para utilizar el servicio de POLKO conforme a lo previsto en estos Términos y
                        Condiciones Generales. El uso indebido o contrario a la normativa vigente de los derechos de
                        propiedad intelectual e industrial de POLKO, así como su reproducción total o parcial, queda
                        prohibido, salvo autorización expresa y por escrito de POLKO.
                    </p>
                    <p>
                        12.3. Los Usuarios tampoco podrán comunicar que los productos o servicios que ofrecen son
                        patrocinados, promovidos, producidos, ofrecidos y/o vendidos por POLKO y deberán abstenerse de
                        realizar cualquier acto que pudiera causar un daño, pérdida de reputación, o disminución del
                        valor de los derechos de propiedad intelectual e industrial de POLKO.
                    </p>
                    <p>
                        12.4. La plataforma web y/o el Sitio puede contener enlaces a sitios web de terceros. En virtud
                        que POLKO no tiene control sobre tales sitios, no será responsable por los contenidos,
                        materiales, acciones y/o servicios prestados por los mismos, ni por daños o pérdidas ocasionadas
                        por la utilización de éstos, causados directa o indirectamente. La presencia de enlaces a otros
                        sitios web no implica una sociedad, relación, aprobación, respaldo de POLKO con dichos sitios y
                        sus contenidos.
                    </p>
                </section>

                <section>
                    <h2>13. INDEMNIDAD</h2>
                    <p>
                        13.1. El Usuario mantendrá indemne a POLKO, así como a sus filiales, empresas controladas y/o
                        controlantes, funcionarios, directivos, sucesores, administradores, representantes y empleados,
                        por cualquier reclamo iniciado por otros Usuarios, terceros o por cualquier Organismo,
                        relacionado con sus actividades en la plataforma web y/o el Sitio, el cumplimiento y/o el
                        incumplimiento de los Términos y Condiciones Generales o demás Políticas, así como respecto de
                        cualquier violación de leyes o derechos de terceros.
                    </p>
                    <p>
                        13.2. A tal fin, el Usuario faculta a POLKO a: i) intervenir y representarlo en dichos reclamos,
                        pudiendo celebrar los acuerdos transaccionales que considere oportunos y que tiendan a evitar
                        mayores costos y/o evitar eventuales contingencias sin limitación, en su nombre y
                        representación; ii) retener y debitar de su Cuenta y/o de la(s) cuenta(s) bancaria(s)
                        registrada(s) en la Cuenta, los fondos existentes y/o futuros para recuperar cualquier gasto
                        incurrido en relación a los supuestos del párrafo anterior, los cuales podrán incluir, entre
                        otros, los honorarios de los letrados intervinientes y las costas judiciales en una cantidad
                        razonable, y/o iii) generar débitos específicos en su facturación.
                    </p>
                </section>

                <section>
                    <h2>14. SOPORTE ELECTRÓNICO</h2>
                    <p>
                        14.1. El Usuario acepta expresamente que la documentación e información relacionada con su
                        vinculación con POLKO y su accionar en la plataforma web tenga soporte electrónico. En todo
                        caso, el Usuario podrá hacer copias, incluso en soporte físico, de la documentación e
                        información provista por POLKO y la plataforma web. Las constancias emitidas en este marco por
                        la plataforma web y/o el Sitio y POLKO, serán consideradas prueba suficiente del cumplimiento o
                        incumplimiento de las obligaciones de las partes, sustituyen la necesidad de cualquier recibo en
                        papel, y son plenamente oponibles al Usuario. A tales efectos, el Usuario, los Consumidores, las
                        Entidades Asociadas, cualquier otra entidad y POLKO acuerdan otorgarle pleno valor probatorio a
                        las constancias que se emitan en el marco de la plataforma web y/o el Sitio, con el alcance
                        establecido en la legislación vigente.
                    </p>
                </section>

                <section>
                    <h2>15. POLITICA DE PRIVACIDAD</h2>
                    <h3>Recopilación de Información Personal</h3>
                    <p>
                        15.1.1. En POLKO, recopilamos información personal de nuestros usuarios para brindarles
                        servicios personalizados y mejorar su experiencia en nuestra plataforma.
                    </p>
                    <p>
                        15.1.2. La información personal que recopilamos puede incluir nombres, direcciones de correo
                        electrónico, números de teléfono, direcciones físicas y otra información relevante para la
                        prestación de nuestros servicios.
                    </p>
                    <p>
                        15.1.3. Nos comprometemos a proteger la confidencialidad y seguridad de la información personal
                        de nuestros usuarios y a utilizarla únicamente para los fines especificados en nuestras
                        políticas.
                    </p>
                    <h3>Uso de la Información Personal</h3>
                    <p>
                        15.2.1. La información personal recopilada se utiliza para proporcionar servicios, procesar
                        transacciones, mantener la seguridad de la plataforma y mejorar nuestros servicios y productos.
                    </p>
                    <p>
                        15.2.2. No compartimos la información personal de nuestros usuarios con terceros, excepto cuando
                        sea necesario para cumplir con los servicios solicitados por los usuarios o cuando lo requiera
                        la ley.
                    </p>
                    <h3>Seguridad de la Información</h3>
                    <p>
                        15.3.1. Implementamos medidas de seguridad físicas, electrónicas y administrativas para proteger
                        la información personal de nuestros usuarios contra accesos no autorizados, alteraciones,
                        divulgaciones o destrucciones.
                    </p>
                    <p>
                        15.3.2. Nuestro personal está capacitado para manejar la información personal de manera
                        confidencial y solo tiene acceso a ella en la medida en que sea necesario para desempeñar sus
                        funciones.
                    </p>
                    <h3>Enlaces a Terceros</h3>
                    <p>15.4.1. Nuestra plataforma puede contener enlaces a sitios web de terceros. No somos responsables
                        de las prácticas de privacidad o el contenido de estos sitios y recomendamos a nuestros usuarios
                        revisar las políticas de privacidad de estos sitios antes de proporcionar cualquier información
                        personal.</p>
                    <h3>Actualizaciones y Cambios en las Políticas de Privacidad</h3>
                    <p>
                        15.5.1. Nos reservamos el derecho de actualizar y modificar nuestras políticas de privacidad en
                        cualquier momento. Se notificará a los usuarios sobre cualquier cambio significativo en nuestras
                        políticas de privacidad a través de nuestra plataforma o por otros medios apropiados.
                    </p>
                    <h3>Solicitud de Eliminación de Datos Personales</h3>
                    <p>
                        15.6.1. En POLKO, respetamos el derecho de nuestros usuarios a tener control sobre su
                        información personal. Los usuarios tienen el derecho de solicitar la eliminación de sus datos
                        personales de nuestra plataforma en cualquier momento.
                    </p>
                    <p>
                        15.6.2. Para solicitar la eliminación de datos personales, los usuarios pueden ponerse en
                        contacto con nuestro equipo de soporte a través de los canales de comunicación proporcionados en
                        nuestra plataforma.
                    </p>
                    <p>
                        15.6.3. Una vez recibida una solicitud de eliminación de datos personales, POLKO verificará la
                        identidad del solicitante y procederá a eliminar los datos personales en un plazo razonable, de
                        conformidad con las leyes aplicables y nuestras políticas internas.
                    </p>
                    <p>
                        15.6.4. Es importante tener en cuenta que la eliminación de ciertos datos personales puede
                        afectar la capacidad del usuario para acceder y utilizar ciertos servicios o funcionalidades de
                        nuestra plataforma.
                    </p>
                    <p>
                        15.6.5. POLKO retendrá cierta información en la medida en que sea necesario para cumplir con
                        obligaciones legales, resolver disputas, mantener la seguridad, prevenir fraudes y hacer valer
                        nuestros acuerdos.
                    </p>
                </section>

                <section>
                    <h2>16. NOTIFICACIONES</h2>
                    <p>
                        16.1. POLKO podrá realizar las notificaciones al Usuario a través de una notificación general en
                        la plataforma web y/o el Sitio, a través de WhatsApp (una aplicación de mensajería instantánea),
                        y a la dirección de correo electrónico facilitada por el Usuario en su Cuenta. El Usuario podrá
                        comunicarse con POLKO mediante el envío de un correo electrónico a la dirección
                        consultas@polko.com.ar
                    </p>
                </section>

                <section>
                    <h2>17. ANEXOS</h2>
                    <p>
                        17.1. Forman parte integral e inseparable de los Términos y Condiciones Generales, los
                        siguientes documentos y/o secciones de POLKO incorporados por referencia, donde se detallan
                        políticas y/o Términos y Condiciones de diferentes servicios ofrecidos en la plataforma web y/o
                        el Sitio. Los mismos se podrán consultar mediante el enlace abajo provisto o accediendo
                        directamente a las páginas correspondientes.
                    </p>
                </section>

                <section>
                    <h2>18. JURISDICCIÓN Y LEY APLICABLE</h2>
                    <p>
                        18.1. Este acuerdo estará regido en todos sus puntos por las leyes vigentes en la República
                        Argentina.
                    </p>
                    <p>
                        18.2. Cualquier controversia derivada del presente acuerdo, su existencia, validez,
                        interpretación, alcance o cumplimiento, será sometida ante la Justicia Provincial Ordinaria con
                        sede en la ciudad de Córdoba.
                    </p>
                </section>

                <section>
                    <h2>19. DOMICILIO</h2>
                    <p>
                        19.1. Se fija como domicilio de POLKO S.A. en Avenida Rafael Núñez N° 3578 B° Cerro de las
                        Rosas, ciudad de Córdoba, Provincia de Córdoba, código postal 5000, República Argentina.
                    </p>
                </section>
            </div>
        </PublicPage>
    );
}

export default TerminosYCondiciones;