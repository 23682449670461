import React from 'react';
import './DescargablesModal.css';
import {PopUpCardModal} from "../../../../layouts/PopUpCardModal";
import {Button} from "../../../../components/Button";
import Axios from "axios";
import FileDownload from "js-file-download";
import {DownloadableCard} from "../../../../components/DownloadableCard";
import showNotification from "../../../../modules/utils/showNotification";
import updateNotification from "../../../../modules/utils/updateNotification";
import {CarouselWrapper} from "../../../../components/CarouselWrapper";

const automotorPreview = "https://polko-images.s3.us-east-2.amazonaws.com/CanalesDigitales/folletos/folleto_0-v2.jpg";
const hogarPreview = "https://polko-images.s3.us-east-2.amazonaws.com/CanalesDigitales/folletos/folleto_1-v2.jpg";
const bicicletaPreview = "https://polko-images.s3.us-east-2.amazonaws.com/CanalesDigitales/folletos/folleto_2-v2.jpg";
const notebookPreview = "https://polko-images.s3.us-east-2.amazonaws.com/CanalesDigitales/folletos/folleto_3-v2.jpg";
const motovehiculoPreview = "https://polko-images.s3.us-east-2.amazonaws.com/CanalesDigitales/folletos/folleto_4-v2.jpg";
const asistenciaViajeroPreview = "https://polko-images.s3.us-east-2.amazonaws.com/CanalesDigitales/folletos/folleto_5-v2.jpg";
const todosPreview = "https://polko-images.s3.us-east-2.amazonaws.com/CanalesDigitales/folletos/folleto_6-v2.jpg";

const automotorName = "Automotor";
const hogarName = "Hogar";
const todosName = "Todos los productos";
const bicicletaName = "Bicicleta y monopatín";
const notebookName = "Notebook y tablet";
const motovehiculoName = "Motovehículo";
const asistenciaAlViajeroName = "Asistencia al viajero";

function DescargablesModal(props) {
    const {setOpen, values} = props;
    const { row: {productos: products, qr: QR, logo: Logo} } = values;

    const handleDownload = (alternative) => {
        showNotification(
            {
                id: 'download-file',
                loading: true,
                color:"gray",
                title: 'Descargando...',
                message: 'Su archivo estará listo en unos segundos.',
                autoClose: false,
                withCloseButton: false,
            }
        )

        const jsonData = {
            qrUrl: QR,
            logoUrl: Logo,
            alternative: alternative,
        };

        Axios.post(
            process.env.REACT_APP_BACK_SERVER + `/folletoCanalDigital`,
            jsonData,
            { responseType: 'blob' }
        )
            .then((response) => {
                FileDownload(response.data, 'productos.png');
                updateNotification(
                    {
                        id: 'download-file',
                        color: 'green',
                        status: 'success',
                        title: '¡Descargado!',
                        message: 'Ya puede compartir su banner personalizado.',
                        autoClose: 3000
                    })
            })
            .catch((error) => {
                updateNotification(
                    {
                        id: 'download-file',
                        color: 'red',
                        status: 'error',
                        title: 'Error al descargar el archivo.',
                        message: error || 'El archivo no pudo ser descargado.',
                        autoClose: 3000
                    })
            });
    };

    //Update this to add more downloadable cards
    const productCards = [
        {
            active: products['vehiculo']['active'],
            preview: automotorPreview,
            handleDownload: () => handleDownload(0),
            name: automotorName
        },
        {
            active: products['motovehiculo']['active'],
            preview: motovehiculoPreview,
            handleDownload: () => handleDownload(4),
            name: motovehiculoName
        },
        {
            active: products['asistencia_viajero']['active'],
            preview: asistenciaViajeroPreview,
            handleDownload: () => handleDownload(5),
            name: asistenciaAlViajeroName
        },
        {
            active: products['hogar']['active'],
            preview: hogarPreview,
            handleDownload: () => handleDownload(1),
            name: hogarName
        },
        {
            active: products['microseguros']['active'],
            cards: [
                {
                    preview: bicicletaPreview,
                    handleDownload: () => handleDownload(2),
                    name: bicicletaName
                },
                {
                    preview: notebookPreview,
                    handleDownload: () => handleDownload(3),
                    name: notebookName
                }
            ]
        }
    ];

    const generateDownloadableCards = (productCards) => {
        const cardsArray = [];

        productCards
            .filter((card) => card.active)
            .forEach((card, index) => {
                if (card.cards && card.cards.length > 0) {
                    card.cards.forEach((innerCard, innerIndex) => {
                        const downloadableCard = (
                            <DownloadableCard
                                key={`inner_${innerIndex}`}
                                preview={innerCard.preview}
                                handleDownload={innerCard.handleDownload}
                                name={innerCard.name}
                            />
                        );
                        cardsArray.push(downloadableCard);
                    });
                } else {
                    const downloadableCard = (
                        <DownloadableCard
                            key={`outer_${index}`}
                            preview={card.preview}
                            handleDownload={card.handleDownload}
                            name={card.name}
                        />
                    );
                    cardsArray.push(downloadableCard);
                }
            });

        if (
            products['vehiculo']['active'] &&
            products['hogar']['active'] &&
            products['microseguros']['active'] &&
            products['motovehiculo']['active'] &&
            products['asistencia_viajero']['active']
        ) {
            const downloadableCard = (
                <DownloadableCard
                    preview={todosPreview}
                    handleDownload={() => handleDownload(6)}
                    name={todosName}
                />
            );
            cardsArray.push(downloadableCard);
        }

        return cardsArray;
    };


    return (
        <PopUpCardModal
            title={"Piezas gráficas disponibles para este canal"}
            body={
                <div className="descargablesModal__body">
                    <CarouselWrapper slideSize={"200px"}>
                        {generateDownloadableCards(productCards)}
                    </CarouselWrapper>
                </div>
            }
            closeAction={() => setOpen({modalName: "", values: null})}
            footer={
                <div className="csm__button__container">
                    <Button variant="filled"
                            color="primary"
                            marginX="sm"
                            type="button"
                            enabled
                            onClick={() => setOpen(false)}
                    >
                        Finalizar
                    </Button>
                </div>
            }
        />
    );
}

export default DescargablesModal;