import React, {useContext, useEffect, useState} from 'react';
import './BestSellingCoverages.css'
import {CollapsibleBox} from "../../../components/CollapsibleBox";
import Chart from "../../../modules/Dashboard/Chart";
import {ProductDetails} from "../ProductDetails";
import {MantineSelectFilter} from "../../../components/Filters/MantineSelectFilter";
import {DetailsTargetCoverage} from "./DetailsTargetCoverage";
import UserContext from "../../../context/UserContext";
import CotizarContext from "../../../context/CotizarContext";

function BestSellingCoverages(props) {
    const {collapse, setCollapse, data} = props;
    const {isMobile, userCompaniesNames} = useContext(UserContext);
    const [selectedProduct, setSelectedProduct] = useState('vehiculo');
    const [selectedCompany, setSelectedCompany] = useState('Sancor');
    const [targetGraphicDetail, setTargetGraphicDetail] = useState(null);
    const {companiesDict} = useContext(CotizarContext);
    // TODO: Reemplazar este por un productDict
    const productDict = {
        vehiculo: "Automotor",
        hogar: "Hogar",
        microseguros: "Microseguro",
        motovehiculos: "Motovehículo",
        asistencia_viajero: "Asistencia al viajero",
    }
    const [topCoverageData, setTopCoverageData] = useState(Object.entries(data[selectedProduct].generales.mas_vendido)[0])


    useEffect(() => {
        let newTarget = null;
        if (data[selectedProduct][selectedCompany] && data[selectedProduct][selectedCompany] !== undefined) {
            newTarget = Object.entries(data[selectedProduct][selectedCompany])[0]
        } else {
            const firstProduct = companiesDict[selectedCompany].products[0].value;
            setSelectedProduct(firstProduct);
            if (data[firstProduct][selectedCompany]) {
                newTarget = Object.entries(data[firstProduct][selectedCompany])[0]
            }
        }
        setTargetGraphicDetail(newTarget);
    }, [selectedCompany, selectedProduct]);

    useEffect(() => {
        setTopCoverageData(Object.entries(data[selectedProduct].generales.mas_vendido)[0])
    }, [data, selectedProduct])

    return (
        <CollapsibleBox
            collapse={collapse}
            withButton={isMobile}
            setCollapse={setCollapse}
            header={
                <div className="DStatistics__container--header">
                    <h1 className="DStatistics__container--header-title">Coberturas más vendidas</h1>
                    {collapse &&
                        <p className="BSCoverages__resume">
                            {topCoverageData[1].company} - {productDict[selectedProduct]} - {topCoverageData[0]}
                        </p>
                    }
                </div>
            }
            content={
                <>
                    <div className="BSCoverages__contentContainer">
                        <div className="BSCoverages__chartHeader">
                            <p className="BSCoverages__filterText">Filtrar por:</p>
                            <div className="BSCoverages__filtersContainer">
                                <div className="BSCoverages__selectContainer">
                                    <MantineSelectFilter
                                        value={selectedCompany}
                                        setValue={setSelectedCompany}
                                        data={userCompaniesNames}
                                        clearable={false}/>
                                </div>
                                <div className="BSCoverages__selectContainer">
                                    <MantineSelectFilter
                                        value={selectedProduct}
                                        setValue={setSelectedProduct}
                                        clearable={false}
                                        data={companiesDict[selectedCompany].products}/>
                                </div>
                            </div>
                        </div>
                        <div className="BSCoverages__chartContainer">

                            {data[selectedProduct][selectedCompany] &&
                                data[selectedProduct][selectedCompany] !== undefined &&
                                targetGraphicDetail &&
                                <Chart
                                    data={data}
                                    active={{company: selectedCompany, product: selectedProduct}}
                                    setTarget={setTargetGraphicDetail}
                                    target={targetGraphicDetail}
                                />
                            }

                            {!targetGraphicDetail
                                ? <p className="BSCoverages__noData">No hay datos para mostrar</p>
                                :
                                <DetailsTargetCoverage
                                    name={targetGraphicDetail[0]}
                                    addition={targetGraphicDetail[1].suma_asegurada}
                                    country={targetGraphicDetail[1].pais}
                                    prize={
                                        selectedProduct !== 'microseguros'
                                            ? targetGraphicDetail[1].premio
                                            : data[selectedProduct].generales.premio
                                    }
                                />
                            }
                        </div>
                        {data[selectedProduct][selectedCompany] &&
                            <ProductDetails
                                data={data[selectedProduct].generales}
                                active={{
                                    company: selectedCompany,
                                    product: selectedProduct
                                }}
                                productDict={productDict}/>}
                        <p className="BSCoverages__clarification">
                            Información generada a partir de las operaciones emitidas
                            por todos los polkistas durante los ultimos 30 días.
                        </p>
                    </div>
                </>
            }/>
    );
}

export default BestSellingCoverages;