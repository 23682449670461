import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../../../../components/Button";
import { EyeIcon } from "../../../../components/icons/EyeIcon";
import UserContext from "../../../../context/UserContext";
import CodigosPopUp from "../CodigosPopUp/CodigosPopUp";
import "../MisAseguradorasCompanies.css";
import "./CodigosContainer.css";

const CodigosContainer = ({ codigos, companyTicket }) => {
    const { isMobile } = useContext(UserContext);
    const history = useHistory();
    const [codigosModalOpen, setCodigosModalOpen] = useState(false);

    const getCodigosLength = (codigos) => {
        let totalKeys = 0;
        codigos.forEach((obj) => {
            Object.values(obj).map((value) => (value ? totalKeys++ : null));
        });
        return totalKeys;
    }

    if (codigos) {
        const codigosAmount = getCodigosLength(codigos);
        if (isMobile || codigosAmount > 2) {
            return (
                <>
                    <div className="CodigosContainer__button--container">
                        <div className="CodigosContainer__button--wrapper">
                            <Button variant="filled" color="lavanda" size="md" enabled fullWidth fullHeight onClick={() => { setCodigosModalOpen(true) }}>
                                <div className="CodigosContainer__button--withIcon">
                                    <span className="CodigosContainer__button--text">{codigosAmount.toString()}</span>
                                    <EyeIcon size="sm" />
                                </div>
                            </Button>
                        </div>
                    </div>
                    {codigosModalOpen &&
                        <CodigosPopUp closeAction={() => { setCodigosModalOpen(false) }} codigos={codigos} />
                    }
                </>
            );
        } else {
            return (
                <div className="CodigosContainer__button--container">
                    {codigos.map((companyCodes, index) =>
                        Object.keys(companyCodes).map((key) => (
                            companyCodes[key] && (
                                <div key={`${index}-${key}`} className="CodigosContainer__button--wrapper">
                                    <Button
                                        key={companyCodes[key]}
                                        variant="filled"
                                        color="lavanda"
                                        size="md"
                                        enabled
                                        fullWidth
                                    >
                                        <span className="CodigosContainer__button--text">{companyCodes[key]}</span>
                                    </Button>
                                </div>
                            )
                        ))
                    )}
                </div>
            );
        }
    } else {
        // Maneja el caso borde en el que se pasó el ticket de odoo a estado completado pero todavía
        // no se asignaron los códigos al usuario desde admin
        if (companyTicket === "En curso" || companyTicket === "Completo") {
            return (
                <div className="CodigosContainer__button--container">
                    <div className="CodigosContainer__button--wrapper">
                        <Button
                            variant="outlined"
                            color="primary"
                            size="md"
                            enabled
                            fullWidth
                            fullHeight>
                            <span className="CodigosContainer__button--text">En trámite</span>
                        </Button>
                    </div>
                </div>
            )
        }
        else if (companyTicket === "Cancelado") {
            return (
                <div className="CodigosContainer__button--container">
                    <div className="CodigosContainer__button--wrapper">
                        <Button
                            variant="outlined"
                            color="primary"
                            size="md"
                            enabled={false}
                            fullWidth
                            fullHeight>
                            <span className="CodigosContainer__button--text">Cancelado</span>
                        </Button>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="CodigosContainer__button--container">
                    <div className="CodigosContainer__button--wrapper">
                        <Button
                            variant="filled"
                            color="primary"
                            size="md"
                            enabled
                            fullWidth
                            fullHeight
                            onClick={() => {
                                history.push('/u/m/alta/compania')
                            }}>
                            <span className="CodigosContainer__button--text">Solicitar</span>
                        </Button>
                    </div>
                </div>
            );
        }
    }
}

export default CodigosContainer;