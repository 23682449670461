import {Grid} from "@mantine/core";
import React from "react";
import {TextLink} from "../TextLink";

function renderColumns(columns, rowData) {
    return Object.values(columns).map((column, index) => {
        if (column.name === "masInfo") return
        return (
            <Grid.Col span={column.colSpan} key={index} className="ctrow__text">
                {column.render ? column.render(rowData[column.name]) : rowData[column.name]}
            </Grid.Col>
        );
    })
}

function renderCardRows(columns, rowData, handleOpenCoverageOverviewModal, dataObjectRequired) {
    return Object.values(columns).map((column, index) => {
        let keyClassName;
        switch (column.name) {
            case "id":
                keyClassName = "coverageCard__id"
                break;
            case "nombre":
                keyClassName = "coverageCard__name"
                break;
            case "premium":
                keyClassName = "coverageCard__premium"
                break;
            case "precio":
                keyClassName = "coverageCard__premium coverageCard__precio"
                break;
            default:
                keyClassName = "ctrow__text"
        }
        return (
            column.name === "masInfo" ?
                <TextLink size={"sm"} key={index}
                          action={() => handleOpenCoverageOverviewModal(dataObjectRequired)}>
                    Ver detalles
                </TextLink>
                :
            <div className={keyClassName} key={index}>
                {column.render ? column.render(rowData[column.name]) : rowData[column.name]}
            </div>
        );
    })
}

export {renderColumns, renderCardRows}