import React, {useState} from 'react';
import './MasterDashboardTable.css';
import Tabs from "../../../components/Tabs/Tabs";
import {FormCard} from "../../../components/FormCard";
import {MasterOperacionesTable} from "../MasterOperacionesTable";
import {MasterPolkistasTable} from "../MasterPolkistasTable";

function MasterDashboardTable(props) {
    const {emissionStatistics, polkistasStatistics} = props;
    const [activeTab, setActiveTab] = useState('OPERACIONES');
    return (
        <div className="masterDashboardTable__container">
            <FormCard>
                    <Tabs tabs={[
                        <span onClick={() => {setActiveTab('OPERACIONES')}}
                              className={activeTab === 'OPERACIONES' ? "masterDashboardTable__tab masterDashboardTable__tab--active" : "masterDashboardTable__tab"}
                        >
                            EMISIONES
                        </span>,
                        <span onClick={() => {setActiveTab('POLKISTAS')}}
                              className={activeTab === 'POLKISTAS' ? "masterDashboardTable__tab masterDashboardTable__tab--active" : "masterDashboardTable__tab"}
                        >
                            POLKISTAS
                        </span>
                    ]}
                          tabContent={[
                              <MasterOperacionesTable rows={emissionStatistics}/>,
                              <MasterPolkistasTable rows={polkistasStatistics}/>
                          ]}
                    />
            </FormCard>
        </div>
    );
}

export default MasterDashboardTable;