import React from 'react';
import './CustomerPopOver.css';
import {Popover} from "@mantine/core";

function CustomerPopOver(props) {
    const {customer, index, open, setOpen} = props;

    return (
        <Popover opened={open === index}
                 onChange={() => setOpen(null)}>
            <Popover.Target>
                <p className="customerPopOver__target" onClick={() => setOpen(index)}>
                    {`${customer['nombre']} ${customer['apellido']}`}
                </p>
            </Popover.Target>

            <Popover.Dropdown>
                <div className="customerPopOver__dropDown__container">
                    <p className="customerPopOver__dropDown__title">Más información</p>
                    <ul>
                        <li className="customerPopOver__dropDown__text">Email: {customer['email']}</li>
                        <li className="customerPopOver__dropDown__text">Celular: {customer['telefono']}</li>
                    </ul>
                </div>
            </Popover.Dropdown>
        </Popover>
    );
}

export default CustomerPopOver;