import React, {useState} from 'react';
import {PopUpCardModal} from "../../../../../layouts/PopUpCardModal";
import {ReactComponent as ArrowIcon} from "../../../../../assets/icons/ic_arrow-down.svg";
import "./TemplateViewer.css";

function TemplateViewer(props) {
    const {templateId, name, closeAction} = props;
    const [mobileTemplate, setMobileTemplate] = useState(false);

    return (
        <PopUpCardModal
            body={
                mobileTemplate ?
                    (<div className="TViewer__imageContainer">
                        <ArrowIcon className="TViewer__arrowIcon TViewer__arrowIcon--left" onClick={() => setMobileTemplate(false)}/>
                        <img className="TViewer__image" alt={`Plantilla ${name}`}
                             src={`https://polko-images.s3.us-east-2.amazonaws.com/CanalesDigitales/templates/template${templateId}-mobile.png`}/>
                    </div>)
                        :
                        (<div className="TViewer__imageContainer">
                            <img className="TViewer__image" alt={`Plantilla ${name}`}
                             src={`https://polko-images.s3.us-east-2.amazonaws.com/CanalesDigitales/templates/template${templateId}-desktop.png`}/>
                        <ArrowIcon className="TViewer__arrowIcon TViewer__arrowIcon--right" onClick={() => setMobileTemplate(true)}/>
                    </div>)}
            title={"Visualizador de plantilla"}
            closeAction={closeAction}
            footer={<p className="TViewer__templateName">Plantilla {name}</p>}
        />
    );
}

export default TemplateViewer;