import {QuotationResponseTabError} from "../../QuotationResponseTabError";
import React, {useMemo} from "react";
import ErrorsDict from "./ErrorsDict";

const RenderError = ({ code, compania, message }) => {
    const error = ErrorsDict(code, compania);
    const detailedError = useMemo(() => {
        if (error.hasDetails){
            if (error.isDefault) return message
            else return error.detailedError
        }
    }, [error.hasDetails, message])

    return (
        <>
            <div className="response__tab__body">
                <QuotationResponseTabError
                    title={error.message || "Error inesperado"}
                    bodyText={error.bodyText}
                    typeError={error.showErrorCode ? code : null}
                    type={error.type || "error"}
                    suggestedActions={error.suggestedActions}
                    detailedError={detailedError}
                />
            </div>
        </>
    )
}

export default RenderError;