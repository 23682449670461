import React from 'react';
import './SuccessModal.css';
import {PopUpCard} from "../../components/PopUpCard";
import {ReactComponent as CloseIcon} from "../../assets/icons/ic_close.svg";
import {ReactComponent as CheckCircledIcon} from "../../assets/icons/ic_check_circled.svg";
import {CustomModal} from "../../components/CustomModal";

function SuccessModal(props) {
    const {title, body, footer, closeAction} = props;

    return (
        <CustomModal>
            <PopUpCard>
                <div className="successModal__container">

                    <div className="successModal__header">
                        <div className="successModal__icon__container" onClick={closeAction}>
                            <CloseIcon className="successModal__icon__close"/>
                        </div>
                        <div className="successModal__title">
                            <CheckCircledIcon className="successModal__icon"/>
                            {title}
                        </div>
                    </div>

                    {body &&
                    <div className="successModal__body">
                        {body}
                    </div>
                    }

                    {footer &&
                    <div className="successModal__footer">
                        {footer}
                    </div>
                    }
                </div>
            </PopUpCard>
        </CustomModal>
    );
}

export default SuccessModal;