import React, {useState, useMemo, useContext, useRef} from 'react';
import PropTypes from 'prop-types';
import AuthContext from "../../AuthContext";
import MotovehiculoContext from "./MotovehiculoContext";
import getMotoVersions from "./services/getMotoVersions";

const MotovehiculoContextProvider = ({children, initialData}) => {
    const marcas = initialData || [];
    const {authToken} = useContext(AuthContext);
    const anios = Array.from({length: 20}, (_, i) => ({value: (2024 - i).toString(), label: (2024 - i).toString()}));
    const [versiones, setVersiones] = useState([]);
    const [isLoadingVersions, setIsLoadingVersions] = useState(false);
    const [versionsError, setVersionsError] = useState(null);
    const vehicleDetails = useRef({
        detalle_marca: "",
        detalle_version: ""
    });

    const updateVersions = (marca, anio, resetValues) => {
        if (resetValues) setVersiones([]);
        setIsLoadingVersions(true);
        setVersionsError(null);
        getMotoVersions(authToken, marca, anio)
            .then(response => {
                setVersiones(response.data);
            })
            .catch(error => {
                console.error('Error al actualizar versiones:', error);
                setVersionsError(error);
            })
            .finally(() => {
                setIsLoadingVersions(false);
            });
    };

    const handleRecotizarInitialValues = (versionValue, versionLabel, marcaLabel) => {
        setVersiones([{value: versionValue, label: versionLabel}]);
        vehicleDetails.current = {
            detalle_marca: marcaLabel,
            detalle_version: versionLabel
        };
    }

    const context = useMemo(() => ({
        marcas,
        anios,
        versiones,
        vehicleDetails,
        updateVersions,
        handleRecotizarInitialValues,
        isLoadingVersions,
        versionsError
    }), [
        marcas,
        anios,
        versiones,
        vehicleDetails,
        isLoadingVersions,
        versionsError
    ]);

    return (
        <MotovehiculoContext.Provider value={context}>
            {children}
        </MotovehiculoContext.Provider>
    );
};

MotovehiculoContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default MotovehiculoContextProvider;
