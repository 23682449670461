import React, {useState} from 'react';
import {Grid} from '@mantine/core';
import {ScoringModel} from "./ScoringModel";
import {MantineSelectField} from "../../../../../../components/FormFields/MantineSelectField";
import QuotationScoringModal from "../../../../../Cotizacion/QuotationScoringModal/QuotationScoringModal";
import {ReactComponent as InformationFilledIcon} from "../../../../../../assets/icons/ic_info-filled.svg";
import {MantineNumberField} from "../../../../../../components/FormFields/MantineNumberField";

export default function ScoringForm() {
    const {formField} = ScoringModel();
    const [showScoringModal, setShowScoringModal] = useState(false);
    const {
        scoring,
        sexo,
        fechaNacimiento
    } = formField;

    const opcionesSexo = [
        {value: '1', label: 'Femenino'},
        {value: '2', label: 'Masculino'}
    ];

    return (
        <>
            <div className="automotor__formStep__container">
                <Grid gutterXs="xs" gutter="md" justify="center" align="center">
                    <Grid.Col xs={12} sm={6}>
                        <MantineNumberField
                            name={`${scoring.name}.${fechaNacimiento.name}`}
                            label={fechaNacimiento.label}
                            withAsterisk
                            notFormat
                            min=""
                        />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={5}>
                        <MantineSelectField
                            name={`${scoring.name}.${sexo.name}`}
                            label={sexo.label}
                            data={opcionesSexo}
                            withAsterisk
                        />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={1}>
                        <div className="automotor__scoringInfo__icon__container" onClick={() => setShowScoringModal(true)}>
                            <InformationFilledIcon className="automotor__scoringInfo__icon"/>
                        </div>
                    </Grid.Col>
                </Grid>
            </div>
            {showScoringModal &&
            <QuotationScoringModal setShow={setShowScoringModal}/>
            }
        </>
    );
}
