import React, {useRef, useState} from 'react';
import './MantineMonthFilter.css';
import {MonthPicker} from '@mantine/dates';
import {ReactComponent as DateIcon} from "../../../assets/icons/ic_calendar.svg"
import {FilterCard} from "../../FilterCard";
import useClickOutside from "../../../modules/utils/useClickOutside";
import dayjs from "dayjs";
import 'dayjs/locale/es';

function MantineMonthFilter(props) {
    const {placeholder, value, setValue} = props;
    const [displayFilter, setDisplayFilter] = useState(false);
    const ref = useRef();
    useClickOutside(ref, () => setDisplayFilter(!displayFilter));

    return (
        <div className="mantine__monthFilter__container">
            <div className="mantine__monthFilter__wrapper"/>

            <div className="mantine__monthFilter__field" onClick={() => setDisplayFilter(true)}>
                {value
                    ? <p className="mantine__monthFilter__text">{dayjs(value).locale("es").format("MMMM") }</p>
                    : <p className="mantine__monthFilter__text--placeholder">{placeholder}</p>}

                <DateIcon className="mantine__monthFilter__icon"/>
                {displayFilter &&
                <FilterCard ref={ref}>
                    <MonthPicker
                        allowDeselect
                        value={value}
                        onChange={setValue}
                        size="xs"
                        locale="es"
                        classNames={{
                            pickerControl: 'mantine__monthFilter__pickerControl'
                        }}
                    />
                </FilterCard>
                }
            </div>

        </div>
    );
}

export default MantineMonthFilter;
