import React from 'react';
import './QuotationButtonInfoModal.css';
import {InformationModal} from "../../../layouts/InformationModal";

function QuotationButtonInfoModal(props) {
    const {setShow} = props;
    return (
        <InformationModal
            closeAction={() => setShow(false)}
            title={"Aplicación de descuentos"}
            severity={"info"}
            body={
                <div className="quotScoModal__body">
                    <p className="quotScoModal__text">
                        Para aplicar descuentos con la compañía seleccionada, debe ingresar el descuento a aplicar y luego presionar el botón de cotizar.
                    </p>
                </div>
            }
        />
    );
}

export default QuotationButtonInfoModal;