import React, {useContext} from 'react';
import './ManagementCard.css';
import {LogoColumn} from "../ManagementTableComponents/LogoColumn";
import {ProductsColumn} from "../ManagementTableComponents/ProductsColumn";
import {DescriptionColumn} from "../ManagementTableComponents/DescriptionColumn";
import {RedesColumn} from "../ManagementTableComponents/RedesColumn";
import {EnlacesColumn} from "../ManagementTableComponents/EnlacesColumn";
import {DescargablesColumn} from "../ManagementTableComponents/DescargablesColumn";
import {ResumenColumn} from "../ManagementTableComponents/ResumenColumn";
import {ActionsColumn} from "../ManagementTableComponents/ActionsColumn";
import MasterContext from "../../../context/MasterContext";

function ManagementCard(props) {
    const {infoCard, setEditRow, setActiveTab, setModalOpen, key} = props;
    const {masterPolkistas} = useContext(MasterContext);
    const fullName = masterPolkistas[infoCard['id_polkista']]['datos_polkista']['NombreCompleto'];

    return (
        <div className="MCard__wrapper" key={key}>
            <div className="MCard__contentContainer">
                <LogoColumn row={infoCard} setModalOpen={setModalOpen}/>
                <div className="MCard__modalLink">
                    <p className="MCard__name">{fullName}</p>
                    <ProductsColumn row={infoCard} setModalOpen={setModalOpen}/>
                    <DescriptionColumn row={infoCard} setModalOpen={setModalOpen}/>
                    <RedesColumn row={infoCard} setModalOpen={setModalOpen}/>
                    <EnlacesColumn row={infoCard} setModalOpen={setModalOpen}/>
                    <DescargablesColumn row={infoCard} setModalOpen={setModalOpen}/>
                    <ResumenColumn row={infoCard} setModalOpen={setModalOpen}/>
                </div>
                <div className="MCard__buttons">
                    <ActionsColumn
                        row={infoCard}
                        setEditRow={setEditRow}
                        setActiveTab={setActiveTab}
                    />
                </div>
            </div>
        </div>
    );
}

export default ManagementCard;