import { MantineInputField } from "../../../../../../../components/FormFields/MantineInputField";
import isValidDni from "../../../../../../../modules/utils/isValidDni";
import { Button } from "../../../../../../../components/Button";
import { PopUpCardModal } from "../../../../../../../layouts/PopUpCardModal";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useCurrentQuotationContext } from "../../../../../../../context/CurrentQuotationContext/CurrentQuotationContext";
import handleAdvanceRequotation from "../../../../../../Cotizacion/utils/handleAdvanceRequotation";
import { useFormikContext } from "formik";
import startEmissionSteps from "../../../../../../Cotizacion/QuotationResponseTab/utils/startEmissionSteps";
import CotizarContext from "../../../../../../../context/CotizarContext";

const renderModalButtons = (confirmEmitir, setConfirmEmitir, emitir, loadingByNewClient, newClientCheck, dni) => {

    if (confirmEmitir) {
        return (
            <div className="advancedForm__button__container">
                <Button
                    type="button"
                    enabled
                    color="primary"
                    variant="filled"
                    onClick={() => setConfirmEmitir(null)}
                >
                    Atrás
                </Button>
                <Button
                    type="button"
                    enabled
                    color="primary"
                    variant="filled"
                    onClick={() => {
                        setConfirmEmitir(null);
                        emitir();
                    }}
                >
                    Continuar
                </Button>
            </div>
        );
    } else {
        return (
            <Button
                type="button"
                enabled
                loading={loadingByNewClient}
                color="primary"
                variant="filled"
                onClick={() => newClientCheck(dni)}
            >
                Emitir
            </Button>
        );
    }
};

const RequestClientDniModal = (props) => {
    const { submitForm, setRequestClientDni } = props;
    const { cotizacionResponse, setCotizacionResponse, discounts, setPrevDiscounts, setUpdatingForm, setCurrentFormData, formData } = useCurrentQuotationContext();
    const { companiesDict } = useContext(CotizarContext);
    const { values } = useFormikContext();
    const [loadingByNewClient, setLoadingByNewClient] = useState(false);
    const [newClientError, setNewClientError] = useState(null);
    const [confirmEmitir, setConfirmEmitir] = useState(null);
    const [dniError, setDniError] = useState(null);

    const newClientCheck = (dniCliente) => {
        if (isValidDni(dniCliente)) {
            setLoadingByNewClient(true);
            setNewClientError(null);
            handleAdvanceRequotation(setCotizacionResponse, 'Federacion_Patronal', values, discounts, submitForm, cotizacionResponse, setPrevDiscounts, true, setLoadingByNewClient);
        }
    };

    const Buttons = useMemo(() => renderModalButtons(confirmEmitir, setConfirmEmitir, () => {
        startEmissionSteps(5, companiesDict, setCurrentFormData, formData, setUpdatingForm);
    }, loadingByNewClient, newClientCheck, values.configuracionAvanzada.Federacion_Patronal.dniCliente), [confirmEmitir, loadingByNewClient, values]);



    useEffect(() => {
        if (isValidDni(values.configuracionAvanzada.Federacion_Patronal.dniCliente) && cotizacionResponse?.Federacion_Patronal) {
            setNewClientError(null);
            if (cotizacionResponse?.Federacion_Patronal?.status_code) {
                setNewClientError("El cliente no cuenta con éste beneficio");
                setDniError(" ");
            } else if (cotizacionResponse?.Federacion_Patronal?.productor_asignado) {
                setConfirmEmitir("El cliente pertenece a otro productor.");
                setDniError(null);
                setNewClientError("");
            } else {
                startEmissionSteps(5, companiesDict, setCurrentFormData, formData, setUpdatingForm);
            }
        }
    }, [cotizacionResponse]);

    return (
        <PopUpCardModal
            title={<h1 className="csm__title">Ingresá el DNI de tu cliente</h1>}
            body={
                <div className="csm__popupBody">
                    <MantineInputField
                        name={`configuracionAvanzada.Federacion_Patronal.dniCliente`}
                        label="DNI del cliente"
                        withAsterisk
                        errorMessage={dniError}
                        onBlurAction={(dni) => {
                            if (!isValidDni(dni)) {
                                setDniError("El DNI ingresado no es válido");
                            }
                        }}
                    />
                </div>
            }
            footer={
                <div className="csm__popupFooter">
                    {confirmEmitir && <p className="RCDM__confirmText">
                        {confirmEmitir}
                        <br/>
                        ¿Desea continuar?
                    </p>}
                    {Buttons}
                    {newClientError && <p className="csm__popupError">{newClientError}</p>}
                </div>
            }
            closeAction={() => {
                setRequestClientDni(false);
                setDniError(null);
                setNewClientError(null);
            }}
        />
    );
};

export default RequestClientDniModal;
