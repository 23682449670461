import {BoxDialog} from "../../../components/BoxDialog";
import "./AcademyDropdown.css"
import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {ReactComponent as PlayIcon} from "../../../assets/icons/ic_play.svg";
import {ReactComponent as SearchIcon} from "../../../assets/icons/ic_search.svg";
import {ReactComponent as ArrowRightIcon} from "../../../assets/icons/ic_arrow-right.svg";
import UserContext from "../../../context/UserContext";
import {useHistory} from "react-router-dom";
import {SmallLoading} from "../../../components/SmallLoading";
import AuthContext from "../../../context/AuthContext";
import {DropdownSearchField} from "./DropdownSearchField";


function AcademyDropdown(props) {
    const {setDisplay} = props;
    const history = useHistory();
    const {courses, setCourses, lastCourses, setLastCourses} = useContext(UserContext);
    const [courseSelected] = useState("");
    const {authToken} = useContext(AuthContext)

    async function getCursos() {
        let url = process.env.REACT_APP_BACK_SERVER + '/getDatosCursos';
        axios.post(url, [], {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        }).then((resp) => {
            if (courses.length === 0) {
                const allCourses = Object.values(resp.data).flat();
                const labeledCourses = allCourses.map(course => {
                    return {...course, label: course.Titulo, value: course.id_Curso, group: course.Modulo}
                })
                setCourses(labeledCourses);
            }
            if (lastCourses.length === 0) {
                const coursesOrdered = courses.sort((a, b) => b.value - a.value);
                setLastCourses(coursesOrdered.slice(0, 2));
            }

        });
    }

    useEffect(() => {
        if (lastCourses.length === 0 || courses.length === 0) {
            getCursos();
        }
    }, [lastCourses, courses]);

    const handleCourseSelected = (courseId) => {
        if (courseId) {
            const course = courses.find(course => course.id_Curso === courseId);
            history.push({
                pathname: '/u/academia/cursos/' + String(course.id_Curso).padStart(2, '0'),
                state: {curso: course}
            })
            setDisplay(false);
        }
    }

    return (
        <div className="ADropdown__boxContainer">
            <BoxDialog withArrow setDisplay={setDisplay} arrowPosition="center" color="tertiary">
                <div className="ADropdown__container">
                    <div>
                        <DropdownSearchField value={courseSelected} setValue={handleCourseSelected}
                                             data={courses}
                                             searchable icon={<SearchIcon className="ADropdown__searchIcon"/>}
                                             styles={() => ({
                                                 wrapper: {
                                                     border: "1px solid #FFFFFF",
                                                     borderRadius: "180px",
                                                 },
                                                 input: {
                                                     backgroundColor: "transparent",
                                                     border: "1px solid #FFFFFF",
                                                     borderRadius: "180px",
                                                     color: "#FFFFFF",
                                                 }
                                             })}/>
                    </div>
                    <div className="ADropdown__lastCoursesContainer">
                        <div className="ADropdown__lastCoursesTitle"><p
                            className="ADropdown__lastCoursesTitle--text">Últimos Cursos</p>
                            <span onClick={() => {
                                history.push('/u/academia')
                                setDisplay(false);
                            }} className="ADropdown__lastCoursesTitle--clickable">Ver más <ArrowRightIcon
                                className="ADropdown__arrowRightIcon"/></span></div>
                        {lastCourses.length < 3 && lastCourses.length !== 0 ?
                            <div className="ADropdown__lastCoursesPreviewContainer">
                                {lastCourses.map((curso) => (
                                    <div key={curso.id_Curso}>
                                        <div className="ADropdown__lastCourseImageContainer" onClick={() => {
                                            history.push({
                                                pathname: '/u/academia/cursos/' + String(curso.value).padStart(2, '0'),
                                                state: {curso: curso}
                                            })
                                            setDisplay(false);
                                        }}><img
                                            className="ADropdown__lastCourseImage" src={curso.Logo} alt={curso.Titulo}/>
                                            <PlayIcon className="ADropdown__playIcon"/>
                                        </div>
                                        <p className="ADropdown__lastCourseName">{curso.Titulo}</p>
                                    </div>
                                ))}
                            </div> :
                            <div className="ADropdown__loadingLastCourses">
                                <SmallLoading/>
                            </div>}
                    </div>
                </div>
            </BoxDialog>
        </div>
    );
}

export default AcademyDropdown;