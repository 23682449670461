import * as Yup from 'yup';
import checkoutFormModel from './formModel';
import {CardNumberValidator} from "../../../../utils/cardNumberValidator";
const moment = require('moment');

const {
    formField: {
        vigenciaDesde,
        formaDePago,
        infoDePago: {
            numeroCbu,
            marcaTarjeta,
            numeroTarjeta,
        },
        cliente: {
            rol,
            personaJuridica,
            dni,
            cuit,
            nombre,
            apellido,
            fechaNacimiento,
            estadoCivil,
            sexo,
            domicilio,
            codigosLocalidad,
            codigoArea,
            telefono,
            email,
            situacionIva,
            categoriaMonotributo,
            actividadPrincipal,
            lugarNacimiento
        },
        detalleEquipoElectronico: {
            sumaAseguradaElectronico,
            descripcionElectronico,
        },
        detalleBicicletas: {
            sumaAseguradaBicicletas,
            descripcionBicicletas,
        },
        comentariosSolicitud
    }
} = checkoutFormModel;
let tipoTarjeta;
let equipoElectronicoSelected = false;
let bicicletasSelected = false;

export default [
    Yup.object().shape({
        //DummyValue for trigger callback method
        dummyValue: Yup.string(),
        detalleBicicletas: Yup.array().of(
            Yup.object().shape({
                [sumaAseguradaBicicletas.name]: Yup.number()
                    .integer()
                    .typeError("Introduzca únicamente números")
                    .when("dummyValue", {
                        is: () => {
                            return bicicletasSelected === true
                        },
                        then: Yup.number()
                            .required(`${sumaAseguradaBicicletas.requiredErrorMsg}`)
                            .typeError("Introduzca únicamente números")
                            .test('notZeroInsuredSum', "La suma debe ser superior a cero", (value) => value > 0)
                    }),
                [descripcionBicicletas.name]: Yup.string()
                    .matches(/^[A-Za-z0-9 ,-]+$/, "Ingrese una descripción válida")
                    .when("dummyValue", {
                        is: () => {
                            return bicicletasSelected === true
                        },
                        then: Yup.string().required(`${descripcionBicicletas.requiredErrorMsg}`)
                    }),
            })).test('insuredSumsAgreggationBicicletas', "Las sumas declaradas deben coincidir con las aseguradas", (sumas, context) => {
                const coberturas = context.parent.coberturas;
                let sumaAsegurada = 0;
                bicicletasSelected = false
                coberturas.map((cob) => {
                    if (cob.numeroCobertura === '48') {
                        bicicletasSelected = true
                        sumaAsegurada = cob.sumaAsegurada
                    } else return true
                })

                let totalInsuredValue = 0;
                sumas.map((item) => {
                    if (item.sumaAseguradaBicicletas !== undefined)
                        totalInsuredValue += parseInt(item.sumaAseguradaBicicletas.toString().replace(/\./g, ""));
                })

                return (sumaAsegurada === totalInsuredValue)
            }
        ),
        detalleEquipoElectronico: Yup.array().of(
            Yup.object().shape({
                [sumaAseguradaElectronico.name]: Yup.number()
                    .integer()
                    .typeError("Introduzca únicamente números")
                    .when("dummyValue", {
                        is: () => {
                            return equipoElectronicoSelected === true
                        },
                        then: Yup.number()
                            .required(`${sumaAseguradaElectronico.requiredErrorMsg}`)
                            .typeError("Introduzca únicamente números")
                            .test('notZeroInsuredSum', "La suma debe ser superior a cero", (value) => value > 0)
                    }),
                [descripcionElectronico.name]: Yup.string()
                    .matches(/^[A-Za-z0-9 ,-]+$/, "Ingrese una descripción válida")
                    .when("dummyValue", {
                        is: () => {
                            return equipoElectronicoSelected === true
                        },
                        then: Yup.string().required(`${descripcionElectronico.requiredErrorMsg}`)
                    }),
            })).test('insuredSumsAgreggationElectrónicos', "Las sumas declaradas deben coincidir con las aseguradas", (sumas, context) => {
                const coberturas = context.parent.coberturas;
                let sumaAsegurada = 0;
                equipoElectronicoSelected = false
                coberturas.map((cob) => {
                    if (cob.numeroCobertura === '26') {
                        equipoElectronicoSelected = true
                        sumaAsegurada = cob.sumaAsegurada
                    } else return true
                })

                let totalInsuredValue = 0;
                sumas.map((item) => {
                    if (item.sumaAseguradaElectronico !== undefined)
                        totalInsuredValue += parseInt(item.sumaAseguradaElectronico.toString().replace(/\./g, ""));
                })

                return (sumaAsegurada === totalInsuredValue)
            }
        ),
    }),
    Yup.object().shape({
        [vigenciaDesde.name]: Yup.date()
            .required(`${vigenciaDesde.requiredErrorMsg}`)
            .min(moment().format('YYYY-MM-DD'), "La fecha no puede ser anterior al dia de hoy")
            .max(moment().add(45, "days").format('YYYY-MM-DD'), "La fecha no puede superar los 45 dias"),

        [formaDePago.name]: Yup.string().required(`${formaDePago.requiredErrorMsg}`),
        infoDePago: Yup.object().when([formaDePago.name], {
            is: '4',    // Débito por CBU
            then: Yup.object().shape({
                [numeroCbu.name]: Yup.string()
                    .required(`${numeroCbu.requiredErrorMsg}`)
                    .matches(/^[0-9]+$/, "Ingrese un número válido")
                    .min(22, 'El número de CBU debe tener 22 dígitos')
                    .max(22, 'El número de CBU debe tener 22 dígitos'),
            })
        }).when([formaDePago.name], {
            is: '2',    // Tarjeta de crédito
            then: Yup.object().shape({
                [marcaTarjeta.name]: Yup.string().test('cardType', 'Seleccione un tipo de tarjeta', type => {
                    tipoTarjeta = type;
                    if (tipoTarjeta !== null) return true;
                }).required(`${marcaTarjeta.requiredErrorMsg}`),
                [numeroTarjeta.name]: Yup
                    .string()
                    .test('cardNumber',
                        'El número de tarjeta ingresado es inválido', //validation message
                        value => {
                            if (tipoTarjeta === "9") {
                                if (value !== undefined && value.length === 15)
                                    return CardNumberValidator(tipoTarjeta, value)
                            } else {
                                if (value !== undefined && value.length === 16)
                                    return CardNumberValidator(tipoTarjeta, value)
                            }
                        })
                    .required()
            })
        }),
    }),
    Yup.object().shape({
        clientes: Yup.array().of(
            Yup.object().shape({
                [rol.name]: Yup.string().required(`${rol.requiredErrorMsg}`),
                [dni.name]: Yup.number().when([personaJuridica.name], {
                    is: '0',
                    then: Yup.number()
                        .required(`${dni.requiredErrorMsg}`)
                        .min(1000000, "El documento ingresado no es válido")
                        .max(99999999, "El documento ingresado no es válido")
                        .integer()
                        .typeError("Introduzca únicamente números"),
                }),
                [cuit.name]: Yup.string()
                    .required(`${cuit.requiredErrorMsg}`)
                    .matches(/^(20|23|27|30|33)([0-9]{9}|-[0-9]{8}-[0-9]{1})$/, "El CUIT ingresado no es válido"),
                [nombre.name]: Yup.string().when([personaJuridica.name], {
                    is: '0',
                    then: Yup.string()
                        .required(`${nombre.requiredErrorMsg}`)
                        .matches(/^[aA-zZ\s]+$/, "Ingrese un nombre válido")
                        .max(40, "El nombre ingresado es muy largo"),
                }),
                [apellido.name]: Yup.string()
                    .required(`${apellido.requiredErrorMsg}`)
                    .matches(/^[aA-zZ\s]+$/, "Ingrese un apellido válido")
                    .max(40, "El valor ingresado es muy largo"),
                [fechaNacimiento.name]: Yup.date().when([personaJuridica.name], {
                    is: '0',
                    then: Yup.date()
                        .max(new Date(Date.now() - 567648000000), "El cliente debe tener al menos 18 años para contratar")
                        .required(`${fechaNacimiento.requiredErrorMsg}`),
                }),
                [estadoCivil.name]: Yup.string().when([personaJuridica.name], {
                    is: '0',
                    then: Yup.string().required(`${estadoCivil.requiredErrorMsg}`),
                }),
                [sexo.name]: Yup.string().when([personaJuridica.name], {
                    is: '0',
                    then: Yup.string().required(`${sexo.requiredErrorMsg}`),
                }),
                [domicilio.name]: Yup.string()
                    .required(`${domicilio.requiredErrorMsg}`)
                    .matches(/^[A-Za-z0-9 .-]+$/, "Esta utilizando carácteres no válidos"),
                [codigosLocalidad.name]: Yup.string().required(`${codigosLocalidad.requiredErrorMsg}`),
                [codigoArea.name]: Yup.number()
                    .required(`${codigoArea.requiredErrorMsg}`)
                    .integer()
                    .min(10, 'Introduzca un código de área valido')
                    .max(9999, 'Introduzca un código de área valido')
                    .typeError("Introduzca únicamente números")
                    .test(
                        'no-leading-zero',
                        'Por favor ingrese el código de área sin 0',
                        (value, context) => {
                            return context.originalValue && !context.originalValue.startsWith('0');
                        }
                    ),
                [telefono.name]: Yup.number()
                    .required(`${telefono.requiredErrorMsg}`)
                    .integer()
                    .min(100000, 'Introduzca un número de celular valido')
                    .max(99999999, 'Introduzca un número de celular valido')
                    .typeError("Introduzca únicamente números")
                    .test(
                        'no-leading-zero',
                        'Por favor introduzca su número sin el 15',
                        (value, context) => {
                            return context.originalValue && !context.originalValue.startsWith('15');
                        }
                    ),
                [email.name]: Yup.string()
                    .required(`${email.requiredErrorMsg}`)
                    .email("Ingrese un email válido"),
                [situacionIva.name]: Yup.string().required(`${situacionIva.requiredErrorMsg}`),
                [categoriaMonotributo.name]: Yup.string().required(`${categoriaMonotributo.requiredErrorMsg}`),
                [actividadPrincipal.name]: Yup.string().when([personaJuridica.name], {
                    is: '0',
                    then: Yup.string().required(`${actividadPrincipal.requiredErrorMsg}`),
                }),
                [lugarNacimiento.name]: Yup.string().when([personaJuridica.name], {
                    is: '0',
                    then: Yup.string()
                        .required(`${lugarNacimiento.requiredErrorMsg}`)
                        .matches(/^([A-Za-z0-9 -]+)$/, "Esta utilizando carácteres no válidos"),
                }),
            })
        )
    }),
    Yup.object().shape({
        [comentariosSolicitud.name]: Yup.string().matches(/^(?=[a-zA-Z0-9()_+=,.:\s-]*$)/, "Por favor, evite caracteres especiales"),
    }),
    Yup.object().shape({})
];
