import React from 'react';
import './RoundedRectangle.css';

function RoundedRectangle(props) {
    const {children, variant, colorVar} = props;
    return (
        <div className={`RRectangle--${variant} RRectangle--${colorVar}`}>
            {children}
        </div>
    );
}

export default RoundedRectangle;