import "./DetailsTargetCoverage.css";
import {currencyFormat} from "../../../../modules/utils/currencyFormat";

function DetailsTargetCoverage(props) {
    const {name, addition, country, prize} = props;
    return (
        <div className="DTCoverage__container">
            <p className="DTCoverage__title">
                {name}
            </p>
            {addition && addition > 0 && <p
                className="DTCoverage__detail">Suma: <span
                className="DTCoverage__detaill--value">${currencyFormat(Math.round(addition))}</span>
            </p>
            }
            {country && country !== '' && <p
                className="DTCoverage__detail">país mas elegido: <span
                className="DTCoverage__detaill--value">{country}</span>
            </p>
            }
            <p
                className="DTCoverage__detail">Premio: <span
                className="DTCoverage__detaill--value">${currencyFormat(Math.round(prize))}</span>
            </p>
        </div>
    );
}

export default DetailsTargetCoverage;