import React, {useCallback, useState} from 'react';
import {Button} from "../../../../components/Button";
import {PopUpCardModal} from "../../../../layouts/PopUpCardModal";
import ReactCrop from 'react-image-crop';
import "./CropImage.css"

function CropImage(props) {
    const {crop, setCrop, setOpenDialog, srcImage, handleUpload, imgRef, setCroppedImageUrl, srcFile, loading = null} = props;
    const [firstStep, setFirstStep] = useState(true);
    const [completedCrop, setCompletedCrop] = useState(null);
    const [cropPreview, setCropPreview] = useState(null);

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    function getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );

        return new Promise((resolve) => {
            canvas.toBlob(blob => {
                blob.name = fileName;
                resolve(blob);
            }, 'image/png, image/jpeg', 1);
        });
    }

    const cropToBase64 = async () => {
        const croppedImg = await getCroppedImg(imgRef.current, completedCrop, `image.${srcFile.name.split('.')[1]}`);
        convertBase64(croppedImg).then((base64) => {
            setCropPreview(base64);
        });
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setCrop({
            "unit": "px",
            "x": 0,
            "y": 0,
            "aspect": 1,
            "width": 0,
            "height": 0
        });
    };

    const handleContinueButton = () => {
        cropToBase64();
        setFirstStep(false)
    }

    const handleUploadButton = () => {
        handleUpload(cropPreview);
        setCroppedImageUrl(cropPreview);
    }

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    return (
        <PopUpCardModal
            title={firstStep ? "Recorte su imagen" : "Subir imagen"}
            closeAction={handleCloseDialog}
            body={firstStep ?
                <div className="CImage__cropContainer">
                    {srcImage && (
                        <div className="CImage__popUpContent">
                            <ReactCrop
                                src={srcImage}
                                crop={crop}
                                onImageLoaded={onLoad}
                                onComplete={(c) => {
                                    setCompletedCrop(c)
                                }}
                                onChange={(c) => {
                                    setCrop(c)
                                }}
                            />
                            <p className="CImage__tutorialText">Arrastre sobre la imagen para seleccionar y
                                recortar la zona deseada.</p>
                        </div>
                    )}
                </div>
                :
                <div className="CImage__cropContainer">
                    <img src={cropPreview} alt="Logo"
                         className="LForm__logoSelected"/>
                </div>
            }
            footer={firstStep ?
                <div className="CImage__popUpButtons">
                    <Button onClick={handleCloseDialog} variant="outlined" color="primary" enabled>
                        Cerrar
                    </Button>
                    <div className="CImage__uploadButton">
                        <Button onClick={handleContinueButton} variant="filled" color="primary"
                                enabled={crop.height !== 0} type="button">
                            Continuar
                        </Button>
                    </div>
                </div>
                :
                <div className="CImage__popUpButtons">
                    <Button onClick={handleCloseDialog} variant="outlined" color="primary" enabled>
                        Cancelar
                    </Button>
                    <div className="CImage__uploadButton">
                        <Button onClick={handleUploadButton} variant="filled" color="primary"
                                enabled={crop.height !== 0} type="button" loading={loading}>
                            Subir
                        </Button>
                    </div>
                </div>
            }
        />

    );
}

export default CropImage;