import React, {useContext} from 'react';
import './NewChannelButton.css';
import {Menu} from "@mantine/core";
import MasterContext from "../../../../context/MasterContext";
import {ReactComponent as PlusFilled} from "../../../../assets/icons/ic_plus_filled-primary.svg";
import showNotification from "../../../../modules/utils/showNotification";

function NewChannelButton(props) {
    const {setIdPolkistaToCreate, setEditRow, setActiveTab, isMobile} = props;
    const {masterPolkistas, availableCredits} = useContext(MasterContext);

    const redirectToDesign = () => {
        setEditRow(null)
        setActiveTab(1)
    }

    const handleAdd = (idPolkista) => {
        if (availableCredits <= 0) {
            showNotification({
                autoClose: 4000,
                color: 'yellow',
                status: 'warning',
                title: 'No tiene créditos disponibles.',
                message: `Para agregar créditos y crear un nuevo canal digital póngase en contacto con un asistente de Polko.`
            });
        } else {
            setIdPolkistaToCreate(idPolkista)
            redirectToDesign()
        }
    };

    return (
        <Menu shadow="md" position="top"
              classNames={{dropdown: "NCButton__menuDropdown"}}
        >
            <Menu.Target>
                {isMobile ?
                    <PlusFilled className="ManagementTab__plusIcon"/>
                    :
                    <button color="primary" className="ManagementTab__addButton">
                        Agregar un nuevo canal
                    </button>
                }
            </Menu.Target>

            <Menu.Dropdown>
                {Object.keys(masterPolkistas).map((id) => {
                    return (
                        <Menu.Item key={id}
                                   onClick={() => handleAdd(id)}
                        >
                            {masterPolkistas[id]['datos_polkista']['NombreCompleto']}
                        </Menu.Item>
                    )
                })}
            </Menu.Dropdown>
        </Menu>);
}

export default NewChannelButton;