import showNotification from "./showNotification";

const copyToClipboard = async (textToCopy) => {
    try {
        await navigator.clipboard.writeText(textToCopy).then(() => {
            showNotification({
                autoClose: 3000,
                color: 'green',
                status: 'success',
                title: 'Link de pago copiado en el portapapeles',
            });
        });
    } catch (err) {
        showNotification({
            autoClose: 3000,
            color: 'red',
            status: 'error',
            title: 'No se pudo copiar el link de pago',
        })
    }
};

export default copyToClipboard