const documentList = [
    { label: "DNI Frente", value: "DNI FRENTE", used: false },
    { label: "DNI Atrás", value: "DNI ATRAS", used: false },
    { label: "Constancia de AFIP", value: "CONSTANCIA AFIP", used: false },
    { label: "Constancia impuesto provincial", value: "CONSTANCIA IMPUESTO PROVINCIAL", used: false },
    { label: "Credencial CIPAS", value: "CREDENCIAL CIPAS", used: false },
    { label: "Constancia CBU", value: "CONSTANCIA CBU", used: false },
    { label: "Pago de matrícula", value: "PAGO DE MATRICULA", used: false },
    { label: "Rúbrica digital", value: "RUBRICA DIGITAL", used: false },
    { label: "Constancia de inscripción UIF", value: "CONSTANCIA INSCRIPCION UIF", used: false },
];

export default documentList;