import {FormCard} from "../FormCard";
import "./HighlightedTitleModal.css"

function HighlightedTitleModal(props) {
    const {children, titleText} = props;

    return (
        <FormCard>
            <div className="HTModal__title">{titleText}</div>
            {children}
        </FormCard>
    );
}

export default HighlightedTitleModal;