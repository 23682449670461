import React, {useContext, useState} from 'react';
import "./ProductOptions.css";
import {IconBike, IconCar, IconHome, IconMotorbike, IconLuggage} from "@tabler/icons";
import {ReactComponent as SancorSVG} from "../../../../assets/logos/sancor_svg.svg";
import {ReactComponent as RUSSVG} from "../../../../assets/logos/rus_svg.svg";
import {ReactComponent as ZurichSVG} from "../../../../assets/logos/zurich_svg.svg";
import {ReactComponent as TerraWindSVG} from "../../../../assets/logos/terrawind_black.svg";
import {ReactComponent as FederacionPatronalSVG} from "../../../../assets/logos/federacion_patronal_logo.svg";
import MasterContext from "../../../../context/MasterContext";
import {getCompaniesByProduct} from "../../../../modules/utils/getCompaniesByProduct";
import {CarouselWrapper} from "../../../../components/CarouselWrapper";
import UserContext from "../../../../context/UserContext";
import {Badge} from "../../../../components/Badge";

const productIconsDict = {
    "vehiculo": <IconCar className="POptions__icon"/>,
    "motovehiculo": <IconMotorbike className="POptions__icon"/>,
    "hogar": <IconHome className="POptions__icon"/>,
    "microseguros": <IconBike className="POptions__icon"/>,
    "asistencia_viajero": <IconLuggage className="POptions__icon"/>,
}

const productNamesDict = {
    'vehiculo': 'Automotor',
    'motovehiculo': 'Motovehículo',
    'hogar': 'Hogar',
    'microseguros': 'Microseguros',
    'asistencia_viajero': 'Asistencia al viajero',
}

const PRODUCT_RENDER_ORDER = ['vehiculo', 'motovehiculo', 'hogar', 'microseguros', 'asistencia_viajero'];

const logoBuilder = (company, selected) => {
    if (company['group'] === "Sancor") {
        return (
            <>
                <SancorSVG className={selected
                    ? "POptions__company__logo POptions__company--sancor"
                    : "POptions__company__logo"
                }/>
                <p className="POptions__company__code">{company['label']}</p>
            </>
        )
    } else if (company['group'] === "RUS") {
        return (
            <>
                <RUSSVG className={selected
                    ? "POptions__company__logo POptions__company--rus"
                    : "POptions__company__logo"
                }/>
                <p className="POptions__company__code">{company['label']}</p>
            </>

        )
    } else if (company['group'] === "Zurich") {
        return (
            <>
                <ZurichSVG className={selected
                    ? "POptions__company__logo POptions__company--zurich"
                    : "POptions__company__logo"
                }/>
                <p className="POptions__company__code">{company['label']}</p>
            </>
        )
    }
    else if (company['group'] === "TerraWind") {
        return (
            <>
                <TerraWindSVG className={selected
                    ? "POptions__company__logo POptions__company--terrawind"
                    : "POptions__company__logo"
                }/>
            </>
        )
    }
    else if (company['group'] === "Federacion_Patronal") {
        return (
            <>
                <FederacionPatronalSVG className={selected
                    ? "POptions__company__logo"
                    : "POptions__company__logo --federacionPatronal-disabled"
                }/>
            </>
        )
    }
}

function ProductOptions(props) {
    const {idPolkista, products, setProducts, edit = true} = props;
    const {companiesDict, codesToShareOptions, masterPolkistas} = useContext(MasterContext);
    const {isMobile} = useContext(UserContext);
    // currentEditingUserCompanies is an array of strings
    // with the names of the companies of the user being edited / created, not the master
    const currentEditingUserCompanies = masterPolkistas[idPolkista]['codigos_aseguradoras'].map((company) => {
        return companiesDict[company['id_aseguradora']]['name']
    });

    //Reemplazar userCompanies por las companias particulartes del usuario en creacion
    const companiesByProduct = getCompaniesByProduct(companiesDict, currentEditingUserCompanies, codesToShareOptions);
    const filteredOrder = PRODUCT_RENDER_ORDER.filter(product => companiesByProduct.hasOwnProperty(product));
    const [productClicked, setProductClicked] = useState([]);

    //Current product being modified
    const [productHovered, setProductHovered] = useState(null);

    const handleActivateProduct = (productKey) => {
        if (!products[productKey]) return;
        const product = products[productKey];

        if (edit) {
            setProductClicked([...productClicked, productKey]);
            return;
        }
        if (productClicked.includes(productKey)) return;

        const newActivatedProducts = {
            ...products,
            [productKey]: {
                ...product,
                idCodigosAseguradoras: product.active ? [] : companiesByProduct[productKey].map((company) => company['value']),
                active: !product.active,
            },
        };
        setProductClicked([...productClicked, productKey]);
        setProducts(newActivatedProducts);
    };

    const handleProductClick = (productKey) => {
        setProductHovered(productKey);
        handleActivateProduct(productKey);
    };

    /**
     * Handles the click on a company, it updates the products state so
     * the idCodigoAseguradora is attached to the product. If there is
     * another code of the same company in another product, it is replaced.
     *  -Only one code per company is allowed across all products.
     *  Cases description:
     *      Lo que ocurre en el producto actual
     *          Si esta agregando
     *              Si el producto actual ya tiene un codigo de la misma compañia --> Reemplazarlo
     *              Si no --> Agregarlo
     *          Si esta quitando --> Quitarlo
     *      Lo que ocurre en los demas productos
     *          Si esta agregando && si el otherProductKey ya tiene un codigo de la misma compañia --> Reeplazarlo
     * @param productKey
     * @param idCodigoAseguradora
     */
    const handleCompanyClick = (productKey, idCodigoAseguradora) => {
        if (!products[productKey]) return;

        const currentProduct = products[productKey];
        const isAddingIdCodigoAseguradora = !currentProduct['idCodigosAseguradoras'].includes(parseInt(idCodigoAseguradora));

        let newIdCodigosAseguradoras = [];
        let newActivatedProducts = {...products};

        // Lo que ocurre en el producto actual
        // Si esta agregando
        if (isAddingIdCodigoAseguradora) {
            let hasCodeOfSameCompany = false;

            // Calculo de la condición
            const groupOfIdCodigoAseguradoraToAdd = codesToShareOptions.find((el) => parseInt(el['value']) === parseInt(idCodigoAseguradora))['group'];
            currentProduct['idCodigosAseguradoras'].forEach((idCodAs) => {
                const groupOfCode = codesToShareOptions.find((el) => parseInt(el['value']) === parseInt(idCodAs))['group'];
                if (groupOfCode === groupOfIdCodigoAseguradoraToAdd) {
                    hasCodeOfSameCompany = true;
                }
            });

            // Si el producto actual ya tiene un codigo de la misma compañia
            if (hasCodeOfSameCompany) { // --> Reemplazarlo
                newIdCodigosAseguradoras = [
                    ...currentProduct['idCodigosAseguradoras'].filter((value) => {
                        const group = codesToShareOptions.find((el) => parseInt(el['value']) === parseInt(value))['group'];
                        return group !== groupOfIdCodigoAseguradoraToAdd;
                    }),
                    idCodigoAseguradora
                ];
            } else { // --> Agregarlo
                newIdCodigosAseguradoras = [
                    ...currentProduct['idCodigosAseguradoras'],
                    idCodigoAseguradora
                ];
            }
        } else {
            // Si esta quitando --> Quitarlo
            newIdCodigosAseguradoras = [
                ...currentProduct['idCodigosAseguradoras'].filter((value) => value !== parseInt(idCodigoAseguradora))
            ];
        }

        newActivatedProducts = {
            ...newActivatedProducts,
            [productKey]: {
                ...currentProduct,
                idCodigosAseguradoras: newIdCodigosAseguradoras,
                active: newIdCodigosAseguradoras.length > 0,
            },
        };

        // Lo que ocurre en los demas productos
        Object.keys(products).forEach((otherProductKey) => {
            // Si esta agregando
            if (otherProductKey !== productKey && isAddingIdCodigoAseguradora) {
                const otherProduct = products[otherProductKey];
                newIdCodigosAseguradoras = [...otherProduct['idCodigosAseguradoras']];
                let hasCodeOfSameCompany = false;

                // Calculo de la condición
                const groupOfIdCodigoAseguradoraToAdd = codesToShareOptions.find((el) => parseInt(el['value']) === parseInt(idCodigoAseguradora))['group'];
                otherProduct['idCodigosAseguradoras'].forEach((idCodAs) => {
                    const foundElement = codesToShareOptions.find((el) => parseInt(el['value']) === parseInt(idCodAs));
                    if (foundElement) {
                        const groupOfCode = foundElement['group'];
                        if (groupOfCode === groupOfIdCodigoAseguradoraToAdd) {
                            hasCodeOfSameCompany = true;
                        }
                    }
                    else {
                        hasCodeOfSameCompany = false;
                    }
                });

                // Si el otherProductKey ya tiene un codigo de la misma compañia
                if (hasCodeOfSameCompany) { // --> Reemplazarlo
                    newIdCodigosAseguradoras = [
                        ...otherProduct['idCodigosAseguradoras'].filter((value) => {
                            const group = codesToShareOptions.find((el) => parseInt(el['value']) === parseInt(value))['group'];
                            if (group) return group !== groupOfIdCodigoAseguradoraToAdd;
                            else return true
                        }),
                        idCodigoAseguradora
                    ];
                }

                newActivatedProducts = {
                    ...newActivatedProducts,
                    [otherProductKey]: {
                        ...otherProduct,
                        idCodigosAseguradoras: newIdCodigosAseguradoras,
                        active: newIdCodigosAseguradoras.length > 0,
                    },
                };
            }
        })

        setProducts(newActivatedProducts);
    };

    const hasZurich = companiesByProduct.vehiculo.find((company) => company['group'] === "Zurich") !== undefined;

    return (
        <div className="POptions__body">
            <div className="POptions__row">
                <CarouselWrapper slideSize="auto" withControls={!isMobile} withDots={isMobile}>
                    {filteredOrder.map((product) => {
                            return (
                                <div
                                    className={products[product] && products[product].active ? "POptions__product__container" : "POptions__product__container--disabled"}
                                    onClick={() => handleProductClick(product)}
                                >
                                    {productIconsDict[product]}
                                    {product === "vehiculo" && hasZurich &&
                                        <div className="POptions__product--new">
                                            <Badge size="small" color="primary">NUEVO</Badge>
                                        </div>
                                    }
                                </div>
                            );
                    })}
                </CarouselWrapper>
            </div>
            {productHovered &&
                <>
                    <p className="POptions__text">Compañías disponibles para coberturas
                        de {productNamesDict[productHovered]}</p>
                    <div className="POptions__companies__container">
                        <CarouselWrapper slideSize="auto" withControls={!isMobile} withDots={isMobile}>
                            {companiesByProduct[productHovered].map((company) => {
                                //TODO: Fixing this requires a major refactor of codesToShareOptions
                                products[productHovered]['idCodigosAseguradoras'].forEach((idCodAs, index) => {
                                    products[productHovered]['idCodigosAseguradoras'][index] = parseInt(idCodAs);
                                })

                                return (
                                    <div onClick={() => handleCompanyClick(productHovered, company['value'])}>
                                        {logoBuilder(company, products[productHovered]['active'] && products[productHovered]['idCodigosAseguradoras'].includes(parseInt(company['value'])))}
                                    </div>
                                )
                            })}
                        </CarouselWrapper>
                    </div>
                </>
            }
            <p className="POptions__text--info">Selecciona un producto, luego haz click sobre las compañías
                que deseas habilitar</p>
        </div>


    );
}

export default ProductOptions;