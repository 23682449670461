import * as Yup from "yup";

const EntradaCotizarModel = () => {
    const formField = {
        idPais: {
            name: 'idPais',
            label: 'País de destino',
            requiredErrorMsg: 'Debe seleccionar un país'
        },
        nombrePais: {
            name: 'nombrePais',
        },
        vigenciaDesde: {
            name: 'vigenciaDesde',
        },
        vigenciaHasta: {
            name: 'vigenciaHasta',
            label: 'Salida/Regreso',
            requiredErrorMsg: 'Debe ingresar el periodo del viaje'
        },
        pasajeros: {
            name: 'pasajeros',
            label: 'Pasajeros',
            requiredErrorMsg: 'Debe ingresar al menos un pasajero'
        }
    }

    const initialValues = {
        [formField.idPais.name]: '',
        [formField.nombrePais.name]: '',
        [formField.vigenciaDesde.name]: '',
        [formField.vigenciaHasta.name]: '',
        [formField.pasajeros.name]: [{edad: ''}],
    }

    const validationSchema = Yup.object().shape({
        [formField.idPais.name]: Yup.string().required(`${formField.idPais.requiredErrorMsg}`),
        [formField.vigenciaHasta.name]: Yup.string().required(`${formField.vigenciaHasta.requiredErrorMsg}`),
        [formField.pasajeros.name]: Yup.array()
            .of(
                Yup.object().shape({
                    age: Yup.string()
                })
            )
            .required(`${formField.pasajeros.requiredErrorMsg}`)
            .test('al-menos-uno-no-vacio', 'Debe ingresar la edad de al menos un pasajero', (pasajeros) => {
                return pasajeros.some(pasajero => pasajero.age !== '');
            }),
    })

    return {formField, initialValues, validationSchema}
}

export {EntradaCotizarModel};
