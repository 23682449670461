import React from 'react';
import './Header.css';
import PolkoPrimary from '../../../assets/logos/polko_primary.png'
import {Button} from '../../../components/Button'
import {ReactComponent as BurguerIcon} from '../../../assets/icons/ic_menu.svg';
import {useAuth0} from "@auth0/auth0-react";

function LogoutButton() {
    const { logout } = useAuth0();
    return (
        <Button
            variant="outlined"
            color="secondary"
            marginX="sm"
            enabled
            onClick={() => logout({ returnTo: window.location.origin })}
        >
            Salir
        </Button>
    );
}

function Header({ setDrawerOpened, page = "landing" }) {
    const { loginWithRedirect } = useAuth0();
    const isLanding = page === "landing";

    return (
        <div className="landing__header__container">
            <img className="landing__header__logo" src={PolkoPrimary} alt="polko"/>
            <div className="landing__header__buttons">
                {isLanding && (
                    <>
                        <Button
                            variant="outlined"
                            color="secondary"
                            marginX="sm"
                            enabled
                            onClick={loginWithRedirect}
                        >
                            Registrarme
                        </Button>
                        <Button
                            variant="filled"
                            color="secondary"
                            marginX="sm"
                            enabled
                            onClick={loginWithRedirect}
                        >
                            Ingresar
                        </Button>
                    </>
                )}
                {!isLanding && <LogoutButton />}
            </div>
            <div className="landing__header__menu">
                {isLanding && <BurguerIcon className="landing__header__burguer" onClick={() => setDrawerOpened(true)} />}
                {!isLanding && <LogoutButton />}
            </div>
        </div>
    );
}

export default Header;