import React, { useContext } from 'react';
import StatisticsCard from "../../../components/StatisticsCard/StatisticsCard";
import { productsByCompany } from '../../../constants/auxPersonalData';
import UserContext from '../../../context/UserContext';
import '../../../pages/MisAseguradoras/MisAseguradoras.css';
import ButtonContainer from "./ButtonContainer/ButtonContainer";
import './MisAseguradorasResumen.css';

const MisAseguradorasResumen = () => {
    const { userCompanies, userCompaniesToPromote } = useContext(UserContext);

    const getProductosDisponibles = () => {
        let productosDisponibles = new Set();
        userCompanies.forEach(company => {
            if (productsByCompany[company]) {
                productsByCompany[company].forEach(product => {
                    productosDisponibles.add(product);
                });
            }
            return productosDisponibles;
        })
        return productosDisponibles.size;
    };

    const getProductosPromocion = () => {
        let productosPromocion = new Set();
        userCompaniesToPromote.forEach(company => {
            if (productsByCompany[company]) {
                productsByCompany[company].forEach(product => {
                    productosPromocion.add(product);
                });
            }
            return productosPromocion;
        })
        return productosPromocion.size;
    };

    const productosDisponibles = getProductosDisponibles();
    const productosPromocion = productosDisponibles + getProductosPromocion();

    const resumenItems = [
        {
            "title": "Aseguradoras con código",
            "value": userCompanies.length
        },
        {
            "title": "Aseguradoras sin código",
            "value": userCompaniesToPromote.length
        },
        {
            "title": "Productos disponibles",
            "value": productosDisponibles,
        },
        {
            "title": "Productos totales",
            "value": productosPromocion
        },
    ]

    return (
        <div className="MisAseguradoras__section">
            <div className="MisAseguradorasResumen__header">
                <h1 className="MisAseguradoras__title">
                    Resumen
                </h1>
                <ButtonContainer />
            </div>
            <div className="MisAseguradorasResumen__content">
                <div className="MisAseguradorasResumen__cardContainer">
                    {resumenItems.map((item, index) => {
                        return (
                            <div key={index} className="MisAseguradorasResumen__StatisticsCard--wrapper">
                                <StatisticsCard value={item.value} title={item.title} variant="flat" fullHeight />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}

export default MisAseguradorasResumen;