import React, {useState, useMemo, useContext, useRef} from 'react';
import PropTypes from 'prop-types';
import AuthContext from "../../AuthContext";
import getCarModels from "./services/getCarModels";
import getCarVersions from "./services/getCarVersions";
import AutomotorContext from "./AutomotorContext";
import handleJeepCase from "./services/handleJeepCase";

const AutomotorContextProvider = ({children, initialData}) => {
    const {marcas, coberturas} = initialData || {marcas: [], coberturas: []};
    const {authToken} = useContext(AuthContext);
    const anios = Array.from({length: 30}, (_, i) => ({value: (2024 - i).toString(), label: (2024 - i).toString()}));
    const [modelos, setModelos] = useState([]);
    const [versiones, setVersiones] = useState([]);
    const [isLoadingModels, setIsLoadingModels] = useState(false);
    const [isLoadingVersions, setIsLoadingVersions] = useState(false);
    const [modelsError, setModelsError] = useState(null);
    const [versionsError, setVersionsError] = useState(null);
    const vehicleDetails = useRef({
        detalle_marca: "",
        detalle_modelo: "",
        detalle_version: ""
    });

    const coberturasAutomotorNames = {
        "names": {
            "RC": "Responsabilidad civil",
            "IT": "Incendio total",
            "IP": "Incendio parcial",
            "RHT": "Robo y/o hurto total",
            "RHP": "Robo y/o hurto parcial",
            "AT": "Accidente total",
            "APD": "Accidente parcial según deducible",
            "LTP": "Lock out o tumulto popular",
            "GTI": "Granizo / Terremoto / Inundación total y parcial",
            "RCR": "Rotura de cristales",
            "RCE": "Rotura de cerraduras",
            "RRH": "Reposición de ruedas en caso de robo o hurto",
            "DPR": "Daños parciales al amparo del Robo Total",
            "CPL": "Extensión de Cobertura a países limítrofes",
            "AV": "Asistencia al vehículo",
            "SCS": "CLEAS (Sistema Compensación de Siniestros)",
        },
    }

    const updateModels = (marca, anio, resetValues) => {
        if (resetValues) {
            setModelos([])
            setVersiones([])
        }
        setIsLoadingModels(true);
        setModelsError(null);
        const realMarca = handleJeepCase(marca);
        getCarModels(authToken, realMarca, anio)
            .then(response => {
                setModelos(response.data);
            })
            .catch(error => {
                console.error('Error al actualizar modelos:', error);
                setModelsError(error);
            })
            .finally(() => {
                setIsLoadingModels(false);
            });
    };

    const updateVersions = (marca, anio, modelo, resetValues) => {
        if (resetValues) setVersiones([]);
        setIsLoadingVersions(true);
        setVersionsError(null);
        const realMarca = handleJeepCase(marca);
        getCarVersions(authToken, realMarca, anio, modelo)
            .then(response => {
                setVersiones(response.data);
            })
            .catch(error => {
                console.error('Error al actualizar versiones:', error);
                setVersionsError(error);
            })
            .finally(() => {
                setIsLoadingVersions(false);
            });
    };

    const handleRecotizarInitialValues = (modeloValue, modeloLabel, versionValue, versionLabel, marcaLabel) => {
        setModelos([{value: modeloValue, label: modeloLabel}]);
        setVersiones([{value: versionValue, label: versionLabel}]);
        vehicleDetails.current = {
            detalle_marca: marcaLabel,
            detalle_modelo: modeloLabel,
            detalle_version: versionLabel
        };
    }

    const context = useMemo(() => ({
        marcas,
        anios,
        modelos,
        versiones,
        coberturas,
        coberturasAutomotorNames,
        updateModels,
        updateVersions,
        isLoadingModels,
        isLoadingVersions,
        modelsError,
        versionsError,
        vehicleDetails,
        handleRecotizarInitialValues
    }), [
        marcas,
        anios,
        modelos,
        versiones,
        coberturas,
        coberturasAutomotorNames,
        isLoadingModels,
        isLoadingVersions,
        modelsError,
        versionsError,
        vehicleDetails,
        handleRecotizarInitialValues
    ]);

    return (
        <AutomotorContext.Provider value={context}>
            {children}
        </AutomotorContext.Provider>
    );
};

AutomotorContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AutomotorContextProvider;
