import formModel from './formModel';

const moment = require('moment');

const {
    formField: {
        vigenciaDesde,
        vigenciaHasta,
        frecuenciaPago,
        tipoVivienda,
        formaDePago,
        infoDePago: {
            numeroCbu,
            marcaTarjeta,
            numeroTarjeta,
        },
        comentariosSolicitud,
        descuentoEspecial,
        cliente: {
            rol,
            personaJuridica,
            dni,
            cuit,
            nombre,
            apellido,
            fechaNacimiento,
            estadoCivil,
            sexo,
            domicilio,
            codigosLocalidad,
            codigoArea,
            telefono,
            email,
            situacionIva,
            categoriaMonotributo,
            actividadPrincipal,
            lugarNacimiento
        },
        cobertura: {
            sumaAsegurada,
            numeroCobertura
        },
        detalleEquipoElectronico: {
            sumaAseguradaElectronico,
            descripcionElectronico,
            codigoElectronico,
            codigoGrupoElectronico
        },
        detalleBicicletas: {
            sumaAseguradaBicicletas,
            descripcionBicicletas,
            codigoBicicletas,
            codigoGrupoBicicletas
        },
    }
} = formModel;

export default {
    [vigenciaDesde.name]: moment().format('YYYY-MM-DD'),
    [vigenciaHasta.name]: moment().add(1,"Y").format("YYYY-MM-DD"),
    [tipoVivienda.name]: "",
    [formaDePago.name]: '4',
    [frecuenciaPago.name]: '5',
    infoDePago: {
        [numeroCbu.name]: '',
        [marcaTarjeta.name]: '',
        [numeroTarjeta.name]: '',
    },
    [comentariosSolicitud.name]: '',
    [descuentoEspecial.name]: 0,
    clientes: [
        {
            [rol.name]: '2',
            [personaJuridica.name]: '0',
            [dni.name]: '',
            [cuit.name]: '',
            [nombre.name]: '',
            [apellido.name]: '',
            [fechaNacimiento.name]: '',
            [estadoCivil.name]: '2',
            [sexo.name]: '',
            [domicilio.name]: '',
            [codigosLocalidad.name]: '',
            [codigoArea.name]: '',
            [telefono.name]: '',
            [email.name]: '',
            [situacionIva.name]: '4',
            [categoriaMonotributo.name]: '1',
            [actividadPrincipal.name]: '10001',
            [lugarNacimiento.name]: 'Cordoba'
        },
    ],
    coberturas: [
        {
            [sumaAsegurada.name]: "",
            [numeroCobertura.name]: "1"
        },
        {
            [sumaAsegurada.name]: "",
            [numeroCobertura.name]: "2"
        },
        {
            [sumaAsegurada.name]: "",
            [numeroCobertura.name]: "17"
        },
        {
            [sumaAsegurada.name]: "",
            [numeroCobertura.name]: "22"
        },
        {
            [sumaAsegurada.name]: "",
            [numeroCobertura.name]: "35"
        },
        {
            [sumaAsegurada.name]: "",
            [numeroCobertura.name]: "38"
        },
    ],
    detalleEquipoElectronico: [
        {
            [descripcionElectronico.name]: "",
            [sumaAseguradaElectronico.name]: "",
            [codigoElectronico.name]: 201,
            [codigoGrupoElectronico.name]: 34,
        },
    ],
    detalleBicicletas: [
        {
            [descripcionBicicletas.name]: "",
            [sumaAseguradaBicicletas.name]: "",
            [codigoBicicletas.name]: 209,
            [codigoGrupoBicicletas.name]: 31,
        },
    ],
};
