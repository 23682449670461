import React, {forwardRef, useContext, useMemo} from 'react';
import './CoveragesPopover.css';
import {CoveragePopoverItem} from "../../../components/CoveragePopoverItem";
import {Button} from '../../../components/Button'
import {useCurrentQuotationContext} from "../../../context/CurrentQuotationContext/CurrentQuotationContext";
import CotizarContext from "../../../context/CotizarContext";

const CoveragesPopover = forwardRef((props, ref) => {
    const {setCoverageComparisonModalOpen, setCartOpened} = props;
    const {discounts, cartCoverages, removeCoverageFromCart} = useCurrentQuotationContext();
    const {companiesDict} = useContext(CotizarContext);

    const coverageItems = useMemo(() => {
        return cartCoverages.map(item => {
            let coveragePremium = item.premium;

            return {
                id: item.id,
                companyImg: item.company,
                companyName: item.companyName,
                coverage: item.nombre,
                premium: coveragePremium,
                actualPremium: item.actualPremium,
                franquicia: item.franquicia ?? 0,
            };
        });
    }, [cartCoverages, discounts]);

    return (
        <div className="coPopover__container" ref={ref}>
            {cartCoverages.length === 0 &&
            <p className="coPopover__empty">No hay coberturas seleccionadas</p>
            }

            {coverageItems.map(item => (
                <CoveragePopoverItem
                    removeCoverageFromCart={removeCoverageFromCart}
                    id={item.id}
                    company={item.companyName}
                    companyImg={companiesDict[item.companyImg]['logo']}
                    coverageName={item.coverage}
                    premium={item.actualPremium}
                />
            ))}

            <hr className="coPopover__hr"/>

            <div className="coPopover__total__container">
                <p>TOTAL: </p>
                <p className="coPopover__total__number">{cartCoverages.length}</p>
            </div>

            <div className="coPopover__button__container">
                <Button variant="filled"
                        color="primary"
                        type="button"
                        disabled={cartCoverages.length <= 0}
                        enabled
                        style={{transform: "scale(0.7)"}}
                        onClick={() => {setCoverageComparisonModalOpen(true); setCartOpened(false)}}>
                    Comparar
                </Button>
            </div>

        </div>
    )
});

export default CoveragesPopover;