const startEmissionSteps = (companyId, companiesDict, setCurrentFormData, formData, setUpdatingForm) => {
    const cotizarDisabledStep = {
        name: "#Cotización",
        form: null,
        initialValues: null,
        validationSchema: null
    }

    setUpdatingForm(true)
    setCurrentFormData([cotizarDisabledStep, ...formData['emision'][companiesDict[companyId]['name']]]);
}

export default startEmissionSteps;