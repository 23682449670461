import React, {useContext, useState} from 'react';
import {Grid, Button, Typography, Snackbar} from '@material-ui/core';
import {InputField} from '../../../../components/FormFields';
import {FieldArray, useFormikContext} from 'formik'
import ClearIcon from '@material-ui/icons/Clear';
import {makeStyles} from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';
import Thumb from "../../../../components/Thumb";
import PopOverCustom from "../../../../components/PopOver";
import ButtonBase from '@material-ui/core/ButtonBase';
import CarPhotosDialog from '../Components/CarPhotosDialog';
import SlantedField from "../../../../components/FormFields/SlantedField";
import Alert from "@material-ui/lab/Alert";
import {Dropzone, MIME_TYPES} from "@mantine/dropzone";
import {Group, Text} from "@mantine/core";
import {IconUpload, IconPhoto, IconX} from '@tabler/icons';
import DefaultValuesContext from "../../../../../context/DefaultValuesContext";
import {downloadFile} from "../../../../utils/downloadFile";

const moment = require('moment');
const UPLOAD_SIZE_LIMIT = 10485760;
const useStyles = makeStyles(() => ({
    listRow: {
        alignItems: 'center',
    },
    buttonForm: {
        backgroundColor: '#452d7b',
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: '#38217b',
            boxShadow: 'none',
        },
    },

    disabled: {
        backgroundColor: "#f8f9fa",
        borderColor: "#f8f9fa",
        cursor: 'not-allowed',
        //
        '& *': {
            color: "#adb5bd",
        },
    },
    customBadge: {
        marginTop: "4px",
        borderRadius: "4px",
        backgroundColor: "rgb(255, 244, 229)",
        padding: "6px 16px",
        fontSize: '15px',
        color: "rgb(102, 60, 0)",
        fontFamily: 'Ubuntu',
        textAlign: 'center',
    }
}));

export default function VehiculoForm(props) {
    const classes = useStyles();
    const {
        formField: {
            comentariosSolicitud,
            vehiculo: {
                patente,
                motor,
                chasis,
                marca,
                modelo,
                anio,
                valor,
                tieneGnc,
                gnc: {
                    descripcionGnc,
                    marcaRegulador,
                    numeroRegulador,
                    cilindros: {
                        marcaCilindro,
                        numeroCilindro,
                    },
                    valorTotal,
                },
            },
            bienesAsegurados: {
                id,
                descripcion,
                suma,
            },
        }
    } = props;
    const [openPhotoHelp, setOpenPhotoHelp] = useState(false);
    const {setFieldValue, values} = useFormikContext();
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const currentWeight = React.useRef(0);
    const [error, setError] = React.useState(null)
    const {valuesMemo} = useContext(DefaultValuesContext)

    const handleOpenAlert = (message) => {
        setOpenAlert(true);
        setAlertMessage(message);
    };

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
        setAlertMessage(null);
    };

    const cilindroVacio = {
        [marcaCilindro.name]: '',
        [numeroCilindro.name]: '',
    };

    const bienVacio = {
        [id.name]: '10',
        [descripcion.name]: '',
        [suma.name]: '',
    };

    const checkLimitSize = (newFile) => {
        let filesSize = newFile.size;

        values.vehiculo.inspeccionPrevia.archivos.map(archivo => {
            filesSize += archivo.file.size
        })

        return (filesSize > UPLOAD_SIZE_LIMIT)
    };

    const displayDICWarning = () => {
        if (values['vehiculo']['valor'] >= valuesMemo['Valor_DIC_Requerido']) {
            const downloadLink = values['clientes'][0]['personaJuridica'] === "0"
                ? "https://polko-images.s3.us-east-2.amazonaws.com/Formularios/DIC_Persona_Humana.pdf"
                : "https://polko-images.s3.us-east-2.amazonaws.com/Formularios/DIC_Persona_Juridica.pdf"
            return (
                <div className={classes.customBadge}>
                    Usted esta emitiendo un vehículo de alta gama, recuerde adjuntar la
                    <span>{" "}
                        <a style={{cursor: "pointer"}} onClick={() => downloadFile(downloadLink, downloadLink.substring(downloadLink.lastIndexOf('/') + 1))}>
                            Declaración Jurada
                        </a>{" "}
                    </span>
                    de Identificación de Cliente solicitado por la UIF.
                </div>
            )
        }
    }

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='h6'>
                        Datos del vehículo
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputField
                        name={`vehiculo.${patente.name}`}
                        label={patente.label}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputField
                        name={`vehiculo.${motor.name}`}
                        label={motor.label}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputField
                        name={`vehiculo.${chasis.name}`}
                        label={chasis.label}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <SlantedField value={values.vehiculo.marca} label={marca.label}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <SlantedField value={values.vehiculo.anio} label={anio.label}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <SlantedField value={values.vehiculo.nombreModelo} label={modelo.label}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <SlantedField value={values.vehiculo.valor.toLocaleString('es-ar', {
                        style: 'currency',
                        currency: 'ARS',
                        minimumFractionDigits: 0
                    })} label={valor.label}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <SlantedField value={values.vehiculo.tieneGnc === "true" ? "Si" : "No"} label={tieneGnc.label}/>
                </Grid>
                {values.vehiculo.tieneGnc === "true" &&
                    <Grid item xs={4}>
                        <SlantedField value={values.vehiculo.gnc.valorTotal} label={valorTotal.label}/>
                    </Grid>}
                {values.vehiculo.tieneGnc === 'true' && (
                    <Grid item container spacing={3}>
                        <Grid item xs={12}>
                            <Divider variant="middle"/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h6'>
                                Datos del equipo GNC
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <InputField
                                name={`vehiculo.gnc.${descripcionGnc.name}`}
                                label={descripcionGnc.label}
                                textTransform={'capitalize'}
                                fullWidth
                            />
                        </Grid> <Grid item/>
                        <Grid item xs={4}>
                            <InputField
                                inputProps={{style: {textTransform: "capitalize"}}}
                                name={`vehiculo.gnc.${marcaRegulador.name}`}
                                label={marcaRegulador.label}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <InputField
                                name={`vehiculo.gnc.${numeroRegulador.name}`}
                                label={numeroRegulador.label}
                                fullWidth
                            />
                        </Grid>
                        <FieldArray name='vehiculo.gnc.cilindros'>{({remove}) => (
                            values.vehiculo.gnc.cilindros.map((e, index) => (
                                <Grid item container spacing={2} className={classes.listRow}>
                                    <Grid item xs={4}>
                                        <InputField
                                            inputProps={{style: {textTransform: "capitalize"}}}
                                            name={`vehiculo.gnc.cilindros[${index}].${marcaCilindro.name}`}
                                            label={marcaCilindro.label}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <InputField
                                            name={`vehiculo.gnc.cilindros[${index}].${numeroCilindro.name}`}
                                            label={numeroCilindro.label}
                                            fullWidth
                                        />
                                    </Grid>
                                    {values.vehiculo.gnc.cilindros.length !== 1 && (
                                        <Grid item xs={4}>
                                            <Button onClick={() => remove(index)}><ClearIcon/></Button>
                                        </Grid>
                                    )}
                                </Grid>
                            ))
                        )}</FieldArray>
                        {values.vehiculo.gnc.cilindros.length < 4 && (
                            <Grid item xs={12}>
                                <FieldArray name='vehiculo.gnc.cilindros'>{({push}) => (
                                    <Button variant='outlined' onClick={() => push(cilindroVacio)}>Nuevo
                                        Cilindro</Button>
                                )}</FieldArray>
                            </Grid>
                        )}
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Divider variant="middle"/>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='h6'>
                        Bienes asegurados (Adicionales)
                    </Typography>
                </Grid>
                <FieldArray name='bienesAsegurados'>{({remove}) => (
                    values.bienesAsegurados.map((e, index) => (
                        <Grid item container spacing={2} className={classes.listRow}>
                            <Grid item xs={4}>
                                <InputField
                                    name={`bienesAsegurados[${index}].${descripcion.name}`}
                                    label={descripcion.label}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <InputField
                                    name={`bienesAsegurados[${index}].${suma.name}`}
                                    label={suma.label}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Button onClick={() => remove(index)}><ClearIcon/></Button>
                            </Grid>
                        </Grid>
                    ))
                )}</FieldArray>
                <Grid item xs={12}>
                    <FieldArray name='bienesAsegurados'>{({push}) => (
                        <Button variant='outlined' onClick={() => push(bienVacio)}>Agregar bien adicional</Button>
                    )}</FieldArray>
                </Grid>
                <Grid item xs={12}>
                    <Divider variant="middle"/>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h6'>
                        Archivos adjuntos
                        <ButtonBase onClick={() => setOpenPhotoHelp(true)}>
                            <PopOverCustom descripcion={'Ayuda'}/>
                        </ButtonBase>
                    </Typography>

                    {displayDICWarning()}
                </Grid>
                <Grid item xs={12}>

                    <Dropzone
                        className={values.vehiculo.inspeccionPrevia.archivos.length >= 6 && classes.disabled}
                        onDrop={(files) => {
                            let json = []
                            let len = values.vehiculo.inspeccionPrevia.archivos.length
                            setError(null)
                            files.map(async function (file) {
                                currentWeight.current += file.size;
                                if (len < 10 && currentWeight.current < 10485760) {
                                    let extension = file.name.split(/[.]+/).pop().toUpperCase();
                                    if (extension !== 'JFIF') {
                                        json.push({
                                            "name": file.name,
                                            "content": "",
                                            "file": file
                                        })
                                    }
                                    else {
                                        setError('El formato .JFIF no es válido.')
                                        currentWeight.current -= file.size
                                    }
                                }
                                else{
                                    setError('El tamaño total supera los 10MB.')
                                    currentWeight.current -= file.size
                                }
                                len += 1
                            })
                            setFieldValue('vehiculo.inspeccionPrevia.archivos', values.vehiculo.inspeccionPrevia.archivos.concat(json));
                        }}
                        onReject={(files) => console.log('rejected files', files)}
                        maxSize={3 * 1024 ** 2}
                        maxFiles={10}
                        accept={[MIME_TYPES.png, MIME_TYPES.jpeg, MIME_TYPES.pdf]}
                        disabled={values.vehiculo.inspeccionPrevia.archivos.length >= 10}
                        {...props}
                    >
                        <Group position="center" spacing="xl" style={{minHeight: 220, pointerEvents: 'none'}}>
                            <Dropzone.Accept>
                                <IconUpload
                                    size={50}
                                    stroke={1.5}
                                />
                            </Dropzone.Accept>
                            <Dropzone.Reject>
                                <IconX
                                    size={50}
                                    stroke={1.5}
                                    // color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                />
                            </Dropzone.Reject>
                            <Dropzone.Idle>
                                <IconPhoto size={50} stroke={1.5}/>
                            </Dropzone.Idle>

                            <div>
                                <Text size="xl" inline align="center">
                                    Arrastre o haga clic para seleccionar los archivos
                                </Text>
                                <Text size="sm" color="dimmed" inline mt={7} align="center">
                                    Puede adjuntar un máximo de 10 archivos (imágenes o pdf), cada archivo no debe
                                    superar los 5mb y en total no pueden exceder los 10mb.
                                </Text>
                            </div>
                        </Group>
                    </Dropzone>
                    <div style={{display: error ? 'flex' : 'none', width:'100%', paddingTop: '12px', justifyContent:'center'}}>
                        <p style={{
                            color: '#c92a2a',
                            letterSpacing:-0.8

                        }}>{error}</p>
                    </div>

                </Grid>
                <FieldArray name='vehiculo.inspeccionPrevia.archivos'>{({remove}) => (
                    values.vehiculo.inspeccionPrevia.archivos.map((file, index) => {
                        return (
                            <Grid item xs={6} sm={4}>
                                <Thumb index={index} values={values} file={file.file}/>
                                <Button onClick={() => {
                                    remove(index)
                                    currentWeight.current -= file.file.size
                                }}><ClearIcon/></Button>
                            </Grid>
                        )
                    })
                )}</FieldArray>
                <Grid item xs={12}>
                    <InputField
                        name={comentariosSolicitud.name}
                        label={comentariosSolicitud.label}
                        multiline
                        rows={2}
                        fullWidth
                        onKeyDown={(e) => {
                            if (e.which === 13) {
                                e.preventDefault();
                            }
                        }}
                    />
                </Grid>
            </Grid>
            <CarPhotosDialog open={openPhotoHelp} onClose={() => setOpenPhotoHelp(false)}/>
            <Snackbar open={openAlert && alertMessage} autoHideDuration={5000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity="warning" elevation={6} variant="filled"
                       classes={{filledWarning: classes.alert}}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}
