import * as Yup from 'yup';
import Model from "./Model";

const {
    formField: {
        nombre,
        apellido,
        celular,
        mail,
        numero_productor,
        numero_organizador,
        compania,
        codigo_postal,
        domicilio,
        estado_civil,
        fecha_nacimiento,
        localidad,
        provincia,
        cuit,
        oficina,
        experiencia,
        select_companies,
        tiene_codigo,
        files,
    },
} = Model;

export const baseSchema = Yup.object({
    [nombre.name]: Yup.string().required(`${nombre.requiredErrorMsg}`),
    [apellido.name]: Yup.string().required(`${apellido.requiredErrorMsg}`),
    [celular.name]: Yup.string().required(`${celular.requiredErrorMsg}`),
    [mail.name]: Yup.string().required(`${mail.requiredErrorMsg}`),
    [compania.name]: Yup.string().required(`${compania.requiredErrorMsg}`),
    [tiene_codigo.name]: Yup.boolean().required(`${tiene_codigo.requiredErrorMsg}`),
});

export const tieneCodigoSchema = Yup.object({
    [numero_productor.name]: Yup.number().required(`${numero_productor.requiredErrorMsg}`),
    [numero_organizador.name]: Yup.number().required(`${numero_organizador.requiredErrorMsg}`),
});

export const noTieneCodigoSchema = Yup.object({
    [cuit.name]: Yup.string().required(`${cuit.requiredErrorMsg}`),
    [oficina.name]: Yup.string().required(`${oficina.requiredErrorMsg}`),
    [fecha_nacimiento.name]: Yup.string().required(`${fecha_nacimiento.requiredErrorMsg}`),
    [estado_civil.name]: Yup.string().required(`${estado_civil.requiredErrorMsg}`),
    [domicilio.name]: Yup.string().required(`${domicilio.requiredErrorMsg}`),
    [codigo_postal.name]: Yup.string().required(`${codigo_postal.requiredErrorMsg}`),
    [localidad.name]: Yup.string().required(`${localidad.requiredErrorMsg}`),
    [provincia.name]: Yup.number().required(`${provincia.requiredErrorMsg}`),
    [experiencia.name]: Yup.string().required(`${experiencia.requiredErrorMsg}`),
    [select_companies.name]: Yup.array()
        .min(1, `${select_companies.requiredErrorMsg}`)
        .required(`${select_companies.requiredErrorMsg}`),
});

export const documentosSchema = Yup.object({
    [files.name]: Yup.array()
        .length(9, "Debe subir todos los archivos requeridos")
        .of(
            Yup.object().shape({
                type: Yup.string().required("Asigne las etiquetas a los archivos")
            })
        )
});
