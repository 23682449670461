import React, {useState} from 'react';
import {at} from 'lodash';
import {useField} from 'formik';
import {TextField, useMediaQuery} from '@material-ui/core';

export function useFastField(props)  {
    const [field, meta, setFieldValue] = useField(props);
    const [value, setValue] = useState(field.value);
    const { onBlur, onChange } = field;

    field.value = value;
    field.onChange = (e) => {
        if (e && e.currentTarget) {
            setValue(e.currentTarget.value);
            setFieldValue.setTouched(e.currentTarget)
            setFieldValue.setValue(e.currentTarget.value)
        }
    };
    field.onBlur = (e) => {
        onChange(e);
        onBlur(e);
    };

    return [field, meta];
}

export default function InputField(props) {

    const {errorText, ...rest} = props;
    const [field, meta] = useFastField(props);
    const isMobile = useMediaQuery('(max-width:500px)');

    function _renderHelperText() {
        const [touched, error] = at(meta, 'touched', 'error');
        if (touched && error) {
            return error;
        }
    }

    return (
        <TextField
            type="text"
            InputLabelProps={{style: isMobile ? {fontSize: 12} : {fontSize: 18}}}
            error={meta.touched && meta.error && true}
            helperText={_renderHelperText()}
            {...field}
            {...rest}
        />
    );
}
