import React from 'react';
import './CarPhotosDialog.css';
import {CustomModal} from "../../../components/CustomModal";
import {PopUpCard} from "../../../components/PopUpCard";
import carPhoto from '../../../assets/_images/car_photo_dialog.jpg';
import motorcyclePhoto from '../../../assets/_images/moto_photo_dialog.png';
import {ReactComponent as CloseIcon} from "../../../assets/icons/ic_close.svg";

function CarPhotosDialog({onClose, type = "automotor"}) {
    const imagesDict = {
        "automotor": carPhoto,
        "motovehiculo": motorcyclePhoto,
    }
    return (
        <CustomModal>
            <PopUpCard>
                <div className="carPhDialog__container">
                    <div className="carPhDialog__icon__container" onClick={onClose}>
                        <CloseIcon className="carPhDialog__icon"/>
                    </div>
                    <p className="carPhDialog__title">¿Cómo deben tomarse las fotos del vehículo?</p>

                    <p className="carPhDialog__text">
                        Deben tomarse 4 (cuatro) fotos con luz diurna: desde una perspectiva frontal, otra posterior
                        y dos laterales. Por ejemplo:
                    </p>

                    <div className="carPhDialog__block">
                        <img src={imagesDict[type]} alt="" className="carPhDialog__image"/>
                    </div>

                    <div className="carPhDialog__block">
                        <p className="carPhDialog__note">
                            *Si el vehículo es 0km puede adjuntar el certificado de no rodamiento en lugar de las fotos.
                        </p>
                    </div>
                </div>
            </PopUpCard>
        </CustomModal>
    );
}

export default CarPhotosDialog;