import React from 'react';
import {Grid} from "@material-ui/core";
import Typography from "../../components/Typography";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import Axios from "axios";



import producto1 from "../../../assets/images/marketing/productos/Productos. Placa seguro de auto 1.0.jpg";
import producto2 from "../../../assets/images/marketing/productos/Productos. Placa seguro de autos 1.1.jpg";
import producto3 from "../../../assets/images/marketing/productos/Productos. Placa seguro de autos 1.2.jpg";
import producto4 from "../../../assets/images/marketing/productos/Productos. Seguro de autos 0km.jpg";
import producto5 from "../../../assets/images/marketing/productos/Llamando al doctor.jpg";

const FileDownload = require('js-file-download');

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        width: '95vw',

    },
    titulomodal: {
        textAlign: 'center',
        fontSize: '24px',
        color: '#452d7b',
        fontFamily: 'Ubuntu',
        fontWeight: 'bold',
        letterSpacing: '-0.5',
        marginBottom: '15px'
    },
    rootModal: {
        display: 'flex', alignItems: 'center', justifyContent: 'center',

    },
    gridTarjeta: {
        borderRadius: '8px',
        boxShadow: '0 5px 40px 0 rgba(70, 70, 70, 0.2)',
        backgroundColor: '#fff',
        margin: '10px 0 10px',
        flexDirection: 'row',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            padding: '10px'
        },
    },
    divRectangle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent:'center',
        padding: '4px 0',
        height: 'fit-content',
        background: 'rgba(255, 255, 255, 0.1)',
        //mixBlendMode: 'hard-light',
        borderRadius: '5px',
        width: '206px',
        boxShadow: '0 5px 40px 0 rgba(70, 70, 70, 0.2)',
        marginBottom:'16px',

    },
    subtituloGrid: {
        textAlign: 'center',
        fontSize: '16px',
        color: '#35303f',
        fontFamily: 'Ubuntu',
        fontWeight: 'bold',
        letterSpacing: '-0.5',
        textTransform:'none'
    },

}));



function ProductosList(props) {
    const classes = useStyles();

    function ConstructorTarjetas(props) {
        let titulo = props.titulo;
        let descarga = props.descarga;

        return (

            <Button className={classes.divRectangle}
                    href={descarga}
                    download={descarga}
            >

                <Typography className={classes.subtituloGrid} style={{width: '174px',}}>
                    {titulo}
                </Typography>

            </Button>


        )
    };


    return (
        <React.Fragment>
            <div >


                <Grid container direction={"column"} className={classes.rootModal}>
                    <div >

                        <ConstructorTarjetas
                            titulo='Seguro de Autos'
                            descarga={producto1}/>

                    </div>

                    <div >
                        <ConstructorTarjetas
                            titulo='Seguro de Autos 2'
                            descarga={producto2}/>
                    </div>

                    <div>
                        <ConstructorTarjetas
                            titulo='Seguro de Autos 3'
                            descarga={producto3}/>
                    </div>

                    <div>
                        <ConstructorTarjetas
                            titulo='Seguro de Autos 0km'
                            descarga={producto4}/>
                    </div>

                    <div>
                        <ConstructorTarjetas
                            titulo='Llamando al Doctor'
                            descarga={producto5}/>
                    </div>

                </Grid>
            </div>
        </React.Fragment>

    );
}

export default ProductosList;