import React, {useState} from "react";
import {Tab} from "../Tab";
import {OvalTab} from "../OvalTab";
import {CarouselWrapper} from "../CarouselWrapper";


function CarouselTabs(props) {
    const {tabs, tabContent, tabType, slideSize} = props;
    const [activeTab, setActiveTab] = useState(0);

    function handleTabClick(index) {
        setActiveTab(index);
    }

    function renderTabs(tabType) {
        switch (tabType) {
            case 'Ovaltype':
                return (
                    <CarouselWrapper slideSize="auto" withDots={false} align="start" initialSlide={0} dragFree={false}>
                        {tabs.map((tab, index) => (
                            <OvalTab key={index} active={activeTab === index} color="primary"
                                     onClick={() => handleTabClick(index)}>
                                <p>{tab}</p>
                            </OvalTab>
                        ))}
                    </CarouselWrapper>
                )
            case 'square':
                return (
                    <CarouselWrapper slideSize={slideSize ? slideSize : "auto"} withDots={false} align="start" initialSlide={0} dragFree={true}>
                        {tabs.map((tab, index) => (
                            <Tab key={index} active={activeTab === index} color="primary"
                                     onClick={() => handleTabClick(index)} type="tab">
                                <p>{tab}</p>
                            </Tab>
                        ))}
                    </CarouselWrapper>
                )
            default:
                return (
                    <CarouselWrapper slideSize="auto" withDots={false} align="start" initialSlide={0} dragFree={false}>
                        {tabs.map((tab, index) => (
                            <Tab key={index} active={activeTab === index} color="primary"
                                 onClick={() => handleTabClick(index)}>
                                <p>{tab}</p>
                            </Tab>
                        ))}
                    </CarouselWrapper>
                )
        }
    }

    return (
        <div className="MMTabs__container">
            <div className="MMTabs__headerContainer">
                {renderTabs(tabType)}
            </div>
            <>
                {tabContent.map((content, index) => (
                    <>
                        {activeTab === index ? content : null}
                    </>
                ))}
            </>
        </div>
    );
}

export default CarouselTabs;
