import React, {useContext, useEffect, useState} from 'react';
import './OperacionesPolkistasTable.css';
import {FormCard} from "../../../components/FormCard";
import {MainTable} from "../../../components/MainTable";
import {Button} from "../../../components/Button";
import {useHistory} from "react-router-dom";
import MasterContext from "../../../context/MasterContext";
import moment from "moment";
import axios from "axios";
import AuthContext from "../../../context/AuthContext";
import {MantineMultiSelectFilter} from "../../../components/Filters/MantineMultiSelectFilter";
import {MantineDateFilter} from "../../../components/Filters/MantineDateFilter";
import Loading from "../../../components/Loading/Loading";
import showNotification from "../../../modules/utils/showNotification";
import {CustomerPopOver} from "../CustomerPopOver";
import {productIdentifier} from "../../../modules/utils/productIdentifier";
import {productDescriptionGenerator} from "../../../modules/utils/productDescriptionGenerator";
import CotizarContext from "../../../context/CotizarContext";

function getPolkistaNameByIdAuth(polkistasInfo, idAuth) {
    for (const usuarioId in polkistasInfo) {
        const usuario = polkistasInfo[usuarioId];
        if (usuario.datos_polkista.id_auth === idAuth) {
            return usuario.datos_polkista.NombreCompleto;
        }
    }
    return null;
}

function OperacionesPolkistasTable() {
    const [rows, setRows] = useState([]);
    const history = useHistory();
    const {masterPolkistas} = useContext(MasterContext);
    const {authToken} = useContext(AuthContext);
    const {coberturasInfo} = useContext(CotizarContext);
    const [popOverCustomerOpened, setPopOverCustomerOpened] = useState(null);

    //FILTERS
    const origenData = ['POLKO', 'CLIENTES'];
    const [polkistasData, setPolkistasData] = useState([]);
    const productosData = [{label: "VEHICULO", value: "Autos"}, {label: "MOTOVEHICULO", value: "Motovehiculos"}, {label: "HOGAR", value: "Hogar"}, {label: "MICROSEGUROS", value: "Microseguros"}, {label:"ASISTENCIA AL VIAJERO", value: "AsistenciaViajero"}];
    const [loading, setLoading] = useState(false);

    const [polkistasFilter, setPolkistasFilter] = useState([]);
    const [origenFilter, setOrigenFilter] = useState([]);
    const [productosFilter, setProductosFilter] = useState([]);
    const [dateRangeFilter, setDateRangeFilter] = useState([
        moment().subtract(45, "days").toDate(),
        moment().toDate(),
    ]);

    const [storedValues, setStoredValues] = useState([polkistasFilter, origenFilter, productosFilter, dateRangeFilter]);

    //COLUMNS
    const commissionColumns = [
        {title: "Polkista", key: "idAuth", render: (value) => <span>{getPolkistaNameByIdAuth(masterPolkistas, value) ?? ""}</span>},
        {title: "ID Cotización", key: "_id", render: (value) => <span>{value}</span>},
        {title: "Producto", key: "producto", render: (value) => <span>{value}</span>},
        {title: "Descripción", key: "descripcion", render: (value) => <span>{value}</span>},
        {
            title: "Cliente",
            key: "cliente",
            render: (value, index) => (
                <span>
                    {value === 'Sin datos'
                        ? 'Sin datos'
                        : <CustomerPopOver
                            customer={value}
                            index={index}
                            open={popOverCustomerOpened}
                            setOpen={setPopOverCustomerOpened}/>
                    }
                </span>)
        },
        {title: "Fecha", key: "fecha", render: (value) => <span>{value}</span>},
        {
            title: "Acciones",
            key: "row",
            render: (value) =>
                <span className="operacionesPolkistasTable__table__actions">
                    <Button
                        variant="filled"
                        color="primary"
                        marginX="sm"
                        size={"sm"}
                        type="button"
                        enabled
                        onClick={() => handleRecotizar(value)}
                    >
                    Recotizar
                    </Button>
                    </span>
        }
    ];

    //FUNCTIONS

    const requestCotizacionesPolkistas = (requestAll = false) => {
        setLoading(true);
        setRows([])
        let url = process.env.REACT_APP_BACK_SERVER + '/getDatosOperaciones'
        const productsValue = productosData.map(producto => producto.value);

        let jsonData;
        if (requestAll) {
            setPolkistasFilter([])
            setOrigenFilter([])
            setProductosFilter([])
            setDateRangeFilter([
                moment().subtract(45, "days").toDate(),
                moment().toDate(),
            ])
            setStoredValues([[], [], [], [
                moment().subtract(45, "days").toDate(),
                moment().toDate(),
            ]]);
            jsonData = {
                "Polkistas": Object.keys(masterPolkistas).map(key => masterPolkistas[key]['datos_polkista']['id_auth']),
                "Origen": origenData,
                "Producto": productsValue,
                "FechaInicio": moment().subtract(45, "days").toDate(),
                "FechaFinalizacion": moment().toDate(),
            }
        } else {
            jsonData = {
                "FechaInicio": moment(dateRangeFilter[0]).format('YYYY-MM-DD'),
                "FechaFinalizacion": moment(dateRangeFilter[1]).format('YYYY-MM-DD'),
                "Polkistas": polkistasFilter.length === 0
                    ? Object.keys(masterPolkistas).map(key => masterPolkistas[key]['datos_polkista']['id_auth'])
                    : polkistasFilter,
                "Origen": origenFilter.length === 0 ? origenData : origenFilter,
                "Producto": productosFilter.length === 0 ? productsValue : productosFilter
            }
            setStoredValues([polkistasFilter, origenFilter, productosFilter, dateRangeFilter]);
        }

        // console.log('jsonData', jsonData)

        axios.post(url, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            }
        }).then(res => {
            const preprocessedData = res.data.map(row => {
                const product = productIdentifier(row);
                return {
                    ...row,
                    "producto": product,
                    "descripcion": productDescriptionGenerator(product, row, coberturasInfo),
                    "cliente": row['cliente'] ? row['cliente'] : "Sin datos",
                    "fecha": moment(row['updated_at']).format('DD/MM/YYYY'),
                }
            })
            return preprocessedData
        }).then((processedData) => {
            setRows(processedData);
        }).catch((err) => {
            showNotification({
                autoClose: 3000,
                color: 'red',
                status: 'error',
                title: 'No ha sido posible obtener la información solicitada.',
                message: `Detalles: ${err.response?.data?.detail?.[0]?.msg}` || "Sin datos del error",
            });
        }).finally(() => {setLoading(false)});
    }

    function handleRecotizar(rowData) {
        console.log(rowData)
        switch (rowData['producto']) {
            case 'AUTOMOTOR':
                history.push({
                    pathname: '/u/cotizar/automotor',
                    state: {
                        rowData: {...rowData, idAuthPolkista: rowData['idAuth']},
                        action: 'recotizar'
                    }
                });
                break;
            case 'HOGAR':
                history.push({
                    pathname: '/u/cotizar/hogar',
                    state: {
                        rowData: {...rowData, idAuthPolkista: rowData['idAuth']},
                        action: 'recotizar'
                    }
                });
                break;
            case 'MICROSEGUROS':
                history.push({
                    pathname: '/u/cotizar/microseguros',
                    state: {
                        rowData: {...rowData, idAuthPolkista: rowData['idAuth']},
                        action: 'recotizar'
                    }
                })
                break;
            case 'ASISTENCIA AL VIAJERO':
                history.push({
                    pathname: '/u/cotizar/asistencia_viajero',
                    state: {
                        rowData: {...rowData, idAuthPolkista: rowData['idAuth']},
                        action: 'recotizar'
                    }
                })
        }
    }

    const checkFiltersChanges = () => {
        return JSON.stringify([polkistasFilter, origenFilter, productosFilter, dateRangeFilter]) !== JSON.stringify(storedValues)
    }

    //EFFECTS
    useEffect(() => {
        const polkistasDataReceived = Object.keys(masterPolkistas).map(polkista => {
            return {label: masterPolkistas[polkista]['datos_polkista']['NombreCompleto'], value: masterPolkistas[polkista]['datos_polkista']['id_auth']}
        })
        setPolkistasData(polkistasDataReceived)
    }, []);

    return (
        <div className="operacionesPolkistasTable__wrapper">
            <FormCard>
                <div className="operacionesPolkistasTable__container operacionesPolkistasTable__variables">
                    <div className="operacionesPolkistasTable__header">
                        <div className="operacionesPolkistasTable__header__filters">  {/*Allows to center element*/}
                            <div className="operacionesPolkistasTable__filters__container operacionesPolkistasTable__filters--polkistas"> {/*Avoids resizing observer bug*/}
                                <MantineMultiSelectFilter
                                    data={polkistasData}
                                    placeholder="Polkistas"
                                    value={polkistasFilter}
                                    onChange={setPolkistasFilter}
                                    searchable
                                    clearable
                                    className="operacionesPolkistasTable__filters"
                                    nothingFound="Sin resultados"
                                />
                            </div>

                            <div className="operacionesPolkistasTable__filters__container operacionesPolkistasTable__filters--origen">
                                <MantineMultiSelectFilter
                                    data={origenData}
                                    placeholder="Origen"
                                    value={origenFilter}
                                    onChange={setOrigenFilter}
                                    searchable
                                    clearable
                                    className="operacionesPolkistasTable__filters"
                                    nothingFound="Sin resultados"
                                />
                            </div>

                            <div className="operacionesPolkistasTable__filters__container operacionesPolkistasTable__filters--productos">
                                <MantineMultiSelectFilter
                                    data={productosData}
                                    placeholder="Productos"
                                    value={productosFilter}
                                    onChange={setProductosFilter}
                                    searchable
                                    clearable
                                    className="operacionesPolkistasTable__filters"
                                    nothingFound="Sin resultados"
                                />
                            </div>

                            <div className="operacionesPolkistasTable__filters__container">
                                <div className="operacionesPolkistasTable__filters--date">
                                    <MantineDateFilter
                                        placeholder={"Fecha"}
                                        value={dateRangeFilter}
                                        setValue={setDateRangeFilter}
                                        minDate={moment().subtract(45, "days").toDate()}
                                        maxDate={moment().toDate()}
                                        type="range"
                                    />
                                </div>
                            </div>

                            <div className="operacionesPolkistasTable__filters__container operacionesPolkistasTable__filters--seeAll">
                                <button className="operacionesPolkistasTable__button__filter"
                                        onClick={() => requestCotizacionesPolkistas(true)}>
                                    {"Ver todo"}
                                </button>
                            </div>

                        </div>

                        <div className="operacionesPolkistasTable__header__buttons">
                            {checkFiltersChanges() &&
                            <p className="operacionesPolkistasTable__header__text">Recuerde aplicar los cambios</p>
                            }

                            <Button variant="filled"
                                    color="primary"
                                    type="button"
                                    enabled
                                    onClick={() => requestCotizacionesPolkistas()}>
                                Aplicar filtros
                            </Button>
                        </div>
                    </div>
                    <hr className="operacionesPolkistasTable__hr"/>
                    <div className="operacionesPolkistasTable__body">
                        {loading
                            ?
                            <div className="operacionesPolkistasTable__body__loading">
                                <Loading/>
                            </div>
                            : <MainTable columns={commissionColumns} rows={rows} pagination/>
                        }
                    </div>
                </div>
            </FormCard>
        </div>
    );
}

export default OperacionesPolkistasTable;