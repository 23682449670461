import React, {useEffect, useState} from 'react';
import {Button, Grid, Typography} from '@material-ui/core';
import {ErrorMessage, FieldArray, useFormikContext} from "formik";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import useStyles from '../styles';
import NumberField from "../../../../components/FormFields/NumberField";
import {InputField} from "../../../../components/FormFields";

export default function DetailsForm(props) {
    const {
        formField: {
            detalleEquipoElectronico: {
                descripcionElectronico,
                sumaAseguradaElectronico,
                codigoElectronico,
                codigoGrupoElectronico
            },
            detalleBicicletas: {
                descripcionBicicletas,
                sumaAseguradaBicicletas,
                codigoBicicletas,
                codigoGrupoBicicletas
            },
        },
        errors
    } = props;
    const classes = useStyles();
    const {values} = useFormikContext();
    const [bicicletasDetailsRequired, setBicicletasDetailsRequired] = useState({selected: false, sumaAsegurada: 0});
    const [equipoElectronicoDetailsRequired, setEquipoElectronicoDetailsRequired] = useState({
        selected: false,
        sumaAsegurada: 0
    });

    const equipoElectronicoVacio = {
        [descripcionElectronico.name]: '',
        [sumaAseguradaElectronico.name]: '',
        [codigoElectronico.name]: 201,
        [codigoGrupoElectronico.name]: 34,
    };
    const bicicletasVacio = {
        [descripcionBicicletas.name]: '',
        [sumaAseguradaBicicletas.name]: '',
        [codigoBicicletas.name]: 209,
        [codigoGrupoBicicletas.name]: 31,
    };

    const checkError = (prod) => {
        let prodName;
        if (prod === 1) prodName = "detalleBicicletas"
        if (prod === 3) prodName = "detalleEquipoElectronico"

        if (errors[prodName] !== undefined)
            return (errors[prodName].includes("Las sumas declaradas deben coincidir con las aseguradas"))
        else
            return false
    }

    useEffect(() => {
        values.coberturas.map((cob) => {
            if (cob.numeroCobertura === "48") {
                setBicicletasDetailsRequired({selected: true, sumaAsegurada: cob.sumaAsegurada})
            }

            if (cob.numeroCobertura === "26")
                setEquipoElectronicoDetailsRequired({selected: true, sumaAsegurada: cob.sumaAsegurada})
        })
    }, []);

    function currencyFormat(numString) {
        numString = numString.toString().replace(/\./g, "")
        numString = numString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return numString
    }

    const buttonsToShow = (index, length, arrayHelpers, emptyArray) => {
        if (length === 1) {
            return (<Grid item xs={1}>
                <Button variant='outlined' size="small"
                        style={{padding: "0", marginTop: "16px"}}
                        onClick={() => arrayHelpers.push(emptyArray)}>
                    <AddIcon/>
                </Button>
            </Grid>)
        } else if (index !== length - 1) {
            return (<Grid item xs={1}>
                <Button variant='outlined' size="small"
                        style={{padding: "0", marginTop: "16px"}}
                        onClick={() => arrayHelpers.remove(index)}>
                    <RemoveIcon/>
                </Button>
            </Grid>)
        } else {
            return (
                <Grid item xs={1}>
                    <div className={classes.fieldArrayButtonsDiv}>
                        <Button variant='outlined' size="small"
                                className={classes.fieldArrayButtons}
                                onClick={() => arrayHelpers.remove(index)}>
                            <RemoveIcon/>
                        </Button>
                        <Button variant='outlined' size="small"
                                className={classes.fieldArrayButtons}
                                onClick={() => arrayHelpers.push(emptyArray)}>
                            <AddIcon/>
                        </Button>
                    </div>
                </Grid>
            )
        }
    }

    return (
        <React.Fragment>
            {bicicletasDetailsRequired.selected &&
            <>
                <Typography className={classes.tableHeader} style={{margin: "32px 0px 16px 16px"}}>
                    Detallá marca, modelo, rodado y número de cuadro de tu bicicleta:
                </Typography>

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <FieldArray name='detalleBicicletas'>{(arrayHelpers) => (
                        values.detalleBicicletas.map((item, index) => (
                            <Grid key={index} item container spacing={4} direction='row'
                                  style={{alignItems: "center", justifyContent: "center"}}>
                                <Grid item xs={4}>
                                    <InputField
                                        name={`detalleBicicletas[${index}].${descripcionBicicletas.name}`}
                                        value={item.descripcionBicicletas}
                                        label={descripcionBicicletas.label}
                                        fullWidth
                                        textTransform={'capitalize'}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberField
                                        notCobertura={true}
                                        errorText={errors.detalleBicicletas !== undefined && errors.detalleBicicletas[index] !== undefined ? errors.detalleBicicletas[index].sumaAseguradaBicicletas : 'Ingrese un número válido'}
                                        value={currencyFormat(item.sumaAseguradaBicicletas)}
                                        name={`detalleBicicletas[${index}].${sumaAseguradaBicicletas.name}`}
                                        label={sumaAseguradaBicicletas.label}
                                        inputProps={{style: {textAlign: 'right'}}}
                                    />
                                </Grid>

                                {buttonsToShow(index, values.detalleBicicletas.length, arrayHelpers, bicicletasVacio)}
                            </Grid>
                        ))
                    )}</FieldArray>
                    {checkError(1)
                    &&
                    <>
                        <ErrorMessage name='detalleBicicletas'
                                      render={msg => <div className={classes.errorMessage}>{msg}</div>}/>
                        <p className={classes.errorMessage}> ($ {bicicletasDetailsRequired.sumaAsegurada})</p>
                    </>}
                </div>
            </>}
            {equipoElectronicoDetailsRequired.selected &&
            <>
                <Typography className={classes.tableHeader} style={{margin: "32px 0px 16px 16px"}}>
                    Declaración de objetos asegurados bajo la cobertura "Robo de objetos
                    varios":
                </Typography>

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <FieldArray name='detalleEquipoElectronico'>{(arrayHelpers) => (
                        values.detalleEquipoElectronico.map((item, index) => (
                            <Grid key={index} item container spacing={4} direction='row'
                                  style={{alignItems: "center", justifyContent: "center"}}>
                                <Grid item xs={4}>
                                    <InputField
                                        name={`detalleEquipoElectronico[${index}].${descripcionElectronico.name}`}
                                        label={descripcionElectronico.label}
                                        value={item.descripcionElectronico}
                                        fullWidth
                                        textTransform={'capitalize'}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberField
                                        notCobertura={true}
                                        errorText={errors.detalleEquipoElectronico !== undefined && errors.detalleEquipoElectronico[index] !== undefined ? errors.detalleEquipoElectronico[index].sumaAseguradaElectronico : 'Ingrese un número válido'}
                                        value={currencyFormat(item.sumaAseguradaElectronico)}
                                        name={`detalleEquipoElectronico[${index}].${sumaAseguradaElectronico.name}`}
                                        label={sumaAseguradaElectronico.label}
                                        inputProps={{style: {textAlign: 'right'}}}
                                    />
                                </Grid>

                                {buttonsToShow(index, values.detalleEquipoElectronico.length, arrayHelpers, equipoElectronicoVacio)}
                            </Grid>
                        ))
                    )}</FieldArray>

                    {checkError(3)
                    &&
                    <>
                        <ErrorMessage name='detalleEquipoElectronico'
                                      render={msg => <div className={classes.errorMessage}>{msg}</div>}/>
                        <p className={classes.errorMessage}> ($ {equipoElectronicoDetailsRequired.sumaAsegurada})</p>
                    </>}
                </div>
            </>}
        </React.Fragment>
    );
}