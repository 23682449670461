import React from 'react';
import {DatePickerInput} from '@mantine/dates';
import moment from "moment";
import './MantineDateFilter.css';
import {ReactComponent as CalendarIcon} from "../../../assets/icons/ic_calendar.svg";

const MantineDateFilter = (props) => {
    const {value, setValue, placeholder="dd/mm/yyyy", type="default"} = props;

    return (
        <DatePickerInput
            {...props}
            value={value}
            valueFormat="DD/MM/YYYY"
            locale="es"
            onChange={(e) => {setValue(e)}}
            dateParser={(dateString) => moment(dateString, 'DD/MM/YYYY').toDate()}
            allowFreeInput
            placeholder={placeholder}
            allowLevelChange={false}
            inputFormat="DD/MM/YYYY"
            type={type}
            clearable
            classNames={{
                wrapper: 'mantine__dateFilter__wrapper',
                day: 'mantine__dateFilter__day',
                input: 'mantine__dateFilter__field',
                rightSection: 'mantine__dateFilter__rightSection'
            }}
            rightSection={<CalendarIcon className="mantine__dateFilter__icon"/>}
        />
    );
};

export default MantineDateFilter;
