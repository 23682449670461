import { Button } from "../../../../../../../components/Button";
import { PopUpCardModal } from "../../../../../../../layouts/PopUpCardModal";
import React from "react";

const EmissionException = (props) => {
    const { onClose, onZurichException } = props;

    const handleEmissionException = () => {
        onZurichException();
        onClose();
    }
    return (
        <PopUpCardModal
            title={<h1 className="csm__title">Cobertura fuera de pauta</h1>}
            body={
                <div className="csm__popupBody">
                    <p className="csm__text">
                        Para emitir la cobertura seleccionada, es necesario solicitar una excepción por emisión.
                    </p>
                </div>
            }
            footer={
                <div className="csm__popupFooter">
                    <Button type="button" enabled color="primary" variant="filled" onClick={handleEmissionException}>
                        Solicitar excepción
                    </Button>
                </div>
            }
            closeAction={onClose}
        />
    );
};

export default EmissionException;
