import "./AgreementCardColumn.css";

const AgreementCardColumn = (props) => {
    const {header, value, bigValue} = props;

    return (
        <div className="AgreementCardColumn">
            <div className="AgreementCardColumn__title">
                {header}
            </div>
            <div
                className={`AgreementCardColumn__value ${bigValue ? "big" : ""}`}>
                {value}
            </div>
        </div>
    );
}

export default AgreementCardColumn;