import axios from "axios";

function getCarModels(authToken, marca, anio) {
    let carModelsUrl = process.env.REACT_APP_BACK_SERVER + '/getModelosAutos'
    return axios.get(carModelsUrl, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        },
        params: {
            'marca': marca,
            'anio': anio
        }
    });
}

export default getCarModels;