import moment from "moment";
import {VehiculoModel} from "../FormStages/Cotizar/Vehiculo/VehiculoModel";

const advancedConfigSancor = (recotizarValues, values, userCodigosOperacion, locationCodes, isPromotedCompany, userDiscounts) => {
    const {initialValues: cotizarVehiculoInitialValues} = VehiculoModel();

    values['fecha'] = moment().format('YYYY-MM-DD');
    if (values['configuracionAvanzada']['Sancor']) {
        if (recotizarValues) {
            values['configuracionAvanzada']['Sancor']['fechaCotizacion'] = moment().format('YYYY-MM-DD');
        }
        if (!isPromotedCompany) {
            values['configuracionAvanzada']['Sancor'].codigos = userCodigosOperacion['Sancor'][0];
        }
        values['configuracionAvanzada']['Sancor'].codSancorLocalidad = `${locationCodes.CodigoLocalidadSancor}`

        if (values['configuracionAvanzada']['Sancor'].vigencia === '1')
            values['configuracionAvanzada']['Sancor'].frecuenciaPago = '5';
        else
            values['configuracionAvanzada']['Sancor'].frecuenciaPago = '7';
    } else {
        values['configuracionAvanzada']['Sancor'] = {
            ...cotizarVehiculoInitialValues['configuracionAvanzada']['Sancor'],
            ...(!isPromotedCompany && {
                "codigos": userCodigosOperacion['Sancor'][0]
            }),
            "codSancorLocalidad": `${locationCodes.CodigoLocalidadSancor}`,
        };
    }
    values['configuracionAvanzada']['Sancor']['isPromotedCompany'] = isPromotedCompany;
    values['configuracionAvanzada']['Sancor']['descuentoFueraPauta'] = userDiscounts ? userDiscounts['Sancor']['AUTOMOTOR']?.fuera_pauta || 0 : 0;
}

export {advancedConfigSancor}
