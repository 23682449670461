import React, {
    useEffect,
    useState,
    useMemo
} from 'react';
import PropTypes from 'prop-types';
import AuthContext from "./AuthContext";
import {useAuth0} from "@auth0/auth0-react";

const AuthContextProvider = ({
                                 children,
                             }) => {

        const {getIdTokenClaims, isAuthenticated, user} = useAuth0();

        const [authToken, setAuthToken] = useState(null)
        const [isAuth, setIsAuth] = useState(false)
        const [isMaster, setIsMaster] = useState(false)
        const [isReady, setIsReady] = useState(false);
        const [mail, setMail] = useState(false);
        const [idAuth, setIdAuth] = useState(null)


        async function getToken() {
            let claims = await getIdTokenClaims();
            setIsMaster(claims['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'].includes('master'))
            setIdAuth(claims['sub'])
            return claims.__raw;
        }

        useEffect(() => {

                let shouldUpdate = true;

                const init = async () => {
                    if (shouldUpdate) {
                        if (isAuthenticated) {
                            getToken().then(
                                id_token => {
                                     //console.log(id_token)

                                    if (shouldUpdate) {
                                        setIsAuth(true);
                                        setAuthToken(id_token);
                                        setMail(user.email);
                                        setIsReady(true);
                                    }
                                });
                        } else {
                            setIsAuth(false)
                            setIsReady(true);
                        }
                    }
                }

                init();

                return () => {
                    /* This cleanup function is used to prevent updating the state
                    when the component is unmounted */
                    shouldUpdate = false;
                };
            }, [authToken, isAuthenticated]
        )

        const context = useMemo(() => ({
            idAuth,
            authToken,
            isAuth,
            isMaster,
            mail,
            isReady
        }), [
            idAuth,
            authToken,
            isAuth,
            isMaster,
            mail,
            isReady
        ]);
        return (
            <AuthContext.Provider value={context}>
                {children}
            </AuthContext.Provider>
        );
    }
;

AuthContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AuthContextProvider;