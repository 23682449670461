import React, {useContext, useState} from 'react';
import './DescriptionModal.css';
import {PopUpCardModal} from "../../../../layouts/PopUpCardModal";
import {Button} from "../../../../components/Button";
import MasterContext from "../../../../context/MasterContext";
import {DescriptionOptions} from "../../Utils/DescriptionOption";
import {buildInitialDescriptions} from "../../../../modules/utils/descriptionObjectBuilder";
import {
    INSERT_NEW_DESCRIPTION,
    saveDescriptionsHandler,
    UPDATE_DESCRIPTION
} from "../../../../modules/utils/saveDescriptionsHandler";
import {useMutation} from "@apollo/client";
import AuthContext from "../../../../context/AuthContext";
import {saveConfiguration} from "../../Utils/saveConfiguration";
import {ErrorModal} from "../../../../layouts/ErrorModal";

function DescriptionModal(props) {
    const {setOpen, values} = props;
    const {descriptions: initialDescriptions} = values;
    const {
        idPolkistaMaster,
        handleModified,
        descripciones,
        defaultDescripcionesId
    } = useContext(MasterContext);
    const {authToken} = useContext(AuthContext);
    const [insertDescription] = useMutation(INSERT_NEW_DESCRIPTION);
    const [updateDescription] = useMutation(UPDATE_DESCRIPTION);
    const [errorContent, setErrorContent] = useState(null);
    const [loading, setLoading] = useState(false);

    //Add all the options to the state
    const [options, setOptions] = useState(
        buildInitialDescriptions(
            initialDescriptions,
            defaultDescripcionesId,
            descripciones,
            descripciones[initialDescriptions.find(id => !defaultDescripcionesId.includes(id))]
        )
    );

    const checkIfAtleastOneIsSelected = () => {
        return options.some(opt => opt.selected)
    }

    const handleUpload = async () => {
        setLoading(true);
        const data = values['row'];
        let jsonData = {
            "logo": data.logo,
            "template": data.template,
            "productos": data.productos,
            "descripcion": options.map(descr => {
                if (descr.selected && descr.variant === 'option')
                    return parseInt(descr.id)
            }).filter(i => i),
            "idMaster": idPolkistaMaster,
            "links": data.redes_sociales,
            "idCanalDigital": data.id,
            "idPolkista": data.id_polkista,
        }

        const customDescriptionObj = options.find(d => d.id === '+');

        jsonData = await saveDescriptionsHandler(
            customDescriptionObj,
            descripciones,
            defaultDescripcionesId,
            initialDescriptions,
            insertDescription,
            updateDescription,
            jsonData //Requires a key called 'descripcion': [Int]
        )

        await saveConfiguration(jsonData, authToken, true)
            .then(() => {
                handleModified();
                setOpen({modalName: "", values: null})
            })
            .catch(err => {
                setErrorContent({
                    title: "Hubo un problema al subir los valores.",
                    typeError: err.response.data.detail.code || "Ha ocurrido un error en la comunicación con el servidor",
                    suggestedActions: ["Reintentá el proceso una vez más", "Si el problema persiste ponete en contacto con el área de soporte"],
                    detailedError: err.response.data.detail.message || "Sin datos del error.",
                })
            });
    }

    return (
        <>
            <PopUpCardModal
                title={"Editar las descripciones del polkista"}
                body={
                    <div className="descriptionModal__body">
                        <DescriptionOptions options={options} setOptions={setOptions}/>
                    </div>
                }
                closeAction={() => setOpen({modalName: "", values: null})}
                footer={
                    <div className="csm__button__container">
                        <Button variant="filled"
                                color="primary"
                                marginX="sm"
                                type="button"
                                enabled={checkIfAtleastOneIsSelected()}
                                onClick={() => handleUpload()}
                                loading={loading}
                        >
                            Aceptar
                        </Button>
                    </div>
                }
            />
            {errorContent &&
            <ErrorModal
                closeAction={() => {
                    setErrorContent(null)
                    setLoading(false)
                }}
                title={errorContent.title}
                typeError={errorContent.typeError}
                suggestedActions={errorContent.suggestedActions}
                detailedError={errorContent.detailedError}/>
            }
        </>
    );
}

export default DescriptionModal;