import React, {useState} from 'react';
import {useField} from "formik";
import {Radio} from "@mantine/core";
import "./MantineRadioButtonsField.css";

function MantineRadioButtonsField(props) {
    const {options, areBooleanValues, onChangeAction} = props;
    const [field, meta, helpers] = useField(props.name);
    const [value, setValue] = useState(field.value);
    const {setValue: setFieldValue, setTouched} = helpers;

    const handleChange = (v) => {
        let realValue = v;
        if (areBooleanValues) realValue = realValue === 'true'
        setValue(realValue);
        setFieldValue(realValue);
        setTouched(props.name, false);
        if (onChangeAction) onChangeAction(realValue);
    };

    return (
        <Radio.Group
            {...props}
            value={value}
            onChange={handleChange}
            error={meta.error && meta.touched && <div>{meta.error}</div>}
            classNames={{
                label: meta.error && meta.touched ? 'mantine__select__label--error' : 'mantine__select__label',
            }}
        >
            <div className="mantine__radio__option__container">
                {options.map((option, index) => (
                    <Radio
                        key={index}
                        value={option.value}
                        label={option.label}
                        labelPosition="left"
                        classNames={{
                            radio: 'mantine__radio__button',
                        }}
                    />
                ))}
            </div>
        </Radio.Group>
    );
}

export default MantineRadioButtonsField;