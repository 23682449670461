import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Auth0ProviderWithHistory from "./auth0-provider-with-history";
import {BrowserRouter as Router} from "react-router-dom";
import {WebSocketLink} from "@apollo/client/link/ws";
import {useAuth0} from "@auth0/auth0-react";
import {ApolloClient, ApolloProvider, HttpLink, InMemoryCache, ApolloLink} from "@apollo/client";
import {split} from 'apollo-link';
import {getMainDefinition} from 'apollo-utilities';
import {setContext} from '@apollo/client/link/context';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';

const AppWithApollo = () => {
    const {getIdTokenClaims, isAuthenticated} = useAuth0();

    const wsLink = new WebSocketLink({
        uri: 'wss://db.polko.com.ar/v1/graphql',
        options: {
            lazy: true,
            reconnect: true,
            connectionParams: async () => {
                let token = null;
                let role = 'user';
                if (isAuthenticated) {
                    const claims = await getIdTokenClaims();
                    token = claims.__raw;

                    if (claims['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'].includes('master')) {
                        role = 'master';
                    }
                }
                if (role === 'master') {
                    return {
                        headers: {
                            Authorization: token ? `Bearer ${token}` : "",
                            'X-Hasura-Role': 'master'
                        },
                    }
                } else {
                    return {
                        headers: {
                            Authorization: token ? `Bearer ${token}` : ""
                        },
                    }
                }
            },
        },
    });

    const withToken = setContext(() =>
        getIdTokenClaims().then(claims => {
            const token = claims.__raw;
            return {token}
        })
    );

    const authLink = new ApolloLink((operation, forward) => {
        const {token} = operation.getContext();
        operation.setContext(({headers}) => ({
            headers: {
                ...headers,
                Authorization: isAuthenticated ? `Bearer ${token}` : null,
            },
        }));
        return forward(operation)
    });

    const httpLink = new HttpLink({
        uri: 'https://db.polko.com.ar/v1/graphql',
    });

    const link = split(
        // split based on operation type
        ({query}) => {
            const {kind, operation} = getMainDefinition(query);
            return kind === 'OperationDefinition' && operation === 'subscription';
        },
        wsLink,
        ApolloLink.from([withToken, authLink, httpLink]),
    );

    const client = new ApolloClient({
        link,
        cache: new InMemoryCache(),
    });

    return (
        <ApolloProvider client={client}>
            <MantineProvider withNormalizeCSS withGlobalStyles theme={{
                colorScheme: 'light',
                colors:
                    {
                        primary: ['#f6e3ff', '#dab2ff', '#c07fff', '#a74cff', '#8200FF', '#7500e6', '#5b00b4', '#410082', '#270050', '#0f0020'],
                    },
                primaryColor: 'primary',
                primaryShade: 4,
                breakpoints: {
                    xs: '30em',
                    sm: '48em', // Esta no la manejamos generalmente
                    md: '64em',
                    lg: '75em',
                    xl: '98em',
                },
                mediaQueries: {
                    xl: {
                        fontSizes: {
                            xl: '2.25rem',
                            lg: '1.75rem',
                            md: '1.25rem',
                            sm: '1rem',
                            xs: '0.75rem',
                        }
                    },
                    lg: {
                        fontSizes: {
                            xl: '2rem',
                            lg: '1.5rem',
                            md: '1rem',
                            sm: '0.85rem',
                            xs: '0.7rem',
                        }
                    },
                    md: {
                        fontSizes: {
                            xl: '2rem',
                            lg: '1.5rem',
                            md: '1rem',
                            sm: '0.85rem',
                            xs: '0.7rem',
                        }
                    },
                    sm: {
                        fontSizes: {
                            xl: '1.5rem',
                            lg: '1.25rem',
                            md: '1rem',
                            sm: '0.75rem',
                            xs: '0.6rem',
                        }
                    },
                    xs: {
                        fontSizes: {
                            xl: '1.5rem',
                            lg: '1.25rem',
                            md: '1rem',
                            sm: '0.75rem',
                            xs: '0.6rem',
                        }
                    }
                },
                lineHeight: 'normal',
                headings: {
                    fontFamily: 'Source Sans 3, sans-serif',
                },
                borderRadius: '0.6rem' | '0.8rem' | '1rem' | '1.2rem' | '1.5rem',
            }}>
                <Notifications />
                <App />
            </MantineProvider>
        </ApolloProvider>
    )
};


ReactDOM.render(
    <Router>
        <Auth0ProviderWithHistory>
            <AppWithApollo/>
        </Auth0ProviderWithHistory>
    </Router>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
