import React, {useContext, useEffect, useState} from 'react';
import './Billetera.css';
import {BilleteraCardPrimary} from "../../components/BilleteraCardPrimary";
import {BilleteraCardSecondary} from "../../components/BilleteraCardSecondary";
import {RegimenComisionarioCard} from "../../components/RegimenComisionarioCard";
import CotizarContext from "../../context/CotizarContext";
import {Carousel} from "@mantine/carousel";
import {CommissionsDetailsModal} from "../../features/Billetera/CommissionsDetailsModal";
import CommissionsHistoricalModal from "../../features/Billetera/CommissionsHistoricalModal/CommissionsHistoricalModal";
import {WithdrawFundsModal} from "../../features/Billetera/WithdrawFundsModal";
import {PendingCommisssionsInfoModal} from "../../features/Billetera/PendingCommisssionsInfoModal";
import {SettledCommissionsInfoModal} from "../../features/Billetera/SettledCommissionsInfoModal";
import axios from "axios";
import AuthContext from "../../context/AuthContext";
import UserContext from "../../context/UserContext";
import {currencyFormat} from "../../modules/utils/currencyFormat";
import {Skeleton} from "@mantine/core";
import {ErrorModal} from "../../layouts/ErrorModal";
import {SuccessModal} from "../../layouts/SuccessModal";

const penaltyCashText = (value) => `Las operaciones realizadas en efectivo, recibirán una liquidación
                        por única vez de $${currencyFormat(value)} 
                        luego de que se acredite el pago de la primera cuota.`

const commissionDictionary = {
    "Sancor": {
        "automotor": (value) => `Polko liquidará por única vez un total de
                        $${currencyFormat(value.general)} por póliza del ramo Automotor cargada y
                        emitida en el sistema.` + (value.efectivo ? ` ${penaltyCashText(value.efectivo)}` : ""),
        "motovehiculo": (value) => `Polko liquidará por única vez un total de
                        $${currencyFormat(value.general)} por póliza del ramo Motovehículo cargada y
                        emitida en el sistema luego de que se acredite el pago de la primera cuota.`,
        "hogar": (value) => `Polko liquidará por única vez el ${value.general}% del valor de la primera cuota de la
                        póliza del ramo Hogar cargada y emitida en el sistema 
                        luego de que se acredite el pago de la primera cuota.`,
        "microseguros": (value) => `Polko liquidará por única vez el ${value.general}% del valor de la primera cuota de la
                        póliza del ramo Microseguros cargada y emitida en el sistema 
                        luego de que se acredite el pago de la primera cuota.`,
    },
    "RUS": {
        "automotor": (value) => `Polko liquidará por única vez un total de
                        $${currencyFormat(value.general)} por póliza del ramo Automotor cargada y
                        emitida en el sistema.` + (value.efectivo ? ` ${penaltyCashText(value.efectivo)}` : ""),
        "motovehiculo": (value) => `Polko liquidará por única vez un total de
                        $${currencyFormat(value.general)} por póliza del ramo Motovehículo cargada y
                        emitida en el sistema luego de que se acredite el pago de la primera cuota.`,
    },
    "Zurich": {
        "automotor": (value) => ` Polko liquidará por única vez un total de $${currencyFormat(value.general)} por póliza del 
                        ramo Automotor cargada y emitida en el sistema luego de que se acredite el pago de la primera cuota.`
            + (value.efectivo ? ` ${penaltyCashText(value.efectivo)}` : ""),
    },
    "TerraWind": {
        "asistencia_viajero": (value, isMaster) => {
            if (isMaster) {
                return `Polko liquidará por única vez el equivalente al ${value.general}% del monto de la asistencia cargada y emitida en el sistema.
Por asistencias cargadas y emitidas por Polkistas bajo tu estructura, Polko liquidará por única vez el equivalente al ${value.general - value.polkista}% del monto de la asistencia  luego de que se acredite el pago de la misma.`
            } else {
                return `Polko liquidará por única vez el equivalente al ${value.polkista}% del monto de la asistencia cargada y emitida en el sistema luego de que se acredite el pago de la misma.`
            }
        },
    },
    "Federacion_Patronal": {
        "automotor": (value) => `Polko liquidará por única vez un total de
                        $${currencyFormat(value.general)} por póliza del ramo Automotor cargada y
                        emitida en el sistema.` + (value.efectivo ? ` ${penaltyCashText(value.efectivo)}` : "")
    }
}

function Billetera() {
    const {companiesDict} = useContext(CotizarContext);
    const {authToken, isMaster} = useContext(AuthContext);
    const {userDetalleComisiones} = useContext(UserContext);
    const [commissionDetailsModalOpen, setCommissionDetailsModalOpen] = useState(false);
    const [commissionHistoricalModalOpen, setCommissionHistoricalModalOpen] = useState(false);
    const [withdrawFundsModalOpen, setWithdrawFundsModalOpen] = useState(false);
    const [pendingCommissionInfoModalOpen, setPendingCommissionInfoModalOpen] = useState(false);
    const [settledCommissionInfoModalOpen, setSettledCommissionInfoModalOpen] = useState(false);

    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [errorModalContent, setErrorModalContent] = useState(null);

    const [comisionesInfoGenerated, setComisionesInfoGenerated] = useState(null);
    const [loading, setLoading] = useState(true);

    //Valores billetera
    const [balance, setBalance] = useState(0)
    const [pendingPayment, setPendingPayment] = useState(0)
    const [polizas, setPolizas] = useState(null)
    const [valorLiquidadas, setValorLiquidadas] = useState(0)
    const [valorEmisionesLiquidadas, setValorEmisionesLiquidadas] = useState(0)
    const [valorOtrosLiquidadas, setValorOtrosLiquidadas] = useState(0)
    const [valorPendientes, setValorPendientes] = useState(0)

    const getCommissionInfo = (company, prod, value) => {
        const companyInfo = commissionDictionary[company];
        if (!companyInfo) {
            return "Compañía no reconocida.";
        }

        const productCommission = companyInfo[prod];
        if (!productCommission) {
            return "Producto no reconocido.";
        }

        return productCommission(value, isMaster);
    }

    const comissionInfoGenerator = () => {
        let comisionesInfo = {}

        for (let company in userDetalleComisiones) {
            let comisionesInfoOfCompany = {}
            for (let product in userDetalleComisiones[company]) {
                if (companiesDict[company] && companiesDict[company]['products'].some(item => item.key === product.toLowerCase())) {
                    comisionesInfoOfCompany[product] = getCommissionInfo(company, product.toLowerCase(), userDetalleComisiones[company][product])
                }
            }

            comisionesInfo[company] = comisionesInfoOfCompany
        }

        return comisionesInfo;
    }

    useEffect(() => {
        setComisionesInfoGenerated(comissionInfoGenerator())
    }, []);

    const getBalanceHistorico = () => {
        let url = process.env.REACT_APP_BACK_SERVER + '/newgetBalanceHistorico';
        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            }
        }).then((resp) => {
            return resp.data;
        }).catch((err) => {
            console.log(err)
        });
    }

    const getInfoComisiones = () => {
        let url = process.env.REACT_APP_BACK_SERVER + '/newgetInfoAllComisiones';
        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            }
        }).then((resp) => {
            return resp.data;
        }).catch((err) => {
            console.log(err)
        });
    }

    const getBilleteraInfo = () => {
        return Promise.all([
            getBalanceHistorico(),
            getInfoComisiones(),
        ]);
    }

    useEffect(() => {
            if (loading) {
                getBilleteraInfo()
                    .then((resp) => {
                        setBalance(resp[0]['pagado'])
                        setPendingPayment(resp[0]['pendiente'])
                        setPolizas(resp[1])
                        const emisionesLiquidadasTotal = resp[1]['liquidas'].emisiones.reduce((total, emision) => total + emision['comision'], 0);
                        const otrosLiquidadosTotal = resp[1]['liquidas'].otros_incentivos.reduce((total, emision) => total + emision['comision'], 0);
                        const emisionesPendientesTotal = resp[1]['pendientes'].emisiones.reduce((total, emision) => total + emision['comision'], 0);
                        const otrosPendientesTotal = resp[1]['pendientes'].otros_incentivos.reduce((total, emision) => total + emision['comision'], 0);
                        setValorEmisionesLiquidadas(emisionesLiquidadasTotal);
                        setValorOtrosLiquidadas(otrosLiquidadosTotal);
                        setValorLiquidadas(emisionesLiquidadasTotal + otrosLiquidadosTotal);
                        setValorPendientes(emisionesPendientesTotal + otrosPendientesTotal);
                    })
                    .catch((err) => {
                        setErrorModalContent({
                            title: "Hubo un problema al solicitar la información, por favor vuelva a intentarlo más tarde.",
                            typeError: err.response?.data?.detail?.code || "Ha ocurrido un error en la comunicación con el servicio",
                            suggestedActions: ["Recargá la página", "Si el problema persiste ponete en contacto con el área de soporte"],
                            detailedError: err.response?.data?.detail?.message || "Sin datos del error",
                        })
                        setErrorModalOpen(true);
                    })
                    .finally(() => {
                        setLoading(false)
                    });
            }
        },
        [loading]
    );

    return (
        <>
            <div className="billetera__container">
                <div className="billetera__body">
                    <p className="billetera__title">Mi billetera</p>
                    <div className="billetera__cards__container">
                        <div className="billetera__cards__one">
                            <BilleteraCardPrimary
                                infoAction={() => setPendingCommissionInfoModalOpen(true)}
                                totalAmount={valorPendientes}
                                text={"Incentivos Pendientes"}
                                buttonAction={() => setCommissionDetailsModalOpen(true)}
                                buttonText={"Ver detalle"}
                                buttonColor={"primary"}
                                loading={loading}
                            />
                        </div>
                        <div className="billetera__cards__two">
                            <BilleteraCardPrimary
                                infoAction={() => setSettledCommissionInfoModalOpen(true)}
                                totalAmount={valorLiquidadas}
                                text={"Incentivos Disponibles"}
                                buttonAction={() => setWithdrawFundsModalOpen(true)}
                                buttonText={"Retirar fondos"}
                                buttonColor={"secondary"}
                                loading={loading}
                            />
                        </div>
                        <div className="billetera__cards__three">
                            <BilleteraCardSecondary
                                amount={balance}
                                pendingPayment={pendingPayment}
                                text={"Incentivos Acumulados"}
                                buttonAction={() => setCommissionHistoricalModalOpen(true)}
                                buttonText={"Ver historial"}
                                buttonColor={"tertiary"}
                                loading={loading}
                            />
                        </div>
                    </div>

                    <p className="billetera__title">Régimen de incentivos</p>
                    <p className="billetera__text">Por el presente, se enuncian las condiciones comisionales
                        establecidas para cada producto disponible en la plataforma.</p>

                    {/*TODO: Display the last update for user*/}
                    {/*<p className="billetera__note">Última actualización 12/01/2021</p>*/}

                    <div className="billetera__carousel__container">
                        {comisionesInfoGenerated
                            ? <div className="billetera__carousel">

                                <Carousel
                                    slideSize={[
                                        {maxWidth: '600px', size: 'small'},
                                        {maxWidth: '900px', size: 'medium'},
                                        {maxWidth: '1200px', size: 'large'},
                                        {size: 'xl'},]}
                                    slideGap="xl"
                                    slidesToScroll={1}
                                    controlsOffset="md"
                                    withControls={false}
                                    withIndicators={true}
                                    dragFree={false}
                                    controlSize={24}
                                    initialSlide={0}
                                    styles={{
                                        indicators: {
                                            bottom: 0,
                                            marginBottom: "-16px"
                                        },
                                        indicator: {
                                            marginTop: "16px",
                                            backgroundColor: "#FFF",
                                            border: "1px solid #C6C6C6",
                                            borderRadius: "50%",
                                            width: "8px",
                                            height: "8px",
                                            transition: 'width 250ms ease',

                                            '&[data-active]': {
                                                backgroundColor: "#8200FF"
                                            },
                                        },
                                    }}
                                >
                                    {Object.keys(comisionesInfoGenerated).sort((a, b) => {
                                        //Companies sorter, Sancor always first
                                        if (a === "Sancor") {
                                            return -1;
                                        } else if (b === "Sancor") {
                                            return 1;
                                        } else {
                                            return 0;
                                        }
                                    }).map(company => (
                                        <Carousel.Slide>
                                            <RegimenComisionarioCard
                                                companyImg={companiesDict[company]['logo']}
                                                comisionInfo={comisionesInfoGenerated[company]}
                                            />
                                        </Carousel.Slide>
                                    ))}
                                </Carousel>

                            </div>
                            : <Skeleton>
                                <div className="billetera__carousel--skeleton"/>
                            </Skeleton>
                        }
                    </div>
                </div>
            </div>

            {commissionDetailsModalOpen &&
                <CommissionsDetailsModal
                    polizas={polizas['pendientes']}
                    onClose={() => setCommissionDetailsModalOpen(false)}
                />
            }
            {commissionHistoricalModalOpen &&
                <CommissionsHistoricalModal
                    onClose={() => setCommissionHistoricalModalOpen(false)}
                />
            }
            {withdrawFundsModalOpen &&
                <WithdrawFundsModal
                    totalAmount={valorLiquidadas}
                    emmissionAmount={valorEmisionesLiquidadas}
                    othersAmount={valorOtrosLiquidadas}
                    polizas={polizas['liquidas']}
                    setErrorModalContent={setErrorModalContent}
                    setErrorModalOpen={setErrorModalOpen}
                    setSuccessModalOpen={setSuccessModalOpen}
                    setLoading={setLoading}
                    onClose={() => setWithdrawFundsModalOpen(false)}
                />
            }

            {pendingCommissionInfoModalOpen &&
                <PendingCommisssionsInfoModal onClose={() => setPendingCommissionInfoModalOpen(false)}/>
            }
            {settledCommissionInfoModalOpen &&
                <SettledCommissionsInfoModal onClose={() => setSettledCommissionInfoModalOpen(false)}/>
            }

            {errorModalOpen &&
                <ErrorModal
                    closeAction={() => {
                        setErrorModalOpen(false)
                    }}
                    title={errorModalContent.title}
                    typeError={errorModalContent.typeError}
                    suggestedActions={errorModalContent.suggestedActions}
                    detailedError={errorModalContent.detailedError}
                />
            }

            {successModalOpen &&
                <SuccessModal
                    closeAction={() => {
                        setSuccessModalOpen(false)
                    }}
                    title={"¡La operación se ha realizado con éxito!"}
                    body={
                        <div className="successModal__body__block">
                            <p className="successModal__subtitle">Más información:</p>
                            <p className="successModal__text">Este proceso puede demorar hasta 7 días hábiles en
                                impactar
                                en el CBU/CVU cargado. Su estado se visualizará en sus "Incentivos Acumulados".</p>
                        </div>
                    }
                />
            }
        </>
    );
}

export default Billetera;