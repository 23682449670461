import React, {useContext} from "react";
import {Route, Redirect,} from "react-router-dom";
import AuthContext from "../../context/AuthContext";


export default function MasterRoute({component: Component, ...rest}) {
    const {isMaster} = useContext(AuthContext);

    return (
        <Route {...rest} render={props => (
            isMaster ?
                <Component {...props} />
                : <Redirect to="/"/>
        )}/>
    );
}
