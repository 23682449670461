import React, {useContext, useState} from 'react';
import {Field, useFormikContext} from "formik";
import {Checkbox, Grid} from '@mantine/core';
import {IconBike, IconDeviceLaptop, IconDeviceTablet, IconLock} from "@tabler/icons";
import CotizarContext from "../../../../../../context/CotizarContext";
import {MantineNumberField} from "../../../../../../components/FormFields/MantineNumberField";
import UserContext from "../../../../../../context/UserContext";
import {CoveragesInformationPopover} from "../../../../../Cotizacion/CoveragesInformationPopover";
import {ReactComponent as InformationFilledIcon} from "../../../../../../assets/icons/ic_info-filled.svg";
import {splitTextInRange} from "../../../../../../modules/utils/splitTextInRange";
import {CustomSlider} from "../../../../../../components/CustomSlider";
import {CoberturasModel} from "./CoberturasModel";

const coberturasAdicionalesIcons = {
    26: <IconLock className="hogarCot__icon"/>,
    48: <IconBike className="hogarCot__icon"/>,
    36: <IconDeviceLaptop className="hogarCot__icon"/>,
    37: <IconDeviceTablet className="hogarCot__icon"/>,
}

export default function CoberturasForm() {
    const {formField} = CoberturasModel();
    const {
        descuento,
        configuracionAvanzada
    } = formField;
    const {coberturasInfo, coberturasAdicionalesOrder} = useContext(CotizarContext);
    const {isMobile, userDiscounts} = useContext(UserContext);
    const {values, setFieldValue} = useFormikContext();
    const [coberturaForPopover, setCoberturaForPopover] = useState(null);
    const hardcodedCompany = "Sancor";

    return (
        <div className="hogar__formStep__container">
            <Grid gutterXs="xs" gutter="md" justify="center" align="center">

                {Object.keys(values.coberturas).map(cobertura => {
                    return (
                        <>
                            <Grid.Col xs={7} sm={7}>
                                <p className="hogarCot__text">{coberturasInfo[cobertura].descripcion}</p>
                            </Grid.Col>
                            <Grid.Col span="content" xs={4} sm={4}>
                                <MantineNumberField
                                    name={`coberturas.${cobertura}.sumaAsegurada`}
                                    min={coberturasInfo[cobertura]['sumaMinima']}
                                    max={coberturasInfo[cobertura]['sumaMaxima']}
                                />
                            </Grid.Col>
                            <Grid.Col span="content" xs={1} sm={1}>
                                <div className="hogarCot__coberturasForm__infoIcon__container" onClick={() => setCoberturaForPopover(cobertura)}>
                                    <InformationFilledIcon className="hogarCot__coberturasForm__infoIcon"/>
                                </div>
                            </Grid.Col>
                        </>
                    )
                })}
            </Grid>

            <p className="hogarCot__subtitle">Adicionales</p>

            <Grid gutterXs="xs" gutter="md" justify="center" align="center">
                {coberturasAdicionalesOrder.map((cobertura) => {
                    const coberturaValue = values.coberturasAdicionales[cobertura];
                    return (
                        <>
                            <Grid.Col span="content" xs={1} sm={1}>
                                <Checkbox
                                    checked={coberturaValue.selectedCobertura}
                                    classNames={{
                                        input: "hogar__cotSuccess__checkbox"
                                    }}
                                    size="sm"
                                    onChange={(e) => {
                                        const {checked} = e.target;
                                        setFieldValue(`coberturasAdicionales.${cobertura}`, {
                                            selectedCobertura: checked,
                                            sumaAsegurada: values['coberturasAdicionales'][cobertura]['sumaAsegurada']
                                        });
                                    }}
                                />
                            </Grid.Col>
                            <Grid.Col span="content" xs={6} sm={1}>
                                {coberturasAdicionalesIcons[cobertura]}
                            </Grid.Col>
                            <Grid.Col span="content" xs={6} sm={5}
                                      style={{width: "50%"}}
                            >
                                <p className="hogarCot__text--tiny">{coberturasInfo[cobertura].descripcion}</p>
                            </Grid.Col>
                            {isMobile &&
                            <Grid.Col span="content" xs={1} sm={1}>
                                <div className="hogarCot__coberturasForm__infoIcon__container" onClick={() => setCoberturaForPopover(cobertura)}>
                                    <InformationFilledIcon className="hogarCot__coberturasForm__infoIcon"/>
                                </div>
                            </Grid.Col>
                            }
                            <Grid.Col xs={4} sm={4}
                                      style={{
                                          visibility: coberturaValue?.selectedCobertura ? "visible" : "hidden",
                                          height: isMobile
                                              ? (coberturaValue?.selectedCobertura ? "100%" : "0px")
                                              : "100%"
                                      }}>
                                <MantineNumberField
                                    name={`coberturasAdicionales.${cobertura}.sumaAsegurada`}
                                    min={coberturasInfo[cobertura]['sumaMinima']}
                                    max={coberturasInfo[cobertura]['sumaMaxima']}
                                />
                            </Grid.Col>
                            {!isMobile &&
                            <Grid.Col xs={1} sm={1}>
                                <div className="hogarCot__coberturasForm__infoIcon__container" onClick={() => setCoberturaForPopover(cobertura)}>
                                    <InformationFilledIcon className="hogarCot__coberturasForm__infoIcon"/>
                                </div>
                            </Grid.Col>
                            }
                        </>
                    )
                })}
            </Grid>

            <p className="hogarCot__subtitle">{descuento.label}</p>
            <div className="hogarCot__discounts__container">
                <Field
                    name={`${configuracionAvanzada.name}.${hardcodedCompany}.${descuento.name}`}
                    max={userDiscounts[hardcodedCompany]['HOGAR']['general']}
                    min={0}
                    interval={5}
                    component={CustomSlider}
                />
            </div>

            {coberturaForPopover &&
            <CoveragesInformationPopover
                onClose={() => setCoberturaForPopover(null)}
                title={coberturasInfo[coberturaForPopover].descripcion}
                descripcion={splitTextInRange(coberturasInfo[coberturaForPopover].descripcionCompleta, 350, 500)}
            />
            }
        </div>
    );
}
