import './AgreementDetail.css';
import { currencyFormat } from '../../../../modules/utils/currencyFormat';

import { ReactComponent as HourglassIcon } from '../../../../assets/icons/ic_hourglass.svg';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/ic_calendar-minimal.svg';
import { ReactComponent as RewardIcon } from '../../../../assets/icons/ic_gift.svg';
import { ReactComponent as GoalIcon } from '../../../../assets/icons/ic_target.svg';
import { ReactComponent as DashIcon } from '../../../../assets/icons/ic_dash.svg';

const AgreementDetail = (props) => {
    const { acuerdo, endDate, daysLeft, daysLeftForCooldown, cooldownHasEnded } = props;
    const status = acuerdo?.estado;

    const useFullDate = daysLeft > 14;
    const agreementEndsSoon = daysLeft => 0 && daysLeft <= 5;
    const agreementHasEnded = daysLeft < 0;

    const formatDaysLeft = (daysLeft) => {
        if (useFullDate) {
            return endDate.toLocaleDateString('es-AR', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit'
            });
        } else {
            return `${daysLeft < 10 ? '0' : ''}${daysLeft} DÍAS`;
        }
    }

    const AgreementItem = (props) => {
        const { icon, title, value, highlight = false, empty = false } = props;

        return (
            <div className={`MWallet__agreementDetail-item ${empty && 'empty'}`}>
                <div className="MWallet__agreementDetail-section">
                    {icon}
                    <span
                        className="MWallet__agreementDetail-title">{title}</span>
                </div>
                {!acuerdo ? <DashIcon className='MWallet__agreementDetail-DashICon' /> : <span
                    className={`MWallet__agreementDetail-value ${highlight && 'highlight'}`}>{value}</span>}
            </div>
        );
    }

    if (cooldownHasEnded || !acuerdo) {
        return (
            <div
                className={`MWallet__agreementDetail empty`}>
                <AgreementItem
                    icon={<CalendarIcon className="MWallet__agreementDetail-icon" />}
                    title="Restan"
                    value={<DashIcon className='MWallet__agreementDetail-DashICon' />}
                    empty={true} />
                <AgreementItem
                    icon={<RewardIcon className="MWallet__agreementDetail-icon" />}
                    title="Incentivo"
                    value={<DashIcon className='MWallet__agreementDetail-DashICon' />}
                    empty={true} />
                <AgreementItem icon={<GoalIcon className="MWallet__agreementDetail-icon" />}
                    title="Objetivo"
                    value={<DashIcon className='MWallet__agreementDetail-DashICon' />}
                    empty={true} />
            </div>
        );
    }
    else if (agreementHasEnded) {
        return (
            <div
                className={`MWallet__agreementDetail--sign`}>
                <p className='MWallet__agreementDetail--sign-caption'>RESTAN {daysLeftForCooldown} DÍAS PARA EL PRÓXIMO ACUERDO</p>
                <HourglassIcon className='MWallet__agreementDetail--sign-hourglassIcon' />
            </div>
        );
    }
    else if (status === "PENDIENTE") {
        return (
            <div className="MWallet__agreementDetail--sign">
                <p className="MWallet__agreementDetail--sign-caption">PENDIENTE DE APROBACIÓN</p>
                <HourglassIcon className="MWallet__agreementDetail--sign-hourglassIcon" />
            </div>
        );
    } else {
        return (
            <div
                className={`MWallet__agreementDetail ${agreementEndsSoon ? ' highlight' : ''}`}>
                <AgreementItem
                    icon={<CalendarIcon className="MWallet__agreementDetail-icon" />}
                    title={`${useFullDate ? `Hasta` : `Restan`}`}
                    value={formatDaysLeft(daysLeft)}
                    highlight={agreementEndsSoon} />
                <AgreementItem
                    icon={<RewardIcon className="MWallet__agreementDetail-icon" />}
                    title="Incentivo"
                    value={`$${currencyFormat(acuerdo.monto)}`}
                    highlight={agreementEndsSoon} />
                <AgreementItem icon={<GoalIcon className="MWallet__agreementDetail-icon" />}
                    title="Objetivo"
                    value={`${acuerdo.emisiones_success.length + acuerdo.emisiones_pending.length}/${acuerdo.emisiones}`}
                    highlight={agreementEndsSoon} />
            </div>
        );
    }
}

export default AgreementDetail;