import React from 'react';
import {QuotationResponseTab} from "../../../../../../Cotizacion/QuotationResponseTab";
import {ReactComponent as InformationFilledIcon} from "../../../../../../../assets/icons/ic_info-filled.svg";

export const getTabsInfo = (companies, userCompanies, companiesDict, activeTab, setActiveTab, setCompanyForModal, tabHandlers) => {
    const tabs = companies.map((company, index) => {
        const isPromotional = !userCompanies.includes(companiesDict[company].name);
        return (
            <div className="automotor__cotSuccess__tabLogoContainer" onClick={() => setActiveTab(index)}>
                <img
                    className={`automotor__cotSuccess__tabLogo ${index !== activeTab && "automotor__cotSuccess__tabLogoContainer--blur"}`}
                    src={companiesDict[company]['logo']}
                    alt={companiesDict[company]['name']}/>
                {isPromotional && (
                    <InformationFilledIcon className="automotor__cotSuccess__promotionalMoreInfo"
                                           onClick={() => setCompanyForModal(companiesDict[company].razonSocial)}/>
                )}
            </div>
        );
    });

    const tabContent = companies.map(company => (
        <QuotationResponseTab
            {...tabHandlers}
            onEmitirClick={(coverage) => tabHandlers.onEmitirClick(company, coverage)}
            company={company}
        />
    ));

    return {tabs, tabContent};
}
