import React from 'react';

const DefaultValuesContext = React.createContext({

    valuesMemo: {},
    companiesDefaultValues: {},
    modified: false,
    isReady: false

});

export default DefaultValuesContext;