import React from 'react';

const DashboardContext = React.createContext({

    cotizaciones: null,
    emisiones: null,
    infoCotizaciones: null,
    infoCotizacionesCD: null,
    billeteraData: null,
    fecha: null,
    datosCoberturas: null,
    rankingEmisores: null,
    dashboardContextError: null,
    setDashboardContextError: null,
    modified: null,
    isReady: null,
    newsletterData: null,
    handleModified: null,
    currentAcuerdo: null,
    setCurrentAcuerdo: null,
    beneficiosDisponibles: null,
    // handleNewsletterData: null,
});

export default DashboardContext;