import axios from "axios";

function getMotoModels(authToken, marca, anio) {
    let carVersionsUrl = process.env.REACT_APP_BACK_SERVER + '/getVersionesMotos'
    return axios.get(carVersionsUrl, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        },
        params: {
            'marca': marca,
            'anio': anio
        }
    });
}

export default getMotoModels;