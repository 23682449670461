import {PopUpCardModal} from "../../../../../../../layouts/PopUpCardModal";
import React from "react";

const DiscountNewClientPopup = ({ onClose }) => {
    return (
        <PopUpCardModal title={<h1 className="csm__title">Condición de Nuevo Cliente</h1>}
                        body={
                            <p className="csm__popupBodyText">
                                <h5>¿A quién se considera <span className="--italic">cliente nuevo</span>?</h5>
                                <ul>
                                    <li>No tener póliza contratada con Federación Patronal desde hace 6 meses o más.</li>
                                    <li>Nunca haber tenido alguna póliza vigente en Federación Patronal.</li>
                                    <li>Vehículos asegurables:
                                        <ul>
                                            <li>Autos de hasta 10 años de antigüedad.</li>
                                        </ul>
                                    </li>
                                </ul>
                                <h5>¿Qué beneficios tiene?</h5>
                                <ul>
                                    <li>Tarifa más agresiva:
                                        <ul>
                                            <li>Descuento adicional del 10%.</li>
                                        </ul>
                                    </li>
                                </ul>
                                <h5>¿Tiene rebaja comisional?</h5>
                                <ul>
                                    <li>Sí, aplica un descuento comisional del 5% de la prima.
                                        <ul>
                                            <li>Reintegro del 100% al productor al cumplir un año de vigencia sin siniestros.
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <h5>¿Cómo puedo verificar si es <span className="--italic">cliente nuevo?</span></h5>
                                <ol>
                                    <li>Seleccionar el descuento para cliente nuevo.</li>
                                    <li>Hacer click en "cotizar".</li>
                                    <li>Elegir la cobertura a emitir.</li>
                                    <li>Ingresar el DNI del cliente.</li>
                                    <li>Desde Polko, haremos la validación con la aseguradora y te informaremos si aplica.</li>
                                </ol>
                            </p>}
                        closeAction={onClose}/>
    );
}

export default DiscountNewClientPopup;