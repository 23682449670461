import React, {useState} from 'react';
import './PopOverInfo.css';
import {Popover} from "@mantine/core";
import {ReactComponent as InformationFilledIcon} from "../../assets/icons/ic_info-filled.svg";

function PopOverInfo(props) {
    const {descripcion, onClick} = props;
    const [opened, setOpened] = useState(false);
    return (
        <Popover withArrow arrowPosition="side" position="bottom-end"
                 shadow={"0 2px 2px rgba(0, 0, 0, 0.25)"}
                 width={"auto"}
                 opened={opened}
                 onChange={setOpened}
                 classNames={{dropdown: "popOverInfo__dropdown"}}
        >
            <Popover.Target>
                <div className="popOverInfo__icon__container"
                     onClick={onClick}
                     onMouseEnter={() => setOpened(true)}
                     onMouseLeave={() => setOpened(false)}
                >
                    <InformationFilledIcon className="popOverInfo__icon"/>
                </div>
            </Popover.Target>
            <Popover.Dropdown>
                <p className="popOverInfo__text">{descripcion}</p>
            </Popover.Dropdown>
        </Popover>
    );
}

export default PopOverInfo;