import React, {useContext, useState} from 'react';
import {useFormikContext} from 'formik'
import {SolicitudModel} from "./SolicitudModel";
import CotizarContext from "../../../../../../context/CotizarContext";
import {Grid} from "@mantine/core";
import {currencyFormat} from "../../../../../../modules/utils/currencyFormat";
import {MantineInputField} from "../../../../../../components/FormFields/MantineInputField";
import {useCurrentQuotationContext} from "../../../../../../context/CurrentQuotationContext/CurrentQuotationContext";
import UserContext from "../../../../../../context/UserContext";
import {CoveragesInformationPopover} from "../../../../../Cotizacion/CoveragesInformationPopover";
import {splitTextInRange} from "../../../../../../modules/utils/splitTextInRange";
import {ReactComponent as InformationFilledIcon} from "../../../../../../assets/icons/ic_info-filled.svg";
import {IconFirstAidKit, IconPhone, IconPlaneDeparture} from "@tabler/icons";

export default function SolicitudForm() {
    const {formField} = SolicitudModel();
    const {comentariosSolicitud} = formField;
    const {values} = useFormikContext();
    const {coberturasInfo, companiesDict, coberturasAdicionalesOrder} = useContext(CotizarContext);
    const {isMobile} = useContext(UserContext);
    const {cotizacionResponse, finalValues} = useCurrentQuotationContext();
    const [coberturaForPopover, setCoberturaForPopover] = useState(null);
    const coberturasSinCostoIcons = {
        44: <IconPlaneDeparture className="microsegurosCot__icon--noCost"/>,
        45: <IconFirstAidKit className="microsegurosCot__icon--noCost"/>,
        50: <IconPhone className="microsegurosCot__icon--noCost"/>,
    }

    return (
        <React.Fragment>
            <Grid justify="center" align="center" gutterXs="xs">
                <Grid.Col span="content" xs={7} sm={7} style={isMobile ? {width: "30%", maxWidth: "30%"} : {}}>
                    <p className="microseguros__detForm__title">
                        Cobertura
                    </p>
                </Grid.Col>
                <Grid.Col span="content" xs={4} sm={4} style={isMobile ? {width: "60%", maxWidth: "60%"} : {}}>
                    <p className="microseguros__detForm__title" style={{textAlign: "right"}}>
                        Suma Asegurada
                    </p>
                </Grid.Col>
                <Grid.Col xs={1} sm={1} style={isMobile ? {width: "10%", maxWidth: "10%"} : {}}/>
            </Grid>

            {Object.keys(values.coberturasAdicionales).sort((a, b) => {
                const indexA = coberturasAdicionalesOrder.indexOf(values.coberturasAdicionales[a].numeroCobertura);
                const indexB = coberturasAdicionalesOrder.indexOf(values.coberturasAdicionales[b].numeroCobertura);
                return indexA - indexB;
            }).map(cobertura => {
                if (values.coberturasAdicionales[cobertura].selectedCobertura) {
                    return (
                        <>
                            <Grid justify="center" align="center" gutterXs="xs">
                                <Grid.Col xs={7} sm={7} style={isMobile ? {width: "55%", maxWidth: "55%"} : {}}>
                                    <p className="microsegurosCot__text">{coberturasInfo[cobertura].descripcion}</p>
                                </Grid.Col>
                                <Grid.Col xs={4} sm={4} style={isMobile ? {width: "35%", maxWidth: "35%"} : {}}>
                                    <p className="microsegurosCot__text--right">$ {currencyFormat(values.coberturasAdicionales[cobertura].sumaAsegurada)}</p>
                                </Grid.Col>
                                <Grid.Col xs={1} sm={1} style={isMobile ? {width: "10%", maxWidth: "10%"} : {}}>
                                    <div onClick={() => setCoberturaForPopover(cobertura)}>
                                        <InformationFilledIcon className="microseguros__coberturasForm__infoIcon"/>
                                    </div>
                                </Grid.Col>
                            </Grid>

                            {/*DETALLES BICICLETAS*/}
                            {cobertura === '48' &&
                            values.detalleBicicletas.map((item) => {
                                return (
                                    <>
                                        <Grid justify="center" align="center" gutterXs="xs">
                                            <Grid.Col xs={7} sm={7} style={isMobile ? {width: "55%", maxWidth: "55%"} : {}}>
                                                <p className="microsegurosCot__text microsegurosCot__text--grey microsegurosCot__text--tabbed">{item.descripcionBicicletas}</p>
                                            </Grid.Col>
                                            <Grid.Col xs={4} sm={4} style={isMobile ? {width: "35%", maxWidth: "35%"} : {}}>
                                                <p className="microsegurosCot__text--right microsegurosCot__text--grey">$ {currencyFormat(item.sumaAseguradaBicicletas)}</p>
                                            </Grid.Col>
                                            <Grid.Col xs={1} sm={1} style={isMobile ? {width: "10%", maxWidth: "10%"} : {}}/>
                                        </Grid>
                                    </>
                                )
                            })
                            }
                            {/*DETALLES OBJETOS VARIOS*/}
                            {cobertura === '26' &&
                            values.detalleEquipoElectronico.map((item) => {
                                return (
                                    <>
                                        <Grid justify="center" align="center" gutterXs="xs">
                                            <Grid.Col xs={7} sm={7} style={isMobile ? {width: "55%", maxWidth: "55%"} : {}}>
                                                <p className="microsegurosCot__text microsegurosCot__text--grey microsegurosCot__text--tabbed">{item.descripcionElectronico}</p>
                                            </Grid.Col>
                                            <Grid.Col xs={4} sm={4} style={isMobile ? {width: "35%", maxWidth: "35%"} : {}}>
                                                <p className="microsegurosCot__text--right microsegurosCot__text--grey">$ {currencyFormat(item.sumaAseguradaElectronico)}</p>
                                            </Grid.Col>
                                            <Grid.Col xs={1} sm={1} style={isMobile ? {width: "10%", maxWidth: "10%"} : {}}/>
                                        </Grid>
                                    </>
                                )
                            })
                            }
                        </>

                    )
                }
            })}


            <div className="microseguros__solicitud__noCost">
                <p className="microseguros__detForm__title">
                    Beneficios sin costo
                </p>

                {Object.keys(coberturasSinCostoIcons).map((coverage) => (
                    <div className="microseguros__solicitud__block">
                        {coberturasSinCostoIcons[coverage]}
                        <p className="microsegurosCot__text">{coberturasInfo[coverage].descripcion}</p>
                    </div>
                ))}
            </div>

            <div className="microseguros__solicitud__block">
                <p className="microseguros__cotSuccess__note">Nota: si deseas modificar las coberturas seleccionadas debes realizar una nueva cotización con las
                    coberturas deseadas.</p>
            </div>

            <div className="microseguros__solicitud__block">
                <MantineInputField
                    style={{width: "100%"}}
                    name={comentariosSolicitud.name}
                    label={comentariosSolicitud.label}
                />
            </div>

            <div className="microseguros__solicitud__footer">
                <p className="autem__solicitud__premium">
                    {/*Add logic to select company*/}
                    {`Cuota mensual: $${currencyFormat(cotizacionResponse[companiesDict[finalValues["company"][0]]["name"]]["premioMensual"])}`}
                </p>
            </div>

            {coberturaForPopover &&
            <CoveragesInformationPopover
                onClose={() => setCoberturaForPopover(null)}
                title={coberturasInfo[coberturaForPopover].descripcion}
                descripcion={splitTextInRange(coberturasInfo[coberturaForPopover].descripcionCompleta, 350, 500)}
            />
            }
        </React.Fragment>
    );
}
