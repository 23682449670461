import "./NotificationBar.css"
import {ReactComponent as NotificationIcon} from '../../../assets/icons/ic_notification.svg';
import React from "react";
import moment from "moment";
import InteractiveBar from "../../../components/InteractiveBar/InteractiveBar";
import store from "store";

function NotificationBar(props) {
    const {
        tableRow,
        checkBoxEnabled,
        details,
        notificationsMarkedArr,
        onCheckboxChange,
        tiny
    } = props;
    const notificationRead = tableRow.leido || store.get("readGlobalNotifications")?.includes(tableRow.id);


    const handleOpenDetails = () => {
        details(tableRow);
    };

    function handleCheckboxChange(e, id) {
        const isChecked = e.target.checked
        onCheckboxChange(id, isChecked, notificationRead);
    }

    return (

        <InteractiveBar title={tableRow.titulo} body={tableRow.mensaje}
                        footer={moment(tableRow.created_at).format("DD/MM/YYYY - HH:mm") + " hs."} extra={
            <div className="NBar__extraContainer">
                {notificationRead ? <div className={tiny ? "NBar__tinyUnreadNotification" : "NBar__unreadNotification"}></div> :
                    <NotificationIcon className={tiny ? "NBar__tinyUnreadNotification" : "NBar__unreadNotification"}/>}
                {checkBoxEnabled ?
                    <div>
                        <input className="NBar__checkbox" type="checkbox" id={tableRow.id} value={tableRow.id}
                               onChange={(e) => handleCheckboxChange(e, tableRow)}
                               checked={notificationsMarkedArr[tableRow.id]}/>
                        <label className="NBar__label" htmlFor={tableRow.id}></label>
                    </div> : ""}</div>
        } tiny={tiny} handlerClick={handleOpenDetails}/>
    )
}

export default NotificationBar;
