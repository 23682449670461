import React, {useRef, useState} from 'react';
import './LogoColumn.css';

function LogoColumn(props) {
    const {row, setModalOpen} = props;
    const { logo } = row;
    const [showedLogo, setShowedLogo] = useState(logo);
    const fileInputRef = useRef(null);

    const handleImageSelect = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            const file = event.target.files[0];
            reader.addEventListener('load', () => {
                    handleOpenModal(reader.result, file, setShowedLogo)
                }
            );
            reader.readAsDataURL(file);

            if (fileInputRef.current) {
                fileInputRef.current.value = null;
            }
        }
    };

    const handleOpenModal = (_logo, _file, _setShowedLogo) => {
        setModalOpen({
            modalName: "Logo",
            values: {row: row, logo: _logo, srcFile: _file, setShowedLogo: _setShowedLogo}
        })
    }

    return (
        <>
            <label className="logoColumn__container">
                <img className="logoColumn__image" src={showedLogo} alt="p_logo"/>
                <input
                    name="file"
                    type="file"
                    ref={fileInputRef}
                    hidden
                    onChange={(event) => handleImageSelect(event)}
                    accept="image/png, image/jpeg"
                />
            </label>
        </>
    );
}

export default LogoColumn;