import React from 'react';
import * as Yup from "yup";

const CoberturasModel = () => {
    const formField = {
        coberturas: { //Object
            name: 'coberturas',
        },
        sumaAsegurada: {
            name: 'sumaAsegurada',
            label: 'Suma asegurada',
            requiredErrorMsg: 'Debe completar este campo'
        },
        numeroCobertura: {
            name: 'numeroCobertura',
            label: 'Número cobertura',
            requiredErrorMsg: 'Debe completar este campo'
        },
        selectedCobertura: {
            name: 'selectedCobertura',
        },
        coberturasAdicionales: { //Object
            name: 'coberturasAdicionales',
        },
        sumaAseguradaAdicional: {
            name: 'sumaAsegurada',
            label: 'Suma asegurada',
            requiredErrorMsg: 'Debe completar este campo'
        },
        numeroCoberturaAdicional: {
            name: 'numeroCobertura',
            label: 'Número cobertura',
            requiredErrorMsg: 'Debe completar este campo'
        },
        selectedCoberturaAdicional: {
            name: 'selectedCoberturaAdicional',
        },
        descuento: {
            name: 'descuento',
            label: 'Descuento',
        },
        configuracionAvanzada: {
            name: 'configuracionAvanzada',
        },
    }

    const initialValues = {
        [formField.coberturas.name]: {
            1: {[formField.sumaAsegurada.name]: 0},
            2: {[formField.sumaAsegurada.name]: 0},
            17: {[formField.sumaAsegurada.name]: 0},
            22: {[formField.sumaAsegurada.name]: 0},
            35: {[formField.sumaAsegurada.name]: 0},
            38: {[formField.sumaAsegurada.name]: 0},
        },
        [formField.coberturasAdicionales.name]: {
            26: {
                [formField.sumaAsegurada.name]: 0,
                [formField.selectedCobertura.name]: false,
            },
            36: {
                [formField.sumaAsegurada.name]: 0,
                [formField.selectedCobertura.name]: false,
            },
            37: {
                [formField.sumaAsegurada.name]: 0,
                [formField.selectedCobertura.name]: false,
            },
            48: {
                [formField.sumaAsegurada.name]: 0,
                [formField.selectedCobertura.name]: false,
            },
        },
        [formField.configuracionAvanzada.name]: {
            Sancor: {
                [formField.descuento.name]: 0,
            }
        }
    }

    const validationSchema = Yup.object().shape({})

    return {formField, initialValues, validationSchema}
}

export {CoberturasModel};
