import React, {useContext} from 'react';
import {Grid} from "@material-ui/core";
import Typography from "../../components/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Axios from "axios";
import UserContext from "../../../context/UserContext";

const FileDownload = require('js-file-download');

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        width: '95vw',

    },
    titulomodal: {
        textAlign: 'center',
        fontSize: '24px',
        color: '#452d7b',
        fontFamily: 'Ubuntu',
        fontWeight: 'bold',
        letterSpacing: '-0.5',
        marginBottom: '15px'
    },
    rootModal: {
        display: 'flex', alignItems: 'center', justifyContent: 'center',

    },
    gridTarjeta: {
        borderRadius: '8px',
        boxShadow: '0 5px 40px 0 rgba(70, 70, 70, 0.2)',
        backgroundColor: '#fff',
        margin: '10px 0 10px',
        flexDirection: 'row',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            padding: '10px'
        },
    },
    divRectangle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent:'center',
        padding: '4px 16px',
        height: 'fit-content',
        background: 'rgba(255, 255, 255, 0.1)',
        //mixBlendMode: 'hard-light',
        borderRadius: '5px',
        width: '174px',
        boxShadow: '0 5px 40px 0 rgba(70, 70, 70, 0.2)',
        marginBottom:'16px',

    },
    subtituloGrid: {
        textAlign: 'center',
        fontSize: '16px',
        color: '#35303f',
        fontFamily: 'Ubuntu',
        fontWeight: 'bold',
        letterSpacing: '-0.5',
    },

}));


function PersonalesList(props) {
    const classes = useStyles();
    const {userMail,userName,userLastName, userPicture,userMatricula,userCargo,userProvince,userPhone} = useContext(UserContext);

    function tarjetapersonal() {

        let mail = userMail;
        let telefono = userPhone;
        let ubicacion = `${userProvince}, Argentina`;
        let nombre = userName + " " + userLastName;
        let cargo = userCargo;
        let matriculaMaster = userMatricula;

        Axios.post(process.env.REACT_APP_BACK_SERVER + `/tarjetapersonal?nombre=${nombre}&mail=${mail}&telefono=${telefono}&ubicacion=${ubicacion}&cargo=${cargo}&matriculaMaster=${matriculaMaster}`, {}, {responseType: 'blob'})
            .then((response) => {
                FileDownload(response.data, 'tarjeta.png');
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function pieMail() {
        let mail = userMail;
        let telefono = userPhone;
        let ubicacion = `${userProvince}, Argentina`;
        let nombre = userName + " " + userLastName;
        let cargo = userCargo;
        let matriculaMaster = userMatricula;
        let foto = userPicture;

        Axios.post(process.env.REACT_APP_BACK_SERVER + `/piedemail?nombre=${nombre}&mail=${mail}&telefono=${telefono}&ubicacion=${ubicacion}&image=${foto}&cargo=${cargo}&matriculaMaster=${matriculaMaster}`, {}, {responseType: 'blob'})
            .then((response) => {
                FileDownload(response.data, 'piemail.png');
            })
            .catch((error) => {
                console.log(error);
            });
    }


    return (
        <React.Fragment>
            <div >


                <Grid container direction={"column"} className={classes.rootModal}>
                    <div className={classes.divRectangle}
                         onClick={tarjetapersonal}
                    >

                        <Typography className={classes.subtituloGrid}>
                            Tarjeta Personal
                        </Typography>

                    </div>

                    <div className={classes.divRectangle}
                         onClick={pieMail}
                    >


                        <Typography className={classes.subtituloGrid}>
                            Membrete de Mail
                        </Typography>
                        {/*<div container direction={"row"}*/}
                        {/*      style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}*/}

                        {/*>*/}
                        {/*      <Grid item>*/}
                        {/*        <Typography className={classes.subtituloGrid}>*/}
                        {/*            Membrete de Mail*/}
                        {/*        </Typography>*/}
                        {/*    </Grid>*/}


                        {/*</div>*/}
                    </div>

                </Grid>
            </div>
        </React.Fragment>

    );
}

export default PersonalesList;