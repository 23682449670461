/**
 * This function is used to transform quotation values into emission values
 * @param valuesToModify
 * @param cotizacionResponse
 * @param finalValues
 * @param userDetalleComisiones
 * @param userCodigosOperacion
 * @param userDiscounts
 * @returns emissionInitialValues
 */
const automotorQuotationToEmissionValues = (valuesToModify, cotizacionResponse, finalValues, userDetalleComisiones, userCodigosOperacion, userDiscounts, discountType, descuento) => {
    const premio = discountType === "premio" ? Math.ceil((Math.ceil(valuesToModify.rowData.premium) * parseInt(valuesToModify.configuracionAvanzada[valuesToModify['company']].cuotas)) * (1 - (descuento / 100))) : parseInt(valuesToModify.rowData.premium);
    valuesToModify = {
        ...valuesToModify,
        "idCotizacion": cotizacionResponse['idCotizar'],
        "premio": premio,
        "idCobertura": valuesToModify.rowData.id,
        "vehiculo": {
            ...valuesToModify["vehiculo"],
            ...finalValues.vehiculo,
            "sumaAseguradaVehiculo": cotizacionResponse[valuesToModify['company']]['sumaAseguradaVehiculo'],
            "tieneGnc": finalValues.vehiculo.tieneGnc === "0" ? "No" : "Si",
            "gnc": {
                ...valuesToModify["vehiculo"]["gnc"],
                "sumaAseguradaGnc": finalValues.vehiculo.sumaAseguradaGnc
            },
        },
        "clientes": [
            {
                ...valuesToModify['clientes'][0],
                "situacionImpositiva": finalValues.situacionImpositiva,
                "tipoPersona": finalValues.tipoPersona,
                "codigosLocalidad": finalValues.codigosLocalidad,
                "codigoPostal": finalValues.codigoPostal,
                "idProvincia": finalValues.idProvincia
            }
        ],
    }

    valuesToModify['parametrosAdicionales'] = valuesToModify['configuracionAvanzada'][valuesToModify['company']]
    valuesToModify["parametrosAdicionales"]['descuentoEspecial'] = valuesToModify.descuentos[valuesToModify['company']]
    valuesToModify["parametrosAdicionales"]['descuentoFueraPauta'] = userDiscounts[valuesToModify['company']]['AUTOMOTOR']?.fuera_pauta

    if (finalValues.origen === 'CLIENTES') {
        valuesToModify.clientes[0].nombre = finalValues.cliente.nombre
        valuesToModify.clientes[0].apellido = finalValues.cliente.apellido
        valuesToModify.clientes[0].email = finalValues.cliente.email
        valuesToModify.clientes[0].telefono = finalValues.cliente.telefono
    }

    if (valuesToModify['company'] === "Sancor") {
        valuesToModify.vigenciaDesde = finalValues.configuracionAvanzada.Sancor.fechaCotizacion;
        valuesToModify.parametrosAdicionales.outStandard = valuesToModify.rowData.parametrosAdicionales.outStandardSancor === "true"
        valuesToModify.parametrosAdicionales.equipoDeRastreo = valuesToModify.rowData.parametrosAdicionales.trackingEquipment
        valuesToModify.parametrosAdicionales.tipoUso = finalValues.configuracionAvanzada.Sancor.usoVehiculo;
        valuesToModify.parametrosAdicionales.comision = userDetalleComisiones['Sancor']['AUTOMOTOR']['general'];
        valuesToModify.parametrosAdicionales.descuentoModelo = 99; //MAX ALLOWED


    } else if (valuesToModify['company'] === "RUS") {
        valuesToModify.vigenciaDesde = finalValues.configuracionAvanzada.RUS.vigenciaDesde;
        valuesToModify.parametrosAdicionales.codigoRC = valuesToModify.rowData.parametrosAdicionales.codigoRC;
        valuesToModify.parametrosAdicionales.comision = userDetalleComisiones['RUS']['AUTOMOTOR']['general'];
        valuesToModify.nombreCobertura += ` [${valuesToModify.rowData.parametrosAdicionales.codigoRC}]`
    } else if (valuesToModify['company'] === "Zurich") {
        valuesToModify.parametrosAdicionales.comision = userDetalleComisiones['Zurich']['AUTOMOTOR']['general'];
        valuesToModify.vigenciaDesde = finalValues.configuracionAvanzada.Zurich.vigenciaDesde;
    }

    valuesToModify['parametrosAdicionales'] = {
        ...valuesToModify['parametrosAdicionales'],
        "codigos": userCodigosOperacion[valuesToModify['company']][0]
    }

    return valuesToModify
}

export {automotorQuotationToEmissionValues}