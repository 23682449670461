import axios from 'axios';
import { FormCard } from '../../../components/FormCard';
import { CodigosContainer } from './CodigosContainer';
import CollapsibleRow from './CollapsibleRow/CollapsibleRow';
import CompanySummaryCard from './CompanySummaryCard/CompanySummaryCard';
import './MisAseguradorasCompanies.css';
import '../../../pages/MisAseguradoras/MisAseguradoras.css';
import UserContext from '../../../context/UserContext';
import { useContext, useEffect, useState } from 'react';
import AuthContext from '../../../context/AuthContext';
import MasterContext from '../../../context/MasterContext/MasterContext';

const COMPANY_PRESTIGIO = {
    Sancor: ["1er lugar \"Universo productores\"", "Destacada \"prestigio empresario\""],
    RUS: ["8vo lugar \"Universo productores\""],
    Zurich: ["Destacada \"prestigio empresario\"", "5to lugar \"Universo productores\""],
    Federacion_Patronal: ["2do lugar \"Universo productores\""],
}

const MisAseguradorasCompanies = () => {
    const { userCodigosOperacion, userCompanies, userCompaniesToPromote } = useContext(UserContext);
    const { authToken } = useContext(AuthContext);
    const { altaCompaniaTickets } = useContext(MasterContext);
    const [companiesData, setCompaniesData] = useState({});

    const codesToShow = (company) => {
        if (userCodigosOperacion[company]) {
            const withoutSensitiveData = userCodigosOperacion[company].map((element) => {
                const cleanedElement = { ...element };
                for (const key in cleanedElement) {
                    if (key === "password" || key.startsWith("comision")) {
                        delete cleanedElement[key];
                    }
                }
                return cleanedElement;
            });
            return withoutSensitiveData;
        } else {
            return null;
        }
    };

    const getCompanyInfo = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BACK_SERVER + '/get_informacion_aseguradoras',
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`,
                    }
                });

            const companyObject = {};
            const allUserCompanies = userCompanies.concat(userCompaniesToPromote);
            const companiesToShow = response.data.filter(companyData => allUserCompanies.includes(companyData.nombre_aseguradora));

            for (const company in companiesToShow) {
                const companyData = companiesToShow[company];

                companyObject[companyData.nombre_aseguradora] = {
                    info: [
                        { "Nombre": companyData.nombre },
                        { "Razón social": companyData.razon_social },
                        { "Cuil": companyData.cuil },
                        { "Ropaje jurídico": companyData.ropaje_juridico },
                        { "Origen": companyData.origen },
                        { "Alcance": companyData.alcance },
                        { "Ranking de facturación": companyData.ranking_facturacion },
                        { "Participación de mercado": companyData.participacion_mercado },
                    ],
                    products: companyData.products,
                    benefits: companyData.benefits,
                    links: {
                        site: companyData.links.find(link => link.nombre === "sitio")?.url || "",
                    },
                    extras: {
                        files: companyData.links.filter(link => link.tipo_link === 'extras').sort((a, b) => b.nombre.length - a.nombre.length) || [],
                        prestigio: COMPANY_PRESTIGIO[companyData.nombre_aseguradora],
                    },
                    codigos: codesToShow(companyData.nombre_aseguradora),
                };
            }

            return companyObject;
        } catch (error) {
            console.log("Error fetching company info:", error);
            return undefined;
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const companyObject = await getCompanyInfo();
            setCompaniesData(companyObject);
        };

        fetchData();
    }, []);

    return (
        <div className="MisAseguradoras__section">
            <h1 className="MisAseguradoras__title">
                Mis Aseguradoras
            </h1>

            <FormCard>
                <div className="MisAseguradorasCompanies__container">
                    <div className='MisAseguradorasCompanies__table--header'>
                        <div>Compañía</div>
                        <div>Productos</div>
                        <div className='MisAseguradorasCompanies__table--header-codigos'>Código</div>
                    </div>
                    <hr />
                    <div className="MisAseguradorasCompanies__rows--container">
                        {
                            // TODO: usar ErrorView
                            !companiesData ? <p>Ocurrió un error al cargar la tabla de aseguradoras</p> :
                                Object.keys(companiesData).map((companyKey, index) => {
                                    const foundTicket = altaCompaniaTickets.find(obj => obj.hasOwnProperty(companyKey));
                                    const companyTicket = foundTicket ? foundTicket[companyKey] : null;
                                    return (
                                        <CollapsibleRow
                                            key={index}
                                            rowItems={
                                                [
                                                    companyKey.replace(/_/g, ' '),
                                                    companiesData[companyKey].products.length || "?",
                                                    <CodigosContainer
                                                        codigos={companiesData[companyKey].codigos}
                                                        companyTicket={companyTicket} />,
                                                ]
                                            }
                                            collapsedContent={
                                                <CompanySummaryCard
                                                    company={companyKey}
                                                    details={companiesData[companyKey].info}
                                                    products={companiesData[companyKey].products}
                                                    benefits={companiesData[companyKey].benefits}
                                                    links={companiesData[companyKey].links}
                                                    extras={companiesData[companyKey].extras}
                                                />
                                            }
                                        />)
                                })
                        }
                    </div>
                </div>
            </FormCard>
        </div>
    );
};

export default MisAseguradorasCompanies;