import React from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import {
    InputLabel,
    FormControl,
    Select,
    MenuItem,
    FormHelperText,
    TextField
} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab'

function AutoCompleteField(props) {
    const { label, data, ...rest } = props;
    const [field, meta] = useField(props);
    const [selectedValue, setSelectedValue] = React.useState(field.value);
    const [touched, error] = at(meta, 'touched', 'error');
    const isError = touched && error && true;

    function _renderHelperText() {
        if (isError) {
            return <FormHelperText>{error}</FormHelperText>;
        }
    }

    return (
        <FormControl {...rest} error={isError}>
            <Autocomplete
                {...field}
                options={data}
                getOptionLabel={option => option.label}
                onChange={(event, newValue) => {
                    setSelectedValue(newValue);
                }}
                value={selectedValue.value}
                renderInput={params => (
                    <TextField
                        {...params}
                        name={field.name}
                        label={label}
                        variant="outlined"
                        fullWidth
                    />

                )}
            />
            {/*<Select {...field} value={selectedValue ? selectedValue : ''}>
                {data.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>*/}
            {_renderHelperText()}
        </FormControl>
    );
}

AutoCompleteField.defaultProps = {
    data: []
};

AutoCompleteField.propTypes = {
    data: PropTypes.array.isRequired
};

export default AutoCompleteField;
