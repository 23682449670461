import React, {useContext} from 'react';
import '../CoverageTableRow/CoverageTableRow.css'
import {ReactComponent as PlusFilledIcon} from "../../assets/icons/ic_plus-filled.svg";
import {ReactComponent as InformationFilledIcon} from "../../assets/icons/ic_info-filled.svg";
import {useFormikContext} from "formik";
import {Button} from "../Button";
import {IconMinus} from '@tabler/icons';
import {useCurrentQuotationContext} from "../../context/CurrentQuotationContext/CurrentQuotationContext";
import CotizarContext from "../../context/CotizarContext";
import {Grid} from "@mantine/core";
import {renderColumns} from "./getColumnsArray";

function GenericCoverageTableRow(props) {
    const {
        rowData,
        tabId,
        addCoverageToCart,
        handleAddStep,
        handleOpenCoverageOverviewModal,
        columns,
        emitirInvisible
    } = props;
    const {values} = useFormikContext();
    const {cartCoverages} = useCurrentQuotationContext();
    const {companiesDict} = useContext(CotizarContext);
    const selectedDiscountForCompany = values['descuentos'] ? values['descuentos'][companiesDict[tabId]['name']] : 0
    const cartCoveragesIds = cartCoverages.map(cov => cov.id);

    //Changes here must be replicated into same object of CoverageCard
    const dataObjectRequired = {
        company: tabId,
        companyName: companiesDict[tabId]['name'],
        ...rowData,
        discount: selectedDiscountForCompany,
        premium: parseInt(rowData.premium),
        actualPremium: parseInt(rowData.premium),
    };

    return (
        <Grid align="center">
            <Grid.Col span={1}
                      onClick={() => addCoverageToCart(dataObjectRequired)}
            >
                {cartCoveragesIds.includes(rowData.id)
                    ? <IconMinus className="ctrow__icon__minus"/>
                    : <PlusFilledIcon className="ctrow__icon__plus"/>
                }
            </Grid.Col>

            {renderColumns(columns, rowData)}

            <Grid.Col span={3} className="ctrow__actions__container">
                <div className="ctrow__info__container"
                     onClick={() => handleOpenCoverageOverviewModal(dataObjectRequired)}
                >
                    <InformationFilledIcon className="ctrow__icon__info"/>
                </div>
                {!emitirInvisible &&
                    <Button
                        type="button"
                        variant="filled"
                        color="primary"
                        enabled
                        marginX="sm"
                        size="md"
                        onClick={() => handleAddStep(tabId, rowData)}
                    >
                        Emitir
                    </Button>}
            </Grid.Col>
        </Grid>
    );
}

export default GenericCoverageTableRow;