import React, {useState} from 'react';
import './AcademyButton.css';
import {Button} from "../../../components/Button";
import {AcademyDropdown} from "../AcademyDropdown";

function AcademyButton() {
    const [showDropdown, setShowDropdown] = useState(false);
    return (
        <div className="AButton__container">
            <Button
                variant="filled"
                color="primary"
                enabled
                onClick={(e) => {
                    e.stopPropagation();
                    setShowDropdown(!showDropdown)
                }}
            >
                Academia
            </Button>
            {showDropdown && <AcademyDropdown setDisplay={setShowDropdown}/>}
        </div>
    );
}

export default AcademyButton;
