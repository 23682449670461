import * as Yup from 'yup';
import profileFormModel from './profileFormModel';

const {
    formField: {
        nombre,
        apellido,
        DNI,
        CUIT,
        nacimiento,
        l_nacimiento,
        nacionalidad,
        email,
        telefono,
        sexo,
        direccion,
        ocupacion,
        IVA,
        matricula,
        CBU,
        codigoPostal
    }
} = profileFormModel;


export default Yup.object().shape({
    [nombre.name]: Yup.string()
        .required(`${nombre.requiredErrorMsg}`)
        .matches(/^[aA-zZ\s]+$/, "Ingrese un nombre válido")
        .max(40, "El nombre ingresado es muy largo"),
    [apellido.name]: Yup.string()
        .required(`${apellido.requiredErrorMsg}`)
        .matches(/^[aA-zZ\s]+$/, "Ingrese un apellido válido")
        .max(40, "El valor ingresado es muy largo"),
    [DNI.name]: Yup.number()
        .required(`${DNI.requiredErrorMsg}`)
        .min(1000000, "El documento ingresado no es válido")
        .max(99999999, "El documento ingresado no es válido")
        .integer()
        .typeError("Introduzca únicamente números"),
    [CUIT.name]: Yup.string()
        .required(`${CUIT.requiredErrorMsg}`)
        .matches(/^(20|23|27|30|33)([0-9]{9}|-[0-9]{8}-[0-9]{1})$/, "El CUIT ingresado no es válido"),
    [nacimiento.name]: Yup.date()
        .max(new Date(Date.now() - 567648000000), "Debe ser mayor de 18 años para utilizar Polko.")
        .required(`${nacimiento.requiredErrorMsg}`),
    [l_nacimiento.name]: Yup.string()
        .required(`${l_nacimiento.requiredErrorMsg}`)
        .matches(/^[A-Za-z0-9 ,-]+$/, "Esta utilizando carácteres no válidos"),
    [nacionalidad.name]: Yup.string()
        .required(`${nacionalidad.requiredErrorMsg}`)
        .matches(/^[A-Za-z0-9 ,-]+$/, "Esta utilizando carácteres no válidos"),
    [email.name]: Yup.string()
        .required(`${email.requiredErrorMsg}`)
        .email("Ingrese un email válido")
        .test('AllowedMails', "Por el momento sólo se aceptan e-mails con dominio de Google", (sumas, context) => {
            const emailInput = context.parent.email;

            if (emailInput !== undefined) {
                if (emailInput.split("@")[1] !== undefined) {
                    if (emailInput.split("@")[1].split(".")[0] !== undefined) {
                        let domain = emailInput.split("@")[1].split(".")[0];
                        return (domain !== "yahoo" && domain !== "outlook");
                    } else return true
                } else return true
            }
        }),
    [telefono.name]: Yup.number()
        .required(`${telefono.requiredErrorMsg}`)
        .integer()
        .typeError("Introduzca únicamente números"),
    [sexo.name]: Yup.string().required(`${sexo.requiredErrorMsg}`),
    [direccion.name]: Yup.string()
        .required(`${direccion.requiredErrorMsg}`)
        .matches(/^(?=[a-zA-Z0-9()_+=,.:\s-]*$)/, "Esta utilizando carácteres no válidos"),
    [ocupacion.name]: Yup.string().required(`${ocupacion.requiredErrorMsg}`),
    [IVA.name]: Yup.string().required(`${IVA.requiredErrorMsg}`),
    [matricula.name]: Yup.string().when([ocupacion.name], {
        is: 'Productor_de_Seguros',
        then: Yup.string().required(`${matricula.requiredErrorMsg}`),
        otherwise: Yup.string().nullable()
    }),
    [CBU.name]: Yup.string()
        .matches(/^[0-9]+$/, "Ingrese un número válido")
        .min(22, 'El número de CBU debe tener 22 dígitos')
        .max(22, 'El número de CBU debe tener 22 dígitos'),
    [codigoPostal.name]: Yup.number().required(`${codigoPostal.requiredErrorMsg}`),
});
