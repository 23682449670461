import {useContext} from 'react';
import * as Yup from "yup";
import CotizarContext from "../../../../../../context/CotizarContext";

const CoberturasModel = () => {
    const {coberturasInfo} = useContext(CotizarContext);
    const formField = {
        tamanioVivienda: {
            name: 'tamanioVivienda',
            label: 'Tamaño de vivienda',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        tipoVivienda: {
            name: 'tipoVivienda',
            label: 'Tipo de vivienda',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        codigosLocalidad: {
            name: 'codigosLocalidad',
            label: 'Localidad',
            requiredErrorMsg: 'Debe completar este campo'
        },
        codigoPostal: {
            name: 'codigoPostal',
            label: "Código postal",
        },
        idProvincia: {
            name: 'idProvincia',
            label: 'Provincia',
        },
        coberturasAdicionales: { //Object
            name: 'coberturasAdicionales',
        },
        sumaAsegurada: {
            name: 'sumaAsegurada',
            label: 'Suma asegurada',
            requiredErrorMsg: 'Debe completar este campo'
        },
        numeroCobertura: {
            name: 'numeroCobertura',
            label: 'Número cobertura',
            requiredErrorMsg: 'Debe completar este campo'
        },
        selectedCobertura: {
            name: 'selectedCobertura',
        },
    }

    const initialValues = {
        [formField.tamanioVivienda.name]: 'Chica',
        [formField.tipoVivienda.name]: '1022',
        [formField.codigosLocalidad.name]: '',
        [formField.coberturasAdicionales.name]: {
            26: {
                [formField.sumaAsegurada.name]: coberturasInfo["26"].sumaChica,
                [formField.selectedCobertura.name]: false,
            },
            36: {
                [formField.sumaAsegurada.name]: coberturasInfo["36"].sumaChica,
                [formField.selectedCobertura.name]: false,
            },
            37: {
                [formField.sumaAsegurada.name]: coberturasInfo["37"].sumaChica,
                [formField.selectedCobertura.name]: false,
            },
            48: {
                [formField.sumaAsegurada.name]: coberturasInfo["48"].sumaChica,
                [formField.selectedCobertura.name]: false,
            },
        },
    }

    const validationSchema = Yup.object().shape({
        [formField.codigosLocalidad.name]: Yup.string().required(`${formField.codigosLocalidad.requiredErrorMsg}`),
        [formField.coberturasAdicionales.name]: Yup.object().test('product-selection', 'Debe seleccionar al menos un producto', coberturas => {
            let isThereAnySelected = false;
            for (let cob in coberturas) {
                if (coberturas[cob].selectedCobertura === true) {
                    isThereAnySelected = true;
                }
            }
            return isThereAnySelected
        })
    })

    return {formField, initialValues, validationSchema}
}

export {CoberturasModel};
