import React, {useState} from 'react';
import {useField} from 'formik';
import {DatePickerInput} from '@mantine/dates';
import moment from "moment";
import {ReactComponent as ExclamationIcon} from "../../../assets/icons/ic_exclamation.svg";
import {ReactComponent as CalendarIcon} from "../../../assets/icons/ic_calendar.svg";

const MantineDatePickerTwoFields = (props) => {
    const {label, name, onChangeAction} = props;
    const firstFieldName = name[0];
    const secondFieldName = name[1];
    const [firstField, firstMeta, firstHelpers] = useField(firstFieldName);
    const [secondField, secondMeta, secondHelpers] = useField(secondFieldName);
    const [firstValue, setFirstValue] = useState(firstField.value === "" ? "" : moment(firstField.value, 'DD-MM-YYYY').toDate());
    const [secondValue, setSecondValue] = useState(secondField.value === "" ? "" : moment(secondField.value, 'DD-MM-YYYY').toDate());
    const {setValue: setFirstFieldValue, setTouched: setFirstTouched} = firstHelpers;
    const {setValue: setSecondFieldValue, setTouched: setSecondTouched} = secondHelpers;

    return (
        <DatePickerInput
            {...props}
            label={label}
            value={[firstValue, secondValue]}
            onBlur={() => {
                setFirstFieldValue(firstValue)
                setSecondFieldValue(secondValue)
                setFirstTouched(firstFieldName, false)
                setSecondTouched(secondFieldName, false)
            }}
            onChange={(e) => {
                setFirstValue(e[0]);
                setSecondValue(e[1]);
                onChangeAction(e)
            }}
            type="range"
            error={firstMeta.error && firstMeta.touched && <div>{firstMeta.error}</div>}
            allowFreeInput
            locale="es"
            inputFormat="DD/MM/YYYY"
            valueFormat="DD/MM/YYYY"
            classNames={{
                wrapper: 'mantine__date__wrapper',
                invalid: 'mantine__date__wrapper',
                label: firstMeta.error && firstMeta.touched ? 'mantine__date__label--error' : 'mantine__date__label',
                required: firstMeta.error && firstMeta.touched ? 'mantine__date__required--error' : 'mantine__date__required',
                error: 'mantine__date__error',
                day: 'mantine__date__day',
                input: firstMeta.error && firstMeta.touched ? 'mantine__date__field--error' : 'mantine__date__field',
                rightSection: 'mantine__date__rightSection'
            }}
            rightSection={
                firstMeta.error && firstMeta.touched
                    ? <ExclamationIcon className="mantine__date__icon--error"/>
                    : <CalendarIcon className="mantine__date__icon"/>
            }
        />
    );
};

export default MantineDatePickerTwoFields;
