import React from 'react';
import './MChoiceResult.css';
import {ReactComponent as CloseIcon} from "../../../../../assets/icons/ic_close.svg";
import {ReactComponent as CheckCircledIcon} from "../../../../../assets/icons/ic_check_circled.svg";
import {ReactComponent as CrossIcon} from "../../../../../assets/icons/ic_cross-circled.svg";
import {PopUpCard} from "../../../../../components/PopUpCard";
import {CustomModal} from "../../../../../components/CustomModal";

function MChoiceResult(props) {
    const {title, bodyItems, footer, closeAction, success} = props;

    return (
        <CustomModal>

            <PopUpCard>
                <div className="MChoiceResult__container">
                    <div className="MChoiceResult__header">
                        <div className="MChoiceResult__iconContainer" onClick={closeAction}>
                            <CloseIcon className="MChoiceResult__iconClose"/>
                        </div>
                        <div className="MChoiceResult__title">
                            {success ? <CheckCircledIcon className="MChoiceResult__icon"/> :
                                <CrossIcon className="MChoiceResult__errorIcon"/>}
                            {title}
                        </div>
                    </div>

                    <div className="MChoiceResult__body">
                        {bodyItems.map(item => (
                            <div className="MChoiceResult__bodyBlock">
                                <p className="MChoiceResult__subtitle">{item.name}</p>
                                <p className="MChoiceResult__text">{item.value}</p>
                            </div>
                        ))}
                    </div>

                    <div className="MChoiceResult__footer">
                        {footer}
                    </div>
                </div>
            </PopUpCard>
        </CustomModal>

    );
}

export default MChoiceResult;