import React, {useCallback, useContext, useState} from 'react';
import './CommissionsHistoricalModal.css';
import {CustomModal} from "../../../components/CustomModal";
import {FormCard} from "../../../components/FormCard";
import {ReactComponent as CloseIcon} from "../../../assets/icons/ic_close.svg";
import {ReactComponent as FilterIcon} from "../../../assets/icons/ic_filter.svg";
import {MainTable} from "../../../components/MainTable";
import CotizarContext from "../../../context/CotizarContext";
import {MantineMonthFilter} from "../../../components/Filters/MantineMonthFilter";
import moment from 'moment';
import {Loading} from "../../../components/Loading";
import useOperaciones from "./hooks/useOperaciones";

function CommissionsHistoricalModal(props) {
    const {onClose} = props;
    const {companiesDict} = useContext(CotizarContext);
    const [loading, setLoading] = useState(false);
    const [dateFilter, setDateFilter] = useState({
        anio: new Date().getFullYear().toString(),
        mes: (new Date().getMonth() + 1).toString()
    });

    const operaciones = useOperaciones(dateFilter, setLoading);

    const changeMonthFilter = useCallback((newMonthValue) => {
        setDateFilter({
            anio: moment(newMonthValue).format("YYYY"),
            mes: moment(newMonthValue).format("MM")
        });
    }, []);


    const historicalCommissionColumns = [
        {title: "ID Operación", key: "_id", render: (value) => <span>{value}</span>},
        {title: "Monto", key: "monto", render: (value) => <span>${value}</span>},
        {title: "Fecha", key: "created_at", render: (value) => <span>{moment(value).format('DD/MM/YYYY')}</span>},
        {
            title: "Estado",
            key: "estado",
            render: (value) => {
                if (value === "Pagado") //TODO: Review options [Pagado, Pendiente]
                    return <span className="comHistModal__column__state--success">{value}</span>
                else if (value === "Pending")
                    return <span className="comHistModal__column__state--pending">{value}</span>
                else
                    return <span className="comHistModal__column__state--failed">{value}</span>
            }
        }
    ];

    const innerHistoricalCommissionColumnsEmisiones = [
        {title: "ID Emisión", key: "idEmision", render: (value) => <span>{value}</span>},
        {title: "Producto", key: "producto", render: (value) => <span>{value === "COMBINADO" ? "HOGAR" : value}</span>},
        {
            title: "Aseguradora",
            key: "compania",
            render: (value) => <img className="comHistModal__image" src={value ? companiesDict[value]['logo'] : ""}
                                    alt="aseguradora"/>
        },
        {title: "Identificador", key: "numeroTramite", render: (value) => <span>{value}</span>},
        {title: "Detalle", key: "detalle", render: (value) => <span>{value}</span>},
        {title: "Comisión", key: "comision", render: (value) => <span>${value}</span>},
        {title: "Cliente", key: "cliente", render: (value) => <span>{value}</span>},
    ];

    const innerHistoricalCommissionColumnsOtros = [
        {title: "ID Emisión", key: "idIncentivo", render: (value) => <span>{value}</span>},
        {title: "Detalle", key: "detalle", render: (value) => <span>{value}</span>},
        {title: "Comisión", key: "comision", render: (value) => <span>${value}</span>},
    ];

    const innerTabs = [
        {label: "Emisiones", key: "operaciones.emisiones", columns: innerHistoricalCommissionColumnsEmisiones},
        {label: "Otros incentivos", key: "operaciones.otros_incentivos", columns: innerHistoricalCommissionColumnsOtros}
    ];


    return (
        <CustomModal>
            <div className="comHistModal__wrapper">
                <FormCard>
                    <div className="comHistModal__container">
                        <div className="comHistModal__icon__container" onClick={onClose}>
                            <CloseIcon className="comHistModal__icon__close"/>
                        </div>
                        <p className="comHistModal__title">Detalle de comisiones</p>

                        <div className="comHistModal__filter__container">
                            <div className="comHistModal__filterButton__container">
                                <FilterIcon className="comHistModal__filter__icon"/>
                                <p className="comHistModal__filter__text">Filtros</p>
                            </div>

                            <MantineMonthFilter
                                placeholder={"Fecha"}
                                value={dateFilter.mes.toString()}
                                setValue={changeMonthFilter}
                            />

                        </div>

                        <div className="comHistModal__body">
                            <div className="comHistModal__body__table">
                                {operaciones && !loading ?
                                    <MainTable
                                        columns={historicalCommissionColumns}
                                        rows={operaciones}
                                        isExpandable
                                        innerRowsKey={innerTabs}
                                    />
                                    :
                                    <Loading/>}
                            </div>
                        </div>
                    </div>
                </FormCard>
            </div>
        </CustomModal>
    );
}

export default CommissionsHistoricalModal;