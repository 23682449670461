import {useContext} from 'react';
import moment from "moment";
import * as Yup from "yup";
import {CardNumberValidator} from "../../../../../../modules/utils/cardNumberValidator";
import CotizarContext from "../../../../../../context/CotizarContext";

const PolizaModel = () => {
    const {companiesDict} = useContext(CotizarContext);
    const formField = {
        vigenciaDesde: {
            name: 'vigenciaDesde',
            label: 'Vigencia Desde',
            requiredErrorMsg: 'Debe especificar una fecha'
        },
        vigenciaHasta: {
            name: 'vigenciaHasta',
            label: 'Vigencia Hasta',
            requiredErrorMsg: 'Debe especificar una fecha'
        },
        formaDePago: {
            name: 'formaDePago',
            label: 'Forma De Pago',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        numeroCbu: {
            name: 'numeroCbu',
            label: 'Número de CBU',
            requiredErrorMsg: 'Debe especificar un número de CBU'
        },
        marcaTarjeta: {
            name: 'marcaTarjeta',
            label: 'Marca de la tarjeta',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        numeroTarjeta: {
            name: 'numeroTarjeta',
            label: 'Número de la tarjeta',
            requiredErrorMsg: 'Debe especificar un número de tarjeta'
        },
    }

    const initialValues = {
        [formField.vigenciaDesde.name]: moment().format('YYYY-MM-DD'),
        [formField.vigenciaHasta.name]: moment().add(1, "Y").format("YYYY-MM-DD"),
        [formField.formaDePago.name]: '',
        infoDePago: {
            [formField.numeroCbu.name]: '',
            [formField.marcaTarjeta.name]: '',
            [formField.numeroTarjeta.name]: '',
        },
    }

    let tipoTarjeta;
    const validationSchema = Yup.object().shape({
        [formField.vigenciaDesde.name]: Yup.date()
            .required(`${formField.vigenciaDesde.requiredErrorMsg}`)
            .min(moment().format('YYYY-MM-DD'), "La fecha no puede ser anterior al dia de hoy")
            .max(moment().add(45, "days").format('YYYY-MM-DD'), "La fecha no puede superar los 45 dias"),

        [formField.formaDePago.name]: Yup.string().required(`${formField.formaDePago.requiredErrorMsg}`),
        infoDePago: Yup.object().when([formField.formaDePago.name], {
            is: 'CBU',    // Débito por CBU
            then: Yup.object().shape({
                [formField.numeroCbu.name]: Yup.string()
                    .required(`${formField.numeroCbu.requiredErrorMsg}`)
                    .matches(/^[0-9]+$/, "Ingrese un número válido")
                    .min(22, 'El número de CBU debe tener 22 dígitos')
                    .max(22, 'El número de CBU debe tener 22 dígitos')
                    .test('cbuType', 'Los CVU comenzados con 000 (Mercado Pago) o 143 (Brubank) no son medios habilitados para el pago de pólizas',
                        value => { return !(value.startsWith('000') || value.startsWith('143'))}),
            })
        }).when([formField.formaDePago.name], {
            is: 'TC',    // Tarjeta de crédito
            then: Yup.object().shape({
                [formField.marcaTarjeta.name]: Yup.string().test('cardType', 'Seleccione un tipo de tarjeta', type => {
                    tipoTarjeta = type;
                    if (tipoTarjeta !== null) return true;
                }).required(`${formField.marcaTarjeta.requiredErrorMsg}`),
                [formField.numeroTarjeta.name]: Yup
                    .string()
                    .test('cardNumber',
                        'El número de tarjeta ingresado es inválido', //validation message
                        (value, context) => {
                            const formValues = context.from[1].value
                            const cardObject = companiesDict[formValues.company].idsTarjeta.find(card => card.value === tipoTarjeta);
                            const cardName = cardObject?.label;
                            if (value !== undefined && 13 <= value.length && value.length <= 19)
                                return CardNumberValidator(cardName, value);
                        })
                    .required()
            })
        }),
    })

    return {formField, initialValues, validationSchema}
}

export {PolizaModel};
