import React, {useContext, useEffect, useRef, useState} from 'react';
import {Grid, Group, Checkbox} from "@mantine/core";
import {FieldArray, useFormikContext} from "formik";
import {InspeccionModel} from "./InspeccionModel";
import {Dropzone, MIME_TYPES} from "@mantine/dropzone";
import {IconPhoto, IconUpload, IconX} from "@tabler/icons";
import {DropzoneFile} from "../../../../../../components/DropzoneFile";
import {MantineTextArea} from "../../../../../../components/FormFields/MantineTextArea";
import UifFormWarn from "../../../../../Cotizacion/UIFFormWarn/UIFFormWarn";
import DefaultValuesContext from "../../../../../../context/DefaultValuesContext";
import {saveFileContent} from "../../../../../../modules/utils/saveFileContent";
import {CarPhotosDialog} from "../../../../../Cotizacion/CarPhotosDialog";
import {PopOverInfo} from "../../../../../../components/PopOverInfo";
import normalizeString from '../../../../../utils/normalizeString.js';
import CotizarContext from "../../../../../../context/CotizarContext";


export default function InspeccionForm(props) {
    const {formField} = InspeccionModel();
    const {
        comentariosInspeccion,
    } = formField;
    const [openPhotoHelp, setOpenPhotoHelp] = useState(false);
    const {valuesMemo} = useContext(DefaultValuesContext);
    const {companiesDict} = useContext(CotizarContext);
    const {values, setFieldValue, errors} = useFormikContext();
    const currentWeight = useRef(0);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (typeof errors?.inspeccionPrevia?.archivos === "string") {
            setError(errors['inspeccionPrevia']['archivos'])
        }
    }, [errors?.inspeccionPrevia?.archivos]);

    return (
        <div className="automotor__formStep__container">
            {values['vehiculo']['sumaAseguradaVehiculo'] >= valuesMemo['Valor_DIC_Requerido'] &&
            <UifFormWarn tipoPersona={values['tipoPersona']}/>
            }
            <div className="autem__inspeccion__dropzone__container">
                <div>
                    <div className="autem__dropzone__header">
                        <p className="autem__dropzone__title" style={{marginRight: "8px"}}>Archivos</p>
                        <PopOverInfo descripcion={"Ayuda"} onClick={() => setOpenPhotoHelp(true)}/>
                    </div>
                    {/*TODO: Make an independent component out of this. Dropzone*/}
                    <Grid gutter="xs" justify="center" align="center">
                        <Grid.Col xs={12}>
                            <Dropzone
                                className={values.inspeccionPrevia.archivos.length >= 6 && "disabled"}
                                onDrop={(files) => {
                                    let json = []
                                    let len = values.inspeccionPrevia.archivos.length
                                    setError(null)
                                    files.map(async function (file, index) {
                                        //TODO: Codigo repetido en los 3 productos
                                        const filename = file.name.split(/[.]+/)[0];
                                        currentWeight.current += file.size;
                                        if (len < 10 && currentWeight.current < 10485760) {
                                            let extension = file.name.split(/[.]+/).pop().toUpperCase();
                                            if (extension !== 'JFIF') {
                                                if (filename.length === 0) {
                                                    setError('El nombre del archivo debe tener al menos 1 caracter.')
                                                    currentWeight.current -= file.size
                                                }
                                                else {
                                                    const fileData = file.slice();
                                                    const normalizedFilename = normalizeString(filename, null, true, "_").substring(0, 24);
                                                    const normalizedFilePath = `${normalizedFilename}.${extension}`;

                                                    json.push(                                                    {
                                                        "name": normalizedFilePath,
                                                        "extension": file.type.split("/")[1],
                                                        "content": "",
                                                        "file": new File([fileData], normalizedFilePath, {
                                                            path: normalizedFilePath,
                                                            size: fileData.size,
                                                            type: file.type,
                                                        }),
                                                        "type": null,
                                                    })

                                                    saveFileContent(file).then((fileContent) => {
                                                        json[index].content = fileContent;
                                                    });
                                                }
                                            } else {
                                                setError('El formato .JFIF no es válido.')
                                                currentWeight.current -= file.size
                                            }
                                        } else {
                                            setError('El tamaño total supera los 10MB.')
                                            currentWeight.current -= file.size
                                        }
                                        len += 1
                                    })
                                    setFieldValue('inspeccionPrevia.archivos', values.inspeccionPrevia.archivos.concat(json), false);
                                }}
                                onReject={() => console.log("El archivo no pudo ser cargado")}
                                maxSize={3 * 1024 ** 2}
                                maxFiles={10}
                                accept={companiesDict[values.company].inspeccionTipoArchivos || [MIME_TYPES.png, MIME_TYPES.jpeg, MIME_TYPES.pdf]}
                                disabled={values.inspeccionPrevia.archivos.length >= 10}
                                {...props}
                            >
                                <Group position="center" spacing="xl" className="autem__inspeccion__dropzone">
                                    <Dropzone.Accept>
                                        <IconUpload
                                            className="autem__inspeccion__dropzone__icon"
                                            stroke={1.5}
                                        />
                                    </Dropzone.Accept>
                                    <Dropzone.Reject>
                                        <IconX
                                            className="autem__inspeccion__dropzone__icon"
                                            stroke={1.5}
                                        />
                                    </Dropzone.Reject>
                                    <Dropzone.Idle>
                                        <IconPhoto
                                            className="autem__inspeccion__dropzone__icon"
                                            stroke={1.5}
                                        />
                                    </Dropzone.Idle>

                                    <div>
                                        <p className="autem__inspeccion__dropzone__title">
                                            Arrastre o haga clic para seleccionar los archivos
                                        </p>
                                        <p className="autem__inspeccion__dropzone__text">
                                            Puede adjuntar un máximo de 10 archivos (imágenes o pdf), cada archivo no
                                            debe superar los 5mb y en total no pueden exceder los 10mb.
                                        </p>
                                    </div>
                                </Group>
                            </Dropzone>
                            {error &&
                            <div className="autem__inspeccion__dropzone__error__container">
                                <p className="autem__inspeccion__dropzone__error">{error}</p>
                            </div>
                            }
                        </Grid.Col>
                    </Grid>
                </div>
                <div className="autem__inspeccion__dropzoneFiles__container">
                    <FieldArray name='inspeccionPrevia.archivos'>{({remove}) => (
                        values.inspeccionPrevia.archivos.map((file, index) => {
                                return <DropzoneFile
                                    file={file}
                                    index={index}
                                    onDelete={() => {
                                        remove(index)
                                        currentWeight.current -= file.file.size
                                    }}
                                />
                            }
                        ))}</FieldArray>
                </div>
            </div>
            <div className="autem__inspeccion__comments__container">
                <Checkbox
                    checked={values.inspeccionPrevia.daniosPreexistentes}
                    classNames={{
                        input: "autem__inspeccion__checkbox"
                    }}
                    onChange={(e) => setFieldValue("inspeccionPrevia.daniosPreexistentes", e.currentTarget.checked)}
                    label={<p className="autem__inspeccion__text">Tiene daños preexistentes</p>}
                />

                {values.inspeccionPrevia.daniosPreexistentes &&
                <div className="autem__inspeccion__inspeccionPrevia__container">
                    <MantineTextArea
                        name={`inspeccionPrevia.${comentariosInspeccion.name}`}
                        label={comentariosInspeccion.label}
                        placeholder={"Describa los daños preexistentes del vehículo"}
                        withAsterisk
                    />
                </div>
                }

            </div>
            {openPhotoHelp &&
            <CarPhotosDialog onClose={() => setOpenPhotoHelp(false)}/>
            }
        </div>
    );
}