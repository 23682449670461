import React, {useContext, useState} from 'react';
import './ContentCard.css';
import {FormCard} from "../../../components/FormCard";
import Tabs from "../../../components/Tabs/Tabs";
import {DesignTab} from "../DesignTab";
import {ManagementTab} from "../ManagementTab";
import MasterContext from "../../../context/MasterContext";
import showNotification from "../../../modules/utils/showNotification";

function ContentCard() {
    const {infoPagesPolkistas, idPolkistaMaster} = useContext(MasterContext);
    const [editRow, setEditRow] = useState(null);
    const [idPolkistaToCreate, setIdPolkistaToCreate] = useState(idPolkistaMaster);
    const [activeTab, setActiveTab] = useState(infoPagesPolkistas.length > 0 ? 0 : 1)

    const handleChangeTab = (tabIndex) => {
        setEditRow(null)

        if (tabIndex === 0 && infoPagesPolkistas.length === 0) {
            // If there are no digital channels created, the user can't access the management tab
            showNotification({
                autoClose: 3000,
                color: 'yellow',
                status: 'warning',
                title: 'No hay canales digitales creados.',
                message: "Cree su primer canal digital para poder gestionarlo."
            });
            return setActiveTab(1)
        } else if (tabIndex === 1 && infoPagesPolkistas.length > 0) {
            // If there are digital channels created, the user must select one to access the design tab
            showNotification({
                autoClose: 3000,
                color: 'yellow',
                status: 'warning',
                title: 'No se ha seleccionado un canal digital.',
                message: "Seleccione un canal digital para poder editarlo."
            });
            return setActiveTab(0)
        }
        setActiveTab(tabIndex)
    }

    return (
        <FormCard>
            <div className="MDCCcard__container">
                <Tabs
                    activeTab={activeTab}
                    tabs={[
                        <span
                            onClick={() => {
                                handleChangeTab(0)
                            }}
                            className={activeTab === 0 ? "MDCCcard__tab MDCCcard__tab--active" : "MDCCcard__tab"}
                        >
                            GESTIÓN
                        </span>,
                        <span
                            onClick={() => {
                                handleChangeTab( 1)
                            }}
                            className={activeTab === 1 ? "MDCCcard__tab MDCCcard__tab--active" : "MDCCcard__tab"}
                        >
                            DISEÑO
                        </span>
                    ]}
                    tabContent={[
                        <ManagementTab
                            editRow={editRow}
                            setIdPolkistaToCreate={setIdPolkistaToCreate}
                            setEditRow={setEditRow}
                            setActiveTab={setActiveTab}
                        />,
                        <DesignTab
                            editRow={editRow}
                            idPolkistaToCreate={idPolkistaToCreate}
                            setEditRow={setEditRow}
                            setActiveTab={setActiveTab}
                        />
                    ]}
                />
            </div>
        </FormCard>
    );
}

export default ContentCard;