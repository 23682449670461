import React, {useContext, useState} from 'react';
import './ProfileMenu.css';
import UserContext from "../../context/UserContext";
import {ProfileDropdown} from "./ProfileDropdown";

function ProfileMenu() {
    const {userPicture} = useContext(UserContext);
    const [showDropdown, setShowDropdown] = useState(false);
    return (
        <div className="profile__menu__container" onClick={() => setShowDropdown(true)}>
            <img
                src={userPicture}
                alt="polkista"
                className="profile__menu__picture"/>
            {showDropdown && <ProfileDropdown setDisplay={setShowDropdown}/>}
        </div>
    );
}

export default ProfileMenu;