import React from 'react';
import {Divider, Grid} from "@mantine/core";
import {useFormikContext} from "formik";
import moment from "moment";
import {PolizaModel} from "./PolizaModel";
import {MantineDateField} from "../../../../../../components/FormFields/MantineDateField";
import MantineDependantField from "../../../../../../components/FormFields/MantineDependantField/MantineDependantField";
import {MantineSelectField} from "../../../../../../components/FormFields/MantineSelectField";
import {MantineInputField} from "../../../../../../components/FormFields/MantineInputField";

export default function PolizaForm() {
    const {formField} = PolizaModel();
    const {vigenciaDesde, vigenciaHasta, formaDePago, marcaTarjeta, numeroTarjeta, numeroCbu} = formField;

    const {values} = useFormikContext();

    const calculateVigenciaHasta = () => {
        if (values.frecuenciaPago === "5") {
            return moment(values.vigenciaDesde, "YYYY-MM-DD").add(1, "Y").toDate()
        } else {
            return moment(values.vigenciaDesde, "YYYY-MM-DD").add(6, "M").toDate()
        }
    }

    const formatVigenciaDisplay = (toConvert) => {
        return moment(toConvert, 'YYYY-MM-DD').format('DD/MM/YYYY')
    }

    const formasDePago = [
        {value: '4', label: 'Débito por CBU'},
        {value: '2', label: 'Tarjeta de crédito'}
    ];

    const idsTarjeta = {
        "Sancor": [
            {value: '1', label: 'Cabal'},
            {value: '5', label: 'Visa'},
            {value: '7', label: 'Favacard'},
            {value: '9', label: 'American Express'},
            {value: '12', label: 'Naranja'},
            {value: '16', label: 'Diners Club'},
            {value: '18', label: 'Naranja Visa'},
            {value: '20', label: 'Cordobesa - Matercard'},
            {value: '23', label: 'TDF - Tierra del Fuego'},
            {value: '45', label: 'Mastercard Nueva'},
            {value: '57', label: 'ELEBAR'},
            {value: '70', label: 'Censosud  Mastercard'},
        ]
    };

    return (
        <div className="automotor__formStep__container">
            <Grid gutter="md" justify="center" align="center">
                <Grid.Col xs={12} sm={6}>
                    <MantineDateField
                        name={vigenciaDesde.name}
                        label={vigenciaDesde.label}
                        withAsterisk
                    />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <MantineDependantField
                        dependantFieldName={vigenciaHasta.name}
                        dependantFieldLabel={vigenciaHasta.label}
                        dependencies={[values.vigenciaDesde]}
                        calculateValueFunction={calculateVigenciaHasta}
                        formatDisplay={formatVigenciaDisplay}
                        disabled
                        withAsterisk
                    />
                </Grid.Col>

                <Divider size="sm"/>

                <Grid.Col xs={12} sm={6}>
                    <MantineSelectField
                        name={formaDePago.name}
                        label={formaDePago.label}
                        data={formasDePago}
                        withAsterisk
                    />
                </Grid.Col>

                <Grid.Col xs={12} sm={6}/>

                {values.formaDePago === '2' && (
                    <>
                        <Grid.Col xs={12} sm={6}>
                            <MantineSelectField
                                name={`infoDePago.${marcaTarjeta.name}`}
                                label={marcaTarjeta.label}
                                data={idsTarjeta["Sancor"]}
                                withAsterisk
                            />
                        </Grid.Col>
                        <Grid.Col xs={12} sm={6}>
                            <MantineInputField
                                name={`infoDePago.${numeroTarjeta.name}`}
                                label={numeroTarjeta.label}
                                withAsterisk
                            />
                        </Grid.Col>
                    </>
                )}

                {values.formaDePago === '4' && (
                    <>
                        <Grid.Col xs={12} sm={6}>
                            <MantineInputField
                                name={`infoDePago.${numeroCbu.name}`}
                                label={numeroCbu.label}
                                withAsterisk
                            />
                        </Grid.Col>
                        <Grid.Col xs={12} sm={6}/>
                    </>
                )}
            </Grid>
        </div>
    );
}