import { Grid, Group } from "@mantine/core";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { IconPhoto, IconUpload, IconX } from "@tabler/icons";
import { FieldArray, useFormikContext } from 'formik';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { DropzoneFile } from '../../components/DropzoneFile';
import UserContext from '../../context/UserContext';
import normalizeString from "../../features/utils/normalizeString";
import { saveFileContent } from "../../modules/utils/saveFileContent";
import getValueByPath from "../../features/utils/getValueByPath";
import "./DropzoneWrapper.css";
import FileList from "./FileList/FileList";

const MAX_WEIGHT = 10485760;
const MAX_FILE_SIZE = 3 * 1024 ** 2;

const DropzoneWrapper = (props) => {
    const { maxFiles, labelOptions, fileFieldName = "files", captionText, fileList } = props;
    const { isMobile } = useContext(UserContext);
    const { values, setFieldValue } = useFormikContext();
    const currentWeight = useRef(0);
    const [error, setError] = useState(null);
    const [labels, setLabels] = useState(labelOptions);
    const unusedLabels = useMemo(() => {
        return labels.filter((option) => !option.used && option.value !== "OTRA");
    }, [labels]);
    const formFileField = getValueByPath(values, fileFieldName);

    return (
        <>
            <div className="DropzoneWrapper__wrapper">
                <Grid gutter="xs" justify="center" align="center">
                    <Grid.Col xs={12}>
                        <Dropzone
                            className={formFileField.length >= maxFiles && "disabled"}
                            onDrop={(files) => {
                                let json = []
                                let len = formFileField.length
                                setError(null)
                                files.map(async function (file, index) {
                                    const filename = file.name.split(/[.]+/)[0];
                                    currentWeight.current += file.size;
                                    if (len < maxFiles && currentWeight.current < MAX_WEIGHT) {
                                        let extension = file.name.split(/[.]+/).pop().toUpperCase();
                                        if (extension !== 'JFIF') {
                                            if (filename.length === 0) {
                                                setError('El nombre del archivo debe tener al menos 1 caracter.')
                                                currentWeight.current -= file.size
                                            }
                                            else {
                                                const fileData = file.slice();
                                                const normalizedFilename = normalizeString(
                                                    filename,
                                                    ["&", "/", "\\", "#", "+", "(", ")", "$", "~", "%", "'", '"', "*", "?", "<", ">", "{", "}"],
                                                    false,
                                                    ""
                                                ).substring(0, 24);
                                                const normalizedFilePath = `${normalizedFilename}.${extension}`;

                                                json.push({
                                                    "name": normalizedFilePath,
                                                    "extension": file.type.split("/")[1],
                                                    "content": "",
                                                    "file": new File([fileData], normalizedFilePath, {
                                                        path: normalizedFilePath,
                                                        size: fileData.size,
                                                        type: file.type,
                                                    }),
                                                    "type": null,
                                                })

                                                saveFileContent(file).then((fileContent) => {
                                                    json[index].content = fileContent;
                                                });
                                            }
                                        } else {
                                            setError('El formato .JFIF no es válido.')
                                            currentWeight.current -= file.size
                                        }
                                    } else {
                                        setError('El tamaño total supera los 10MB.')
                                        currentWeight.current -= file.size
                                    }
                                    len += 1
                                })
                                setFieldValue(fileFieldName, formFileField.concat(json), false)
                            }}
                            onReject={() => console.log("El archivo no pudo ser cargado")}
                            maxSize={MAX_FILE_SIZE}
                            maxFiles={maxFiles}
                            accept={[MIME_TYPES.png, MIME_TYPES.jpeg, MIME_TYPES.pdf]}
                            disabled={formFileField.length >= maxFiles}
                            {...props}
                        >
                            <Group position="center" spacing="xl" className="DropzoneWrapper__container">
                                <Dropzone.Accept>
                                    <IconUpload
                                        className="DropzoneWrapper__icon"
                                        stroke={1.5}
                                    />
                                </Dropzone.Accept>
                                <Dropzone.Reject>
                                    <IconX
                                        className="DropzoneWrapper__icon"
                                        stroke={1.5}
                                    />
                                </Dropzone.Reject>
                                <Dropzone.Idle>
                                    <IconPhoto
                                        className="DropzoneWrapper__icon"
                                        stroke={1.5}
                                    />
                                </Dropzone.Idle>

                                <div className='DropzoneWrapper__text--container'>
                                    <p className="DropzoneWrapper__text--title">
                                        Arrastre o haga clic para seleccionar los archivos
                                    </p>
                                    <p className="DropzoneWrapper__text--body">
                                        Cada archivo no debe superar los 5MB y en total no pueden exceder los 10MB.
                                    </p>
                                </div>
                            </Group>
                        </Dropzone>
                        {error &&
                            <div className="DropzoneWrapper__error-container">
                                <p className="DropzoneWrapper__error">{error}</p>
                            </div>
                        }
                    </Grid.Col>
                </Grid >
                {fileList && isMobile && (unusedLabels.length > 0) && (
                    <FileList filesToUpload={unusedLabels} />
                )}
                <div className="DropzoneWrapper__file-container">
                    <FieldArray name={fileFieldName}>{({ remove }) => (
                        formFileField.map((file, index) => {
                            return <DropzoneFile
                                file={file}
                                index={index}
                                fileFieldName={fileFieldName}
                                fileOptions={labels}
                                setFileOptions={setLabels}
                                onDelete={() => {
                                    remove(index)
                                    if (!file.link) {
                                        currentWeight.current -= file.file.size
                                    }
                                }}
                            />
                        }
                        ))}</FieldArray>
                </div>
            </div>
            {fileList && !isMobile && (unusedLabels.length > 0) && (
                <FileList filesToUpload={unusedLabels} />
            )}
        </>
    );
}

export default DropzoneWrapper;
