import React, {useContext, useRef, useState} from 'react';
import './LogoModal.css';
import {CropImage} from "../../Utils/CropImage";
import MasterContext from "../../../../context/MasterContext";
import AuthContext from "../../../../context/AuthContext";
import {saveConfiguration} from "../../Utils/saveConfiguration";
import {ErrorModal} from "../../../../layouts/ErrorModal";

function LogoModal(props) {
    const {setOpen, values} = props;
    const {logo, setShowedLogo, srcFile} = values;
    const [crop, setCrop] = useState({unit: '%', aspect: 1});
    const imgRef = useRef(null);
    const {idPolkistaMaster, handleModified} = useContext(MasterContext);
    const {authToken} = useContext(AuthContext);
    const [errorContent, setErrorContent] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleUpload = async (croppedLogo) => {
        setLoading(true);
        const data = values['row'];
        let jsonData = {
            "logo": croppedLogo,
            "template": data.template,
            "productos": data.productos,
            "descripcion": data.descripcion,
            "idMaster": idPolkistaMaster,
            "links": data.redes_sociales,
            "idCanalDigital": data.id,
            "idPolkista": data.id_polkista,
        }

        await saveConfiguration(jsonData, authToken, true)
            .then(() => {
                handleModified();
                setOpen({modalName: "", values: null});
            })
            .catch(err => {
                setErrorContent({
                    title: "Hubo un problema al subir los valores.",
                    typeError: err.data.detail.code || "Ha ocurrido un error en la comunicación con el servidor",
                    suggestedActions: ["Reintentá el proceso una vez más", "Si el problema persiste ponete en contacto con el área de soporte"],
                    detailedError: err.data.detail.message || "Sin datos del error.",
                })
            });
    }

    return (
        <>
            <div className="descriptionModal__body">
                <CropImage srcImage={logo} crop={crop} setCrop={setCrop} loading={loading}
                           setOpenDialog={setOpen} handleUpload={handleUpload} imgRef={imgRef}
                           setCroppedImageUrl={setShowedLogo} srcFile={srcFile}/>
            </div>
            {errorContent &&
            <ErrorModal
                closeAction={() => {
                    setErrorContent(null)
                    setLoading(false)
                }}
                title={errorContent.title}
                typeError={errorContent.typeError}
                suggestedActions={errorContent.suggestedActions}
                detailedError={errorContent.detailedError}/>
            }
        </>
    );
}

export default LogoModal;