import React, {useEffect, useState} from 'react';
import {ErrorMessage, FieldArray, useFormikContext} from "formik";
import {DetallesModel} from "./DetallesModel";
import {currencyFormat} from "../../../../../../modules/utils/currencyFormat";
import {MantineInputField} from "../../../../../../components/FormFields/MantineInputField";
import {MantineNumberField} from "../../../../../../components/FormFields/MantineNumberField";
import {Grid} from "@mantine/core";
import {BikePhotosDialog} from "../../../../../Cotizacion/BikePhotosDialog";
import RenderDropzone from "./AuxComponents/RenderDropzone";
import ButtonsToShow from "./AuxComponents/ButtonsToShow";

export default function DetallesForm() {
    const {formField} = DetallesModel();
    const {
        descripcionElectronico,
        sumaAseguradaElectronico,
        codigoElectronico,
        codigoGrupoElectronico,
        descripcionBicicletas,
        sumaAseguradaBicicletas,
        codigoBicicletas,
        codigoGrupoBicicletas,
        archivosBici,
        archivosElectronicos,
    } = formField;
    const {values, errors} = useFormikContext();
    const [errorArchivosBici, setErrorArchivosBici] = useState(null);
    const [errorArchivosElectronicos, setErrorArchivosElectronicos] = useState(null);
    const [openPhotoHelp, setOpenPhotoHelp] = useState(false);
    const keysToFilter = ["48", "26"];
    const filteredObject = Object.keys(values.coberturasAdicionales)
        .filter(key => keysToFilter.includes(key) && values.coberturasAdicionales[key].selectedCobertura);


    const equipoElectronicoVacio = {
        [descripcionElectronico.name]: '',
        [sumaAseguradaElectronico.name]: '',
        [codigoElectronico.name]: 201,
        [codigoGrupoElectronico.name]: 34,
    };
    const bicicletasVacio = {
        [descripcionBicicletas.name]: '',
        [sumaAseguradaBicicletas.name]: '',
        [codigoBicicletas.name]: 209,
        [codigoGrupoBicicletas.name]: 31,
    };

    const checkError = (prod) => {
        let prodName;
        if (prod === 1) prodName = "detalleBicicletas"
        if (prod === 3) prodName = "detalleEquipoElectronico"

        if (errors[prodName] !== undefined)
            return (errors[prodName].includes("Las sumas declaradas deben coincidir con las aseguradas"))
        else
            return false
    }

    useEffect(() => {
        if (typeof errors?.archivosBici === "string") {
            setErrorArchivosBici(errors[archivosBici.name])
        }
        if (typeof errors?.archivosElectronicos === "string") {
            setErrorArchivosElectronicos(errors[archivosElectronicos.name])
        }
    }, [errors?.archivosBici, errors?.archivosElectronicos]);

    const renderForm = (type, values, checkError, currencyFormat) => {
        let formComponent;
        let buttonFunction;
        let name;
        let label;
        let sumaAsegurada;
        let sumaAseguradaLabel;
        let title;
        let fieldName;
        let errorIndex;
        let err;
        let setErr;
        const coverageGroup = () => {
            if (type === "48") {
                return "bicicletas"
            } else if (type === "26") {
                return "equipoElectronico"
            }
        }

        if (type === "48") {
            formComponent = "detalleBicicletas";
            buttonFunction = bicicletasVacio;
            name = descripcionBicicletas.name;
            label = descripcionBicicletas.label;
            sumaAsegurada = sumaAseguradaBicicletas.name;
            sumaAseguradaLabel = sumaAseguradaBicicletas.label;
            title = "Detallá tus Bicicletas y/o Monopatines"
            fieldName = archivosBici.name;
            errorIndex = 1;
            err = errorArchivosBici;
            setErr = setErrorArchivosBici;
        } else if (type === "26") {
            formComponent = "detalleEquipoElectronico";
            buttonFunction = equipoElectronicoVacio;
            name = descripcionElectronico.name;
            label = descripcionElectronico.label;
            sumaAsegurada = sumaAseguradaElectronico.name;
            sumaAseguradaLabel = sumaAseguradaElectronico.label;
            title = "Detallá tus Objetos Varios asegurados"
            fieldName = archivosElectronicos.name;
            errorIndex = 3
            err = errorArchivosElectronicos;
            setErr = setErrorArchivosElectronicos;
        }

        return (
            <>
                <p className="hogar__detForm__title">
                    {title}
                </p>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                }}>
                    <FieldArray name={formComponent}>{(arrayHelpers) => (
                        values[formComponent].map((item, index) => (
                            <Grid justify="center" align="center" gutterXs="xs">
                                <Grid.Col item xs={4}>
                                    <MantineInputField
                                        name={`${formComponent}[${index}].${name}`}
                                        label={label}/>
                                </Grid.Col>
                                <Grid.Col item xs={4}>
                                    <MantineNumberField
                                        name={`${formComponent}[${index}].${sumaAsegurada}`}
                                        label={sumaAseguradaLabel}
                                        inputProps={{style: {textAlign: 'right'}}}
                                    />
                                </Grid.Col>
                                <ButtonsToShow index={index} length={values[formComponent].length}
                                               arrayHelpers={arrayHelpers} emptyArray={buttonFunction}/>
                            </Grid>
                        ))
                    )}</FieldArray>
                </div>
                {checkError(errorIndex) &&
                    <ErrorMessage name={formComponent}
                                  render={msg => <div className="hogar__detForm__error__container">
                                      <p className="hogar__detForm__error"> {msg}</p>
                                      <p className="hogar__detForm__error"> ($ {currencyFormat(values.coberturasAdicionales[type].sumaAsegurada)})</p>
                                  </div>}
                    />
                }
                <RenderDropzone coverageGroup={coverageGroup()} fieldName={fieldName} error={err} setError={setErr}
                                setOpenPhotoHelp={setOpenPhotoHelp}/>
            </>
        );
    }

    return (
        <div className="hogarCot__form__container">
            {filteredObject.map((type) => (
                renderForm(type, values, checkError, currencyFormat)))}

            {openPhotoHelp &&
                <BikePhotosDialog onClose={() => setOpenPhotoHelp(false)}/>
            }
        </div>
    );
}