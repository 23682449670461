import React from "react";
import withRoot from '../withRoot';
import { motion } from "framer-motion";
import logoround from '../../assets/_images/loading_logo.svg';

const Loading = () => (
    <div className="spinner" style={{height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <motion.img
            src={logoround}
            style={{width: '96px', height: '96px'}}
            animate={{
                opacity: [0.2, 1, 1, 0],
                scale: [0.6, 0.9, 0.9, 0.6],
                rotate: [0, 360, 360],
            }}
            transition={{
                duration: 1.4,
                ease: "easeInOut",
                repeat: Infinity,
                repeatType: 'loop',
                repeatDelay: 0.8,
            }}
        />
    </div>
);

export default withRoot(Loading);
