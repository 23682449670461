import React, {useEffect, useState} from 'react';
import './DropzoneFileNoLabel.css';
import {ReactComponent as CloseIcon} from "../../assets/icons/ic_close.svg";
import PdfIcon from "../../assets/images/PDF_icon.svg";

function DropzoneFileNoLabel(props) {
    const {file, onDelete} = props;
    const [preview, setPreview] = useState(null);

    const handleDeleteImage = () => {
        onDelete()
    }

    useEffect(() => {
        let objectUrl;

        if (file) {
            if (file.file.type === 'application/pdf') {
                setPreview(PdfIcon)
            } else {
                objectUrl = URL.createObjectURL(file.file)
                setPreview(objectUrl)
            }
        }

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [file]);


    return (
        <div className="dropzoneFileNoLabel__card">
            <div className="dropzoneFileNoLabel__image__container">
                {preview && <img className="dropzoneFileNoLabel__image" src={preview} alt="preview_file"/>}
                <div onClick={() => handleDeleteImage()}><CloseIcon className="dropzoneFileNoLabel__icon__close"/></div>
            </div>
        </div>
    );
}

export default DropzoneFileNoLabel;