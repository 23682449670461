import CardFrame from "../../../../../components/CardFrame/CardFrame";
import {Collapse} from "@mantine/core";
import React, {useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import './CustomAgreement.css';
import {ReactComponent as EditSquareIcon} from "../../../../../assets/icons/ic_edit_square.svg";
import {ReactComponent as DownArrowIcon} from "../../../../../assets/icons/ic_chevron_down.svg";
import CustomAgreementForm from "./Form/CustomAgreementForm";
import {Form, Formik} from 'formik';
import {Button} from "../../../../../components/Button";
import {CustomAgreementModel} from "./Form/CustomAgreementModel";
import {format, addMonths} from "date-fns";
import {CustomAgreementBanner} from './CustomAgreementBanner';

const CustomAgreement = (props) => {
    const {highlight, onClick, opened, toggle, setData} = props;
    const {formField, validationSchema, initialValues} = CustomAgreementModel();
    const [submitted, setSubmitted] = useState(null);

    return (
        <CardFrame highlight={highlight} onClick={onClick} withBanner={submitted && !opened}
                   bannerContent={<CustomAgreementBanner agreement={submitted}/>}>
            <div className={`CustomAgreement--header`} onClick={toggle}>
                <div>
                    <EditSquareIcon className="CustomAgreement__EditSquareIcon"/>
                    Nuevo acuerdo personalizado
                </div>
                <DownArrowIcon className={`CustomAgreement__DownArrowIcon ${opened ? "rotated" : ""}`}/>
            </div>
            <Collapse className={"CustomAgreement--body"} in={opened} transitionDuration={300}
                      transitionTimingFunction={"ease"}>
                <div className={"CustomAgreement--collapse"}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values, actions, errors) => {
                            setSubmitted({
                                "Meses": values.cantidadMeses,
                                "Objetivo": values.cantidadEmisiones,
                                "Finaliza": format(addMonths(new Date(), values.cantidadMeses), 'dd/MM/yyyy'),
                            });
                            setData({...values, inicio: new Date()});
                            actions.setSubmitting(false);
                            toggle();
                        }}
                    >
                        {({isSubmitting, values, setSubmitting, errors}) => (
                            <Form method="post" className={"CustomAgreement__form"}>
                                <CustomAgreementForm/>
                                <div className={"CustomAgreement__footer"}>
                                    <Button type="submit" enabled={!isSubmitting} variant="filled"
                                            color="primary">
                                        Listo
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Collapse>
        </CardFrame>
    );
}

export default CustomAgreement;