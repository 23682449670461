import MisDocumentosTable from "../../features/MisDocumentos/MisDocumentosTable/MisDocumentosTable";
import "./MisDocumentos.css";

const MisDocumentos = () => {
    return (
        <div className="MisDocumentos__wrapper">
            <div className="MisDocumentos__container">
                <h1 className="MisDocumentos__header">Mis documentos</h1>
                <MisDocumentosTable />
            </div>
        </div>
    );
}

export default MisDocumentos;
