import React, {useContext, useMemo, useState} from 'react';
import './MyWallet.css'
import {CollapsibleBox} from "../../../components/CollapsibleBox";
import {Collection} from "./Collection";
import {currencyFormat} from "../../../modules/utils/currencyFormat";
import {useHistory} from "react-router-dom";
import UserContext from "../../../context/UserContext";
import DashboardContext from "../../../context/DashboardContext";
import AuthContext from '../../../context/AuthContext';
import {Button} from "../../../components/Button";
import {SummaryBox} from "../../../components/SummaryBox";
import {addDays, differenceInCalendarDays, parseISO} from "date-fns";
import {AgreementDetail} from './AgreementDetail'
import {AgreementPopUp} from "./AgreementsPopUp";

import {ReactComponent as PlusIcon} from '../../../assets/icons/ic_plus-circled.svg';
import {ReactComponent as HourglassIcon} from '../../../assets/icons/ic_hourglass.svg';
import {ReactComponent as MoreInfoIcon} from '../../../assets/icons/ic_info.svg';
import AgreementMoreInfo from "./AgreementMoreInfo/AgreementMoreInfo";

const AGREEMENT_COOLDOWN = 30;


function MyWallet(props) {
    const {collapse, setCollapse} = props;
    const [agreementsOpen, setAgreementsOpen] = useState(false);
    const [moreInfo, setMoreInfo] = useState(false);
    const history = useHistory();
    const {isMobile} = useContext(UserContext);
    const {billeteraData, currentAcuerdo} = useContext(DashboardContext);
    const {isMaster} = useContext(AuthContext);

    const endDate = addDays(parseISO(currentAcuerdo?.inicio), currentAcuerdo?.dias);
    const daysLeft = differenceInCalendarDays(endDate, new Date());
    const cooldownDueDate = addDays(endDate, AGREEMENT_COOLDOWN);
    const daysLeftForCooldown = differenceInCalendarDays(cooldownDueDate, new Date());
    const cooldownHasEnded = daysLeftForCooldown < 0;

    const collapseAgreementValue = useMemo(() => {
        if (currentAcuerdo) {
            if (currentAcuerdo.estado === "PENDIENTE") {
                return <HourglassIcon className='MWallet__agreementDetail--sign-hourglassIcon --gray'/>
            } else {
                return (
                    <span>
                    {currentAcuerdo.emisiones_success.length + currentAcuerdo.emisiones_pending.length}/
                        {currentAcuerdo.emisiones}
                </span>
                );
            }
        } else {
            return (
                <PlusIcon
                    onClick={(e) => {
                        e.stopPropagation();
                        setAgreementsOpen(true);
                    }}
                    className="MWallet__agreementHeader-plusIcon"
                />
            );
        }
    }, [currentAcuerdo]);

    const MyWalletHeaderExpanded = () => {
        if (isMaster) {
            return (
                <div className="MWallet__agreementHeader">
                    <h1 className="MWallet__agreementHeader-title">Acuerdos</h1>
                    <div className="MWallet__agreementHeader-plusIcon--container">
                        <MoreInfoIcon onClick={(e) => {
                            e.stopPropagation();
                            setMoreInfo(true);
                        }} className="MWallet__agreementHeader-plusIcon --primary"/>
                        {(!currentAcuerdo || cooldownHasEnded) && (
                            <PlusIcon onClick={(e) => {
                                e.stopPropagation();
                                setAgreementsOpen(true);
                            }} className="MWallet__agreementHeader-plusIcon"/>
                        )}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="MWallet__wallet--header">
                    <h1 className="MWallet__wallet--header-title">Mi billetera</h1>
                </div>
            );
        }
    }

    return (
        <>
            <CollapsibleBox collapse={collapse}
                            header={
                                collapse ?
                                    <div className="MWallet__agreementHeader--collapsed">
                                        <div>

                                            <h1 className="DStatistics__container--header-title">Mi billetera</h1>
                                            <p className="MWallet__resume">Disponible:
                                                ${currencyFormat(billeteraData.billeteraTotal)}</p>
                                        </div>
                                        {isMaster && <SummaryBox name={"Acuerdos"} variant={"filled"}
                                                                 value={collapseAgreementValue}
                                        />}
                                    </div>
                                    :
                                    <MyWalletHeaderExpanded/>
                            }
                            content={
                                <div className="MWallet__container">
                                    {isMaster &&
                                        <div className="MWallet__section">
                                            <AgreementDetail acuerdo={currentAcuerdo} endDate={endDate}
                                                             daysLeft={daysLeft} daysLeftForCooldown={daysLeftForCooldown} cooldownHasEnded={cooldownHasEnded}/>
                                        </div>
                                    }
                                    <div className="MWallet__section">
                                        {isMaster &&
                                            <div className='MWallet__wallet--title-container'>
                                                <h1 className="MWallet__wallet--header-title">Mi billetera</h1>
                                            </div>
                                        }
                                        <div className={`MWallet__collectionContainer ${!isMaster && 'polkista'}`}>
                                            <Collection title="Recaudación semanal"
                                                        amount={currencyFormat(billeteraData.billeteraSemanal)}
                                                        percent={billeteraData.porcentajeSemanal}
                                                        timeUnit="la semana pasada"
                                                        isMaster={isMaster}/>
                                            <Collection title="Recaudación mensual"
                                                        amount={currencyFormat(billeteraData.billeteraMensual)}
                                                        percent={billeteraData.porcentajeMensual}
                                                        timeUnit="el mes pasado"
                                                        isMaster={isMaster}/>
                                        </div>
                                    </div>
                                    <div className="MWallet__footerContainer">
                                        <div className={`MWallet__totalContainer ${!isMaster && 'polkista'}`}>
                                            <p className="MWallet__totalText">{!isMaster ? 'Disponible para retirar' : 'Disponible'}</p>
                                            <p className="MWallet__totalAmount">${currencyFormat(billeteraData.billeteraTotal)}</p>
                                        </div>
                                        <Button variant="filled" color="primary" size={'lg'} borderRadius='small'
                                                fullWidth={true}
                                                enabled onClick={() => {
                                            history.push('/u/billetera')
                                        }}>RETIRAR FONDOS</Button>
                                    </div>
                                </div>
                            } withButton={isMobile} setCollapse={setCollapse}/>
            {
                agreementsOpen && <AgreementPopUp closeAction={() => setAgreementsOpen(false)}></AgreementPopUp>
            }
            {
                moreInfo && <AgreementMoreInfo closeAction={() => setMoreInfo(false)}/>
            }
        </>
    );
}

export default MyWallet;