export const provinciasList = [
    {'value': 1, 'label': 'CIUDAD AUTONOMA DE BUENOS AIRES (CABA)'},
    {'value': 2, 'label': 'BUENOS AIRES'},
    {'value': 3, 'label': 'CATAMARCA'},
    {'value': 4, 'label': 'CORDOBA'},
    {'value': 5, 'label': 'CORRIENTES'},
    {'value': 6, 'label': 'ENTRE RIOS'},
    {'value': 7, 'label': 'JUJUY'},
    {'value': 8, 'label': 'MENDOZA'},
    {'value': 9, 'label': 'LA RIOJA'},
    {'value': 10, 'label': 'SALTA'},
    {'value': 11, 'label': 'SAN JUAN'},
    {'value': 12, 'label': 'SAN LUIS'},
    {'value': 13, 'label': 'SANTA FE'},
    {'value': 14, 'label': 'SANTIAGO DEL ESTERO'},
    {'value': 15, 'label': 'TUCUMAN'},
    {'value': 16, 'label': 'CHACO'},
    {'value': 17, 'label': 'CHUBUT'},
    {'value': 18, 'label': 'FORMOSA'},
    {'value': 19, 'label': 'MISIONES'},
    {'value': 20, 'label': 'NEUQUEN'},
    {'value': 21, 'label': 'LA PAMPA'},
    {'value': 22, 'label': 'RIO NEGRO'},
    {'value': 23, 'label': 'SANTA CRUZ'},
    {'value': 24, 'label': 'TIERRA DEL FUEGO'}
]

// TODO: integrar con los cambios en la branch de performance
export const productsByCompany = {
    "Sancor": ["Automotor", "Motovehículo", "Hogar", "Microseguros"],
    "RUS": ["Automotor", "Motovehículo"],
    "Zurich": ["Automotor"],
    "TerraWind": ["Asistencia al viajero"],
    "FedPat": ["Automotor"],
}