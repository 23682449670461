import React from 'react';
import './StepperStep.css';
import {ReactComponent as CheckIcon} from '../../assets/icons/ic_check.svg';

function StepperStep(props) {
    const {step, label, completed, color = "primary"} = props;
    const disabledFormat = label.includes("#");
    const formattedLabel = disabledFormat ? label.split("#")[1] : label;

    return (
        <div key={step} className="stepperStep__container">
            <div className="stepperStep__item">
                <span className={disabledFormat ? "stepperStep__line--disabled" : `stepperStep__line --${color}`}/>

                <div className={disabledFormat ? "stepperStep__circle--disabled" : `stepperStep__circle --${color}`}>
                    {completed
                        ? <CheckIcon className="stepperStep__icon"/>
                        : <p className="stepperStep__step">{step}</p>
                    }
                </div>

                <span className={disabledFormat ? "stepperStep__line--disabled" : `stepperStep__line --${color}`}/>
            </div>

            <p className={disabledFormat ? "stepperStep__label--disabled" : `stepperStep__label --${color}`}>{formattedLabel}</p>

        </div>
    );
}

export default StepperStep;