import {RoundedPanel} from "../../../../../../components/RoundedPanel";
import React from "react";
import "./ZurichAgreement.css";

const ZurichAgreement = () => {
    return (
        <div className="zurichAgreement__container">
            <RoundedPanel color="primary-alternative">
                <div className="zurichAgreement__convenioInfoContainer">
                    <p className="zurichAgreement__convenioInfoTitle">Este producto cuenta con los siguientes beneficios:</p>
                    <ul className="zurichAgreement__convenioInfoListContainer">
                        <li>Reparaciones exclusivas en concesionarios oficiales, utilizando repuestos originales y la experiencia de sus talleres.</li>
                        <li>Asistencia en ruta sin límite de kilometraje y eventos, proporcionando cobertura adicional contra robo.</li>
                        <li>Servicio de traslado del vehículo a un concesionario oficial para realizar los servicios de mantenimiento regulares.</li>
                        <li>Sistema de siniestros Z-Track, que permite a los clientes realizar un seguimiento detallado y en tiempo real de la resolución de siniestros de manera autónoma y multiplataforma.</li>
                    </ul>
                </div>
            </RoundedPanel>
        </div>
    );
}

export default ZurichAgreement;