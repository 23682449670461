import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import HighlightedTitleModal from "../../components/HighlightedTitleModal/HighlightedTitleModal";
import { LeadCatcherForm } from "../../features/LeadCatcher/form";
import { ErrorModal } from "../../layouts/ErrorModal";
import { SuccessModal } from "../../layouts/SuccessModal";
import "./AltaCompania.css";

function AltaCompania() {
    const [openCheckOut, setOpenCheckOut] = useState(0);
    const [formLinks, setFormLinks] = useState(null);
    const history = useHistory();

    return (
        <div className="AltaCompania__container">
            <div className="AltaCompania__modalContainer">
                <HighlightedTitleModal titleText="SOLICITAR CÓDIGO">
                    <div className="AltaCompania__formContainer">
                        <LeadCatcherForm setOpenCheckOut={setOpenCheckOut} setFormLinks={setFormLinks} />
                    </div>
                </HighlightedTitleModal>
                {openCheckOut === 200 &&
                    <SuccessModal
                        title={"La solicitud fue enviada exitosamente."}
                        body={
                            formLinks?.length !== 0 &&
                                <>
                                    <p className="AltaCompania__statusCard--subtitle"> Mientras tanto, podés ir llenando los siguientes formularios:</p>
                                    <div className="AltaCompania__statusCard-links">
                                        {
                                            formLinks instanceof Error || formLinks == null ?
                                                <p>Hubo un error al obtener los links a los formularios.</p>
                                                :
                                                formLinks.map((link, index) => {
                                                    return <a key={index} href={link.url} target="_blank" rel="noreferrer">{link.nombre}</a>
                                                })
                                        }
                                    </div>
                                </>
                        }
                        closeAction={() => {
                            setOpenCheckOut(0)
                            setOpenCheckOut(0);
                            history.push("/u/m/misaseguradoras");
                        }} />
                }
                {openCheckOut === 500 &&
                    <ErrorModal closeAction={() => setOpenCheckOut(0)}
                                title="Ocurrió un error al enviar la solicitud."
                                typeError="Error desconocido."
                                suggestedActions={["Revisá que la información ingresada sea correcta", "Reintentá el proceso una vez más", "Si el problema persiste ponete en contacto con el área de soporte"]}
                                detailedError="Error desconocido"/>
                }
            </div>
        </div>
    );
}

export default AltaCompania;