import React from 'react';
import './QuotationScoringModal.css';
import {InformationModal} from "../../../layouts/InformationModal";

function QuotationScoringModal(props) {
    const {setShow} = props;
    return (
        <InformationModal
            closeAction={() => setShow(false)}
            title={"Información de scoring"}
            severity={"info"}
            body={
                <div className="quotScoModal__body">
                    <p className="quotScoModal__text">
                        La información proporcionada sobre el sexo y la fecha de nacimiento del asegurado será utilizada para calcular el
                        precio de la cotización ofrecida por la compañía Zurich.
                    </p>
                </div>
            }
        />
    );
}

export default QuotationScoringModal;