import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom';
import "./ModalIndicator.css";
import useClickOutside from "../../modules/utils/useClickOutside";
import { calculatePosition } from "../../modules/utils/calculatePosition";
import UserContext from "../../context/UserContext";
import useWindowWidth from "../../hooks/useWindowWidth";

const ModalIndicator = ({
                            icon,
                            title,
                            message,
                            arrowDirection,
                            referenceElement,
                            extraGap = 0,
                            duration = 3000,
                            setVisible,
                            setDisplay,
                            fadeOut
                        }) => {
    const [subDirection, setSubDirection] = useState("left");
    const [fadeOutAnim, setFadeOutAnim] = useState(false);
    const modalRef = useRef(null);
    const el = useRef(document.createElement('div'));
    const { isMobile } = useContext(UserContext);
    const [position, setPosition] = useState(!isMobile ? { top: -9999, left: -9999 } : {});
    const windowWidth = useWindowWidth();

    const updateTooltipPosition = () => {
        if (!referenceElement.current || !modalRef.current) return;
        const newPosition = calculatePosition(referenceElement.current, modalRef.current, modalRef.current, arrowDirection, true);

        switch (arrowDirection) {
            case 'top':
                newPosition.top += extraGap;
                break;
            case 'down':
                newPosition.top -= extraGap;
                break;
            case 'left':
                newPosition.left += extraGap;
                break;
            case 'right':
                newPosition.left -= extraGap;
                break;
        }

        // Ajuste para evitar que el modal se salga de los bordes de la pantalla
        const viewportHeight = window.innerHeight;

        if (newPosition.left + newPosition.width >= windowWidth) {
            newPosition.left = newPosition.left - newPosition.width + 28;
            setSubDirection("right");
        } else setSubDirection("left");
        if (newPosition.top + newPosition.height > viewportHeight) {
            newPosition.top = viewportHeight - newPosition.height;
        }
        if (newPosition.left < 0) {
            newPosition.left = 0;
        }
        if (newPosition.top < 0) {
            newPosition.top = 0;
        }
        setPosition(newPosition);
    };

    useClickOutside(referenceElement, () => setDisplay(false));

    useLayoutEffect(() => {
        if (!isMobile && referenceElement && modalRef.current && referenceElement.current) {
            updateTooltipPosition();
        }
    }, [referenceElement, arrowDirection, extraGap, windowWidth]);

    useEffect(() => {
        document.body.appendChild(el.current);
        return () => {
            document.body.removeChild(el.current);
        };
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', updateTooltipPosition);
        return () => {
            window.removeEventListener('scroll', updateTooltipPosition);
        };
    }, [referenceElement.current]);

    useEffect(() => {
        if (fadeOut) {
            const timer = setTimeout(() => {
                setFadeOutAnim(true);
            }, duration - 500);

            return () => clearTimeout(timer);
        }
    }, [duration]);

    useEffect(() => {
        if (fadeOutAnim) {
            const timer = setTimeout(() => {
                setVisible(false);
            }, 500);

            return () => clearTimeout(timer);
        }
    }, [fadeOutAnim, setVisible]);

    useEffect(() => {
        if (modalRef.current && position.top === -9999 && position.left === -9999) {
            updateTooltipPosition();
        }
    }, [modalRef.current]);

    return ReactDOM.createPortal(
        <div
            ref={modalRef}
            className={`modalIndicator ${arrowDirection} ${subDirection ? `--${subDirection}` : ''} ${fadeOutAnim ? 'fade-out' : ''}`}
            style={{ top: position.top, left: position.left, visibility: position.top === -9999 ? 'hidden' : 'visible' }}
        >
            {icon && (
                <div className="modal__indicator__icon">
                    {icon}
                </div>
            )}
            <div className="modalIndicator__content">
                <h3>{title}</h3>
                <p>{message}</p>
            </div>
        </div>,
        el.current
    );
};

export default ModalIndicator;