import React from "react";
import {sendWhatsapp} from "../../../../../../modules/utils/sendWhatsapp";
import "./FormaPagoForm.css";

export default function nextStepsText(method, step) {
    switch (method) {
        case "TC":
        case "TD":
            const initText = step === "mid" ? "Luego de solicitar la póliza" : "En breve"
            return <p className="autem__success__footer__text--next_steps">
                {initText}, un asesor de Polko se comunicará para <u>gestionar el pago</u> del
                voucher correspondiente.
            </p>;
        case "TRANSFERENCIA":
            return (
                step === "mid" ? <p className="autem__success__footer__text--next_steps">
                        Luego de solicitar la póliza, se mostrarán los datos de la cuenta a transferir.
                    </p> :
                    <div className="nextStepsText__container">
                        <div className="nextStepsText__section">
                            <p className="autem__success__footer__text--next_steps">Datos de la cuenta a transferir:</p>
                            <div
                                className="autem__success__footer__PolkoPay autem__success__footer__text--next_steps">
                                <p>
                                    CBU
                                </p>
                                <p>
                                    0720064920000000577854
                                </p>
                                <p>
                                    Alias
                                </p>
                                <p>
                                    somos.polko
                                </p>
                                <p>
                                    Razón Social
                                </p>
                                <p>
                                    PAS SA
                                </p>
                                <p>
                                    CUIT
                                </p>
                                <p>
                                    30711392404
                                </p>
                            </div>
                        </div>
                        <div className="nextStepsText__section">
                            <p className="autem__success__footer__text--next_steps">
                                Enviá el comprobante de la transferencia por alguno de los siguientes medios:
                            </p>
                            <div
                                className="autem__success__footer__PolkoPay autem__success__footer__text--next_steps">
                                <p className="autem__success__footer__text--next_steps">Mail:
                                </p>
                                <a href="mailto:consultas@polko.com.ar"
                                   className="nextStepsText__link">consultas@polko.com.ar</a>
                                <p className="autem__success__footer__text--next_steps">Celular:
                                </p>
                                <a onClick={() => sendWhatsapp('https://wa.me/5493518687927')}
                                   className="nextStepsText__link">+54
                                    9 3518 68-7927</a>
                            </div>
                        </div>
                    </div>
            );
        default:
            return null;
    }

}