import React, {useContext} from 'react';
import {useFormikContext} from "formik";
import {currencyFormat} from "../../../../../../modules/utils/currencyFormat";
import CotizarContext from "../../../../../../context/CotizarContext";

export default function SolicitudForm() {
    const {values} = useFormikContext();
    const {companiesDict} = useContext(CotizarContext);

    const calculateDisplayPremium = () => {
        if (values.company === 'RUS') {
            return Math.ceil(values.premio / parseInt(values.parametrosAdicionales.cuotas))
        } else {
            return values.premio
        }
    }

    return (
        <React.Fragment>
            <div className="motovehiculo__formStep__container">
                <div className="motem__solicitud__header">
                    <p className="motem__solicitud__title">Detalles de póliza</p>
                    <img className="motem__solicitud__logo" src={companiesDict[values.company]['logo']} alt={values.company}/>
                </div>
                <hr className="motem__solicitud__hr"/>
                <div className="motem__solicitud__body">
                    <div className="motem__solicitud__body__content">
                        <p className="motem__solicitud__content__title">Datos del vehículo</p>
                        <p className="motem__solicitud__content__text">{values.motovehiculo.detalle_marca + " " + values.motovehiculo.detalle_version + " " + values.motovehiculo.anio}</p>
                        <p className="motem__solicitud__content__text">Patente: <span>{values.motovehiculo.patente}</span></p>
                        <p className="motem__solicitud__content__text">Valor vehículo: <span>$ {currencyFormat(values.motovehiculo.sumaAseguradaVehiculo)}</span></p>
                        <p className="motem__solicitud__content__text">Cobertura: <span>{values.nombreCobertura}</span></p>
                    </div>
                    <div className="motem__solicitud__body__content">
                        <p className="motem__solicitud__content__title">Datos del asegurado</p>
                        <p className="motem__solicitud__content__text">Nombre: <span>{values.clientes[0].nombre + " " + values.clientes[0].apellido}</span></p>
                        <p className="motem__solicitud__content__text">
                            {values.clientes[0]['tipoPersona'] === '1'
                                ? <>CUIT: <span>{values.clientes[0].cuit}</span></>
                                : <>DNI: <span>{values.clientes[0].dni}</span></>
                            }
                        </p>
                        <p className="motem__solicitud__content__text">Celular: <span>{values.clientes[0].telefono}</span></p>
                    </div>
                </div>
                <div className="motem__solicitud__footer">
                    <p className="motem__solicitud__premium">
                        Cuota mensual: ${currencyFormat(calculateDisplayPremium())}
                    </p>
                </div>
            </div>
        </React.Fragment>
    );
}