import React from "react";
import {motion} from "framer-motion";
import logoround from '../../assets/_images/loading_logo.svg';
import withRoot from "../../modules/withRoot";

function SmallLoading(props) {
    const {scale=[0.3, 0.45, 0.45, 0.3], width = 16, height = 16} = props;

    return (
        <div className="spinner"
             style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <motion.img
                src={logoround}
                style={{width: `${width}px`, height: `${height}px`}}
                animate={{
                    opacity: [0.2, 1, 1, 0],
                    scale: scale,
                    rotate: [0, 360, 360],
                }}
                transition={{
                    duration: 1.4,
                    ease: "easeInOut",
                    repeat: Infinity,
                    repeatType: 'loop',
                    repeatDelay: 0.8,
                }}
            />
        </div>
    )
}

export default withRoot(SmallLoading);
