import React from 'react';
import * as Yup from "yup";

const SolicitudModel = () => {
    const formField = {
        comentariosSolicitud: {
            name: 'comentariosSolicitud',
            label: 'Comentarios de la solicitud',
            requiredErrorMsg: ''
        },
    }

    const initialValues = {
        [formField.comentariosSolicitud.name]: '',
    }

    const validationSchema = Yup.object().shape({
        [formField.comentariosSolicitud.name]: Yup.string().matches(/^(?=[a-zA-Z0-9()_+=,.:\s-]*$)/, "Por favor, evite caracteres especiales"),
    })

    return {formField, initialValues, validationSchema}
}

export {SolicitudModel};
