import React, {useContext, useEffect, useState} from 'react';
import {Button, Grid} from '@material-ui/core';
import {InputField, RegularInputField, SelectField} from '../../../../components/FormFields';
import {makeStyles} from '@material-ui/core/styles';
import {Field, FieldArray, useFormikContext} from 'formik'
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormikAutocomplete from "../../../../components/FormFields/FormikAutocomplete";
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import ControlledSelectField from "../../../../utils/ControlledSelectField";
import PhoneNumberField from "../../../../components/FormFields/PhoneNumberField";
import {CuitGenerator} from "../../../../utils/cuitGenerator";
import CotizarContext from "../../../../../context/CotizarContext";

const useStyles = makeStyles((theme) => ({
    clientRow: {
        alignItems: 'center',
    },
    centerText: {
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonContained: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        backgroundColor: '#452d7b',
        '&:hover': {
            backgroundColor: '#38217b',
            boxShadow: 'none',
        },
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}));

export default function ClienteForm(props) {
    const classes = useStyles();
    const {localidades} = useContext(CotizarContext);
    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const {values, setFieldValue} = useFormikContext();
    const [anchorEl, setAnchorEl] = useState(null);
    const {
        formField: {
            cliente: {
                rol,
                personaJuridica,
                dni,
                cuit,
                nombre,
                apellido,
                fechaNacimiento,
                estadoCivil,
                sexo,
                domicilio,
                codigosLocalidad,
                codigoArea,
                telefono,
                email,
                situacionIva,
                categoriaMonotributo,
                actividadPrincipal,
                lugarNacimiento
            },
        },
        errors
    } = props;

    const [opcionesRolesState, setOpcionesRolesState] = useState([
        {value: '1', label: 'Tomador', used: false},
        // {value: '2', label: 'Asegurado'},
        {value: '7', label: 'Asegurado Adicional', used: false},
        {value: '15', label: 'Acreedor Hipotecario', used: false},
        {value: '18', label: 'Beneficiario de Asistencia', used: false},
    ]);

    const clienteVacio = {
        [rol.name]: '',
        [personaJuridica.name]: '0',
        [dni.name]: '',
        [cuit.name]: '',
        [nombre.name]: '',
        [apellido.name]: '',
        [fechaNacimiento.name]: '',
        [estadoCivil.name]: '2',
        [sexo.name]: '',
        [domicilio.name]: '',
        [codigosLocalidad.name]: '',
        [codigoArea.name]: '',
        [telefono.name]: '',
        [email.name]: '',
        [situacionIva.name]: '4',
        [categoriaMonotributo.name]: '1',
        [actividadPrincipal.name]: '10001',
        [lugarNacimiento.name]: 'Cordoba'
    };

    const opcionesSexo = [
        {value: '1', label: 'Femenino'},
        {value: '2', label: 'Masculino'}
    ];

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);

    const handleOpen = (index) => {
        setSelectedIndex(index);
        setOpen(true);
    };

    const opcionesBooleanas = [
        {value: '0', label: 'Persona Física'},
        {value: '1', label: 'Persona Jurídica'}
    ];

    const hasErrors = (e, idx) => {
        if (e.clientes !== undefined && e.clientes[idx] !== undefined) {
            return Object.keys(e.clientes[idx]).length > 0
        } else
            return values.clientes[idx].cuit === "";
    }

    const handleModifyCustomer = (e, index) => {
        let rolToSetTrue;
        let rolToSetFalse;

        if (e.target !== undefined) {
            setFieldValue(`clientes[${index}].rol`, e.target.value)
            rolToSetTrue = e.target.value;
            if (values.clientes[index].rol !== '') {
                rolToSetFalse = values.clientes[index].rol;
            }
        } else
            rolToSetFalse = values.clientes[index].rol;

        opcionesRolesState.map((rol, idx) => {
            if (rol.value === rolToSetTrue || rol.value === rolToSetFalse) {
                let items = [...opcionesRolesState];
                let item = items[idx];
                item.used = !item.used;
                items[idx] = item
                setOpcionesRolesState(items)
            }
        })
    }

    useEffect(() => {
        if (values.clientes[selectedIndex].dni) {
            if ((values.clientes[selectedIndex].dni).length === 8 && values.clientes[selectedIndex].sexo !== '') {
                let cuit = CuitGenerator(values.clientes[selectedIndex].dni, values.clientes[selectedIndex].sexo)
                setFieldValue(`clientes[${selectedIndex}]cuit`, cuit)
            }
        }
    }, [values.clientes[selectedIndex].dni, values.clientes[selectedIndex].sexo]);

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <FieldArray name='clientes'>{({remove}) => (
                    values.clientes.map((e, index) => (
                        <Grid key={index} item container spacing={2} direction='row' className={classes.clientRow}>
                            <Grid item xs={2}>
                                <Typography variant="h6" align='center'>
                                    {index + 1}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <ControlledSelectField
                                    name={`clientes[${index}].${rol.name}`}
                                    onChange={(cust) => handleModifyCustomer(cust, index)}
                                    label={rol.label}
                                    data={index === 0 ? [{value: '2', label: 'Asegurado'}] : opcionesRolesState}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={2} className={classes.centerText}>
                                <Button onClick={() => handleOpen(index)}>
                                    <EditIcon/>
                                </Button>
                            </Grid>
                            <Grid item xs={2} className={classes.centerText}>
                                {hasErrors(errors, index)
                                    ? (
                                        <div>
                                            <ErrorOutlineIcon
                                                style={{color: 'Blue'}}
                                                aria-owns={openPopover ? 'mouse-over-popover' : undefined}
                                                aria-haspopup="true"
                                                onMouseEnter={handlePopoverOpen}
                                                onMouseLeave={handlePopoverClose}
                                            />
                                            <Popover
                                                id="mouse-over-popover"
                                                className={classes.popover}
                                                classes={{
                                                    paper: classes.paper,
                                                }}
                                                open={openPopover}
                                                anchorEl={anchorEl}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                                onClose={handlePopoverClose}
                                                disableRestoreFocus
                                            >
                                                <Typography>Tiene campos sin completar o incorrectos</Typography>
                                            </Popover>
                                        </div>
                                    )
                                    : (<CheckCircleOutlineIcon style={{color: 'Green'}}/>)
                                }
                            </Grid>
                            {values.clientes.length !== 1 && (
                                <Grid item xs={2}>
                                    <Button onClick={() => {
                                        setSelectedIndex(0);
                                        remove(index)
                                        handleModifyCustomer(e, index)
                                    }}>
                                        <ClearIcon/>
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    ))
                )}</FieldArray>
                <Grid item xs={12}>
                    <FieldArray name='clientes'>{({push}) => (
                        <Button disabled={values.clientes.length === opcionesRolesState.length + 1} variant='outlined'
                                onClick={() => push(clienteVacio)}>nuevo cliente</Button>
                    )}</FieldArray>
                </Grid>
            </Grid>
            <Dialog
                aria-labelledby="form-dialog-title"
                open={open}
                onClose={() => setOpen(false)}
                fullWidth={true}
                maxWidth='md'
            >
                <DialogTitle id="form-dialog-title">Datos del cliente</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>

                        {values.clientes[selectedIndex].rol === '15' &&
                        <Grid item container xs={12}>
                            <Grid item xs={3}>
                                <SelectField
                                    name={`clientes[${selectedIndex}].${personaJuridica.name}`}
                                    label={"Tipo de Persona"}
                                    data={opcionesBooleanas}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        }

                        {values.clientes[selectedIndex].personaJuridica === '0' && (
                            <Grid item xs={12} sm={4}>
                                <InputField
                                    textTransform={'capitalize'}
                                    name={`clientes[${selectedIndex}].${nombre.name}`}
                                    label={nombre.label}
                                    fullWidth
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} sm={4}>
                            <InputField
                                textTransform={'capitalize'}
                                name={`clientes[${selectedIndex}].${apellido.name}`}
                                label={values.clientes[selectedIndex].personaJuridica === '0' ? apellido.label : "Razón Social*"}
                                fullWidth
                            />
                        </Grid>
                        {values.clientes[selectedIndex].personaJuridica === '0' && (
                            <Grid item xs={12} sm={4}>
                                <SelectField
                                    name={`clientes[${selectedIndex}].${sexo.name}`}
                                    label={sexo.label}
                                    data={opcionesSexo}
                                    fullWidth
                                />
                            </Grid>
                        )}
                        {values.clientes[selectedIndex].personaJuridica === '0' && (
                            <Grid item xs={12} md={4} sm={6}>
                                <InputField
                                    name={`clientes[${selectedIndex}].${dni.name}`}
                                    label={dni.label}
                                    fullWidth
                                    textTransform={'uppercase'}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} md={4} sm={6}>
                            <RegularInputField
                                name={`clientes[${selectedIndex}].${cuit.name}`}
                                label={cuit.label}
                                // onBlur={(e) => handleValueChange(e)}
                                fullWidth
                            />
                        </Grid>
                        {values.clientes[selectedIndex].personaJuridica === '0' && (
                            <Grid item xs={12} md={4} sm={6}>
                                <InputField
                                    name={`clientes[${selectedIndex}].${fechaNacimiento.name}`}
                                    label={fechaNacimiento.label}
                                    type="date"
                                    fullWidth
                                    defaultValue={values.vigenciaHasta}
                                    InputLabelProps={{shrink: true,}}
                                    textTransform={'lowercase'}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} md={4} sm={6}>
                            <InputField
                                textTransform={'capitalize'}
                                name={`clientes[${selectedIndex}].${domicilio.name}`}
                                label={domicilio.label}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={4} sm={6}>
                            <InputField
                                inputProps={{style: {textTransform: "lowercase"}}}
                                name={`clientes[${selectedIndex}].${email.name}`}
                                label={email.label}
                                fullWidth
                                textTransform={'lowercase'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <PhoneNumberField
                                codAreaName={`clientes[${selectedIndex}].${codigoArea.name}`}
                                phoneName={`clientes[${selectedIndex}].${telefono.name}`}
                                label={telefono.label}
                                isMobile={false}
                            />
                        </Grid>
                        {values.clientes[selectedIndex].rol !== '2' && (
                            <Grid item xs={12} md={4} sm={6}>
                                <Field
                                    name={`clientes[${selectedIndex}].${codigosLocalidad.name}`}
                                    label={codigosLocalidad.label}
                                    component={FormikAutocomplete}
                                    options={localidades}
                                    textFieldProps={{
                                        label: codigosLocalidad.label,
                                        required: true,
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <div>
                        <Button
                            variant='contained'
                            className={classes.buttonContained}
                            onClick={() => setOpen(false)}
                            color='primary'
                        >
                            Aceptar
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
