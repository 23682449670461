import { format } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { FormCard } from "../../../components/FormCard";
import UserContext from "../../../context/UserContext";
import MisDocumentosRow from "../MisDocumentosRow/MisDocumentosRow";
import "./MisDocumentosTable.css";
import documentList from '../documentList';
import AuthContext from '../../../context/AuthContext';
import getDocuments from '../../MisAseguradoras/MisAseguradorasCompanies/utils/getDocuments';

const MisDocumentosTable = () => {
    const { isMobile, idPolkista } = useContext(UserContext);
    const { authToken } = useContext(AuthContext);
    const [documents, setDocuments] = useState([]);
    const [tableItems, setTableItems] = useState([]);

    useEffect(() => {
        const fetchDocuments = async () => {
            const res = await getDocuments(idPolkista, authToken);
            setDocuments(res);
        };

        fetchDocuments();
    }, []);

    useEffect(() => {
        setTableItems(
            documentList.map((item) => {
                const document = documents.find((doc) => doc.label === item.value);
                return {
                    title: item.value,
                    name: document?.filename,
                    modificationDate: document ? format(new Date(document.updated_at), "dd/MM/yyyy") : null,
                    link: document?.link,
                };
            })
        )
    }, [documents]);

    if (isMobile) {
        return (
            <div className="MisDocumentosTable__container">
                {tableItems.map((item, index) => (
                    <MisDocumentosRow
                        key={index}
                        rowItems={item}
                        compact
                    />
                ))}
            </div>

        );
    } else {
        return (
            <div className="MisDocumentosTable__wrapper">
                <FormCard>
                    <div className="MisDocumentosTable__container">
                        {tableItems.map((item, index) => (
                            <MisDocumentosRow
                                key={index}
                                rowItems={item}
                            />
                        ))}
                    </div>
                </FormCard>
            </div>
        );
    }
}

export default MisDocumentosTable;
