import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    ListItemText,
    Dialog,
    Button,
    List,
    ListItem,
    IconButton,
    ListItemAvatar,
    Typography
} from '@material-ui/core';
import {useMutation, gql} from "@apollo/client";
import {useAuth0} from "@auth0/auth0-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import clsx from 'clsx';
import store from 'store'
import update from 'store/plugins/update';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '960px',
        maxHeight: '824px',
        paddingTop: theme.spacing(3)
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Ubuntu',
        fontSize: '24px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '-0.5px',
        color: '#452d7b',
        marginBottom: theme.spacing(2)
    },
    exitButtonDiv: {
        position: 'absolute',
        right: 0,
        top: 0,
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(2),
    },
    exitButton: {
        color: '#e54343',
        fontSize: '30px',
    },
    rowDivider: {
        width: '100%',
        height: '4px',
        backgroundColor: '#973379',
    },
    verticalDivider: {
        width: '4px',
        height: '100%',
        // margin: '20px 24px 0 8px',
        backgroundColor: '#c0c0c0',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'row',
        height: '550px',
        width: '100%',
        overflow: 'auto',
        justifyContent: 'space-between'
    },
    listText: {
        fontFamily: 'Ubuntu',
        fontSize: '16px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#35303f',
    },
    listTextDetail: {
        fontFamily: 'Montserrat',
        fontSize: '13px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.54',
        letterSpacing: 'normal',
        textAlign: 'justify',
        color: '#8a8597',
    },
    indicator: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '8px',
        height: '40px',
    },
    readIndicator: {
        backgroundColor: '#62c08a'
    },
    readPlaceholder: {
        backgroundColor: '#f3f4f8'
    },
    globalIndicator: {
        backgroundColor: '#452d7b'
    },
    notificationsArea: {
        width: '470px',
    },
    notificationsTitle: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        paddingTop: theme.spacing(3)
    },
    text: {
        fontFamily: 'Ubuntu',
        fontSize: '24px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '-0.5px',
        color: '#35303f',
        marginBottom: theme.spacing(2)
    },
    secondaryText: {
        fontFamily: 'Ubuntu',
        fontSize: '14px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'right',
        color: '#973379',
        marginBottom: theme.spacing(2)
    },
    notificationsList: {
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'auto',
        maxHeight: '482px',
        border: 'solid 1px #c0c0c0',
    },
    notificationContent: {
        width: '462px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(2),
    },
    menuItem: {
        paddingBottom: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingLeft: 0,
        paddingTop: 0,
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        border: 'solid 1px #c0c0c0',
        '&:hover': {
            backgroundColor: '#f3f4f8',
        },
        height: '96px',
    },
    menuItemRead: {
        backgroundColor: '#f3f4f8'
    },
    itemTextRoot: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: 0,
        //width: '100%',
        textAlign: 'left'
    },
    date: {
        width: '100%',
        fontFamily: 'Ubuntu',
        fontSize: '12px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'right',
        color: '#973379',
    },
    notificationTitle: {
        width: '100%',
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(2)
    },
    notificationMessage: {
        overflow: 'auto',
        maxHeight: '400px',
        padding: theme.spacing(3),
        //width: '100%',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.29',
        letterSpacing: 'normal',
        textAlign: 'justify',
        color: '#8a8597',
        flexGrow: 1,
        whiteSpace: 'pre-wrap'
    },
    buttonArea: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    botonMenu: {
        borderRadius: 8,
        border: 'solid 3px #452d7b',
        '&:hover': {
            backgroundColor: '#D3D3D3',
            boxShadow: 'none',
        },
        textTransform: 'none',
        padding: theme.spacing(1),
    },
    botonText: {
        fontFamily: "Ubuntu",
        fontSize: '20px',
        fontWeight: "bold",
        fontStyle: "normal",
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: "center",
        color: "#452d7b",
        textShadow: '0 1px 2px rgba(82, 82, 82, 0.15)',
        textShadowOffset: {
            width: 0,
            height: 1
        },
        textShadowRadius: 2
    },
    notificationPlaceholder: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Ubuntu',
        fontSize: '24px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '-0.5px',
        color: '#c0c0c0',
        marginBottom: theme.spacing(2)
    }
}));

const MARK_AS_READ_MUTATION = gql`
    mutation MyMutation($id: Int!, $read: Boolean!) {
        update_General_Notificaciones(where: {id: {_eq: $id}}, _set: {leido: $read}) {
            affected_rows
        }
    }
`;

const MARK_ALL_AS_READ_MUTATION = gql`
    mutation MyMutation($idAuth: String!) {
        update_General_Notificaciones(_set: {leido: true}, where: {id_Auth: {_eq: $idAuth}}) {
            affected_rows
        }
    }
`;

function NotificationsCenterDialog(props) {
    const classes = useStyles();
    const {open, onClose, data, notification, setLocalStorageChanged} = props;
    const [selectedNotification, setSelectedNotification] = useState(null);
    const {getIdTokenClaims} = useAuth0();
    const [markAsRead] = useMutation(MARK_AS_READ_MUTATION);
    const [markAllAsRead] = useMutation(MARK_ALL_AS_READ_MUTATION);

    store.addPlugin(update);

    const readNotification = () => {
        if (selectedNotification !== null){
            if(selectedNotification.row.id_Auth) {
                markAsRead({ variables: { id: selectedNotification.row.id, read: true } }).then(() => {
                    setSelectedNotification(null)
                });
            } else {
                store.update('readGlobalNotifications', [], (readGlobalNotifications) => {
                    if(!store.get('readGlobalNotifications')?.includes(selectedNotification.row.id)){
                        readGlobalNotifications.push(selectedNotification.row.id);
                        setLocalStorageChanged(true);
                    }
                });
                setSelectedNotification(null)
            }
        }
    };

    const unreadNotification = () => {
        if (selectedNotification !== null){
            if(selectedNotification.row.id_Auth) {
                markAsRead({ variables: { id: selectedNotification.row.id, read: false } }).then(() => {
                    setSelectedNotification(null)
                });
            } else {
                store.update('readGlobalNotifications', (readGlobalNotifications) => {
                    let index = readGlobalNotifications.indexOf(selectedNotification.row.id);
                    if (index > -1) {
                        readGlobalNotifications.splice(index, 1);
                        setLocalStorageChanged(true);
                    }
                });
                setSelectedNotification(null)
            }
        }
    };

    const handleReadAll = async () => {
        let claims = await getIdTokenClaims();
        let user = claims.sub;
        markAllAsRead({variables: {idAuth: user}}).then(() => {
            setSelectedNotification(null);
            store.update('readGlobalNotifications', [], (readGlobalNotifications) => {
                data.forEach(item => {
                    if(!store.get('readGlobalNotifications')?.includes(item.id) && item.id_Auth === null){
                        readGlobalNotifications.push(item.id);
                        setLocalStorageChanged(true);
                    }
                })
            });
        });
    };

    useEffect(() => {
        if(notification)
            setSelectedNotification(notification)
    }, [notification]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={false}
        >
            <div className={classes.root}>
                <div className={classes.title}>Centro de notificaciones</div>
                <div className={classes.exitButtonDiv}>
                    <IconButton className={classes.exitButton} onClick={onClose}>
                        <FontAwesomeIcon icon={faTimes}/>
                    </IconButton>
                </div>
                <div className={classes.rowDivider}/>
                <div className={classes.dialogContent}>
                    <div className={classes.notificationsArea}>
                        <div className={classes.notificationsTitle}>
                            <div className={classes.text}>Listado</div>
                            <Button className={classes.secondaryText} onClick={handleReadAll}>
                                MARCAR TODOS COMO LEIDOS
                            </Button>
                        </div>
                        <List disablePadding className={classes.notificationsList}>
                            {
                                (data) ?
                                    data?.map((row, index) => {
                                        const leido = row.leido || store.get('readGlobalNotifications')?.includes(row.id);
                                        return (
                                            <ListItem
                                                key={index}
                                                className={(leido || index === selectedNotification?.index)
                                                    ? clsx(classes.menuItemRead, classes.menuItem)
                                                    : classes.menuItem}
                                                onClick={() => setSelectedNotification({row, index})}
                                            >
                                                <ListItemAvatar>
                                                    {row.id_Auth === null && !leido
                                                        ? <div className={clsx(classes.indicator, classes.globalIndicator)}/>
                                                        : leido
                                                            ? <div className={clsx(classes.indicator, classes.readPlaceholder)}/>
                                                            : <div className={clsx(classes.indicator, classes.readIndicator)}/>
                                                    }
                                                </ListItemAvatar>
                                                <ListItemText
                                                    classes={{
                                                        primary: classes.listText,
                                                        secondary: classes.listTextDetail,
                                                        root: classes.itemTextRoot
                                                    }}
                                                    primary={row.titulo}
                                                    secondary={row.mensaje.length > 50 ? row.mensaje.slice(0, 50) + '...' : row.mensaje}
                                                />
                                                <div className={classes.date}>
                                                    {moment(row.created_at).format("DD/MM/YYYY HH:mm")}
                                                </div>
                                            </ListItem>
                                    )})
                                    : null
                            }
                        </List>
                    </div>
                    <div className={classes.verticalDivider} />
                    <div className={classes.notificationContent}>
                        {
                            (selectedNotification)
                                ? <>
                                    <div className={clsx(classes.notificationTitle, classes.text)}>
                                        {selectedNotification?.row.titulo}
                                    </div>
                                    <div className={classes.notificationMessage}>
                                        {selectedNotification?.row.mensaje}
                                    </div>
                                    <div className={classes.buttonArea}>
                                        <Button
                                            variant='outlined' className={classes.botonMenu}
                                            onClick={selectedNotification?.row.leido
                                            || store.get('readGlobalNotifications')?.includes(selectedNotification?.row.id)
                                                ? unreadNotification : readNotification}>
                                            <Typography className={classes.botonText}>
                                                {selectedNotification?.row.leido
                                                || store.get('readGlobalNotifications')?.includes(selectedNotification?.row.id)
                                                    ? 'Marcar como no leído' : 'Marcar como leído'}
                                            </Typography>
                                        </Button>
                                    </div>
                                </>
                                : <div className={classes.notificationPlaceholder}>
                                    {'Selecciona una notificación'}
                                </div>
                        }

                    </div>
                </div>
            </div>
        </Dialog>
    );
}

export default NotificationsCenterDialog;
