import React, {useContext} from 'react';
import './Dashboard.css';
import {QuotationsTable} from "../../features/Dashboard/QuotationsTable";
import DashboardContext from "../../context/DashboardContext";
import {Shortcuts} from "../../features/Dashboard/Shortcuts";
import UserContext from "../../context/UserContext";
import {DashboardStatistics} from "../../features/Dashboard/DashboardStatistics";

function Dashboard() {
    const {infoCotizaciones, infoCotizacionesCD} = useContext(DashboardContext);
    const {isMobile} = useContext(UserContext);

    return (
        <div className="dashboard__container">
            {isMobile && <div>
                <Shortcuts/>
            </div>}
            <DashboardStatistics isMobile={isMobile}/>

            <QuotationsTable cotizacionesPolko={infoCotizaciones}
                             cotizacionesCD={infoCotizacionesCD} isMobile={isMobile}/>
        </div>
    );
}

export default Dashboard;