import React from 'react';
import {IconZoomIn} from '@tabler/icons';
import "./ImageTemplate.css"


function ImageTemplate(props) {
    const {templateId, name, highlight} = props;

    return (
        <div className="ITemplate__imageContainer">
            <div className="ITemplate__templateDesktopContainer">
                <img className="ITemplate__templateDesktopImage"
                     src={`https://polko-images.s3.us-east-2.amazonaws.com/CanalesDigitales/templates/template${templateId}-desktop.png`}
                     alt={name}/>
            </div>
            <div className="ITemplate__templateMobileContainer">
                <div className="ITemplate__imageWrapper"></div>
                <img className="ITemplate__templateMobileImage"
                     src={`https://polko-images.s3.us-east-2.amazonaws.com/CanalesDigitales/templates/template${templateId}-mobile.png`}
                     alt={name}/>
            </div>
            {highlight && <div className="ITemplate__zoomIconContainer">
                <IconZoomIn className="ITemplate__zoomIcon"/>
            </div>}
        </div>
    );
}

export default ImageTemplate;