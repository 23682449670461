import React, {createRef, useState} from 'react';
import {Grid, Popover} from "@mantine/core";
import {useFormikContext} from "formik";
import {ClienteModel} from "./ClienteModel";
import {ReactComponent as EditIcon} from "../../../../../../assets/icons/ic_edit.svg";
import {ReactComponent as ExclamationIcon} from "../../../../../../assets/icons/ic_exclamation.svg";
import {ReactComponent as CircledCheckIcon} from "../../../../../../assets/icons/ic_check_circled.svg";
import PhoneInput from "react-phone-number-input";
import {MantineSelectField} from "../../../../../../components/FormFields/MantineSelectField";
import {MantineInputField} from "../../../../../../components/FormFields/MantineInputField";
import {MantineDateField} from "../../../../../../components/FormFields/MantineDateField";
import {MantineInputRefField} from "../../../../../../components/FormFields/MantineInputRefField";
import "./ClienteForm.css";
import {ZipCodeFieldPackage} from "../../../../../Cotizacion/ZipCodeFieldPackage";

export default function ClienteForm() {
    const {values, setFieldValue, errors} = useFormikContext();
    const {formField} = ClienteModel();
    const {
        tipoDocumento,
        numeroDocumento,
        nombre,
        apellido,
        fechaNacimiento,
        genero,
        pais_pasajero,
        provincia,
        codigoPostal,
        ciudad,
        calle, numero, opcional,
        telefono,
        email,
        nombre_contacto_emergencia,
        apellido_contacto_emergencia,
        telefono_contacto_emergencia
    } = formField;
    const [selectedIndex, setSelectedIndex] = useState(0);
    const ref = createRef();
    const [popoverState, setPopoverState] = useState({});
    const [localidadOptions, setLocalidadOptions] = useState(() => {
        const array = [];
        for (let i = 0; i < values.pasajeros.length; i++) {
            array.push([]);
        }
        return array;
    });
    const [provinciasOptions, setProvinciasOptions] = useState([]);

    const updateLocalidadOptions = (newLocalidades) => {
        setLocalidadOptions((prevState) => {
            const newState = { ...prevState };
            newState[selectedIndex] = newLocalidades;
            return newState;
        });
    };

    const opcionesSexo = [
        {value: 'FEMENINO', label: 'Femenino'},
        {value: 'MASCULINO', label: 'Masculino'}
    ];

    const opcionesTipoDocumento = [
        {value: 'DNI', label: 'DNI'},
        {value: 'PASAPORTE', label: 'Pasaporte'},
    ];

    const pasajeroVacio = {
        [tipoDocumento.name]: 'DNI',
        [numeroDocumento.name]: '',
        [nombre.name]: '',
        [apellido.name]: '',
        [fechaNacimiento.name]: '',
        [genero.name]: '',
        [pais_pasajero.name]: '11',
        [provincia.name]: '',
        [codigoPostal.name]: '',
        [ciudad.name]: '',
        [calle.name]: '',
        [numero.name]: '',
        [opcional.name]: '',
        [telefono.name]: '',
        [email.name]: '',
        [nombre_contacto_emergencia.name]: '',
        [apellido_contacto_emergencia.name]: '',
        [telefono_contacto_emergencia.name]: '',
    }

    const initializePassenger = (index) => {
        if (!values.pasajeros[index].hasOwnProperty('nombre')) setFieldValue(`pasajeros[${index}]`, {...values.pasajeros[index], ...pasajeroVacio})
        setSelectedIndex(index);
    }


    const handlePopOverOpen = (index) => {
        setPopoverState({
            ...popoverState,
            [index]: true
        });
    }

    const handlePopOverClose = (index) => {
        setPopoverState({
            ...popoverState,
            [index]: false
        });
    }

    const hasErrors = (e, idx) => {
        if (e.pasajeros !== undefined && e.pasajeros[idx] !== undefined) {
            return Object.keys(e.pasajeros[idx]).length > 0
        } else
            return false;
    }

    return (
        <React.Fragment>
            <div className="autem__clientes__layout">
                {/*FORMULARIO DE CLIENTE*/}
                <div className="autem__clientes__form__container">
                    {values.pasajeros.map((client, index) => {
                        if (index === selectedIndex) {
                            return (
                                <Grid align="center" gutterXs="xs">
                                    <Grid.Col xs={12} sm={6}>
                                        <MantineInputField
                                            name={`pasajeros[${selectedIndex}].${nombre.name}`}
                                            label={nombre.label}
                                            withAsterisk
                                        />
                                    </Grid.Col>
                                    <Grid.Col xs={12} sm={6}>
                                        <MantineInputField
                                            name={`pasajeros[${selectedIndex}].${apellido.name}`}
                                            label={apellido.label}
                                            withAsterisk
                                        />
                                    </Grid.Col>
                                    <Grid.Col xs={12} sm={6}>
                                        <MantineSelectField
                                            name={`pasajeros[${selectedIndex}].${genero.name}`}
                                            label={genero.label}
                                            data={opcionesSexo}
                                            withAsterisk
                                        />
                                    </Grid.Col>
                                    <Grid.Col xs={12} sm={6}>
                                        <MantineDateField
                                            name={`pasajeros[${selectedIndex}].${fechaNacimiento.name}`}
                                            label={fechaNacimiento.label}
                                            withAsterisk
                                        />
                                    </Grid.Col>
                                    <Grid.Col xs={12} sm={6}>
                                        <MantineSelectField
                                            name={`pasajeros[${selectedIndex}].${tipoDocumento.name}`}
                                            label={tipoDocumento.label}
                                            data={opcionesTipoDocumento}
                                            withAsterisk
                                        />
                                    </Grid.Col>
                                    <Grid.Col xs={12} sm={6}>
                                        <MantineInputField
                                            name={`pasajeros[${selectedIndex}].${numeroDocumento.name}`}
                                            label={numeroDocumento.label}
                                            withAsterisk
                                        />
                                    </Grid.Col>
                                    <ZipCodeFieldPackage
                                        localidadField={{
                                            name: `pasajeros[${selectedIndex}].${ciudad.name}`,
                                            label: ciudad.label
                                        }}
                                        localidadSize={6}
                                        codigoPostalField={{
                                            name: `pasajeros[${selectedIndex}].${codigoPostal.name}`,
                                            label: codigoPostal.label
                                        }}
                                        codigoPostalSize={6}
                                        provinciaField={{
                                            name: `pasajeros[${selectedIndex}].${provincia.name}`,
                                            label: provincia.label
                                        }}
                                        provinciaSize={6}
                                        localidadOptions={localidadOptions[selectedIndex] || []}
                                        setLocalidadOptions={updateLocalidadOptions}
                                        provinciasOptions={provinciasOptions}
                                        setProvinciaOptions={setProvinciasOptions}
                                        saveLabelAsValue/>
                                    <Grid.Col xs={12} sm={6}>
                                        <MantineInputField
                                            name={`pasajeros[${selectedIndex}].${calle.name}`}
                                            label={calle.label}
                                            withAsterisk
                                        />
                                    </Grid.Col>
                                    <Grid.Col xs={12} sm={6}>
                                        <MantineInputField
                                            name={`pasajeros[${selectedIndex}].${numero.name}`}
                                            label={numero.label}
                                            withAsterisk
                                        />
                                    </Grid.Col>
                                    <Grid.Col xs={12} sm={6}>
                                        <MantineInputField
                                            name={`pasajeros[${selectedIndex}].${opcional.name}`}
                                            label={opcional.label}
                                        />
                                    </Grid.Col>
                                    <Grid.Col xs={12} sm={6}>
                                        <MantineInputField
                                            name={`pasajeros[${selectedIndex}].${email.name}`}
                                            label={email.label}
                                            withAsterisk
                                        />
                                    </Grid.Col>

                                    <Grid.Col xs={12} sm={6}>
                                        <PhoneInput
                                            defaultCountry="AR"
                                            ref={ref}
                                            inputComponent={MantineInputRefField}
                                            name={`pasajeros[${selectedIndex}].${telefono.name}`}
                                            label={telefono.label}
                                            value={values['pasajeros'][selectedIndex][telefono.name]}
                                            onChange={(e) => {
                                                /** @namespace e.length **/
                                                if (e && e.length > 12) {
                                                    setFieldValue(`pasajeros[${selectedIndex}].${telefono.name}`, e)
                                                }
                                            }}
                                            withAsterisk
                                        />
                                    </Grid.Col>
                                </Grid>
                            )
                        }
                    })}
                    <hr className="asistenciaViajero__hrDivider"/>
                    <Grid align="center" gutterXs="xs">
                        <h1 className="asistenciaViajero__emergencyContactTitle">Contacto de emergencia de los pasajeros</h1>
                        <Grid.Col xs={12} sm={6}>
                            <MantineInputField
                                name={nombre_contacto_emergencia.name}
                                label={nombre_contacto_emergencia.label}
                                withAsterisk
                            />
                        </Grid.Col>
                        <Grid.Col xs={12} sm={6}>
                            <MantineInputField
                                name={apellido_contacto_emergencia.name}
                                label={apellido_contacto_emergencia.label}
                                withAsterisk
                            />
                        </Grid.Col>
                        <Grid.Col xs={12} sm={6}>
                            <PhoneInput
                                defaultCountry="AR"
                                ref={ref}
                                inputComponent={MantineInputRefField}
                                name={telefono_contacto_emergencia.name}
                                label={telefono_contacto_emergencia.label}
                                value={values[telefono_contacto_emergencia.name]}
                                onChange={(e) => {
                                    /** @namespace e.length **/
                                    if (e && e.length > 12) {
                                        setFieldValue(telefono_contacto_emergencia.name, e)
                                    }
                                }}
                                withAsterisk
                            />
                        </Grid.Col>
                    </Grid>
                </div>

                {/*LISTA DE pasajeros*/}
                <div className="asistenciaViajero__clientes__list__container">
                    {values.pasajeros.map((e, index) => (
                        <div
                            className={selectedIndex === index ? "asistenciaViajero__clientes__list__row--active" : "asistenciaViajero__clientes__list__row"}>
                            <div className="autem__clientes__input__container">
                                <p className="asistenciaViajero__pasajeroListName">Pasajero {index + 1}</p>
                            </div>
                            <div className="autem__clientes__icon__container"
                                 onClick={() => initializePassenger(index)}>
                                <EditIcon className="autem__clientes__icon--edit"/>
                            </div>
                            {hasErrors(errors, index)
                                ? (
                                    <Popover width={200} position="bottom" withArrow shadow="md"
                                             opened={popoverState[index] || false}>
                                        <Popover.Target>
                                            <div className="autem__clientes__icon__container"
                                                 onMouseEnter={() => handlePopOverOpen(index)}
                                                 onMouseLeave={() => handlePopOverClose(index)}>
                                                <ExclamationIcon className="autem__clientes__icon--exclamation"/>
                                            </div>
                                        </Popover.Target>
                                        <Popover.Dropdown sx={{pointerEvents: 'none'}}>
                                            <p>Tiene campos sin completar</p>
                                        </Popover.Dropdown>
                                    </Popover>
                                )
                                : (<div className="autem__clientes__icon__container">
                                    <CircledCheckIcon className="autem__clientes__icon--check"/>
                                </div>)
                            }
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
}