import React from 'react';
import './MasterDashboardStatistics.css';
import {StatisticsCard} from "../../../components/StatisticsCard";

function MasterDashboardStatistics(props) {
    const {data} = props;
    return (
        <div>
            <p className="masterDashboardStatistics__title">Estadísticas globales</p>
            <div className="masterDashboardStatistics">
                {data.map((statistic, index) => (
                    <div className="masterDashboardStatistics__card">
                        <StatisticsCard key={index} title={statistic.title} value={statistic.value}/>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default MasterDashboardStatistics;