import React, {useState} from 'react';
import './QuotationResponseTabError.css';
import {ReactComponent as ExclamationIcon} from "../../../assets/icons/ic_exclamation.svg";

function QuotationResponseTabError(props) {
    const {title, typeError, bodyText, type, detailedError, suggestedActions} = props;
    const [verMasOpen, setVerMasOpen] = useState(false);

    return (
        <div className="responseTabError__container">
            <div className="responseTabError__header">
                <ExclamationIcon className={`responseTabError__icon --${type}`}/>
                <p className={`responseTabError__title --${type}`}>
                    {title}
                </p>
            </div>

            <div className={`responseTabError__body`}>
                {typeError && <div className="responseTabError__body__block">
                    <p className="responseTabError__subtitle">Error</p>
                    <p className="responseTabError__text">{typeError}</p>
                </div>}

                {bodyText && <div className="responseTabError__body__block">
                    <p className="responseTabError__text">{bodyText}</p>
                </div>}

                {suggestedActions && <div className="responseTabError__body__block">
                    <p className="responseTabError__subtitle">Acciones recomendadas</p>
                    {suggestedActions.map(action => (
                        <p className="responseTabError__text">{action}</p>
                    ))}
                </div>}
            </div>

            {detailedError && <div className={`responseTabError__body__block responseTabError__body__block--${verMasOpen}`}>
                <p className="responseTabError__subtitle--link" onClick={() => setVerMasOpen(!verMasOpen)}>
                    Ver {verMasOpen ? "menos" : "más"} información del problema</p>

                <p className={`responseTabError__text responseTabError__text--${verMasOpen}`}>
                    {detailedError}
                </p>
            </div>}
        </div>
    );
}

export default QuotationResponseTabError;