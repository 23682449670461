import {BestSellingCoverages} from "../../../features/Dashboard/BestSellingCoverages";
import {MyWallet} from "../../../features/Dashboard/MyWallet";
import {RankingCard} from "../../../features/Dashboard/RankingCard";
import React, {useContext, useState} from "react";
import {ReactComponent as ChevronDown} from '../../../assets/icons/ic_chevron_down.svg';
import DashboardContext from "../../../context/DashboardContext";
import "./DashboardStatistics.css";

function DashboardStatistics(props) {
    const {
        datosCoberturas
    } = useContext(DashboardContext);

    const {isMobile, companies} = props;
    const [collapseCoverages, setCollapseCoverages] = useState(isMobile);
    const [collapseWallet, setCollapseWallet] = useState(isMobile);
    const [collapseRanking, setCollapseRanking] = useState(isMobile);

    const openCollapse = () => {
        setCollapseCoverages(!collapseCoverages);
        setCollapseWallet(!collapseWallet);
        setCollapseRanking(!collapseRanking);
    }

    return (
        <div>
            {!isMobile && <p className="DStatistics__title DStatistics__title--clickable"
                             onClick={() => isMobile ? null : openCollapse()}>Estadísticas <ChevronDown
                className={`DStatistics__icChevronDown--${collapseCoverages}`}/></p>}
            <div className="DStatistics__contentContainer">
                <div className="DStatistics__cardsContainer">
                    <div
                        className={collapseCoverages ? "DStatistics__containerCollapsed dashboard__coverage" : "DStatistics__BSCoverageContainer dashboard__coverage"}>
                        <BestSellingCoverages collapse={collapseCoverages} setCollapse={setCollapseCoverages}
                                              data={datosCoberturas} companies={companies}/>
                    </div>
                    <div
                        className={collapseWallet ? "DStatistics__containerCollapsed--fit dashboard__wallet" : "DStatistics__fitContainer dashboard__wallet"}>
                        <MyWallet collapse={collapseWallet} setCollapse={setCollapseWallet} isMobile={isMobile}/>
                    </div>
                    <div
                        className={collapseRanking ? "DStatistics__containerCollapsed--fit dashboard__ranking" : "DStatistics__fitContainer dashboard__ranking"}>
                        <RankingCard collapse={collapseRanking} setCollapse={setCollapseRanking}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardStatistics;