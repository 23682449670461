import React from 'react';

const UserContext = React.createContext({

    userIdAuth: null,
    userName: null,
    userLastName: null,
    userProvince: null,
    userIdProvince: null,
    userMail: null,
    userPicture: null,
    setUserPicture: null,
    userPhone: null,
    userCargo: null,
    userMatricula: null,
    masterMatricula: null,
    masterName: null,
    masterLastName: null,
    userDNI: null,
    userCUIT: null,
    userDireccion: null,
    userSituacionIVA: null,
    userOcupacion: null,
    userFechaNacimiento: null,
    userNacionalidad: null,
    userSexo: null,
    userLugarNacimiento: null,
    userPais: null,
    userCBU: null,
    userCodigoLocalidad: null,
    userLocalidad: null,
    userCodigoPostal: null,
    userWebHabilitado: null,
    userLink: null,
    userQR: null,
    userDetalleComisiones: null,
    userCodigosOperacion: null,
    userCompanies: null,
    userCompaniesNames: null,
    userCompaniesToPromote: null,
    userDiscounts: null,
    idMaster: null,
    isMobile: null,
    sidebarItems: null,
    sidebarOpen: null,
    setSidebarOpen: null,
    activeFormProduct: null,
    setActiveFormProduct: null,
    courses: null,
    setCourses: null,
    lastCourses: null,
    setLastCourses: null,
    setPersonalRanking: null,
    personalRanking: null,
    idPolkista: null,
    isReady: null
});

export default UserContext;